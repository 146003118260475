import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { TasksView } from './TasksView';
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";

import Config from '@apricityhealth/web-common-lib/Config';

import React, { Component } from 'react';
import {
    AppBar, Toolbar, Typography, DialogActions, DialogTitle,
    DialogContentText, DialogContent, Dialog,
    CircularProgress, FormGroup, IconButton, TextField, Button,
    Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from 'axios';

export class ActionView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        console.log("componentWillMount:", this);
        const { planId } = this.props.appContext.state.plan;
        const { actionId } = this.props;
        if (planId && actionId)
            this.getAction(planId, actionId);
    }
    componentDidUpdate(oldProps) {
        const { planId } = this.props.appContext.state.plan;
        if (this.state.action && this.state.actionId !== this.props.actionId)
            this.getAction(planId, this.props.actionId);
    }
    closeAction() {
        console.log("closeAction()", this);
        this.props.onClose();
    }

    getAction(planId, actionId) {
        const self = this;
        const getAction = {
            url: Config.baseUrl + `${Config.pathPrefix}actions/${planId}/${actionId}?errors=true&dependencies=true`,
            method: 'GET',
            headers: { "Authorization": self.props.appContext.state.idToken }
        };

        console.log("getAction:", getAction);
        this.setState({ action: null, progress: <CircularProgress /> });
        Axios(getAction).then(function (response) {
            console.log("getAction response:", response);
            const action = response.data.action;
            action.errors = response.data.errors;
            self.loadAction(action);
        }).catch(function (error) {
            console.error("getAction error:", error);
            self.setState({ progress: error.message });
        });
    }

    loadAction(action) {
        this.setState({
            action: action,
            actionId: action.actionId,
            progress: null,
            dialog: null
        });
    }

    showActionJSON() {
        let { appContext } = this.props;
        let { action } = this.state;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={action}
                onEditDone={(action) => {
                    this.setState({ action, dialog: null });
                }}
                onDone={() => {
                    this.setState({ dialog: null });
                }} />
        });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    saveAction(planId = null) {
        console.log("saveAction()", this);

        const self = this;
        const { action } = this.state;
        const { appContext } = this.props;

        if (!planId && action.planId && action.planId !== planId) {
            return this.displayOverrideDialog(action.planId, this.saveAction.bind(this));
        }
        if (!planId) {
            planId = appContext.state.plan.planId;
        }

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        self.setState({ progress: <CircularProgress /> });

        if (action.actionId) {
            const updateAction = {
                url: Config.baseUrl + `${Config.pathPrefix}actions/${planId}/${action.actionId}`,
                method: 'PUT',
                headers: { "Authorization": self.props.appContext.state.idToken },
                data: action
            };

            console.log("updateAction:", updateAction);
            Axios(updateAction).then(function (response) {
                console.log("updateAction response:", response);
                self.setState({ actionId: response.data.actionId, action: response.data, progress: null });
            }).catch(function (error) {
                console.log("updateAction error:", error);
                self.setState({ progress: error.message });
            });
        }
        else {
            // no planId, so this is a new action..
            const addAction = {
                url: Config.baseUrl + `${Config.pathPrefix}actions/${planId}`,
                method: 'POST',
                headers: { "Authorization": self.props.appContext.state.idToken },
                data: action
            };

            console.log("addAction:", addAction);
            Axios(addAction).then(function (response) {
                console.log("addAction response:", response);
                self.setState({ actionId: response.data.actionId, action: response.data, progress: null });
            }).catch(function (error) {
                console.log("addAction error:", error);
                self.setState({ progress: error.message });
            });
        }
    }

    deleteAction() {
        console.log("deleteAction()", this);
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Action: {`${self.state.actionId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDeleteAction() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDeleteAction() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDeleteAction() {
        this.setState({ dialog: null });
    }

    confirmDeleteAction() {
        const self = this;
        const action = self.state.action;
        const planId = action.planId;

        self.setState({ dialog: null });
        if (action.actionId) {
            const deleteAction = {
                url: Config.baseUrl + `${Config.pathPrefix}actions/${planId}/${action.actionId}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("deleteAction:", deleteAction);
            Axios(deleteAction).then(function (response) {
                console.log("deleteAction response:", response);
                self.setState({ progress: null });
                self.closeAction();
            }).catch(function (error) {
                console.log("deleteAction error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }

    onChangeCondition(e) {
        this.setState({ conditions: e });
    }

    resetErrors() {
        const self = this;
        const { appContext } = this.props;
        const { action } = this.state;

        const deleteErrors = {
            url: Config.baseUrl + `${Config.pathPrefix}actions/${action.planId}/${action.actionId}/errors`,
            method: 'DELETE',
            headers: { "Authorization": appContext.state.idToken }
        };

        this.setState({ progress: <CircularProgress /> });
        console.log("deleteErrors:", deleteErrors);
        Axios(deleteErrors).then((response) => {
            action.errors = [];
            self.setState({ progress: null, action });
            console.log("deleteErrors response:", response);
        }).catch((err) => {
            self.setState({ progress: null });
            console.log("deleteErrors error:", err);
        });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { action, progress, dialog } = self.state;

        const errorColumns = [
            { id: 'createDate', numeric: false, disabledPadding: false, label: 'Date' },
            { id: 'message', numeric: false, disabledPadding: false, label: 'Message' }
        ];

        if (action && Array.isArray(action.errors) && action.errors.length > 0) {
            var errors = <div align="right"><EnhancedTable
                disableMultiSelect={true}
                order='desc'
                orderBy='createDate'
                columnData={errorColumns}
                data={action.errors}
                rowsPerPage={5}
                title='Errors' />
                <Button variant="contained" style={styles.button} onClick={() => { self.resetErrors(); }}>Delete Errors</Button>
            </div>;
        }

        if (action) {
            var editAction = <div align="left" style={styles.div}>
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <p>Action ID: {action.actionId}</p>
                            </td>
                            <td style={styles.td} valign="bottom">
                                <IconButton onClick={() => { self.showActionJSON(); }}><CodeIcon /></IconButton>
                                <IconButton style={styles.button} onClick={self.saveAction.bind(self)}><SaveIcon /></IconButton>
                                <IconButton style={styles.button} onClick={self.deleteAction.bind(self)}><DeleteIcon /></IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <TasksView appContext={appContext} tasks={action.tasks}
                                    onTasksChanged={(tasks) => { action.tasks = tasks; self.setState({ action }); }} />
                                <Accordion style={styles.panel}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Advanced Options</Typography>
                                    </AccordionSummary >
                                    <AccordionDetails>
                                        <div>
                                            <FormGroup row>
                                                <TextField multiline style={styles.question} label="Conditions" value={action.conditions} rowsMax="5"
                                                    onChange={(e) => { action.conditions = e.target.value; self.setState({ action }); }} />
                                                <TextField style={styles.actionId} label="Action ID" value={action.actionId}
                                                    onChange={(e) => { action.actionId = e.target.value; self.setState({ action }); }} />
                                            </FormGroup>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>;
        }

        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => self.closeAction()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="h6" color="inherit">Action Details</Typography>
            </Toolbar>
        </AppBar> : null;

        return (
            <div style={{ width: 1400 }} >
                {appBar}
                {editAction}
                {errors}
                {progress}
                {dialog}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 5
    },
    div: {
        margin: 10
    },
    question: {
        margin: 5,
        width: 800
    },
    actionId: {
        margin: 5,
        width: 300
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
    panel: {
        margin: 5
    }
}

export default ActionView;
