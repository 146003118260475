import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    CircularProgress,
    IconButton,
    FormControlLabel,
    Checkbox,
    Drawer
} from '@material-ui/core/';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import NavigationClose from '@material-ui/icons/Close';

import SaveIcon from '@material-ui/icons/Save';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import SelectCondition from '@apricityhealth/web-common-lib/components/SelectCondition';

const MAX_TYPES_DISPLAYED = 10;

const Promise = require('bluebird');

class ReviewConditionTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            types: props.types,
            updateParent: false,
            parentId: '',
            comorbidity: false,
            clearComorbitiy: false,
            dialog: null,
            progress: false
        }
    }

    closeView() {
        this.props.onClose();
    }

    saveTypes() {
        const { appContext } = this.props;
        const { types } = this.state;

        if (isArrayValid(types)) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            Promise.map(types, (type) => {
                if (this.state.comorbidity)
                    type.comorbidity = true;
                else if (this.state.clearComorbitiy)
                    type.comorbidity = false;
                if (this.state.updateParent)
                    type.parentId = this.state.parentId;
                return appContext.stores.DataTypesStore.saveCondition(type);
            }, { concurrency: 20 }).then(() => {
                this.setState({ progress: null, error: null });
                this.closeView();
            }).catch((err) => {
                this.setState({ progress: null, error: getErrorMessage(err) });
            })
        }
    }

    render() {
        const { appContext } = this.props;
        const { types, progress, dialog, parentId, comorbidity, error, clearComorbitiy } = this.state;

        return (
            <Drawer variant="persistent" anchor="right" open={true} >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => this.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Conditions Review</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    Total Types: {types.length}
                                    <span style={{ color: 'red' }}>{error}</span>
                                </td>

                                <td valign="top" align="right">
                                    <IconButton onClick={this.saveTypes.bind(this, null, null)}>{progress || <SaveIcon />}</IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ol>
                                        {isArrayValid(types) && types.map((rule, i, a) => {
                                            if (i < MAX_TYPES_DISPLAYED)
                                                return <li key={i}>{rule.name || `<no name>`} {rule.system || `<no system>`}</li>;
                                            else if (i === MAX_TYPES_DISPLAYED) {
                                                return <span>... and {types.length - MAX_TYPES_DISPLAYED} more not displayed.</span>
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </ol>

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={this.state.updateParent}
                                            onChange={(e) => {
                                                this.setState({ updateParent: e.target.checked });
                                            }}
                                        />
                                        } label="Update Parent" />
                                    <SelectCondition
                                        appContext={appContext}
                                        enableNone={true}
                                        conditionId={parentId}
                                        onChange={(parentId) => {
                                            this.setState({ parentId, updateParent: true })
                                        }}
                                    />
                                    <br />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={comorbidity}
                                            onChange={(e) => {
                                                this.setState({ comorbidity: e.target.checked, clearComorbitiy: false });
                                            }}
                                        />
                                        } label="Flag as Comorbidity" />
                                    <br />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={clearComorbitiy}
                                            onChange={(e) => {
                                                this.setState({ clearComorbitiy: e.target.checked, comorbidity: false });
                                            }}
                                        />
                                        } label="Clear Comorbidity" />
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
            </Drawer>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 10
    },
    table: {
        "width": "100%"
    },
}

export default ReviewConditionTypes;
