import React, { Component } from "react";
import {
    IconButton, Badge,
    Tooltip,
} from '@material-ui/core/';
import { v4 as uuidv4 } from 'uuid';
import ReactDragListView from 'react-drag-listview';
import {
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';
import DoneIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloneIcon from '@material-ui/icons/FileCopy';
import VisibilityOn from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import QuestionComponent from "./QuestionComponent";
import { DataLinkIcon } from '../dialogs/DataLinksDialog';

import move from "../utils/ArrayMove";
import { Table } from 'antd';

import 'antd/dist/antd.css';
import '../styles/tableedit.css';
import createIdFromText from "../utils/CreateIdFromText";


export function getQuestionText(question) {
    return question && isArrayValid(question.question) ? question.question[0].text : "";
}

export function getAnswerText(answer, addScore = false) {
    let helperText = ""
    if (answer.controlType === 'slider') {
        helperText = `<span style="color:blue">[${answer?.controlOptions?.min}-${answer?.controlOptions?.max} | ${answer?.controlOptions?.scale}]</span>`;
    }
    if (addScore === true)
        return answer && isArrayValid(answer.text) && answer.text[0] + ` (${answer.score}) ${helperText}`;
    else
        return answer && isArrayValid(answer.text) && answer.text[0];
}


export class QuestionsComponent extends Component {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            questionModels: JSON.parse(JSON.stringify(this.props.questionModels)),
            allowQuestionSelect: this.props.allowQuestionSelect || false,
            showAnswers: true,
            editingKey: null
        };

        //this is the drag function for sorting the question list in the model
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                let { questionModels } = self.state;
                questionModels = move(questionModels, fromIndex, toIndex);
                self.setState({
                    questionModels
                }, () => self.onChange());
            },
            handleSelector: "a",
        };

    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.state.questionModels) !== JSON.stringify(this.props.questionModels)) {
            this.setState({ questionModels: JSON.parse(JSON.stringify(this.props.questionModels)) });
        }
    }

    cloneQuestion(questionModel) {
        let { onCloneQuestion } = this.props;
        if (typeof onCloneQuestion === 'function') {
            onCloneQuestion(questionModel);
        } else {
            let { questionModels } = this.state;
            let num = questionModels.length + 1;
            let newQuestionModel = JSON.parse(JSON.stringify(questionModel));
            delete newQuestionModel.originalQuestion;
            delete newQuestionModel.originalSymptom;
            let newQuestion = newQuestionModel.question;
            //important.. remove the symptom and dataId since the quesiton is added and not yet hooked to a symptom
            newQuestionModel.order = num;
            newQuestion.order = num;
            newQuestion.name = newQuestion.name + `(${num})`;
            newQuestion.description = newQuestion.name;
            newQuestion.question[0].text = newQuestion.question[0].text + `(${num})`;
            newQuestion.questionId = createIdFromText(newQuestion.name);
            newQuestion.dataId = newQuestion.questionId;
            newQuestionModel.key = uuidv4();

            newQuestionModel.symptom.dataId = newQuestion.dataId;
            newQuestionModel.symptom.name = newQuestion.name;
            newQuestionModel.symptom.description = newQuestion.description;


            questionModels.push(newQuestionModel)
            this.setState({ questionModels, key: newQuestionModel.key }, () => this.onChange());
        }
    }

    onAddQuestion() {
        let { onAddQuestion } = this.props;
        let { questionModels } = this.state;
        if (typeof onAddQuestion === 'function') {
            onAddQuestion();
            this.setState({ editingKey: "newQuestion" + (questionModels.length + 1) });
        } else {

            let newQuestion = {
                question: [{ text: ``, condition: '' }],
                questionId: "newQuestion" + questionModels.length + 1,
                answers: [],
                dataId: null,
                andFlagConditions: [],
                orFlagConditions: [],
                addFlags: [],
                removeFlags: [],
                mapping: [],
                bounds: [],
                baseline: false,
                order: questionModels.length + 1
            };
            let symptomDataType = {
                category: 'symptom',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            let questionModel = { question: newQuestion, order: newQuestion.order, key: uuidv4(), symptom: symptomDataType }
            questionModels.push(questionModel)

            this.setState({ questionModels, editingKey: questionModel.key }, () => this.onChange());
        }
    }


    onDeleteQuestion(questionModel) {
        let { onDeleteQuestion, onQuestionDeleted } = this.props;
        if (typeof onDeleteQuestion === 'function') {
            onDeleteQuestion(questionModel);
        } else {
            let { questionModels } = this.state;
            let index = questionModels.findIndex((model) => model.question.questionId === questionModel.question.questionId);
            if (index < 0) {
                this.setState({ error: "question not found in question models" });
                return;
            }
            questionModels.splice(index, 1);
            if (typeof onQuestionDeleted === 'function') {
                onQuestionDeleted(questionModel);
            }
            this.setState({ questionModels }, () => this.onChange());
        }
    }

    onChange() {
        let { questionModels } = this.state;
        let { onChange } = this.props;
        if (typeof onChange === 'function')
            this.props.onChange(questionModels);
    }


    updateQuestion(updatedQuestion) {
        let question = updatedQuestion.question;
        let { questionModels } = this.state;
        let symptomDataType = updatedQuestion.symptom;
        if (!symptomDataType) {
            symptomDataType = {
                category: 'symptom',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            updatedQuestion.symptom = symptomDataType;
        }
        symptomDataType.dataId = question.dataId;
        symptomDataType.name = question.name;
        symptomDataType.description = question.name;

        this.setState({ questionModels }, () => this.onChange());
    }

    updateKeys() {
        // //we do this in case the question ID has changed we want to update the keys in the list. The key is unique to ensure the table works correctly
        // let { questionModels } = this.state;
        // questionModels.forEach(questionModel => {
        //     if (questionModel.question)
        //         questionModel.key = questionModel.question.questionId;
        // });
        // this.setState({ questionModels });
    }


    render() {
        let { appContext } = this.props;
        const { dialog, allowQuestionSelect, questionModels, showAnswers, editingKey } = this.state;
        let columns = [
            {
                title: "#",
                width: 60,
                render: (text, record, index) => {
                    return <div >
                        <a className="drag-handle" href="#section" >{record.order}</a>
                    </div >;
                }
            },
            {
                title: <Badge badgeContent={0} color='error'>
                    {"QUESTION"}
                </Badge>,
                ellipsis: true,
                width: '80%',
                render: (text, record, index) => {
                    const obj = {
                        children: text,
                        props: {},
                    };

                    let questionErrors = [];
                    let errorTitle = questionErrors.length > 1 ? <ol className="no-padding-list">{questionErrors.map(err => <li>{err}</li>)}</ol> : "";
                    obj.children = editingKey !== record.key ? <div>
                        <Tooltip title={errorTitle}><Badge badgeContent={0} color='error'>
                            <span dangerouslySetInnerHTML={{ __html: record.question ? getQuestionText(record.question) : record.key }} />
                        </Badge></Tooltip>

                        {showAnswers && record.question && <ol>{isArrayValid(record.question.answers) ? record.question.answers.map(ans => <li key={ans.answerId}><span dangerouslySetInnerHTML={{ __html: getAnswerText(ans, true) }} /></li>) : "No Answers"}</ol>}
                    </div> : <div className="no_wrap"><div className="item item1"><QuestionComponent appContext={appContext} allowQuestionSelect={allowQuestionSelect} question={record.question} symptom={record.symptom} onChange={(question) => { record.question = question; this.updateQuestion(record) }} /></div><div className="item item2"><Tooltip title={"Close"}><IconButton style={styles.operationButton} onClick={() => this.setState({ editingKey: null }, () => this.updateKeys())}><DoneIcon /></IconButton></Tooltip></div></div>;

                    if (editingKey === record.key) {
                        obj.props.colSpan = 2;
                    }
                    return obj;
                }
            },
            {
                title: "ACTION",
                width: '20%',
                render: (text, record, index) => {
                    const obj = {
                        children: text,
                        props: {},
                    };
                    obj.children = <div>
                        {(editingKey === record.key) && <Tooltip title={"Close"}><IconButton style={styles.operationButton} onClick={() => this.setState({ editingKey: null }, () => this.updateKeys())}><DoneIcon /></IconButton></Tooltip>}
                        {(editingKey !== record.key) && <Tooltip title={"Edit"}><IconButton style={styles.operationButton} onClick={() => this.setState({ editingKey: record.key })}> <EditIcon /></IconButton></Tooltip>}
                        {record.symptom && <DataLinkIcon appContext={appContext} dataId={record.symptom.dataId} />}
                        {<Tooltip title={"Clone Question"}><IconButton style={styles.operationButton} onClick={() => this.cloneQuestion(record)}><CloneIcon /></IconButton></Tooltip>}
                        {<Tooltip title={'Delete Question'}><IconButton style={styles.operationButton} onClick={() => this.onDeleteQuestion(record)}>{<DeleteIcon />}</IconButton></Tooltip>}
                    </div>;
                    if (editingKey === record.key) {
                        obj.props.colSpan = 0;
                    }
                    return obj;
                }
            }
        ];

        //this is crazy the table won't refresh without creating a new list
        let contentList = [...questionModels]

        return (
            <ReactDragListView  {...this.dragProps}>
                <Table
                    className="question-table"
                    title={() => {
                        return <table valign='bottom' style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td valign='bottom'><i>The questions can be reorderd by dragging the number.</i></td>
                                    <td valign='bottom' align='right'>
                                        <Tooltip title={'Add Question'}><IconButton onClick={() => this.onAddQuestion()}><AddIcon /></IconButton></Tooltip>
                                        <Tooltip title={showAnswers ? 'Hide Answers' : "Show Answers"}><IconButton onClick={() => { this.setState({ showAnswers: !showAnswers }) }}>{showAnswers ? <VisibilityOff /> : <VisibilityOn />}</IconButton></Tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }}
                    columns={columns}
                    pagination={{ pageSize: 20 }}
                    dataSource={contentList}
                />
                {dialog}
            </ReactDragListView>

        );
    }
}


const styles = {
    operationButton: {
        marginTop: -5,
        marginBottom: -5,
    },
    appBar: {
        width: '1300px',
        backgroundColor: "#FF9800"
    },
    name: {
        width: 200,
    },
    reference: {
        width: 500,
    },
    pageSize: {
        width: 150,
    },
    entry: {
        width: 100,
    },
    select: {
        paddingLeft: 10,
        width: 300,
        align: "left",
        overflow: 'visible'
    },
    description: {
        width: 500,
    },
    desc: {
        align: 'bottom',
        valign: 'bottom'
    },

};


export default QuestionsComponent;
