import React, { Component } from "react";
import {
    Chip,
    Avatar,
    FormLabel,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    TextField
} from '@material-ui/core/';

import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';

import {
    isArrayValid
} from '../utils/Utils';

class EditableTextField extends Component {
    constructor(props) {
        super(props);

        this.state = { value: props.value };
    }

    onChange(e) {
        this.setState( { value: e.target.value });
        if ( this.props.onChange )
            this.props.onChange( e );
    }

    render() {
        return <div>
            <TextField label={this.props.label} value={this.state.value} style={this.props.style} 
                onChange={this.onChange.bind(this)} />
        </div>;
    }
}

class EditMappingArray extends Component {
    constructor(props) {
        super(props);

        this.state = {
            array: props.array,
            name: props.name,
            menu: null
        };
    }

    onAdd = index => e => {
        let { array } = this.state;
        //just add a default one
        array.splice( 0, 0, { min: '', max: '', v: '' })
        this.setState({ array });
    }

    onChangeData = index => e => {
        //const self = this;
        let { array } = this.state;
        let mapping = array[index];
        let menu = <Menu open={true} onClose={this.closeMenu.bind(this)} anchorEl={e.target}>
            <MenuItem>
                <EditableTextField value={mapping.description} label="Description" onChange={(e) => mapping.description = e.target.value} />
            </MenuItem>
            <MenuItem>
                <EditableTextField value={mapping.min} label="Min" onChange={(e) => mapping.min = e.target.value} />
            </MenuItem>
            <MenuItem>
                <EditableTextField value={mapping.max} label="Max" onChange={(e) => mapping.max = e.target.value} />
            </MenuItem>
            <MenuItem>
                <EditableTextField value={mapping.v} label="Value" onChange={(e) => mapping.v = e.target.value} />
            </MenuItem>
        </Menu>;

        this.setState({ menu });
    }

    closeMenu() {
        this.setState({ menu: null });
    }

    componentDidUpdate(prevProps) {
        const hasNewArray = JSON.stringify(prevProps.array) !== JSON.stringify(this.props.array)
        const hasNewName = prevProps.name !== this.props.name
        if (hasNewArray || hasNewName){
            let array = this.props.array ? this.props.array : [];
            let name = this.props.name;
            this.setState({ array, name });
        }
    }

    onDelete = index => e => {
        let { array } = this.state;
        array.splice( index, 1 );
        this.setState({ array });
    }

    render() {
        let self = this;
        let { array, menu } = this.state;

        let dataChips = [];
        if (isArrayValid(array)) {
            for (let index = 0; index < array.length; index++) {
                let data = array[index];
                if ( data.min === undefined ) data.min = '';
                if ( data.max === undefined ) data.max = '';
                let label = data.description || (`${data.min} < ${data.v} < ${data.max}`);
                dataChips.push(
                    <Chip
                        key={index}
                        avatar={<Avatar><FaceIcon /></Avatar>}
                        label={label}
                        onClick={self.onChangeData(index)}
                        onDelete={self.onDelete(index)}
                    />);

            }
        }

        let label = <FormLabel>{this.props.label || "Edit Mapping"}</FormLabel>;
        if ( this.props.tip ) 
            label = <Tooltip title={this.props.tip}>{label}</Tooltip>;

        return <div >
            <div style={{ marginTop: '5px' }} />
            {label}
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={self.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div >;
    }
}

export default EditMappingArray;
