import React from 'react';
import {
    AppBar, Toolbar, Typography, Button, TextField, FormControlLabel, Checkbox, CircularProgress,
    Accordion, AccordionSummary, AccordionDetails, IconButton, Drawer, Paper, Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ConversationView from '@apricityhealth/web-common-lib/views/ConversationView';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import SelectModelType from '@apricityhealth/web-common-lib/components/SelectModelType';
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';
import JSONView from './JSONView';

import Axios from 'axios';
import Config from '@apricityhealth/web-common-lib/Config';
import Moment from 'moment';
import DataLinksDialog from '../dialogs/DataLinksDialog';

function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
}

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

export function getPatient(appContext, patientId) {
    return new Promise((resolve, reject) => {
        if (patientId === '*') {
            return resolve();
        }

        let url = Config.baseUrl + `${Config.pathPrefix}patients?patientId=${patientId}`;
        const getPatient = {
            url: url,
            method: 'GET',
            headers: { "Authorization": appContext.state.idToken }
        }
        console.log(`getPatient request:`, getPatient)
        Axios(getPatient).then((response) => {
            console.log(`getPatient response: `, response);
            if (isArrayValid(response.data.patients))
                resolve(response.data.patients[0]);
            else
                resolve();
        }).catch(() => {
            resolve()
        });
    });
}

function formatDate(date) {
    if (typeof date === 'string')
        date = Moment(date).toDate();
    if (date.getFullYear) {
        return date.getFullYear() + "-" + addZero((date.getMonth() + 1)) + "-" +
            addZero(date.getDate()) + "T" + addZero(date.getHours()) + ":" + addZero(date.getMinutes());
    }
    return null;
}

class ConversationTestView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            baseline: false,
            triage: false,
            verify: true,
            showVideos: false,
            enableModels: false,
            patientId: '',
            flags: [],
            models: [],
            triggers: [],
            questionTriggers: {},
            requiredData: [],
            videos: [],
            recommendations: [],
            historicalData: [],
            data: [],
            useEventTime: false,
            eventTime: new Date(),
            status: null,
            enableUserType: false,
            userType: 'patient'
        };
        console.log("ConversationTestView()", this);
    }

    onPatientSelected(v) {
        console.log("onPatientSelected:", v);
        this.setState({ patientId: v, status: null }, () => this.getAlertLevels());
    }

    handleResponse(res, view) {
        console.log("handleResponse:", res);

        let reset = view.state.conversation.length === 0;          // reset our data track if this is the start of the conversation
        let { recommendations, flags, models, triggers, requiredData, questionTriggers } = this.state;
        if (res.flags) {
            flags = res.flags;
        }
        if (res.models) {
            models = res.models;
        }
        if (res.recommendations) {
            let list = [];
            for (let k in res.recommendations) list.push(res.recommendations[k]);
            recommendations = list;
        }
        if (res.triggers) {
            let list = [];
            for (let k in res.triggers) list.push({ modelId: k, ...res.triggers[k] });
            triggers = list;
        }
        if (res.requiredData && res.aquiredData) {
            let list = [];
            for (let i = 0; i < res.requiredData.length; ++i) {
                list.push({ dataId: res.requiredData[i], aquired: res.aquiredData.indexOf(res.requiredData[i]) >= 0 });
            }
            requiredData = list;
        }
        if (res.question) {
            questionTriggers = res.question.triggers;
        }

        this.setState({ recommendations, flags, models, triggers, requiredData, questionTriggers });
        this.updateData(reset);
        this.loadVideos();
    }

    loadVideos() {
        let { appContext } = this.props
        let { patientId, showVideos } = this.state;
        let idToken = appContext.state.idToken;
        if (showVideos) {
            getPatient(appContext, patientId).then((results) => {
                const request = {
                    url: Config.baseUrl + `${Config.pathPrefix}content/education/${patientId}?conditional=true`,
                    method: 'GET',
                    headers: { "Authorization": idToken }
                }

                this.setState({ progress: <div className='App-loading'><CircularProgress /></div> });
                console.log(`getConditionalEducation request`, request);
                return Axios(request);
            }).then((response) => {
                console.log(`getConditionalEducation response`, response);
                if (response && response.data) {
                    let videoItems = response.data;
                    this.setState({ progress: null, videoItems })
                }
            }).catch((error) => {
                this.setState({ progress: null })
                console.error("getConditionalEducation error", error);
            });
        }

    }
    getPlanIds(patient) {
        let planIds = [];
        if (patient) {
            for (let i = 0; i < patient.plans.length; ++i) {
                planIds.push(patient.plans[i].planId);
            }
        }
        return planIds;
    }

    getAlertLevels() {
        const self = this;
        let { patientId } = this.state;
        const { appContext } = this.props;
        if (patientId) {
            return new Promise((resolve, reject) => {
                getPatient(appContext, patientId).then((patient) => {
                    let planIds = this.getPlanIds(patient);
                    if (planIds.length > 0) {
                        let promises = [];
                        for (let i = 0; i < planIds.length; ++i) {
                            let planId = planIds[i];
                            let getAlertLevels = {
                                url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/alert_levels/*`,
                                method: 'GET',
                                headers: { "Authorization": appContext.state.idToken },
                            }
                            console.log("getAlertLevels:", getAlertLevels);
                            promises.push(Axios(getAlertLevels));
                        }

                        Promise.all(promises).then((results) => {
                            console.log("getAlertLevels results:", results);

                            let alert_levels = [];
                            for (let i = 0; i < results.length; ++i) {
                                alert_levels = alert_levels.concat(results[i].data);
                            }

                            console.log("alert_levels loaded:", alert_levels);
                            self.setState({ alert_levels, alertLevelsLoaded: true }, resolve);
                        }).catch((err) => {
                            console.error("getAlertLevels error:", err);
                            setTimeout(() => self.getAlertLevels().then(resolve).catch(reject), 1000);      // re-try in 1 second
                        })
                    }
                    else {
                        self.setState({ alert_levels: [], alertLevelsLoaded: true });
                        resolve();
                    }
                })
            })
        }
    }

    resetConversation(hardReset = false, resetContext = false) {
        this.setState({ status: null })
        this.conversation.resetConversation(hardReset, resetContext);
    }

    updateData(reset) {
        const self = this;
        const { appContext } = this.props;
        const { useEventTime, eventTime, patientId } = this.state;

        if (patientId) {
            self.setState({ progress: <CircularProgress size={20} />, error: null });
            const getPatientData = {
                url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}/data`,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken }
            };

            console.log("getPatientData:", getPatientData);
            Axios(getPatientData).then(function (response) {
                console.log("getPatientData response:", response);
                let data = response.data.data;
                if (useEventTime) {
                    let historicalData = [];
                    let newData = [];
                    for (let i = 0; i < data.length; ++i) {
                        let record = data[i];
                        if (Moment(record.eventTime).toDate() < eventTime)
                            historicalData.push(record);
                        else
                            newData.push(record);
                    }

                    self.setState({ progress: null, data: newData, historicalData });
                }
                else {
                    if (reset === false) {
                        const { historicalData } = self.state;

                        let newData = [];
                        for (let i = 0; i < data.length; ++i) {
                            let record = data[i];
                            if (historicalData.length > 0 && record.eventTime <= historicalData[0].eventTime)
                                break;      // no more new data, so stop for loop
                            newData.push(record);
                        }
                        console.log("newData:", newData);
                        self.setState({ progress: null, data: newData });
                    }
                    else {
                        self.setState({ progress: null, historicalData: response.data.data, data: [] });
                    }
                }

            }).catch(function (error) {
                console.log("getPatientData error:", error);
                self.setState({ progress: null, error: error.message });
            });
        }
    }

    onModelControl(table, value, row, index, id) {
        return <SelectModelType appContext={this.props.appContext} modelId={value}
            onChange={(type) => table.setDataField(index, id, type ? type.modelId : '')}
            onClick={(e) => table.handleClickEdit(e)} />;
    }

    onSelectModels(e) {
        this.setState({ models: e.target.value })
    }

    onToggleUseEventTime(e) {
        let { useEventTime } = this.state;
        useEventTime = !useEventTime;
        this.setState({ useEventTime });
    }

    onToggleVerify(e) {
        this.setState({ verify: !this.state.verify });
    }

    onToggleVideos(e) {
        this.setState({ showVideos: !this.state.showVideos }, this.loadVideos.bind(this));
    }

    onToggleModels() {
        this.setState({ enableModels: !this.state.enableModels });
    }

    onToggleBaseline(e) {
        this.setState({ baseline: !this.state.baseline });
    }

    onToggleTriage(e) {
        this.setState({ triage: !this.state.triage })
    }

    onChangeEventTime(e) {
        let eventTime = Moment(e.target.value).toDate();
        this.setState({ eventTime });
    }

    onCloseDetails() {
        this.state.table.setState({ selected: [] });     //  de-select the item from the table
        this.setState({ dialog: null });
    }

    onShowDetails(selected, table) {
        if (selected.length > 0) {
            const details = table.props.data[selected[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <JSONView appContext={this.props.appContext} data={details} onClose={this.onCloseDetails.bind(this)} />
            </Drawer>;
            this.setState({ dialog, table });
        }
    }

    onShowDataLinks(selected, table) {
        if (selected.length > 0) {
            const row = table.props.data[selected[0]];
            let dialog = <DataLinksDialog appContext={this.props.appContext} dataId={row.dataId} onClose={this.onCloseDetails.bind(this)} />;
            this.setState({ dialog, table })
        }
    }

    onConversationDone(res) {
        console.log("onConversationDone:", res);
        const { alert_levels } = this.state;
        let validFlag = res.flags && res.flags.find((e) => alert_levels.find((x) => x.addFlag === e));
        let flag = alert_levels.find((e) => e.addFlag === validFlag);
        console.log(" flag ", flag);

        let currentStatus = flag ? flag.alertLevelId : null;
        let statusBar = flag ? <div align='center'>
            <Paper style={{ background: flag.color, padding: 10 }}>
                {currentStatus}</Paper><br /></div> : null;
        this.setState({ status: statusBar })
    }

    render() {
        const { appContext } = this.props;
        const { dialog, baseline, triage, enableModels, useEventTime, eventTime, patientId, progress, error,
            data, historicalData, flags, models, verify, triggers, questionTriggers, requiredData, showVideos, 
            videoItems, status, enableUserType, userType } = this.state;
        const store = appContext.stores.DataTypesStore;
        const flagsColumns = [
            { id: 'flagId', numeric: false, disabledPadding: false, label: 'Flag' },
        ];

        const modelSelect = enableModels ? <FormControl style={styles.select}>
            <InputLabel>Select Models</InputLabel>
            <Select multiple={true} value={models} onChange={this.onSelectModels.bind(this)}>
                {store.getModels().map((model) => <MenuItem key={model.modelId} value={model.modelId}>{model.modelId}</MenuItem>)}
            </Select>
        </FormControl> : null;

        const userTypeSelect = enableUserType ? <FormControl style={styles.select}>
            <InputLabel>Select User Type</InputLabel>
            <Select value={userType} onChange={(e) => this.setState({userType: e.target.value })}>
                <MenuItem value={'patient'}>Patient</MenuItem>
                <MenuItem value={'delegate'}>Delegate</MenuItem>
                <MenuItem value={'provider'}>Provider</MenuItem>
                <MenuItem value={'lab'}>Lab</MenuItem>
            </Select>
        </FormControl> : null;

        let flagList = [];
        for (let i = 0; i < flags.length; ++i)
            flagList.push({ flagId: flags[i] });
        const flagsPanel = <Accordion style={styles.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Flags ({flags ? flags.length : '0'})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <EnhancedTable
                        disableActions={true}
                        disableSelect={true}
                        order='asc'
                        orderBy='name'
                        columnData={flagsColumns}
                        data={flagList}
                        rowsPerPage={5}
                        title='Flags' />
                </div>
            </AccordionDetails>
        </Accordion>;

        const requiredDataColumns = [
            { id: 'dataId', label: 'Data ID' },
            { id: 'aquired', label: 'Aquired' },
        ];
        const requiredDataCount = requiredData ? requiredData.length : '0';
        const aquiredDataCount = requiredData ? requiredData.filter(e => e.aquired === true).length : '0';
        const requiredDataPanel = <Accordion style={styles.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Required Data ({requiredDataCount}), Aquired Data ({aquiredDataCount})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <EnhancedTable
                        disableMultiSelect={true}
                        disableActions={true}
                        order='desc'
                        orderBy='eventTime'
                        columnData={requiredDataColumns}
                        data={requiredData}
                        rowsPerPage={10}
                        onSelected={this.onShowDataLinks.bind(this)}
                        title='Required Data' />
                </div>
            </AccordionDetails>
        </Accordion>;

        const triggerColumns = [
            { id: 'modelId', numeric: false, disabledPadding: false, label: 'Model ID' },
            { id: 'data', numeric: false, disabledPadding: false, label: 'Data ID', formatValue: (v) => v.dataId }
        ];
        const triggerPanel = <Accordion style={styles.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Model Triggers ({triggers ? triggers.length : '0'})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <EnhancedTable
                        disableMultiSelect={true}
                        disableActions={true}
                        order='desc'
                        orderBy='eventTime'
                        columnData={triggerColumns}
                        data={triggers}
                        rowsPerPage={10}
                        onSelected={this.onShowDetails.bind(this)}
                        title='Model Triggers' />
                </div>
            </AccordionDetails>
        </Accordion>;

        const questionTriggersPanel = <Accordion style={styles.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Question Triggers</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <JSONView appContext={this.props.appContext} data={questionTriggers} />
                </div>
            </AccordionDetails>
        </Accordion>;

        const dataColumns = [
            { id: 'eventTime', numeric: false, disabledPadding: false, label: 'Event Time' },
            { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
            { id: 'data', numeric: false, disabledPadding: false, label: 'Values', formatValue: (v, r) => { return v ? v.join(',') : '' } }
        ];
        const dataPanel = <Accordion style={styles.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Data ({data ? data.length : '0'})</Typography>
            </AccordionSummary >
            <AccordionDetails>
                <div>
                    <EnhancedTable
                        disableMultiSelect={true}
                        disableActions={true}
                        order='desc'
                        orderBy='eventTime'
                        columnData={dataColumns}
                        data={data}
                        rowsPerPage={10}
                        onSelected={this.onShowDetails.bind(this)}
                        title='Data' />
                </div>
            </AccordionDetails>
        </Accordion>;

        const historicalDataPanel = <Accordion style={styles.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Historical Data ({historicalData ? historicalData.length : '0'})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <EnhancedTable
                        disableMultiSelect={true}
                        disableActions={true}
                        order='desc'
                        orderBy='eventTime'
                        columnData={dataColumns}
                        data={historicalData}
                        rowsPerPage={10}
                        onSelected={this.onShowDetails.bind(this)}
                        title='Historical Data' />
                </div>
            </AccordionDetails>
        </Accordion>;
        const manifestCols = [
            { id: 'title', numeric: false, disabledPadding: false, label: 'Title' },
            { id: 'type', numeric: false, disabledPadding: false, label: 'Type' },
            { id: 'order', numeric: false, disabledPadding: false, label: 'Order' },
            { id: 'condition', numeric: false, disabledPadding: false, label: 'Condition' }
        ];

        const videoMamifestPanel = <Accordion style={styles.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Video List ({videoItems ? videoItems.length : '0'})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <EnhancedTable
                        disableMultiSelect={true}
                        disableActions={true}
                        order='order'
                        orderBy='order'
                        columnData={manifestCols}
                        data={videoItems}
                        rowsPerPage={10}
                        onSelected={this.onShowDetails.bind(this)}
                        title='Education Videos' />
                </div>
            </AccordionDetails>
        </Accordion>;

        const eventTimeEdit = useEventTime ?
            <TextField label="Event Time"
                style={{ margin: 10 }}
                type="datetime-local"
                value={formatDate(eventTime)}
                onChange={this.onChangeEventTime.bind(this)} />
            : null;

        return (
            <div align="center" height="100%" width={800}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => this.props.onClose()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Test Conversation</Typography>
                    </Toolbar>
                </AppBar>

                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <td align="left">
                                <FormControlLabel control={<Checkbox checked={verify} onChange={this.onToggleVerify.bind(this)} />}
                                    label="Verify" />
                                <FormControlLabel control={<Checkbox checked={baseline} onChange={this.onToggleBaseline.bind(this)} />}
                                    label="Baseline" />
                                <FormControlLabel control={<Checkbox checked={triage} onChange={this.onToggleTriage.bind(this)} />}
                                    label="Triage" />
                                <FormControlLabel control={<Checkbox checked={useEventTime} onChange={this.onToggleUseEventTime.bind(this)} />}
                                    label="Use Event Time" />
                                <FormControlLabel control={<Checkbox checked={showVideos} onChange={this.onToggleVideos.bind(this)} />}
                                    label="Show Videos" />
                                <FormControlLabel control={<Checkbox checked={enableModels} onChange={this.onToggleModels.bind(this)} />}
                                    label="Select Models" />
                                <FormControlLabel control={<Checkbox checked={enableUserType} onChange={(e,v) => this.setState({enableUserType: v})} />}
                                    label="User Type" />
                                {eventTimeEdit !== null ? <br /> : null}
                                {eventTimeEdit}
                                {modelSelect !== null ? <br /> : null}
                                {modelSelect}
                                {userTypeSelect !== null ? <br /> : null}
                                {userTypeSelect}
                                <br />
                                <SelectPatient style={styles.select}
                                    appContext={appContext}
                                    planId={this.props.planId}
                                    patientId={patientId}
                                    enableCreatePatient={true}
                                    enableNone={true}
                                    onSelect={this.onPatientSelected.bind(this)} />
                                <p>Plan Id: {this.props.planId}</p>
                                <p>Patient Id: {patientId}</p>
                                {flagsPanel}
                                {historicalDataPanel}
                                {dataPanel}
                                {triggerPanel}
                                {requiredDataPanel}
                                {questionTriggersPanel}
                                {showVideos && videoMamifestPanel}
                                <Button variant="contained" style={styles.button} onClick={this.resetConversation.bind(this, true, true)}>Reset Context</Button>
                                <Button variant="contained" style={styles.button} onClick={this.resetConversation.bind(this, true, false)}>Restart</Button>
                                {error}
                                {progress}
                                {dialog}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {status}
                                <ConversationView appContext={appContext}
                                    source={[{ client: 'rosetta-web-client' }]}
                                    patientId={patientId}
                                    verify={verify}
                                    baseline={baseline}
                                    triage={triage}
                                    eventTime={useEventTime ? eventTime : undefined}
                                    userType={enableUserType ? userType : undefined}
                                    models={models}
                                    onPostDone={this.onConversationDone.bind(this)}
                                    onResponse={this.handleResponse.bind(this)} ref={(conversation) => this.conversation = conversation} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const styles = {
    text: {
        margin: 10,
        width: '100%'
    },
    table: {
        margin: 10,
        width: 1024
    },
    select: {
        margin: 10,
        width: '50%'
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    paper: {
        margin: 10,
        maxHeight: '100%',
        height: '100%',
        overflow: 'auto',
        width: 1024
    },
    responses: {
        margin: 10
    },
    edit: {
        margin: 10,
        width: '100%'
    },
    panel: {
        margin: 5
    }

}

export default ConversationTestView;
