import React from "react";
import PropTypes from 'prop-types';
import { default as SortableTree, toggleExpandedForAll, walk } from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

import {
    withStyles,
    CircularProgress,
    TextField,
    FormControlLabel,
    Checkbox,
    FormLabel,
    IconButton,
    Drawer,
    Tooltip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Switch
} from '@material-ui/core/';

//import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import FlagIcon from '@material-ui/icons/Flag';
import TranslateIcon from '@material-ui/icons/Translate';
import RefreshIcon from '@material-ui/icons/Refresh';
import TestIcon from '@material-ui/icons/QuestionAnswer';
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import ErrorIcon from '@material-ui/icons/Error'
import SaveIcon from '@material-ui/icons/Save';

import {
    isArrayValid
} from '../utils/Utils';

import {
    deleteQuestionErrors
} from '@apricityhealth/web-common-lib/utils/Services';

import JsonDialog from '../dialogs/JsonDialog';
import EditQuestionDialog from '../dialogs/EditQuestionDialog';
import SaveAllQuestionsDialog from '../dialogs/SaveAllQuestionsDialog'
import DeleteQuestionDialog from '../dialogs/DeleteQuestionDialog';
import AdHocTranslationDialog from '../dialogs/AdHocTranslationDialog';
import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';
import { ImportExport } from '@apricityhealth/web-common-lib/components/ImportExport';
import Plan from '@apricityhealth/web-common-lib/components/Plan';

import ConversationTestView from "./ConversationTestView";
import QuestionErrorsView from "./QuestionErrorsView";
import PubSub from 'pubsub-js'
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import EditableNote from '../components/EditableNote'
import '../styles/questions.css';

const maxDepth = 3;

function getText(texts, textId, language = 'en-us') {
    if (texts[language]) {
        if (texts[language][textId]) {
            return texts[language][textId]
        } else {
            //console.error(`Failed to find textId [${textId}]`, texts[language] );
        }
    }
    else {
        //console.error(`Failed to find language [${language}]`)
    }
    return null;
}

function filterQuestion(filter, question, texts, language) {
    if (filter === "" || filter === "*") return true;
    if (question) {
        let translation = getText(texts, question.questionId, language);
        if (translation && translation.text) {
            if (translation.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                return true;
        }

        if (question.questionId && question.questionId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
        if (isArrayValid(question.answers)) {
            for (let i = 0; i < question.answers.length; ++i) {
                let answer = question.answers[i];
                translation = getText(texts, question.questionId + '.' + answer.answerId, language);
                if (translation && translation.text) {
                    if (translation.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                        return true;
                }

                translation = getText(texts, question.questionId + '.' + answer.answerId + ".explanation", language);
                if (translation && translation.text) {
                    if (translation.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                        return true;
                }

                if (answer.dataId && answer.dataId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                    return true;
                if (answer.explanation && answer.explanation.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                    return true;
            }

        }
        if (question.dataId && question.dataId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
        if (question.category && question.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
        if (isArrayValid(question.andFlagConditions)) {
            for (let i = 0; i < question.andFlagConditions.length; ++i) {
                if (question.andFlagConditions[i].toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                    return true;
            }
        }
        if (question.explanation && question.explanation.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
    }
    return false;
}

class QuestionsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dependencies: true,
            tags: [],
            filter: "",
            questions: [],
            orderMode: false,
            showCategories: true,
            showAnswers: false,
            dialog: null,
            showBaseline: false,
            expandAllToggleEnabled: false,
            treeData: null,
            errorState: false,
            expanded: null,
            mode: props.appContext.state.mode,
            language: props.appContext.state.language, //'en-us',
            texts: {},
            plan: null,
            progress: null
        };
        console.log(`questions type view`, this.state.plan)
    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('STORE_UPDATE', this.loadContent.bind(this));
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;
        const { dependencies } = this.state;

        if (appContext.state.plan) {
            const { planId } = appContext.state.plan;
            let questions = appContext.stores.DataTypesStore.getQuestions();
            if (!dependencies)
                questions = questions.filter((e) => e.planId === planId);

            let texts = appContext.stores.DataTypesStore.getTextHash();
            self.setState({ questions, texts, filtered: null }, self.onContentLoaded.bind(self));
        }
    }

    onContentLoaded() {
        const { questions } = this.state;
        this.initializeTreeData();

        if ( questions.length > 0) {        // make sure the questions have actually been loaded..
            // automatically open the question by ID if provided..
            let parts = window.location.pathname.slice(1).split('/');
            if (parts.length > 1) {
                window.history.replaceState(null, '', window.location.href.substring(0, window.location.href.lastIndexOf('/')));
                this.editQuestion(parts[1]);
            }
        }
    }

    showNodeJSON({ node }) {
        let { appContext } = this.props;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={node.question}
                onDone={() => {
                    this.setState({ dialog: null });
                }} />
        });
    }

    captureExpandedState() {
        let { treeData } = this.state;
        this.localExpanded = []
        walk({
            treeData,
            callback: (rowInfo) => {
                if (rowInfo.node.isQuestion) {
                    if (rowInfo.node.expanded) {
                        this.localExpanded.push(rowInfo.node.question.questionId)
                    }
                }
                if (rowInfo.node.isCategory) {
                    if (rowInfo.node.expanded) {
                        this.localExpanded.push(rowInfo.node.nodeId)
                    }
                }

            },
            getNodeKey: ({ treeIndex }) => treeIndex,
            ignoreCollapsed: true,
        });
    }


    initializeTreeData() {
        let self = this;
        let { questions, language, texts, filter, expandAllToggleEnabled,
            showBaseline, showCategories, showAnswers } = this.state;

        self.captureExpandedState();

        let treeData = [];
        let categoryNodes = {};
        if (!language)
            language = 'en-us';
        if (language === 'en-US')
            language = 'en-us';

        questions.sort((a, b) => a.order - b.order);

        for (let i = 0; i < questions.length; i++) {
            let question = questions[i];
            if (question.baseline === undefined) question.baseline = false;
            if (showBaseline !== question.baseline) {
                continue;
            }
            if (!filterQuestion(filter, question, texts, language)) {
                continue;
            }

            let text = isArrayValid(question.question) ? question.question[0].text : '';
            let translation = getText(texts, question.questionId, language);
            if (translation && translation.text)
                text = translation.text;
            text = text.replace(/<\/?[^>]+(>|$)/g, "");     // strip out any HTML from the text

            let expand = (this.localExpanded.includes(question.questionId))
            let subtitle = <span><Plan appContext={this.props.appContext} planId={question.planId} />
                {question.preConditions ? `, CONDITION` : null}
                {question.required ? ', REQUIRED' : null}
                {question.multiSelect ? ', MULTI-SELECT' : null}
                {question.eod ? ', EOD' : null}
                {question.detect ? ', DETECT' : null}
                {question.bail ? ', BAIL' : null}
                {question.dataId ? `, DATA: ${question.dataId}` : null}
                {question.baselineId ? `, BASELINE: ${question.baselineId}` : null}
                {isArrayValid(question.andFlagConditions) ? `, andFlags: ${question.andFlagConditions.join(',')}` : null}
                {isArrayValid(question.orFlagConditions) ? `, orFlags: ${question.orFlagConditions.join(',')}` : null}
                {isArrayValid(question.addFlags) ? `, addFlags: ${question.addFlags.join(',')}` : null}
                {isArrayValid(question.removeFlags) ? `, removeFlags: ${question.removeFlags.join(',')}` : null}
                {isArrayValid(question.bounds) ? ', Bounds' : null}
                {isArrayValid(question.mapping) ? ', Mapping' : null}
            </span>;

            let node = {
                isQuestion: true,
                question: question,
                expanded: (expandAllToggleEnabled ? true : expand),
                isBaseline: question.baseline,
                text,
                subtitle,
                children: []
            };

            if (showAnswers === true) {
                let answers = question.answers;
                for (let j = 0; j < answers.length; j++) {
                    let answer = answers[j];
                    answer.order = j;

                    let category = answer.category
                    if (category) {
                        let translation = getText(texts, `${question.questionId}.${answer.answerId}.category`, language);
                        if (translation)
                            category = translation.text;
                    }
                    let text = answer.text[0];
                    let translation = getText(texts, `${question.questionId}.${answer.answerId}`, language);
                    if (translation)
                        text = translation.text;
                    text = text.replace(/<\/?[^>]+(>|$)/g, "");     // strip out any HTML from the text

                    if (category)
                        text = <span><b>{category + ' / '}</b>{text}</span>;

                    let subtitle = <span>
                        TYPE: {answer.controlType}
                        {answer.preConditions ? `, CONDITION` : null}
                        {answer.detect ? ', DETECT' : null}
                        {answer.bail ? ', BAIL' : null}
                        {answer.dataId ? `, DATA: ${answer.dataId}` : null}
                        {answer.baselineId ? `, BASELINE: ${answer.baselineId}` : null}
                        {isArrayValid(answer.addModels) ? `, addModels: ${answer.addModels.join(',')}` : null}
                        {isArrayValid(answer.addRequiredData) ? `, addRequiredData: ${answer.addRequiredData.join(',')}` : null}
                        {isArrayValid(answer.addOptionalData) ? `, addOptionalData: ${answer.addOptionalData.join(',')}` : null}
                        {isArrayValid(answer.andFlagConditions) ? `, andFlags: ${answer.andFlagConditions.join(',')}` : null}
                        {isArrayValid(answer.orFlagConditions) ? `, orFlags: ${answer.orFlagConditions.join(',')}` : null}
                        {isArrayValid(answer.addFlags) ? `, addFlags: ${answer.addFlags.join(',')}` : null}
                        {isArrayValid(answer.removeFlags) ? `, removeFlags: ${answer.removeFlags.join(',')}` : null}
                        {isArrayValid(answer.bounds) ? ', Bounds' : null}
                        {isArrayValid(answer.mapping) ? ', Mapping' : null}
                    </span>;

                    let child = {
                        isAnswer: true,
                        isQuestion: false,
                        question,
                        answer,
                        text,
                        subtitle
                    };
                    node.children.push(child);
                }
            }

            if (showCategories === true) {
                if (!question.category) {
                    question.category = ''
                }
                if (!categoryNodes[question.category]) {
                    let nodeId = question.category
                    let expand = (this.localExpanded.includes(nodeId))
                    categoryNodes[question.category] = {
                        text: question.category || "Uncategorized",
                        nodeId,
                        isCategory: true,
                        children: [],
                        expanded: expand || expandAllToggleEnabled
                    };

                    treeData.push(categoryNodes[question.category]);
                    treeData.sort((a, b) => { return a.nodeId < b.nodeId ? -1 : a.nodeId > b.nodeId ? 1 : 0 });
                }
                categoryNodes[question.category].children.push(node);
            } else {
                treeData.push(node);
            }
        }
        self.setState({ treeData });
    }


    expandTree() {
        let self = this;
        let { treeData, expandAllToggleEnabled } = self.state;
        const newTreeData = toggleExpandedForAll({
            treeData,
            expanded: expandAllToggleEnabled
        });
        this.setState({
            treeData: newTreeData
        })
    }

    createNewQuestion() {
        let self = this;
        const { language, texts } = this.state;
        const { appContext } = this.props;
        this.setState({
            dialog: <EditQuestionDialog
                texts={texts}
                language={language}
                appContext={appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={() => {
                    self.setState({ dialog: null }, self.loadContent.bind(self));;
                }} />
        });
    }


    editQuestion(questionId) {
        let self = this;
        const { appContext } = this.props;
        let { texts, language } = this.state;
        self.setState({
            dialog: <EditQuestionDialog
                style={{ width: '100%' }}
                questionId={questionId}
                texts={texts}
                language={language}
                appContext={appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={() => {
                    self.setState({ dialog: null }, self.loadContent.bind(self));
                }} />
        });

    }

    canDrop(args) {
        const self = this
        console.log("canDrop:", args);
        let { node, nextParent, prevParent } = args;
        let { showCategories, orderMode } = self.state;

        //categories cant move
        if (node.isCategory) {
            return false
        }

        //While grouping by category
        if (showCategories) {
            // question can move to other categories or order within category
            if (node.isQuestion && nextParent && nextParent.isCategory && (nextParent.nodeId !== prevParent.nodeId || orderMode)) {
                return true
            }
        } else {
            //change order of questions
            if (node.isQuestion && nextParent === null && orderMode) {
                return true
            }
        }

        return false
    };

    getBaselineQuestions(questions) {
        let baseline = [];
        for (let index = 0; index < questions.length; index++) {
            if (questions[index].baseline)
                baseline.push(questions[index]);
        }
        return baseline;
    }

    onChangeTree(treeData) {
        console.log("onChangeTree:", treeData);

        const { orderMode } = this.state;
        let nodes = [...treeData];
        let modified = [];
        let category = '';
        let order = 0;
        let planId = this.props.appContext.state.plan.planId;

        while (nodes.length > 0) {
            let node = nodes.splice(0, 1)[0];
            if (node.isCategory) {
                category = node.text;
                nodes.splice(0, 0, ...node.children);
            }
            else if (node.isQuestion) {
                const question = node.question;
                if (question.planId === planId) {
                    let touched = false;
                    if (orderMode && question.order !== order) {
                        console.log(`Question ${question.questionId} order modified, previous order: ${question.order}, new order: ${order}`);
                        question.order = order;
                        touched = true;
                    }
                    if (category && question.category !== category) {
                        console.log(`Question ${question.questionId} category modified, previous category: ${question.category}, new category: ${category}`);
                        question.category = category;
                        touched = true;
                    }

                    if (touched && !modified.find((e) => e.questionId === question.questionId)) {
                        modified.push(question);
                    }
                }
                order += 1;
            }
        }

        if (modified.length > 0) {
            const self = this;
            const { appContext } = this.props;
            const store = appContext.stores.DataTypesStore;

            console.log("Saving modified questions:", modified);
            this.setState({ treeData, progress: <CircularProgress size={20} /> });

            store.saveQuestions(modified).then(() => {
                self.loadContent();
            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
        else {
            this.setState({ treeData });
        }
    }

    cancel() {
        this.setState({ dialog: null });
    }

    deleteQuestion(question) {
        let { appContext } = this.props;
        let { texts, language } = this.state;
        const self = this;

        self.setState({
            dialog:
                <DeleteQuestionDialog
                    question={question}
                    appContext={appContext}
                    language={language}
                    texts={texts}
                    onCancel={() => {
                        self.setState({ dialog: null });
                    }}
                    onDone={() => {
                        self.setState({ dialog: null }, self.loadContent.bind(self));
                    }}
                />
        });
    }

    clearErrors() {
        const self = this;
        self.setState({
            dialog:
                <Dialog
                    maxWidth={'sm'}
                    fullWidth={true}
                    model="false"
                    open={true}
                >
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to clear all <b>Question Errors</b>?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.cancel(); self.clearErrorsConfirmed() }}>Delete Errors</Button>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.cancel() }}>Cancel</Button>
                    </DialogActions>
                </Dialog>
        });
    }

    clearErrorsConfirmed() {
        const self = this;
        this.setState({ progress: <CircularProgress size={20} /> });
        deleteQuestionErrors(this.props.appContext, '*').then(() => {
            this.setState({ progress: null, errors: [] }, this.loadContent.bind(this));
        }).catch((err) => {
            console.error(err);
            self.setState({ progress: null, error: err.message });
        })
    }

    displayErrors(question) {
        console.log("displayErrors:", question);
        const self = this;
        this.setState({
            dialog: <QuestionErrorsView
                appContext={this.props.appContext}
                questionId={question.questionId}
                onClose={() => { self.setState({ dialog: null }) }}
            />
        });
    }

    nodeHasErrors(node) {
        const { appContext } = this.props;
        const question_errs = appContext.stores.DataTypesStore.getErrors('questions').errors;

        if (node.isCategory) {
            for (let i = 0; i < node.children.length; ++i) {
                if (this.nodeHasErrors(node.children[i])) return true;
            }
        }
        else if (node.isQuestion) {
            if (!this.ensureTranslation(node)) return true;
            if (!this.ensureQuestionAnswerTranslations(node)) return true;
            if (node.question.errors > 0) return true;
            if (question_errs[node.question.questionId]) return true;
        } else if (node.isAnswer) {
            if (!this.ensureTranslation(node)) return true;
        }

        return false;
    }

    getNodeProps({ node, path, treeIndex }) {
        let style = this.nodeHasErrors(node) ? { ...styles.node, boxShadow: `2px 2px 2px 2px #ff6600` } : styles.node;

        return {
            listIndex: 0,
            lowerSiblingCounts: [],
            style,
            title: (
                <div style={{ fontSize: 16, width: 800, border: 0, overflow: 'hidden', fontWeight: 'normal' }}>{node.text}</div>
            ),
            buttons: (this.getNodeButtons(node, path, treeIndex))
        };
    }

    getNodeButtons(node, path, treeIndex) {
        const { language } = this.state;
        let self = this;
        let buttons = [];

        if (node.isQuestion) {
            const question_errs = this.props.appContext.stores.DataTypesStore.getErrors('questions').errors;

            buttons.push(<span key='order' style={{ fontSize: '12px' }}>{node.question.order}</span>);
            if (isArrayValid(node.question.addFlags)) {
                buttons.push(<FlagIcon key='flags' />);
            }

            if (language !== 'en-us') {
                buttons.push(<IconButton key='translate' onClick={this.onTranslateNode.bind(this, node)}><TranslateIcon /></IconButton>);
            }

            buttons.push(<IconButton key='edit' onClick={() => self.editQuestion(node.question.questionId)}><CreateIcon /></IconButton>);
            buttons.push(<IconButton key='delete' disabled={node.question.planId !== this.props.appContext.state.plan.planId}
                onClick={() => { self.deleteQuestion(node.question) }}><DeleteIcon /></IconButton>);
            buttons.push(<IconButton key='code' onClick={() => self.showNodeJSON({ node, path, treeIndex })}><CodeIcon /></IconButton>);
            let errors = question_errs[node.question.questionId];
            if (node.question.errors > 0 || errors)
                buttons.push(<Tooltip key='errors' title={errors ? errors.join(', ') : ''}><IconButton onClick={(e) => { self.displayErrors(node.question) }}><ErrorIcon color="error" /></IconButton></Tooltip>);
        }
        else if (node.isAnswer) {
            let score = node.answer.score;
            let scoreLabel = <FormLabel style={{
                fontSize: 10, display: 'inline-block', color: 'purple', width: 50,
                textAlign: 'right', marginRight: 30, border: 'none'
            }}>
                {score}
            </FormLabel>;
            buttons.push(scoreLabel);

            if (language !== 'en-us') {
                buttons.push(<IconButton key='translate' onClick={this.onTranslateNode.bind(this, node)}><TranslateIcon /></IconButton>);
            }
            if (isArrayValid(node.answer.addFlags)) {
                buttons.push(<FlagIcon />);
            }
        }

        return buttons;
    }

    createTextExport() {
        let { questions, texts, language, filter, showBaseline } = this.state;
        let report = [];
        if (isArrayValid(questions)) {
            questions.sort((a, b) => {
                if (a.questionId < b.questionId)
                    return -1;
                else if (a.questionId > b.questionId)
                    return 1;
                return 0;
            });


            for (let index = 0; index < questions.length; index++) {
                let question = questions[index];
                if (question.baseline !== showBaseline) continue;

                if (!filterQuestion(filter, question)) {
                    continue;
                }
                let textId = question.questionId;
                let text = getText(texts, textId, language);

                let translation = '';
                if (text)
                    translation = text.text;

                // let english = question.question[0];
                let english = getText(texts, question.questionId, 'en-us');
                report.push({ item: report.length, textId, category: 'question', language, english: english.text, text: translation });

                let answers = question.answers;
                if (isArrayValid(answers)) {
                    for (let index = 0; index < answers.length; index++) {
                        let answer = answers[index];
                        textId = question.questionId + '.' + answer.answerId;
                        text = getText(texts, textId, language);
                        translation = '';
                        if (text)
                            translation = text.text;
                        let english = getText(texts, question.questionId + '.' + answer.answerId, 'en-us');
                        report.push({ item: report.length, textId, category: 'answer', language, english: english.text, text: translation });
                    }
                }
            }

        }

        // console.log(`text export report`, report);
        return report;
    }

    ensureQuestionAnswerTranslations(node) {
        let { texts, language } = this.state;
        if (!language)
            language = 'en-us';
        if (language === 'en-US')
            language = 'en-us';
        let allTextValid = true
        if (node.question && node.question.answers) {
            if (isArrayValid(node.question.answers)) {
                node.question.answers.forEach((answer) => {
                    const textId = node.question.questionId + '.' + answer.answerId
                    const text = getText(texts, textId, language)
                    if (!text || text.text === '') {
                        allTextValid = false
                    }
                })
            }
        }
        return allTextValid
    }

    onTranslateNode(node) {
        const self = this;
        let { texts, language } = this.state;
        let dialog =
            <AdHocTranslationDialog
                node={node}
                texts={texts}
                language={language}
                appContext={self.props.appContext}
                onCancel={() => {
                    self.setState({ dialog: null })
                }}
                onDone={() => {
                    self.setState({ dialog: null })
                    self.loadContent()
                }}
            />
        self.setState({ dialog: dialog })
    }

    ensureTranslation(node) {
        let { texts, language } = this.state;
        let textId = node.isQuestion ? node.question.questionId : node.question.questionId + '.' + node.answer.answerId;
        let text = getText(texts, textId, language);
        return (text && text.text)
    }

    testConversation() {
        const { appContext } = this.props;
        if (appContext.state.plan) {
            var dialog = <Drawer variant="persistent" anchor="right" open={true} >
                <ConversationTestView appContext={appContext} planId={appContext.state.plan.planId} onClose={this.cancel.bind(this)} />
            </Drawer>;
            this.setState({ progress: null, dialog: dialog });
        }
    }

    saveAll() {
        const { appContext } = this.props;
        const { questions } = this.state;
        var dialog = <SaveAllQuestionsDialog appContext={appContext} questions={questions} onDone={() => this.setState({ dialog: null })} onCancel={() => this.setState({ dialog: null })} />;
        this.setState({ progress: null, dialog: dialog });
    }

    render() {
        const self = this;
        const { error, filter, dialog } = this.state;
        const { appContext } = this.props;
        const { showBaseline, showCategories, showAnswers, language, dependencies, orderMode, questions } = self.state;
        const { plan } = appContext.state;
        const store = appContext.stores.DataTypesStore;
        let importExport = <ImportExport
            asText={true}
            name={`QuestionContent_${language} `}
            exportStart={() => {
                return Promise.resolve(self.createTextExport());
            }}
            exportRow={(row) => {
            }}
            importStart={() => {
                return Promise.resolve();
            }}
            importRow={(row) => {
                // return self.importText(row);
            }}
            importDone={(err) => {
            }}
        />;

        let sortableTree = self.state.treeData ?
            <div style={{ height: 1000, width: '100%' }} valign='top'>
                <SortableTree
                    treeData={this.state.treeData}
                    maxDepth={maxDepth}
                    canDrop={this.canDrop.bind(this)}
                    onChange={this.onChangeTree.bind(this)}
                    theme={FileExplorerTheme}
                    canNodeHaveChildren={node => !node.isAnswer}
                    isVirtualized={true}
                    generateNodeProps={this.getNodeProps.bind(this)}
                />
            </div> : null;

        const languages = require('../data/Languages.json');

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 400 }} value={filter} label="Filter"
                            onChange={(event) => {
                                self.setState({ filter: event.target.value }, self.initializeTreeData.bind(self))
                            }} />
                        <SelectLanguage appContext={appContext} language={language} languages={languages} onChange={(newLanguage) => {
                            self.setState({ language: newLanguage }, self.initializeTreeData.bind(self));
                        }} />
                    </td>
                    <td width="400"align="right" >
                        {error}
                        <Tooltip title='Allow question order to be changed by drag and drop'>
                            <FormControlLabel label='Order Mode' control={<Switch checked={orderMode} onChange={(e) => {
                                self.setState({ orderMode: e.target.checked });
                            }} />} />
                        </Tooltip>
                        <EditableNote textId={`questionsTooling`} planId={"tooling"} />
                        <IconButton disabled={store.progress !== null} onClick={store.loadDataTypes.bind(store)}>{store.progress || <RefreshIcon title="Reresh" />}</IconButton>
                        <IconButton onClick={this.testConversation.bind(this)}><TestIcon title="Test Conversation" /></IconButton>
                        <IconButton onClick={this.createNewQuestion.bind(this)}><AddIcon /></IconButton>
                        <Tooltip title="Save All"><IconButton disabled={plan && plan.protected} onClick={() => { this.saveAll() }}><SaveIcon /></IconButton></Tooltip>
                    </td>
                    <td width="50" />
                </tr>
                <tr>
                    <td >
                        <FormControlLabel control={<Checkbox onChange={(e) => {
                            self.setState({ expandAllToggleEnabled: e.target.checked }, self.expandTree.bind(self));
                        }} />} label="Expand/Collapse All" />
                        <FormControlLabel control={<Checkbox
                            checked={showBaseline} onChange={(e) => {
                                self.setState({ showBaseline: e.target.checked },
                                    self.initializeTreeData.bind(self));
                            }} />} label="Baseline" />
                        <FormControlLabel control={<Checkbox
                            checked={showCategories} onChange={(e) => {
                                self.setState({ showCategories: e.target.checked }, self.initializeTreeData.bind(self));
                            }} />} label="Show Categories" />
                        <FormControlLabel control={<Checkbox
                            checked={showAnswers} onChange={(e) => {
                                self.setState({ showAnswers: e.target.checked }, self.initializeTreeData.bind(self));
                            }} />} label="Show Answers" />
                        <FormControlLabel control={<Checkbox
                            checked={dependencies} onChange={(e) => {
                                self.setState({ dependencies: e.target.checked }, self.loadContent.bind(self));
                            }} />} label="Show Dependencies" />
                    </td>
                    <td align="right" style={{ marginRight: 150 }}>
                        Total Questions: {questions && questions.length}
                    </td>
                    <td width="50" />
                </tr>
                <tr>
                    <td colSpan="3">
                        {sortableTree}
                        {dialog}
                    </td>
                </tr>
                <tr>
                    <td>
                    </td>
                    <td align="right">
                        <div style={styles.footerTools}>
                            {importExport}
                            <Tooltip title="Delete Errors" >
                                <IconButton aria-label="" onClick={() => { self.clearErrors() }}>
                                    <DeleteSweepIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            </tbody></table>
        );
    }
}



const styles = {
    div: {
        margin: 10
    },
    footerTools: {
        display: 'flex'
    },
    progress: {
        color: 'red'
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
    },
    sidebarHeader: {
        display: 'flex',
        padding: '0 8px',
        justifyContent: 'left'
    },
    node: {
        height: 50,
        width: 1050,
        border: 0,
        cursor: 'pointer'
    }
};


QuestionsView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(QuestionsView);
