import React, { Component } from "react";
import PubSub from 'pubsub-js';

import {
    CircularProgress,
    IconButton
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import {
    loadQuestions,
    loadText
} from '@apricityhealth/web-common-lib/utils/Services';

import {
    isArrayValid,
    getByTextIdByLanguage,
} from '../utils/Utils';

import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';

class QuestionsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            progress: null,
            language: 'en-us'
        };
    }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.loadContent();
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;

        self.setState({ progress: <CircularProgress size={20} />, error: null });

        let promises = [];
        promises.push(loadQuestions(appContext, { dependencies: true }));
        promises.push(loadText(appContext, { dependencies: true } ));
        Promise.all(promises).then((response) => {
            let questions = response[0];
            let texts = response[1];
            self.setState({ questions, texts, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });

    }

    render() {
        let { questions, progress, error, texts, language } = this.state;
        let { appContext } = this.props;

        const languages = require('../data/Languages.json');
        let languageSelector = <SelectLanguage appContext={appContext} language={language} languages={languages} onChange={(newLanguage) => {
            this.setState({ language: newLanguage }, this.loadContent.bind(this));
        }} />;

        return <table style={{width: '100%'}}><tbody>
            <tr>
                <td>
                    {languageSelector}
                </td>
                <td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    {this.getQuestionsReport(questions, texts, language)}
                </td>
            </tr>
        </tbody>
        </table>;
    }

    getQuestionsReport(question, texts, language, questionNumber = 0) {
        let report = [];
        if (isArrayValid(question)) {
            let baselines = [];
            let normal = [];
            for (let j = 0; j < question.length; j++) {
                if (question[j].baseline)
                    baselines.push(question[j]);
                else
                    normal.push(question[j]);
            }
            report.push(<div key={report.length + questionNumber} className='group_name'>Question pool:</div>);
            normal.sort((a, b) => {
                if (a.order < b.order)
                    return -1;
                else if (a.order > b.order)
                    return 1;
                return 0;
            });
            for (let j = 0; j < normal.length; j++) {
                let nextQuestion = normal[j];
                report.push(this.getQuestionsReport(nextQuestion, texts, language, j + questionNumber));
            }
            report.push(<div key={report.length + questionNumber} className='group_name'>Baseline questions:</div>);
            baselines.sort((a, b) => {
                if (a.order < b.order)
                    return -1;
                else if (a.order > b.order)
                    return 1;
                return 0;
            });
            for (let j = 0; j < baselines.length; j++) {
                let nextQuestion = baselines[j];
                report.push(this.getQuestionsReport(nextQuestion, texts, language, j + questionNumber));
            }
            return report;
        }
        let questionList = [];

        if (isArrayValid(question.question)) {
            questionList.push(<div className='question_id'>[{question.questionId}]</div>);
            let translation = getByTextIdByLanguage(texts, question.questionId, language);
            if (!translation)
                translation = getByTextIdByLanguage(texts, question.questionId);

            if (translation)
                translation = translation.text
            questionList.push(<div className='question'>{questionNumber}. <span dangerouslySetInnerHTML={{ __html: translation }} /></div>);
        }
        if (isArrayValid(question.answers)) {

            let listItems = [];
            for (let m = 0; m < question.answers.length; m++) {
                let answer = question.answers[m];
                let translation = getByTextIdByLanguage(texts, question.questionId + '.' + answer.answerId, language);
                if (!translation)
                    translation = getByTextIdByLanguage(texts, question.questionId);
                if (translation)
                    translation = translation.text
                if (answer.score)
                    translation = `${translation} : [${answer.score}]`

                listItems.push(<li key={listItems.length}><span dangerouslySetInnerHTML={{ __html: translation }} /></li>);
            }
            questionList.push(<ul className='answer_list'>{listItems}</ul>);
        }
        return questionList;
    }
}


export default QuestionsReport;
