import React from "react";
import Moment from 'moment';

import {
    Typography,
    List,
    CircularProgress,
} from '@material-ui/core/';

import { isArrayValid } from '../utils/Utils';
import User from "@apricityhealth/web-common-lib/components/User";

class ContentActivityView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            error: null,
            dialog: null,
            view: null,
            plan: null
        };
    }

    componentDidUpdate() {
        console.log("ContentActivityView.componentDidUpdate")
    }

    generateActivityView(activity) {
        if ( activity === null) {
            return <div align='center'><CircularProgress /></div>
        }
        const { appContext } = this.props;

        if (isArrayValid(activity)) {
            let rows = [];
            activity.forEach((k) => {
                rows.push(
                    <div key={k._id}>
                        <Typography style={{ marginTop: '2px' }} align='left' variant="subtitle1" color="inherit" >
                            Author: <User appContext={appContext} userId={k.userId} userIdHidden={true} />
                        </Typography>
                        <Typography style={{ marginTop: '2px' }} align='left' variant="subtitle2" color="secondary" >
                            Date: {(k.updatedAt !== null && k.updatedAt !== '') ? Moment(k.updatedAt).local().format("MM-DD-YYYY HH:mm") : ''}
                        </Typography>
                        <Typography style={{ marginTop: '2px' }} align='left' variant="subtitle2" color="secondary" >
                            Type: {k.activityType}
                        </Typography>
                        <pre>{k.comment}</pre>
                        <div style={{ marginTop: '10px' }} />
                    </div>);
            });

            return <div>
                <Typography margintop='20px' align='left' variant="h6" color="inherit" >
                    {activity.length} updates
                </Typography>
                <List
                    style={{ marginTop: '10px' }}
                    height={1000}
                    width={'90%'}
                >
                    {rows}
                </List>
            </div>;
        } else {
            return <div><h2>No activity.</h2></div>
        }
    }

    render() {
        const { parent: { state: { activity }}} = this.props;
        return <div>
            {this.generateActivityView( activity )}
        </div>
    }
}

export default ContentActivityView;

