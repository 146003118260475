import React from "react";

import {
    CircularProgress,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    TextField,
    Tooltip
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';

import {
    isArrayValid
} from '../utils/Utils';

import {
    loadTypes,
    saveType,
    deleteType
} from '@apricityhealth/web-common-lib/utils/Services';

import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import SaveToPlanDialog from "../dialogs/SaveToPlanDialog";
import ChangedByButton from "../components/ChangedByButton";

class TypeView extends React.Component {
    constructor(props) {
        super(props);

        if ( typeof props.onClose !== 'function' ) throw new Error("onClose property missing!");
        if (! props.typePath ) throw new Error("typePath property missing!");
        if (! props.primaryKey ) throw new Error("primaryKey property missing!");
        if (! props.typeFields || !Array.isArray(props.typeFields) ) console.warn("typeFields property is missing!");        // should be there, but not an error if they don't have it
        if (! props.title ) console.warn("title property is missing!");
        if (! props.orderBy ) console.warn("orderBy property is missing!");

        this.state = {
            typeId: props.typeId || '',
            type: {},
            editControls: [],
            dialog: null,
            modified: false
        };
    }

    componentDidMount() {
        this.loadType();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.typeId !== this.props.typeId) {
            this.setState({ typeId: this.props.typeId, type: {}}, this.loadType.bind(this));
        }
    }

    onCloseView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else {
            this.props.onClose();
        }
    }
    
    onCloseDialog() {
        this.setState({dialog: null});
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Type Modified!</DialogTitle>
            <DialogContent>This type has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveType(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    loadType() {
        const { typeId, modified } = this.state;
        const { appContext, typePath } = this.props;

        console.log("loadType:", typeId);
        if (modified === true) {
            return this.displayModifiedDialog(this.loadType.bind(this));
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        (typeId ? loadTypes(appContext, { typeId, typePath }) : Promise.resolve()).then((types) => {
            let type = null;
            if (!isArrayValid(types)) {
                type = this.createNewType();
            } else {
                type = types[0]
            }
            this.setState({
                type,
                dialog: null,
                modified: false,
                progress: null
            }, this.createEditControls.bind(this));
        }).catch((err) => {
            this.setState({ dialog: null, progress: null, error: getErrorMessage(err) });
        });
    }

    createNewType() {
        const { typeFields, primaryKey } = this.props;
        const newType = {};
        newType[primaryKey] = '';
        if ( typeFields && Array.isArray(typeFields)) {
            for(let i=0;i<typeFields.length;++i) {
                const field = typeFields[i];
                if (! field.id ) continue;
                if ( newType[field.id] === undefined ) newType[field.id] = field.defaultValue || '';
            }
        }
        return newType;
    }

    createEditControls(callback) {
        const { typeFields } = this.props;
        const { type } = this.state;

        const editControls = [];
        const fields = (typeFields || []).filter((e) => e.editField);
        for(let i=0;i<fields.length;++i) {
            const field = fields[i];
            switch (field.editType) {
                case 'text':
                    editControls.push(<TextField key={i} style={{ margin: 5, width: (field.width || 500) }} label={field.label} value={type[field.id]} onChange={(e) => {
                        type[field.id] = e.target.value;
                        this.setState({ type, modified: true }, this.createEditControls.bind(this));
                    }} />);
                    editControls.push(<br key={`br${i}`} />);
                    break;
                case 'number':
                    editControls.push(<TextField key={i} type='number' style={{ margin: 5, width: (field.width || 500) }} label={field.label} value={type[field.id]} onChange={(e) => {
                        type[field.id] = e.target.value;
                        this.setState({ type, modified: true }, this.createEditControls.bind(this));
                    }} />);
                    editControls.push(<br key={`br${i}`} />);
                    break;
                default:
                    editControls.push(<div key={i} style={{ margin: 5 }}><span>{field.label}: {type[field.id]}</span></div>);
                    break;
            }
        }
        console.log("createEditControls:", editControls);
        this.setState({editControls}, callback);
    }

    saveType(callback, planId = null) {
        const { appContext, typePath } = this.props;
        const { type } = this.state;

        if (!planId && type.planId && type.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(type.planId, this.saveType.bind(this, callback));
        }

        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        console.log("saveType:", type, planId );
        saveType(appContext, type, { planId, typePath } ).then((type) => {
            this.setState({
                type,
                modified: false,
                progress: null
            }, this.createEditControls.bind(this,callback));
        }).catch((err) => {
            this.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    saveToPlan(callback) {
        const { typePath, primaryKey } = this.props;
        const { type } = this.state;
        let dialog = <SaveToPlanDialog appContext={this.props.appContext} planId={type.planId} onCancel={this.onCloseDialog.bind(this)}
            onDone={({plan, move}) => {
                console.log("plan:", plan, ", move: ", move );
                let deleteType = { ...type, typePath, typeId: type[primaryKey] };
                this.saveType(() => {
                    if ( move ) {
                        deleteType(this.props.appContext, deleteType).then(() => {
                            this.onCloseView();
                        }).catch((err) => {
                            this.setState({ error: getErrorMessage(err)}, callback );                            
                        });
                    } else {
                        callback();
                    }
                }, plan.planId );
            }} />;
        this.setState({dialog});
    }

    deleteType() {
        const { appContext, typePath, primaryKey } = this.props;
        const { type } = this.state;

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Delete this type, are you sure?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    this.setState({ progress: <CircularProgress size={20} />, error: null });
                    deleteType(appContext, {...type, typePath, typeId: type[primaryKey] } ).then(() => {
                        this.setState({ progress: null, dialog: null }, this.onCloseView.bind(this));
                    }).catch((err) => {
                        this.setState({ progress: null, dialog: null, error: getErrorMessage(err) });
                    })
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    this.setState({ progress: null, dialog: null });
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    onShowCode() {
        const { type } = this.state;
        const { appContext } = this.props;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={type}
                onEditDone={(type) => {
                    this.setState({type, dialog: null});
                }}
                onDone={this.onCloseDialog.bind(this)} />
        });
    }

    render() {
        const { appContext, primaryKey, collection } = this.props;
        const { type, dialog, progress, error, editControls } = this.state;

        return <div align="center" style={{minWidth: 600}}>
        <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={this.onCloseView.bind(this)}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="h6" color="inherit">Edit Type</Typography>
            </Toolbar>
        </AppBar>
        <table style={{ width: '100%', margin: 5 }}>
            <tbody>
                <tr>
                    <td>
                    </td>
                    <td align='right' valign='top'>
                        {progress}
                        <Tooltip title="Save"><IconButton disabled={progress !== null} onClick={this.saveType.bind(this, null, null)}><SaveIcon /></IconButton></Tooltip>
                        <Tooltip title='Code'><IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton></Tooltip>
                        <Tooltip title='Delete'><IconButton disabled={!type[primaryKey] || progress !== null} onClick={this.deleteType.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                        <br />
                        <span style={{color: 'red', maxWidth: 300}}>{error}</span>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        {editControls}
                        <br />
                        {collection ? <ChangedByButton appContext={appContext} primaryKey={type[primaryKey]} collection={collection} /> : null}
                    </td>
                </tr>
            </tbody>
        </table>
        {dialog}
    </div>;

    }
}

const styles = {
    warningIcon: {
        marginRight: 10
    },
    recWarning: {
        marginLeft: 15,
        marginRight: 5,
        marginTop: 13
    },
    copy: {
        color: 'white',
        backgroundColor: 'tomato',
        marginLeft: 15,
        marginRight: 5,
        marginTop: 13
    },
    treeWrap: {
        height: 'calc(100vh - 265px)',
        width: '100%',
        position: 'absolute'
    },
    div: {
        margin: 10
    },
    description: {
        margin: 5,
        width: 500
    },
    footer: {
        bottom: 0,
        position: 'absolute',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    name: {
        margin: 5,
        width: 300
    },
    weight: {
        margin: 5,
        width: 50
    },
    dialogItem: {
        width: '20%',
        display: 'inline-block',
        textAlign: 'left'
    },
};

export default TypeView;
