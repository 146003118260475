import React, { Component } from "react";
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import EducationItemView from './EducationItemView';

import {
    Drawer,
    IconButton,
    CircularProgress,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import {
    loadEducation,
    saveEducation
} from '@apricityhealth/web-common-lib/utils/Services';

import PubSub from 'pubsub-js'

import RefreshIcon from '@material-ui/icons/Refresh';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';
import ImportExport from '@apricityhealth/web-common-lib/components/ImportExport';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';

class EducationView extends Component {
    //get video manifest
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            dependencies: true,
            language: '*'
        };
    }

    componentDidMount() {
        const self = this;
        this.loadContent();
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;
        const { language } = this.state;

        self.setState({ progress: <CircularProgress size={20} />, error: null });

        loadEducation(appContext,{ educationId: '*', dependencies: this.state.dependencies, language } ).then((education) => {
            self.setState({education, progress: null}, this.handleOpen.bind(this));
        }).catch((err) => {
            self.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    handleOpen() {
        // automatically open the education content if on the path
        let parts = window.location.pathname.slice(1).split('/');
        if ( parts.length > 1) {
            window.history.replaceState( null, '',  window.location.href.substring( 0, window.location.href.lastIndexOf('/') ));

            const education = this.state.education.find((e) => e.educationId === parts[1]);
            if (education ) {
                let dialog = <Drawer variant="persistent" anchor="right" open={true} >
                    <EducationItemView
                        appContext={this.props.appContext}
                        education={education}
                        onDone={this.onCloseDialog.bind(this)}
                    />
                </Drawer>;
                this.setState({ dialog });
            }
        }
    }

    onCloseDialog() {
        this.setState({dialog: null}, this.loadContent.bind(this));
        if (this._table)
            this._table.setState({ selected: [] });        // unselect the question
    }

    onCreate() {
        console.log("onCreate");
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <EducationItemView
                appContext={this.props.appContext}
                education={null}
                onDone={this.onCloseDialog.bind(this)}
            />
        </Drawer>;
        this.setState({ dialog });
    }

    onEdit(selected) {
        if ( selected.length > 0 ) {
            const education = this.state.education[selected[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true} >
                <EducationItemView
                    appContext={this.props.appContext}
                    education={education}
                    onDone={this.onCloseDialog.bind(this)}
                />
            </Drawer>;
            this.setState({ dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    importEducation(rows) {
        console.log("importEducation:", rows );
        const { appContext } = this.props;
        const { planId } = appContext.state.plan;

        const { language } = this.state;
        if ( language !== '*') {
            for(let i=0;i<rows.length;++i) {
                let row = rows[i];
                row.language = language;
            }
        }

        this.setState({progress: <CircularProgress size={20} />, error: null});
        saveEducation(appContext, rows, planId).then(() => {
            this.setState({progress: null}, this.loadContent.bind(this) );
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    render() {
        let { error, progress, education, dialog, language, dependencies } = this.state;

        const columnData = [
            { id: 'title', numeric: false, disabledPadding: false, label: 'Title' },
            { id: 'order', numeric: true, disabledPadding: false, label: 'Order' },
            { id: 'type', numeric: false, disabledPadding: false, label: 'Education Type' },
            { id: 'educationId', numeric: false, disabledPadding: false, label: 'ID' },
            { id: 'language', label: 'Language '},
            { id: 'educationView', label: 'Education View', formatValue: (v) => toBoolean(v) ? "Yes" : "No" },
            { id: 'tutorial', label: 'Tutorial', formatValue: (v) => toBoolean(v) ? "Yes" : "No"},
            {
                id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                    return <Plan appContext={this.props.appContext} planId={v} />
                }
            }
        ];

        return (
            <table width='100%'><tbody>
                <tr>
                    <td>
                        <SelectLanguage enableAll={true} value={language} onChange={(language) => {
                            this.setState({language }, this.loadContent.bind(this));
                        }} />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={dependencies} onChange={(e, v) => {
                            this.setState({ dependencies: v }, this.loadContent.bind(this));
                        }} />} label="Show Dependencies" />
                    </td>
                    <td align="right">
                        <span style={{ color: 'red' }}>{error}</span>
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                        <ImportExport
                            toolTip="Education Content"
                            name={`education`}
                            asText={true}
                            exportStart={() => {
                                return Promise.resolve(education);
                            }}
                            exportRow={(row) => {
                                delete row.planId;
                                delete row.updatedAt;
                                delete row.createdAt;
                                delete row._id;
                                delete row.__v;
                                delete row._index;
                            }}
                            importStart={() => {
                                this.setState({ progress: <CircularProgress size={20} /> });
                                return Promise.resolve();
                            }}
                            importRows={(rows) => {
                                return this.importEducation(rows);
                            }}
                            importRow={() => { }}
                            importDone={() => {
                                this.setState({
                                    progress: null
                                });
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => this._table = table}
                            orderBy='order'
                            onAdd={this.onCreate.bind(this)}
                            onSelected={this.onEdit.bind(this)}
                            columnData={columnData}
                            data={education}
                            title='Education Content'
                        />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

export default EducationView



