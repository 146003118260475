import React from 'react';
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core/';

class EditJsonDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jsonText: JSON.stringify(this.props.json || {}, null, 4),
            error: null
        }
    }

    onEditDone() {
        let { jsonText } = this.state;

        // remove whitepsace and new lines
        if (jsonText) {
            jsonText = jsonText.replace(/(\r\n|\n|\r)/gm, "");
            jsonText = jsonText.replace(/\s+/g, " ");
        }

        try {
            // if it crashes here, someone is calling this dialog without an onDone function property..
            this.props.onDone(JSON.parse(jsonText));
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    render() {
        const { error, jsonText } = this.state;

        return <Dialog maxWidth={'md'} fullWidth={true} open={true}>
            <DialogContent>
                <div style={{ marginTop: '10px', minWidth: '400px' }} />
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    minRows={20}
                    spellCheck="false"
                    onChange={(e) => {
                        try {
                            JSON.parse(e.target.value);
                            this.setState({ jsonText: e.target.value, error: null });
                        } catch (err) {
                            this.setState({ jsonText: e.target.value, error: err.message });
                        }
                    }}
                    style={{ width: '98%' }}
                    value={jsonText}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <span style={{ color: 'red' }}>{error}</span>
                <Button variant='contained' style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
                <Button disabled={error !== null} variant="contained" style={styles.button} onClick={this.onEditDone.bind(this)}>Save</Button>
            </DialogActions>

        </Dialog>;
    }
}

const styles = {
    button: {
        margin: 5
    },
    jsonPaper: {
        margin: 5,
        width: '98%',
        borderRadius: 6
    }
}
export default EditJsonDialog;