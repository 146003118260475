import React, { Component } from 'react';
import { 
    Drawer, 
    CircularProgress, 
    TextField, 
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip
 } from '@material-ui/core/';
 
import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import ValidIcon from '@material-ui/icons/CheckCircle';

import DetectModelView from './DetectModelView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import { loadDetectModels , loadModels} from '@apricityhealth/web-common-lib/utils/Services';
import EditableNote from '../components/EditableNote'
import PubSub from 'pubsub-js'
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';

function testFilter(filter, model) {
    if (model) {
        filter = filter.toLowerCase();
        if (model.name && model.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.modelId && model.modelId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.description && model.description.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class DetectModelsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            types: [],
            conditionTypes: []
        }
    }

    componentDidMount() {
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.category !== this.props.category) {
            this.loadContent();
        } else {
            this.handleOpen();
        }
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        let { appContext, category} = this.props
        self.setState({ progress: <CircularProgress size={20} />, error: null });

        let promises = [];
        promises.push(loadDetectModels(appContext, { dependencies: this.state.dependencies, category } ))
        promises.push(loadModels(appContext, { dependencies: this.state.dependencies} ))

        Promise.all(promises).then((responses) => {
            self.setState( {types:responses[0], conditionTypes:responses[1], progress: null}, self.onContentLoaded.bind(self))
        }).catch((err) => {
            console.log(err)
            self.setState( { types: [], progress: null, error: err.message })
        });
    }
    
    onContentLoaded() {
        this.updateFilter();
        this.handleOpen();
    }

    handleOpen() {
        // automatically open the question by ID if provided..
        let parts = window.location.pathname.slice(1).split('/');
        if ( parts.length > 1) {
            window.history.replaceState( null, '',  window.location.href.substring( 0, window.location.href.lastIndexOf('/') ));
            this.onOpenModel( parts[1] );
        }
    }

    updateFilter() {
        let { types, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < types.length; ++i) {
                if (testFilter(filter, types[i]))
                    filtered.push(types[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    typeSelected(s, t) {
        console.log("typeSelected:", s, t);
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const type = this.state.filtered ? this.state.filtered[s[0]] : this.state.types[s[0]];
            this.onOpenModel( type.modelId );
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    onOpenModel(modelId) {
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <DetectModelView appContext={this.props.appContext} modelId={modelId} conditionTypes={this.state.conditionTypes}
                onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    createType() {
        console.log("createType");
        const { appContext, category } = this.props;
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <DetectModelView appContext={appContext} modelId='' category={category} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog: dialog });
    }
    render() {
        const self = this;
        const { filtered, types, dialog, error, progress, dependencies } = this.state;
        let { filter } = this.state;
        let store = this.props.appContext.stores.DataTypesStore;
        let errors = store.getErrors(`${this.props.category}_detect`).errors;

        const columnData = [
            { id: 'modelId', width: 25, label: 'Valid', formatValue: (v) => {
                return errors[v] ? <Tooltip key='error' title={errors[v].join(', ')}><WarningIcon style={{ fill: 'red'}} /></Tooltip> : <ValidIcon key='valid' style={{ fill: 'green'}} />;
            }},
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'modelId', numeric: false, disabledPadding: false, label: 'Model ID' },
            { id: 'order', numeric: true, disabledPadding: false, label: 'Order' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } },
            { id: 'inactive', label: 'Inactive', formatValue: (v) => toBoolean(v) ? "Yes" : "No" }
        ];

        return (
            <table width='100%'><tbody>
            <tr>
                <td>
                    <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                        onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                    <FormControlLabel
                        style={{margin: 5}}
                        control={<Checkbox
                            checked={dependencies}
                            onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self ));
                            }}  
                        />}
                        label="Show Dependencies" 
                    />
                </td>
                <td align="right">
                        {error}
                        <EditableNote textId={`detectModelsTooling`} planId={"tooling"} />
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <EnhancedTable
                        title=''
                        disableMultiSelect={true}
                        onTable={table => self._types = table}
                        onSelected={this.typeSelected.bind(this)}
                        onAdd={this.createType.bind(this)}
                        orderBy='name'
                        columnData={columnData}
                        data={filtered ? filtered : types} />
                    {dialog}
                </td>
            </tr>
            </tbody></table> 
        );
    }
}

export default DetectModelsView;
