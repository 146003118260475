import {
    Tooltip, Badge
} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles';

export const ErrorBadge = withStyles({
    root: {
        backgroundColor: '#E25241',
        justifyContent: 'center',
        color: 'white',
        borderRadius: '10px',
        fontSize: 14,
        padding: '0 6px',
        height: '20px',
        minWidth: '20px',
    }
})(Badge);

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);