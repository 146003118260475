import { v4 as uuidv4 } from 'uuid';

import { parseCode } from '@apricityhealth/web-common-lib/utils/ParseCode';
import { loadQuestions, conversation, addPatient, deletePatient } from '@apricityhealth/web-common-lib/utils/Services';
import { isArrayValid } from '../utils/Utils';

const CONCURRENT_CONVERSATIONS = 10;     // number of conversations to be having at once 

export class DialogTester {
    constructor(appContext) {
        this.appContext = appContext;
    }

    permutations = [];
    permutationNum = 0;
    errors = [];
    activeConversations = 0;
    completedConversations = 0;
    conversationPromises = [];
    dataTypes = [];
    abort = true;

    numberOfPermutations(questions) {
        function calculatePermuations(question, permuations) {
            let count = question.answers.length;
            if (permuations === 0)
                permuations = count;
            else
                permuations *= count;
            return permuations;
        }

        let permuations = 0;
        for (let i = 0; i < questions.length; ++i)
            permuations = calculatePermuations(questions[i], permuations);

        return permuations;
    }

    cancelGeneratePermuations() {
        if ( this.abort === false ) {
            this.abort = true;
        }
    }

    generatePermuations( progressCallback ) {
        if ( this.abort !== true ) {
            throw new Error("Already generating permuations");
        }
        const self = this;
        this.permutations = [];
        this.permutationNum = 0;
        this.errors = [];
        this.activeConversations = 0;
        this.completedConversations = 0;
        this.conversationPromises = [];
        this.dataTypes = [];
        this.abort = false;
        this.progressCallback = progressCallback;

        return new Promise((resolve, reject) => {
            loadQuestions(this.appContext, true).then((questions) => {
                let numPermuations = this.numberOfPermutations(questions);
                console.log(`Calculated ${numPermuations} possible permuations for ${questions.length} questions.`);
                //log.info("getQuestions:", result.questions );
                for (let i = 0; i < questions.length; ++i) {
                    let question = questions[i];
                    if (question.preConditions)
                        this.getPreConditionDataTypes(parseCode(question.preConditions));
                    for (let j = 0; j < question.answers.length; ++j) {
                        let answer = question.answers[j];
                        if (answer.preConditions)
                            this.getPreConditionDataTypes(parseCode(answer.preConditions));
                    }
                }

                let perm = { id: this.permutationNum, journals: [], patientId: null };
                self.permutations.push(perm);
                return self.runConversations();
            }).then(() => {
                self.abort = true;
                resolve(self.permutations);
            }).catch((error) => {
                console.error("generatePermuations error:", error);
                self.abort = true;
                reject(error);
            });
        });
    }

    runConversations() {
        const self = this;
        return new Promise((resolve, reject) => {
            this.timer = setInterval(() => {
                if (this.abort === true) {
                    clearInterval(this.timer);
                    return resolve();
                }
                if (self.startNextPermuation() === false) {
                    console.log(`Waiting for ${self.conversationPromises.length} conversation promises.`);
                    Promise.all(self.conversationPromises).then(() => {
                        clearInterval(this.timer);
                        resolve();
                    }).catch((err) => {
                        console.error("runConversations error:", err);
                        reject(err);
                    })
                }
            }, 1000);
        })
    }

    startNextPermuation() {
        if (this.activeConversations < CONCURRENT_CONVERSATIONS) {
            console.log(`startNextPermuation ${this.activeConversations} active conversations of ${CONCURRENT_CONVERSATIONS}, ${this.permutations.length} permuations.`);
            // find the next permuation to run..
            for (let i = 0; i < this.permutations.length; ++i) {
                let permutation = this.permutations[i];
                if (permutation.active === true) continue;
                if (permutation.complete === true) continue;
                if (permutation.error === true) continue;
                this.conversationPromises.push(this.startConversation(permutation));
                return true;
            }

            //log.info("permutations:", permutations );
            if (this.activeConversations === 0)
                return false;       // we are done
        }

        return true;
    }

    startConversation(permutation) {
        const self = this;
        console.log(`startConversation:`, permutation);

        permutation.active = true;      // set the active flag to true on the permuation while it's working
        this.activeConversations += 1;
        return new Promise((resolve, reject) => {
            let dialog = { baseline: false, reset: true, verify: false, flags: [], answers: [] };
            self.createTestPatient().then((patient) => {
                //use a new patient for this new conversation
                permutation.patientId = patient.patientId;

                //get the first question
                return self.lambdaConverse(permutation.patientId, dialog);
            }).then((dialog) => {
                //ok get the next question from the starrt of the conversation
                let question = dialog.question;

                //this is trouble..the reset did not return the first question
                if (!question) {
                    throw new Error(`Dialog totally failed and full stotp.. not even getting the first question`);
                }

                // ok.. lets roll.. ! .. 
                return self.nextQuestion(dialog, permutation, question);

            }).then(() => {
                return self.deletePatient(permutation.patientId);
            }).then(() => {
                self.activeConversations -= 1;
                self.completedConversations += 1;
                permutation.complete = true;
                permutation.active = false;

                let percentDone = ((self.completedConversations * 100) / self.permutations.length).toFixed(1);
                console.log(`end conversation for `, permutation.id);
                if ( self.progressCallback )
                    self.progressCallback( `Permuatation ${self.completedConversations} of ${self.permutations.length} done [${percentDone}%]` );

                resolve()
            }).catch((err) => {
                self.activeConversations -= 1;
                permutation.error = true;           // flag this permutation for the eror
                permutation.active = false;         // set it to in-active

                console.error("startConversation error:", err);
                self.errors.push({ permutation, error: err });

                self.deletePatient(permutation.patientId).then(() => {
                    reject(err);
                }).catch((err2) => {
                    console.error("deletePatient error:", err2);
                    reject(err);
                })
            });
        });
    }

    converse(permutation, dialog) {
        let self = this;
        return new Promise((resolve, reject) => {

            self.lambdaConverse(permutation.patientId, dialog).then((dialog) => {
                let question = dialog.question;

                //this handles end of a conversation and we get an alert flag.
                if (!question || question.eod) {
                    //add the alert to the journal so we can draw the alert nodes
                    permutation.journals.push(
                        {
                            questionId: dialog.flags[0],
                            text: dialog.flags,

                        })
                    return resolve();
                }

                //ok.. lets roll!! . 
                return self.nextQuestion(dialog, permutation, question);

            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    getPreConditionDataTypes(parsedCode) {
        //log.info("getPreConditionDataTypes:", parsedCode );
        for (let i = 0; i < parsedCode.functions.length; ++i) {
            let func = parsedCode.functions[i];
            if (func.functionName === 'isDataInRange') {
                let dataId = func.args[1].string;
                if (this.dataTypes.indexOf(dataId) < 0) {
                    console.log(`preConditionDataTypes.push(${dataId})`)
                    this.dataTypes.push(dataId);
                }
            }
            else if (func.functionName === 'isDataValue') {
                let dataId = func.args[1].string;
                if (this.dataTypes.indexOf(dataId) < 0) {
                    console.log(`preConditionDataTypes.push(${dataId})`)
                    this.dataTypes.push(dataId);
                }
            }
            else {
                console.warn("Unknown function type:", func);
            }
        }
        for (let i = 0; i < parsedCode.groups.length; ++i) {
            this.getPreConditionDataTypes(parsedCode.groups[i]);
        }
    }

    nextQuestion(dialog, permutation, question) {
        let permuationCopy = JSON.parse(JSON.stringify(permutation));

        if (this.abort === true) {
            return Promise.resolve(); //throw new Error("DialogTester Aborted.");
        }

        //check on a prior answer and if so just use it and move on.. depth first until we hit a question not prior answered 
        //we have stored all the prior answers so just keep going
        let journal = this.findQuestion(permutation.journals, question.questionId);
        if (journal) {
            //we have an answer in this current permutation already to just keep going depth first
            dialog.answers = [];
            dialog.answers.push({ answerId: journal.answerId, text: journal.text[0] });
            return this.converse(permutation, dialog);
        } else {
            let answers = question.answers;
            //so this question has not been answer so we need to store each anser in a new permutation and then start over
            for (let index = 0; index < answers.length; index++) {
                let answer = answers[index];

                // log.debug(`Adding answer ${answer.text[0]} for  ${question.questionId}`)
                if (index === 0) {
                    //add answer 0 the 'current' permutation. and just keep going using the same permutation. Always do answer zero on the current one
                    permutation.journals.push(
                        {
                            questionId: question.questionId,
                            text: answer.text,
                            answerId: answer.answerId
                        })
                    dialog.answers = [];
                    dialog.answers.push({ answerId: answer.answerId, text: answer.text[0] });
                }
                else if (isArrayValid(answer.addModels) ||
                    isArrayValid(answer.addRequiredData) ||
                    isArrayValid(answer.addOptionalData) ||
                    isArrayValid(answer.removeFlags) ||
                    isArrayValid(answer.addFlags) ||
                    answer.detect === true ||
                    this.dataTypes.indexOf(answer.dataId) >= 0 ||
                    this.dataTypes.indexOf(question.dataId) >= 0) {

                    // ok.. so we are going down this permutaion and have hit a split.
                    // so we need to clone this permutation and store the ew answer since that is a new path
                    // remember answer '0' is alraedy being handled as the existing permutation

                    this.permutationNum += 1;
                    let newPermutation = JSON.parse(JSON.stringify(permuationCopy));

                    newPermutation.active = false;
                    newPermutation.id = this.permutationNum;
                    newPermutation.journals.push(
                        {
                            questionId: question.questionId,
                            text: answer.text,
                            answerId: answer.answerId
                        })
                    this.permutations.push(newPermutation);
                    console.log(`${this.permutations.length} permuations added.`)
                }
            }

            return this.converse(permutation, dialog);
        }
    }

    lambdaConverse(patientId, dialog) {
        return new Promise((resolve, reject) => {
            conversation(this.appContext, patientId, dialog).then((result) => {
                return resolve(result);
            }).catch((err) => {
                console.error("lambdaConverse error:", err);
                reject(err);
            });
        });
    }

    createTestPatient() {
        return new Promise((resolve, reject) => {
            addPatient(this.appContext, { firstName: "Test", lastName: uuidv4() } ).then((patient) => {
                resolve(patient);
            }).catch((error) => {
                console.error("createTestPatient error:", error);
                reject(error);
            });
        });
    }

    deletePatient(patientId) {
        return deletePatient(this.appContext, patientId);
    }

    findQuestion(journals, questionId) {
        if (!isArrayValid(journals)) return null;
        for (let i = 0; i < journals.length; ++i) {
            let journal = journals[i];
            if (journal.questionId === questionId) {
                return journal;
            }
        }
        return null;
    }
}

