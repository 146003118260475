import React from "react";
//import sequential from 'promise-sequential';
import PubSub from 'pubsub-js'

import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    LinearProgress,
    CircularProgress
} from '@material-ui/core/';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    Logger,
} from '@apricityhealth/web-common-lib'

import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

const Promise = require('bluebird');
const log = new Logger();

export const MODEL_TO_PATH = {
    "QuestionModel": {
        getUrl: (planId, objectId) => {
            return Config.baseUrl + `${Config.pathPrefix}dialog/questions/${planId}/${objectId}`;
        },
        saveUrl: (planId) => {
            return Config.baseUrl + `${Config.pathPrefix}dialog/questions/${planId}/`;
        },
        deleteUrl: (planId, objectId) => {
            return Config.baseUrl + `${Config.pathPrefix}dialog/questions/${planId}/${objectId}`;
        },
        getResult: (response) => response.data.question
    },
    "TextModel": {
        getUrl: (planId, objectId) => {
            return Config.baseUrl + `${Config.pathPrefix}content/text?textId=${objectId}&planId=${planId}`;
        },
        saveUrl: () => {
            return Config.baseUrl + `${Config.pathPrefix}content/text/`;
        },
        deleteUrl: (planId, objectId) => {
            return Config.baseUrl + `${Config.pathPrefix}content/text/${encodeURIComponent(objectId)}?planId=${planId}`;
        },
        getResult: (response) => response.data.text
    },
    "DataTypeModel": "data",
    "ModelTypeModel": "models",
    "GraphModel": "graphs",
    "ConditionTypeModel": "conditions",
    "ConfigModel": "configs",
    "ProcedureTypeModel": "procedures",
    "MedicationTypeModel": "medications",
    "RecommendCategoryModel": "recommend_categories",
    "RecommendGroupTypeModel": "recommend_group_types",
    "RecommendProtocolModel": "recommend_protocols",
    "RecommendModelModel": "recommend_models",
    "RecommendTypeModel": "recommendations",
    "FlagTypeModel": "flags",
    "AlertTypeModel": "alerts",
    "AlertLevelModel": "alert_levels",
    "FollowUpTypeModel": "followups",
    "ObservationRuleModel": "observations",
    "DetectModel": "detect_models",
    "DiagnoseModel": "diagnose_models",
    "DataModelModel": "data_models",
    "EducationModel": "education",
    "BroadcastGroupModel": "broadcast_groups"
};

export function getType(appContext, planId, model, objectId) {
    const { state: { idToken } } = appContext;
    const request = {
        method: 'GET',
        headers: { "Authorization": idToken }
    };

    const path = MODEL_TO_PATH[model];
    if (path === undefined) {
        return Promise.reject(new Error(`Unknown model ${model} passed into getType`));
    }
    if (typeof path.getUrl === 'function') {
        request.url = path.getUrl(planId, objectId);
    } else {
        request.url = Config.baseUrl + `${Config.pathPrefix}types/${planId}/${path}/${objectId}`;
    }

    console.log("getType request", request);
    return Axios(request).then((result) => {
        console.log("getType result:", result.data);
        if (typeof path.getResult === 'function') {
            return path.getResult(result);
        }
        return result.data;
    }).catch((err) => {
        console.error("getType error:", err);
        throw err;
    });
}

export function saveType(appContext, planId, model, object) {
    if (Array.isArray(object)) {
        return Promise.map(object, (obj) => saveType(appContext, planId, model, obj));
    }

    object.planId = planId;     // some end-points require the planId to be in the object, not in the URL..

    const { state: { idToken } } = appContext;
    const request = {
        method: 'POST',
        headers: { "Authorization": idToken },
        data: object
    };

    const path = MODEL_TO_PATH[model];
    if (path === undefined) {
        return Promise.reject(new Error(`Unknown model ${model} passed into saveType`));
    }
    if (typeof path.saveUrl === 'function') {
        request.url = path.saveUrl(planId);
    } else {
        request.url = Config.baseUrl + `${Config.pathPrefix}types/${planId}/${path}/`;
    }

    console.log("saveType request", request);
    return Axios(request).then((result) => {
        console.log("saveType result:", result.data);
        return result;
    }).catch((err) => {
        console.error("saveType error:", err);
        throw err;
    });
}

export function deleteType(appContext, planId, model, objectId) {
    const { state: { idToken } } = appContext;
    const request = {
        method: 'DELETE',
        headers: { "Authorization": idToken }
    };

    const path = MODEL_TO_PATH[model];
    if (path === undefined) {
        return Promise.reject(new Error(`Unknown model ${model} passed into deleteType`));
    }
    if (typeof path.deleteUrl === 'function') {
        request.url = path.deleteUrl(planId, objectId);
    } else {
        request.url = Config.baseUrl + `${Config.pathPrefix}types/${planId}/${path}/${objectId}`;
    }

    console.log("deleteType request", request);
    return Axios(request).then((result) => {
        console.log("deleteType result:", result.data);
        return result;
    }).catch((err) => {
        console.error("deleteType error:", err);
        throw err;
    });
}

export function sortKeys(config) {
    const ordered = {};
    Object.keys(config).sort().forEach((key) => {
        if (typeof config[key] === 'object' && !Array.isArray(config[key])) {
            ordered[key] = sortKeys(config[key]);
        } else {
            ordered[key] = config[key];
        }
    });
    return ordered;
}

export function findDependencies(plans, planId) {
    if (!plans) {
        return [];
    }
    const plan = plans.find((e) => e.planId === planId);
    if (!plan) {
        log.error(`plan ${planId} not found in plans!`);
        return [];
    }
    let dependencies = [...plan.dependencies];
    for (let i = 0; i < dependencies.length; ++i) {
        const subDeps = findDependencies(plans, dependencies[i]);
        for (let k = 0; k < subDeps.length; ++k) {
            if (dependencies.indexOf(subDeps[k]) < 0) {
                dependencies.push(subDeps[k]);
            }
        }
    }
    return dependencies;
}

// given the array of planIds, find the planId that is shared by all, or empty string if none
export function findCommonDependencies(plans, planIds) {
    const dependencies = planIds.map((planId) => findDependencies(plans, planId));
    const minDepends = dependencies.reduce((p, c) => {
        if (p === null || c.length < p.length) {
            return c;
        }
        return p;
    }, null) || [];

    let common = [];
    for (let i = 0; i < minDepends.length; ++i) {
        const testPlanId = minDepends[i]

        let found = true;
        for (let k = 0; found && k < dependencies.length; ++k) {
            found = dependencies[k].indexOf(testPlanId) >= 0;
        }
        // if all the other plans have this planId, then this is the common dependency...
        if (found) {
            common.push(testPlanId);
        }
    }

    return common;
}

export class ContentConflictsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            view: null,
            dialog: null,
            checked: {},
            actions: [],
            mode: this.props.mode || 'conflict'             // also supports "same", which is for duplicated data that is the same in sibling plans
        };
    }

    componentDidMount() {
        this.abort = false;
    }

    componentWillUnmount() {
        this.abort = true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mode !== this.props.mode) {
            this.setState({ mode: this.props.mode, checked: {}, actions: [] })
        }
    }

    onResolveConflicts() {
        const { parent: { state: { conflicts, plans } } } = this.props;
        const { checked, mode } = this.state;
        const { plan } = conflicts;

        let totalSelected = 0;
        let summary = [];
        let actions = [];
        for (let srcPlanId in checked) {
            for (let dstPlanId in checked[srcPlanId]) {
                for (let model in checked[srcPlanId][dstPlanId]) {
                    const dstPlan = plans.find((e) => e.planId === dstPlanId);
                    const srcPlan = plans.find((e) => e.planId === srcPlanId);
                    const files = Object.keys(checked[srcPlanId][dstPlanId][model]);

                    let selectedCount = 0; //files.reduce((p,file) => checked[srcPlanId][dstPlanId][model][file] === true ? p + 1 : p, 0);
                    for (let i = 0; i < files.length; ++i) {
                        const file = files[i];
                        if (checked[srcPlanId][dstPlanId][model][file] === true) {
                            const keys = conflicts[mode][srcPlanId][file][model];
                            for (let j = 0; j < keys.length; ++j) {
                                const key = keys[j];
                                console.log(`key: ${key}, model: ${model}, dstPlanId: ${dstPlanId}, srcPlanId: ${srcPlanId}, planId: ${plan.planId}`)
                                if (dstPlanId !== '_keep' && dstPlanId !== '_delete') {
                                    actions.push({ action: 'move', srcPlanId: plan.planId, dstPlanId: dstPlanId, model, file, key });
                                    actions.push({ action: 'delete', planId: srcPlanId, model, file, key });
                                } else if (dstPlanId === '_keep') {
                                    actions.push({ action: 'delete', planId: srcPlanId, model, file, key });
                                } else if (dstPlanId === '_delete') {
                                    actions.push({ action: 'delete', planId: plan.planId, model, file, key })
                                }
                            }
                            selectedCount += 1;
                        }
                    }
                    if (selectedCount > 0) {
                        totalSelected += selectedCount;
                        if (dstPlanId !== '_keep' && dstPlanId !== '_delete') {
                            summary.push(<li key={totalSelected}>Moving <b>{selectedCount} {model}{selectedCount > 1 ? "'s" : ""}</b> from <b>{plan.title}</b> to <b>{dstPlan.title}</b>, removing from <b>{srcPlan.title}</b>.</li>);
                        } else if (dstPlanId === '_keep') {
                            summary.push(<li key={totalSelected}>Deleting <b>{selectedCount} {model}{selectedCount > 1 ? "'s" : ""}</b> from <b>{srcPlan.title}</b>.</li>);
                        } else if (dstPlanId === '_delete') {
                            summary.push(<li key={totalSelected}>Deleting <b>{selectedCount} {model}{selectedCount > 1 ? "'s" : ""}</b> from <b>{plan.title}</b>.</li>);
                        }
                    }
                }
            }
        }
        console.log("onResolveConflicts:", actions);

        if (summary.length > 0) {
            this.setState({
                actions, dialog: <Dialog open={true} maxWidth='md' fullWidth={true}>
                    <DialogTitle>Resolve {totalSelected} {mode === 'conflict' ? 'Conflicts' : 'Duplicates'}?</DialogTitle>
                    <DialogContent>
                        <ul>{summary}</ul>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={() => this.setState({ dialog: null })}>Cancel</Button>
                        <Button variant='contained' onClick={this.resolveConflicts.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            })
        }

    }

    processAction(action, progress) {
        const { appContext } = this.props;
        return new Promise((resolve, reject) => {
            if (this.abort === true) {
                return reject(new Error("Resolve conflicts aborted..."));
            }
            this.setState({
                dialog:
                    <Dialog open={true} maxWidth='sm' fullWidth={true}>
                        <DialogContent>
                            <LinearProgress style={{ width: '100%' }} variant='determinate' value={progress} />
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button variant="contained" onClick={() => this.abort = true}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
            });

            if (action.action === 'move') {
                getType(appContext, action.srcPlanId, action.model, action.key).then((data) => {
                    if (this.abort) throw new Error("Resolve conflicts aborted...");
                    return saveType(appContext, action.dstPlanId, action.model, data);
                }).then(() => {
                    if (this.abort) throw new Error("Resolve conflicts aborted...");
                    return deleteType(appContext, action.srcPlanId, action.model, action.key);
                }).then(resolve).catch(reject);
            } else if (action.action === 'delete') {
                deleteType(appContext, action.planId, action.model, action.key).then(resolve).catch(reject);
            } else {
                return reject(new Error(`Unknown action type ${action.action}!`))
            }
        })
    }

    resolveConflicts() {
        const { actions } = this.state;

        this.abort = false;
        let done = 0;
        Promise.map(actions, (action) => {
            done += 1;
            return this.processAction(action, Math.floor((done / actions.length) * 100))
        }, { concurrency: 10 }).then(() => {
            this.setState({ dialog: null });
            PubSub.publish('PLAN_TOPIC', { action: "RefreshPlan" });
        }).catch((err) => {
            console.error("resolveConflicts error:", err);
            this.abort = true;
            this.setState({ dialog: null })
            this.props.parent.setState({ error: getErrorMessage(err) })
        })
    }

    generateConflictsView(conflicts, plans, checked, mode) {
        console.log("generateConflictsView:", conflicts, plans, checked, mode);
        if (!conflicts || !conflicts.siblings || !conflicts.plan) {
            return <div align='center'><CircularProgress /></div>
        }

        const { siblings, plan } = conflicts;

        const isAllChecked = (srcPlanId, dstPlanId, model) => {
            if (checked[srcPlanId] === undefined) checked[srcPlanId] = {};
            if (checked[srcPlanId][dstPlanId] === undefined) checked[srcPlanId][dstPlanId] = {};
            if (checked[srcPlanId][dstPlanId][model] === undefined) checked[srcPlanId][dstPlanId][model] = {};
            return Object.keys(checked[srcPlanId][dstPlanId][model]).reduce((p, c) => p | checked[srcPlanId][dstPlanId][model][c], false);
        }
        const handleCheckAll = (srcPlanId, dstPlanId, model) => (e, c) => {
            for (let i in checked[srcPlanId]) {     // enumerate the plans
                for (let k in checked[srcPlanId][i][model]) {      // enumerate the files in this given plan & model
                    checked[srcPlanId][i][model][k] = (i === dstPlanId) ? c : false;
                }
            }
            this.setState({ view: this.generateConflictsView() });
        }
        const isChecked = (srcPlanId, dstPlanId, model, file) => {
            // if (checked[srcPlanId] === undefined) checked[srcPlanId] = {};
            // if (checked[srcPlanId][dstPlanId] === undefined) checked[srcPlanId][dstPlanId] = {};
            // if (checked[srcPlanId][dstPlanId][model] === undefined) checked[srcPlanId][dstPlanId][model] = {};
            if (checked[srcPlanId][dstPlanId][model][file] === undefined) checked[srcPlanId][dstPlanId][model][file] = false;
            return checked[srcPlanId][dstPlanId][model][file];
        };
        const handleCheck = (srcPlanId, dstPlanId, model, file) => (e, c) => {
            for (let i in checked[srcPlanId]) {
                checked[srcPlanId][i][model][file] = (i === dstPlanId) ? c : false;
            }
            this.setState({ view: this.generateConflictsView() });
        };

        console.log("generateConflictsView:", conflicts, plans);
        siblings.sort((a, b) => a.title.localeCompare(b.title));

        let totalMergeCount = 0;
        let content = siblings.map((sibling) => {
            const conflict = conflicts[mode][sibling.planId] || {};
            const commonPlanIds = findCommonDependencies(plans, [plan.planId, sibling.planId]);
            const commonPlans = plans.filter((e) => commonPlanIds.indexOf(e.planId) >= 0);

            let conflictReport = null;
            if (Object.keys(conflict).length > 0) {
                const conflictGroups = sortKeys(Object.keys(conflict).reduce((p, file) => {
                    for (let model in conflict[file]) {
                        if (p[model] === undefined) p[model] = {};
                        if (p[model][file] === undefined) p[model][file] = [];
                        p[model][file].push(...conflict[file][model]);
                    }
                    return p;
                }, {}));

                let mergeCount = 0;
                let conflictCount = 0;
                const conflictTable = <table width='100%'><tbody>
                    {Object.keys(conflictGroups).map((model) => {
                        const files = Object.keys(conflictGroups[model]);
                        return <tr><td><b>{model}</b><br />
                            <table style={{ marginLeft: 50 }}><tbody>
                                <tr><td width='1000px'></td>
                                    {commonPlans.map((plan, i) => {
                                        return <td key={i} style={{ margin: 5, paddingLeft: 10, paddingRight: 10, borderBottom: '1pt solid #000000' }}>{plan.title}<br /><Checkbox style={{ padding: 0, margin: 1 }}
                                            checked={isAllChecked(sibling.planId, plan.planId, model)}
                                            onChange={handleCheckAll(sibling.planId, plan.planId, model)} /></td>
                                    })}
                                    <td style={{ margin: 5, paddingLeft: 10, paddingRight: 10, borderBottom: '1pt solid #000000' }}><b><i>Keep</i></b><br /><Checkbox style={{ padding: 0, margin: 1 }}
                                        checked={isAllChecked(sibling.planId, '_keep', model)}
                                        onChange={handleCheckAll(sibling.planId, '_keep', model)} />
                                    </td>
                                    <td style={{ margin: 5, paddingLeft: 10, paddingRight: 10, borderBottom: '1pt solid #000000' }}><b><i>Delete</i></b><br /><Checkbox style={{ padding: 0, margin: 1 }}
                                        checked={isAllChecked(sibling.planId, '_delete', model)}
                                        onChange={handleCheckAll(sibling.planId, '_delete', model)} />
                                    </td>
                                </tr>
                                {files.map((file) => {
                                    const keys = conflictGroups[model][file];
                                    let keyNames = keys.join(',');
                                    if (keyNames.length > 128) {
                                        keyNames = keyNames.substring(0, 125) + `...(${keys.length} Keys)`
                                    }
                                    conflictCount += 1;
                                    const keepChecked = isChecked(sibling.planId, '_keep', model, file);
                                    if (keepChecked) {
                                        mergeCount += 1;
                                    }
                                    const deleteChecked = isChecked(sibling.planId, '_delete', model, file);
                                    if (deleteChecked) {
                                        mergeCount += 1;
                                    }
                                    return <tr key={conflictCount}><td>{file} ({keyNames})</td>
                                        {commonPlans.map((plan, i) => {
                                            const checked = isChecked(sibling.planId, plan.planId, model, file);
                                            if (checked) mergeCount += 1;
                                            return <td key={i} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <Checkbox style={{ padding: 0, margin: 1 }} checked={checked} onChange={handleCheck(sibling.planId, plan.planId, model, file)} />
                                            </td>;
                                        })}
                                        <td style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <Checkbox style={{ padding: 0, margin: 1 }} checked={keepChecked} onChange={handleCheck(sibling.planId, '_keep', model, file)} />
                                        </td>
                                        <td style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <Checkbox style={{ padding: 0, margin: 1 }} checked={deleteChecked} onChange={handleCheck(sibling.planId, '_delete', model, file)} />
                                        </td>
                                    </tr>;
                                })}
                            </tbody></table>
                            <br /><br />
                        </td></tr>
                    })}
                </tbody></table>

                conflictReport = <Accordion style={{ margin: 10 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h3><b>{plan.title}</b> has <b>{conflictCount}</b> {mode === 'conflict' ? 'conflicting records' : 'identical records'} with <b>{sibling.title}</b>, both plans inherit from <b>{commonPlans.map((p) => p.title).join(', ')}</b>.
                            <i>{mergeCount > 0 ? `(${mergeCount} selected for merge)` : ''}</i></h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        {conflictTable}
                    </AccordionDetails>
                </Accordion>;
                totalMergeCount += mergeCount;
            }

            return <div key={sibling.planId}>
                {conflictReport}
            </div>;
        });

        return <div style={{ marginTop: 20 }}>
            <h2>{plan.title} has {siblings.length} sibling plans.</h2>
            <Button style={{ margin: 5 }} variant='contained' disabled={totalMergeCount === 0} onClick={this.onResolveConflicts.bind(this)}>Resolve {totalMergeCount} {mode === 'conflict' ? 'Conflicts' : 'Duplicates'}</Button><br />
            <ul>
                <li><i>Select {mode === 'conflict' ? 'conflicting records' : 'identical records'} below, any selected will be moved from <b>{plan.title}</b> into the selected plan and deleted from the sibling plan.</i></li>
                <li><i>If <b>Keep</b> is selected, then the record will be removed from the sibling plan. The record in <b>{plan.title}</b> plan will not be affected.</i></li>
                <li><i>If <b>Delete</b> is selected, then the record will be removed from <b>{plan.title}</b>. The record in the sibling plan will not be affected.</i></li>
            </ul>
            {content}
        </div>
    }

    render() {
        const { parent: { state: { conflicts, plans } } } = this.props;
        const { progress, dialog, checked, mode } = this.state;
        const view = this.generateConflictsView(conflicts, plans, checked, mode);

        return <div>
            <div align='center'>
                {progress}
            </div>
            {view}
            {dialog}
        </div>;
    }
}

export default ContentConflictsView;

