import React, { Component } from 'react';
import {
    TextField, FormControlLabel, Checkbox,
    IconButton,
} from '@material-ui/core/';
import { ErrorBadge, HtmlTooltip } from '../components/StyledComponents';
import { Redirect } from 'react-router-dom';
import RefreshIcon from '@material-ui/icons/Refresh';
import ValidIcon from '@material-ui/icons/CheckCircle';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import PubSub from 'pubsub-js'
import EditableNote from '../components/EditableNote'

export class SymptomModelsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: "symptom",
            models: [],
            filter: "",
            dependencies: true,
            filtered: []
        }
    }

    componentDidMount() {
        this.loadContent();
        const self = this;
        this.planToken = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.dataToken = PubSub.subscribe('STORE_UPDATE', () => {
            this.loadContent();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.planToken);
        PubSub.unsubscribe(this.dataToken);
    }

    loadContent() {
        const self = this;
        let { category, dependencies } = this.state;
        let { appContext } = this.props;

        self.setState({ models: null, filtered: [], error: null });
        if (this._types)
            this._types.setState({});        // unselect everything
        const { plan } = appContext.state;
        if (plan) {
            const { planId } = appContext.state.plan;
            try {
                let models = appContext.stores.DataTypesStore.getModels();
                models = models.filter((e) => e.category === category);
                if (!dependencies)
                    models = models.filter((e) => e.planId === planId);
                self.setState({ models }, () => self.updateFilter());
            } catch (err) {
                self.setState({ models: [], error: getErrorMessage(err) })
            };
        }
    }


    onCloseDialog() {
        if (this._types)
            this._types.setState({ selected: [] });
        this.setState({ dialog: null });
        this.loadContent();
    }

    typeSelected(s, t) {
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const model = self.state.filtered[s[0]];
            self.setState({ dialog: <Redirect to={{ pathname: `/symptom_models_unified/model`, state: { modelId: model.modelId } }} push={true} /> });
        } else {
            // nothing selected..
            self.setState({ dialog: null });
        }

    }

    createType() {
        this.setState({ dialog: <Redirect to={{ pathname: `/symptom_models_unified/model`, state: { modelId: "newSymptomModel" } }} push={true} /> });

    }

    updateFilter() {
        let { models, filter } = this.state;
        var filtered = [];
        if (filter !== null) {
            for (var i = 0; i < models.length; ++i) {
                if (testFilter(filter, models[i]))
                    filtered.push(models[i]);
            }
        }
        this.setState({ filtered });
    }


    render() {
        const self = this;
        let { filtered, dialog, error, filter, dependencies } = this.state;
        const { category, appContext } = this.props;
        const store = appContext.stores.DataTypesStore;

        const errors = store.getErrors(`${category}_models`).errors;
        const columnData = [
            {
                id: 'modelId', width: 25, label: 'Valid', formatValue: (v) => {
                    let errorList = v ? errors[v] ? errors[v].map((element, index) => { return <li key={index}>{element}</li> }) : [] : [];
                    return errors[v] ? <HtmlTooltip key='error' title={errorList}><ErrorBadge>{Array.isArray(errors[v]) ? errors[v].length : 0}</ErrorBadge></HtmlTooltip> : <ValidIcon key='valid' style={{ fill: 'green' }} />;
                }
            },
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
        ];

        return (
            <table width='95%'><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 400 }} value={filter} label="Filter"
                            onChange={(e) => {
                                filter = e.target.value; self.setState({ filter }, () => self.updateFilter());
                            }} /><br />
                        {error}
                    </td>
                    <td>
                        <FormControlLabel control={<Checkbox
                            checked={dependencies} onChange={(e) => {
                                self.setState({ dependencies: e.target.checked }, self.loadContent.bind(self));
                            }} />} label="Show Dependencies" />
                    </td>
                    <td align="right">
                    <EditableNote textId={`symptomsTooling`} planId={"tooling"} />
                        <IconButton disabled={store.progress !== null} onClick={() => store.loadDataTypes()}>{store.progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="3">
                        <EnhancedTable
                            title='Symptoms'
                            disableMultiSelect={true}
                            onTable={table => self._types = table}
                            onSelected={(s, t) => self.typeSelected(s, t)}
                            disableAdd={store.progress !== null}
                            onAdd={() => self.createType()}
                            orderBy='name'
                            columnData={columnData}
                            data={filtered} />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}


function testFilter(filter, model) {
    if (model) {
        filter = filter.toLowerCase();
        if (model.name && model.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.description && model.description.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.modelId && model.modelId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.dataId && model.dataId.toLowerCase().indexOf(filter) >= 0)
            return true;

        function testSymptoms(symptoms, filter) {
            if (Array.isArray(symptoms)) {
                for (let i = 0; i < symptoms.length; ++i) {
                    let symptom = symptoms[i];
                    if (symptom.dataId && symptom.dataId.toLowerCase().indexOf(filter) >= 0)
                        return true;
                }
            }
            return false;
        }

        if (testSymptoms(model.triggerSymptoms, filter))
            return true;
        if (testSymptoms(model.primarySymptoms, filter))
            return true;
        if (testSymptoms(model.associatedSymptoms, filter))
            return true;
        if (testSymptoms(model.clinicalModifiers, filter))
            return true;
        if (testSymptoms(model.laboratory, filter))
            return true;
        if (testSymptoms(model.negativeSymptoms, filter))
            return true;
    }
    return false;
}

export default SymptomModelsView;
