import React, { Component } from "react";
import {
    Button,
    Tab,
    Tabs,
    List,
    ListItem,
    ListItemText,
    IconButton,
    CircularProgress
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import {
    loadDetectModels,
    loadModels,
    loadQuestions,
    loadText,
    loadDataTypes,
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';

import { findNodeByDataId, getByTextIdByLanguage } from "../utils/Utils";
import PubSub from 'pubsub-js'

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');

class ValidationReportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detectModels: [],
            ruleModels: [],
            questions: [],
            dialog: null,
            messages: props.appContext.state.messages,
            expanded: null,
            currentTab: 0
        };
    }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.loadContent();
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;
        let promises = [];
        promises.push(loadQuestions(appContext, { dependencies: true } ));
        promises.push(loadDetectModels(appContext, { dependencies: true } ));
        promises.push(loadModels(appContext, { dependencies: true } ));
        promises.push(loadText(appContext, { dependencies: true } ));
        promises.push(loadDataTypes(appContext, { dependencies: true } ));

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Promise.all(promises).then((response) => {
            let questions = response[0];
            let ruleModels = response[1];
            let detectModels = response[2];
            let texts = response[3];
            let types = response[4];

            self.setState({ ruleModels, types, detectModels, questions, texts, progress: null }, self.createReports.bind(self));
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }
    export() {
        let self = this;
        const { exportReport } = self.state;
        const parser = new Parser({ withBOM: true, excelStrings: true });
        var data = JSON.parse(JSON.stringify(exportReport));
        var csvString = parser.parse(data);
        fileDownload(csvString, 'DataCollectionReport.csv');
    }

    createReports() {
        let self = this;
        let { questions, ruleModels, detectModels, types, texts } = this.state;
        self.getValidationReport(ruleModels, detectModels, questions, texts);
        self.createDialogCollectionTable(questions, types);
    }

    createDialogCollectionTable(questions, types) {
        console.log("createDialogCollectionTable:", questions, types );
        let self = this;
        let exportReport = [];
        let found = [];

        if (isArrayValid(questions)) {
            for (let index = 0; index < questions.length; index++) {
                const question = questions[index];
                let row = self.addDataRow(found, question.dataId, types);
                if (row) {
                    exportReport.push(row);
                }


                if (isArrayValid(question.answers)) {
                    for (let index = 0; index < question.answers.length; index++) {
                        const answer = question.answers[index];
                        row = self.addDataRow(found, answer.dataId, types);
                        if (row)
                            exportReport.push(row);

                        if (isArrayValid(answer.addRequiredData)) {
                            for (let index = 0; index < answer.addRequiredData.length; index++) {
                                const requiredData = answer.addRequiredData[index];
                                row = self.addDataRow(found, requiredData, types);
                                if (row)
                                    exportReport.push(row);

                            }
                        }
                        if (isArrayValid(answer.addOptionalData)) {
                            for (let index = 0; index < answer.addOptionalData.length; index++) {
                                const requiredData = answer.addOptionalData[index];
                                row = self.addDataRow(found, requiredData, types);
                                if (row)
                                    exportReport.push(row);

                            }
                        }
                    }
                }
            };
        }
        exportReport.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase())
                return -1;
            else if (a.name.toLowerCase() > b.name.toLowerCase())
                return 1;
            return 0;
        });

        let items = [];
        exportReport.forEach(row => {
            items.push(
                <ListItem key={items.length}>
                    <ListItemText
                        primary={row.name}
                    />
                </ListItem>);
        });
        let dataCollectionReport = <List>
            {items}
        </List>
        this.setState({ exportReport, dataCollectionReport });
    }

    addDataRow(found, dataId, types) {
        if (dataId) {
            if (found.includes(dataId)) return null;
            found.push(dataId);

            let type = findNodeByDataId(types, dataId);
            let name = type ? type.name : ''
            return { name }
        };
        return null;

    }
    render() {
        const self = this;
        const { error, progress, report, dialog, currentTab, dataCollectionReport } = self.state;

        let exportButton = <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.export() }}>Export</Button>
        let dataReport = <div>{exportButton}<br />{dataCollectionReport}</div>

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td></td>
                <td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={() => self.loadContent()}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <Tabs value={currentTab} onChange={(e, t) => self.setState({ currentTab: t })}>
                        <Tab label="Field Mappings" />
                        <Tab label="Dialog Data" />
                    </Tabs>
                    {currentTab === 0 && report}
                    {currentTab === 1 && dataReport}
                    {dialog}
                </td>
            </tr>
        </tbody>
        </table>;
    }

    getValidationReport(ruleModel, detectModel, questions, texts) {
        let rows = [];
        if (isArrayValid(detectModel)) {
            for (let i = 0; i < detectModel.length; i++) {
                rows.push(<tr key={rows.length}><td>{this.getDetectValidation(detectModel[i], questions, texts)}</td></tr>);
            }
        }
    
        let report = <table style={{width: '100%'}} align="left" className='validation_report_table' >
            <tbody>
                {rows}
            </tbody>
        </table>;
        this.setState( {report} );
    }
    
    getDetectValidation(detectModel, questions, texts,) {
        let rows = [];
    
        rows.push(<tr key={rows.length}><th className='validation_report_model_seperator' colSpan='3'> </th>
        </tr>);
        rows.push(<tr key={rows.length}><th className='validation_report_model_header' colSpan='3' >
            {detectModel.name} Model</th>
        </tr>);
    
        //for each set of symptoms make  sure there is a question  with valid answer
        if (isArrayValid(detectModel.primarySymptoms)) {
            rows.push(<tr key={rows.length}><th className='validation_report_symptom_heading' colSpan='3' >
                Primary Symptoms</th>
            </tr>);
            rows.push(<tr key={rows.length} className='validation_report_heading'>
                <th className='validation_report_heading'>Symptom - Score</th>
                <th className='validation_report_heading'>Answer</th>
                <th className='validation_report_heading'>Field Type</th>
            </tr>);
    
            for (let i = 0; i < detectModel.primarySymptoms.length; i++) {
                let primarySymptom = detectModel.primarySymptoms[i];
                rows = rows.concat(this.getSymptomLinks(questions, primarySymptom, texts, rows.length ));
            }
        }
    
    
        if (isArrayValid(detectModel.associatedSymptoms)) {
            rows.push(<tr key={rows.length}><th className='validation_report_symptom_heading' colSpan='3' >
                Asscoiated Symptoms</th>
            </tr>);
            rows.push(<tr key={rows.length} className='validation_report_heading'>
                <th className='validation_report_heading'>Symptom - Score</th>
                <th className='validation_report_heading'>Answer</th>
                <th className='validation_report_heading'>Field Type</th>
            </tr>);
            for (let i = 0; i < detectModel.associatedSymptoms.length; i++) {
                let associatedSymptom = detectModel.associatedSymptoms[i];
                rows = rows.concat(this.getSymptomLinks(questions, associatedSymptom, texts, rows.length));
            }
        }
    
        return <table align="left" className='detect_rule_model_table'>
            <tbody>
                {rows}
            </tbody>
        </table>;
    }
    
    getSymptomLinks(questions, symptom, texts, keyOffset = 0) {
        let rows = []
        let dataId = symptom.dataId;
        //for each value
        if (isArrayValid(symptom.values)) {
            for (let j = 0; j < symptom.values.length; j++) {
                let value = symptom.values[j];
                let links = this.findAnswersInQuestions(questions, dataId, value);
                // console.log(`links  returned `, links);
                if (isArrayValid(links)) {
                    for (let k = 0; k < links.length; k++) {
                        let link = links[k];
                        let text = `[question:${link.question.questionId}]`;
                        if (link.answer) {
                            text = getByTextIdByLanguage(texts, link.question.questionId + '.' + link.answer.answerId);
                            if (text) text = text.text;
                            // text = link.answer.text[0];
                        } else {
                            text = getByTextIdByLanguage(texts, link.question.questionId);
                            if (text) text = text.text;
                        }
                        rows.push(<tr key={rows.length + keyOffset} className='validation_report_symptom_row'>
                            <td className='validation_report_symptom' >{dataId} - {value.value}</td>
                            <td className='validation_report_answer'> {text}</td>
                            <td className='validation_report_answer'> {link.type}</td>
                        </tr>);
                    }
                } else {
                    rows.push(<tr key={rows.length + keyOffset} className='validation_report_symptom_row'>
                        <td className='validation_report_symptom' >{dataId} - {value.value}</td>
                        <td className='validation_report_no_answer'> No answers found</td>
                        <td className='validation_report_no_answer'> NA</td>
                    </tr>);
                }
            }
        }
        return rows;
    }
    
    findAnswersInQuestions(questions, dataId, value) {
        let links = [];
        if (isArrayValid(questions)) {
    
            for (let i = 0; i < questions.length; i++) {
                let question = questions[i];
                //check the dataId on the quesiton first..
                if (question.dataId === dataId) {
                    if (isArrayValid(question.answers)) {
                        for (let j = 0; j < question.answers.length; j++) {
                            let answer = question.answers[j];
                            if (isArrayValid(answer.mapping)) {
                                for (let i = 0; i < answer.mapping.length; i++) {
                                    if (Number(answer.mapping[i].v) === Number(value.value))
                                        links.push({ question, answer, type: "mapping" });
                                }
                            }
                            else if (Number(answer.score) === Number(value.value) || (isNaN(answer.score) && isNaN(value.value))) {
                                links.push({ question, answer, type: "dataId" });
                            }
                        }
                    }
                    if (isArrayValid(question.mapping)) {
                        for (let h = 0; h < question.mapping.length; h++) {
                            if (Number(question.mapping[h].v) === Number(value.value))
                                links.push({ question, type: "mapping" });
                        }
                    }
                } else if (isArrayValid(question.addRequiredData) && question.addRequiredData.includes(dataId)) {
                    if (isArrayValid(question.answers)) {
                        for (let j = 0; j < question.answers.length; j++) {
                            let answer = question.answers[j];
                            if (Number(answer.score) === Number(value.value)) {
                                links.push({ question, answer, type: "addRequiredData" });
                            }
                        }
                    }
                } else {
    
                    if (isArrayValid(question.answers)) {
                        for (let j = 0; j < question.answers.length; j++) {
                            let answer = question.answers[j];
                            if (answer.dataId === dataId) {
                                if (Number(answer.score) === Number(value.value)) {
                                    links.push({ question, answer, type: "dataId" });
                                }
                                if (isArrayValid(answer.mapping)) {
                                    for (let i = 0; i < answer.mapping.length; i++) {
                                        if (Number(answer.mapping[i].v) === Number(value.value))
                                            links.push({ question, answer, type: "mapping" });
                                    }
                                }
                            } else if (isArrayValid(answer.addRequiredData) && answer.addRequiredData.includes(dataId)) {
                                if (Number(answer.score) === Number(value.value)) {
                                    links.push({ question, answer, type: "addRequiredData" });
                                }
                                if (isArrayValid(answer.mapping)) {
                                    for (let i = 0; i < answer.mapping; i++) {
                                        if (Number(answer.mapping[i].v) === Number(value.value))
                                            links.push({ question, answer, type: "addRequiredData" });
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
        return links;
    }
}

const styles = {
    button: {
        margin: 10
    }
};

export default ValidationReportView;
