import React, { Component } from "react";
import PropTypes from 'prop-types';

import {
    Chip,
    FormLabel,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    withStyles, 
    Avatar
} from '@material-ui/core/';

import FlagIcon from '@material-ui/icons/Flag';
import AddIcon from '@material-ui/icons/Add';
import {
    arrayRemoveLocation,
    arrayInsertLocation,
    isArrayValid,
} from '../utils/Utils';

import { SelectAlertType } from "./SelectAlertType";

class EditAlertTypeArrayView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            array: props.array ? props.array : [],
            name: props.name,
            menu: null
        };
    }

    onAdd = index => e => {
        let { array } = this.state;
        //just add a default one
        arrayInsertLocation(array, 0, { alertTypeId: '' });
        this.setState({ array });
    }

    onUpdateAlertNotificationType(index, type) {
        let { array } = this.state;
        console.log("onUpdateAlertNotificationType:", type );
        array[index] = type ? { alertTypeId: type.alertTypeId }: { alertTypeId: '' };
        this.setState({ array, menu: null });
    }

    onChangeData = index => e => {
        let { array } = this.state;
        const { appContext } = this.props;
        let data = array[index] || '';
        let menu = <Menu open={true} onClose={this.closeMenu.bind(this)} anchorEl={e.target}>
            <MenuItem style={{ height: 50 }}>
                <SelectAlertType
                    appContext={appContext}
                    alertTypeId={data.alertTypeId}
                    onSelect={this.onUpdateAlertNotificationType.bind(this, index)} />
            </MenuItem>
        </Menu>;
        this.setState({ menu });
    }

    closeMenu() {
        this.setState({ menu: null });
    }

    static getDerivedStateFromProps(props, state){
        const hasNewArray = JSON.stringify(state.array) !== JSON.stringify(props.array) 
        const hasNewName = state.name !== props.name 
        if (hasNewArray || hasNewName){
            const array = props.array ? props.array : [];
            const name = props.name;
            return { array:array, name:name };
        }else{
            return null
        }
    }

    onDelete = index => e => {
        let { array } = this.state;
        arrayRemoveLocation(array, index);
        this.setState({ array });
    }

    render() {
        let { array, name, menu } = this.state;
        let self = this;

        let dataChips = [];
        if (isArrayValid(array)) {
            for (let index = 0; index < array.length; index++) {
                let data = array[index];
                dataChips.push(
                    <Chip
                        key={index}
                        avatar={<Avatar><FlagIcon /></Avatar>}
                        label={data.alertTypeId}
                        onClick={self.onChangeData(index)}
                        onDelete={self.onDelete(index)}
                    />);

            }
        }
        return (<div >
            <div style={{ marginTop: '5px' }} />
            <FormLabel>{name}:</FormLabel>
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={self.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div >
        )
    }
}

const styles = {
    div: {
        margin: 10
    }
};

EditAlertTypeArrayView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditAlertTypeArrayView);
