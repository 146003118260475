import React, { Component } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    TextField,
    MenuItem,
    Input,
    InputLabel,
    FormControl,
    Select,
    DialogTitle,
    IconButton
} from '@material-ui/core/';

import InfoIcon from '@material-ui/icons/Info';

import { v4 as uuidv4 } from 'uuid';

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType'
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

import EditModelTypeArrayView from '../components/EditModelTypeArrayView'
import EditDataTypeArray from '../components/EditDataTypeArray'
import EditFlagArrayView from '../components/EditFlagArrayView'
import EditMappingArray from '../components/EditMappingArray';
import EditObjectArray from '../components/EditObjectArray';
import EditDataTypeDialog from './EditDataTypeDialog';

import SelectTupleIndex from "@apricityhealth/web-common-lib/components/SelectTupleIndex";
import EditConditionArray from "../components/EditConditionArray";
import EditMedicationArray from "../components/EditMedicationArray";

const TEXT_OPTIONS = {
    "min": "",
    "max": "",
    "minScale": ""
};

const CONTROL_TYPES = {
    "text": {
        "name": "Text"
    },
    "edit": {
        "name": "Editable Text",
        "options": TEXT_OPTIONS
    },
    "number": {
        "name": "Number",
        "options": TEXT_OPTIONS
    },
    "spinner": {
        "name": "Spinner"
    },
    "singleton": {
        "name": "Singleton"
    },
    "image": {
        "name": "Image"
    },
    "bodyMap": {
        "name": "Body Map"
    },
    "uploadImage": {
        "name": "Upload Image",
        "options": {
            "maxImageCount": "1",
            "enableMultipleImages": "false"
        }
    },
    "date": {
        "name": "Date"
    },
    "patientData": {
        "name": "Patient Data",
        "options": {
            "dataId": "",
            "tupleIndex": "0",
            "days": "14"
        }
    },
    "systolicBloodPressure": {
        "name": "Systolic BP",
        "options": TEXT_OPTIONS
    },
    "diastolicBloodPressure": {
        "name": "Diastolic BP",
        "options": TEXT_OPTIONS
    },
    "temperature": {
        "name": "Temperature",
        "options": TEXT_OPTIONS
    },
    "oxygen": {
        "name": "Oxygen",
        "options": TEXT_OPTIONS
    },
    "heartRate": {
        "name": "Heart Rate",
        "options": TEXT_OPTIONS
    },
    "slider": {
        "name": "Slider",
        "options": {
            "min": "1",
            "max": "10",
            "scale": "1"
        }
    },
};

class EditAnswerDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            texts: props.texts,
            language: props.language || 'en-us',
            answer: null,
            question: props.question,
            progress: null,
            error: null,
            dialog: null,
            done: false
        };
    }

    componentDidMount() {
        this.loadAnswer();
    }

    loadAnswer() {
        const ANSWER_TEMPLATE = {
            answerId: uuidv4(),
            text: [''],
            explanation: '',
            controlType: "text",
            controlOptions: {},
            andFlagConditions: [],
            orFlagConditions: [],
            addFlags: [],
            removeFlags: [],
            mapping: [],
            bounds: [],
            addRequiredData: [],
            addOptionalData: [],
            addModels: [],
            addConditions: [],
            addMedications: [],
            preConditions: '',
        };
        let answer = this.props.answer ? JSON.parse(JSON.stringify(this.props.answer)) : ANSWER_TEMPLATE;
        for(let k in ANSWER_TEMPLATE) {
            if ( answer[k] === undefined ) answer[k] = ANSWER_TEMPLATE[k];
        }

        this.setState({ answer });
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        console.log("onDone:", this.state.answer );
        const { onDone } = this.props;
        if ( onDone ) 
            onDone( this.state.answer );
    }

    onSelectDataType(type) {
        let { answer } = this.state;
        answer.dataId = type ? type.dataId : '';
        this.setState({ answer });
    }

    onSelectBaselineType(type) {
        let { answer } = this.state;
        answer.baselineId = type ? type.dataId : '';
        this.setState({ answer });
    }

    displayPreview() {
        const self = this;
        let { answer } = this.state;
        let text = answer.text[0];

        this.setState({
            dialog: <Dialog fullWidth={true} maxWidth="sm" modal="true" open={true}>
                <DialogTitle>Preview</DialogTitle>
                <DialogContent>
                    <div style={{ margin: 5 }}>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => {
                        self.setState({ dialog: null });
                    }}>OK</Button>
                </DialogActions>
            </Dialog>
        });
    }

    onAddQuestionIdsUsingModels() {
        console.log("onAddQuestionIdsUsingModels");
        const { answer, question } = this.state;
        const { DataTypesStore } = this.props.appContext.stores;
        let dataId = answer.dataId || question.dataId;
        if ( dataId ) {
            answer.questionIds = DataTypesStore.findRelatedQuestionIdsForDataId( dataId );
            this.setState({answer});
        }
    }

    getAdvancedOptions() {
        const self = this;

        const { answer } = this.state;
        const { appContext } = this.props;

        return <div>
            <SelectDataType
                appContext={appContext}
                dataId={answer.dataId}
                enableNone={true}
                onCreate={() => {
                    self.setState({
                        dialog: <EditDataTypeDialog appContext={this.props.appContext}
                            dataType={{ dataId: '', name: '', description: '', category: 'symptom', tupleDescriptions: [] }}
                            onCancel={() => self.setState({ dialog: null })}
                            onDone={(newType) => {
                                answer.dataId = newType.dataId;
                                self.setState({ dialog: null, answer });
                            }} />
                    });
                }}
                onSelect={this.onSelectDataType.bind(this)} />
            <br />
            <SelectDataType
                appContext={appContext}
                label="Baseline Data Type"
                dataId={answer.baselineId}
                enableNone={true}
                onCreate={() => {
                    self.setState({
                        dialog: <EditDataTypeDialog appContext={this.props.appContext}
                            dataType={{ dataId: '', name: '', description: '', category: 'symptom', tupleDescriptions: [] }}
                            onCancel={() => self.setState({ dialog: null })}
                            onDone={(newType) => {
                                answer.baselineId = newType.dataId;
                                self.setState({ dialog: null, answer });
                            }} />
                    });
                }}
                onSelect={this.onSelectBaselineType.bind(this)} />
            <br />
            <SelectTupleIndex
                appContext={appContext}
                label="Baseline Tuple"
                dataId={answer.baselineId}
                tupleIndex={answer.baselineTuple}
                onChange={(tupleIndex) => answer.baselineTuple = tupleIndex} />
            <br />
            <EditFlagArrayView appContext={appContext} array={answer.andFlagConditions} label={'And Flags'} tip={'Flags required to display this answer, all flags must be active.'} />
            <br />
            <EditFlagArrayView appContext={appContext} array={answer.orFlagConditions} label={'Or Flags'} tip={'Flags required to display this answer, any of the flags may be active.'} />
            <br />
            <EditConditions
                appContext={appContext}
                conditions={answer.preConditions}
                onChange={(conditions) => {
                    answer.preConditions = conditions;
                    self.setState({ answer });
                }}
                name={'Pre Conditions'}
            />
            <EditModelTypeArrayView appContext={appContext} array={answer.addModels} label={'Add Models'} 
                tip={'Add the given models to the conversation when this answer is given, all data needed by the given model will be requested.'} />
            <br />
            <EditDataTypeArray
                appContext={appContext}
                array={answer.addRequiredData}
                label={'Required Data'}
                onChange={(addRequiredData) => {
                    answer.addRequiredData = addRequiredData
                    self.setState({ answer });
                }}
                tip={'Any data types specified will be aquired when this answer is given.'}
            />
            <br />
            <EditFlagArrayView appContext={appContext} array={answer.addFlags} disableNot={true} label={'Add Flags'} tip={'Add conversation flags which can affect conversation flow.'} />
            <br />
            <EditFlagArrayView appContext={appContext} array={answer.removeFlags} disableNot={true} label={'Remove Flags'} tip={'Remove conversation flags which can affect the conversation flow.'} />
            <br />
            <EditConditionArray appContext={appContext} array={answer.addConditions} label={'Add Conditions'} tip={'Add the given condition to the patient record when this answer is given.'} />
            <br />
            <EditMedicationArray appContext={appContext} array={answer.addMedications} label={'Add Medications'} tip={'Add the given medications to the patient record when this answer is given.'} />
            <br />
            <EditMappingArray appContext={appContext} array={answer.bounds} label={'Bounds'} tip={'Allow answer values only between the given min and max.'}/>
            <br />
            <EditMappingArray appContext={appContext} array={answer.mapping} label={'Mapping'} tip={'Remap an answer value into a given score based on the answer being between a given min and max.'}/>
            <br />
        </div>
    }

    onSelectControlType(e) {
        const { answer } = this.state;
        if ( e.target.value !== answer.controlType) {
            answer.controlType = e.target.value;

            let options = CONTROL_TYPES[answer.controlType].options;
            if ( options ) {
                for(let j in answer.controlOptions) {
                    if ( options[j] === undefined ) delete answer.controlOptions[j];
                }
                for(let k in options) {
                    answer.controlOptions[k] = options[k]; 
                }
            }
            else {
                for(let j in answer.controlOptions) delete answer.controlOptions[j];
            }
            this.setState({ answer });
        }
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { answer, progress, dialog} = this.state;

        if (! answer ) return null;
        let advancedOptions = self.getAdvancedOptions();
        if (answer.category === undefined ) {
            answer.category = '';
        }
        if (answer.score === undefined) {
            answer.score = '0';
        }
        if (answer.noAnswerScore === undefined) {
            answer.noAnswerScore = '';
        }

        function getControlTypes() {
            let items = [];
            for(let k in CONTROL_TYPES) {
                items.push(<MenuItem key={k} value={k}>{CONTROL_TYPES[k].name}</MenuItem>);
            }
            return items;
        }

        return (
            <Dialog model="false" open={true} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>Edit Answer</DialogTitle>
                <DialogContent>
                    <TextField
                        style={styles.answer}
                        label="Category"
                        value={answer.category}
                        onChange={(e) => {
                            answer.category = e.target.value;
                            self.setState({ answer });
                        }}
                    />
                    <br />
                    <TextField
                        style={styles.answer}
                        label="Answer Text"
                        value={answer.text[0]}
                        onChange={(e) => {
                            answer.text[0] = e.target.value;
                            self.setState({ answer });
                        }}
                    />
                    <IconButton onClick={self.displayPreview.bind(self)}><InfoIcon /></IconButton>
                    <br />
                    <TextField
                        style={styles.answer}
                        label="Explanation Text"
                        value={answer.explanation}
                        onChange={(e) => {
                            answer.explanation = e.target.value;
                            self.setState({ answer });
                        }}
                    />
                    <br />
                    <TextField
                        style={styles.name}
                        label="Answer Id:"
                        value={answer.answerId}
                        onChange={(e) => {
                            answer.answerId = e.target.value;
                            self.setState({ answer });
                        }}
                    />
                    <br />
                    <FormControlLabel style={{ marginLeft: '0px' }}
                        control={<Checkbox
                            checked={answer.detect}
                            onChange={(e) => {
                                answer.detect = e.target.checked;
                                self.setState({ answer });
                            }}
                        />
                        }
                        label="Detect" />
                    <FormControlLabel style={{ marginLeft: '0px' }}
                        control={<Checkbox
                            checked={answer.bail}
                            onChange={(e) => {
                                answer.bail = e.target.checked;
                                self.setState({ answer });
                            }}
                        />
                        }
                        label="Bail" />
                    <FormControlLabel style={{ marginLeft: '0px' }}
                        control={<Checkbox
                            checked={answer.default}
                            onChange={(e) => {
                                answer.default = e.target.checked;
                                self.setState({ answer });
                            }}
                        />
                        }
                        label="Default" />
                    <FormControlLabel style={{ marginLeft: '0px' }}
                        control={<Checkbox
                            checked={answer.noCulling}
                            onChange={(e) => {
                                answer.noCulling = e.target.checked;
                                self.setState({ answer });
                            }}
                        />
                        }
                        label="No Culling" />
                    <br />
                    <FormControl style={styles.select}>
                        <InputLabel>Control Type</InputLabel>
                        <Select
                            value={answer.controlType}
                            onChange={this.onSelectControlType.bind(this)}
                            input={<Input name="controlType" id="controlType-helper" />}
                        >
                            {getControlTypes()}
                        </Select>
                    </FormControl>
                    <br />
                    <EditObjectArray style={{margin: 5}} appContext={appContext} object={answer.controlOptions} label={'Control Options'} />
                    <br />
                    <FormControl style={{width: 150, margin: 5}}>
                        <InputLabel>Score</InputLabel>
                        <Select
                            value={answer.score}
                            onChange={(e) => {
                                answer.score = e.target.value;
                                self.setState({ answer });
                            }}>
                            <MenuItem value='0'>0</MenuItem>
                            <MenuItem value='1'>1</MenuItem>
                            <MenuItem value='2'>2</MenuItem>
                            <MenuItem value='3'>3</MenuItem>
                            <MenuItem value='4'>4</MenuItem>
                            <MenuItem value='NaN'>NaN</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField style={{width: 150, margin: 5}} label='Custom Score' value={answer.score} onChange={(e) => {
                        answer.score = e.target.value;
                        self.setState({ answer })
                    }} />
                    <br />
                    <FormControl style={styles.select}>
                        <InputLabel>No Answer Score</InputLabel>
                        <Select
                            value={answer.noAnswerScore}
                            onChange={(e) => {
                                answer.noAnswerScore = e.target.value;
                                self.setState({ answer });
                            }}>
                            <MenuItem value=''>None</MenuItem>
                            <MenuItem value='0'>0</MenuItem>
                            <MenuItem value='1'>1</MenuItem>
                            <MenuItem value='2'>2</MenuItem>
                            <MenuItem value='3'>3</MenuItem>
                            <MenuItem value='4'>4</MenuItem>
                            <MenuItem value='NaN'>NaN</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    {advancedOptions}
                    {progress}
                    {dialog}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={(e) => { self.onDone() }}>Save</Button>
                    <Button variant="contained" style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    answer: {
        margin: 5,
        width: 450
    },
    name: {
        margin: 5,
        width: 300
    },
    select: {
        margin: 5,
        width: 300
    },
    div: {
        margin: 10
    },

    button: {
        margin: 10
    }
};

export default EditAnswerDialog;
