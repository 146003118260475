import React, { Component } from "react";
import {
    CircularProgress, Select, MenuItem
} from '@material-ui/core/';
import PrintModel from '../print/PrintModel';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import PubSub from 'pubsub-js'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class ModelsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            progress: null,
            modelFilter: "*",
            showDetectModels: false,
            showQuestions: false,
            showPrimary: false,
            showTrigge: false,
            showRecommendations: false
        };
    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                this.loadContent();
            }
        });
        this.dataToken = PubSub.subscribe('STORE_UPDATE', () => {
            this.loadContent();
        });

    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
        PubSub.unsubscribe(this.dataToken);
    }

    loadContent() {
        const { appContext } = this.props;
        const { plan } = appContext.state;
        if (plan) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            try {
                const store = appContext.stores.DataTypesStore;
                let models = store.getModels();
                this.setState({ progress: null, models });
            } catch (err) {
                this.setState({ progress: null, models: [], error: getErrorMessage(err) })
            };

        }

    }

    render() {
        let self = this;
        let { appContext } = this.props;
        let { models, error, progress, modelFilter, showDetectModels, showQuestions, showPrimary, showTrigger, showRecommendations } = self.state;
        console.debug(`Report models `, models)
        if (modelFilter !== "*")
            models = models.filter((m) => m.category === modelFilter);


        return <div>
            {error}
            {progress}
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <FormControlLabel
                    control={<Switch
                        checked={showDetectModels}
                        onChange={(e) => this.setState({ showDetectModels: e.target.checked })}

                    />}
                    label="Show Detect"
                />
                <FormControlLabel
                    control={<Switch
                        checked={showPrimary}
                        onChange={(e) => this.setState({ showPrimary: e.target.checked })}

                    />}
                    label="Show Primary"
                />
                <FormControlLabel
                    control={<Switch
                        checked={showTrigger}
                        onChange={(e) => this.setState({ showTrigger: e.target.checked })}

                    />}
                    label="Show Trigger"
                />
                <FormControlLabel
                    control={<Switch
                        checked={showQuestions}
                        onChange={(e) => this.setState({ showQuestions: e.target.checked })}

                    />}
                    label="Show Questions"
                />
                <FormControlLabel
                    control={<Switch
                        checked={showRecommendations}
                        onChange={(e) => this.setState({ showRecommendations: e.target.checked })}

                    />}
                    label="Show Recommendations"
                />
                <Select
                    style={{ width: "10rem" }}
                    value={modelFilter} onChange={(e) => this.setState({ modelFilter: e.target.value })}
                >
                    <MenuItem key={"*"} value={"*"}>All</MenuItem>
                    <MenuItem key={"triage"} value={"triage"}>Triage</MenuItem>
                    <MenuItem key={"lab"} value={"lab"}>Lab</MenuItem>
                    <MenuItem key={"symptom"} value={"symptom"}>Symptom</MenuItem>
                    <MenuItem key={"pro"} value={"pro"}>Condition</MenuItem>
                </Select>
            </div>
            {models.map((model) => {
                let modelId = model.modelId;
                return <PrintModel key={modelId} showDetectModels={showDetectModels} showRecommendations={showRecommendations} showQuestions={showQuestions} showPrimary={showPrimary} showTrigger={showTrigger} appContext={appContext} modelId={modelId} />
            })}
        </div>
    }
}

export default ModelsReport;