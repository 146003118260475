import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core/';
import {
    Chip,
    FormLabel,
    Button,
    Tooltip,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    TextField
} from '@material-ui/core/';

import {
    isArrayValid
} from '../utils/Utils';

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType'
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';

class AddDataArrayView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: props.array,
            name: props.name,
            menu: null
        };
    }

    onAdd = index => e => {
        let { array } = this.state;
        //just add a default one
        array.splice(0, 0, { dataId: '', values: [], status: 'preliminary' });
        this.setState({ array });
        if (this.props.onChange)
            this.props.onChange(array)
    }

    onSelectDataType(type, index) {
        let { array } = this.state;
        let dataId = type ? type.dataId : '';

        let previous = array[index];
        if (previous.dataId !== dataId) {
            let values = previous.values;
            while (values.length < type.tupleDescriptions.length)
                values.push('');
            while (values.length > type.tupleDescriptions.length)
                values.pop();

            array[index] = { dataId: type ? type.dataId : '', values, status: previous.status };
            console.log('onSelectDataType:', array[index], type);
            this.setState({ type, array }, this.onChangeData(index));
            if (this.props.onChange)
                this.props.onChange(array)
        }
        else {
            this.setState({ type });
        }
    }

    onChangeValues = index => values => {
        let { array } = this.state;
        let item = array[index];
        item.values = values.map(e => e.value);

        this.setState({ array });
        if (this.props.onChange)
            this.props.onChange(array)
    }

    onChangeStatus = index => e => {
        let { array } = this.state;
        let item = array[index];
        item.status = e.target.value;
        this.setState({ array });
        if (this.props.onChange)
            this.props.onChange(array);
    }

    onChangeData = index => e => {
        //console.log("onChangeData:", index, e );
        let { array, type } = this.state;
        const { appContext } = this.props;

        let addData = array[index];
        console.log("addData:", addData);
        const columnData = [
            { id: 'tupleIndex', numeric: false, disabledPadding: false, label: 'Tuple Index' },
            { id: 'tuple', numeric: false, disabledPadding: false, label: 'Tuple' },
            { id: 'value', editType: 'text', numeric: false, disabledPadding: false, label: 'Value', width: 400 },
        ];
        let values = [];
        for (let i = 0; i < addData.values.length; ++i) {
            let item = { tupleIndex: i, tuple: '', value: addData.values[i] };
            if (type && type.tupleDescriptions.length > i)
                item.tuple = type.tupleDescriptions[i].description;
            values.push(item);
        }

        console.log("values:", values);
        let menu = <Dialog open={true} fullWidth={true} maxWidth={'md'} onClose={this.closeMenu.bind(this)}>
            <DialogContent style={{ minHeight: 400 }}>
                <SelectDataType
                    appContext={appContext}
                    dataId={addData.dataId}
                    onSelect={(type) => this.onSelectDataType(type, index)} />
                <EnhancedTable
                    disableActions={true}
                    orderBy='tupleIndex'
                    columnData={columnData}
                    data={values}
                    rowsPerPage={5}
                    onDataChanged={this.onChangeValues(index)}
                    title='Values' />
                <TextField label='Status' value={addData.status}
                    onChange={(e) => this.onChangeStatus(index)} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.closeMenu.bind(this)}>Done</Button>
            </DialogActions>
        </Dialog>
        this.setState({ menu });
    }

    closeMenu() {
        this.setState({ menu: null });
    }


    componentDidUpdate(prevProps) {
        const hasNewArray = JSON.stringify(prevProps.array) !== JSON.stringify(this.props.array)
        const hasNewName = prevProps.name !== this.props.name
        if (hasNewArray || hasNewName) {
            let array = this.props.array ? this.props.array : [];
            let name = this.props.name;
            this.setState({ array, name });
        }
    }


    onDelete = index => e => {
        let { array } = this.state;
        array.splice(index, 1);
        this.setState({ array });
        if (this.props.onChange) {
            this.props.onChange(array)
        }
    }

    render() {
        let self = this;
        let { array, name, menu } = this.state;

        let dataChips = [];
        if (isArrayValid(array)) {
            for (let index = 0; index < array.length; index++) {
                let data = array[index];
                dataChips.push(
                    <Chip key={index}
                        avatar={<Avatar><FaceIcon /></Avatar>}
                        label={data.dataId}
                        onClick={self.onChangeData(index)}
                        onDelete={self.onDelete(index)}
                    />);

            }
        }
        //console.log("EditDataTypesArrayView.render()", menu )
        return <div >
            <div style={{ marginTop: '5px' }} />
            <FormLabel>{name}:</FormLabel>
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={self.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div>;
    }
}

const styles = {
    div: {
        margin: 10
    }
};

AddDataArrayView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default AddDataArrayView;
