import React from 'react';

import {
    AppBar, Toolbar, Typography, Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent,
    IconButton, TextField, Button, CircularProgress, Drawer, Checkbox, FormControlLabel, FormControl
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { SelectProcedure } from '@apricityhealth/web-common-lib/components/SelectProcedure';
import TypeSearch from '@apricityhealth/web-common-lib/components/TypeSearch';

import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import ChangedByButton from '../components/ChangedByButton';

class ProcedureTypeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            modified: false
        };
    }

    componentDidMount() {
        if (this.props.type)
            this.loadType(JSON.parse(JSON.stringify(this.props.type)));
    }

    componentDidUpdate(oldProps){
        if (this.props.type !== oldProps.type)
            this.loadType(JSON.parse(JSON.stringify(this.props.type)));
    }

    closeType() {
        if ( this.state.modified === true ) {
            this.displayModifiedDialog( this.props.onClose );
        }
        else 
            this.props.onClose();
    }

    displayModifiedDialog( done ) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Procedure Type Modified!</DialogTitle>
            <DialogContent>This procedure type has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState( { modified: false, dialog: null });
                    self.saveType( done );
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState( { modified: false, dialog: null }, done );
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState( { dialog });
    }

    closeSearch() {
        this.setState({ dialog: null });
    }

    loadType(type) {
        if ( this.state.modified === true ) {
            return this.displayModifiedDialog( this.loadType.bind(this, type) );
        }

        if (!type.procedureId)
            type.procedureId = '';
        if (!type.name)
            type.name = '';
        if (!type.code)
            type.code = '';
        if (!type.system)
            type.system = '';
        if (!type.parentId)
            type.parentId = '';
        this.setState({
            type: type,
            progress: null,
            dialog: null,
            modified: false
        });
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    saveType(callback, planId = null) {
        const self = this;
        const { type } = self.state;
        const { appContext } = this.props;

        if ( !planId && type.planId && type.planId !== planId ) {
            return this.displayOverrideDialog( type.planId, this.saveType.bind(this,callback));
        }
       
        self.setState({ progress: <CircularProgress size={20} />, error: null });
        appContext.stores.DataTypesStore.saveProcedure(type, planId).then((response) => {
            self.setState({ type:response, modified: false, progress: null }, callback );
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error)});
        });
    }

    deleteType() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Procedure: {`${self.state.type.name}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDeleteType() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDeleteType() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDeleteType() {
        this.setState({ dialog: null });
    }
    confirmDeleteType() {
        const { appContext } = this.props;
        const { type } = this.state;

        this.setState({ dialog: null });
        if (type.procedureId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            appContext.stores.DataTypesStore.deleteProcedure(type.procedureId, type.planId).then(() => {
                this.setState({ progress: null });
                this.closeType();
            }).catch((error) => {
                this.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    updateType(item) {
        let { type } = this.state;
        type.name = item.name;
        type.code = item.code;
        type.system = item.system;
        this.setState({ modified: true, dialog: null, type });
    }
    handleCheckBoxChange = (e) => {
        let { type } = this.state
        type[e.target.name] = !type[e.target.name]
        this.setState({ dialog: null, type, modified: true });
    }

    showJSON(){
        const self = this;
        const { type } = this.state;
        let { appContext } = this.props;
        self.setState({
          dialog: <JsonDialog
            appContext={appContext}
            dataType={type}
            onEditDone={(type) => {
              self.setState({ type, dialog: null });
            }}
            onDone={(dataType) => {
              self.setState({ dialog: null });
            }} />
        });
    }

    search(args) {
        let self = this;
        let { appContext } = this.props;
        let { code, system, search } = args;
        var dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <TypeSearch
                appContext={appContext}
                searchType={'procedures'}
                code={code}
                system={system}
                search={search}
                closeSearch={() => self.closeSearch()}
                itemSelected={(item) => self.updateType(item)} />
        </Drawer>;
        self.setState({ progress: null, dialog: dialog });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { type, progress, error, dialog } = this.state;
        const disableDelete = type ? type.planId !== appContext.state.plan.planId : true;

        return (
            <div align="center" width='500'>
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeType()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Procedure Type</Typography>
                    </Toolbar>
                </AppBar>
                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    Procedure ID: {type.procedureId}
                                </td>
                                <td align='right'>
                                    <span style={{color: 'red'}}>{error}</span>
                                    <IconButton disabled={progress !== null} onClick={this.saveType.bind(this, null, null)}>{progress || <SaveIcon />}</IconButton>
                                    <IconButton onClick={() => {self.showJSON();}}><CodeIcon /></IconButton>
                                    <IconButton disabled={disableDelete} onClick={this.deleteType.bind(this)}><DeleteIcon /></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <TextField style={styles.question} label="Name" value={type.name}
                                        onChange={(e) => { type.name = e.target.value; self.setState({ type, modified: true }); }} />
                                    <IconButton disabled={!type.name} onClick={() => { self.search( { search: type.name }); }} ><SearchIcon /></IconButton> 
                                    <br />
                                    <TextField style={styles.question} label="Code ID" value={type.code}
                                        onChange={(e) => { type.code = e.target.value; self.setState({ type, modified: true }); }} />
                                    <IconButton disabled={!type.code} onClick={() => { self.search( { system: type.system, code: type.code }); }} ><SearchIcon /></IconButton> 
                                    <br />
                                    <TextField style={styles.question} label="System" value={type.system}
                                        onChange={(e) => { type.system = e.target.value; self.setState({ type, modified: true }); }} />
                                    <br />
                                    <SelectProcedure appContext={appContext} procedureId={type.parentId} label={'Parent Procedure'} enableNone={true}
                                        onSelect={(t) => { 
                                            let parentId = t ? t.procedureId : '';
                                            if ( parentId !== type.parentId ) {
                                                type.parentId = parentId;
                                                self.setState({ type, modified: true });
                                            } 
                                        }} />
                                    <br/>
                                    <td colspan='2'>
                                        <FormControl  style={styles.status} >
                                        <FormControlLabel key='hidden' label='Hidden' control={
                                            <Checkbox name="hidden" checked={type.hidden} 
                                            onChange={self.handleCheckBoxChange } />
                                        }/>
                                        </FormControl>
                                    </td>
                                    <br/>
                                    <ChangedByButton appContext={appContext} primaryKey={type.procedureId} collection='ProcedureTypeModel' />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}

            </div >
        );
    }
}

const styles = {
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    question: {
        margin: 5,
        width: '80%'
    },
    select: {
        margin: 10,
        width: 200
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
    menuList: {
        width: '500px',
        justifyContent: 'left'
        // backgroundColor: 'pink',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    menuExpansionSummary: {
        justifyContent: 'left'
    },
    menuExpansionDetails: {
        width: '800px',
        background: '#ffffff',
        padding: '0px'
    },
    menuExpansion: {
        width: '800px',
        background: '#ffffff',
        justifyContent: 'left'
    },
}

export default ProcedureTypeView;
