import React, { useState, useEffect } from 'react';

import {
    withStyles,
    Dialog, DialogContent, DialogActions, Button, TextField, DialogTitle,
    Typography, CircularProgress,
    Checkbox
} from '@material-ui/core/';
import { createIdFromText } from "../utils/CreateIdFromText";
import {
    isArrayValid,
} from '@apricityhealth/web-common-lib/utils/Services';

const DATA_TYPES = ["Alert", "Score", "Grade", "Priority"];
const CATEGORIES = {
    "Alert": "alert",
    "Score": "score",
    "Grade": "grade",
    "Priority": "status"
}
const MODEL_ATTRIBUTES = {
    "Model": "modelDataId",
    "Alert": "alertDataId",
    "Score": "scoreDataId",
    "Grade": "gradeDataId",
    "Priority": "priorityDataId"
}

function GenerateDetectDataTypes({ classes, style, model, appContext, onDone }) {
    const [dialogOpen, setDialogOpen] = useState(null);
    const [progress, setProgress] = useState(null);
    const [hasWarning, setWarning] = useState(false);
    const [usedIds, setUsedIds] = useState([]);
    const [prefix, setPrefix] = useState("");
    const [name, setName] = useState("");
    const [selectedDataTypes, setSelectedDataTypes] = useState(DATA_TYPES);
    const store = appContext.stores.DataTypesStore;

    let allDataTypes = store.getDataTypes();;

    useEffect(() => {
        let usedIds = [];
        DATA_TYPES.forEach((type) => {
            let name = prefix + type;
            let item = allDataTypes.find((item) => item.dataId === name);
            if (item) {
                usedIds.push(name);
            }
        });
        //model is a special case
        let name = prefix + "Model";
        let item = allDataTypes.find((item) => item.dataId === name);
        if (item) {
            usedIds.push("Model");
        }

        setUsedIds(usedIds);
        setWarning(usedIds.length > 0);
    }, [prefix]);

    async function onOk() {
        setProgress(<CircularProgress size={20} />);
        let typesToCreate = {};

        let planId = appContext && appContext.state && appContext.state.plan && appContext.state.plan.planId;
        let modelDataType = {
            dataId: prefix + "Model",
            name: name + " Model",
            planId: planId,
            description: name + " Model",
            category: 'model',
            tupleDescriptions: [],
            valueDescriptions: []
        }
        typesToCreate["Model"] = modelDataType;

        selectedDataTypes.forEach((type) => {
            let newType = createDataType(type, modelDataType.dataId, planId);
            typesToCreate[type] = newType;
        })

        Object.keys(typesToCreate).forEach(key => {
            model[MODEL_ATTRIBUTES[key]] = typesToCreate[key].dataId
        });
        let promises = [];
        Object.values(typesToCreate).forEach(type => {
            promises.push(saveType(type));
        });
        let types = await Promise.all(promises);
        const linkStore = appContext.stores.DataTypesStore;
        linkStore.loadDataTypes();

        setProgress(null);
        if (typeof onDone === 'function')
            onDone({ model, types });

        setDialogOpen(false);
    }

    function saveType(dataType) {
        updateTupleDescriptions(dataType);
        const linkStore = appContext.stores.DataTypesStore;
        let planId = appContext && appContext.state && appContext.state.plan && appContext.state.plan.planId;

        if (isArrayValid(dataType.tupleDescriptions)) {
            let tupleUpdates = [];
            for (let i = 0; i < dataType.tupleDescriptions.length; ++i) {
                let tupleDesc = dataType.tupleDescriptions[i];
                if (tupleDesc.oldIndex !== tupleDesc.index) {
                    tupleUpdates.push({ oldIndex: tupleDesc.oldIndex, newIndex: tupleDesc.index });
                }
            }

            if (tupleUpdates.length > 0) {
                try {
                    linkStore.updateTupleIndexes(dataType.dataId, tupleUpdates);
                } catch (err) {
                    throw err;
                }
            }
        }
        return linkStore.saveDataType(dataType, planId)
    }

    function updateTupleDescriptions(dataType) {
        let tupleDescriptions = require('../data/DataTypeTupleDescriptionTemplates.json');
        let tupleDescriptionItem = tupleDescriptions.tupleDescriptionItems.find((tupleDescriptionItem) => {
            return (tupleDescriptionItem.id === dataType.category)
        })

        if (tupleDescriptionItem) {
            tupleDescriptionItem = JSON.stringify(tupleDescriptionItem)
            tupleDescriptionItem = tupleDescriptionItem.replace(/{{name}}/g, dataType.name)
            tupleDescriptionItem = JSON.parse(tupleDescriptionItem)
            dataType.tupleDescriptions = tupleDescriptionItem.tupleDescriptions
        }
    }

    function onCancel() {
        console.log(`cancel `);
        setDialogOpen(false);
    }

    function createDataType(type, parentId, planId) {
        return {
            dataId: prefix + type,
            name: name + " " + type,
            planId: planId,
            description: name + " " + type,
            category: CATEGORIES[type],
            parentId,
            tupleDescriptions: [],
            valueDescriptions: []
        }
    }

    return (
        <span >
            <Button style={style || null} variant="contained" onClick={() => setDialogOpen(true)}>Generate</Button>
            <Dialog model="true" open={dialogOpen}>
                <DialogTitle>{model && model.name}: Data Types Generation {progress}</DialogTitle>
                <DialogContent>
                    <Typography>This will generate the data types needed in support of the Detect Model.</Typography>
                    <div >
                        <div className={classes.grid}>
                            <Checkbox
                                key={"model"}
                                disabled={true}
                                checked={true}
                                value={"Model"}
                            />
                            <div className={classes.grid1}>{"Model"}</div>
                            <div className={classes.grid2} data-found={usedIds.find((item) => item === "Model") ? 1 : 0}>{prefix && prefix + "Model"}</div>
                        </div>

                        {DATA_TYPES.map((type) => {
                            let name = prefix + type;
                            let found = usedIds.find((item) => item === name);
                            return <div className={classes.grid}>
                                <Checkbox
                                    key={type}
                                    onChange={(e) => {
                                        if (selectedDataTypes.includes(type)) {
                                            setSelectedDataTypes(selectedDataTypes.filter(item => item !== type))
                                        } else {
                                            setSelectedDataTypes([type, ...selectedDataTypes])

                                        }
                                    }}
                                    checked={selectedDataTypes.includes(type)}
                                    value={type}
                                />

                                <div className={classes.grid1}>{type}</div>
                                <div className={classes.grid2} data-found={found ? 1 : 0}>{prefix && selectedDataTypes.includes(type) && name}</div>
                            </div>
                        })}
                    </div>
                    <br></br>
                    <Typography ></Typography>
                    <TextField
                        className={classes.name}
                        label={"Name"}
                        error={hasWarning}
                        helperText={hasWarning ? "Prefix is in use." : `Enter the name (with spaces) for the model and the dataId will be generated.`}
                        value={name}
                        onChange={(e) => { setName(e.target.value); setPrefix(createIdFromText(e.target.value)) }} />

                </DialogContent>
                <DialogActions>
                    <Button disabled={hasWarning || progress || selectedDataTypes.length < 1} className={classes.button} variant="contained" onClick={onOk}>Ok</Button>
                    <Button className={classes.button} variant="contained" onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </span >
    );
}

const styles = ({ palette }) => ({
    button: {
        margin: '1rem',
    },
    warning: {
        color: "red",
    },
    typePreview: {
        color: "grey",
        marginLeft: '2rem'
    },
    dataTypes: {
        background: 'yellow',
        width: '100%'
    },
    name: {
        width: '100%'
    },
    grid: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    grid1: {
        flexGrow: 1
    },
    grid2: {
        minWidth: '50%',
        color: 'gray',
        '&[data-found="1"]': {
            color: 'red',
        }
    }

});

export default withStyles(styles)(GenerateDetectDataTypes);