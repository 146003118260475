import React, { Component } from "react";
import PropTypes from 'prop-types';
import '../styles/content.css';

import {
    withStyles,
    TextField,
    IconButton,
    Drawer,
    Tooltip,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    Button
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import TranslateIcon from '@material-ui/icons/Translate';
import ExportIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/LinkOff';
import EditIcon from '@material-ui/icons/Edit';

import {
    isArrayValid
} from '../utils/Utils';

import SelectLanguage from '@apricityhealth/web-common-lib/components/SelectLanguage';
import ImportExport from '@apricityhealth/web-common-lib/components/ImportExport';

import { v4 as uuidv4 } from 'uuid';
import PubSub from 'pubsub-js'

import TranslationDialog from '../dialogs/TranslationDialog';
import SelectCategory from './SelectCategory';
import ContentExportDialog from "../dialogs/ContentExportDialog";
import TextView from './TextView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import { DataLinkIcon } from '../dialogs/DataLinksDialog';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

const Promise = require("bluebird");

function filterText(filter, text) {
    if (text.textId && text.textId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (text.text && text.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;

    return false;
}

class TextsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            store: props.appContext.stores.DataTypesStore,
            dependencies: false,
            unlinked: false,
            noEnglish: false,
            filter: "",
            language: 'en-us',
            category: 'All',
            categories: ['All'],
            dialog: null,
            dialogTextIds: [],
            plan: null
        };

    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('STORE_UPDATE', this.loadContent.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext } = this.props;

        const { plan } = appContext.state;        
        if ( plan ) {
            const { store, dependencies, unlinked, language, category, filter, noEnglish} = this.state;
            const { planId } = plan;

            let texts = store.getTexts().filter((e) => (dependencies === true || e.planId === planId) ).sort((a, b) => {
                if (a.textId < b.textId)
                    return -1;
                else if (a.textId > b.textId)
                    return 1;
                return 0;
            });

            let categories = [];
            if (isArrayValid(texts)) {
                for (let j = 0; j < texts.length; j++) {
                    let text = texts[j];
                    if (text.category && !categories.includes(text.category))
                        categories.push(text.category);
                }
            }
            categories.sort();
            categories.unshift('All');

            console.log("category:", category );
            texts = texts.filter((e,i,a) => {
                if ( noEnglish ) {
                    if ( e.language !== 'en-us' && !a.find((k) => k.textId === e.textId && k.language === 'en-us')) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if ( category !== 'All' && e.category !== category ) return false;
                if ( language !== '*' && e.language !== language ) return false;
                if ( filter && !filterText(filter, e)) return false;
                if ( unlinked && store.getTextLinks(e.textId).links.length > 0) return false;
                return true;
            });

            this.setState({ texts, categories });
        }
    }

    importRows(rows) {
        console.log("importRows:", rows);
        const { appContext } = this.props;
        return new Promise((resolve, reject) => {
            this.setState({error: null});
            Promise.map(rows, (row, index, size) => {
                this.setState({ dialog: <Dialog open={true}><DialogContent><br />Importing {index} of {size} rows...<br /><br /></DialogContent></Dialog>});
                if (!row.textId || !row.category || !row.language || row.en_version === undefined ) {
                    console.error("Invalid row:", row );
                    throw new Error(`Invalid row: ` + JSON.stringify(row) );
                }
                const text = {
                    textId: row.textId,
                    language: row.language,
                    version: row.en_version,        // use the english version, that we translated from to set the new version
                    category: row.category,
                    text: row.text,
                }
                return appContext.stores.DataTypesStore.saveText(text);
            }).then((results) => {
                console.log("importDone:", results);
                this.setState({ dialog: null }, this.loadContent.bind(this));
                resolve();
            }).catch((error) => {
                this.setState({ dialog: null, error: getErrorMessage(error) });
                reject(error);
            });
        });
    }

    onCloseDialog(reload = true) {
        const { store } = this.state;
        this.setState({ dialog: null }, reload ? store.loadDataTypes.bind(store) : null)
        if (this._plans)
            this._plans.setState({ selected: [] });        // unselect the question
    }

    onCancel() {
        this.setState({ dialog: null });
        if (this._plans)
            this._plans.setState({ selected: [] });        // unselect the question
    }

    onEdit(text) {
        return <IconButton onClick={() => {
            const { appContext } = this.props;
            let dialog = <Drawer variant="persistent" anchor="right" open={true} >
                <TextView appContext={appContext} text={text} onClose={this.onCancel.bind(this)} onDone={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ dialog });
        }}><EditIcon /></IconButton>
    }

    onDelete(text) {
        console.log("onDelete:", text, this );
        const { appContext } = this.props;
        if ( this.deleteQueue === undefined ) this.deleteQueue = [];
        this.deleteQueue.push(text);

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Please confirm you want to delete text(s) {this.deleteQueue.reduce( (p, v) => { if ( p ) p += ', '; p += v.textId; return p;}, '')}? 
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog.bind(this, false)}>Cancel</Button>
                <Button onClick={() => {
                    this.onCloseDialog(false);
                    this.setState({error: null});

                    Promise.map(this.deleteQueue, (text) => {
                        return appContext.stores.DataTypesStore.deleteText(text.textId, text.language, text.planId);
                    }, { concurrency: 5 }).then(() => {
                        this.deleteQueue = [];
                        this.loadContent();
                    }).catch((err) => {
                        this.deleteQueue = [];
                        this.setState( { error: getErrorMessage(err) });
                    })
                }}>Delete</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog});
    }

    onDeleteUnlinked() {
        const self = this;
        const { appContext } = this.props;
        const { texts } = this.state;
        let unlinked = [];
        for(let i=0;i<texts.length;++i) {
            if ( appContext.stores.DataTypesStore.getTextLinks(texts[i].textId).links.length === 0 ) {
                unlinked.push( texts[i] );
            }
        }

        let dialog = <Dialog open={true}>
            <DialogTitle>Delete unlinked text</DialogTitle>
            <DialogContent>{`Found ${unlinked.length} unlinked text entries, the following will be deleted:`}
            <br /><br />
            <div className="display-linebreak"><b>{unlinked.map((e) => e.textId).join('\n')}</b></div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => this.setState({dialog: null})}>Cancel</Button>
                <Button variant='contained' onClick={() => {
                    self.setState({dialog: null, progress: <CircularProgress size={20} />, error: null});
                    Promise.map(unlinked, (unlink) => {
                        return appContext.stores.DataTypesStore.deleteText(unlink.textId, unlink.language, unlink.planId)
                    }, { concurrency: 5}).then(() => {
                        self.setState({progress: null}, self.loadContent.bind(self));
                    }).catch((err) => {
                        self.setState({progress: null, error: getErrorMessage(err)});
                    })
                }}>Confirm</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog});
    }

    createContent() {
        const self = this;
        const { appContext } = this.props;
        const { language } = this.state;
        const newText = {
            textId: uuidv4(),
            version: 0,
            category: "new category",
            text: "new text",
            language
        };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <TextView appContext={appContext} text={newText} onClose={() => self.onCancel()} onDone={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ dialog });
    }

    render() {
        const { error, store, texts, categories, dialog, language, category, filter, dependencies, unlinked, noEnglish } = this.state;
        const { appContext } = this.props;

        let importButton = <Tooltip title="Import">
            <ImportExport
                asText={true}
                note={"If importing translations please ensure the CSV is UTF encoded so languages will import. The schema should match the export schema.. please check"}
                ignoreExport={true}
                name={`Message Content Translations`}
                exportStart={() => {
                }}
                exportRow={() => {
                }}
                importStart={() => {
                    return Promise.resolve();
                }}
                importRows={(rows) => {
                    return this.importRows(rows);
                }}
                importRow={() => {
                }}
                importDone={() => {
                }}
            />
        </Tooltip>;

        let translateButton = <Tooltip title="Translate Content"><IconButton
            onClick={(e) => {
                this.setState({
                    dialog:
                        <TranslationDialog
                            content={texts}
                            appContext={this.props.appContext}
                            onCancel={() => {
                                this.setState({ dialog: null })
                            }}
                            onDone={() => {

                                this.setState({ texts, dialog: null })
                            }}
                        />
                })
            }}>
            <TranslateIcon />
        </IconButton></Tooltip>;

        let exportButton = <Tooltip title="Export"><IconButton
            onClick={(e) => {
                this.setState({
                    dialog:
                        <ContentExportDialog
                            content={texts}
                            appContext={this.props.appContext}
                            onCancel={() => {
                                this.setState({ dialog: null })
                            }}
                            onDone={() => {
                                this.setState({ texts, dialog: null })
                            }}
                        />
                })
            }}>
            <ExportIcon />
        </IconButton></Tooltip>;

        const columnData = [
            { id: 'textId', label: 'Text ID' },
            { id: 'language', label: 'Language' },
            { id: 'category', label: 'Category' },
            { id: 'text', label: 'Text', formatValue: (v) => v.substring(0, 128) },
            {
                id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                    return <Plan appContext={appContext} planId={v} />
                }
            },
            { id: 'links', label: 'Links', formatValue: (v,r) => {
                return <DataLinkIcon appContext={appContext} textId={r.textId} style={{topMargin: 0, bottomMargin: 5}} />
            } }
        ];
        const languages = require('../data/Languages.json');

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 400 }} value={filter} label="Filter" onChange={(event) => {
                            this.setState({ filter: event.target.value }, () => {
                                if ( this.updateFilter ) clearTimeout(this.updateFilter);
                                this.updateFilter = setTimeout(this.loadContent.bind(this), 250);
                            }) }} />
                        <SelectCategory appContext={appContext} category={category} categories={categories} onChange={(newCategory) => {
                            this.setState({ category: newCategory }, this.loadContent.bind(this));
                        }} />
                        <SelectLanguage appContext={appContext} enableAll={true} language={language} languages={languages} onChange={(newLanguage) => {
                            this.setState({ language: newLanguage }, this.loadContent.bind(this) );
                        }} />
                        <br />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={dependencies} onChange={(e, v) => {
                            this.setState({ dependencies: v }, this.loadContent.bind(this));
                        }} />} label="Show Dependencies" />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={unlinked} onChange={(e, v) => {
                            this.setState({ unlinked: v }, this.loadContent.bind(this));
                        }} />} label="Show Unlinked" />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={noEnglish} onChange={(e, v) => {
                            this.setState({ noEnglish: v }, this.loadContent.bind(this));
                        }} />} label="Show No English" />
                    </td>
                    <td align="right">
                        <span style={{color: 'red'}}>{error}</span>
                        <IconButton disabled={store.progress !== null} onClick={store.loadDataTypes.bind(store)}>{store.progress || <RefreshIcon />}</IconButton>
                        <Tooltip title='Delete unlinked text'><IconButton onClick={this.onDeleteUnlinked.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                        {translateButton}
                        {exportButton}
                        {importButton}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={false}
                            onTable={table => this._plans = table}
                            orderBy='tags'
                            columnData={columnData}
                            data={texts}
                            onAdd={() => this.createContent()}
                            onDelete={this.onDelete.bind(this)}
                            onActions={(table, numSelected, actions) => {
                                if (numSelected > 0 ) {
                                    if ( numSelected === 1) {
                                        const { selected, data } = table.state;
                                        let instance = data[selected[0]];
                                        actions.unshift( this.onEdit(instance) );
                                    }
                                }
                            }}
                            title='Text Content' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

const styles = {
    div: {
        margin: 10
    },
    buttonHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        align: 'center',
        flexWrap: 'wrap',
        marginLeft: 20,
        marginTop: 10,
    },
    spacer: {
        flexGrow: 1
    },
    progress: {
        color: 'red'
    }
};

TextsView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(TextsView);
