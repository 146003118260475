import React, { Component } from 'react';
import { Drawer,
    CircularProgress, 
    TextField, 
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core/';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import RefreshIcon from '@material-ui/icons/Refresh';

import Plan from '@apricityhealth/web-common-lib/components/Plan';

import GraphTypeView from './GraphTypeView';
import {
    loadGraphs,
} from '@apricityhealth/web-common-lib/utils/Services'
import {
    isArrayValid
} from '../utils/Utils';

import PubSub from 'pubsub-js'

function filterGraph(filter, graph) {
    if (graph.graphId && graph.graphId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (graph.name && graph.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (graph.description && graph.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (graph.category && graph.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (graph.dataId && graph.dataId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (isArrayValid(graph.tags)) {
        for (var i = 0; i < graph.tags.length; ++i) {
            if (graph.tags[i].toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                return true;
        }
    }

    return false;
}

export class GraphTypesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            graphs: []
        }


    }
    
    componentDidMount() {
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        let { appContext } = this.props;
        let { dependencies } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadGraphs(appContext, { dependencies } ).then((response) => {
            self.setState({ graphs: response, progress: null }, self.updateFilter.bind(self));
        }).catch((err) => {
            self.setState({ progress: null, error: err.message });
        });
    }
    updateFilter() {
        let { filter, graphs } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < graphs.length; ++i) {
                if (filterGraph(filter, graphs[i]))
                    filtered.push(graphs[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });
    }

    selectGraph(s, t) {
        console.log("selectRule:", s, t);
        if (s.length > 0) {
            const graph = this.state.filtered ? this.state.filtered[s[0]] : this.state.graphs[s[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <GraphTypeView appContext={this.props.appContext} graph={graph} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    createGraph() {
        console.log("createRule");
        let newGraph = { name: '', graphId: '', dataId: '', description: '', tags: [], order: 0, tupleIndex: 0, category: '', graphType: '' };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <GraphTypeView appContext={this.props.appContext} graph={newGraph} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    render() {
        const self = this;
        let { filter, filtered, graphs, dialog, error, progress, dependencies } = this.state;

        const columnData = [
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'category', numeric: false, disabledPadding: false, label: 'Category' },
            { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data ID' },
            { id: 'tupleIndex', numeric: false, disabledPadding: false, label: 'Tuple Index' },
            { id: 'disabled', numeric: false, disabledPadding: false, label: 'Disabled'},
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={styles.text} value={filter} label="Filter"
                            onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._types = table}
                            onSelected={this.selectGraph.bind(this)}
                            onAdd={this.createGraph.bind(this)}
                            orderBy='name'
                            columnData={columnData}
                            data={filtered ? filtered : graphs}
                            title='Graph Types' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 500
    },
}

export default GraphTypesView;
