import React, { Component } from "react";
import preventClickthrough from 'react-prevent-clickthrough';

import {
    IconButton, CircularProgress, MenuItem, FormControl, InputLabel, Select,
    Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, Button, TextField,
    Switch, Tooltip
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';
import CreateIcon from '@material-ui/icons/Create';
import WarningIcon from '@material-ui/icons/Warning';
import SortableTree, { toggleExpandedForAll, walk } from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

import {
    isArrayValid,
    arrayInsertLocation,
    getLocationByRecommendId,
} from '../utils/Utils';
import {
    loadRecommendationModels,
    loadRecommendations,
    loadPlans
} from '@apricityhealth/web-common-lib/utils/Services';

import ReactSelect, { components } from "react-select";
import RecommendationTypesView from './RecommendationTypesView';
import EditCategoryDialog from '../dialogs/EditCategoryDialog';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import { parseCode } from '@apricityhealth/web-common-lib/utils/ParseCode';
import { buildCode } from '@apricityhealth/web-common-lib/utils/BuildCode';

import '../styles/workup.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const { Placeholder } = components;

const TYPE_TEMPLATES = require('../data/TypeTemplates.json');

const getNodeKey = ({ node: { id, treeId } }) => treeId;

class SymptomRecommendComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowModelSelect: this.props.allowModelSelect || false,
            originalRecommendModel: null,
            dialog: null,
            drawerOpen: false,
            wideDrawer: false,
            expandAll: false,
            hasError: false,
            selectedModel: null,
            recommendations: [],
            cloneRecommend: false,
            preserveRecommend: true
        };
        this.expanded = {};
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (this.props.priorityDataId !== oldProps.priorityDataId) {
            this.onSymptomChanged();
        }
    }


    loadContent() {
        const self = this;
        const { appContext } = this.props;
        let { selectedModel } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        let promises = [];
        promises.push(loadRecommendations(appContext, { dependencies: true }));
        promises.push(loadRecommendationModels(appContext, { dependencies: false, planId: "*", resolveConflicts: false }));
        promises.push(loadPlans(appContext));
        Promise.all(promises).then(([recommendations, recommendModels, plans]) => {
            let items = [];
            recommendModels.forEach(recModel => {
                let plan = plans.find((plan) => plan.planId === recModel.planId);
                let planName = plan ? plan.title : "";
                let state = plan.protected ? "prot" : "edit";
                let label = recModel.name ? `${recModel.name} (${planName} - ${state})` : `${recModel.modelId} (${planName} - ${state})`;
                let value = recModel.modelId + "-" + recModel.category + "-" + planName + "-" + state;
                let item = { value, modelId: recModel.modelId, label };

                items.push(item);
            });

            let priorityDataTypeMap = {};
            recommendModels.forEach(nextModel => {
                if (!nextModel.groups) nextModel.groups = [];
                nextModel.groups.forEach(group => {
                    let funct = parseCode(group.dataConditions);
                    if (funct && isArrayValid(funct.functions)) {
                        let isDataInRange = funct.functions[0];
                        if (isDataInRange.functionName === "isDataInRange" && isArrayValid(isDataInRange.args))
                            priorityDataTypeMap[isDataInRange.args[1].string] = nextModel;
                    }
                });
            });

            items.sort((a, b) => {
                if (a.label < b.label)
                    return -1;
                else if (a.label > b.label)
                    return 1;
                return 0;
            });

            items.unshift({ value: 'none', modelId: 'none', label: "<None>" }, { value: 'newRecommendModel', modelId: 'newRecommendModel', label: "<New Model>" });
            if (!selectedModel) {
                selectedModel = items[0];
            }

            self.setState({ priorityDataTypeMap, recommendations, selectedModel, recommendModels, items }, () => this.onSymptomChanged());
        }).catch((err) => {
            self.setState({ error: getErrorMessage(err) });
        });
    }


    loadRecommendations() {
        const self = this;
        const { appContext } = this.props;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadRecommendations(appContext, { dependencies: true }).then((recommendations) => {
            self.setState({ recommendations }, () => this.initalizeTreeData());
        }).catch((err) => {
            self.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    onChange() {
        let { recommendModel, originalRecommendModel, cloneRecommend, preserveRecommend } = this.state;
        if (typeof this.props.onChange === 'function')
            this.props.onChange({ recommendModel, originalRecommendModel, cloneRecommend, preserveRecommend });
    }

    onSymptomChanged() {
        let { priorityDataId } = this.props;
        let { items, selectedModel, originalRecommendModel, priorityDataTypeMap } = this.state;
        let recommendModel = priorityDataTypeMap[priorityDataId];
        if (!recommendModel) {
            selectedModel = items[0];
        } else {
            selectedModel = items.find(item => item.modelId === recommendModel.modelId);
            recommendModel = JSON.parse(JSON.stringify(recommendModel));
            originalRecommendModel = JSON.parse(JSON.stringify(recommendModel));
        }

        this.setState({ recommendModel, originalRecommendModel, selectedModel }, () => { this.initalizeTreeData(); this.onChange(); });
    }


    onModelTemplateSelected(selectedModel) {
        let { planId } = this.props;
        let { recommendModels } = this.state;

        let recommendModel = null;
        if (selectedModel.modelId === "none") {
            recommendModel = null;
        } else if (selectedModel.modelId === "newRecommendModel") {
            recommendModel = this.newRecommendModel("New Recommend Model", 'newRecommendModel', planId);
        } else {
            recommendModel = recommendModels.find(nextModel => nextModel.modelId === selectedModel.modelId);
        }

        if (recommendModel)
            recommendModel = JSON.parse(JSON.stringify(recommendModel));

        this.setState({ recommendModel, selectedModel }, () => { this.initalizeTreeData(); this.onChange(); });
    }


    newRecommendModel(name, modelId, planId) {
        let { priorityDataId } = this.props;
        let model = TYPE_TEMPLATES.recommendModels["symptomRecommendModel"];
        model.modelId = modelId;
        model.planId = planId;
        model.name = name;
        model.description = `Recommend model for ${name}`;
        if (isArrayValid(model.groups)) {
            model.groups.forEach(group => {
                let funct = parseCode(group.dataConditions);
                let isDataInRange = funct.functions[0];
                isDataInRange.args[1] = { string: priorityDataId, type: "'" };
                group.dataConditions = buildCode(funct);
                group.name = `Priority ${isDataInRange.args[3].string}-${isDataInRange.args[4].string}`
            });
        }
        return model;

    }

    initalizeTreeData() {
        const self = this;
        const { recommendModel } = this.state;
        const { appContext: { stores: { DataTypesStore }} } = this.props;

        let hasError = false;
        let treeData = [];
        if (recommendModel && isArrayValid(recommendModel.groups)) {
            for (let i = 0; i < recommendModel.groups.length; i++) {
                let group = recommendModel.groups[i];
                let treeId = group._id || group.name + ":" + group.type;

                let type = DataTypesStore.getRecommendGroupTypes().find((e) => e.typeId === group.type);
                let { nodes, error } = this.getGroupChildren(group);
                if (error) hasError = true;
                let node = {
                    nodeType: 'group',
                    group,
                    error: type ? false : true, //missing recommendation type
                    text: `${group.name} (${type ? type.name : group.type})`,
                    treeId,
                    children: nodes,
                    expanded: this.expanded[treeId]
                };


                treeData.push(node);
            }
        }
        self.setState({ treeData, hasError });
    }

    getGroupChildren(group) {
        const { appContext: { stores: { DataTypesStore }} } = this.props;
        
        let children = [];
        let hasError = false;
        if (isArrayValid(group.categories)) {
            for (let i = 0; i < group.categories.length; ++i) {
                let category = group.categories[i];
                let treeId = category._id || category.name + ":" + group.type;;

                let cat = DataTypesStore.getRecommendCategories().find((e) => e.categoryId === category.name);
                let { nodes, error } = this.getCategoryChildren(group, category);
                hasError = error;
                let node = {
                    nodeType: 'category',
                    category,
                    group,
                    text: cat ? cat.name : category.name,
                    error: cat ? false : true, //missing category type
                    treeId,
                    children: nodes,
                    expanded: this.expanded[treeId]
                }

                children.push(node);
            }
        }
        return { nodes: children, error: hasError }
    }

    getCategoryChildren(group, category) {
        const { recommendations } = this.state;
        let error = false;
        let nodes = [];
        if (isArrayValid(category.recommendations)) {
            let recs = category.recommendations;
            for (let i = 0; i < recs.length; ++i) {
                let recommendation = recs[i];
                // let treeId = recommendation._id || recommendation.recommendId; //this may not work since can have more than one of the same recommendation

                let type = recommendations.find((e) => e.recommendId === recommendation.recommendId);
                // update the data in the model with the latest name from the type
                if (type) recommendation.name = type.name;
                if (!type) error = true;
                let node = {
                    nodeType: 'recommendation',
                    recommendation,
                    category,
                    group,
                    text: type && type.name ? type.name.substring(0, 80) : recommendation.name,
                    subtitle: type ? undefined : 'Recommendation type not found!',
                    error: type && type.name ? false : true,
                    children: []
                };
                nodes.push(node)
            }
        }
        return { nodes, error };
    }


    onAddGroup() {
        let { recommendModel } = this.state;
        let newGroup = {
            type: 'treatmentAdvisory',
            name: `Priority 1-4`,
            description: `Treatment Advisory`,
            dataConditions: "isDataInRange(context,'priorityDataId','0','1','4','-1','true','','')",
            categories: [{
                recommendations: [

                ],
                name: "pearls"
            }],

        }
        recommendModel.groups.splice(0, 0, newGroup);
        this.setState({ recommendModel }, () => { this.initalizeTreeData(); this.onChange(); })
    }

    onDeleteGroup(group) {
        let { recommendModel } = this.state;
        let groupIndex = recommendModel.groups.indexOf(group);
        if (groupIndex >= 0) {
            recommendModel.groups.splice(groupIndex, 1);
            this.setState({ recommendModel }, () => { this.initalizeTreeData(); this.onChange(); })
        }
    }

    onEditGroup(recommendModel, group, e) {
        preventClickthrough(e);
        let { appContext, priorityDataId } = this.props;
        //reset this to the default
        if (!group.dataConditions)
            group.dataConditions = `isDataInRange(context,'${priorityDataId}','0','1','4','-1','true','','')`;
        this.setState({
            dialog: <EditGroupDialog
                group={group}
                appContext={appContext}
                onCancel={() => {
                    this.setState({ dialog: null });
                }}
                onChange={() => {
                    this.setState({ recommendModel }, () => { this.initalizeTreeData(); this.onChange(); })
                }}
                onDone={() => {
                    this.setState({ dialog: null });
                }}
            />
        });
    }

    onAddCategory(group) {
        let { recommendModel } = this.state;
        group.categories.splice(0, 0, {
            name: "New Category",
            recommendations: [],
        });
        this.setState({ recommendModel }, () => { this.initalizeTreeData(); this.onChange(); })

    }


    onEditCategory(category) {
        let self = this;
        let { recommendModel } = this.state;
        this.setState({
            dialog: <EditCategoryDialog
                style={{ width: '100%' }}
                category={category}
                appContext={this.props.appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(update) => {
                    for (let k in update)
                        category[k] = update[k];
                    this.setState({ dialog: null, recommendModel }, () => { this.initalizeTreeData(); this.onChange(); })
                }} />
        });
    }


    canDrop(args) {
        const { node, nextParent } = args;
        if (node.nodeType === 'group') {
            // groups can only be on the root
            return !nextParent;
        }
        else if (node.nodeType === 'category') {
            // categories can only be attached to a group
            return nextParent && nextParent.nodeType === 'group';
        }
        else if (node.nodeType === 'recommendation') {
            // recommendations can only be attached to a category
            return nextParent && nextParent.nodeType === 'category';
        }
        return false;
    }

    onDeleteCategory(group, category) {
        let { recommendModel } = this.state;
        let index = group.categories.indexOf(category);
        if (index >= 0) {
            group.categories.splice(index, 1);
            this.setState({ recommendModel }, () => { this.initalizeTreeData(); this.onChange(); })
        }
    }

    onDeleteRecommendation(category, recommendation) {
        let { recommendModel } = this.state;
        let index = category.recommendations.indexOf(recommendation);
        if (index >= 0) {
            category.recommendations.splice(index, 1);
            this.setState({ recommendModel }, () => { this.initalizeTreeData(); this.onChange(); })
        }
    }

    onEditRecommendation(category, recommendation) {
        const self = this;
        let { recommendModel } = self.state;
        self.setState({
            dialog: <EditRecommendationDialog
                appContext={self.props.appContext}
                recommendation={recommendation}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onChange={() => {
                    self.setState({ recommendModel }, () => { self.initalizeTreeData(); self.onChange(); })
                }}
                onDone={() => {
                    self.setState({ dialog: null });
                }}
            />
        });
    }

    getNodeProps(args) {
        let { node } = args;

        let title = node.text;
        let subtitle = null;
        let buttons = [];

        if (node.nodeType === 'group') {
            buttons.push(<IconButton onClick={this.onEditGroup.bind(this, this.state.recommendModel, node.group)}><CreateIcon /></IconButton>);
            buttons.push(<IconButton onClick={this.onAddCategory.bind(this, node.group)}><AddIcon /></IconButton>);
            buttons.push(<IconButton key='delete' onClick={this.onDeleteGroup.bind(this, node.group)}><DeleteIcon /></IconButton>);
        }
        else if (node.nodeType === 'category') {
            buttons.push(<IconButton key='edit' onClick={this.onEditCategory.bind(this, node.category)}><EditIcon /></IconButton>);
            buttons.push(<IconButton key='delete' onClick={this.onDeleteCategory.bind(this, node.group, node.category)}><DeleteIcon /></IconButton>);
        }
        else if (node.nodeType === 'recommendation') {
            buttons.push(<div key='edit' style={{ fontSize: '12px' }}>{node.recommendation.weight}<IconButton onClick={this.onEditRecommendation.bind(this, node.category, node.recommendation)}><CreateIcon /></IconButton></div>);
            buttons.push(<IconButton key='delete' onClick={this.onDeleteRecommendation.bind(this, node.category, node.recommendation)}><DeleteIcon /></IconButton>);
            if (node.recommendation && node.recommendation.explanation)
                subtitle = node.recommendation.explanation
        }

        let style = node.error ? { height: 40, border: 0, boxShadow: `0 0 0 2px tomato`, cursor: 'pointer' }
            : { height: 40, border: 0, cursor: 'pointer' };

        return {
            listIndex: 0,
            lowerSiblingCounts: [],
            style,
            title: (<div>{title}</div>),
            subtitle,
            buttons
        };
    }

    onChangeTree(treeData) {
        walk({
            treeData: treeData,
            getNodeKey,
            callback: (rowInfo) => {
                let node = rowInfo.node;
                if (node.treeId && node.expanded)
                    this.expanded[node.treeId] = node.expanded;
            },
        });
        this.setState({ treeData });
    }

    onMoveNode(args) {
        let { recommendModel } = this.state
        const { node, nextParentNode } = args;
        let isPoolDrop = node.isPool;

        if (isPoolDrop) {
            let recommendation = node.recommendation;
            let category = nextParentNode.category;

            let index = getLocationByRecommendId(nextParentNode.children, node.recommendation.recommendId);
            if (recommendation) {
                //Add the recommendation to the model
                let newChild = {
                    "weight": recommendation.weight,
                    "explanation": recommendation.explanation,
                    "name": recommendation.name,
                    "recommendId": recommendation.recommendId,
                    order: index
                };
                arrayInsertLocation(category.recommendations, index, newChild);
                for (let index = 0; index < category.recommendations.length; index++) {
                    category.recommendations[index].order = index;
                }
                this.expanded[nextParentNode.treeId] = true;
            }

            this.setState({ recommendModel }, () => { this.onChange(); this.initalizeTreeData() })
        }

    }

    onToggleEditRecommendations() {
        let { editRecommendations } = this.state;
        this.setState({ editRecommendations: !editRecommendations });
    }

    onToggleExpandAll() {
        let { expandAll, treeData } = this.state;
        const newTreeData = toggleExpandedForAll({
            treeData,
            expanded: !expandAll
        });
        walk({
            treeData: newTreeData,
            getNodeKey,
            callback: (rowInfo) => {
                let node = rowInfo.node;
                if (node.treeId)
                    this.expanded[node.treeId] = node.expanded;
            },
        });
        this.setState({ treeData: newTreeData, expandAll: !expandAll });
    }

    render() {
        const self = this;
        const { error, selectedModel, recommendModels, recommendModel, treeData, editRecommendations, expandAll, dialog, items, allowModelSelect, hasError, cloneRecommend, preserveRecommend } = this.state;
        let { appContext } = this.props;
        let loading = true
        if (isArrayValid(recommendModels))
            loading = false
        let showOptions = selectedModel && selectedModel.modelId !== 'none' && selectedModel.modelId !== 'newRecommendModel';

        return (
            <div>  {error}
                <table style={{ height: "500px", width: "100%" }}>
                    <tbody>
                        <tr >
                            <td valign='top' >

                                {allowModelSelect && <FormControl style={{ margin: 5, width: '500px' }}>
                                    <InputLabel>Select Model Template</InputLabel>
                                    <ReactSelect
                                        isLoading={loading}
                                        isSearchable
                                        value={selectedModel}
                                        options={items}
                                        placeholder={"Select Model Template (...all plans)"}
                                        onChange={(e) => this.onModelTemplateSelected(e)}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        styles={{
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                overflow: 'visible'
                                            }),
                                            container: (provided, state) => ({
                                                ...provided,
                                                marginTop: 20
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: -15,
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: '0.75rem',
                                                lineHeight: 1,
                                                marginLeft: -5
                                            })
                                        }}
                                    />
                                </FormControl>}
                            </td>
                            {showOptions && <td align="right" width="200px">
                                <Tooltip title={'Preserve the Recommend model and data types.'}>
                                    <FormControlLabel style={{ marginLeft: '0px', marginTop: '20px' }}
                                        control={<Checkbox
                                            checked={preserveRecommend}
                                            onChange={(e) => {
                                                this.setState({ preserveRecommend: e.target.checked }, () => this.onChange());
                                            }}
                                        />
                                        }
                                        label="Preserve" />
                                </Tooltip>
                                <Tooltip title={'Clone the Recommend model and data types and rename. This will also create them in the current plan.'}>
                                    <FormControlLabel style={{ marginLeft: '0px', marginTop: '20px' }}
                                        control={<Checkbox
                                            checked={cloneRecommend}
                                            onChange={(e) => {
                                                this.setState({ cloneRecommend: e.target.checked }, () => this.onChange());
                                            }}
                                        />
                                        }
                                        label="Clone" />
                                </Tooltip>

                            </td>}


                        </tr>
                        <tr valign='top'>
                            <td valign='top' >
                                {recommendModel && <Tooltip title={'Add rule'}><IconButton onClick={() => this.onAddGroup()}><AddIcon /></IconButton></Tooltip>}
                                {hasError && <Tooltip title={'Missing Recs'}><IconButton ><WarningIcon style={{ fill: '#ff5e00' }} /></IconButton></Tooltip>}
                                {isArrayValid(treeData) && <FormControlLabel
                                    style={{ marginLeft: "5px" }}
                                    control={
                                        <OrangeSwitch
                                            checked={editRecommendations}
                                            onChange={(e) => self.onToggleEditRecommendations()} />}
                                    label={'Edit Recommendations'} />
                                }
                                {isArrayValid(treeData) && <FormControlLabel
                                    control={
                                        <OrangeSwitch
                                            label={"Expand all/collapse"}
                                            checked={expandAll}
                                            onChange={(e) => self.onToggleExpandAll()} />}
                                    label="Expand all/collapse" />}
                            </td>
                            <td rowSpan="2" align='right' valign='top' style={{ width: '90%' }}>
                                {editRecommendations && <RecommendationTypesView
                                    appContext={appContext}
                                    editMode={true}
                                    readOnly={true}
                                    externalNodeType={'recommendations'}
                                    onChange={() => self.loadRecommendations()} />}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: "48%", height: "100%" }}>
                                <SortableTree
                                    style={{ width: "100%" }}
                                    treeData={treeData}
                                    maxDepth={3}
                                    theme={FileExplorerTheme}
                                    dndType={'recommendation'}
                                    canDrop={self.canDrop.bind(self)}
                                    onChange={self.onChangeTree.bind(self)}
                                    onMoveNode={self.onMoveNode.bind(self)}
                                    generateNodeProps={self.getNodeProps.bind(self)} />
                                {dialog}
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>)

    }
}


class EditGroupDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: this.props.group,
        };

        console.debug(`Edit group `, props.group)
    }

    // componentDidUpdate(oldProps) {
    //     if (oldProps.group !== this.props.group)
    //         this.setState({ group: JSON.parse(JSON.stringify(this.props.group)) });
    // }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onChange() {
        const { onChange } = this.props;
        const { group } = this.state;
        if (typeof onChange === 'function')
            onChange(group);
    }

    onDone() {
        const { onDone } = this.props;
        const { group } = this.state;
        if (typeof onDone === 'function')
            onDone(group);
    }

    onChangeMin(e) {
        const { group } = this.state;
        let funct = parseCode(group.dataConditions);
        let isDataInRange = funct.functions[0];
        isDataInRange.args[3] = { string: e.target.value, type: "'" };
        group.name = `Priority ${isDataInRange.args[3].string}-${isDataInRange.args[4].string}`;
        group.dataConditions = buildCode(funct);
        this.setState({ group }, () => this.onChange());
    }

    onChangeMax(e) {
        const { group } = this.state;
        let funct = parseCode(group.dataConditions);
        let isDataInRange = funct.functions[0];
        isDataInRange.args[4] = { string: e.target.value, type: "'" };
        group.name = `Priority ${isDataInRange.args[3].string}-${isDataInRange.args[4].string}`;
        group.dataConditions = buildCode(funct);
        this.setState({ group }, () => this.onChange());
    }



    render() {
        const self = this;
        const { appContext: { stores: { DataTypesStore }} } = this.props;
        let { group } = this.state;

        let funct = parseCode(group.dataConditions);
        let isDataInRange = funct.functions[0];
        let min = isDataInRange && isArrayValid(isDataInRange.args) && isDataInRange.args[3]
        if (min)
            min = min.string;
        else
            min = 0;
        let max = isDataInRange && isArrayValid(isDataInRange.args) && isDataInRange.args[4]
        if (max)
            max = max.string
        else
            max = 0


        const generateTypeItems = () => {
            return DataTypesStore.getRecommendGroupTypes().map((k) => {
                return <MenuItem key={k.typeId} value={k.typeId}>{k.name}</MenuItem>
            })
        }

        return <Dialog maxWidth={'md'} model="true" open={true}>
            <DialogContent>
                <FormControl stlye={{ margin: 5 }}>
                    <InputLabel>Type</InputLabel>
                    <Select style={{ marginTop: 20, width: 300 }} label='Type' value={group.type}
                        onChange={(e) => { group.type = e.target.value; this.setState({ group }, () => this.onChange()) }}
                    >
                        {generateTypeItems()}
                    </Select>
                </FormControl>
                <FormControl style={{ margin: 5 }}>
                    <InputLabel>Min</InputLabel>
                    <Select value={min} onChange={(e) => this.onChangeMin(e)}>
                        <MenuItem value='0'>0</MenuItem>
                        <MenuItem value='1'>1</MenuItem>
                        <MenuItem value='2'>2</MenuItem>
                        <MenuItem value='3'>3</MenuItem>
                        <MenuItem value='4'>4</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ margin: 5 }}>
                    <InputLabel>Max</InputLabel>
                    <Select value={max} onChange={(e) => this.onChangeMax(e)}>
                        <MenuItem value='0'>0</MenuItem>
                        <MenuItem value='1'>1</MenuItem>
                        <MenuItem value='2'>2</MenuItem>
                        <MenuItem value='3'>3</MenuItem>
                        <MenuItem value='4'>4</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" self={self} onClick={(e) => { self.onDone() }}>Ok</Button>
                <Button variant="contained" self={self} onClick={(e) => { self.onCancel() }}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}



class EditRecommendationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendation: props.recommendation,
        };
    }

    componentDidUpdate(oldProps) {
        let { recommendation } = this.state;
        if (!recommendation) {
            this.setState({ recommendation: this.props.recommendation });
        } else if (recommendation.recommendId !== this.props.recommendation.recommendId) {
            this.setState({ recommendation: this.props.recommendation });
        }
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onChange() {
        const { onChange } = this.props;
        let { recommendation } = this.state;
        if (Array.isArray(recommendation.protocol) && recommendation.protocol.includes('none'))
            delete recommendation.protocol;
        if (typeof onChange === 'function')
            onChange(recommendation);
    }

    onDone() {
        const { onDone } = this.props;
        const { recommendation } = this.state;
        if (typeof onDone === 'function')
            onDone(recommendation);
    }


    render() {
        const self = this;
        const { appContext: { stores: { DataTypesStore }} } = this.props;
        const { recommendation } = this.state;

        if (!recommendation.protocol) recommendation.protocol = ["none"];
        if (!Array.isArray(recommendation.protocol)) {
            let protocol = recommendation.protocol;
            recommendation.protocol = [protocol];
        }

        return <Dialog maxWidth={'md'} model="true" open={true}>
            <DialogContent>

                <TextField
                    label={'Explanation'}
                    style={{ margin: 5, width: 300 }}
                    value={recommendation.explanation}
                    onChange={(e) => { recommendation.explanation = e.target.value; this.setState({ recommendation }, () => this.onChange()) }}
                />
                <TextField
                    type='number'
                    label="Weight"
                    style={{ margin: 5, width: 100 }}
                    value={recommendation.weight}
                    onChange={(e) => { recommendation.weight = e.target.value; this.setState({ recommendation }, () => this.onChange()) }}
                />

                <FormControl style={this.props.style || { margin: 5, width: 550 }}>
                    <InputLabel>Protocol/Highlight Recommendation</InputLabel>
                    <Select
                        value={recommendation.protocol}
                        multiple
                        onChange={(e) => {
                            console.debug(`recommend protocol value `, e.target.value)
                            recommendation.protocol = e.target.value;
                            self.setState({ recommendation });
                        }
                        }>
                        {DataTypesStore.getRecommendProtocols().map((item) => (
                            <MenuItem key={item.protocolId} value={item.protocolId}>
                                {item.name}
                            </MenuItem>
                        ))}

                    </Select>

                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" self={self} onClick={(e) => { self.onDone() }}>Ok</Button>
                <Button variant="contained" self={self} onClick={(e) => { self.onCancel() }}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <components.ValueContainer {...props}>
            <Placeholder  {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child => {
                return child && child.type !== Placeholder ? child : null;
            })}
        </components.ValueContainer>
    );
};

const OrangeSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: 'blue',
        },
        '&$checked + $track': {
            backgroundColor: 'blue',
        },
    },
    checked: {},
    track: {},
})(Switch);

export default SymptomRecommendComponent;
