
import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import {
    withStyles,
    DialogTitle,
    DialogContent,
    Dialog,
    Paper,
    Tooltip,
    TextField,
    IconButton,
    Button
} from '@material-ui/core/';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import {
    isArrayValid,
    loadText,
    saveText
} from '@apricityhealth/web-common-lib/utils/Services';


function EditableNote({ textId, planId, language, classes }) {
    if (!language) language = "en-us"
    const [note, setNote] = useState({ text: "" });
    const [text, setText] = useState({ text: "" });
    const [editText, setEditText] = useState(false)

    // Auto fetch the data on mount
    useEffect(() => {
        async function load() {
            try {
                let idToken = sessionStorage.getItem("idToken");
                let appContext = { state: { idToken, plan: { planId } } };
                let text = await loadText(appContext, { textId, language });
                if (!isArrayValid(text)) {
                    text = {
                        text: "",
                        category: "rosetta",
                        textId,
                        planId,
                        language,
                    }
                } else {
                    text = text[0]
                }
                setText(text)
                setNote(text.text)
            } catch(err) {
                console.error("EditableNote error:", err );
            }
        }
        load();

        return () => {
            setText(null);
            setNote(null);
        }
    }, [textId, planId, language])

    function onClose() {
        setEditText(false);
        if (note !== text.text) {
            text.text = note;
            let idToken = sessionStorage.getItem("idToken");
            let appContext = { state: { idToken, plan: { planId } } };
            saveText(appContext, text, planId).catch((err) => {
                console.error("EditableNote error:", err );
            })
        }
    }

    return (
        <>
            <HtmlTooltip title={text && <pre className={classes.pre} dangerouslySetInnerHTML={{ __html: text.text }} />}>
                <IconButton onClick={(e) => setEditText(true)}><InfoOutlined /></IconButton>
            </HtmlTooltip>
            <Dialog
                open={editText}
                onClose={() => onClose()}
                maxWidth={'xl'}
                PaperComponent={PaperComponent} //need this to drag
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle className={classes.dialog} id="draggable-dialog-title">
                    <div className={classes.closeButton}>
                        <Button onClick={() => onClose()}>Close</Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        variant="outlined"
                        className={classes.note}
                        minRows={15}
                        value={note}
                        label="Note"
                        onChange={(e) => {
                            setNote(e.target.value)
                        }} />
                </DialogContent>
            </Dialog>
        </>
    );
}

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fcfcfc',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 620,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const styles = {
    pre: {
        whiteSpace: 'pre-wrap',
    },
    closeButton: {
        position: 'absolute',
        margin: "2px",
        padding: "0px",
        width: "90px",
        height: "50px",
        fontSize: '1rem',
        right: -8,
        top: 8,
    },
    dialog: {
        cursor: 'move'
    },
    note: {
        margin: "10px 2px 15px 2px",
        width: '500px',
        height: '100%',
        border: '1px',
    }
}
export default withStyles(styles)(EditableNote)

