import React from 'react';
import {
    Input,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@material-ui/core/';

class SelectMode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: props.appContext.state.mode,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.mode !== this.props.mode) {
            this.handleChange(nextProps.mode);
        }
    }

    handleChange(mode) {
        const { appContext, onChange } = this.props;
        if (onChange)
            onChange(mode);
        this.setState({ mode: mode });
        appContext.setState({ mode: mode });
    }

    render() {
        const self = this;
        const { mode } = this.state;

        var items = [];
        items.push(<MenuItem key='1' value="simple">Simple</MenuItem>)
        items.push(<MenuItem key='2' value="advanced">Advanced</MenuItem>)

        return (
            <FormControl style={ this.props.style || { width: 200, margin: 5 }}>
                <InputLabel htmlFor="mode-simple">Access Mode</InputLabel>
                <Select
                    value={mode}
                    onChange={(e) => self.handleChange(e.target.value)}
                    input={<Input name="mode" id="mode-simple" />}
                >
                    {items}
                </Select>
            </FormControl>
        );
    }
}

export default SelectMode;
