import React from "react";
import PubSub from 'pubsub-js';
import {
    CircularProgress,
    IconButton,
    TextField
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import { getReportSeries } from '@apricityhealth/web-common-lib/utils/Services';
import DateTimeRange from "@apricityhealth/web-common-lib/components/DateTimeRange";
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from '@apricityhealth/web-common-lib/components/User';
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import ChangesView from "../views/ChangesView";

class ChangeReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changes: [],
            startTime: null,
            endTime: null,
            dataId: null,
            userId: null,
            collection: null,
            progress: null,
            language: 'en-us',
            limit: 1000,
            dialog: null
        };
    }

    componentDidMount() {
        let self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.loadContent();
    }

    loadContent() {
        let self = this;
        const { appContext } = this.props;
        const { planId } = appContext.state.plan ? appContext.state.plan : {};
        const { startTime, endTime, dataId, userId, collection, limit } = this.state;

        this.setState({ progress: <CircularProgress size={20} />, error: null });

        let args = { dataId, changeUserId: userId, planId, collectionName: collection, limit };
        if (startTime) args.startTime = startTime;
        if (endTime) args.endTime = endTime;

        getReportSeries(appContext, 'Change', args).then((response) => {
            self.setState({ versions: response.data ? response.data.versions : [], changes: response.data ? response.data.results : [], progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });

    }
    onClose() {
        this.setState({ dialog: null });
        if (this._types) this._types.setState({ selected: [] });
    }

    onSelectChange(s, t) {
        if (s.length > 0) {
            const { changes } = this.state;
            const change = changes[s[0]];

            let dialog = <ChangesView appContext={this.props.appContext} primaryKey={change.dataId} collection={change.collectionName} onDone={this.onClose.bind(this)} />;
            this.setState({ dialog });
        } else {
            this.setState({ dialog: null });
        }
    }

    render() {
        const { appContext } = this.props;
        let { changes, dialog, progress, error, language, startTime, endTime, dataId, userId, collection, limit } = this.state;
        let dateTimeSelector = <DateTimeRange appContext={appContext} startTime={startTime} endTime={endTime}
            endTimeChange={(endTime) => { this.setState({ endTime }) }} startTimeChange={(startTime) => { this.setState({ startTime }) }} />;

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td>
                    {dateTimeSelector}
                    <TextField style={{ margin: 5, width: 200 }} value={dataId} label="dataId" onChange={(event) => { this.setState({ dataId: event.target.value }) }} />
                    <TextField style={{ margin: 5, width: 200 }} value={userId} label="userId" onChange={(event) => { this.setState({ userId: event.target.value }) }} />
                    <TextField style={{ margin: 5, width: 200 }} value={collection} label="Collection" onChange={(event) => { this.setState({ collection: event.target.value }) }} />
                    <TextField style={{ margin: 5, width: 200 }} type='number' value={limit} label="Limit" onChange={(event) => { this.setState({ limit: event.target.value }) }} />
                </td>
                <td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    {this.getChangesReport(changes, language)}
                </td>
            </tr>
            {dialog}
        </tbody>
        </table>;
    }

    getChangesReport(changes, language) {
        const self = this;
        const { appContext } = this.props;
        let columData = [
            { id: 'planId', label: 'Plan', formatValue: (v, r) => <Plan appContext={appContext} planId={v} /> },
            { id: 'userId', label: 'User', formatValue: (v, r) => <User appContext={appContext} userId={v} userIdHidden={true} /> },
            { id: 'dataId', label: 'Primary Key' },
            { id: 'changeType', label: 'Change Type' },
            { id: 'collectionName', label: 'Collection' },
            { id: 'createDate', label: 'Date' },
            { id: 'version', label: 'Version Changed' },
        ];
        return <EnhancedTable
            onSelected={(s, t) => self.onSelectChange(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            onTable={table => self._types = table}
            orderBy='createdAt'
            columnData={columData}
            data={changes}
            title='Changes'
        />;
    }
}

export default ChangeReport;