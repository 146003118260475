import React from "react";
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import {
    CircularProgress,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Drawer,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Tooltip,
} from '@material-ui/core/';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import SaveAsIcon from '@material-ui/icons/SaveAlt';
import CodeIcon from '@material-ui/icons/Code';
import CreateIcon from '@material-ui/icons/Create';

import PrintIcon from '@material-ui/icons/Print';

import SortableTree from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

import {
    isArrayValid,
} from '../utils/Utils';

import {
    loadModels,
    deleteModels,
    loadDataTypes,
    saveDataType
} from '@apricityhealth/web-common-lib/utils/Services';

import { generateMinMaxLabel } from "@apricityhealth/web-common-lib/components/EditConditions";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

import SaveToPlanDialog from "../dialogs/SaveToPlanDialog";
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import { DataLinkIcon } from '../dialogs/DataLinksDialog';
import EditTriggerDialog from "../dialogs/EditTriggerDialog";
import EditSymptomDialog from '../dialogs/EditSymptomDialog';
import EditModelDialog from '../dialogs/EditModelDialog';
import SymptomTypesView from './SymptomTypesView';

import { updateModelDataType, importDataTypesToModel } from '../utils/Models';
import ChangedByButton from "../components/ChangedByButton";

import EditableNote from '../components/EditableNote'

import 'react-perfect-scrollbar/dist/css/styles.css';
import '../styles/workup.css';

export function isNull(value) {
    return value === '' || value === null || value === undefined;
}

export class ModelTypeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            error: null,
            model: null,
            modelId: '',
            dialog: null,
            drawerOpen: false,
            modified: false,
            treeData: [],
            expandedNodes: [true, true, true, true, true, true, true, true]
        };
    }

    componentDidMount() {
        this.loadModel(this.props.modelId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.modelId !== prevProps.modelId)
            this.loadModel(this.props.modelId);
    }

    onCloseView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else
            this.props.onClose();
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Model Modified!</DialogTitle>
            <DialogContent>This model has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ dialog: null });
                    self.saveModel(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    onToggleDrawer() {
        var newState = !this.state.drawerOpen;
        this.setState({ drawerOpen: newState });
    }

    loadModel(modelId) {
        const self = this;

        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadModel.bind(this, modelId));
        }

        if (modelId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            loadModels(this.props.appContext, { modelId, dependencies: true }).then((models) => {
                let model = isArrayValid(models) ? models[0] : self.createNewModel();
                self.setState({ progress: null, model, modelId: model.modelId }, this.createTreeNodes.bind(this));
            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
        else {
            let model = this.createNewModel();
            this.setState({ model, modelId: model.modelId }, this.createTreeNodes.bind(this))
        }
    }

    createNewModel() {
        let newModel = {
            planId: '',
            modelId: 'newModel',
            name: `New Model`,
            category: this.props.category || 'pro',
            dataId: '',
            order: 0,
            description: ``,
            instructions: ``,
            complaintText: '',
            detectModel: '',
            alertDataId: '',
            gradeDataId: '',
            priorityDataId: '',
            scoreDataId: '',
            triggerSymptoms: [],
            requiredData: [],
            primarySymptoms: [],
            associatedSymptoms: [],
            positiveSymptoms: [],
            negativeSymptoms: [],
            clinicalModifiers: [],
            laboratory: []
        };
        return newModel;
    }

    // update the values of all data types from this model
    exportModelToDataTypes() {
        const { model } = this.state;

        return new Promise((resolve, reject) => {
            loadDataTypes(this.props.appContext, { dependencies: true }).then((types) => {
                let modifiedTypes = [];
                function updateValues(symptoms, types) {
                    if (Array.isArray(symptoms)) {
                        for (let i = 0; i < symptoms.length; ++i) {
                            let symptom = symptoms[i];
                            let dataType = types.find((v) => v.dataId === symptom.dataId);
                            if (modifiedTypes.indexOf(dataType) >= 0) continue;
                            if (!dataType) {
                                console.error(`Failed to find dataType ${symptom.dataId}`);
                                continue;
                            }
                            if (symptom.tupleIndex === undefined || symptom.tupleIndex < 0 ||
                                symptom.tupleIndex >= dataType.tupleDescriptions.length) continue;
                            let tuple = dataType.tupleDescriptions[symptom.tupleIndex];
                            if (symptom.description && tuple.description !== symptom.description) {
                                tuple.description = symptom.description;
                                modifiedTypes.push(dataType);
                            }
                        }
                    }
                }

                updateValues(model.triggerSymptoms, types);
                updateValues(model.primarySymptoms, types);
                updateValues(model.associatedSymptoms, types);
                updateValues(model.positiveSymptoms, types);
                updateValues(model.negativeSymptoms, types);
                updateValues(model.clinicalModifiers, types);
                updateValues(model.laboratory, types);

                if (modifiedTypes.length > 0) {
                    console.log("modifiedTypes:", modifiedTypes);
                    return saveDataType(this.props.appContext, modifiedTypes);
                }
                else
                    return Promise.resolve();
            }).then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
            })
        })
    }

    saveModel(callback, planId) {
        const self = this;
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        const { model } = this.state;

        if (!planId && model.planId && model.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(model.planId, this.saveModel.bind(this, callback));
        }
        if (! planId ) {
            planId = _.get(appContext,"state.plan.planId");
            if (! planId ) {
                return this.setState({error: 'No valid plan selected'});
            }
        }
        // set the planId in the model before we call updateModelDataType, that data type MUST be in the same
        // plan as the model, otherwise chaos!
        model.planId = planId;

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        updateModelDataType(appContext, model).then(() => {
            return store.saveModel(model, planId);
        }).then((response) => {
            self.setState({ modified: false, progress: null, model: response, modelId: response.modelId }, callback);
        }).catch((error) => {
            console.error("save model error:", error);
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    saveToPlan(callback) {
        const self = this;
        const store = this.props.appContext.stores.DataTypesStore;
        const { model } = this.state;
        let dialog = <SaveToPlanDialog appContext={this.props.appContext} planId={model.planId} onCancel={this.onCloseDialog.bind(this)}
            onDone={({ plan, move }) => {
                console.log("plan:", plan, ", move: ", move);
                self.setState({ progress: <CircularProgress size={20} />, error: null, dialog: null }, () => {
                    store.saveModel(model, plan.planId).then(() => {
                        if (move) {
                            store.deleteModel(model.modelId, model.planId).then(() => {
                                self.setState({ progress: null }, self.onCloseView.bind(self))
                            }).catch((err) => {
                                self.setState({ error: getErrorMessage(err), dialog: null });
                            })
                        } else {
                            self.setState({ progress: null }, callback);
                        }
                    })
                });
            }} />;
        this.setState({ dialog });
    }

    deleteModel() {
        console.log("deleteModel:", this);
        const self = this;
        const { model } = this.state;
        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Delete this model, are you sure?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: <CircularProgress size={20} />, error: null });
                    deleteModels(this.props.appContext, { modelId: model.modelId, planId: model.planId }).then(() => {
                        self.setState({ progress: null, dialog: null }, self.onCloseView.bind(self));
                    }).catch((err) => {
                        console.error("deleteRecommendationModel failed:", err);
                        self.setState({ progress: null, error: getErrorMessage(err), dialog: null });
                    })
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: null, dialog: null });
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    onEditModel() {
        const self = this;
        let dialog = <EditModelDialog appContext={this.props.appContext} model={this.state.model} onDone={(model) => {
            console.log("onEditModel done:", model);
            self.setState({ dialog: null, model, modified: true });
        }} onCancel={() => {
            console.log("onEditModel cancel");
            self.setState({ dialog: null });
        }} />;

        this.setState({ dialog });
    }

    onShowCode() {
        this.setState({
            dialog: <JsonDialog
                appContext={this.props.appContext}
                dataType={this.state.model}
                onEditDone={(model) => {
                    this.setState({model, dialog: null});
                }}
                onDone={this.onCloseDialog.bind(this)} />
        });
    }

    onCloseDialog(type) {
        this.setState({ dialog: null });
    }

    createTriggerNodes(triggers) {
        let children = [];
        if (isArrayValid(triggers)) {
            for (let i = 0; i < triggers.length; i++) {
                let trigger = triggers[i];
                let text = `${trigger.description}`;

                let subtitle = generateMinMaxLabel(`${trigger.dataId}[${trigger.tupleIndex}]`, trigger.min, trigger.max, false, trigger.inclusive) + `, ${trigger.timeWindow} Hours`;
                if (trigger.disableQuestions)
                    subtitle += ", Disable Questions";
                if (trigger.useLatest)
                    subtitle += ", Use Lastest";
                if (trigger.useLowest)
                    subtitle += ", Use Lowest";
                if (trigger.addModel)
                    subtitle += ", AddModel";
                if (trigger.inGroup)
                    subtitle += ", Same Day";
                if (trigger.inSession)
                    subtitle += ", Same Session";
                if (trigger.required)
                    subtitle += `, Required`;
                if (trigger.condition)
                    subtitle += `, Condition`;
                if (trigger.symptom)
                    subtitle += `, Symptom`;
                let child = {
                    trigger,
                    triggers,
                    text,
                    subtitle
                };

                children.push(child);
            }
        }
        return children;
    }

    createDataNodes(dataIds) {
        let children = [];
        if (isArrayValid(dataIds)) {
            for (let i = 0; i < dataIds.length; i++) {
                let dataId = dataIds[i];
                let child = {
                    dataId,
                    dataIds,
                    text: dataId
                };

                children.push(child);
            }
        }
        return children;
    }

    createSymptomNodes(symptoms) {
        let children = [];
        if (isArrayValid(symptoms)) {
            for (let i = 0; i < symptoms.length; i++) {
                let symptom = symptoms[i];
                let text = `${symptom.description}`;
                let subtitle = `Data ID: ${symptom.dataId}, ${symptom.timeWindow} Hours`;
                if (symptom.disableQuestions)
                    subtitle += ", Disable Questions";
                if (symptom.useLatest)
                    subtitle += ", Use Latest";
                if (symptom.useLowest)
                    subtitle += ", Use Lowest";
                if (symptom.inGroup)
                    subtitle += ", Same Day";
                if (symptom.inSession)
                    subtitle += ", Same Session";
                if (symptom.required)
                    subtitle += `, Required`;
                if (symptom.useNAN)
                    subtitle += ", UseNAN";
                if (symptom.condition)
                    subtitle += `, Condition`;
                if (symptom.symptom)
                    subtitle += `, Symptom`;
                if (symptom.triage)
                    subtitle += `, Triage`;
                if (symptom.weight)
                    subtitle += `, Weight: ${symptom.weight}`;

                let child = {
                    symptom,
                    symptoms,
                    text,
                    subtitle
                };

                children.push(child);
            }
            children.sort((a, b) => {
                if (a.modelTupleIndex < b.modelTupleIndex)
                    return -1;
                else if (a.modelTupleIndex > b.modelTupleIndex)
                    return 1;
                return 0;
            });
        }
        return children;
    }

    createTreeNodes() {
        let treeData = [];

        let { model, expandedNodes } = this.state;
        console.log("createTreeModel:", model);
        if (model) {
            treeData.push({
                model,
                text: "Trigger Parameters",
                triggers: model.triggerSymptoms,
                expanded: expandedNodes[treeData.length],
                children: this.createTriggerNodes(model.triggerSymptoms)
            });

            treeData.push({
                model,
                text: "Required Data",
                dataIds: model.requiredData,
                expanded: expandedNodes[treeData.length],
                children: this.createDataNodes(model.requiredData)
            });

            treeData.push({
                model,
                text: "Primary Symptoms",
                symptoms: model.primarySymptoms,
                expanded: expandedNodes[treeData.length],
                children: this.createSymptomNodes(model.primarySymptoms)
            });

            treeData.push({
                model,
                text: "Associated Symptoms",
                symptoms: model.associatedSymptoms,
                expanded: expandedNodes[treeData.length],
                children: this.createSymptomNodes(model.associatedSymptoms),
                defaultWeight: 0.8
            });

            treeData.push({
                model,
                text: "Pertinent Positives",
                symptoms: model.positiveSymptoms,
                expanded: expandedNodes[treeData.length],
                children: this.createSymptomNodes(model.positiveSymptoms),
                defaultWeight: -1
            });

            treeData.push({
                model,
                text: "Pertinent Negatives",
                symptoms: model.negativeSymptoms,
                expanded: expandedNodes[treeData.length],
                children: this.createSymptomNodes(model.negativeSymptoms),
                defaultWeight: -1
            });

            treeData.push({
                model,
                text: "Laboratory Tests",
                symptoms: model.laboratory,
                expanded: expandedNodes[treeData.length],
                children: this.createSymptomNodes(model.laboratory)
            });

            treeData.push({
                model,
                text: "Clinical Modifiers",
                symptoms: model.clinicalModifiers,
                expanded: expandedNodes[treeData.length],
                children: this.createSymptomNodes(model.clinicalModifiers)
            });

        }

        this.setState({ treeData });
        console.log("treeData:", treeData);
    }

    onEditTrigger(node) {
        console.log("onEditTrigger:", node);
        const self = this;
        let dataId = this.state.model.dataId;
        let dialog = <EditTriggerDialog appContext={this.props.appContext} trigger={node.trigger} dataId={dataId} onDone={(trigger) => {
            console.log("onEditTrigger done:", trigger);
            for (let k in trigger) {
                node.trigger[k] = trigger[k];
            }
            node.text = `${trigger.dataId}[${trigger.description}]`;
            self.setState({ dialog: null, modified: true }, self.createTreeNodes.bind(self));
        }} onCancel={this.onCloseDialog.bind(this)} />;

        this.setState({ dialog });
    }

    onDeleteTrigger(node) {
        console.log("onDeleteTrigger:", node);
        let i = node.triggers.indexOf(node.trigger);
        if (i >= 0) {
            node.triggers.splice(i, 1);
            this.setState({ modified: true }, this.createTreeNodes.bind(this));
        }
    }

    onEditSymptom(node) {
        console.log("onEditSymptom:", node);
        const self = this;
        let dataId = this.state.model.dataId;
        let dialog = <EditSymptomDialog appContext={this.props.appContext} symptom={node.symptom} dataId={dataId} onDone={(symptom) => {
            console.log("onEditSymptom done:", symptom);
            for (let k in symptom) {
                node.symptom[k] = symptom[k];
            }
            node.text = `${symptom.dataId}[${symptom.description}]`;
            self.setState({ dialog: null, modified: true }, self.createTreeNodes.bind(self));
        }} onCancel={this.onCloseDialog.bind(this)} />;

        this.setState({ dialog });
    }

    onDeleteSymptom(node) {
        console.log("onDeleteSymptom:", node);
        let i = node.symptoms.indexOf(node.symptom);
        if (i >= 0) {
            node.symptoms.splice(i, 1);
            this.setState({ modified: true }, this.createTreeNodes.bind(this));
        }
    }

    onDeleteData(node) {
        let i = node.dataIds.indexOf(node.dataId);
        if (i >= 0) {
            node.dataIds.splice(i, 1);
            this.setState({ modified: true }, this.createTreeNodes.bind(this));
        }
    }

    getNodeButtons(node) {
        const { appContext } = this.props;

        let buttons = [];
        if (node.symptom !== undefined) {
            buttons.push(<DataLinkIcon appContext={appContext} dataId={node.symptom.dataId} />);
            buttons.push(<IconButton onClick={this.onEditSymptom.bind(this, node)}><CreateIcon /></IconButton>);
            buttons.push(<IconButton onClick={this.onDeleteSymptom.bind(this, node)}><DeleteIcon /></IconButton>);
        }
        if (node.trigger !== undefined) {
            buttons.push(<DataLinkIcon appContext={appContext} dataId={node.trigger.dataId} />);
            buttons.push(<IconButton onClick={this.onEditTrigger.bind(this, node)}><CreateIcon /></IconButton>);
            buttons.push(<IconButton onClick={this.onDeleteTrigger.bind(this, node)}><DeleteIcon /></IconButton>);
        }
        if (node.dataId !== undefined && node.dataIds !== undefined) {
            buttons.push(<DataLinkIcon appContext={appContext} dataId={node.dataId} />);
            buttons.push(<IconButton onClick={this.onDeleteData.bind(this, node)}><DeleteIcon /></IconButton>);
        }

        return buttons;
    }

    getNodeProps(args) {
        //console.log("getNodeProps:",args);
        let { node } = args;
        return {
            style: { height: 50, width: 500, border: 0 },
            title: node.text,
            buttons: this.getNodeButtons(node)
        };
    }

    onChangeTree(treeData) {
        console.log("onChangeTree:", treeData);
        let { expandedNodes, modified } = this.state;
        for (let i = 0; i < treeData.length; ++i) {
            let node = treeData[i];
            expandedNodes[i] = node.expanded;

            function symptomsChanged(symptoms, nodes) {
                if (symptoms.length !== nodes.length)
                    return true;
                for (let i = 0; i < symptoms.length; ++i) {
                    if (JSON.stringify(symptoms[i]) !== JSON.stringify(nodes[i].symptom))
                        return true;
                }
                return false;
            }
            function triggersChanged(triggers, nodes) {
                if (triggers.length !== nodes.length)
                    return true;
                for (let i = 0; i < triggers.length; ++i) {
                    if (JSON.stringify(triggers[i]) !== JSON.stringify(nodes[i].trigger))
                        return true;
                }
                return false;
            }
            function dataIdsChanged(dataIds, nodes) {
                if (dataIds.length !== nodes.length)
                    return true;
                for (let i = 0; i < dataIds.length; ++i) {
                    if (dataIds[i] !== nodes[i].dataId)
                        return true;
                }
                return false;
            }

            if (node.symptoms !== undefined) {
                let defaultWeight = node.defaultWeight || 1;
                if (symptomsChanged(node.symptoms, node.children)) {
                    modified = true;
                    node.symptoms.splice(0, node.symptoms.length);        // remove the existing symptoms in-place
                    for (let i = 0; i < node.children.length; ++i) {
                        let child = node.children[i];
                        let symptom = child.symptom;
                        if (child.trigger !== undefined) {
                            symptom.weight = defaultWeight;                 // new node, assign the default weight
                            delete child.trigger;
                        }
                        child.symptoms = node.symptoms;
                        child.text = `${symptom.dataId}[${symptom.description}]`;
                        node.symptoms.push(child.symptom);
                    }
                }

            }
            else if (node.triggers !== undefined) {
                if (triggersChanged(node.triggers, node.children)) {
                    modified = true;
                    node.triggers.splice(0, node.triggers.length);        // remove the existing symptoms in-place
                    for (let i = 0; i < node.children.length; ++i) {
                        let child = node.children[i];
                        let trigger = child.trigger;
                        delete child.symptom;
                        child.triggers = node.triggers;
                        child.text = `${trigger.dataId}[${trigger.description}]`;
                        node.triggers.push(child.trigger);
                    }
                }
            }
            else if (node.dataIds !== undefined) {
                if (dataIdsChanged(node.dataIds, node.children)) {
                    modified = true;
                    node.dataIds.splice(0, node.dataIds.length);        // remove the existing symptoms in-place
                    for (let i = 0; i < node.children.length; ++i) {
                        let child = node.children[i];
                        let dataId = child.dataId;
                        child.dataIds = node.dataIds;
                        child.text = dataId;
                        node.dataIds.push(dataId);
                    }
                }
            }
        }
        this.setState({ treeData, expandedNodes, modified }, this.createTreeNodes.bind(this));
    }

    canDrop(args) {
        console.log("canDrop:", args);
        let { node, nextParent } = args;
        if (nextParent !== null && nextParent.symptoms !== undefined && node.symptom !== undefined) {
            return true;
        }
        if (nextParent !== null && nextParent.triggers !== undefined && node.trigger !== undefined) {
            return true;
        }
        if (nextParent !== null && nextParent.dataIds !== undefined && node.dataId !== undefined) {
            return true;
        }
        return false;
    }

    canNodeHaveChildren(node) {
        if (node.symptoms !== undefined && node.symptom === undefined) return true;
        if (node.triggers !== undefined && node.trigger === undefined) return true;
        if (node.dataIds !== undefined && node.dataId === undefined) return true;
        return false;
    }

    print() {
        let { model } = this.state;
        this.setState({ dialog: <Redirect to={{ pathname: `/print/model`, state: { modelId: model.modelId } }} push={true} /> })
    }

    confirmUpdateModelDataType() {
        let dialog = <Dialog open={true}>
            <DialogTitle>Do you wish to update the descriptions?</DialogTitle>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    this.setState({ dialog: null });
                    this.updateModelDataType(true);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    this.setState({ dialog: null });
                    this.updateModelDataType(false);
                }}>No</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    this.setState({ dialog: null });
                }}>Cancel</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog })
    }
    updateModelDataType(updateDesriptions) {
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        importDataTypesToModel(this.props.appContext, this.state.model, updateDesriptions).then((model) => {
            this.setState({ model, progress: null, modified: true }, () => this.createTreeNodes.bind(this));
        }).catch((err) => {
            this.setState({ progress: null, error: getErrorMessage(err) });
        });
    }
    render() {
        const self = this;
        const { treeData, progress, error, dialog, model, drawerOpen } = this.state;
        const { appContext } = this.props;

        let drawer = null;
        if (drawerOpen === true) {
            drawer = <Drawer variant="permanent" anchor="right" open={drawerOpen} onClose={() => { self.setState({ drawerOpen: false }) }}>
                <div align="left"><IconButton onClick={() => { self.setState({ drawerOpen: false }) }}><ChevronRightIcon /></IconButton></div>
                <SymptomTypesView appContext={appContext} editMode={false} category='symptoms' />
            </Drawer>;
        }
        else {
            drawer = <Drawer variant="permanent" anchor="right" open={drawerOpen}>
                <IconButton onClick={() => { self.setState({ drawerOpen: true }) }}><ChevronLeftIcon /></IconButton>
            </Drawer>;
        }

        // let importData = <Tooltip title='Import data from data types.'><IconButton onClick={() => {
        //     this.confirmUpdateModelDataType();
        // }}><ImportIcon /></IconButton></Tooltip>;
        // let exportData = <Tooltip title='Export data to data types.'><IconButton onClick={() => {
        //     self.setState({progress: <CircularProgress size={20} />, error: null});
        //     self.exportModelToDataTypes().then((model) => {
        //         self.setState({progress: null});
        //     }).catch((err) => {
        //         self.setState({progress: null, error: getErrorMessage(err) });
        //     });
        // }}><ExportIcon /></IconButton></Tooltip>;

        let disableDelete = model ? model.planId !== appContext.state.plan.planId : true;
        let title = model ? model.name : "";

        let sortableTree = treeData ? <div style={{ height: 900, width: '100%' }} valign='top'>
            <SortableTree
                treeData={treeData}
                maxDepth={2}
                theme={FileExplorerTheme}
                dndType={'dataType'}
                isVirtualized={true}
                canDrop={this.canDrop.bind(this)}
                onChange={this.onChangeTree.bind(this)}
                generateNodeProps={this.getNodeProps.bind(this)}
                canNodeHaveChildren={this.canNodeHaveChildren.bind(this)}
            />

        </div> : null;
        let owner = model ? <ChangedByButton appContext={appContext} primaryKey={model.modelId} collection='ModelTypeModel' /> : null;

        let edit = <table width="100%"><tbody>
            <tr>
                <td>
                    <Tooltip title='Edit Model'><IconButton onClick={this.onEditModel.bind(this)}><EditIcon /></IconButton></Tooltip>{title}
                </td>
                <td align="right">
                    <Tooltip title='Save'><IconButton disabled={progress !== null} onClick={this.saveModel.bind(this, this.createTreeNodes.bind(this), null)}><SaveIcon /></IconButton></Tooltip>
                    <Tooltip title='Save To Plan'><IconButton disabled={progress !== null} onClick={this.saveToPlan.bind(this, this.createTreeNodes.bind(this))}><SaveAsIcon /></IconButton></Tooltip>
                    {model && model.planId ? <EditableNote textId={`conditionModel.${model.modelId}`} planId={model.planId} /> : null}
                    <Tooltip title='Refresh'><IconButton disabled={progress !== null} onClick={this.loadModel.bind(this, this.state.modelId)}>{progress || <RefreshIcon />}</IconButton></Tooltip>
                    <Tooltip title='View Code'><IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton></Tooltip>
                    <Tooltip title={'Print'}><IconButton disabled={progress !== null} onClick={() => this.print(model)}><PrintIcon /></IconButton></Tooltip>
                    <Tooltip title='Delete'><IconButton disabled={disableDelete} onClick={this.deleteModel.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                    <br />
                    <span style={{ color: 'red' }}>{error}</span>
                </td>
                <td width="50"></td>
            </tr>
            <tr>
                <td colSpan="2" valign="top">
                    {sortableTree}
                    {owner}
                    {drawer}
                    {dialog}
                </td>
            </tr>
        </tbody></table>;

        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={this.onCloseView.bind(this)}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Edit Model</Typography>
                    </Toolbar>
                </AppBar>
                <div align="left" style={styles.div}>
                    {edit}
                </div>
            </div>
        );
    }
}

const styles = {
    appBar: {
        width: '100%',
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 5,
        width: 1200
    },
    button: {
        margin: 5
    },
    number: {
        margin: 5,
        width: 200
    },
    name: {
        margin: 5,
        width: 300
    },
    select: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

export default ModelTypeView;
