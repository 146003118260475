import React from "react";
import {
    CircularProgress,
    TextField,
} from '@material-ui/core/';

import SortableTree from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

import {
    isArrayValid,
} from '../utils/Utils';

import {
    loadDataTypes,
} from '@apricityhealth/web-common-lib/utils/Services';

import PubSub from 'pubsub-js'
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

import 'react-perfect-scrollbar/dist/css/styles.css';
import '../styles/workup.css';

export class SymptomTypesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            dataTypes: [],
            treeData: null
        }
    }

    componentDidMount() {
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadDataTypes(this.props.appContext, { dependencies: true} ).then((dataTypes) => {
            self.setState({ progess: null, dataTypes }, self.createTreeData.bind(self));
        }).catch((err) => {
            console.error("loadContent error:", err);
            self.setState({ progress: null, error: getErrorMessage(err) });
        })
    }

    createTreeData() {
        let { filter, dataTypes } = this.state;
        let treeData = [];
        if (isArrayValid(dataTypes)) {
            for (let i = 0; i < dataTypes.length; ++i) {
                let dataType = dataTypes[i];
                let desc = isArrayValid(dataType.tupleDescriptions) ? dataType.tupleDescriptions[0].description : dataType.description || dataType.name;
                let text = `${desc} (${dataType.dataId})`;
                if (filter && text.toLowerCase().indexOf(filter.toLowerCase()) < 0) continue;

                treeData.push({
                    text,
                    dataId: dataType.dataId,
                    symptom: {
                        dataId: dataType.dataId,
                        description: desc,
                        values: dataType.valueDescriptions || [],
                        tupleIndex: 0,
                        modelTupleIndex: -1,
                        timeWindow: 24,
                        useLowest: false,
                        useLatest: false,
                        inGroup: false,
                        inSession: false,
                        required: false,
                        symptom: false,
                        triage: false,
                        weight: 1.0
                    },
                    trigger: {
                        dataId: dataType.dataId,
                        description: desc,
                        condition: '',
                        tupleIndex: 0,
                        timeWindow: 24,
                        useLowest: false,
                        useLatest: false,
                        inGroup: false,
                        inSession: false,
                        inclusive: false,
                        addModel: false,
                        symptom: false,
                        min: 0,
                        max: null
                    }
                })
            }
        }
        treeData.sort((a, b) => { return a.text.localeCompare(b.text, 'en', { sensitivity: 'base' }) })
        this.setState({ treeData });
    }

    canDrop() {
        return false;
    }

    getNodeProps({ node }) {
        return {
            style: { height: 50, width: 500, border: 0 },
            title: node.text
        };
    }

    onChangeTree() {
    }

    render() {
        const self = this;
        let { progress, filter, treeData } = this.state;

        let tree = treeData ? <div style={{ height: 900, width: '100%' }} valign='top'>
            <SortableTree
                treeData={treeData}
                maxDepth={1}
                theme={FileExplorerTheme}
                dndType={'dataType'}
                isVirtualized={true}
                canDrop={this.canDrop.bind(this)}
                generateNodeProps={this.getNodeProps.bind(this)}
                onChange={this.onChangeTree.bind(this)}
                shouldCopyOnOutsideDrop={true}
            />
        </div> : null;

        return <div>
            <TextField value={filter} label='Filter' style={styles.description} onChange={(e) => {
                self.setState({ filter: e.target.value }, self.createTreeData.bind(self));
            }} />
            <br />
            {tree}
            {progress}
        </div>;
    }
};

const styles = {
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 5
    },
    button: {
        margin: 5
    },
    number: {
        margin: 5,
        width: 200
    },
    name: {
        margin: 5,
        width: 300
    },
    select: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

export default SymptomTypesView;
