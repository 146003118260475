import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    CircularProgress,
    IconButton,
    FormControlLabel,
    Checkbox,
    Drawer
} from '@material-ui/core/';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import NavigationClose from '@material-ui/icons/Close';

import SaveIcon from '@material-ui/icons/Save';
import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import EditDataTypeDialog from '../../dialogs/EditDataTypeDialog';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import SelectUnit from '@apricityhealth/web-common-lib/components/SelectUnit';

const MAX_RULES_DISPLAYED = 10;

const Promise = require('bluebird');

class ReviewObservations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rules: props.rules,
            updateDataId: false,
            dataId: '',
            updateTargetUnit: false,
            targetUnit: '',
            dialog: null,
            reviewed: false,
            clearReviewed: false,
            ignore: false,
            clearIgnore: false,
            progress: false
        }
        this._SelectDataTypeChild = React.createRef();
    }

    closeView() {
        this.props.onClose();
    }

    resetSelectDataTypeChild() {
        this._SelectDataTypeChild.current.updateTypes()
    }

    showCreateDataType() {
        let { appContext } = this.props
        let dataType = {
            name: "New Name",
            description: "New Description",
            category: "labTest",
            tupleDescriptions: [],
            kind: 'symptom',
            dataId: ''
        }

        this.setState({
            dialog: <EditDataTypeDialog
                appContext={appContext}
                dataType={dataType}
                onCancel={() => {
                    this.setState({ dialog: null });
                }}
                onDone={() => {
                    this.setState({ dialog: null }, this.resetSelectDataTypeChild.bind(this));
                }} />
        });
    }


    saveRules() {
        const { appContext } = this.props;
        const { rules } = this.state;

        if (isArrayValid(rules)) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            Promise.map(rules, (rule) => {
                if (this.state.reviewed)
                    rule.isReviewed = true;
                else if (this.state.clearReviewed)
                    rule.isReviewed = false;
                if (this.state.ignore)
                    rule.isIgnored = true;
                else if (this.state.clearIgnore)
                    rule.isIgnored = false;
                if (this.state.updateTargetUnit)
                    rule.targetUnit = this.state.targetUnit;
                if (this.state.updateDataId)
                    rule.dataId = this.state.dataId;
                console.debug(`calling save observation `, rule.ruleId)
                return appContext.stores.DataTypesStore.saveObservationRule(rule);
            }, { concurrency: 20 }).then(() => {
                this.setState({ progress: null, error: null });
                this.closeView();
            }).catch((err) => {
                this.setState({ progress: null, error: getErrorMessage(err) });
            })
        }
    }


    render() {
        const { appContext } = this.props;
        const { rules, progress, dialog, dataId, reviewed, ignore, error, clearIgnore, clearReviewed } = this.state;

        return (
            <Drawer variant="persistent" anchor="right" open={true} >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => this.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Observations Review</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    Total Rules: {rules.length}
                                    <span style={{ color: 'red' }}>{error}</span>
                                </td>

                                <td valign="top" align="right">
                                    <IconButton onClick={this.saveRules.bind(this, null, null)}>{progress || <SaveIcon />}</IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ol>
                                        {isArrayValid(rules) && rules.map((rule, i, a) => {
                                            if (i < MAX_RULES_DISPLAYED)
                                                return <li key={i}>{rule.name || `<no name>`} {rule.system || `<no system>`}</li>;
                                            else if (i === MAX_RULES_DISPLAYED) {
                                                return <span>... and {rules.length - MAX_RULES_DISPLAYED} more not displayed.</span>
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </ol>

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={this.state.updateDataId}
                                            onChange={(e) => {
                                                this.setState({ updateDataId: e.target.checked });
                                            }}
                                        />
                                        } label="Update Data Type" />
                                    <SelectDataType
                                        category={'labTest,vitals'}
                                        appContext={appContext} dataId={dataId}
                                        ref={this._SelectDataTypeChild}
                                        enableNone={true}
                                        onSelect={(type) => {
                                            this.setState({ dataId: type ? type.dataId : '', updateDataId: true })
                                        }}
                                        onCreate={() => {
                                            this.setState({
                                                dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                                    dataType={{ dataId: '', name: '', description: '', category: 'labTest', tupleDescriptions: [] }}
                                                    onCancel={() => this.setState({ dialog: null })}
                                                    onDone={(newType) => {
                                                        this.setState({ dialog: null, dataId: newType.dataId, updateDataId: true });
                                                    }} />
                                            });
                                        }}
                                    />
                                    <br />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={this.state.updateTargetUnit}
                                            onChange={(e) => {
                                                this.setState({ updateTargetUnit: e.target.checked });
                                            }}
                                        />
                                        } label="Update Target Unit" />
                                    <SelectUnit
                                        value={this.state.targetUnit}
                                        onChange={(targetUnit) => {
                                            console.log("targetUnit:", targetUnit);
                                            this.setState({ targetUnit, updateTargetUnit: true })
                                        }}
                                        enableNone={true}
                                    />
                                    <br />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={reviewed}
                                            onChange={(e) => {
                                                this.setState({ reviewed: e.target.checked, clearReviewed: false });
                                            }}
                                        />
                                        } label="Flag as Reviewed" />
                                    <br />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={clearReviewed}
                                            onChange={(e) => {
                                                this.setState({ clearReviewed: e.target.checked, reviewed: false });
                                            }}
                                        />
                                        } label="Clear Reviewed" />
                                    <br />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={ignore}
                                            onChange={(e) => {
                                                this.setState({ ignore: e.target.checked, clearIgnore: false });
                                            }}
                                        />
                                        } label="Flag as Ignored" />
                                    <br />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={clearIgnore}
                                            onChange={(e) => {
                                                this.setState({ clearIgnore: e.target.checked, ignore: false });
                                            }}
                                        />
                                        } label="Clear Ignored" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
            </Drawer>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 10
    },
    table: {
        "width": "100%"
    }
}

export default ReviewObservations;
