import React, { Component } from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';

import {
    CircularProgress,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    TextField,
    InputLabel,
    FormControlLabel, 
    Checkbox
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';
import ColorPicker from 'material-ui-color-picker';

import Axios from 'axios';

import Config from '@apricityhealth/web-common-lib/Config';
import SelectFlagType from '@apricityhealth/web-common-lib/components/SelectFlagType';
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import EditFlagArrayView from '../components/EditFlagArrayView';
import ChangedByButton from '../components/ChangedByButton';

import '../styles/workup.css';
import createIdFromText from "../utils/CreateIdFromText";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

class AlertLevelView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertLevelId: props.alertLevelId,
            alertLevel: { alertLevelId: '', name: '', color: '', addFlag: '', priority: '0', removeFlags: [], triage: this.props.triage },
            dialog: null,
            model: null,
            drawerOpen: false,
            modified: false,
            progress: null
        };
    }

    componentDidMount() {
        console.log("componentDidMount:", this );
        if ( this.props.alertLevelId )
            this.loadAlertLevel(this.props.alertLevelId);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.alertLevelId !== this.props.alertLevelId)
            this.setState({ alertLevelId: this.props.alertLevelId }, this.loadAlertLevel.bind(this, this.props.alertLevelId));
    }


    onCloseView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else
            this.props.onClose();
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Alert Level Modified!</DialogTitle>
            <DialogContent>This alert level has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveAlertLevel(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    onToggleDrawer() {
        var newState = !this.state.drawerOpen;
        this.setState({ drawerOpen: newState });
    }

    loadAlertLevel(alertLevelId) {
        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadAlertLevel.bind(this, alertLevelId));
        }

        const self = this;
        const { appContext } = this.props;
        const { plan, idToken } = appContext.state;
        
        if ( alertLevelId ) {
            this.setState({
                progress: <CircularProgress size={20} />, error: null
            });
    
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}types/${plan.planId}/alert_levels/${alertLevelId}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };

            console.log("loadAlertLevel request", request);
            Axios(request).then((response) => {
                console.log("loadAlertLevel response:", response);
                self.setState({ alertLevel: response.data[0], progress: null } );
            }).catch((error) => {
                console.log("loadAlertLevel error:", error.response);
                self.setState({ alertLevel: null, progress: null, error: getErrorMessage(error) })
            });
        }
        else {
            this.setState( { alert: {
                alertLevelId: '',
                name: '',
                description: '',
                priority: '0',
                order: '0',
                color: '',
                addFlag: '',
                removeFlags: [],
                triage: this.props.triage
            }});
        }
    }

    saveAlertLevel(callback, planId = null) {
        const self = this;
        const { appContext } = this.props;
        const { idToken } = appContext.state;
        const { alertLevel } = this.state;

        if ( !planId && alertLevel.planId && alertLevel.planId !== appContext.state.plan.planId ) {
            return this.displayOverrideDialog( alertLevel.planId, this.saveAlertLevel.bind(this,callback) );
        }
        if (! planId ) {
            planId = appContext.state.plan.planId;
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null });

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/alert_levels/`,
            method: 'POST',
            headers: { "Authorization": idToken },
            data: alertLevel
        };

        console.log("saveAlertLevel request", request);
        Axios(request).then((response) => {
            console.log("saveAlertLevel response:", response);
            self.setState({ alertLevelId: response.data.alertLevelId, alertLevel: response.data, progress: null }, callback );
        }).catch((error) => {
            console.log("saveAlertLevel error:", error.response);
            self.setState({ progress: null, error: getErrorMessage(error) })
        });
    }

    deleteAlertLevel() {
        console.log("deleteModel:", this);
        const self = this;
        const { alertLevel } = this.state;
        const { appContext } = this.props;
        const { idToken } = appContext.state;

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Delete this Alert Level, are you sure?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: <CircularProgress size={20} /> });
                    
                    const request = {
                        url: Config.baseUrl + `${Config.pathPrefix}types/${alertLevel.planId}/alert_levels/${alertLevel.alertLevelId}`,
                        method: 'DELETE',
                        headers: { "Authorization": idToken }
                    };
            
                    console.log("deleteAlertLevel request", request);
                    Axios(request).then((response) => {
                        console.log("deleteAlertLevel response:", response);
                        self.setState({ progress: null, dialog: null }, self.onCloseView.bind(self));
                    }).catch((error) => {
                        console.log("deleteAlertLevel error:", error.response);
                        self.setState({ dialog: null, progress: null, error: getErrorMessage(error) })
                    });
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: null, dialog: null });
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }


    onShowCode() {
        let { alertLevel } = this.state;
        let { appContext } = this.props;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={alertLevel}
                onEditDone={(alertLevel) => {
                    this.setState({alertLevel, dialog: null});
                }}
                onDone={() => { 
                    this.setState({ dialog: null }); 
                }} />
        });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { progress, error } = this.state;
        const { dialog, alertLevel } = self.state;

        if (!Array.isArray(alertLevel.removeFlags))
            alertLevel.removeFlags = [];
        if ( alertLevel.description === undefined ) alertLevel.description = '';
        if ( alertLevel.order === undefined ) alertLevel.order = 0;

        let deleteAlertLevelButton = this.props.onClose ? <IconButton onClick={this.deleteAlertLevel.bind(this)}><DeleteIcon /></IconButton> : null;
        let editAlertTypeMenu = <table width="100%"><tbody>
            <tr>
                <td></td>
                <td align="right">
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadAlertLevel.bind(this, this.state.alertLevelId)}>{progress || <RefreshIcon />}</IconButton>
                    <IconButton onClick={this.saveAlertLevel.bind(this, this.onCloseView.bind(this), null)}><SaveIcon /></IconButton>
                    <IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton>
                    {deleteAlertLevelButton}
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <TextField disabled={true} style={styles.name} label="alertLevelId" value={alertLevel.alertLevelId} onChange={(e) => {
                        alertLevel.alertLevelId = e.target.value;
                        self.setState({ alertLevel });
                    }} />
                    <br />
                    <TextField style={styles.name} label="Name" value={alertLevel.name} onChange={(e) => {
                        alertLevel.name = e.target.value;
                        alertLevel.alertLevelId = createIdFromText( alertLevel.name );
                        self.setState({ alertLevel });
                    }} />
                    <br />
                    <TextField style={styles.description} label="Description" value={alertLevel.description} onChange={(e) => {
                        alertLevel.description = e.target.value;
                        self.setState({ alertLevel });
                    }} />
                    <br />
                    <TextField style={styles.name} label="Order" type='number' value={alertLevel.order} onChange={(e) => {
                        alertLevel.order = e.target.value;
                        self.setState({ alertLevel });
                    }} />
                    <br />
                    <TextField style={styles.name} label="Priority" type='number' value={alertLevel.priority} onChange={(e) => {
                        alertLevel.priority = e.target.value;
                        self.setState({ alertLevel });
                    }} />

                    <br />
                    <div style={{margin: 5}}>
                        <InputLabel>Color<br />
                        </InputLabel> 
                        <ColorPicker name='color' TextFieldProps={{ value: alertLevel.color }} value={alertLevel.color} onChange={(color) => {
                            alertLevel.color = color;
                            self.setState({ alertLevel});
                        }} />
                    </div>
                    <br />
                    <SelectFlagType appContext={appContext} label="Add Flag" flagId={alertLevel.addFlag} onChange={(type) => {
                        console.log("onChange flag:", type );
                        alertLevel.addFlag = type ? type.flagId : '';
                        self.setState({ alertLevel });
                    }} />
                    <br />
                    <div style={{margin: 5}}>
                        <EditFlagArrayView appContext={appContext} label="Remove Flags" array={alertLevel.removeFlags} onChange={(array) => {
                            alertLevel.removeFlags = array;
                            self.setState({ alertLevel });
                        }}/>
                    </div>
                    <br />
                    <FormControlLabel style={{margin: 5}} control={<Checkbox checked={alertLevel.triage} onChange={(e,v) => {
                        alertLevel.triage = v;
                        self.setState({ alertLevel });
                    }} />}label="Triage" />
                    <br />
                    <ChangedByButton appContext={appContext} primaryKey={alertLevel.alertLevelId} collection='AlertLevelModel' />
                </td>

            </tr>
            <tr>
                <td colSpan="2" valign="top">
                    {dialog}
                    {progress}
                </td>
            </tr>
        </tbody></table>;

        return (
            <div align="center" style={{width: 600}}>
                <AppBar style={{width: 600}} position="static">
                    <Toolbar>
                        <IconButton onClick={this.onCloseView.bind(this)}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Alert Level</Typography>
                    </Toolbar>
                </AppBar>
                <div align="left" style={styles.div}>
                    {editAlertTypeMenu}
                </div>
            </div>
        );
    }
}

const styles = {
    root: {
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 60
    },
    table: {
        width: '70%',
        height: 800
    },
    content: {
        transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
        marginRight: 60,
        paddingBottom: 20
    },
    div: {
        margin: 10
    },
    progress: {
        color: 'red'
    },
    drawer: {
        overflow: 'hidden',
        width: 50,
        marginTop: '70px',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        overflow: 'hidden',
        width: 600,
        resize: 'vertical',
        marginTop: '70px',
    },
    drawerClose: {
        marginTop: '70px',
        overflowX: 'hidden',
        resize: 'vertical',
        width: 50
    },
    menuExpansion: {
        width: '1200px',
        justifyContent: 'left'
    },
    menuExpansionDetails: {
        width: '1200px',
        padding: '0px'
    },
    name: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

export default AlertLevelView;