import React, { Component } from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';

import {
    CircularProgress,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    TextField
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';

import Axios from 'axios';

import Config from '@apricityhealth/web-common-lib/Config';
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import ChangedByButton from '../components/ChangedByButton';

import '../styles/workup.css';
import createIdFromText from "../utils/CreateIdFromText";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

class BroadcastGroupView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: props.groupId,
            group: { groupId: '', name: '', description: '', condition: '' },
            dialog: null,
            modified: false,
            progress: null
        };
    }

    componentDidMount() {
        console.log("componentDidMount:", this );
        if ( this.props.groupId )
            this.loadBroadcastGroup(this.props.groupId);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.groupId !== this.props.groupId)
            this.setState({ groupId: this.props.groupId }, this.loadBroadcastGroup.bind(this, this.props.groupId));
    }


    onCloseView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else {
            this.props.onClose();
        }
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Broadcast Group Modified!</DialogTitle>
            <DialogContent>This broadcast group has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveBroadcastGroup(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    loadBroadcastGroup(groupId) {
        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadBroadcastGroup.bind(this, groupId));
        }

        const self = this;
        const { appContext } = this.props;
        const { plan, idToken } = appContext.state;
        
        if ( groupId ) {
            this.setState({
                progress: <CircularProgress size={20} />, error: null
            });
    
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}types/${plan.planId}/broadcast_groups/${groupId}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };

            console.log("loadBroadcastGroup request", request);
            Axios(request).then((response) => {
                console.log("loadBroadcastGroup response:", response);
                self.setState({ group: response.data[0], progress: null } );
            }).catch((error) => {
                console.log("loadBroadcastGroup error:", error.response);
                self.setState({ group: null, progress: null, error: getErrorMessage(error) })
            });
        }
        else {
            this.setState( { group: {
                groupId: '',
                name: '',
                description: '',
                condition: ''
            }});
        }
    }

    saveBroadcastGroup(callback, planId = null) {
        const self = this;
        const { appContext } = this.props;
        const { idToken } = appContext.state;
        const { group } = this.state;

        if ( planId && group.planId && group.planId !== appContext.state.plan.planId ) {
            return this.displayOverrideDialog( group.planId, this.saveBroadcastGroup.bind(this,callback) );
        }
        if (! planId ) {
            planId = appContext.state.plan.planId;
        }
        this.setState({ progress: <CircularProgress size={20} />, error: null });

        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/broadcast_groups/`,
            method: 'POST',
            headers: { "Authorization": idToken },
            data: group
        };

        console.log("saveBroadcastGroup request", request);
        Axios(request).then((response) => {
            console.log("saveBroadcastGroup response:", response);
            self.setState({ groupId: response.data.groupId, group: response.data, progress: null, modified: false }, callback );
        }).catch((error) => {
            console.log("saveBroadcastGroup error:", error);
            self.setState({ progress: null, error: getErrorMessage(error) })
        });
    }

    deleteBroadcastGroup() {
        console.log("deleteBroadcastGroup:", this);
        const self = this;
        const { group } = this.state;
        const { appContext } = this.props;
        const { idToken } = appContext.state;

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Delete this Broadcast Group, are you sure?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: <CircularProgress size={20} /> });
                    
                    const request = {
                        url: Config.baseUrl + `${Config.pathPrefix}types/${group.planId}/broadcast_groups/${group.groupId}`,
                        method: 'DELETE',
                        headers: { "Authorization": idToken }
                    };
            
                    console.log("deleteBroadcastGroup request", request);
                    Axios(request).then((response) => {
                        console.log("deleteBroadcastGroup response:", response);
                        self.setState({ progress: null, dialog: null }, self.onCloseView.bind(self));
                    }).catch((error) => {
                        console.log("deleteBroadcastGroup error:", error.response);
                        self.setState({ dialog: null, progress: null, error: getErrorMessage(error) })
                    });
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: null, dialog: null });
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }


    onShowCode() {
        let { group } = this.state;
        let { appContext } = this.props;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={group}
                onEditDone={(group) => {
                    this.setState({ group, dialog: null});
                }}
                onDone={() => { 
                    this.setState({ dialog: null }); 
                }} />
        });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { progress, error } = this.state;
        const { dialog, group } = self.state;

        if ( group.groupId === undefined ) group.groupId = '';
        if ( group.description === undefined ) group.description = '';
        if ( group.name === undefined ) group.name = '';

        let deleteButton = this.props.onClose ? <IconButton onClick={this.deleteBroadcastGroup.bind(this)}><DeleteIcon /></IconButton> : null;
        let editAlertTypeMenu = <table width="100%"><tbody>
            <tr>
                <td></td>
                <td align="right">
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadBroadcastGroup.bind(this, this.state.groupId)}>{progress || <RefreshIcon />}</IconButton>
                    <IconButton onClick={this.saveBroadcastGroup.bind(this, null, null)}><SaveIcon /></IconButton>
                    <IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton>
                    {deleteButton}
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <TextField disabled={true} style={styles.name} label="groupId" value={group.groupId} onChange={(e) => {
                        group.groupId = e.target.value;
                        self.setState({ group, modified: true });
                    }} />
                    <br />
                    <TextField style={styles.name} label="Name" value={group.name} onChange={(e) => {
                        group.name = e.target.value;
                        group.groupId = createIdFromText( group.name );
                        self.setState({ group, modified: true });
                    }} />
                    <br />
                    <TextField style={styles.description} label="Description" value={group.description} onChange={(e) => {
                        group.description = e.target.value;
                        self.setState({ group, modified: true });
                    }} />
                    <br />
                    <EditConditions
                        appContext={appContext}
                        conditions={group.condition}
                        onChange={(condition) => {
                            group.condition = condition;
                            self.setState({ group, modified: true });
                        }}
                        name={'Condition'}
                    />
                    <br />
                    <ChangedByButton appContext={appContext} primaryKey={group.groupId} collection='BroadcastGroupModel' />
                </td>

            </tr>
            <tr>
                <td colSpan="2" valign="top">
                    {dialog}
                    {progress}
                </td>
            </tr>
        </tbody></table>;

        return (
            <div align="center" style={{width: 600}}>
                <AppBar style={{width: 600}} position="static">
                    <Toolbar>
                        <IconButton onClick={this.onCloseView.bind(this)}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Broadcast Group</Typography>
                    </Toolbar>
                </AppBar>
                <div align="left" style={styles.div}>
                    {editAlertTypeMenu}
                </div>
            </div>
        );
    }
}

const styles = {
    root: {
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 60
    },
    table: {
        width: '70%',
        height: 800
    },
    content: {
        transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
        marginRight: 60,
        paddingBottom: 20
    },
    div: {
        margin: 10
    },
    progress: {
        color: 'red'
    },
    drawer: {
        overflow: 'hidden',
        width: 50,
        marginTop: '70px',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    menuExpansion: {
        width: '1200px',
        justifyContent: 'left'
    },
    menuExpansionDetails: {
        width: '1200px',
        padding: '0px'
    },
    name: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

export default BroadcastGroupView;