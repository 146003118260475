import React, { Component } from 'react';
import Axios from 'axios';
import {
    TextField,
    AppBar,
    Button,
    Toolbar,
    CircularProgress,
    Typography,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import Config from '@apricityhealth/web-common-lib/Config';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import JsonDialog from '../dialogs/JsonDialog';
import CodeIcon from '@material-ui/icons/Code';
import LinkIcon from '@material-ui/icons/Launch';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

class TrialView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            progress: null,
            trial: props.trial
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.trial !== oldProps.trial)
            this.loadTrial(this.props.trial);
    }

    closeTrial() {
        console.log("closeTrial()", this);
        this.props.onClose();
    }

    onChange = type => e => {
        let { trial } = this.state;
        trial[type] = e.target.value;
        this.setState({ trial });
    }
    changeDate(time) {
        let { trial } = this.state;
        if (time.endDate) {
            trial.endDate = time.endDate
        }
        if (time.startDate) {
            trial.startDate = time.startDate
        }
        this.setState({ trial });
    }

    loadTrial(trial) {
        console.log("loadTrial:", trial);
        this.setState({
            trial: trial,
            progress: null,
        });
    }
    deleteTrial() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Trial: {`${self.state.trial.name}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.onCloseDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDelete() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onShowCode() {
        this.setState({
            dialog: <JsonDialog
                appContext={this.props.appContext}
                dataType={this.state.trial}
                onEditDone={(trial) => {
                    this.setState({trial, dialog: null});
                }}
                onDone={this.onCloseDialog.bind(this)} />
        });
    }
    confirmDelete() {
        const self = this;
        const { trial } = self.state;

        self.setState({ dialog: null });
        if (trial.trialId) {
            const deleteTrial = {
                url: Config.baseUrl + `${Config.pathPrefix}trials/${trial.trialId}`,
                method: 'DELETE',
                headers: { "Authorization": self.props.appContext.state.idToken }
            };

            self.setState({ progress: <CircularProgress /> });
            console.log("deleteTrial:", deleteTrial);
            Axios(deleteTrial).then(function (response) {
                console.log("deleteTrial response:", response);
                self.setState({ progress: null });
                self.closeTrial();
            }).catch(function (error) {
                console.log("deleteTrial error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }
    saveTrial() {
        const self = this;
        let { trial } = self.state;

        const saveTrial = {
            url: Config.baseUrl + `${Config.pathPrefix}trials`,
            method: 'POST',
            headers: { "Authorization": self.props.appContext.state.idToken },
            data: trial
        };

        this.setState({ progress: <CircularProgress /> });
        console.log("saveTrial request:", saveTrial);
        Axios(saveTrial).then(function (response) {
            console.log("saveTrial response:", response);
            self.setState({ trial: response.data, progress: null });
        }).catch(function (error) {
            console.log("saveTrial error:", error);
            self.setState({ progress: error.message });
        });
    }

    render() {
        const { appContext } = this.props;
        const { dialog, trial, progress } = this.state;
        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={() => this.closeTrial()}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="title" color="inherit">Trial Details</Typography>
            </Toolbar>
        </AppBar> : null;

        return (
            <div align="left">
                {appBar}
                <div align="left" style={{ margin: 10 }}>
                    <div style={styles.trialHeader}>
                        <div>Trial ID: {trial.trialId}</div>
                        <div >
                            <IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton>
                            <IconButton onClick={this.saveTrial.bind(this)}><SaveIcon /></IconButton>
                            <IconButton onClick={this.deleteTrial.bind(this)}><DeleteIcon /></IconButton>
                        </div>
                    </div>
                    <FormControlLabel label='Active' style={{ margin: 5 }} control={
                        <Checkbox checked={trial.active} onChange={(e, v) => { trial.active = v; this.setState({ trial }) }} />} />
                    <TextField
                        style={{ margin: 5 }}
                        fullWidth
                        label="Name"
                        variant="outlined"
                        value={trial.name}
                        onChange={this.onChange("name")}
                    />
                    <TextField
                        style={{ margin: 5 }}
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={trial.description}
                        multiline={true}
                        rows={5}
                        onChange={this.onChange("description")}
                    />
                    <TextField
                        style={{ margin: 5 }}
                        fullWidth
                        label="Criteria"
                        variant="outlined"
                        value={trial.criteria}
                        multiline={true}
                        rows={10}
                        onChange={this.onChange("criteria")}
                    />
                    <TextField
                        style={{ margin: 5 }}
                        fullWidth
                        label="Intervention"
                        variant="outlined"
                        value={trial.intervention}
                        multiline={true}
                        rows={2}
                        onChange={this.onChange("intervention")}
                    />
                    <TextField
                        style={{ margin: 5 }}
                        fullWidth
                        label="Keywords"
                        variant="outlined"
                        value={trial.keywords}
                        multiline={false}
                        rows={5}
                        onChange={this.onChange("keywords")}
                    />
                    <TextField
                        style={{ margin: 5, width: '90%' }}
                        label="URL"
                        variant="outlined"
                        value={trial.url}
                        onChange={this.onChange("url")}
                    />
                    <a href={trial.url} target='_blank' rel="noreferrer"><IconButton><LinkIcon /></IconButton></a>
                    <br />
                    <TextField
                        style={{ margin: 5, width: 200 }}
                        label='Phase'
                        value={trial.phase}
                        onChange={this.onChange('phase')}
                    />
                    <TextField
                        style={{ margin: 5, width: 200 }}
                        label='Status'
                        value={trial.status}
                        onChange={this.onChange('status')}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            style={styles.picker}
                            openTo="year"
                            label="Start Date"
                            format="MM/dd/yyyy"
                            value={trial.startDate}
                            onChange={(startDate) => { this.changeDate({ startDate }) }}
                        />
                        <IconButton style={{ verticalAlign: 'bottom' }} onClick={() => { trial.startDate = null; this.setState({ trial }) }}><DeleteIcon /></IconButton>
                        <DatePicker
                            style={styles.picker}
                            openTo="year"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={trial.endDate}
                            onChange={(endDate) => { this.changeDate({ endDate }) }}
                        />
                        <IconButton style={{ verticalAlign: 'bottom' }} onClick={() => { trial.endDate = null; this.setState({ trial }) }}><DeleteIcon /></IconButton>
                    </MuiPickersUtilsProvider>
                    <br />
                    <EditConditions
                        appContext={appContext}
                        conditions={trial.condition}
                        onChange={(condition) => {
                            trial.condition = condition;
                            this.setState({ trial });
                        }}
                        name={'Trial Conditions'}
                    />
                </div>
                {progress}
                {dialog}
            </div>
        );
    }
}

/*
    trialId: { type: String,  required:true},
    name: { type: String },
    description: { type: String },
    startDate: { type: Date,  required:true},
    endDate: { type: Date,  required:false},
*/

const styles = {
    trialHeader: {
        marginLeft: 12,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    picker: {
        margin: 5,
        width: 150
    },
    appBar: {
        backgroundColor: "#FF9800"
    }
}

export default TrialView;
