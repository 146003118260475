import React from "react";

import {
    withStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core/';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChangedByButton from './ChangedByButton';
import DiffRowCheckbox from './DiffRowCheckbox';

// CSS
import "../styles/content.css";
import "diff2html/dist/diff2html.min.css";

export class DiffRowExpansionBox extends React.Component {
    static defaultProps = {
        expanded: false
    }

    shouldComponentUpdate(nextProps) {
        if ((nextProps.checked !== this.props.checked) || 
            (nextProps.expanded !== this.props.expanded) || 
            (nextProps.view.filename !== this.props.view.filename)) {
            return true
        } else {
            return false
        }
    }

    render() {
        const { expanded, id, onChange, view, allowMerge, approve, checked } = this.props
        let { appContext, classes } = this.props;

        //console.log("DiffRowExpansionBox render:", view, this.props );
        let diffDetails = (k, view) => {
            const { expanded } = this.props
            if (expanded) {
                return (
                    <div style={styles.diffBox}>
                        {getDiffHtml(k, view)}
                    </div>
                )
            }
        }
        let getDiffHtml = (k, view) => {
            let { expanded } = this.props
            if (expanded) {
                return (
                    <div dangerouslySetInnerHTML={{ __html: view.diffHtml }} />
                )
            }
        }
        let checkbox = allowMerge !== true ? <DiffRowCheckbox id={view.index} checked={checked} onChange={approve.bind(this, view.index)} /> : null;
        let details = diffDetails(id, view) || <div />;
        return (
            <Accordion
                key={id}
                value={id}
                expanded={expanded}
                onChange={(e) => { onChange(id) }}
                style={{ backgroundColor: 'white', margin: '0px' }}
                classes={{ root: classes.rootExpand, expanded: classes.rootExpand }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={styles.panelSummary}
                    classes={{ content: classes.rootExpand, root: classes.rootExpand, expanded: classes.rootExpand }}

                >
                    <div style={styles.manageContent} key={id}>
                        <div style={styles.diffRow}>
                            {checkbox}
                            <div>{view.filename}</div>
                            <div style={styles.spacer}></div>
                            <ChangedByButton appContext={appContext} changeKeys={view.changeKeys} />
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {details}
                </AccordionDetails>
            </Accordion>
        )
    }
}


const styles = {
    manageContent: {
        width: '100%'
    },
    diffBox: {
        width: '100%'
    },
    diffRow: {
        display: 'flex',
        alignItems: 'center',
        margin: 2
    },
    panelSummary: {
        margin: 2,
    },
    spacer: {
        flexGrow: 1
    }
}

const materialStyles = () => ({
    rootExpand: {
        margin: '0 !important',
    }
});

export default withStyles(materialStyles)(DiffRowExpansionBox);
