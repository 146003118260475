import React from 'react';
import { Drawer,
    CircularProgress, 
    TextField, 
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import ValidIcon from '@material-ui/icons/CheckCircle';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import PredictorModelView from './PredictorModelView';

import {
    loadPredictorModels
} from '@apricityhealth/web-common-lib/utils/Services';

import PubSub from 'pubsub-js'

function filterModel(filter, model) {
    if (model.predictorId && model.predictorId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (model.description && model.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (model.name && model.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;

    return false;
}

export class PredictionModelsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            models: []
        }
    }
    
    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.loadContent();
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        let { appContext } = this.props;
        let { dependencies } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadPredictorModels(appContext, '*', dependencies).then((models) => {
            self.setState({ models, progress: null, error: null }, self.onContentLoaded.bind(self));
        }).catch((err) => {
            self.setState({ progress: null, error: err.message });
        });
    }

    onContentLoaded() {
        this.updateFilter();
        this.handleOpen();
    }

    handleOpen() {
        // automatically open the question by ID if provided..
        let parts = window.location.pathname.slice(1).split('/');
        if ( parts.length > 1) {
            window.history.replaceState( null, '',  window.location.href.substring( 0, window.location.href.lastIndexOf('/') ));
            let model = this.state.models.find((e) => e.predictorId === parts[1]);
            if ( model )
                this.openModel(model);
        }
    }

    updateFilter() {
        let { filter, models } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < models.length; ++i) {
                if (filterModel(filter, models[i]))
                    filtered.push(models[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });
    }

    selectModel(s, t) {
        console.log("selectModel:", s, t);
        if (s.length > 0) {
            const model = this.state.filtered ? this.state.filtered[s[0]] : this.state.models[s[0]];
            this.openModel( model );
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    openModel(model) {
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <PredictorModelView appContext={this.props.appContext} model={model} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    createModel() {
        console.log("createModel");
        let newModel = { algorithm: 'AutoML', planId: '', name: '', description: '', predictorId: '', 
            target: { dataId: '', tupleIndex: '', type: 'float', average: true }, 
            related: [], meta: [], forecastFrequency: 'D', updateFrequency: '1d', backFill: 'median', middleFill: 'median', 
            futureFill: 'median', forecastLength: 10, ownerId: '', training: {
                sourceStage: 'develop',
                flagged: true,
                maxPatients: 1000,
                andFilters: [],
                orFilters: []
            } };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <PredictorModelView appContext={this.props.appContext} model={newModel} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }

    render() {
        const self = this;
        let { filter, filtered, models, dialog, error, progress, dependencies } = this.state;
        let errors = this.props.appContext.stores.DataTypesStore.getErrors(`predictor_models`).errors;

        const columnData = [
            { id: 'predictorId', width: 25, label: 'Valid', formatValue: (v) => {
                return errors[v] ? <Tooltip key='error' title={errors[v].join(', ')}><WarningIcon style={{ fill: 'red'}} /></Tooltip> : <ValidIcon key='valid' style={{ fill: 'green'}} />;
            }},
            { id: 'predictorId', numeric: false, disabledPadding: false, label: 'Predictor ID' },
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={styles.text} value={filter} label="Filter"
                            onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._types = table}
                            onSelected={this.selectModel.bind(this)}
                            onAdd={this.createModel.bind(this)}
                            orderBy='predictorId'
                            columnData={columnData}
                            data={filtered ? filtered : models}
                            title='Predictor Models' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 500
    },
}

export default PredictionModelsView;
