import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core/';

import EditConditionGroup from '../views/EditConditionGroup';

class EditConditionGroupDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: JSON.parse( JSON.stringify( props.group )),
        };
    }

    componentDidUpdate(oldProps) {
        if (oldProps.group !== this.props.group)
            this.setState({ group: JSON.parse( JSON.stringify( this.props.group)) });
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        const { onDone } = this.props;
        const { group } = this.state;
        if (onDone)
            onDone(group);
    }


    render() {
        let { group } = this.state;
        let { appContext, model } = this.props
        let self = this;
        return <Dialog fullWidth={true} maxWidth={'md'} model="true" open={true}>
            <DialogContent>
                <EditConditionGroup appContext={appContext} group={group} model={model} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Ok</Button>
                <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    }
};

EditConditionGroupDialog.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditConditionGroupDialog);
