import React, { Component } from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
} from '@material-ui/core/';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

function getText(texts, textId, language = 'en-us') {
    if ( texts[language] ) {
        if ( texts[language][textId] ) {
            return texts[language][textId]
        }
    }
    return null;
}

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

const AWS_LANG_CODES = {
    'Arabic': 'ar',
    'Chinese (Simplified)': 'zh',
    'Chinese (Traditional)': 'zh-tw',
    'Czech ': 'cs',
    'Danish ': 'da',
    'Dutch ': 'nl',
    'Finnish ': 'fi',
    'French ': 'fr',
    'German ': 'de',
    'Hebrew ': 'he',
    'Indonesian ': 'id',
    'Italian': 'it',
    'Japanese ': 'ja',
    'Korean ': 'ko',
    'Polish': 'pl',
    'Portuguese ': 'pt',
    'Russian ': 'ru',
    'Spanish ': 'es',
    'Swedish': 'sv',
    'Turkish ': 'tr'
}

class AdHocTranslationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            node: props.node,
            dialog: null,
            progress:null,
            language: props.language,
            languageDescription : '',
            englishText: '',
            textId:'',
            texts:props.texts
        };

        console.log("AdHocTranslationDialog:", props.node );
    }

    translate() {
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        let { textId, language } = this.state;

        this.setState({progress: <CircularProgress />, error: null });
        console.log("translate:", textId, language );
        store.translateText(textId, language).then((result) => {
            this.setState({progress: null});
            this.props.onDone(result);
        }).catch((err) => {
            console.error("translateText error:", err );
            this.setState({progress: null, error: getErrorMessage(err)})
        });
    }

    componentDidMount(){
        this.findEnglishTranslation()
    }

    findEnglishTranslation(){
        let {node, texts, language}= this.state
        let self = this
        let textId = '';
        if (node.isQuestion) {
            textId = node.question.questionId;
        } else if (node.isAnswer) {
            textId = node.question.questionId + '.' + node.answer.answerId
        }
        let lang = getKeyByValue(AWS_LANG_CODES, language);
        let text = getText(texts, textId, 'en-us');
        if (text){
            self.setState({textId: textId, englishText:text.text, languageDescription:lang})
        }

    }

    render(){
        let {dialog, progress, error, englishText, textId, languageDescription} = this.state
        let disableTranslate = true
        let self = this

        let englishTranslation = () => {
            console.log("englishTranslation:", englishText, languageDescription, textId);
            if (englishText && languageDescription && textId ){
                disableTranslate = false
                return (
                    <div>
                        <div>TextId: <b>{textId}</b></div>
                        <div>English Text: <b> {englishText} </b></div>
                        <br/>
                        <div>Attempt to translate English to {languageDescription}? </div>
                    </div>
                )
            }else{
                return (
                    <div>
                    English Text: <b> English Text Not found</b>
                    </div>
                )
            }
        }
        return (
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                model="false"
                open={true}
            >
                    <DialogTitle>Content Translation</DialogTitle>
                    <DialogContent>
                        {englishTranslation()}
                        {dialog}
                    </DialogContent>
                    <DialogActions>
                        <span style={{color: 'red'}}>{error}</span>
                        {progress}
                        <Button variant="contained" self={self} disabled={disableTranslate}  onClick={this.translate.bind(this)}>Translate</Button>
                        <Button variant="contained" self={self} onClick={this.props.onCancel}>Cancel</Button>
                    </DialogActions>
            </Dialog>
        )
    }

}

export default AdHocTranslationDialog