import React, { Component } from "react";
import PropTypes from 'prop-types';

import {
    Chip,
    FormLabel,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    withStyles, 
    Avatar
} from '@material-ui/core/';

import FlagIcon from '@material-ui/icons/Flag';
import AddIcon from '@material-ui/icons/Add';

import { SelectActivityType } from "./SelectActivityType";

class EditActivityArrayView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            array: props.array || [],
            name: props.name,
            menu: null
        };
    }

    onAdd() {
        const { array } = this.state;
        array.unshift( { activityType: '' })
        this.setState({ array }, () => {
            if ( this.props.onChange ) {
                this.props.onChange(array);
            }
        });
    }

    onUpdateActivityType(index, type) {
        const { array } = this.state;
        array[index] = type ? { activityType: type.activityType } : { activityType: '' };
        this.setState({ array, menu: null }, () => {
            if ( this.props.onChange ) {
                this.props.onChange(array);
            }
        });
    }

    onChangeData(index) {
        return (e) => {
            let { array } = this.state;
            const { appContext } = this.props;
            let data = array[index] || '';
            let menu = <Menu open={true} onClose={this.closeMenu.bind(this)} anchorEl={e.target}>
                <MenuItem style={{ height: 50 }}>
                    <SelectActivityType
                        appContext={appContext}
                        activityType={data.activityType}
                        onChange={(activityType) => this.onUpdateActivityType(index, { activityType })} />
                </MenuItem>
            </Menu>;
            this.setState({ menu });
        }
    }

    closeMenu() {
        this.setState({ menu: null });
    }

    onDelete(index) {
        return () => {
            let { array } = this.state;
            array.splice( index, 1)
            this.setState({ array }, () => {
                if ( this.props.onChange ) {
                    this.props.onChange(array);
                }
            });
        }
    }

    render() {
        let { array, name, menu } = this.state;

        let dataChips = [];
        for (let i = 0; i < array.length; i++) {
            let data = array[i];
            dataChips.push(
                <Chip
                    key={i}
                    avatar={<Avatar><FlagIcon /></Avatar>}
                    label={data.activityType}
                    onClick={this.onChangeData(i)}
                    onDelete={this.onDelete(i)}
                />);

        }
        return (<div >
            <div style={{ marginTop: '5px' }} />
            <FormLabel>{name}:</FormLabel>
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={() => this.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div >
        )
    }

    static getDerivedStateFromProps(props, state){
        const hasNewArray = JSON.stringify(state.array) !== JSON.stringify(props.array) 
        const hasNewName = state.name !== props.name 
        if (hasNewArray || hasNewName){
            const array = props.array ? props.array : [];
            const name = props.name;
            return { array:array, name:name };
        }else{
            return null
        }
    }
}

const styles = {
    div: {
        margin: 10
    }
};

EditActivityArrayView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditActivityArrayView);
