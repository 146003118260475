import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    CircularProgress, IconButton, Typography, Badge, TextField, Accordion, AccordionSummary, AccordionDetails, Box,
    Tooltip, FormControl, InputLabel, Snackbar, LinearProgress, FormControlLabel, Checkbox
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { StylesProvider } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    saveModels,
    deleteModels,
    deleteDetectModels,
    deleteRecommendationModel,
    isArrayValid,
    saveRecommendtionModel,
    saveDetectModels,
    deleteDataType
} from '@apricityhealth/web-common-lib/utils/Services';
import { isAdminUser } from '../utils/Utils';

import BackIcon from '@material-ui/icons/ArrowBack';
import ResetQuestion from '@material-ui/icons/RestorePage';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircle from '@material-ui/icons/CheckCircle';
import PrintIcon from '@material-ui/icons/Print';
import CodeIcon from '@material-ui/icons/Code';
import ReactSelect, { components } from "react-select";

import QuestionComponent from "./QuestionComponent";
import SymptomRecommendComponent from "./SymptomRecommendComponent"
import SymptomDetectComponent from "./SymptomDetectComponent"
import JsonDialog from '../dialogs/JsonDialog';

import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import { updateModelDataType } from '../utils/Models';
import { parseCode } from '@apricityhealth/web-common-lib/utils/ParseCode';
import { buildCode } from '@apricityhealth/web-common-lib/utils/BuildCode';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';
import { createIdFromText } from "../utils/CreateIdFromText";
import QuestionUtils from '../utils/QuestionUtils';
import { ModelDeleteDialog } from './SurveyModelView';
import PubSub from 'pubsub-js'
import EditableNote from '../components/EditableNote'
import 'antd/dist/antd.css';
import '../styles/tableedit.css';

const TYPE_TEMPLATES = require('../data/TypeTemplates.json');
const { Placeholder } = components;

export class SymptomModelView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            planId: props.appContext.state.plan ? props.appContext.state.plan.planId : null,
            language: 'en-us', //fix to english for now
            error: null,
            modelWarnings: null,
            modelId: this.props.location && this.props.location.state && this.props.location.state.modelId ? this.props.location.state.modelId : null,
            dialog: null,
            allQuestions: [], //these are all the questions from the backend. 
            allModels: [], //all the symptom models from the back end.
            allDataTypes: [], //all the symptom data types from the back end. 
            allAlerts: null,
            blueprint: {
                model: null,
                originalModel: null,
                modelDataType: null,
                originalSymptomDataType: null,
                symptomDataType: null,
                originalChiefComplaintDataType: null,
                chiefComplaintDataType: null,
                question: null,
                originalQuestion: null,
                originalModelDataType: null,
                deletedQuestions: [],
                detectModel: null,
                originalDetectModel: null,
                recommendModel: null,
                originalRecommendModel: null,
                preserveChiefComplaint: false,
                preserveSymptom: false,
            },
            showUpdateProgress: false,
            updateProgress: [],
            validated: false,
            editRecommendations: false,
            modelOverride: false,
            showAnswers: false,
            editingKey: null,
            hasDuplicateName: false,
            allModelNames: {},
            done: false,
            wideDrawer: false,
            drawerOpen: true,
            symtomAccordianExpand: true,
            questionAccordianExpand: true,
            detectAccordianExpand: true,
            recommendAccordianExpand: true,
            triageAccordianExpand: true,

            selectedSymptom: {},
            selectedChiefComplaint: {}
        };
    }

    componentDidMount() {
        this.loadContent();
        this.planToken = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                //go back to the main models page
                this.setState({ dialog: <Redirect to={{ pathname: "/symptom_models_unified" }} /> });
            }
        });

        this.dataToken = PubSub.subscribe('STORE_UPDATE', () => {
            this.updateTypes();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.planToken);
        PubSub.unsubscribe(this.dataToken);
    }

    updateTypes() {
        const { appContext } = this.props;
        const { plan } = appContext.state;
        if (plan) {
            this.setState({ error: null });
            try {
                const store = appContext.stores.DataTypesStore;

                //may need to do something when dependancies is enabled/..

                let allModels = store.getModels();
                let allQuestions = store.getQuestions();
                let allTexts = store.getTexts();
                let allDataTypes = store.getDataTypes();

                let allSymptoms = allDataTypes.filter(e => e.category === 'symptom');
                let allChiefComplaints = allDataTypes.filter(e => e.category === 'chiefComplaint');
                let allRecommendationModels = store.getRecommendModels();
                let allRecommendations = store.getRecommendationTypes();
                let allAlerts = store.getAlertLevels();
                let allDetectModels = store.getDetectModels();
                let texts = store.getTextHash();


                let chiefComplaintItems = [{ value: 'newChiefComplaint', label: "<New Complaint>" }];
                allChiefComplaints.forEach(dataType => {
                    let label = dataType.name ? `${dataType.name} (${dataType.dataId})` : `${dataType.modelId} (${dataType.dataId})`;
                    let item = { value: dataType.dataId, label };
                    chiefComplaintItems.push(item);
                });

                chiefComplaintItems.sort((a, b) => {
                    if (a.label < b.label)
                        return -1;
                    else if (a.label > b.label)
                        return 1;
                    return 0;
                });

                let symptomItems = [{ value: 'newSymptom', label: "<New Data Id>" }];
                allSymptoms.forEach(dataType => {
                    let label = dataType.name ? `${dataType.name} (${dataType.dataId})` : `${dataType.modelId} (${dataType.dataId})`;
                    let item = { value: dataType.dataId, label };
                    symptomItems.push(item);
                });

                symptomItems.sort((a, b) => {
                    if (a.label < b.label)
                        return -1;
                    else if (a.label > b.label)
                        return 1;
                    return 0;
                });

                let allModelNames = {};
                allModels.forEach(type => {
                    let name = type.name.toLowerCase();
                    if (!allModelNames[name]) allModelNames[name] = [];
                    allModelNames[name].push(type.dataId);
                });

                this.setState({
                    allQuestions, allDetectModels, allSymptoms, symptomItems, allModels, allAlerts, allRecommendationModels, allRecommendations,
                    texts, allTexts, allDataTypes, allChiefComplaints, chiefComplaintItems, allModelNames
                });
            } catch (err) {
                console.log(err)
                this.setState({ models: [], error: getErrorMessage(err) })
            };

        }
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;
        const { plan } = appContext.state;

        if (plan) {
            this.setState({ blueprint: {}, error: null });
            try {
                const store = appContext.stores.DataTypesStore;
                this.questionUtils = new QuestionUtils(appContext);
                // if (!dependencies)
                // models = models;

                let allModels = store.getModels();;
                let allQuestions = store.getQuestions();;
                let allTexts = store.getTexts();;
                let allDataTypes = store.getDataTypes();;

                let allSymptoms = allDataTypes.filter(e => e.category === 'symptom');;
                let allChiefComplaints = allDataTypes.filter(e => e.category === 'chiefComplaint');;
                let allRecommendationModels = store.getRecommendModels();;
                let allRecommendations = store.getRecommendationTypes();;
                let allAlerts = store.getAlertLevels();
                let allDetectModels = store.getDetectModels();;
                let texts = store.getTextHash();


                let chiefComplaintItems = [{ value: 'newChiefComplaint', label: "<New Complaint>" }];
                allChiefComplaints.forEach(dataType => {
                    let label = dataType.name ? `${dataType.name} (${dataType.dataId})` : `${dataType.modelId} (${dataType.dataId})`;
                    let item = { value: dataType.dataId, label };
                    chiefComplaintItems.push(item);
                });

                let symptomItems = [{ value: 'newSymptom', label: "<New Symptom>" }];
                allSymptoms.forEach(dataType => {
                    let label = dataType.name ? `${dataType.name} (${dataType.dataId})` : `${dataType.modelId} (${dataType.dataId})`;
                    let item = { value: dataType.dataId, label };
                    symptomItems.push(item);
                });

                let allModelNames = {};
                allModels.forEach(type => {
                    let name = type.name.toLowerCase();
                    if (!allModelNames[name]) allModelNames[name] = [];
                    allModelNames[name].push(type.dataId);
                });

                self.setState({
                    allQuestions, allDetectModels, allSymptoms, symptomItems, allModels, allAlerts, allRecommendationModels, allRecommendations,
                    texts, allTexts, allDataTypes, allChiefComplaints, chiefComplaintItems, allModelNames
                }, () => self.initializeBlueprint());
            } catch (err) {
                console.log(err)
                self.setState({ models: [], error: getErrorMessage(err) })
            };

        }
    }

    initializeBlueprint() {
        //this should only be done on initial model load, since questions can be added and not complete;
        let { allQuestions, allModels, planId, allDataTypes,
            blueprint, modelId, selectedChiefComplaint, chiefComplaintItems,
            isNewChiefComplaint, isNewSymptom, selectedSymptom, symptomItems,
            updateProgress } = this.state;

        blueprint = {
            model: null,
            originalSymptomDataType: null,
            symptomDataType: null,
            originalChiefComplaintDataType: null,
            chiefComplaintDataType: null,
            originalModel: null,
            modelDataType: null,
            originalModelDataType: null,
            question: null,
            originalQuestion: null,
            deletedQuestions: [],
            detectModel: null,
            originalDetectModel: null,
            recommendModel: null,
            originalRecommendModel: null,
            preserveChiefComplaint: false,
            preserveSymptom: false,
        };

        let model = null;
        if (modelId) {
            //try to find the model
            model = allModels.find(model => model.modelId === modelId);
            if (model && model.modelId) {
                blueprint.originalModel = JSON.parse(JSON.stringify(model))// copy of the original to update any changes
                blueprint.model = JSON.parse(JSON.stringify(model));
            }
        }

        if (!model) { // no model found.. make a new one
            model = JSON.parse(JSON.stringify(TYPE_TEMPLATES.proModels["symptomProModel"]));
            model.modelId = "newSymptomModel";
            modelId = model.modelId;
            let triggerSymptom = JSON.parse(JSON.stringify(TYPE_TEMPLATES.typeDefaults["triggerSymptom"]));
            model.triggerSymptoms[0] = triggerSymptom;
            let primarySymptom = JSON.parse(JSON.stringify(TYPE_TEMPLATES.typeDefaults["primarySymptom"]));
            model.primarySymptoms[0] = primarySymptom;
            blueprint.model = model;
        }

        let chiefComplaintDataType = null;
        if (isArrayValid(model.triggerSymptoms)) {
            //so for a symptom we only have one primary symptom so we are just gonna deal with the one. 
            let symptom = model.triggerSymptoms[0];
            chiefComplaintDataType = allDataTypes.find((type) => { return type.dataId === symptom.dataId });
            if (chiefComplaintDataType) {
                isNewChiefComplaint = false;
                blueprint.originalChiefComplaintDataType = JSON.parse(JSON.stringify(chiefComplaintDataType));
                blueprint.chiefComplaintDataType = chiefComplaintDataType;
            }
        }
        if (!chiefComplaintDataType) {
            chiefComplaintDataType = {
                name: "",
                description: "",
                planId: planId,
                override: true,
                baseline: false,
                kind: 'symptom',
                category: 'chiefComplaint',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            isNewChiefComplaint = true;
            blueprint.chiefComplaintDataType = chiefComplaintDataType;
        }

        selectedChiefComplaint = chiefComplaintItems.find(symptom => symptom.value === chiefComplaintDataType.dataId);
        if (!selectedChiefComplaint) {
            selectedChiefComplaint = chiefComplaintItems[0];
        }

        let symptomDataId = null;
        let symptomDataType = null;
        if (isArrayValid(model.primarySymptoms)) {
            //so for a symptom we only have one primary symptom so we are just gonna deal with the one. 
            let symptom = model.primarySymptoms[0];
            symptomDataId = symptom.dataId;
            symptomDataType = allDataTypes.find((type) => type.dataId === symptom.dataId);
            if (symptomDataType) {
                isNewSymptom = false;
                blueprint.originalSymptomDataType = JSON.parse(JSON.stringify(symptomDataType));
                blueprint.symptomDataType = symptomDataType;
            }
        }
        if (!symptomDataType) {
            isNewSymptom = true;
            symptomDataType = {
                name: "",
                description: "",
                planId: planId,
                category: 'symptom',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            blueprint.symptomDataType = symptomDataType;
        }
        selectedSymptom = symptomItems.find(symptom => symptom.value === symptomDataType.dataId);

        if (!selectedSymptom) {
            selectedSymptom = symptomItems[0];
        }

        let modelDataType = allDataTypes.find((type) => type.dataId === model.dataId);
        if (modelDataType && modelDataType.dataId) {
            blueprint.originalModelDataType = JSON.parse(JSON.stringify(modelDataType));
            blueprint.modelDataType = JSON.parse(JSON.stringify(modelDataType));
        } else {
            modelDataType = {
                planId: planId,
                category: 'model',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            blueprint.modelDataType = modelDataType;
        }

        let question = null;
        if (symptomDataId && symptomDataId !== undefined && symptomDataId !== "")
            question = allQuestions.find((question) => { return (question.dataId === symptomDataId) });
        if (question && question.questionId) {
            if (!question.name)
                question.name = "";
            //only clone the original if it already exists
            blueprint.originalQuestion = JSON.parse(JSON.stringify(question));
            // need to add the recommendations here once I figure out how
            this.questionUtils.unlocalizeQuestion(question);
            blueprint.question = JSON.parse(JSON.stringify(question));
        } else {
            //no question yet so lets make an empty one
            question = JSON.parse(JSON.stringify(TYPE_TEMPLATES.questionModels["symptomSeverityQuestion"]));
            question.questionId = symptomDataId;
            question.name = symptomDataType.name;
            question.dataId = symptomDataId;
            question.planId = planId;
            blueprint.question = question;
        }

        updateProgress = [];
        this.setState({
            modelId, progress: null, blueprint, selectedChiefComplaint, validated: false,
            updateProgress, showUpdateProgress: false, isNewSymptom, isNewChiefComplaint,
            selectedSymptom
        });
    }

    onChiefComplaintChanged(selectedChiefComplaint) {
        let { allChiefComplaints, blueprint, planId } = this.state;
        let dataId = selectedChiefComplaint.value; //value is from the select
        let chiefComplaintDataType = null;
        if (dataId === 'newChiefComplaint') { // make a new chief Complaint 
            chiefComplaintDataType = {
                name: "",
                description: "",
                planId: planId,
                override: true,
                baseline: false,
                kind: 'symptom',
                category: 'chiefComplaint',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            blueprint.originalChiefComplaintDataType = null;
            blueprint.chiefComplaintDataType = chiefComplaintDataType;
        } else {
            chiefComplaintDataType = allChiefComplaints.find((type) => type.dataId === dataId);
            if (chiefComplaintDataType && chiefComplaintDataType.dataId) {
                blueprint.chiefComplaintDataType = chiefComplaintDataType;
            } else {
                this.setState({ error: "Chief Complaint not found" });
                return;
            }

        }

        this.setState({ error: null, blueprint, selectedChiefComplaint, validated: false });
    }
    onSymptomChanged(selectedSymptom) {
        let { allSymptoms, blueprint, planId, allQuestions } = this.state;

        let dataId = selectedSymptom.value;

        let symptomDataType = null;
        if (dataId === 'newSymptom') { // make a new chief Complaint 
            symptomDataType = {
                dataId: "newSymptom",
                name: "",
                description: "",
                planId: planId,
                category: 'symptom',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            blueprint.originalSymptomDataType = null;
            blueprint.symptomDataType = symptomDataType;
        } else {
            symptomDataType = allSymptoms.find((type) => type.dataId === dataId);
            if (symptomDataType && symptomDataType.dataId) {
                blueprint.symptomDataType = symptomDataType;

            } else {
                this.setState({ error: "Symptom Data Type not found" });
                return;
            }

        }

        let question = allQuestions.find((question) => question.dataId === symptomDataType.dataId);
        if (question && question.questionId) {
            //only clone the original if it already exists
            // blueprint.originalQuestion = JSON.parse(JSON.stringify(question));
            if (symptomDataType.name) {
                if (!question.name)
                    question.name = symptomDataType.name;
            }
            this.questionUtils.unlocalizeQuestion(question);
            blueprint.question = JSON.parse(JSON.stringify(question));

        } else {
            //no question yet so lets make an empty one
            question = JSON.parse(JSON.stringify(TYPE_TEMPLATES.questionModels["symptomSeverityQuestion"]));
            question.questionId = symptomDataType.dataId;
            question.name = symptomDataType.name;
            question.dataId = symptomDataType.dataId;
            question.planId = planId;
            blueprint.question = question;
        }

        this.setState({ error: null, blueprint, selectedSymptom, validated: false });
    }

    onCloseView() {
        this.setState({ dialog: <Redirect to="/symptom_models_unified" /> })
    }

    confirmDeleteAll() {
        const self = this;
        let { confirmDeleteSymptom, confirmDeleteChiefComplaint, confirmDeleteDataTypes, confirmDeleteModel, confirmDeleteQuestions } = this.state;
        self.setState({
            dialog: <ModelDeleteDialog
                confirmDeleteSymptom={confirmDeleteSymptom}
                confirmDeleteChiefComplaint={confirmDeleteChiefComplaint}
                confirmDeleteDataTypes={confirmDeleteDataTypes}
                confirmDeleteModel={confirmDeleteModel}
                confirmDeleteQuestions={confirmDeleteQuestions}
                onCancel={() => this.setState({ dialog: null })}
                onDone={(data) => this.setState({ dialog: null, ...data }, () => this.deleteAll())}
            />

        });
    }


    deleteDataTypes() {
        const { appContext } = this.props;
        let { blueprint, confirmDeleteSymptom, confirmDeleteChiefComplaint } = this.state;
        let promises = [];


        let originalModelDataType = blueprint.originalModelDataType;
        let originalSymptomDataType = blueprint.originalSymptomDataType;

        //delete the model data type
        if (originalModelDataType && originalModelDataType.dataId) {
            promises.push(deleteDataType(appContext, originalModelDataType));
        }
        //delete the symptom data type
        if (Boolean(confirmDeleteSymptom) && originalSymptomDataType && originalSymptomDataType.dataId) {
            promises.push(deleteDataType(appContext, originalSymptomDataType));
        }

        //delete the chief complaint data type
        let originalChiefComplaintDataType = blueprint.originalChiefComplaintDataType;
        if (Boolean(confirmDeleteChiefComplaint) && originalChiefComplaintDataType && originalChiefComplaintDataType.dataId) {
            promises.push(deleteDataType(appContext, blueprint.originalChiefComplaintDataType));
        }

        let originalAlertDataType = blueprint.originalAlertDataType;
        if (originalAlertDataType && originalAlertDataType.dataId) {
            promises.push(deleteDataType(appContext, originalAlertDataType));
        }

        let originalGradeDataType = blueprint.originalGradeDataType;
        if (originalGradeDataType && originalGradeDataType.dataId) {
            promises.push(deleteDataType(appContext, originalGradeDataType));
        }

        let originalScoreDataType = blueprint.originalScoreDataType;
        if (originalScoreDataType && originalScoreDataType.dataId) {
            promises.push(deleteDataType(appContext, originalScoreDataType));
        }

        let originalPriorityDataType = blueprint.originalPriorityDataType;
        if (originalPriorityDataType && originalPriorityDataType.dataId) {
            promises.push(deleteDataType(appContext, originalPriorityDataType));
        }

        if (promises.length > 0) {
            this.updateProgress(`Deleting ${promises.length} data types...`)
        }
        return Promise.all(promises);

    }


    saveAll() {
        let { appContext } = this.props;
        this.setState({ progress: <CircularProgress size={20} />, showUpdateProgress: true, error: null });
        const sequential = require('promise-sequential');
        let promises = [];
        // promises.push(() => this.deleteDeletedQuestions());
        promises.push(() => this.saveDataTypes());
        promises.push(() => this.saveQuestions());
        promises.push(() => this.saveModel());
        promises.push(() => this.saveDetectModel());
        promises.push(() => this.saveRecommendModel());
        sequential(promises).then(() => {
            appContext.stores.DataTypesStore.loadDataTypes();
            this.updateProgress("Closing view...");
            this.onCloseView();
        }).catch((err) => {
            console.log(`error `, getErrorMessage(err))
            this.setState({ progress: null, error: getErrorMessage(err), showUpdateProgress: false });
        })
    }


    saveDataTypes() {
        const { appContext } = this.props;
        let { blueprint } = this.state;
        let preserveDetect = blueprint.preserveDetect;
        let cloneDetect = blueprint.cloneDetect;
        let originalDetectModel = blueprint.originalDetectModel;
        let preserveChiefComplaint = blueprint.preserveChiefComplaint;
        let preserveSymptom = blueprint.preserveSymptom;
        let promises = [];
        let modelDataType = blueprint.modelDataType;
        let originalModelDataType = blueprint.originalModelDataType;

        //delete the original data type if it changed
        if (modelDataType && modelDataType.dataId) {
            if (originalModelDataType && modelDataType.dataId !== originalModelDataType.dataId) {
                this.updateProgress("Deleting data type...");
                promises.push(deleteDataType(appContext, originalModelDataType));
            }
            this.updateProgress("Saving data type...");
            promises.push(this.saveDataType(modelDataType, originalModelDataType === null));
        }

        if (!Boolean(preserveSymptom)) {
            let symptomDataType = blueprint.symptomDataType;
            let originalSymptomDataType = blueprint.originalSymptomDataType;
            if (symptomDataType && symptomDataType.dataId) {
                //delete the original data type if it changed
                if (originalSymptomDataType && symptomDataType.dataId !== originalSymptomDataType.dataId) {
                    this.updateProgress("Deleting data type...");
                    promises.push(deleteDataType(appContext, originalSymptomDataType));
                }
                this.updateProgress("Saving data type...");
                promises.push(this.saveDataType(symptomDataType, originalSymptomDataType === null));
            }
        }

        if (!Boolean(preserveChiefComplaint)) {
            let chiefComplaintDataType = blueprint.chiefComplaintDataType;
            let originalChiefComplaintDataType = blueprint.originalChiefComplaintDataType;
            if (chiefComplaintDataType && chiefComplaintDataType.dataId) {
                //delete the original data type if it changed
                if (originalChiefComplaintDataType && chiefComplaintDataType.dataId !== originalChiefComplaintDataType.dataId) {
                    this.updateProgress("Deleting data type...");
                    promises.push(deleteDataType(appContext, originalChiefComplaintDataType));
                }
                this.updateProgress("Saving data type...");
                promises.push(this.saveDataType(chiefComplaintDataType, originalChiefComplaintDataType === null));
            }
        }

        let alertDataType = blueprint.alertDataType;

        if (alertDataType && alertDataType.dataId) {
            let originalAlertDataType = blueprint.originalAlertDataType;
            if (originalAlertDataType && !preserveDetect) {
                this.updateProgress("Deleting data type...");
                promises.push(deleteDataType(appContext, originalAlertDataType));
            }
            if (cloneDetect || !originalDetectModel) {
                this.updateProgress("Saving data type...");
                promises.push(this.saveDataType(alertDataType, originalAlertDataType === null));
            }
        }

        let gradeDataType = blueprint.gradeDataType;
        if (gradeDataType && gradeDataType.dataId) {
            let originalGradeDataType = blueprint.originalGradeDataType;
            if (originalGradeDataType && !preserveDetect) {
                this.updateProgress("Deleting data type...");
                promises.push(deleteDataType(appContext, originalGradeDataType));
            }
            if (cloneDetect || !originalDetectModel) {
                this.updateProgress("Saving data type...");
                promises.push(this.saveDataType(gradeDataType, originalGradeDataType === null));
            }
        }

        let scoreDataType = blueprint.scoreDataType;

        if (scoreDataType && scoreDataType.dataId) {
            let originalScoreDataType = blueprint.originalScoreDataType;
            if (originalScoreDataType && !preserveDetect) {
                this.updateProgress("Deleting data type...");
                promises.push(deleteDataType(appContext, originalScoreDataType));
            }
            if (cloneDetect || !originalDetectModel) {
                this.updateProgress("Saving data type...");
                promises.push(this.saveDataType(scoreDataType, originalScoreDataType === null));
            }
        }

        let priorityDataType = blueprint.priorityDataType;

        if (priorityDataType && priorityDataType.dataId) {
            let originalPriorityDataType = blueprint.originalPriorityDataType;
            if (originalPriorityDataType && !preserveDetect) {
                this.updateProgress("Deleting data type...");
                promises.push(deleteDataType(appContext, originalPriorityDataType));
            }
            if (cloneDetect || !originalDetectModel) {
                this.updateProgress("Saving data type...");
                promises.push(this.saveDataType(priorityDataType, originalPriorityDataType === null));
            }
        }

        return Promise.all(promises);
    }

    saveDataType(dataType, isNew = false) {
        const { appContext } = this.props;
        let planId = appContext && appContext.state && appContext.state.plan && appContext.state.plan.planId;
        this.updateProgress("Update tuple descriptions...")
        if (toBoolean(isNew))
            this.updateTupleDescriptions(dataType);
        const linkStore = appContext.stores.DataTypesStore;

        if (isArrayValid(dataType.tupleDescriptions)) {
            let tupleUpdates = [];
            for (let i = 0; i < dataType.tupleDescriptions.length; ++i) {
                let tupleDesc = dataType.tupleDescriptions[i];
                if (tupleDesc.oldIndex !== tupleDesc.index) {
                    tupleUpdates.push({ oldIndex: tupleDesc.oldIndex, newIndex: tupleDesc.index });
                }
            }

            if (tupleUpdates.length > 0) {
                try {
                    this.updateProgress("Updating tuple indexes...");
                    linkStore.updateTupleIndexes(dataType.dataId, tupleUpdates);
                } catch (err) {
                    throw err;
                }
            }
        }
        this.updateProgress(`Saving data type ${dataType.dataId}...`);
        return linkStore.saveDataType(dataType, planId);
    }

    updateTupleDescriptions(dataType) {
        let tupleDescriptions = require('../data/DataTypeTupleDescriptionTemplates.json');
        let tupleDescriptionItem = tupleDescriptions.tupleDescriptionItems.find((tupleDescriptionItem) => {
            return (tupleDescriptionItem.id === dataType.category)
        })

        if (tupleDescriptionItem) {
            tupleDescriptionItem = JSON.stringify(tupleDescriptionItem)
            tupleDescriptionItem = tupleDescriptionItem.replace(/{{name}}/g, dataType.name)
            tupleDescriptionItem = JSON.parse(tupleDescriptionItem)
            dataType.tupleDescriptions = tupleDescriptionItem.tupleDescriptions
        }
    }

    saveQuestions() {
        const { blueprint } = this.state;
        let promises = []
        let question = blueprint.question;
        let originalQuestion = blueprint.originalQuestion;


        if (question) {
            //add any specific info for surveys
            question.category = 'symptom';
            if (!originalQuestion)
                originalQuestion = JSON.parse(JSON.stringify(question))

            promises.push(this.questionUtils.saveQuestion(JSON.parse(JSON.stringify(question)), originalQuestion));
        }
        this.updateProgress(`Saving ${promises.length} questions...`);
        return Promise.all(promises);
    }


    saveDetectModel() {
        const { appContext } = this.props;
        let { blueprint } = this.state;
        let preserveDetect = blueprint.preserveDetect;
        let cloneDetect = blueprint.cloneDetect;
        let detectModel = blueprint.detectModel;
        if (!detectModel) return Promise.resolve();
        detectModel.category = "symptom";
        let originalDetectModel = blueprint.originalDetectModel;

        let deleteModel = Promise.resolve();

        if (originalDetectModel && !preserveDetect) {
            this.updateProgress("Deleting detect model...");
            deleteModel = deleteDetectModels(appContext, originalDetectModel.modelId);
        }

        return deleteModel.then(() => {

            this.updateProgress(`Saving detect model...`);
            if (detectModel && (cloneDetect || !originalDetectModel))
                return saveDetectModels(appContext, detectModel);
            else
                return Promise.resolve();
        })
    }

    saveRecommendModel() {
        const { appContext } = this.props;
        let { blueprint } = this.state;
        let recommendModel = blueprint.recommendModel;
        if (!recommendModel) return Promise.resolve();

        let cloneRecommend = blueprint.cloneRecommend;
        let preserveRecommend = blueprint.preserveRecommend;

        let originalRecommendModel = blueprint.originalRecommendModel;

        let deleteModel = Promise.resolve();

        if (originalRecommendModel && !preserveRecommend) {
            this.updateProgress("Deleting recommend model...");
            deleteModel = deleteRecommendationModel(appContext, originalRecommendModel);

        }

        return deleteModel.then(() => {
            this.updateProgress(`Saving recommend model...`);
            if (recommendModel && cloneRecommend)
                return saveRecommendtionModel(appContext, recommendModel);
            else
                return Promise.resolve();
        })
    }

    saveModel() {
        const { appContext } = this.props;
        let { blueprint } = this.state;
        let model = blueprint.model;
        let originalModel = blueprint.originalModel;
        model.category = "symptom";
        let deleteModel = Promise.resolve();
        //only delete if it changed.. save some processing and potential errors
        if (originalModel && originalModel.modelId) {
            if (model.modelId !== originalModel.modelId) {
                this.updateProgress("Deleting model...");
                deleteModel = this.deleteModel();
            }
        }
        return deleteModel.then(() => {
            this.updateProgress("Updating model data types...");
            return updateModelDataType(appContext, model);
        }).then(() => {
            this.updateProgress("Saving model...");
            return saveModels(appContext, model);
        })
    }



    deleteAll() {
        let { confirmDeleteDataTypes, confirmDeleteQuestions, confirmDeleteModel } = this.state;

        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        let promises = [];
        if (confirmDeleteDataTypes) {
            promises.push(() => this.deleteDataTypes());
        }

        if (confirmDeleteQuestions) {
            promises.push(() => this.deleteQuestions());
        }

        if (confirmDeleteModel) {
            promises.push(() => this.deleteModel());
            promises.push(() => this.deleteDetectModel());
            promises.push(() => this.deleteRecommendModel());
        }

        const sequential = require('promise-sequential');
        sequential(promises).then(() => {
            let { appContext } = this.props;
            appContext.stores.DataTypesStore.loadDataTypes();
            this.setState({ progress: null }, () => this.onCloseView());
        }).catch((err) => {
            console.log(`Error deleting all `, getErrorMessage(err));
            this.setState({ progress: null, error: getErrorMessage(err) });
        })

    }

    deleteModel() {
        const { appContext } = this.props;
        let { blueprint } = this.state;
        if (blueprint && blueprint.originalModel) {
            let model = blueprint.originalModel
            this.updateProgress("Deleting model...");
            return deleteModels(appContext, model);
        } else {
            return Promise.resolve();
        }
    }

    deleteDetectModel() {
        const { appContext } = this.props;
        let { blueprint } = this.state;
        if (blueprint && blueprint.originalDetectModel) {
            let model = blueprint.originalDetectModel
            this.updateProgress("Deleting detect model...");
            return deleteDetectModels(appContext, model);
        } else {
            return Promise.resolve();
        }
    }

    deleteRecommendModel() {
        const { appContext } = this.props;
        let { blueprint } = this.state;
        if (blueprint && blueprint.originalRecommendModel) {
            let model = blueprint.originalRecommendModel
            this.updateProgress("Deleting recommend model...");
            return deleteRecommendationModel(appContext, model);
        } else {
            return Promise.resolve();
        }
    }

    deleteQuestions() {
        let { blueprint } = this.state;
        let promises = [];
        let originalQuestion = blueprint.originalQuestion;
        if (originalQuestion && originalQuestion.questionId) {
            promises.push(this.questionUtils.deleteQuestion(originalQuestion));
        }

        if (promises.length > 0) {
            this.updateProgress(`Deleting ${promises.length} questions...`)
        }
        return Promise.all(promises);
    }

    onShowCode() {
        let { blueprint } = this.state;
        this.setState({
            dialog: <JsonDialog
                dataType={blueprint}
                name={blueprint.symptomDataType.name}
                collapsed={1}
                onEditDone={(blueprint) => {
                    this.setState({blueprint, dialog: null});
                }}
                onDone={() => this.setState({ dialog: null })} />
        });
    }

    onToggleDrawer() {
        let { drawerOpen } = this.state;
        this.setState({ drawerOpen: !drawerOpen });
    }



    updateSymptomName(name) {
        let { blueprint, modelId, allModelNames } = this.state;
        if (blueprint.model.name !== name) {
            blueprint.model.name = name;
            blueprint.modelDataType.name = name;
            let dups = allModelNames[name.toLowerCase()]
            let hasDuplicateName = isArrayValid(dups) && dups[0] !== blueprint.symptomDataType.dataId;
            this.setState({ blueprint, modelId, validated: false, hasDuplicateName });
        }
    }

    updateSymptomDescription(description) {
        let { blueprint } = this.state;
        blueprint.symptomDataType.description = description;
        blueprint.modelDataType.description = description;
        blueprint.model.description = description;
        this.setState({ blueprint, validated: false });
    }

    updateDetectModel(updates) {
        let { blueprint } = this.state;
        let validated = blueprint.detectModel && updates.detectModel && blueprint.detectModel.modelId !== updates.detectModel.modelId;
        blueprint = { ...blueprint, ...updates };


        //we do the priority so it can load the recommend..

        if (blueprint.model && blueprint.model.name && blueprint.model !== "") {
            let name = blueprint.model.name;
            let newId = createIdFromText(name);
            let priorityDataType = blueprint.priorityDataType;
            if (priorityDataType) {
                priorityDataType.dataId = newId + "Priority";
            }
        }

        this.setState({ blueprint, validated });
    }

    resetQuestion() {
        let { blueprint, planId } = this.state;
        let symptomDataType = blueprint.symptomDataType;
        let name = blueprint.question.name;
        let question = JSON.parse(JSON.stringify(TYPE_TEMPLATES.questionModels["symptomSeverityQuestion"]));
        question.questionId = symptomDataType.dataId;
        question.name = name;
        question.dataId = symptomDataType.dataId;
        question.planId = planId;
        blueprint.question = question;
        this.setState({ blueprint, validated: false });
    }

    print() {
        let { blueprint } = this.state;
        this.setState({ dialog: <Redirect to={{ pathname: `/print/model`, state: { modelId: blueprint.model.modelId } }} push={true} /> })
    }

    onDetectModelChanged() {

        let { blueprint, modelOverride, allDataTypes } = this.state;
        if (!modelOverride) {
            let detectModel = blueprint.detectModel;
            if (detectModel && (detectModel.modelId !== "newDetectModel")) {
                let modelDataType = allDataTypes.find((type) => type.dataId === detectModel.modelDataId);
                if (modelDataType) {
                    blueprint.modelDataType = modelDataType;
                    blueprint.model.modelDataId = detectModel.modelId;
                    this.setState({ blueprint });
                } else {
                    this.setState({ error: `Warning: Model data type not found for selected detect model ${detectModel.modelDataId}` })
                }
            }
        }
    }



    validateModel() {
        let { blueprint, allChiefComplaints } = this.state;
        let model = blueprint.model;

        if (!model.name || model.name === "") {
            this.setState({ error: "Name is a required field" });
            return;
        }
        let name = model.name;
        let newSymptomIdFromName = createIdFromText(name);
        let newModelIdFromName = newSymptomIdFromName + "Model";


        let modelDataType = blueprint.modelDataType;
        let question = blueprint.question;
        if (!question.name) {
            question.name = name + " Severity"
        }

        let symptomDataType = blueprint.symptomDataType;
        let preserveSymptom = blueprint.preserveSymptom;
        if (!Boolean(preserveSymptom)) {
            symptomDataType.name = question.name;
            symptomDataType.description = question.name;
            symptomDataType.dataId = createIdFromText(question.name);
        }

        question.dataId = symptomDataType.dataId;

        let chiefComplaintDataType = blueprint.chiefComplaintDataType;
        //reset the cheif complaint in case it was changed and someone pressed preserve and validate again..
        let existingChiefComplaintDataType = allChiefComplaints.find((type) => type.dataId === chiefComplaintDataType.dataId);
        if (existingChiefComplaintDataType)
            blueprint.chiefComplaintDataType = existingChiefComplaintDataType;

        let preserveChiefComplaint = blueprint.preserveChiefComplaint;
        if (!Boolean(preserveChiefComplaint)) {
            chiefComplaintDataType.name = name + " Complaint";
            chiefComplaintDataType.dataId = newSymptomIdFromName + "Complaint";
        }
        model.modelId = newModelIdFromName;
        model.dataId = newModelIdFromName;
        model.modelDataId = newModelIdFromName;

        // if (!Boolean(preserveChiefComplaint)) {
        model.triggerSymptoms = [];
        let triggerSymptom = JSON.parse(JSON.stringify(TYPE_TEMPLATES.typeDefaults["triggerSymptom"]));
        triggerSymptom.dataId = chiefComplaintDataType.dataId;
        // triggerSymptom.description = chiefComplaintDataType.name;
        model.triggerSymptoms[0] = triggerSymptom;
        // }

        if (!Boolean(preserveSymptom)) {
            model.primarySymptoms = [];
            let primarySymptom = JSON.parse(JSON.stringify(TYPE_TEMPLATES.typeDefaults["primarySymptom"]));
            primarySymptom.dataId = symptomDataType.dataId;
            // primarySymptom.description = symptomDataType.name;
            model.primarySymptoms[0] = primarySymptom;
        }
        let detectModel = blueprint.detectModel;
        if (!detectModel) { //null everything since no detect needed
            blueprint.alertDataType = null;
            blueprint.gradeDataType = null;
            blueprint.scoreDataType = null;
            blueprint.priorityDataType = null;
            blueprint.recommendModel = null;
            blueprint.modelDataType.dataId = newModelIdFromName;
            blueprint.modelDataType.name = name;

        } else {

            blueprint.modelDataType.dataId = newModelIdFromName;
            blueprint.modelDataType.name = name;
            blueprint.modelDataType.description = name + " Model Data Type";


            let alertDataType = blueprint.alertDataType;
            if (alertDataType) {
                alertDataType.name = name + " Alert";
                alertDataType.description = "Alert type for " + name;
                alertDataType.dataId = newSymptomIdFromName + "Alert";
                alertDataType.parentId = modelDataType.dataId;
            }

            let gradeDataType = blueprint.gradeDataType;
            if (gradeDataType) {
                gradeDataType.name = name + " Grade";
                gradeDataType.description = "Grade type for " + name;
                gradeDataType.dataId = newSymptomIdFromName + "Grade";
                gradeDataType.parentId = modelDataType.dataId;
            }

            let scoreDataType = blueprint.scoreDataType;
            if (scoreDataType) {
                scoreDataType.name = name + " Score";
                scoreDataType.description = "Score type for " + name;
                scoreDataType.dataId = newSymptomIdFromName + "Score";
                scoreDataType.parentId = modelDataType.dataId;
            }

            let priorityDataType = blueprint.priorityDataType;
            if (priorityDataType) {
                priorityDataType.name = name + " Priority";
                priorityDataType.description = "Priority type for " + name;

                priorityDataType.dataId = newSymptomIdFromName + "Priority";
                priorityDataType.parentId = modelDataType.dataId;
            }

            detectModel.name = name + " Detect Model";
            detectModel.category = "pro"; //sometimes they pick lab models.. 
            detectModel.modelId = newSymptomIdFromName + "DetectModel";
            detectModel.modelDataId = modelDataType.dataId;
            detectModel.description = "Detect model for " + name;
            detectModel.alertDataId = alertDataType.dataId;
            detectModel.gradeDataId = gradeDataType.dataId;
            detectModel.priorityDataId = priorityDataType.dataId;
            detectModel.scoreDataId = scoreDataType.dataId;

            let recommendModel = blueprint.recommendModel;
            if (recommendModel) {
                recommendModel.guideline = true;
                recommendModel.name = name + " Recommend Model";
                recommendModel.description = "Recommend model for " + name;
                recommendModel.modelId = newSymptomIdFromName + "RecommendModel";
                if (isArrayValid(recommendModel.groups)) {
                    recommendModel.groups.forEach(group => {
                        let funct = parseCode(group.dataConditions);
                        let isDataInRange = funct.functions[0];
                        if (isDataInRange.functionName === "isDataInRange") {
                            isDataInRange.args[1] = { string: priorityDataType.dataId, type: "'" };
                            group.dataConditions = buildCode(funct);
                            group.name = `Priority ${isDataInRange.args[3].string}-${isDataInRange.args[4].string}`
                        } else {
                            this.setState({ error: ` ${isDataInRange.functionName} not coded for yet in the recommend model. Tell Ray` })
                        }
                    });
                }
            }
        }

        this.setState({ modelId: newModelIdFromName, blueprint, validated: true });
    }


    updateProgress(progress) {
        let { updateProgress } = this.state;
        updateProgress.push(progress);
        this.setState({ updateProgress });
    }

    render() {
        let self = this;
        let { appContext } = this.props;

        let { progress, allAlerts, error, validated, allChiefComplaints,
            dialog, blueprint, planId, symtomAccordianExpand, init,
            questionAccordianExpand, detectAccordianExpand, recommendAccordianExpand,
            selectedChiefComplaint, chiefComplaintItems, showUpdateProgress, updateProgress,
            selectedSymptom, symptomItems, hasDuplicateName
        } = this.state;
        const store = appContext.stores.DataTypesStore;
        let model = blueprint.model;
        let question = blueprint.question;
        let modelDataId = blueprint.modelDataType ? blueprint.modelDataType.dataId : null;
        let priorityDataId = blueprint.priorityDataType ? blueprint.priorityDataType.dataId : null;
        let symptomName = blueprint.symptomDataType ? blueprint.symptomDataType.name : null;

        let loading = true
        if (isArrayValid(allChiefComplaints))
            loading = false

        let progressList = updateProgress.map((element, index) => { return <li key={index}>{element}</li> })

        const errors = appContext.stores.DataTypesStore.getErrors(`symptom_models`).errors;
        const errorCount = model ? errors[model.modelId] ? errors[model.modelId].length : 0 : 0;
        let errorList = model ? errors[model.modelId] ? errors[model.modelId].map((element, index) => { return <li key={index}>{element}</li> }) : [] : [];

        return <StylesProvider styles={{ margin: 10 }}>
            <Box display="block" displayPrint="none" m={1}>
                <div className="page">
                    {error}
                    <table width="99%">
                        <tbody>
                            <tr align='top'>
                                <td  >
                                    <Tooltip title={"Back"}><IconButton onClick={() => this.setState({ dialog: <Redirect to="/symptom_models_unified" /> })}><BackIcon /></IconButton></Tooltip>
                                </td>
                                <td align="right"  >
                                    <Tooltip title={'Print Symptom'}><IconButton disabled={progress !== null} onClick={() => this.print(model)}><PrintIcon /></IconButton></Tooltip>
                                    {model && model.modelId ? <EditableNote textId={`symptomModel.${model.modelId}`} planId={model.planId} /> : null}
                                    <Tooltip title={'Reload Symptom'}><IconButton disabled={store.progress !== null} onClick={() => store.loadDataTypes()}>{store.progress || <RefreshIcon />}</IconButton></Tooltip>
                                    <Tooltip title={'Delete Symptom'}><IconButton disabled={progress !== null} onClick={() => this.confirmDeleteAll()}><DeleteIcon /></IconButton></Tooltip>
                                    {isAdminUser(appContext) && <Tooltip title={'Show JSON'}><IconButton disabled={progress !== null} onClick={() => this.onShowCode()}><CodeIcon /></IconButton></Tooltip>}
                                    {validated ? <Tooltip title={'Save Symptom'}><IconButton disabled={progress !== null} onClick={() => { this.setState({ showUpdateProgress: true }, () => this.saveAll()); }}>{progress || <SaveIcon />}</IconButton></Tooltip> : <Tooltip title={'Validate Symptom'}><IconButton disabled={progress !== null} onClick={() => this.validateModel()}>{progress || <CheckCircle />}</IconButton></Tooltip>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <MuiAccordion square expanded={symtomAccordianExpand} onChange={() => this.setState({ symtomAccordianExpand: !symtomAccordianExpand })}>
                        <MuiAccordionSummary>
                            <HtmlTooltip title={errorList} interactive>
                                <Badge
                                    badgeContent={errorCount}
                                    color='error'
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <Typography className="section_title">Symptom</Typography>
                                </Badge>
                            </HtmlTooltip>
                        </MuiAccordionSummary>
                        <MuiAccordionDetails >

                            <table style={{ width: "100%", background: "white" }}>
                                <tbody>

                                    <tr >
                                        <td style={{ background: "white", width: "350px" }}>
                                            {model && <TextField
                                                required
                                                style={{ width: "100%" }}
                                                label="Symptom name:"
                                                error={Boolean(hasDuplicateName)}
                                                helperText={Boolean(hasDuplicateName) && "Duplicate exists"}
                                                value={blueprint.model.name}
                                                onChange={(e) => {
                                                    self.updateSymptomName(e.target.value);
                                                }}
                                            />}
                                        </td>
                                        <td style={{ background: "white", width: "500px" }}>
                                            {model && <TextField
                                                style={{ width: "100%", marginLeft: "15px", }}
                                                label="Symptom description:"
                                                value={blueprint.model.description}
                                                onChange={(e) => {
                                                    self.updateSymptomDescription(e.target.value);
                                                }}
                                            />}
                                        </td>

                                    </tr>
                                    <tr>
                                        {model && <td style={{ background: "white", width: "350px" }}>
                                            <FormControl style={{ marginTop: "15px", width: "100%" }}>
                                                <InputLabel>Select Trigger Data Id</InputLabel>
                                                <ReactSelect
                                                    isLoading={loading}
                                                    style={{ margin: "10px" }}
                                                    isSearchable
                                                    value={selectedChiefComplaint}
                                                    options={chiefComplaintItems}
                                                    placeholder={"Select Trigger Data Id"}
                                                    onChange={(e) => this.onChiefComplaintChanged(e)}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{
                                                        valueContainer: (provided, state) => ({
                                                            ...provided,
                                                            overflow: 'visible'
                                                        }),
                                                        container: (provided, state) => ({
                                                            ...provided,
                                                            marginTop: 20,
                                                        }),
                                                        placeholder: (provided, state) => ({
                                                            ...provided,
                                                            position: "absolute",
                                                            top: -15,
                                                            transition: "top 0.1s, font-size 0.1s",
                                                            fontSize: '0.75rem',
                                                            lineHeight: 1,
                                                            marginLeft: -5
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </td>}
                                        <td width="200px">
                                            {model && selectedChiefComplaint.value !== "newChiefComplaint" && <Tooltip title={'Preserve the chief complaint'}>
                                                <FormControlLabel style={{ marginLeft: '0px', marginTop: '25px' }}
                                                    control={<Checkbox
                                                        checked={blueprint.preserveChiefComplaint}
                                                        onChange={(e) => {
                                                            blueprint.preserveChiefComplaint = e.target.checked;
                                                            this.setState({ blueprint });
                                                        }}
                                                    />
                                                    }
                                                    label="Preserve" />
                                            </Tooltip>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </MuiAccordionDetails>
                    </MuiAccordion>

                    <MuiAccordion square expanded={questionAccordianExpand} onChange={() => this.setState({ questionAccordianExpand: !questionAccordianExpand })}>
                        <MuiAccordionSummary>
                            <Typography className="section_title">Question</Typography>
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                            <table >
                                <tbody>
                                    <tr>
                                        {model && <td style={{ width: "350px" }} >
                                            <FormControl style={{ width: "350px" }}>
                                                <InputLabel>Select Severity Question Data Id</InputLabel>
                                                <ReactSelect
                                                    isLoading={loading}
                                                    style={{ margin: "10px" }}
                                                    isSearchable
                                                    value={selectedSymptom}
                                                    options={symptomItems}
                                                    placeholder={"Select Severity Question Data Id"}
                                                    onChange={(e) => this.onSymptomChanged(e)}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{
                                                        valueContainer: (provided, state) => ({
                                                            ...provided,
                                                            overflow: 'visible'
                                                        }),
                                                        container: (provided, state) => ({
                                                            ...provided,
                                                            marginTop: 20
                                                        }),
                                                        placeholder: (provided, state) => ({
                                                            ...provided,
                                                            position: "absolute",
                                                            top: -15,
                                                            transition: "top 0.1s, font-size 0.1s",
                                                            fontSize: '0.75rem',
                                                            lineHeight: 1,
                                                            marginLeft: -5
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </td>}
                                        <td width="200px">
                                            {model && selectedSymptom.value !== "newSymptom" && <Tooltip title={'Preserve the symptom'}>
                                                <FormControlLabel style={{ marginLeft: '0px', marginTop: '20px' }}
                                                    control={<Checkbox
                                                        checked={blueprint.preserveSymptom}
                                                        onChange={(e) => {
                                                            blueprint.preserveSymptom = e.target.checked;
                                                            this.setState({ blueprint });
                                                        }}
                                                    />
                                                    }
                                                    label="Preserve" />
                                            </Tooltip>}
                                        </td>
                                        <td align='right'>
                                            {model && <Tooltip title={'Reset to default'}><IconButton style={{ marginLeft: '0px', marginTop: '20px' }} disabled={progress !== null} onClick={() => this.resetQuestion()}><ResetQuestion /></IconButton></Tooltip>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='3' valign="top">
                                            {model && <QuestionComponent
                                                appContext={appContext}
                                                planId={planId}
                                                question={question}
                                                init={init}
                                                onChange={(question) => {
                                                    blueprint.question = question;
                                                    //this is a special case for the symptom question. we want the question wired to the symptom
                                                    blueprint.question.dataId = blueprint.symptomDataType.dataId;
                                                    self.setState({ blueprint, validated: false });
                                                }} />
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </MuiAccordionDetails>
                    </MuiAccordion>
                    <MuiAccordion square expanded={detectAccordianExpand} onChange={() => this.setState({ detectAccordianExpand: !detectAccordianExpand })}>
                        <MuiAccordionSummary>
                            <Typography className="section_title">Detect</Typography>
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                            <table width="100%" valign="top">
                                <tbody>
                                    <tr>
                                        <td>
                                            {model && <SymptomDetectComponent
                                                appContext={appContext}
                                                modelDataId={modelDataId}
                                                allowModelSelect
                                                planId={planId}
                                                alerts={allAlerts}
                                                onChange={(updates) => {
                                                    blueprint = { ...blueprint, ...updates };
                                                    self.setState({ blueprint, validated: false }, () => self.onDetectModelChanged());
                                                }}
                                            />}
                                        </td>
                                    </tr>
                                    {dialog}
                                </tbody>
                            </table >
                        </MuiAccordionDetails>
                    </MuiAccordion>
                    <MuiAccordion square expanded={recommendAccordianExpand} onChange={() => this.setState({ recommendAccordianExpand: !recommendAccordianExpand })}>
                        <MuiAccordionSummary>
                            <Typography className="section_title">Recommendations</Typography>
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                            <table width="100%" valign="top">
                                <tbody>
                                    <tr>
                                        <td>
                                            {model && <SymptomRecommendComponent
                                                appContext={appContext}
                                                priorityDataId={priorityDataId}
                                                allowModelSelect
                                                symptomName={symptomName}
                                                planId={planId}
                                                onChange={(updates) => {
                                                    blueprint = { ...blueprint, ...updates };
                                                    self.setState({ blueprint, validated: false });
                                                }}
                                            />}
                                        </td>
                                    </tr>

                                </tbody>
                            </table >
                        </MuiAccordionDetails>
                    </MuiAccordion>
                    {dialog}
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        open={showUpdateProgress}
                        onClose={() => this.setState({ showUpdateProgress: !showUpdateProgress })}
                        key={'snack'}
                    >
                        <MuiAlert variant="outlined" severity="info"
                            onClose={() => this.setState({ showUpdateProgress: !showUpdateProgress })}>
                            <AlertTitle>Updating...<LinearProgress /></AlertTitle>
                            <ol className="no-padding-list">{progressList}</ol>
                        </MuiAlert>
                    </Snackbar>
                </div>
            </Box>
        </StylesProvider >;
    }
}


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


export const MuiAccordion = withStyles({
    root: {
        // boxShadow: '0 2px 10px rgb(0 0 0 / 0.2)',
        // border: '0.5px solid rgba(0, 0, 0, .100)',
        borderRadius: '5px',
        // boxShadow: '2px 2px 1px #ebebec',
        boxShadow: '1px 1px 2px 2px #d4d4d4',
        '&:not(:last-child)': {
            borderBottom: '0.5px solid rgba(0, 0, 0, .100)',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            marginBottom: '10px'
        },
        marginBottom: '10px'
    },
    expanded: {},
})(Accordion);

export const MuiAccordionSummary = withStyles({
    root: {
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        // backgroundImage: 'linear-gradient(rgb(255, 255, 255) 0%, rgb(229, 229, 229) 100%)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 46,
        '&$expanded': {
            minHeight: 46,
            borderBottom: '0px solid rgba(0, 0, 0, .125)',
        },
        margin: '12px 0',
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(AccordionSummary);

export const MuiAccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(AccordionDetails);

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <components.ValueContainer {...props}>
            <Placeholder  {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child => {
                return child && child.type !== Placeholder ? child : null;
            })}
        </components.ValueContainer>
    );
};
const MuiAlert = withStyles({
    root: {
        backgroundColor: '#ffffff',

    }
})(Alert);
// const RedSwitch = withStyles({
//     switchBase: {
//         '&$checked': {
//             color: 'red',
//         },
//         '&$checked + $track': {
//             backgroundColor: 'red',
//         },
//     },
//     checked: {},
//     track: {},
// })(Switch);

export default SymptomModelView;
