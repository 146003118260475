import React, { Component } from "react";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import SortableTree, { walk, toggleExpandedForAll } from "react-sortable-tree";
import Draggable from 'react-draggable';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import Plan from "@apricityhealth/web-common-lib/components/Plan";
import { Link } from 'react-router-dom';

import {
    withStyles,
    CircularProgress,
    TextField,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    Checkbox,
    DialogTitle,
    DialogContentText,
    Dialog,
    DialogActions,
    DialogContent,
    Select,
    MenuItem,
    Badge,
    Switch,
    Tooltip,
    Paper
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import EditableNote from '../components/EditableNote'
import PubSub from 'pubsub-js'

import {
    isArrayValid,
} from '../utils/Utils';

import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import EditRecommendationDialog from '../dialogs/EditRecommendationDialog';

import {
    deleteRecommendation,
    loadRecommendationModels,
    loadRecommendations,
    saveRecommendation
} from "@apricityhealth/web-common-lib/utils/Services";

import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';

const maxDepth = 10;
const getNodeKey = ({ node }) => `${node.id}`

class RecommendationTypesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
            filter: '',
            dependencies: true,
            dialog: null,
            progress: null,
            externalNodeType: "recommendation",
            showGroups: false,
            showGroupsFilterCategory: false,
            groupsFilterCategory: '',
            expandAllToggleEnabled: false,
            checkAllModels: false,
            openNewWindow: toBoolean(localStorage.getItem("DataLinksDIalog.openNewWindow"))
        };
        this.expandedNodes = {};
    }

    componentDidMount() {
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { dependencies } = this.state;
        const { appContext } = this.props;

        let promises = [];
        promises.push(loadRecommendations(appContext, { dependencies }));
        promises.push(loadRecommendationModels(appContext, { dependencies: true }));
        promises.push(loadRecommendationModels(appContext, { planId: "*", dependencies: false }));

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Promise.all(promises).then((results) => {
            let recommendations = results[0];
            let models = results[1];
            let allModels = results[2];
            recommendations.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));

            self.setState({ recommendations, allModels, models, progress: null }, self.initializeTreeModel.bind(self));
        }).catch(function (error) {
            self.setState({ progress: null, error: error.message });
        });
    }

    getUsedRecommendationsByCategory() {
        let recommendationByCategory = {};

        let { models } = this.state;
        if (Array.isArray(models)) {
            for (let i = 0; i < models.length; i++) {
                let model = models[i];
                let groups = model.groups;
                if (!Array.isArray(groups)) continue;;
                for (let j = 0; j < groups.length; j++) {
                    let group = groups[j];
                    let categories = group.categories
                    if (!Array.isArray(categories)) continue;;
                    for (let k = 0; k < categories.length; k++) {
                        let category = categories[k];
                        if (!recommendationByCategory[category.name]) {
                            recommendationByCategory[category.name] = []
                        }

                        let recommendations = category.recommendations;
                        if (!Array.isArray(recommendations)) continue;;
                        for (let l = 0; l < recommendations.length; l++) {
                            if (recommendationByCategory[category.name].indexOf(recommendations[l].recommendId) === -1) {
                                recommendationByCategory[category.name].push(recommendations[l].recommendId)
                            }
                        }
                    }
                }
            }
        }
        return recommendationByCategory
    }

    getUsedRecommendations() {
        let { models, allModels } = this.state;

        console.debug(`Recommend models `, models, allModels)
        let usedRecommendations = []
        if (Array.isArray(models)) {
            for (let i = 0; i < allModels.length; i++) {
                let model = allModels[i];
                let groups = model.groups;
                if (!Array.isArray(groups)) continue;;

                for (let j = 0; j < groups.length; j++) {
                    let group = groups[j];
                    let categories = group.categories;
                    if (!Array.isArray(categories)) continue;;

                    for (let k = 0; k < categories.length; k++) {
                        let category = categories[k];
                        let recommendations = category.recommendations;
                        if (!Array.isArray(recommendations)) continue;;

                        for (let l = 0; l < recommendations.length; l++) {
                            if (usedRecommendations.indexOf(recommendations[l].recommendId) < 0)
                                usedRecommendations.push(recommendations[l].recommendId)
                        }
                    }
                }
            }
        }
        return usedRecommendations
    }

    filterRecommendation(filter, recommendation) {
        if (recommendation.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
        if (recommendation.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
        if (recommendation.type.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
        if (recommendation.recommendId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            return true;
        return false;
    }

    initializeTreeModel() {
        let { recommendations, filter, expandAllToggleEnabled, showGroups, showGroupsFilterCategory, groupsFilterCategory } = this.state;

        this.captureExpandedState()

        let treeData = [];
        let recommendationsByCategory = this.getUsedRecommendationsByCategory();
        let usedRecommendationsInRecommendModels = this.getUsedRecommendations();

        for (let i = 0; i < recommendations.length; i++) {
            let recommendation = recommendations[i];
            if (!this.filterRecommendation(filter, recommendation)) {
                continue;
            }

            recommendation.name = recommendation.name.trim();
            let text = recommendation.name;
            let recommendId = recommendation.recommendId;
            let type = recommendation.type;
            let category = recommendation.category;
            let id = recommendId + ":" + this.level;
            let links = this.getRecommendationLinks(recommendation);
            let isUsed = usedRecommendationsInRecommendModels.includes(recommendId);
            let subtitle = <Plan appContext={this.props.appContext} planId={recommendation.planId} />;

            let recommendationNode = {
                nodeType: 'recommendation',
                type,
                text,
                id,
                links,
                recommendId,
                isUsed,
                category,
                subtitle,
                isPool: true,
                recommendation,
                children: [],
                expanded: this.expandedNodes[id] || expandAllToggleEnabled
            };

            /////SHOW GROUP SECTION
            if (showGroups) {
                if (showGroupsFilterCategory) {
                    if (!groupsFilterCategory) {
                        continue
                    }
                    if (!recommendationsByCategory[groupsFilterCategory]) {
                        continue
                    }
                    if (recommendationsByCategory[groupsFilterCategory] &&
                        recommendationsByCategory[groupsFilterCategory].indexOf(recommendation.recommendId) < 0) {
                        continue
                    }
                }

                let group = recommendation.group;
                let groupNode = treeData.find((e) => e.id === group);
                if (!groupNode) {
                    let children = [];
                    groupNode = {
                        nodeType: 'group',
                        text: group,
                        id: group,
                        group,
                        recommendation,
                        children,
                        expanded: this.expandedNodes[id] || expandAllToggleEnabled
                    }
                    treeData.push(groupNode);
                }

                groupNode.children.push(recommendationNode)
                groupNode.children.sort((a, b) => (a.recommendation.option > b.recommendation.option) ? -1 : 1)
            } else {
                treeData.push(recommendationNode);
            }
        }

        this.setState({ treeData });
    }

    expandTree() {
        let { treeData, expandAllToggleEnabled } = this.state;
        const newTreeData = toggleExpandedForAll({
            treeData,
            expanded: expandAllToggleEnabled
        });
        this.setState({ treeData: newTreeData });
    }

    captureExpandedState() {
        let { treeData } = this.state;
        this.expandedNodes = {};
        walk({
            treeData,
            callback: (rowInfo) => { this.expandedNodes[rowInfo.node.id] = rowInfo.node.expanded; },
            getNodeKey,
            ignoreCollapsed: true,
        });
    }

    saveRecommendation(recommendation, planId = null) {
        const self = this;
        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        saveRecommendation(this.props.appContext, recommendation, planId).then(() => {
            self.setState({ progress: null, override: false, dialog: null });
            if (self.props.onChange)
                self.props.onChange(self);
            self.loadContent()
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    deleteRecommendation(recommendation) {
        const self = this;
        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        deleteRecommendation(this.props.appContext, recommendation).then(() => {
            self.setState({ progress: null });
            if (self.props.onChange)
                self.props.onChange(self);
            self.loadContent()
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    confirmDeleteRecommendation(node) {
        const self = this;

        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    titleStyle={{ textAlign: "center" }}
                >
                    <DialogTitle >Delete Recommendation Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>This will delete the recommendation. Are you sure?</DialogContentText>
                        <DialogContentText>
                            <br />
                            <b>{node.recommendation.name}</b>
                            <br />
                            <Plan appContext={this.appContext} planId={node.recommendation.planId} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.deleteRecommendation(node.recommendation); }} >Yes</Button>,
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.cancel(); }}>No</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    showNodeJSON(node) {
        this.setState({
            dialog: <JsonDialog
                appContext={this.props.appContext}
                dataType={node.recommendation}
                onDone={() => {
                    this.setState({ dialog: null });
                }} />
        });
    }

    showAllReferenceLinks(recommendation) {
        const { openNewWindow } = this.state;

        let links = this.getRecommendationLinks(recommendation)
        let listsItems = [];
        for (let index = 0; index < links.length; index++) {
            let element = links[index];
            const url = `/recommend_models/${element.model.modelId}`;
            listsItems.push(
                <ListItem key={listsItems.length}>
                    {openNewWindow ? <IconButton onClick={() => window.open(url, '_blank')}><LinkIcon /></IconButton> :
                        <IconButton component={Link} to={url}><LinkIcon /></IconButton>}
                    <ListItemText
                        primary={`${element.model.name}: ${element.group.name}: ${element.category.name}`}
                        secondary={element.group.type}
                    />
                </ListItem>
            );
        }
        this.setState({
            dialog: <div width='1000px'>
                <Dialog
                    maxWidth={'md'}
                    fullWidth={true}
                    model="false"
                    open={true}
                    width='1000px'
                >
                    <DialogTitle >{recommendation.name}</DialogTitle>
                    <DialogContent>
                        <div width='1000px'>
                            Recommendation exists in the following models:
                            <List>
                                {listsItems}
                            </List>
                        </div>
                        <FormControlLabel label='Open links in new window' control={<Switch checked={openNewWindow} onChange={(e) => {
                            this.setState({ openNewWindow: e.target.checked }, this.showAllReferenceLinks.bind(this, recommendation));
                            localStorage.setItem("DataLinksDIalog.openNewWindow", e.target.checked);
                        }} />} />

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={this} style={{ margin: 10 }} onClick={(e) => { this.setState({ dialog: null }); }} >Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancel() {
        this.setState({ dialog: null });
    }

    saveRecommendationNode(node, recommendation, planId = null) {
        const { appContext } = this.props;
        if (!planId && recommendation.planId && recommendation.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(recommendation.planId, this.saveRecommendationNode.bind(this, node, recommendation));
        }
        this.saveRecommendation(recommendation, planId);
    }

    deleteUnusedRecommentations(listItems) {
        let self = this;
        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        let promises = [];
        listItems.forEach(recommendation => {
            promises.push(deleteRecommendation(this.props.appContext, recommendation))
        });
        Promise.all(promises).then(() => {
            self.setState({ progress: null });
            if (self.props.onChange)
                self.props.onChange(self);
            self.loadContent()
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    confirmDeleteUnusedRecommentations() {
        let { recommendations } = this.state;
        let usedRecommendationsInRecommendModels = this.getUsedRecommendations();
        let unusedRecommendations = recommendations.filter((rec) => !usedRecommendationsInRecommendModels.includes(rec.recommendId));
        let listItems = [];
        for (let i = 0; i < unusedRecommendations.length; i++) {
            let element = unusedRecommendations[i];
            listItems.push(
                <li>{element.name}</li>
            );
        }

        this.setState({
            dialog: <div width='1000px'>
                <Dialog
                    maxWidth={'md'}
                    fullWidth={true}
                    model="false"
                    open={true}
                    width='1000px'
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Remove {unusedRecommendations.length} unused recommendations?</DialogTitle>
                    <DialogContent>
                        <div width='1000px' height="500px">
                            {unusedRecommendations.length} recommendations will be removed, are you sure?
                            <br /><br />
                            <ul >
                                {listItems}
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ margin: 10 }} onClick={(e) => { this.setState({ dialog: null }); }} >Cancel</Button>
                        <Button variant="contained" style={{ margin: 10 }} onClick={(e) => { this.deleteUnusedRecommentations(unusedRecommendations) }} >Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    editRecommendation(node) {
        this.setState({
            dialog: <EditRecommendationDialog
                recommendation={node.recommendation}
                appContext={this.props.appContext}
                onCancel={() => {
                    this.setState({ dialog: null });
                }}
                onDone={(recommendation) => {
                    this.saveRecommendationNode(node, recommendation);
                }} />
        });
    }

    createNewRecommendation() {
        let self = this;
        let newRecommendation = {
            name: "",
            description: "",
            category: "",
            option: '0',
            type: "",
            recommendId: uuidv4()
        }
        self.setState({
            dialog: <EditRecommendationDialog
                recommendation={newRecommendation}
                appContext={this.props.appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(recommendation) => {
                    self.saveRecommendation(recommendation);

                }} />
        });
    }

    compareRecommendationNames(node) {
        let { treeData } = this.state;
        let recommendId = node.recommendation.recommendId;
        let shrunkRecommendationName = node.recommendation.name.replace(/\s/g, '');
        let found = false;
        walk({
            treeData,
            callback: (rowInfo) => {
                if (rowInfo.node.nodeType === 'recommendation') {
                    if (rowInfo.node.recommendation.recommendId !== recommendId) {
                        let nextRecommendationName = rowInfo.node.recommendation.name.replace(/\s/g, '');
                        if (nextRecommendationName === shrunkRecommendationName) {
                            found = true;
                        }
                    }
                }
            },
            getNodeKey,
            ignoreCollapsed: true,
        });
        return found;
    }

    getRecommendationLinks(recommendation) {
        let links = [];
        let { models } = this.state;

        if (!isArrayValid(models)) return [];
        for (let i = 0; i < models.length; i++) {
            let model = models[i];
            let groups = model.groups;
            if (!isArrayValid(groups)) continue;;

            for (let j = 0; j < groups.length; j++) {
                let group = groups[j];
                let categories = group.categories
                if (!isArrayValid(categories)) continue;;

                for (let k = 0; k < categories.length; k++) {
                    let category = categories[k];
                    let recommendations = category.recommendations;

                    if (!isArrayValid(recommendations)) continue;;

                    for (let l = 0; l < recommendations.length; l++) {
                        let nextRecommendation = recommendations[l];
                        if (recommendation.recommendId === nextRecommendation.recommendId) {
                            links.push({ model, group, category });
                        }
                    }
                }
            }
        }
        return links
    }

    canDrop({ node, nextParent }) {
        if (this.props.appContext.isProtected()) return false;
        if (node.nodeType === 'recommendation' && nextParent) {
            if (nextParent.nodeType === 'group')
                return true;
        }
        return false;
    };

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    onMoveNode(args, planId = null) {
        const { appContext } = this.props;
        const { node, nextParentNode } = args;
        if (node.nodeType === 'recommendation' && nextParentNode) {
            let recommendation = node.recommendation;
            let recommendationDirty = false;
            if (nextParentNode.nodeType === 'group') {
                if (recommendation.group !== nextParentNode.group) {
                    if (!planId && recommendation.planId && recommendation.planId !== appContext.state.plan.planId) {
                        return this.displayOverrideDialog(recommendation.planId, this.onMoveNode.bind(this, args));
                    }

                    recommendation.group = nextParentNode.group;
                    recommendationDirty = true;
                }
            }
            if (recommendationDirty === true) {
                this.saveRecommendation(recommendation, planId);
            }
        }
    }

    getNodeButtons(node) {
        let { appContext, readOnly } = this.props
        let buttons = [];
        if (readOnly) {
            return buttons;
        }
        let optionSelect = <TextField key='optionSelect'
            disabled={appContext.isProtected()}
            type='number'
            label="Rank:"

            style={{ marginLeft: '5px', width: '50px', height: '40px', textAlign: 'center', align: 'center' }}
            value={node.recommendation.option}
            onChange={(e) => {
                node.recommendation.option = e.target.value;
                this.saveRecommendationNode(node, node.recommendation)
            }}
        />
        let linksCount = isArrayValid(node.links) ? node.links.length : 0;
        let showAllReferenceLinksButton =
            <IconButton key='showReferences' onClick={() => { this.showAllReferenceLinks(node.recommendation) }}>
                <Badge badgeContent={`${linksCount}`} invisible={linksCount === 0} color='primary'>
                    <LinkIcon />
                </Badge>
            </IconButton>;

        let removeNodeButton =
            <IconButton key='removeNode'
                onClick={() => { this.confirmDeleteRecommendation(node) }}>
                <DeleteIcon />
            </IconButton>;


        let editButton =
            <IconButton key='editButton'
                onClick={() => this.editRecommendation(node)}>
                <CreateIcon />
            </IconButton>;

        let showJsonButton =
            <IconButton key='showCode'
                onClick={() => this.showNodeJSON(node)}>
                <CodeIcon />
            </IconButton>;

        if (node.nodeType === 'recommendation') {
            buttons.push(optionSelect);
            buttons.push(showAllReferenceLinksButton);
            buttons.push(editButton);

            if (appContext.state && appContext.state && appContext.state.plan) {
                if (node.recommendation) {
                    buttons.push(removeNodeButton);
                }
            }

            buttons.push(showJsonButton);
        }
        return buttons;
    }

    getNodeProps({ node, path, treeIndex }) {
        let style = { height: 50, width: '100%', border: 0, cursor: 'pointer' };
        if (this.compareRecommendationNames(node)) {
            style.boxShadow = `0 0 0 2px #0000FF`;
        }
        if (node.nodeType === 'recommendation' && !node.isUsed) {
            style.boxShadow = `0 0 0 2px #00ff55`;
            style.border = 2
        }

        return {
            listIndex: 0,
            lowerSiblingCounts: [],
            style,
            title: (
                <div style={{ fontSize: 16, maxWidth: "500px", border: 0, overflow: 'hidden', fontWeight: 'normal' }}
                >{node.text} </div>
            ),
            buttons: (this.getNodeButtons(node, path, treeIndex))
        };
    }

    generateRecommendationItems() {
        const { appContext: { stores: { DataTypesStore } } } = this.props;
        return DataTypesStore.getRecommendCategories().sort((a, b) => a.name.localeCompare(b.name)).map((e) => {
            return <MenuItem key={e.categoryId} value={e.categoryId}>{e.name}</MenuItem>;
        })
    }

    showCategoryDropdown() {
        const self = this;
        const { showGroups, showGroupsFilterCategory, groupsFilterCategory } = this.state;
        if (showGroups && showGroupsFilterCategory) {
            return (
                <Select style={styles.select}
                    label='Category'
                    value={groupsFilterCategory}
                    name='groupsFilterCategory'
                    onChange={(e) => self.setState({ groupsFilterCategory: e.target.value }, self.initializeTreeModel.bind(self))}>
                    {this.generateRecommendationItems()}
                </Select>
            )
        }
    }

    showGroupFilterCategoryCheckBox() {
        const self = this;
        const { showGroups } = this.state;
        if (showGroups) {
            return (
                <span>
                    <FormControlLabel
                        control={<Checkbox
                            name='showGroupsFilterCategory'
                            onChange={(e, v) => self.setState({ showGroupsFilterCategory: v }, self.initializeTreeModel.bind(self))}
                        />
                        }
                        label="Filter on Model Category" />
                    {this.showCategoryDropdown()}
                </span>)
        }
    }

    onChangeTree(treeData) {
        this.setState({ treeData });
    }

    render() {
        const self = this;
        let { dialog,
            error,
            progress,
            treeData,
            filter,
            showGroups,
            expandAllToggleEnabled,
            dependencies,
            recommendations
        } = self.state;

        let { editMode } = this.props;

        let usedRecommendationsInRecommendModels = this.getUsedRecommendations();
        let unusedRecommendations = recommendations ? recommendations.filter((rec) => !usedRecommendationsInRecommendModels.includes(rec.recommendId)) : [];
        let newRecommendationButton = editMode !== false ? <IconButton onClick={this.createNewRecommendation.bind(this)}><AddIcon /></IconButton> : null;

        let sortableTree =
            <div style={styles.treeWrap} valign='top'>
                <SortableTree
                    treeData={treeData}
                    maxDepth={maxDepth}
                    canDrop={this.canDrop.bind(this)}
                    onChange={this.onChangeTree.bind(this)}
                    theme={FileExplorerTheme}
                    onMoveNode={this.onMoveNode.bind(this)}
                    getNodeKey={getNodeKey}
                    canNodeHaveChildren={node => node.nodeType === 'group'}
                    isVirtualized={true}
                    shouldCopyOnOutsideDrop={true}
                    rowdirection='ltr'
                    dndType={this.state.externalNodeType}
                    generateNodeProps={this.getNodeProps.bind(this)}
                />
            </div>


        const contentStyle = Object.assign({}, styles.content);
        if (self.state.drawerOpen)
            contentStyle.marginRight = 450;

        return <table width="100%"><tbody>
            <tr><td>
                <TextField
                    style={{ margin: 5, width: 500 }}
                    value={filter} label="Filter"
                    name='filter'
                    onChange={(e) => self.setState({ filter: e.target.value }, self.initializeTreeModel.bind(self))}
                />
            </td>
                <td align="right">
                    {error}
                    <EditableNote textId={`recommendTypesTooling`} planId={"tooling"} />
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td>
                    <FormControlLabel control={
                        <Checkbox
                            checked={showGroups}
                            name='showGroups'
                            onChange={(e, v) => self.setState({ showGroups: v }, self.initializeTreeModel.bind(self))}
                        />}
                        label="Groups" />
                    <FormControlLabel control={
                        <Checkbox
                            checked={expandAllToggleEnabled}
                            name='expandAllToggleEnabled'
                            onChange={(e, v) => self.setState({ expandAllToggleEnabled: v }, self.expandTree.bind(self))}
                        />} label="Expand all/collapse" />
                    <FormControlLabel control={
                        <Checkbox
                            checked={dependencies}
                            name='dependencies'
                            onChange={(e, v) => self.setState({ dependencies: v }, self.loadContent.bind(self))}
                        />} label="Show Dependencies" />
                    {this.showGroupFilterCategoryCheckBox()}
                    {recommendations ? recommendations.length : 0} Recommendation Types, {unusedRecommendations.length} unused.
                </td>
                <td align="right">
                    <Tooltip title='Delete unused recommendations'><IconButton onClick={this.confirmDeleteUnusedRecommentations.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                    {newRecommendationButton}
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <div style={{ width: '100%' }} valign='top' >
                        {sortableTree}
                    </div>
                    {dialog}
                </td>
            </tr>
        </tbody></table>;
    }
}

const styles = {
    div: {
        margin: 10
    },
    progress: {
        color: 'red'
    },
    select: {
        width: 200
    },
    sidebarHeader: {
        display: 'flex',
        padding: '0 8px',
        justifyContent: 'left'
    }, drawer: {
        overflow: 'hidden',
        width: 50,
        marginTop: '70px',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    treeWrap: {
        height: 'calc(100vh - 240px)',
        width: '100%',
    },
    drawerPaper: {
        overflow: 'hidden',
        marginTop: '70px',
        width: 600,
        resize: 'vertical',
        minWidth: 50,
    },
    drawerOpen: {
        overflow: 'hidden',
        width: 600,
        resize: 'vertical',
        marginTop: '70px',
    },
    drawerClose: {
        marginTop: '70px',
        overflowX: 'hidden',
        resize: 'vertical',
        width: 50
    },
    footer: {
        bottom: 0,
        minHeight: 50,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #C5C5C5'
    }
};

RecommendationTypesView.propTypes = {
    children: PropTypes.node
};


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default withStyles(styles)(RecommendationTypesView);