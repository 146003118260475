import React from "react";

import { default as SortableTree, toggleExpandedForAll, walk } from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import {
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    DialogActions,
    DialogContent,
    CircularProgress,
    Tooltip,
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import OverrideIcon from '@material-ui/icons/CheckCircle';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import {
    arrayRemoveLocation,
    isArrayValid
} from '../utils/Utils';

import {
    loadDataTypes
} from '@apricityhealth/web-common-lib/utils/Services';

import { SelectDataTypeCategory } from '@apricityhealth/web-common-lib/components/SelectDataTypeCategory';
import JsonDialog from '../dialogs/JsonDialog';
import EditDataTypeDialog from '../dialogs/EditDataTypeDialog';
import DiffDialog from "../dialogs/DiffDialog";

import Plan from '@apricityhealth/web-common-lib/components/Plan';
import PubSub from 'pubsub-js'
import { DataLinkIcon } from "../dialogs/DataLinksDialog";

import EditableNote from '../components/EditableNote'

const getNodeKey = ({ node }) => `${node.nodeId}`

function arrayFilterDataTypes(filter, dataType) {
    let parts = filter.split(',');
    for (let i = 0; i < parts.length; ++i) {
        if (!parts[i]) continue;
        if (filterDataTypes(parts[i], dataType))
            return true;
    }
    return false;
}

function filterDataTypes(filter, dataType) {
    if (dataType.dataId && dataType.dataId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (dataType.name && dataType.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (dataType.description && dataType.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (dataType.category && dataType.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;

    return false;
}

class DataTypesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: "",
            dataTypes: [],
            category: props.category || '',
            sorting: false,
            dialog: null,
            showCategories: false,
            treeData: null,
            questions: [],
            models: [],
            theme: props.appContext.state.theme,
            mode: props.appContext.state.mode,
            expandedNodes: {},
            expandAll: false,
            expandedTypes: {},
            plan: null,
            progress: null
        };
    }

    componentDidMount() {
        this.token = PubSub.subscribe('STORE_UPDATE', this.loadContent.bind(this));
        this.loadContent();
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    componentDidUpdate(oldProps) {
        if (this.props.category !== oldProps.category) {
            this.setState({ category: this.props.category }, this.loadContent.bind(this));
        }
        else
            this.handleOpen();
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;
        const { plan } = appContext.state;
        if (plan) {
            const { planId } = plan;
            const { category, dependencies } = this.state;

            const store = appContext.stores.DataTypesStore;
            let dataTypes = store.getDataTypes().filter((e) => (dependencies === true || e.planId === planId) && (!category || e.category === category));
            self.setState({ dataTypes }, () => {
                self.handleOpen();
                self.createTreeData();
            });
        }
    }

    handleOpen() {
        // automatically open the question by ID if provided..
        let parts = window.location.pathname.slice(1).split('/');
        if (parts.length > 1) {
            window.history.replaceState(null, '', window.location.href.substring(0, window.location.href.lastIndexOf('/')));
            let dataType = this.state.dataTypes.find((e) => e.dataId === parts[1]);
            if (dataType)
                this.editDataType(dataType);
        }
    }

    deleteTupleDescription(dataType, tupleDescription) {
        const self = this;
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        this.setState({
            dialog: null,
            progress: <CircularProgress size={20} />
        });

        arrayRemoveLocation(dataType.tupleDescriptions, tupleDescription.index);
        for (let i = 0; i < dataType.tupleDescriptions.length; ++i)
            dataType.tupleDescriptions[i].index = i;
        this.createTreeData();

        store.saveDataType(dataType).then(() => {
            self.setState({ progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });

    }

    deleteDataType(dataType) {
        const self = this;
        const store = this.props.appContext.stores.DataTypesStore;
        this.setState({ dialog: null });

        this.setState({ progress: <CircularProgress size={20} /> });
        store.deleteDataType(dataType.dataId, dataType.planId).then(() => {
            self.setState({ progress: null }, self.loadContent.bind(self));
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    showNodeJSON(node) {
        let { appContext } = this.props;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={node.dataType}
                onDone={() => {
                    this.setState({ dialog: null });
                }} />
        });
    }

    editDataTypeNode(node) {
        this.editDataType(node.dataType);
    }

    editDataType(dataType) {
        let self = this;
        let { appContext } = this.props;
        self.setState({
            dialog: <EditDataTypeDialog
                appContext={appContext}
                dataType={dataType}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={() => {
                    self.setState({ dialog: null }, self.loadContent.bind(self));
                }} />
        });
    }

    createNewDataType() {
        let self = this;
        let { appContext } = this.props;
        let newDataType = {
            dataId: "New Data ID",
            name: "New type",
            description: "New type",
            category: '',
            tupleDescriptions: []
        }

        self.setState({
            dialog: <EditDataTypeDialog
                appContext={appContext}
                dataType={newDataType}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={() => {
                    self.setState({ dialog: null }, self.loadContent.bind(self));
                }} />
        });

    }

    confirmDeleteDataType(dataType) {
        const self = this;
        let data = this.props.appContext.stores.DataTypesStore.getDataTypeLinks(dataType.dataId);
        let hasReferences = data && data.links.length > 0;

        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    titleStyle={{ textAlign: "center" }}
                >
                    <DialogTitle >Delete Data Type Confirm</DialogTitle>
                    <DialogContent>
                        {hasReferences ? <span style={{ color: 'red' }}>{`WARNING: This data type is REFERENCED in ${data.links.length} places.`}<br /><br /></span>
                            : null}
                        This will delete '{dataType.name}'. Are you sure?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.deleteDataType.bind(this, dataType)}>Yes</Button>
                        <Button variant="contained" style={styles.button} onClick={this.onCloseDialog.bind(this)}>No</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    expandTree() {
        let self = this;
        let { treeData, expandAll } = self.state;
        const newTreeData = toggleExpandedForAll({
            treeData,
            expanded: expandAll
        });
        this.setState({ treeData: newTreeData }, this.createTreeData.bind(this));
    }

    captureExpandedState() {
        let { treeData, expandedNodes, expandAll } = this.state;
        walk({
            treeData,
            callback: (rowInfo) => {
                expandedNodes[rowInfo.node.nodeId] = rowInfo.node.expanded || expandAll;
            },
            getNodeKey,
            ignoreCollapsed: true,
        });
    }

    createTreeData() {
        let self = this;

        let { dataTypes,
            filter,
            showCategories,
            expandedNodes,
            expandAll
        } = this.state;

        self.captureExpandedState();

        let treeData = [];
        let nodes = {};                 // all nodes by thier dataId
        let childNodes = {};            // nodes by their parentId
        let categoryNodes = {};
        for (let i = 0; i < dataTypes.length; i++) {
            let dataType = dataTypes[i];

            if (dataType.category === undefined) dataType.category = 'none';
            if (dataType.baseline === undefined) dataType.baseline = false;
            if (dataType.parentId === dataType.dataId) dataType.parentId = '';

            let text = `${dataType.name} (${dataType.dataId})`;
            let subtitle = <Plan appContext={this.props.appContext} planId={dataType.planId} />;
            let nodeId = dataType.dataId;
            let node = {
                isDataType: true,
                dataType,
                text,
                subtitle,
                nodeId,
                children: [],
                expanded: expandedNodes[nodeId] || expandAll
            };
            nodes[node.nodeId] = node;

            if (showCategories === true) {
                if (!categoryNodes[dataType.category]) {
                    let nodeId = dataType.category;
                    let categoryNode = {
                        isCategory: true,
                        text: dataType.category,
                        nodeId,
                        children: [],
                        expanded: expandedNodes[nodeId] || expandAll
                    };

                    categoryNodes[dataType.category] = categoryNode;
                    treeData.push(categoryNode);
                }
                categoryNodes[dataType.category].children.push(node);
                categoryNodes[dataType.category].children.sort((a, b) => { return a.nodeId < b.nodeId ? -1 : a.nodeId > b.nodeId ? 1 : 0 });
            }
            else if (dataType.parentId) {
                if (!childNodes[dataType.parentId])
                    childNodes[dataType.parentId] = [node];
                else
                    childNodes[dataType.parentId].push(node);
            }
            else {
                treeData.push(node);
            }
        }

        // after we create all nodes, then push the children under the parent nodes
        for (let k in childNodes) {
            let children = childNodes[k];
            let parent = nodes[k];
            if (!parent) {
                console.error(`Failed to find parent ${k}`, children);
                treeData.push(...children);
                continue;
            }
            parent.children.push(...children);
            parent.children.sort((a, b) => { return a.nodeId < b.nodeId ? -1 : a.nodeId > b.nodeId ? 1 : 0 });
        }

        if (filter) {
            treeData = treeData.filter((node) => {
                let nodes = [node];
                while (nodes.length > 0) {
                    let test = nodes.splice(0, 1)[0];
                    if (test.dataType) {
                        if (arrayFilterDataTypes(filter, test.dataType)) {
                            return true;
                        }
                    }
                    if (isArrayValid(test.children))
                        nodes.push(...test.children);
                }
                return false;
            })
        }
        treeData.sort((a, b) => { return a.nodeId < b.nodeId ? -1 : a.nodeId > b.nodeId ? 1 : 0 });
        self.setState({ expandedNodes, treeData });
    }


    getNodeButtons(node) {
        const { appContext, readOnly } = this.props;

        let buttons = [];
        if (node.isDataType) {
            if (node.dataType.override) {
                buttons.push(<Tooltip key='override' title='Show Override'><IconButton onClick={this.showOverride.bind(this, node.dataType)}><OverrideIcon color='primary' /></IconButton></Tooltip>);
            }

            buttons.push(<DataLinkIcon appContext={appContext} dataId={node.dataType.dataId} />);
            if (!readOnly) {
                buttons.push(<Tooltip title='Edit'><span><IconButton onClick={this.editDataTypeNode.bind(this, node)}><CreateIcon /></IconButton></span></Tooltip>);
                buttons.push(<Tooltip title='Delete'><span><IconButton onClick={this.confirmDeleteDataType.bind(this, node.dataType)}><DeleteIcon /></IconButton></span></Tooltip>);
            }
            buttons.push(<Tooltip title='Show Code'><span><IconButton onClick={this.showNodeJSON.bind(this, node)}><CodeIcon /></IconButton></span></Tooltip>);
        }
        if (node.isCategory) {
            buttons.push(<>({node.children.length})</>)
        }

        return buttons;
    }

    canDrop(args) {
        let { node, nextParent } = args;
        if (node.isDataType) {
            if (nextParent && (nextParent.isCategory || nextParent.isDataType))
                return true;            // allow us to change the categories 
            if (!nextParent)
                return true;            // alow to be dropped back onto the root
        }

        return false;
    };

    showOverride(type) {
        const self = this;
        const { appContext } = this.props;
        const { plans } = appContext.state;

        const store = appContext.stores.DataTypesStore;
        this.setState({
            dialog: <DiffDialog appContext={appContext} type={type}
                onLoadType={(planId, type) => {
                    return new Promise((resolve, reject) => {
                        loadDataTypes(appContext, { dataId: type.dataId, planId }).then((types) => {
                            if (types.length > 0)
                                resolve(types[0]);
                            else
                                resolve(null);
                        }).catch((err) => {
                            reject(err);
                        });
                    })
                }}
                onDelete={(planId) => {
                    if (planId) {
                        type = { ...type, planId }
                    }
                    self.confirmDeleteDataType(type);
                }}
                onMerge={(planId) => {
                    console.log("onMerge:", planId);
                    let plan = plans.find((e) => e.planId === planId) || { title: planId };
                    self.setState({
                        dialog: <Dialog open={true}>
                            <DialogContent>Please confirm you want to merge this override into the '{plan.title}' plan?</DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={() => {
                                    self.setState({ progress: <CircularProgress size={20} />, error: null, dialog: null })
                                    store.saveDataType(type, planId).then(() => {
                                        return store.deleteDataType(type.dataId, type.planId);
                                    }).then(() => {
                                        self.setState({ progress: null }, self.loadContent.bind(self));
                                    }).catch((err) => {
                                        self.setState({ progress: null, error: getErrorMessage(err) })
                                    })
                                }}>Yes, Merge</Button>
                                <Button variant="contained" onClick={() => self.setState({ dialog: null })}>Cancel</Button>
                            </DialogActions>
                        </Dialog>
                    });
                }}
                onClose={this.onCloseDialog.bind(this)} />
        });
    }

    onChangeTreeChildren(nodes, parentId, category, modified) {
        for (let i = 0; i < nodes.length; ++i) {
            let node = nodes[i];
            if (node.isDataType === true) {
                let dataType = node.dataType;
                if (category && dataType.category !== category) {
                    dataType.category = category;
                    if (modified.indexOf(dataType) < 0)
                        modified.push(dataType);
                }
                if (parentId && dataType.parentId !== parentId) {
                    dataType.parentId = parentId;
                    if (modified.indexOf(dataType) < 0)
                        modified.push(dataType);
                }
            }
            if (isArrayValid(node.children)) {
                this.onChangeTreeChildren(node.children, node.nodeId, null, modified);
            }
        }
    }

    onChangeTree(treeData) {
        let modified = [];          // all modified data types are pushed into this array for saving..
        for (let i = 0; i < treeData.length; ++i) {
            let node = treeData[i];
            if (node.isCategory === true) {
                if (isArrayValid(node.children)) {
                    this.onChangeTreeChildren(node.children, null, node.nodeId, modified);
                }
            }
            else if (node.isDataType === true) {
                let dataType = node.dataType;
                if (dataType.parentId) {
                    dataType.parentId = '';
                    if (modified.indexOf(dataType) < 0)
                        modified.push(dataType);
                }
                if (isArrayValid(node.children)) {
                    this.onChangeTreeChildren(node.children, node.nodeId, null, modified);
                }
            }
        }

        if (modified.length > 0) {
            const store = this.props.appContext.stores.DataTypesStore;
            console.log(`${modified.length} data types modified:`, modified);
            store.saveDataType(modified).then((result) => {
                console.log("Modified types saved:", result);
            }).catch((err) => {
                console.error("Failed to save types:", err);
            })
        }
        this.setState({ treeData });
    }

    getNodeProps({ node }) {
        return {
            listIndex: 0,
            lowerSiblingCounts: [],
            style: styles.tree_node,
            title: node.text,
            buttons: this.getNodeButtons(node)
        };
    }

    onSelectCategory(e) {
        this.setState({ category: e.target.value }, this.loadContent.bind(this));
    }

    render() {
        const self = this;
        const { category, treeData, error, filter, dialog, showCategories, dependencies, dataTypes } = this.state;
        const store = this.props.appContext.stores.DataTypesStore;

        let sortableTree = treeData ? <div style={styles.tree} valign='top'><SortableTree
            treeData={treeData}
            maxDepth={20}
            canDrop={this.canDrop.bind(this)}
            onChange={this.onChangeTree.bind(this)}
            theme={FileExplorerTheme}
            canNodeHaveChildren={node => !node.isTupleDescription}
            isVirtualized={true}
            rowdirection='ltr'
            dndType='symptom'
            generateNodeProps={this.getNodeProps.bind(this)}
        /></div> : null;

        return <table width="100%"><tbody>
            <tr>
                <td>
                    <TextField style={styles.filter} value={filter} label="Filter" onChange={(e) => {
                        this.setState({ filter: e.target.value }, self.createTreeData.bind(self))
                    }} />
                    <SelectDataTypeCategory value={category} onChange={this.onSelectCategory.bind(this)} />
                </td>
                <td align="right">
                    {error}
                    <EditableNote textId={`dataTypesTooling`} planId={"tooling"} />
                    <IconButton disabled={store.progress !== null} onClick={store.loadDataTypes.bind(store)}>{store.progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td>
                    <FormControlLabel control={<Checkbox checked={this.state.expandAll} onChange={(e) => {
                        self.setState({ expandAll: e.target.checked }, self.expandTree.bind(self));
                    }} />} label="Expand/Collapse All" />
                    <FormControlLabel control={<Checkbox checked={showCategories} onChange={(e) => {
                        self.setState({ showCategories: e.target.checked }, self.createTreeData.bind(self));
                    }} />} label="Show Categories" />
                    <FormControlLabel control={<Checkbox checked={dependencies} onChange={(e, v) => {
                        self.setState({ dependencies: v }, self.loadContent.bind(self));
                    }} />} label="Show Dependencies" />
                    {dataTypes ? dataTypes.length : 0} Data Types
                </td>
                <td align="right">
                    <IconButton onClick={this.createNewDataType.bind(this)}><AddIcon /></IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    {sortableTree}
                    {dialog}
                </td>
            </tr>
        </tbody></table>;
    }
}

const styles = {
    filter: {
        margin: 5,
        width: 500
    },
    select: {
        margin: 5,
        width: 300
    },
    tree: { height: 1000, width: '100%' },
    tree_node: {
        height: 50,
        width: 500,
        border: 0,
        cursor: 'pointer'
    },
    index_label: { fontSize: 10, display: 'inline-block', color: 'purple', width: 50, textAlign: 'right', marginRight: 30, border: 'none' },
    div: {
        margin: 10
    },
    progress: {
        color: 'red'
    }
};

export default DataTypesView;
