import React, { Component } from "react";
import PubSub from 'pubsub-js'

import {
    CircularProgress,
    IconButton
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import {
    loadModels,
    loadQuestions,
} from '@apricityhealth/web-common-lib/utils/Services';

import {
    isArrayValid,
    getDataTypeQuestionLinks
} from '../utils/Utils';

function getDetectModelReport(model, questions) {
    let report = [];
    if (isArrayValid(model)) {
        model.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            else if (a.name > b.name)
                return 1;
            return 0;
        });
        //lets  do trigger symptom table first 
        report.push(getTriggerTable(model));

        let rows = []
        for (let j = 0; j < model.length; j++) {
            let nextModel = model[j];
            rows.push(<tr key={rows.length}><th className='detect_seperator' colSpan='8' > {nextModel.name} Model </th></tr>);
            rows.push(<tr key={rows.length} className='symptom_row'>
                <th className='detect_symptom_title' > Data Source - Category  </th>
                <th className='detect_symptom_title' > {nextModel.name} irAE Model</th>
                <th className='detect_symptom_title' > #Q's  </th>
                <th className='detect_symptom_title' > Question ID Name</th>
                <th className='detect_symptom_title' > Data ID Name </th>
                <th className='detect_symptom_title' > Weight </th>
                <th className='detect_symptom_title' > Data Range </th>
                <th className='detect_symptom_title' > Transformation </th>
            </tr>);

            rows.push(getDetectTable(nextModel, questions, rows.length));
        }

        report.push(
            <table key={report.length} align="left" className='detect_table' >
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
    return report;
}

function getTriggerTable(model) {
    let tables = [];
    if (isArrayValid(model)) {
       

        let data = []
        if (isArrayValid(model)) {
            for (let j = 0; j < model.length; j++) {
                let nextModel = model[j];
                if (isArrayValid(nextModel.triggerSymptoms)) {
                    // let columns = [];
                    let triggerSymptoms = nextModel.triggerSymptoms;
                    for (let x = 0; x < triggerSymptoms.length; x++) {
                        let triggerSymptom = triggerSymptoms[x];
                        data.push({ dataId: triggerSymptom.dataId, name: nextModel.name, modelId: nextModel.modelId })
                        // columns.push(<td key={columns.length} className='trigger_symptom'>{triggerSymptom.dataId}</td>);
                        // columns.push(<td key={columns.length} className='trigger_symptom_model'>{nextModel.name}</td>);
                        // rows.push(<tr key={rows.length} className='symptom_row'>{columns}</tr>);
                        // columns = [];
                    }
                }
            }
        }
        data.sort((a, b) => {
            if (a.dataId < b.dataId)
                return -1;
            else if (a.dataId > b.dataId)
                return 1;
            return 0;
        });
        let rows = [];
        if (isArrayValid(data)) {
            data.forEach((d) => {
                let columns = [];
                columns.push(<td key={columns.length} className='trigger_symptom'>{d.dataId}</td>);
                columns.push(<td key={columns.length} className='trigger_symptom_model'>{d.name} [{d.modelId}]</td>);
                rows.push(<tr key={rows.length} className='symptom_row'>{columns}</tr>);
                columns = [];
            })
        }


        tables.push(
            <table key={tables.length} align="left" className='trigger_table' >
                <tbody>
                    <tr><th className='detect_seperator' colSpan='4' > All Triggers </th></tr>
                    <tr className='symptom_row'>
                        <th className='symptom_title' > Trigger  </th>
                        <th className='symptom_title' > irAE Condition Model </th>
                    </tr>
                    {rows}
                </tbody>

            </table>
        );
        return tables;
    }
}

function getDetectTable(model, questions, keyOffset = 0) {
    let rows = [];

    if (isArrayValid(model.triggerSymptoms)) {
        let symptoms = model.triggerSymptoms;
        for (let x = 0; x < symptoms.length; x++) {
            let symptom = symptoms[x];
            let links = getSymptonQuestionList(symptom, questions);
            let columns = [];
            columns.push(<td key={columns.length} className='detect_trigger_symptom'>PRO-trigger</td>);
            columns.push(<td key={columns.length} className='detect_trigger_symptom_detail'>{symptom.dataId}</td>);
            columns.push(<td key={columns.length} className='detect_trigger_symptom_detail_center'>{links.numQuestions}</td>);
            columns.push(<td key={columns.length} className='detect_trigger_symptom_detail'>{links.list}</td>);
            columns.push(<td key={columns.length} className='detect_trigger_symptom_detail'></td>);
            columns.push(<td key={columns.length} className='detect_trigger_symptom_detail_center'></td>);
            columns.push(<td key={columns.length} className='detect_trigger_symptom_detail'></td>);
            columns.push(<td key={columns.length} className='detect_trigger_symptom_detail'></td>);
            rows.push(<tr key={rows.length + keyOffset} className='symptom_row'>{columns}</tr>);
        }

    }

    if (isArrayValid(model.primarySymptoms)) {
        let symptoms = model.primarySymptoms;
        for (let x = 0; x < symptoms.length; x++) {
            let symptom = symptoms[x];
            let links = getSymptonQuestionList(symptom, questions);
            let columns = [];
            columns.push(<td key={columns.length} className='detect_symptom'>PRO-primary </td>);
            columns.push(<td key={columns.length} className='symptom_detail'>{symptom.dataId}</td>);
            columns.push(<td key={columns.length} className='symptom_detail_center'>{links.numQuestions}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'>{links.list}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            columns.push(<td key={columns.length} className='symptom_detail_center'>{symptom.weight}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);

            rows.push(<tr key={rows.length + keyOffset} className='symptom_row'>{columns}</tr>);
        }

    }

    if (isArrayValid(model.associatedSymptoms)) {
        let symptoms = model.associatedSymptoms;
        for (let x = 0; x < symptoms.length; x++) {
            let symptom = symptoms[x];
            let links = getSymptonQuestionList(symptom, questions);
            let columns = [];
            columns.push(<td key={columns.length} className='detect_symptom'>PRO-associated </td>);
            columns.push(<td key={columns.length} className='symptom_detail'>{symptom.dataId}</td>);
            columns.push(<td key={columns.length} className='symptom_detail_center'>{links.numQuestions}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'>{links.list}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            columns.push(<td key={columns.length} className='symptom_detail_center'>{symptom.weight}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            rows.push(<tr key={rows.length + keyOffset} className='symptom_row'>{columns}</tr>);
        }
    }


    if (isArrayValid(model.clinicalModifiers)) {
        let symptoms = model.clinicalModifiers;
        for (let x = 0; x < symptoms.length; x++) {
            let symptom = symptoms[x];
            let links = getSymptonQuestionList(symptom, questions);
            let columns = [];
            columns.push(<td key={columns.length} className='detect_symptom'>PRO-modifiers </td>);
            columns.push(<td key={columns.length} className='symptom_detail'>{symptom.dataId}</td>);
            columns.push(<td key={columns.length} className='symptom_detail_center'>{links.numQuestions}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'>{links.list}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            columns.push(<td key={columns.length} className='symptom_detail_center'>{symptom.weight}</td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            columns.push(<td key={columns.length} className='symptom_detail'></td>);
            rows.push(<tr key={rows.length + keyOffset} className='symptom_row'>{columns}</tr>);
        }

    }

    return rows;
}

function getSymptonQuestionList(symptom, questions) {

    let questionIdList = [];
    let links = getDataTypeQuestionLinks(symptom, questions);
    if (isArrayValid(links)) {
        let found = [];
        for (let index = 0; index < links.length; index++) {
            let link = links[index];
            if (link.question) {
                if (!found.includes(link.question.questionId)) {
                    found.push(link.question.questionId);
                    questionIdList.push(<li key={questionIdList.length} className='question_id_list_item'>{link.question.questionId}</li>);
                }

            } else {
                questionIdList.push(<li key={questionIdList.length} className='question_id_list_item'>none</li>);
            }
        }
    }

    return { list: <ul className='question_id_list'>{questionIdList}</ul>, numQuestions: questionIdList.length };
}


class SymptomsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            questions: [],
            progress: null
        };
    }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.loadContent();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;

        self.setState({ progress: <CircularProgress size={20} />, error: null });

        let promises = [];
        promises.push(loadQuestions(appContext, { dependencies: true }));
        promises.push(loadModels(appContext, { dependencies: true }));
        Promise.all(promises).then((response) => {
            let questions = response[0];
            let models = response[1];
            self.setState({ questions, models, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });

    }

    render() {
        let self = this;
        let { models, questions, progress } = self.state;

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td colSpan="2" align='right'>
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    {getDetectModelReport(models, questions)}
                </td>
            </tr>
        </tbody>
        </table>;
    }
}

export default SymptomsReport;
