import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    RadioGroup,
    Radio,
    Button,
    MuiThemeProvider,
    LinearProgress
} from '@material-ui/core/';
import { getTheme } from '@apricityhealth/web-common-lib/themes/manifest';
import {
    isArrayValid,
    addUniqueValue,
    removeValue
} from '../utils/Utils';
import {
    translateContent,
    translateText
} from '@apricityhealth/web-common-lib/utils/Services';
const AWS_LANG_CODES = {
    'Arabic': 'ar',
    'Chinese (Simplified)': 'zh',
    'Chinese (Traditional)': 'zh-tw',
    'Czech ': 'cs',
    'Danish ': 'da',
    'Dutch ': 'nl',
    'Finnish ': 'fi',
    'French ': 'fr',
    'German ': 'de',
    'Hebrew ': 'he',
    'Indonesian ': 'id',
    'Italian': 'it',
    'Japanese ': 'ja',
    'Korean ': 'ko',
    'Polish': 'pl',
    'Portuguese ': 'pt',
    'Russian ': 'ru',
    'Spanish ': 'es',
    'Swedish': 'sv',
    'Turkish ': 'tr'
}

class TranslationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: props.content,
            dialog: null,
            contentSet: '*',
            categories: [],
            targetLangauges: []
        };
    }
    translate() {
        const self = this;
        self.setState({ dialog: null, progress: <LinearProgress /> });
        const { appContext } = this.props;
        let { content, contentSet, targetLangauges, categories } = this.state;

        if (isArrayValid(content)) {
            translateContent(appContext, { categories: categories.join(','), contentSet, targetLanguageCode: targetLangauges.join(',')} ).then(() => {
                self.setState({ progress: null });
                self.onDone();
            }).catch((error) => {
                self.setState({ progress: error.message });
            });
        } else {
            translateText(appContext, { textId: content.textId, targetLanguageCode: targetLangauges.join(',')} ).then(() => {
                self.setState({ progress: null });
                self.onDone();
            }).catch((error) => {
                self.setState({ progress: error.message });
            });
        }

    }

    confirmTranslate() {
        console.log(`confirmTranslate`);
        const self = this;

        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}

                >
                    <DialogTitle>Translate Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>This will translate the selected content. Are you sure?<br />Please note this is an async procesess and and will update the database as soon as it is complete.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.translate(); }} >Yes</Button>,
                  <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.cancel(); }}>No</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }


    cancel() {
        this.setState({ dialog: null });

    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        const { onDone } = this.props;
        const { content } = this.state;
        if (onDone)
            onDone(content);
    }


    changeContentSet(event) {
        this.setState({ contentSet: event.target.value });
    };

    changeLanguage(event, name) {
        let { targetLangauges } = this.state;
        if (event.target.checked)
            addUniqueValue(targetLangauges, name);
        else
            removeValue(targetLangauges, name);

        this.setState({ targetLangauges });
    };

    changeCategory(event, name) {
        let { categories } = this.state;
        if (event.target.checked)
            addUniqueValue(categories, name);
        else
            removeValue(categories, name);
        this.setState({ categories });
    };

    buildCategorySelectionList() {
        let { content, categories } = this.state;

        if (isArrayValid(content)) {
            let categoryItems = [];
            if (isArrayValid(content)) {
                for (let j = 0; j < content.length; j++) {
                    let text = content[j];
                    if (text.category && !categoryItems.includes(text.category))
                        categoryItems.push(text.category);
                }
            }
            var items = [];
            if (isArrayValid(categoryItems)) {
                for (var i = 0; i < categoryItems.length; ++i) {
                    const cat = categoryItems[i];
                    items.push(
                        <FormControlLabel
                            key={cat}
                            control={
                                <Checkbox key={i} checked={categories[cat]} onChange={(e) => this.changeCategory(e, cat)} value={cat} />
                            }
                            label={cat}
                        />);
                }
            }

            return <FormControl  >
                <FormLabel >Categories</FormLabel>
                <FormGroup>
                    {items}
                </FormGroup>
            </FormControl>;
        } else {
            return null;
        }
    }


    buildLanguageSelectionList() {
        let self = this;
        let { targetLangauges } = this.state;
        let items = [];
        for (let name in AWS_LANG_CODES) {
            let lang = AWS_LANG_CODES[name];
            let value = targetLangauges[name];
            let check = <Checkbox
                key={name}
                onChange={(e) => self.changeLanguage(e, lang)}
                checked={value} value={lang} />;
            items.push(
                <FormControlLabel
                    control={check}
                    label={name}
                />);
        }
        return <FormControl  >
            <FormLabel >Languages</FormLabel>
            <FormGroup>
                {items}
            </FormGroup>
        </FormControl>;
    }

    buildContentSelection() {
        let { content, contentSet } = this.state;

        if (isArrayValid(content)) {

            return <FormControl  >
                <FormLabel >Content Set</FormLabel>
                <RadioGroup
                    aria-label="Content Selection"
                    name="content"
                    value={contentSet}
                    onChange={(e) => this.changeContentSet(e)}
                >
                    <FormControlLabel value="*" control={<Radio />} label="All Content" />
                    <FormControlLabel value="changed" control={<Radio />} label="Changed Only" />
                </RadioGroup>
            </FormControl>;
        } else {
            return null;
        }
    }

    render() {
        let self = this;
        let { dialog, content, targetLangauges, categories, progress } = this.state;
        let { appContext } = this.props
        let disableTranslate = true;

        if (isArrayValid(content)) {
            disableTranslate = targetLangauges.length === 0 || categories.length === 0;
        } else {
            disableTranslate = targetLangauges.length === 0;
        }

        const theme = getTheme(appContext.state.theme);
        let categorySelection = self.buildCategorySelectionList();

        let languageSelection = self.buildLanguageSelectionList();

        let contentSetSelection = self.buildContentSelection();

        let contentLabel = null
        if (isArrayValid(content)) {
            contentLabel = <FormLabel style={styles.label} >Select the categories to be tranlated</FormLabel>
        } else {
            contentLabel = <FormLabel style={styles.label} >The following text will be translated: <div style={{ color: 'blue', marginTop: '15px', fontSize: '14px' }}>'{content.text.substring(0, 200)}...'</div></FormLabel>
        }

        let optionsLabel = null
        if (isArrayValid(content)) {
            optionsLabel = <FormLabel style={styles.label} >Select if you want all content translated or only changed content and then select one or more languages.</FormLabel>
        } else {
            optionsLabel = <FormLabel style={styles.label} >Select one or more languages.</FormLabel>
        }

        // let translateButton = <Button
        //     style={{ textTransform: "none" }}
        //     onClick={() => self.confirmTranslate()}>
        //     Translate Content
        //     <TranslateIcon />
        // </Button>;

        return (
            <MuiThemeProvider theme={theme}>
                <Dialog
                    maxWidth={'sm'}
                    fullWidth={true}
                    model="false"
                    open={true}
                >
                    <DialogTitle>Content Translation Dialog</DialogTitle>
                    <div style={{ margin: '10px' }}>
                        {progress}
                    </div>
                    {contentLabel}
                    <div style={{ marginTop: '10px' }} />
                    {optionsLabel}
                    <div style={{ marginTop: '10px' }} />
                    <DialogContent>
                        <table style={styles.table}>
                            <tbody>
                                <tr><th><div style={{ marginTop: '10px' }} /></th></tr>
                                <tr>
                                    <td valign='top'>{categorySelection}</td>
                                    <td valign='top'>{contentSetSelection}</td>
                                    <td valign='top'>{languageSelection}</td>
                                </tr>
                            </tbody>
                            {dialog}
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={self} disabled={disableTranslate} style={styles.button} onClick={(e) => { self.confirmTranslate() }}>Translate</Button>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                    </DialogActions>

                </Dialog>

            </MuiThemeProvider >

        )
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    },
    table: {
        valign: 'top',
    },
    label: {
        margin: 10,
        marginLeft: 25
    },
    linearColorPrimary: {
        backgroundColor: '#b2dfdb',
    },
    linearBarColorPrimary: {
        backgroundColor: '#00695c',
    },
};

TranslationDialog.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(TranslationDialog);
