export function getModelsForDataIds( appContext, dataIds ) {
    const store = appContext.stores.DataTypesStore;

    let models = [];
    let links = store.getDataTypeLinks(dataIds);
    for(let i=0;i<links.length;++i) {
        let dataLinks = links[i];
        for(let k=0;k<dataLinks.links.length;++k) {
            let link = dataLinks.links[k];
            if ( link.model !== undefined ) {
                if ( !models.find((e) => e.modelId === link.model.modelId) )
                    models.push( link.model );
            }
        }
    }
    console.log("getModelsForDataIds:", models );
    return models;
}

export function importDataTypesToModel( appContext, model, updateDesriptions = true ) {
    const store = appContext.stores.DataTypesStore;
    return new Promise((resolve, reject) => {
        try {
            let types = store.getDataTypes();
            function updateValues( symptoms, types ) {
                if ( Array.isArray(symptoms )) {
                    for(let i=0;i<symptoms.length;++i) {
                        let symptom = symptoms[i];
                        if (! symptom.dataId ) continue;
                        let dataType = types.find((v) => v.dataId === symptom.dataId );
                        if (! dataType ) {
                            return reject( new Error(`Failed to find dataType ${symptom.dataId}`) );
                        }
                        if ( symptom.tupleIndex === undefined || symptom.tupleIndex < 0 || symptom.tupleIndex >= dataType.tupleDescriptions.length ) {
                            return reject( new Error(`Invalid tupleIndex for symptom ${symptom.name}`) );
                        }
                        let tuple = dataType.tupleDescriptions[symptom.tupleIndex];
                        symptom.values = tuple.valueDescriptions;
                        symptom.classType = tuple.classType;
                        if ( updateDesriptions && tuple.description )
                            symptom.description = tuple.description;
                    }
                }
            }
            function updateTriggers( triggers, types ) {
                if ( Array.isArray(triggers )) {
                    for(let i=0;i<triggers.length;++i) {
                        let trigger = triggers[i];
                        if (! trigger.dataId ) continue;
                        let dataType = types.find((v) => v.dataId === trigger.dataId );
                        if (! dataType ) {
                            return reject( new Error(`Failed to find dataType ${trigger.dataId}`) );
                        }
                        if ( trigger.tupleIndex === undefined || trigger.tupleIndex < 0 || trigger.tupleIndex >= dataType.tupleDescriptions.length ) {
                            return reject( new Error(`Invalid tupleIndex for symptom ${trigger.name}`) );
                        }
                        let tuple = dataType.tupleDescriptions[trigger.tupleIndex];
                        trigger.classType = tuple.classType;
                        if ( updateDesriptions && tuple.description )
                            trigger.description = tuple.description;
                    }
                }
            }

            updateTriggers( model.triggerSymptoms, types );
            updateValues( model.primarySymptoms, types );
            updateValues( model.associatedSymptoms, types );
            updateValues( model.positiveSymptoms, types );
            updateValues( model.negativeSymptoms, types );
            updateValues( model.clinicalModifiers, types );
            updateValues( model.laboratory, types );

            resolve(model);
        } catch(err) {
            console.error("importDataTypesToModel error:", err );
            reject(err);
        }
    })
}

// update this model's output data type
export function updateModelDataType(appContext, model) {
    if ( Array.isArray(model) ) {
        return Promise.all( model.map((e) => updateModelDataType(appContext,e)) );
    }

    const store = appContext.stores.DataTypesStore;
    return new Promise((resolve, reject) => {
        if (!model.dataId)
            return resolve();

        // firstly, pull in the latest values from the data types without updating the descriptions in the symptoms/triggers
        importDataTypesToModel(appContext, model, false).then(() => {
            let types = store.getDataTypes();
            let modifiedTypes = [];
            let modelDataType = types.find((v) => v.dataId === model.dataId);
            if (modelDataType) {
                modifiedTypes.push(modelDataType);

                console.log(`Updating model data type ${model.dataId}...`);
                let tupleUpdates = [];
                function updateTupleDescriptions(dataType, symptoms, types) {
                    if (Array.isArray(symptoms)) {
                        for (let i = 0; i < symptoms.length; ++i) {
                            let symptom = symptoms[i];
                            if (!symptom.dataId) continue;
                            let sourceType = types.find((v) => v.dataId === symptom.dataId);
                            if (!sourceType) {
                                console.error(`Failed to find dataId: ${symptom.dataId}`);
                                continue;
                            }
                            let sourceTuple = sourceType.tupleDescriptions[symptom.tupleIndex];

                            let newTupleIndex = dataType.tupleDescriptions.length;
                            if (symptom.modelTupleIndex !== newTupleIndex) {
                                tupleUpdates.push({ oldIndex: symptom.modelTupleIndex, newIndex: newTupleIndex })
                                symptom.modelTupleIndex = newTupleIndex;
                            }
                            symptom.values = sourceTuple.valueDescriptions;
                            dataType.tupleDescriptions.push({
                                classType: sourceTuple.classType,
                                dataType: sourceTuple.dataType,
                                description: symptom.description,
                                index: dataType.tupleDescriptions.length,
                                orgin: sourceTuple.origin,
                                valueDescriptions: sourceTuple.valueDescriptions
                            });
                        }
                    }
                }

                modelDataType.description = model.description;
                modelDataType.tupleDescriptions = [];
                updateTupleDescriptions(modelDataType, model.primarySymptoms, types);
                updateTupleDescriptions(modelDataType, model.associatedSymptoms, types);
                updateTupleDescriptions(modelDataType, model.positiveSymptoms, types);
                updateTupleDescriptions(modelDataType, model.negativeSymptoms, types);
                updateTupleDescriptions(modelDataType, model.clinicalModifiers, types);
                updateTupleDescriptions(modelDataType, model.laboratory, types);

                if (tupleUpdates.length > 0) {
                    store.updateTupleIndexes(model.dataId, tupleUpdates);
                }
            }
            else {
                console.error(`Failed to find model data type ${model.dataId}`);
            }
            if (modifiedTypes.length > 0) {
                let promises = [];
                for(let i=0;i<modifiedTypes.length;++i) {
                    let type = modifiedTypes[i];
                    promises.push( store.saveDataType(type, type.planId) );       
                }
                return Promise.all(promises);
            }
            else
                return Promise.resolve();
        }).then(() => {
            resolve(model);
        }).catch((err) => {
            console.error("updateModelDataType:", err);
            reject(err);
        })
    })
}
