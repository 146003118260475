import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    CircularProgress, IconButton, Typography, Badge, TextField, FormControl, FormGroup,
    FormLabel, FormControlLabel, FormHelperText, Checkbox, Snackbar, MenuItem, Select, InputLabel,
    Dialog, DialogActions, Button, DialogContent, Tooltip, LinearProgress,
} from '@material-ui/core/';
import { v4 as uuidv4 } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import { StylesProvider } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import {
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';

import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

import { isAdminUser } from '../utils/Utils';

import BackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import CodeIcon from '@material-ui/icons/Code';
import CheckCircle from '@material-ui/icons/CheckCircle';
import SaveAsIcon from '@material-ui/icons/SaveAlt';

import QuestionsComponent from "./QuestionsComponent";
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import SaveToPlanDialog from "../dialogs/SaveToPlanDialog";

import { updateModelDataType } from '../utils/Models';
import QuestionUtils from '../utils/QuestionUtils';
import createIdFromText from "../utils/CreateIdFromText";
import PubSub from 'pubsub-js'

import 'antd/dist/antd.css';
import '../styles/tableedit.css';

const Sequential = require('promise-sequential');
const TYPE_TEMPLATES = require('../data/TypeTemplates.json');

export class SurveyModelView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            language: 'en-us', //fix to english for now
            error: null,
            modelId: this.props.location && this.props.location.state && this.props.location.state.modelId ? this.props.location.state.modelId : null,
            dialog: null,
            allModels: [],
            allQuestions: [],
            allDataTypes: [],
            texts: {},
            showAnswers: false,
            showUpdateProgress: false,
            updateProgress: [],
            blueprint: {
                model: null,
                originalModel: null,
                modelDataType: null,
                originalModelDataType: null,
                modelQuestions: null,
                originalModelQuestions: [],
                deletedQuestions: []
            },
        };
    }

    componentDidMount() {
        this.loadContent();
        this.planToken = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                //go back to the main models page
                this.setState({ dialog: <Redirect to={{ pathname: "/survey_models" }} /> });
            }
        });

        this.dataToken = PubSub.subscribe('STORE_UPDATE', () => {
            this.updateTypes();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.planToken);
        PubSub.unsubscribe(this.dataToken);
    }


    updateTypes() {
        const { appContext } = this.props;
        const { plan } = appContext.state;
        if (plan) {
            this.setState({ error: null });
            try {
                const store = appContext.stores.DataTypesStore;
                let allModels = store.getModels().filter((e) => e.category === 'survey');
                let allQuestions = store.getQuestions();
                let allDataTypes = store.getDataTypes();
                let texts = store.getTexts();

                this.setState({ allQuestions, allModels, texts, allDataTypes });

            } catch (err) {
                this.setState({ models: [], error: getErrorMessage(err) })
            };

        }
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;
        this.questionUtils = new QuestionUtils(appContext);

        const store = appContext.stores.DataTypesStore;
        let allModels = store.getModels().filter((e) => e.category === 'survey');
        let allQuestions = store.getQuestions();
        let allDataTypes = store.getDataTypes();
        let texts = store.getTexts();

        this.setState({ allQuestions, allModels, texts, allDataTypes }, () => self.initializeBlueprint());
    }


    initializeBlueprint() {
        let { allQuestions, allModels, allDataTypes, blueprint, modelId, updateProgress } = this.state;
        blueprint = {
            model: null,
            originalModel: null,
            modelDataType: null,
            originalModelDataType: null,
            modelQuestions: null,
            originalModelQuestions: [],
            deletedQuestions: []
        };
        let name = ``
        let description = ""
        let modelDataType = null;
        let modelDataId = "newSurveyModel";

        let model = null;
        if (modelId) {
            //try to find the model
            model = allModels.find(model => model.modelId === modelId);
            if (model && model.modelId) {
                name = model.name;
                modelDataId = model.dataId;
                modelId = model.modelId;
                description = model.description;
                blueprint.originalModel = JSON.parse(JSON.stringify(model))// copy of the original to update any changes
                blueprint.model = JSON.parse(JSON.stringify(model));
            }
        }

        if (!model) { // no model found.. make a new one
            modelId = 'newSurveyModel'
            model = JSON.parse(JSON.stringify(TYPE_TEMPLATES.proModels["surveyModel"]));
        }
        blueprint.model = model;

        modelDataType = allDataTypes.find((type) => type.dataId === modelDataId);
        if (modelDataType && modelDataType.dataId) {
            blueprint.originalModelDataType = JSON.parse(JSON.stringify(modelDataType));
            blueprint.modelDataType = JSON.parse(JSON.stringify(modelDataType));
        } else {
            modelDataType = {
                dataId: modelDataId,
                name,
                description,
                category: 'model',
                tupleDescriptions: [],
                valueDescriptions: []
            }
            blueprint.modelDataType = modelDataType;
        }

        let surveyQuestions = this.gatherModelQuestions(allQuestions, allDataTypes, model.primarySymptoms);
        blueprint.originalModelQuestions = JSON.parse(JSON.stringify(surveyQuestions));
        blueprint.modelQuestions = JSON.parse(JSON.stringify(surveyQuestions));
        updateProgress = [];
        this.setState({ progress: null, blueprint, modelId, validated: false, updateProgress, showUpdateProgress: false, });
    }

    gatherModelQuestions(allQuestions, allDataTypes, symptoms) {
        let modelQuestions = [];

        for (let index = 0; index < symptoms.length; index++) {
            const modelSymptom = symptoms[index];
            let symptom = null;
            let originalSymptom = null;
            let originalQuestion = null;
            let dataType = allDataTypes.find((type) => type.dataId === modelSymptom.dataId);
            if (dataType) {
                symptom = JSON.parse(JSON.stringify(dataType));
                originalSymptom = JSON.parse(JSON.stringify(dataType));
            } else {
                dataType = {
                    category: 'symptom',
                    tupleDescriptions: [],
                    valueDescriptions: []
                }
                symptom = JSON.parse(JSON.stringify(dataType));
            }

            let question = allQuestions.find((question) => question.dataId === modelSymptom.dataId);
            if (question) {
                originalQuestion = JSON.parse(JSON.stringify(question));
                symptom.dataId = question.dataId;
                symptom.name = question.name;
                symptom.description = question.description;

            } else {
                let questionId = modelSymptom.dataId + "Question";
                question = {
                    question: [{ text: `[[${questionId}]]`, condition: '' }],
                    questionId,
                    answers: [],
                    dataId: null,
                    andFlagConditions: [],
                    orFlagConditions: [],
                    addFlags: [],
                    removeFlags: [],
                    mapping: [],
                    bounds: [],
                    baseline: false,
                    order: modelQuestions.length + 1
                };
            }


            this.questionUtils.unlocalizeQuestion(question); // very important that we convert each question to regular text
            modelQuestions.push({ question, originalQuestion, order: index + 1, symptom, originalSymptom, key: uuidv4() });
        };
        return modelQuestions;
    }

    onCloseView() {
        this.setState({ dialog: <Redirect to="/survey_models" /> })
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    saveAll(callback, planId = null) {
        const { appContext } = this.props;
        const { blueprint } = this.state;
        if (!planId && blueprint.model.planId && blueprint.model.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(blueprint.model.planId, this.saveAll.bind(this, callback));
        }

        this.setState({ progress: <CircularProgress size={20} />, showUpdateProgress: true, updateProgress: [], error: null }, () => {
            let promises = [];
            promises.push(() => this.validateModel());
            promises.push(() => this.deleteDeletedQuestions());
            promises.push(() => this.saveDataTypes(planId));
            promises.push(() => this.saveQuestions(planId));
            promises.push(() => this.saveModel(planId));
            Sequential(promises).then(() => {
                console.debug(`save done `)
                callback();
            }).catch((err) => {
                console.error(`error `, getErrorMessage(err))
                this.setState({ progress: null, error: getErrorMessage(err), showUpdateProgress: false });
            })
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null })
    }

    saveToPlan(callback) {
        const { blueprint } = this.state;
        let dialog = <SaveToPlanDialog appContext={this.props.appContext} planId={blueprint.model.planId} onCancel={this.onCloseDialog.bind(this)}
            onDone={({ plan, move }) => {
                console.debug("plan:", plan, ", move: ", move);

                this.saveAll(() => {
                    if (move) {
                        this.setState({ confirmDeleteDataTypes: true, confirmDeleteQuestions: true, confirmDeleteModel: true }, () => {
                            this.deleteAll(callback)
                        })
                    } else {
                        callback();
                    }
                }, plan.planId);
            }} />;
        this.setState({ dialog });
    }

    saveQuestions = async (planId = null) => {
        const { blueprint } = this.state;
        let promises = []

        let model = blueprint.model;
        if (isArrayValid(blueprint.modelQuestions)) {
            blueprint.modelQuestions.forEach(modelQuestion => {
                if (modelQuestion.question) {
                    //add any specific info for surveys
                    modelQuestion.question.category = model.name;
                    if (!modelQuestion.originalQuestion)
                        modelQuestion.originalQuestion = JSON.parse(JSON.stringify(modelQuestion.question));
                    let question = JSON.parse(JSON.stringify(modelQuestion.question));       // clone a copy, so we don't see the localized strings in the view
                    promises.push(this.questionUtils.saveQuestion(question, modelQuestion.originalQuestion, false, planId, true));
                }
            });
        }
        this.updateProgress(`Saving ${promises.length} survey questions...`);
        let result = await Promise.all(promises);
        console.debug(`all survey questions saved `)
        return result;
    }


    saveModel = async (planId = null) => {
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        let { blueprint } = this.state;
        let model = blueprint.model;
        if (!model.triggerSymptoms) model.triggerSymptoms = [];//validation needs this
        if (!model.associatedSymptoms) model.associatedSymptoms = [];//validation needs this
        if (!model.positiveSymptoms) model.positiveSymptoms = [];//validation needs this
        if (!model.negativeSymptoms) model.negativeSymptoms = [];//validation needs this
        if (!model.clinicalModifiers) model.clinicalModifiers = [];//validation needs this
        if (!model.laboratory) model.laboratory = [];//validation needs this

        let originalModel = blueprint.originalModel;
        let deleteModel = Promise.resolve();
        //only delete if it changed.. save some processing and potential errors
        if (originalModel && originalModel.modelId) {
            if (model.modelId !== originalModel.modelId) {
                this.updateProgress("Deleting model...");
                deleteModel = this.deleteModel();
            }
        }
        let results = await deleteModel;
        this.updateProgress("Updating model data types...");
        results = await updateModelDataType(appContext, model);
        console.debug(`Survey Save model `, model)
        this.updateProgress("Saving model...");
        results = await store.saveModel(model, planId);
        console.debug(`Survey Save model complete `, model)
        return results;
    }

    saveDataTypes(planId = null) {
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        let { blueprint } = this.state;

        let promises = [];
        let modelDataType = blueprint.modelDataType;
        let originaModelDataType = blueprint.originaModelDataType;
        let modelQuestions = blueprint.modelQuestions;
        //delete the original data type if it changed
        if (originaModelDataType && modelDataType.dataId !== originaModelDataType.dataId) {
            this.updateProgress("Deleting model data type...");
            promises.push(() => store.deleteDataType(originaModelDataType.dataId, originaModelDataType.planId));
        }

        //Save the model data type
        console.debug(`Survey Save model data type `, blueprint.modelDataType)
        promises.push(() => this.saveDataType(blueprint.modelDataType, planId));

        //save all the question data types
        if (isArrayValid(modelQuestions)) {
            modelQuestions.forEach(questionModel => {
                let symptom = questionModel.symptom;
                let originalSymptom = questionModel.originalSymptom;
                if (originalSymptom && symptom.dataId !== originalSymptom.dataId) {
                    this.updateProgress("Deleting question data type...");
                    promises.push(() => store.deleteDataType(originalSymptom.dataId, originalSymptom.planId));
                }

                if (symptom && symptom.dataId) {
                    symptom.parentId = modelDataType.dataId;
                    promises.push(() => this.saveDataType(symptom, planId));
                }
                let { question, originalQuestion } = questionModel;

                if (originalQuestion && originalQuestion.multiSelect === true) {
                    //delete the old data types
                    let { answers } = originalQuestion;
                    if (isArrayValid(answers)) {
                        answers.forEach(answer => {
                            if (answer.dataId)
                                promises.push(() => store.deleteDataType(answer.dataId, planId));

                        });
                    }
                }
                if (question.multiSelect === true) {
                    let { answers } = question;
                    if (isArrayValid(answers)) {
                        answers.forEach(answer => {
                            if (answer.dataId) {
                                let dataType = {
                                    name: getAnswerText(answer),
                                    description: getAnswerText(answer),
                                    dataId: answer.dataId,
                                    parentId: symptom.dataId,
                                    category: 'symptom',
                                    tupleDescriptions: [],
                                    valueDescriptions: []
                                }
                                promises.push(() => this.saveDataType(dataType, planId));
                            }

                        });
                    }

                }
            });
        }
        return Sequential(promises);
    }


    saveDataType(dataType, planId = null) {
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        console.debug(`Survey Save data type `, dataType)

        this.updateProgress("Update tuple descriptions...")
        this.updateTupleDescriptions(dataType);
        console.debug(`Survey updateTupleDescriptions `, dataType)
        if (isArrayValid(dataType.tupleDescriptions)) {
            let tupleUpdates = [];
            for (let i = 0; i < dataType.tupleDescriptions.length; ++i) {
                let tupleDesc = dataType.tupleDescriptions[i];
                if (tupleDesc.oldIndex !== tupleDesc.index) {
                    tupleUpdates.push({ oldIndex: tupleDesc.oldIndex, newIndex: tupleDesc.index });
                }
            }

            if (tupleUpdates.length > 0) {
                try {
                    this.updateProgress("Updating tuple indexes...");
                    store.updateTupleIndexes(dataType.dataId, tupleUpdates);
                } catch (err) {
                    throw err;
                }
            }
        }
        this.updateProgress(`Saving data type ${dataType.dataId}...`);
        return store.saveDataType(dataType, planId);
    }

    updateTupleDescriptions(dataType) {
        let tupleDescriptions = require('../data/DataTypeTupleDescriptionTemplates.json');
        let tupleDescriptionItem = tupleDescriptions.tupleDescriptionItems.find((tupleDescriptionItem) => {
            return (tupleDescriptionItem.id === dataType.category)
        })

        if (tupleDescriptionItem) {
            tupleDescriptionItem = JSON.stringify(tupleDescriptionItem)
            tupleDescriptionItem = tupleDescriptionItem.replace(/{{name}}/g, dataType.name)
            tupleDescriptionItem = JSON.parse(tupleDescriptionItem)
            dataType.tupleDescriptions = tupleDescriptionItem.tupleDescriptions
        }
    }

    deleteAll(callback) {
        let { confirmDeleteDataTypes, confirmDeleteQuestions, confirmDeleteModel } = this.state;
        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        let promises = [];
        if (confirmDeleteDataTypes)
            promises.push(() => this.deleteDataTypes());
        if (confirmDeleteQuestions) {
            promises.push(() => this.deleteDeletedQuestions());
            promises.push(() => this.deleteQuestions());
        }
        if (confirmDeleteModel)
            promises.push(() => this.deleteModel())

        Sequential(promises).then(() => {
            this.setState({ progress: null }, callback);
        }).catch((err) => {
            this.setState({ progress: null, error: getErrorMessage(err) });
        })

    }

    deleteQuestions() {
        let { blueprint } = this.state;
        let promises = [];
        if (blueprint && isArrayValid(blueprint.originalModelQuestions)) {
            blueprint.originalModelQuestions.forEach(questionModel => {
                let originalQuestion = questionModel.originalQuestion;
                if (originalQuestion && originalQuestion.questionId) {
                    promises.push(this.questionUtils.deleteQuestion(originalQuestion));
                }
            });
        }
        if (promises.length > 0) {
            this.updateProgress(`Deleting ${promises.length} questions...`)
        }
        return Promise.all(promises);
    }

    deleteDeletedQuestions() {
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        let { blueprint } = this.state;

        let promises = [];
        if (blueprint && isArrayValid(blueprint.deletedQuestions)) {
            blueprint.deletedQuestions.forEach(questionModel => {
                let originalQuestion = questionModel.originalQuestion;
                if (originalQuestion && originalQuestion.questionId) {
                    promises.push(this.questionUtils.deleteQuestion(originalQuestion));
                }
                let originalSymptom = questionModel.originalSymptom;
                if (originalSymptom && originalSymptom.dataId) {
                    promises.push(store.deleteDataType(originalSymptom.dataId, originalSymptom.planId));
                }
            });
        }
        if (promises.length > 0) {
            this.updateProgress(`Deleting ${promises.length} questions...`)

        }
        return Promise.all(promises);

    }

    deleteDataTypes() {
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        let { blueprint } = this.state;
        let promises = [];

        //delete the model data type
        if (blueprint && blueprint.originaModelDataType && blueprint.originaModelDataType.dataId) {
            promises.push(store.deleteDataType(blueprint.originaModelDataType.dataId, blueprint.originaModelDataType.planId));
        }
        //delete all the question data types
        if (blueprint && isArrayValid(blueprint.originalModelQuestions)) {
            blueprint.originalModelQuestions.forEach(questionModel => {
                let originalSymptom = questionModel.originalSymptom;
                if (originalSymptom && originalSymptom.dataId) {
                    promises.push(store.deleteDataType(originalSymptom.dataId, originalSymptom.planId));
                }
            });
        }
        if (promises.length > 0) {
            this.updateProgress(`Deleting ${promises.length} data types...`)
        }
        return Promise.all(promises);

    }

    deleteModel() {
        const { appContext } = this.props;
        const store = appContext.stores.DataTypesStore;
        let { blueprint } = this.state;
        if (blueprint && blueprint.originalModel) {
            let model = blueprint.originalModel
            this.updateProgress("Deleting model...");
            return store.deleteModel(model.modelId, model.planId);
        } else {
            return Promise.resolve();
        }
    }

    confirmDeleteAll() {
        const self = this;
        let { confirmDeleteDataTypes, confirmDeleteModel, confirmDeleteQuestions } = this.state;
        self.setState({
            dialog: <ModelDeleteDialog
                confirmDeleteDataTypes={confirmDeleteDataTypes}
                confirmDeleteModel={confirmDeleteModel}
                confirmDeleteQuestions={confirmDeleteQuestions}
                onCancel={() => this.setState({ dialog: null })}
                onDone={(data) => this.setState({ dialog: null, ...data }, () => this.deleteAll(this.onCloseView.bind(this)))}
            />

        });
    }

    onShowCode() {
        let { blueprint } = this.state;
        this.setState({
            dialog: <JsonDialog
                dataType={blueprint}
                name={blueprint.model.name}
                collapsed={1}
                onDone={() => {
                    this.setState({ dialog: null })
                }} />
        });
    }

    print() {
        let { blueprint } = this.state;
        this.setState({ dialog: <Redirect to={{ pathname: `/print/model`, state: { modelId: blueprint.model.modelId, showTriggerSymptoms: false } }} push={true} /> })
    }


    updateName(name) {
        let { blueprint } = this.state;
        if (blueprint.model.name !== name) {
            let newId = createIdFromText(name);
            let modelId = newId + "Model";
            let surveyModelDataId = newId + "Model";

            blueprint.model.name = name;
            blueprint.model.modelId = modelId;
            blueprint.model.dataId = modelId;
            blueprint.model.modelDataId = modelId;

            blueprint.modelDataType.dataId = surveyModelDataId;
            blueprint.modelDataType.name = name;

            this.setState({ blueprint, modelId, validated: false });
        }
    }


    validateModel() {
        return new Promise((resolve, reject) => {
            let { blueprint } = this.state;
            let modelQuestions = blueprint.modelQuestions;

            let model = blueprint.model;
            if (!model.category) model.category = 'survey';
            model.primarySymptoms = []; //we are gonna rebuild this every time

            for (let index = 0; index < modelQuestions.length; index++) {
                const question = modelQuestions[index];
                if (question.question)
                    question.question.category = model.name;
                let symptomDataType = question.symptom;
                if (!symptomDataType) {
                    return reject(new Error("Question has no data type defined"));
                }
                //this may be a new question, if so them add the dataId to the primary symptoms. If not leave it alone it was just an edit
                model.primarySymptoms.push({
                    symptom: false,
                    inGroup: false,
                    inSession: true,
                    required: false,
                    triage: false,
                    useNAN: false,
                    weight: 1,
                    values: [],
                    dataId: symptomDataType.dataId,
                    description: symptomDataType.description,
                    tupleIndex: 0,
                    timeWindow: 0,
                    modelTupleIndex: model.primarySymptoms.length,
                })

                let answers = question.question.answers;
                if (isArrayValid(answers)) {
                    answers.forEach(answer => {
                        if (answer.dataId) {
                            model.primarySymptoms.push({
                                symptom: false,
                                inGroup: false,
                                inSession: true,
                                required: false,
                                triage: false,
                                useNAN: false,
                                weight: 1,
                                values: [],
                                dataId: answer.dataId,
                                description: getAnswerText(answer),
                                tupleIndex: 0,
                                timeWindow: 0,
                                modelTupleIndex: model.primarySymptoms.length,
                            })
                        }
                    });
                }
            }

            this.setState({ blueprint, validated: true, error: null }, resolve);
        })
    }

    updateProgress(progress) {
        let { updateProgress } = this.state;
        updateProgress.push(progress);
        this.setState({ updateProgress });
    }

    render() {
        let self = this;
        let { appContext } = this.props;
        const { progress, error, blueprint, dialog, showUpdateProgress, updateProgress } = this.state;

        let model = blueprint.model;
        let modelQuestions = blueprint.modelQuestions;
        let progressList = updateProgress.map((element, index) => { return <li key={index}>{element}</li> })
        const errors = appContext.stores.DataTypesStore.getErrors(`survey_models`).errors;
        const errorCount = model ? errors[model.modelId] ? errors[model.modelId].length : 0 : 0;
        let errorList = model ? errors[model.modelId] ? errors[model.modelId].map((element, index) => { return <li key={index}>{element}</li> }) : [] : [];

        return <StylesProvider styles={{ margin: 10 }}>
            <div className="page">
                <table width="99%" >
                    <tbody>
                        <tr align='top'>
                            <td colSpan='2' >
                                <div>
                                    <Tooltip title={"Back"}><IconButton onClick={() => this.setState({ dialog: <Redirect to="/survey_models" /> })}><BackIcon /></IconButton></Tooltip>
                                </div>

                            </td>
                            <td align="right"  >
                                <span style={{ color: 'red' }}>{error}</span>
                                <Tooltip title={'Save Survey'}><IconButton disabled={progress !== null} onClick={() => this.saveAll(this.onCloseView.bind(this))}>{progress || <SaveIcon />}</IconButton></Tooltip>
                                <Tooltip title='Save To Plan'><IconButton disabled={progress !== null} onClick={this.saveToPlan.bind(this, this.onCloseView.bind(this))}><SaveAsIcon /></IconButton></Tooltip>
                                <Tooltip title='Validate Survey'><IconButton onClick={() => {
                                    this.validateModel().then(() => {
                                        this.setState({ dialog: <Dialog open={true}><DialogContent>Model validated successfully!</DialogContent><DialogActions><Button onClick={this.onCloseDialog.bind(this)}>OK</Button></DialogActions></Dialog> });
                                    }).catch((err) => {
                                        this.setState({ dialog: <Dialog open={true}><DialogContent>{getErrorMessage(err)}</DialogContent><DialogActions><Button onClick={this.onCloseDialog.bind(this)}>OK</Button></DialogActions></Dialog> });
                                    })
                                }}><CheckCircle /></IconButton></Tooltip>
                                <Tooltip title={'Print Survey'}><IconButton disabled={progress !== null} onClick={() => this.print()}><PrintIcon /></IconButton></Tooltip>
                                <Tooltip title={'Reload Survey'}><IconButton disabled={progress !== null} onClick={() => this.loadContent()}><RefreshIcon /></IconButton></Tooltip>
                                <Tooltip title={'Delete Survey'}><IconButton disabled={progress !== null} onClick={() => this.confirmDeleteAll()}><DeleteIcon /></IconButton></Tooltip>
                                {isAdminUser(appContext) && <Tooltip title={'Show JSON'}><IconButton disabled={progress !== null} onClick={() => this.onShowCode()}><CodeIcon /></IconButton></Tooltip>}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* <MuiAccordion square expanded={true} >
                    <MuiAccordionSummary> */}
                <HtmlTooltip title={errorList} interactive>
                    <Badge
                        badgeContent={errorCount}
                        color='error'
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <Typography style={{ margin: 5, marginBottom: 10 }} className="section_title">Survey</Typography>
                    </Badge>
                </HtmlTooltip>
                {/* </MuiAccordionSummary>
                    <MuiAccordionDetails > */}
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr valign='top'>
                            <td >
                                {model && <div style={{ display: "flex", width: '100%' }} >
                                    <TextField
                                        style={{ margin: 5, width: '80%' }}
                                        required
                                        autoFocus
                                        label="Name:"
                                        value={model.name}
                                        onChange={(e) => {
                                            self.updateName(e.target.value);
                                        }}
                                    />
                                    <FormControl
                                        style={{ margin: 5, width: '20%' }}
                                    >
                                        <InputLabel>Category</InputLabel>
                                        <Select value={model.category} onChange={(e) => {
                                            model.category = e.target.value;
                                            self.setState({ model, validated: false });
                                        }}>
                                            <MenuItem value='pro'>PRO</MenuItem>
                                            <MenuItem value='lab'>Lab</MenuItem>
                                            <MenuItem value='triage'>Triage</MenuItem>
                                            <MenuItem value='survey'>Survey</MenuItem>
                                            <MenuItem value='symptom'>Symptom</MenuItem>
                                        </Select>
                                    </FormControl> </div>}
                            </td>
                            <td rowSpan='3' valign='top' style={{ width: '100%' }}>
                                {model &&
                                    <EditConditions
                                        style={{ width: '100%' }}
                                        appContext={appContext}
                                        conditions={model.condition}
                                        onChange={(condition) => {
                                            model.condition = condition;
                                            self.setState({ model, validated: false });
                                        }}
                                        name={<span style={{ fontSize: '14px' }}>Condition that triggers this survey</span>}
                                    />}
                            </td>
                        </tr>
                        <tr valign='top'>
                            <td >
                                {model && <TextField
                                    style={{ margin: 5, width: '500px' }}
                                    label="Description:"
                                    value={model.description}
                                    onChange={(e) => {
                                        let description = e.target.value;
                                        if (model.description !== description) {
                                            model.description = description;
                                            self.setState({ model, validated: false });
                                        }
                                    }}
                                />}
                            </td>
                        </tr>
                        <tr valign='top'>
                            <td >
                                {model && <TextField
                                    style={{ margin: 5, width: '500px' }}
                                    label="Instructions:"
                                    value={model.instructions}
                                    onChange={(e) => {
                                        let instructions = e.target.value;
                                        if (model.instructions !== instructions) {
                                            model.instructions = instructions;
                                            self.setState({ model, validated: false });
                                        }
                                    }}
                                />}
                            </td>
                        </tr>

                        <tr>
                            <td colSpan='2' valign="top">
                                {model && <QuestionsComponent
                                    appContext={appContext}
                                    questionModels={modelQuestions}
                                    onQuestionDeleted={(questionModel) => {
                                        blueprint.deletedQuestions.push(questionModel);
                                        self.setState({ blueprint, validated: false });
                                    }}
                                    onChange={(questionModels) => {
                                        blueprint.modelQuestions = questionModels;
                                        self.setState({ blueprint, validated: false });
                                    }}
                                />}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {dialog}
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={showUpdateProgress}
                    onClose={() => this.setState({ showUpdateProgress: !showUpdateProgress })}
                    key={'snack'}
                >
                    <MuiAlert variant="outlined" severity="info"
                        onClose={() => this.setState({ showUpdateProgress: !showUpdateProgress })}>
                        <AlertTitle>Updating...<LinearProgress /></AlertTitle>
                        <ol className="no-padding-list">{progressList}</ol>
                    </MuiAlert>
                </Snackbar>
            </div>
        </StylesProvider>;
    }
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export class ModelDeleteDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDeleteChiefComplaint: false,
            confirmDeleteSymptom: false,
            confirmDeleteDataTypes: false,
            confirmDeleteModel: false,
            confirmDeleteQuestions: false
        }
    }
    onDone() {
        let { confirmDeleteDataTypes, confirmDeleteModel, confirmDeleteQuestions, confirmDeleteSymptom, confirmDeleteChiefComplaint } = this.state;
        if (typeof this.props.onDone === 'function') {
            this.props.onDone({ confirmDeleteDataTypes, confirmDeleteModel, confirmDeleteQuestions, confirmDeleteChiefComplaint, confirmDeleteSymptom })
        }
    }

    onCancel() {
        if (typeof this.props.onCancel === 'function') {
            this.props.onCancel()
        }
    }

    render() {
        let { confirmDeleteDataTypes, confirmDeleteModel, confirmDeleteQuestions, confirmDeleteSymptom, confirmDeleteChiefComplaint } = this.state;

        return <Dialog maxWidth={'sm'} modal="true" open={true}  >
            <DialogContent style={{ overflow: 'visible' }} >
                <FormControl component="fieldset" >
                    <FormLabel component="legend">Select components to delete: Be vewy vewy careful.</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={confirmDeleteChiefComplaint} onChange={() => this.setState({ confirmDeleteChiefComplaint: !confirmDeleteChiefComplaint })} name="confirmDeleteSymptom" />}
                            label="Delete Chief Complaint"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={confirmDeleteSymptom} onChange={() => this.setState({ confirmDeleteSymptom: !confirmDeleteSymptom })} name="confirmDeleteSymptom" />}
                            label="Delete Symptom"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={confirmDeleteDataTypes} onChange={() => this.setState({ confirmDeleteDataTypes: !confirmDeleteDataTypes })} name="confirmDeleteDataTypes" />}
                            label="Delete Non-Symtom Data Types"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={confirmDeleteQuestions} onChange={() => this.setState({ confirmDeleteQuestions: !confirmDeleteQuestions })} name="confirmDeleteQuestions" />}
                            label="Delete Question and Answers"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={confirmDeleteModel} onChange={() => this.setState({ confirmDeleteModel: !confirmDeleteModel })} name="confirmDeleteModel" />}
                            label="Delete Models"
                        />
                    </FormGroup>
                    <FormHelperText>Be careful this operation cannot be undone</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={{ margin: 10 }} onClick={(e) => { this.onCancel() }}>Cancel</Button>
                <Button variant="contained" style={{ margin: 10 }} onClick={(e) => { this.onDone() }}>Ok</Button>
            </DialogActions>
        </Dialog >

    }


}


function getAnswerText(answer) {
    return answer && isArrayValid(answer.text) && answer.text[0];
}


const MuiAlert = withStyles({
    root: {
        backgroundColor: '#ffffff',

    }
})(Alert);


export default SurveyModelView;
