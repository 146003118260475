import React, { Component } from "react";
import {
    Chip,
    Avatar,
    FormLabel,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    TextField
} from '@material-ui/core/';

import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';

import {
    isArrayValid
} from '../utils/Utils';

class EditableTextField extends Component {
    constructor(props) {
        super(props);

        this.state = { value: props.value, name: props.name };
    }

    onChange(e) {
        this.setState({ value: e.target.value, name: e.target.name });
        if (this.props.onChange)
            this.props.onChange(e);
    }

    render() {
        return <div>
            <TextField label={this.props.label} name={this.state.name} value={this.state.value} style={this.props.style}
                onKeyDown={(e) => {
                    if (this.props.onEnter)
                        if (e.key === "Enter")
                            this.props.onEnter();
                }}
                onChange={this.onChange.bind(this)} />
        </div>;
    }
}


class EditObjectArray extends Component {
    constructor(props) {
        super(props);
        this.state = {
            object: props.object,
            menu: null,
            editKey: null,
            editValue: null
        };
        this.menuTarget = null
    }


    onAdd = index => e => {
        let { object } = this.state;
        //just add a default one
        if (!object)
            object = { 'Key': 'Value' };
        else {
            let objectKeys = Object.keys(object);
            let keyCount = 1;
            while (objectKeys.includes(`key${keyCount}`)) {
                keyCount++;
            }
            object[`key${keyCount}`] = `value${keyCount}`;
        }
        this.setState({ object });
    }

    generateMenu = (object, objectKey) => {
        let objectValue = object[objectKey];
        let menu = <Menu open={true} onClose={this.closeMenu.bind(this)} anchorEl={this.menuTarget}>
            <MenuItem key={0}>
                <EditableTextField key={objectKey} value={objectKey} name={objectKey} label="Key" onChange={this.handleKeyChange.bind(this)} />
            </MenuItem>
            <MenuItem key={1}>
                <EditableTextField ref={(input) => { this.inputRef = input }} onEnter={this.closeMenu.bind(this)} key={objectValue} value={objectValue} name={objectKey} label="Value" onChange={this.handleValueChange.bind(this)} />
            </MenuItem>
        </Menu>;
        return menu
    }

    onChangeData = objectKey => e => {
        let { object, editKey, editValue } = this.state;
        this.menuTarget = e.target
        let menu = this.generateMenu(object, objectKey)

        editValue = object[objectKey];
        editKey = objectKey;

        this.setState({ menu, editValue, editKey });
    }

    handleKeyChange(e) {
        let { object, editKey, editValue } = this.state;
        let fieldKey = e.target.value;
        if (fieldKey && editKey !== fieldKey) {
            delete object[editKey];
            object[fieldKey] = editValue;
            editKey = fieldKey
            this.setState({ object, editKey });
        }
    }

    handleValueChange(e) {
        let { object, editKey, editValue } = this.state;
        const fieldValue = e.target.value;
        object[editKey] = fieldValue;
        editValue = fieldValue
        this.setState({ object, editValue });
    }

    closeMenu() {
        this.setState({ menu: null });
    }

    onDelete = objectKey => e => {
        let { object } = this.state;
        delete object[objectKey];
        this.setState({ object });
    }

    render() {
        let self = this;
        let { object, menu } = this.state;
        if (!object)
            object = {};

        let dataChips = [];
        let objectKeys = Object.keys(object);
        if (isArrayValid(objectKeys)) {
            for (let index = 0; index < objectKeys.length; index++) {
                let objectKey = objectKeys[index];
                let objectValue = object[objectKey];
                if (!objectValue) objectValue = '';

                let label = `${objectKey} : ${objectValue} `;
                dataChips.push(
                    <Chip
                        key={index}
                        avatar={<Avatar><FaceIcon /></Avatar>}
                        label={label}
                        onClick={self.onChangeData(objectKey)}
                        onDelete={self.onDelete(objectKey)}
                    />);

            }
        }

        return <div style={this.props.style || { margin: 5 }} >
            <div style={{ marginTop: '5px' }} />
            <FormLabel>{this.props.label}</FormLabel>
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={self.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div>;
    }
}


export default EditObjectArray;
