import React from "react";

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Paper,
} from '@material-ui/core/';

import ReactJson from 'react-json-view';
import EditJsonDialog from "./EditJsonDialog";
class JsonDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            dataType: props.dataType,
            collapsed: props.collapsed || 3
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataType !== this.props.dataType)
            this.setState({ dataType: this.props.dataType });
    }

    onDone() {
        if (this.props.onDone)
            this.props.onDone(this.state.dataType);
    }

    onEdit() {
        this.setState({
            dialog: <EditJsonDialog json={this.state.dataType} 
                onDone={(json) => {
                    this.setState( {dialog: null, dataType: json});
                    this.props.onEditDone(json);
                }} 
                onCancel={() => {
                    this.setState({ dialog: null})
                }}
            />
        })
    }

    render() {
        let { dataType, collapsed, dialog } = this.state;

        return (
            <Dialog maxWidth={'md'} fullWidth={true} open={true}>
                <DialogContent>
                    <Paper key="2" style={styles.jsonPaper}>
                        <ReactJson src={dataType}
                            collapsed={collapsed} name={this.props.name || dataType.name} collapseStringsAfterLength={64} displayDataTypes={false} />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    {dialog}
                    {this.props.onEditDone && <Button variant="contained" style={styles.button} onClick={this.onEdit.bind(this)}>Edit</Button>}
                    <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>Ok</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    button: {
        margin: 5
    },
    jsonPaper: {
        margin: 5,
        width: '98%',
        borderRadius: 6
    }
};

export default JsonDialog;
