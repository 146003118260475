import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    TextField,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    MuiThemeProvider
} from '@material-ui/core/';
import { getTheme } from '@apricityhealth/web-common-lib/themes/manifest';

import {
    exportData
} from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

class CommitDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            progress: null,
            error: null
        };
    }

    onDone() {
        let { comment } = this.state;
        const { onDone } = this.props;
        if (onDone)
            onDone(comment);
    }

    onChange = type => e => {
        let { comment } = this.state;
        comment = e.target.value;
        this.setState({ comment });
    }

    saveCommit() {
        this.setState({ dialog: null });
        const self = this;
        const { appContext, planId } = this.props;
        const { comment } = this.state;
        this.setState({
            progress: <CircularProgress />
        });
        exportData(appContext, comment, planId ).then(() => {
            self.setState({ progress: null });
            self.onDone();
        }).catch((error) => {
            self.setState({ error: getErrorMessage(error), progress: null });
        });
    }

    render() {
        const { comment, progress, error } = this.state;
        const { appContext } = this.props
        const theme = getTheme(appContext.state.theme);

        return (
            <MuiThemeProvider theme={theme}>
                <Dialog
                    model="false"
                    open={true}
                >
                    <DialogTitle>Commit</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Note: This will commit all changes and do a pull request to development to include in the next build. Are you sure? </DialogContentText>
                        <div style={{ marginTop: '20px' }} />
                        <TextField
                            fullWidth
                            label="Please add a comment indicating the reason for changes:"
                            variant="outlined"
                            value={comment}
                            multiline={true}
                            rows={3}
                            onChange={this.onChange()}
                        />
                    </DialogContent>
                    <DialogActions>
                        <span style={{color: 'red'}}>{error}</span>
                        {progress}
                        <Button variant="contained" disabled={progress !== null} style={styles.button} onClick={(e) => { this.saveCommit() }}>Yes</Button>
                        <Button variant="contained" disabled={progress !== null} style={styles.button} onClick={(e) => { this.onDone() }}>No</Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider >

        )
    }
}

const styles = {
    div: {
        margin: 10
    },
    button: {
        margin: 10
    }
};

CommitDialog.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(CommitDialog);
