import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Dialog,
    IconButton,
    TextField,
    Button,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    CircularProgress,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Tooltip,
    Tabs,
    Tab
} from '@material-ui/core/';

import SelectModelType from '../components/SelectModelType';
import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import SaveIcon from '@material-ui/icons/Save';
import ImportIcon from '@material-ui/icons/CloudUpload';
import ExportIcon from '@material-ui/icons/CloudDownload'
import CreateIcon from '@material-ui/icons/ImportExport';
import ClearIcon from '@material-ui/icons/Clear';
import NavigationClose from '@material-ui/icons/Close';
import ForecastIcon from '@material-ui/icons/Schedule';
import Moment from 'moment';
import ParseDuration from 'parse-duration';

import { 
    loadPredictorInstances, 
    deletePredictorInstance,
    deletePredictorModel, 
    savePredictorModel,
    trainPredictorModel,
    savePredictorInstance
} from '@apricityhealth/web-common-lib/utils/Services';

import OverrideDialog from "../dialogs/OverrideDialog";
import JsonDialog from '../dialogs/JsonDialog';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import EditDataTypeDialog from '../dialogs/EditDataTypeDialog';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import createIdFromText from '../utils/CreateIdFromText';
import ForecastDialog from '../dialogs/ForecastDialog';
import DataFilters from '../components/DataFilters';
import ChangedByButton from '../components/ChangedByButton';

export class PredictorModelView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            model: null,
            tab: '0'
        };
    }
    componentDidMount() {
        if (this.props.model)
            this.loadModel(this.props.model);
        this.loadInstancesTimer = setInterval( this.loadInstances.bind(this), 15 * 1000 );
    }

    componentWillUnmount() {
        clearInterval( this.loadInstancesTimer );
    }

    componentDidUpdate(oldProps) {
        if (this.props.model !== oldProps.model)
            this.loadModel(this.props.model);
    }

    closeView() {
        console.log("closeView()", this);
        this.props.onClose();
    }

    loadModel(model) {
        console.log("loadModel:", model);
        if ( model.training === undefined ) {
            model.training = { sourceStage: 'develop', flagged: true, maxPatients: 1000, days: 60, andfilters: [], orFilters: [] };
        }
        this.setState({
            model,
            dialog: null
        }, this.loadInstances.bind(this));
    }

    loadInstances() {
        const self = this;
        const { model } = this.state;
        if ( model ) {
            this.setState({ error: null });
            loadPredictorInstances(this.props.appContext, { predictorId: model.predictorId, instanceId: '*' } ).then((instances) => {
                self.setState({ instances });
            }).catch((err) => {
                self.setState({ error: getErrorMessage(err)});
            })
        }
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    savePredictorModel(planId) {
        const self = this;
        const { model } = self.state;
        let { appContext } = this.props;

        if (!planId && model.planId && model.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(model.planId, this.savePredictorModel.bind(this));
        }
        this.setState({ progress: <CircularProgress size={20} />, error: null });

        delete model._id;
        delete model.__v;
        
        savePredictorModel(appContext, model, planId).then((response) => {
            console.log("savePredictorModel response:", response);
            self.setState({ override: false, model: response, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    deletePredictorModel() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete model: {`${self.state.model.predictorId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.onCloseDialog.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={this.onConfirmDelete.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    showCode() {
        let { appContext } = this.props;
        let { model } = this.state;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={model}
                onEditDone={(model) => {
                    this.setState({model, dialog: null});
                }}
                onDone={() => {
                    this.setState({ dialog: null });
                }}
            />
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onConfirmDelete() {
        const self = this;
        const model = this.state.model;
        let { appContext } = this.props;
        self.setState({ dialog: null });
        if (model.predictorId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            deletePredictorModel(appContext, model).then(() => {
                self.setState({ progress: null });
                self.closeView();
            }).catch((error) => {
                console.log("deletePredictorModel error:", error.response);
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    onDataTypeControl(table, value, row, index, id) {
        const { appContext } = this.props;
        return <SelectDataType
            style={{width: 300, margin: 5}}
            appContext={appContext}
            dataId={value}
            onClick={(e) => table.handleClickEdit(e)}
            onChange={(type) => {
                table.setDataField(index, id, type ? type.dataId : '' )
            }}
        />;
    }

    onTupleControl(table, value, row, index, id) {
        const { appContext } = this.props;
        return <SelectTupleIndex
            style={{width: 300, margin: 5}}
            appContext={appContext}
            dataId={row.dataId}
            tupleIndex={value}
            onClick={(e) => table.handleClickEdit(e)}
            onChange={(tupleIndex) => {
                table.setDataField(index, id, tupleIndex);
            }}
            name={'Conditions'}
        />;
    }

    onTypeControl(table, value, row, index, id) {
        return <FormControl style={{margin: 5}}>
            <InputLabel>Type</InputLabel>
            <Select value={value} onChange={(e) => {
                table.setDataField(index, id, e.target.value)
            }}>
                <MenuItem value='float'>Float</MenuItem>
                <MenuItem value='string'>String</MenuItem>
            </Select>
        </FormControl> 
    }

    onAverageControl(table, value, row, index, id) {
        //console.log("onAverageControl:", value );
        return <FormControlLabel 
            control={<Checkbox 
            defaultChecked={value}
            onClick={(e) => {
                table.handleClickEdit(e)
            }}
            onChange={(e,v) => {
                table.setDataField(index, id, v ); 
            } } />} label='Average' />;
    }

    onAddInstance() {
        const self = this;
        const { appContext } = this.props;
        const { model } = this.state;
        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Create</DialogTitle>
            <DialogContent>Please confirm you want to create a new instance for model {model.predictorId}?</DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                <Button onClick={() => {
                    self.onCloseDialog();
                    self.setState({error: null});
                    trainPredictorModel(appContext, model).then(self.loadInstances.bind(self)).catch((err) => {
                        self.setState({error: getErrorMessage(err)});
                    })
                }}>Create</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog});
    }

    onDeleteInstance(instance) {
        console.log("onDeleteInstance:", instance, this );
        const self = this;
        const { appContext } = this.props;
        if ( this.deleteQueue === undefined ) this.deleteQueue = [];
        this.deleteQueue.push(instance);

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Please confirm you want to delete instance(s) {this.deleteQueue.reduce( (p, v) => { if ( p ) p += ', '; p += v.instanceId; return p;}, '')}? 
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                <Button onClick={() => {
                    self.onCloseDialog();
                    self.setState({error: null});

                    let promises = [];
                    for(let i=0;i<self.deleteQueue.length;++i) {
                        let instance = self.deleteQueue[i];
                        promises.push( deletePredictorInstance( appContext, instance ) );
                    }
                    self.deleteQueue = [];

                    Promise.all(promises).then(() => {
                        self.loadInstances();
                    }).catch((err) => {
                        self.setState( { error: getErrorMessage(err) });
                    })
                }}>Delete</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog});
    }

    onImportConditionModel() {
        const self = this;
        const { appContext } = this.props;
        const { model } = this.state;
        let dialog = <Dialog open={true}>
            <DialogTitle>Import Condition Model</DialogTitle>
            <DialogContent>
                Select the model you would like to import into this predictor, please note all existing related data will be replaced.
                <SelectModelType appContext={appContext} onChange={(type) => {
                    console.log("importType:", type);
                    self.setState({importType: type})
                }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                <Button onClick={() => {
                    const { importType } = self.state;
                    self.onCloseDialog();
                    model.related = [];
                    for(let i=0;i<importType.primarySymptoms.length;++i) {
                        let symptom = importType.primarySymptoms[i];
                        model.related.push( { dataId: symptom.dataId, tupleIndex: symptom.tupleIndex, type: 'float', average: true } );
                    }
                    for(let i=0;i<importType.associatedSymptoms.length;++i) {
                        let symptom = importType.associatedSymptoms[i];
                        model.related.push( { dataId: symptom.dataId, tupleIndex: symptom.tupleIndex, type: 'float', average: true } );
                    }
                    for(let i=0;i<importType.negativeSymptoms.length;++i) {
                        let symptom = importType.negativeSymptoms[i];
                        model.related.push( { dataId: symptom.dataId, tupleIndex: symptom.tupleIndex, type: 'float', average: true } );
                    }
                    for(let i=0;i<importType.clinicalModifiers.length;++i) {
                        let mod = importType.clinicalModifiers[i];
                        model.related.push( { dataId: mod.dataId, tupleIndex: mod.tupleIndex, type: 'float', average: true } );
                    }
                    self.setState({model});
                }}>Import</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog});
    }

    onInstanceDetails(instance) {
        const self = this;
        let { appContext } = this.props;
        return <Tooltip key='viewDetails' title='View Details'><IconButton onClick={() => {
            self.setState({
                dialog: <JsonDialog
                    appContext={appContext}
                    dataType={instance}
                    onDone={() => {
                        self.setState({ dialog: null });
                    }}
                />
            });
        }}><CodeIcon /></IconButton></Tooltip>;
    }

    onForecastsControl(instance) {
        const self = this;
        const { appContext } = this.props;
        const { model } = this.state;
        return <Tooltip key='viewForecasts' title='View Forecasts'><IconButton onClick={() => {
            self.setState({
                dialog: <ForecastDialog
                    appContext={appContext}
                    instance={instance}
                    model={model}
                    onClose={() => {
                        self.setState({ dialog: null });
                    }}
                />
            });
        }}><ForecastIcon /></IconButton></Tooltip>;
    }

    onActiveControl(table, value, row, index, id ) {
        const self = this;
        value = value === 'true';
        return <FormControlLabel label="Active" control={<Checkbox checked={value} onChange={(e,v) => {
            console.log("Active onChange:", v);
            table.setDataField( index, id, v );
            savePredictorInstance( self.props.appContext, row.model.predictorId, row, row.planId );
        }} onClick={(e) => { table.preventClickThrough(e) }}/>} />;
    }

    forceDataImport(table) {
        const { selected, data } = table.state;
        const { appContext } = this.props;

        let instances = [];
        if ( Array.isArray(selected) && selected.length > 0 ) {
            for(let i=0;i<selected.length;++i) {
                instances.push( data[selected[i]] );
            }
        }

        for(let i=0;i<instances.length;++i) {
            let instance = instances[i];
            if (! instance.forceUpdate ) {
                instance.forceUpdate = true;
                savePredictorInstance( appContext, instance.model.predictorId, instance, instance.planId );
            }
        }
    }

    resetInstance(table) {
        const { selected, data } = table.state;
        const { appContext } = this.props;

        let instances = [];
        if ( Array.isArray(selected) && selected.length > 0 ) {
            for(let i=0;i<selected.length;++i) {
                instances.push( data[selected[i]] );
            }
        }

        for(let i=0;i<instances.length;++i) {
            let instance = instances[i];
            instance.status = 'idle';
            instance.error = '';
            instance.lastDataImport = instance.previousDataImport;
            savePredictorInstance( appContext, instance.model.predictorId, instance, instance.planId );
        }
    }

    reimportForecast(table) {
        const { selected, data } = table.state;
        const { appContext } = this.props;

        let instances = [];
        if ( Array.isArray(selected) && selected.length > 0 ) {
            for(let i=0;i<selected.length;++i) {
                instances.push( data[selected[i]] );
            }
        }

        for(let i=0;i<instances.length;++i) {
            let instance = instances[i];
            instance.status = 'importing_forecast';
            savePredictorInstance( appContext, instance.model.predictorId, instance, instance.planId );
        }
    }

    getNextForecastTime(predictor, instance) {
        if ( instance.forceUpdate || !instance.lastDataImport ) return new Moment();
        let nextTime = Moment(instance.lastDataImport);
        let freq = ParseDuration(predictor.updateFrequency || '1d', 'ms');
        return nextTime.add(freq, 'ms');
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        let { tab, model, instances, progress, error, dialog } = this.state;

        if (!model) {
            return null;
        }

        const columnData = [
            { id: "_index", numeric: true, label: "Index" },
            { id: 'dataId', numeric: false, disabledPadding: false, label: 'Data Id', editControl: this.onDataTypeControl.bind(this) },
            { id: 'tupleIndex', numeric: false, disabledPadding: false, label: 'Tuple Index', editControl: this.onTupleControl.bind(this) },
            { id: 'type', numeric: false, disabledPadding: false, label: 'Type', editControl: this.onTypeControl.bind(this) },
            { id: 'average', numeric: false, disabledPadding: false, label: 'Average', editControl: this.onAverageControl.bind(this) }
        ];
        const instanceColumns = [
            { id: 'instanceId', label: 'Instance ID' },
            { id: 'nextDataImport', label: 'Next Update', formatValue: (v, r) => {
                let nextTime = self.getNextForecastTime(r.model, r);
                return `${nextTime.diff(Moment(),'minutes')} min`;
            }},
            { id: 'status', label: 'Status' },
            { id: 'mpe', label: 'MPE', formatValue: (v,r) => { return `${Number(v).toFixed(3)}%` } },
            { id: 'error', label: 'Error' },
            { id: 'forecastActive', label: 'Forecast', editControl: this.onActiveControl.bind(this) }
        ];

        function getFillSelect(property, label) {
            return <FormControl style={styles.name}>
                <InputLabel>{label}</InputLabel>
                <Select value={model[property]} onChange={(e) => {
                    model[property] = e.target.value;
                    self.setState({model, modified: true });
                }}>
                    <MenuItem value='zero'>Zero</MenuItem>
                    <MenuItem value='mean'>Mean</MenuItem>
                    <MenuItem value='median'>Median</MenuItem>
                    <MenuItem value='min'>Min</MenuItem>
                    <MenuItem value='max'>Max</MenuItem>
                </Select>
            </FormControl>;
        }

        let tabs = [
            <div key='options' style={styles.tabContent}>
                <TextField style={styles.name} label="Name" value={model.name}
                    onChange={(e) => {
                        model.name = e.target.value;
                        model.predictorId = createIdFromText(model.name);
                        self.setState({ model, modified: true });
                    }} />
                <FormControl style={styles.name}>
                    <InputLabel>Algorithm</InputLabel>
                    <Select value={model.algorithm} onChange={(e) => {
                        model.algorithm = e.target.value;
                        self.setState({ model, modified: true });
                    }}>
                        <MenuItem value='AutoML'>AutoML</MenuItem>
                        <MenuItem value='ARIMA'>ARIMA</MenuItem>
                        <MenuItem value='DeepARPlus'>DeepARPlus</MenuItem>
                        <MenuItem value='ETS'>ETS</MenuItem>
                        <MenuItem value='NPTS'>NPTS</MenuItem>
                        <MenuItem value='Prophet'>Prophet</MenuItem>
                        <MenuItem value='CNN-QR'>CNN-QR</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <TextField style={styles.desc} label="Description" value={model.description}
                    onChange={(e) => { model.description = e.target.value; self.setState({ model, modified: true }); }} />
                <br />
                <TextField style={styles.name} type='number' label="Forecast Length" value={model.forecastLength}
                    onChange={(e) => { model.forecastLength = e.target.value; self.setState({ model, modified: true }); }} />
                <FormControl style={styles.name}>
                    <InputLabel>Forecast Frequency</InputLabel>
                    <Select value={model.forecastFrequency} onChange={(e) => {
                        model.forecastFrequency = e.target.value;
                        self.setState({ model, modified: true });
                    }}>
                        <MenuItem value='D'>Daily</MenuItem>
                        <MenuItem value='W'>Weekly</MenuItem>
                        <MenuItem value='M'>Monthly</MenuItem>
                        <MenuItem value='Y'>Yearly</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={styles.name}>
                    <InputLabel>Update Frequency</InputLabel>
                    <Select value={model.updateFrequency} onChange={(e) => {
                        model.updateFrequency = e.target.value;
                        self.setState({ model, modified: true });
                    }}>
                        <MenuItem value='2h'>2h</MenuItem>
                        <MenuItem value='4h'>4h</MenuItem>
                        <MenuItem value='8h'>8h</MenuItem>
                        <MenuItem value='12h'>12h</MenuItem>
                        <MenuItem value='1d'>Daily</MenuItem>
                        <MenuItem value='1w'>Weekly</MenuItem>
                        <MenuItem value='1m'>Monthly</MenuItem>
                        <MenuItem value='1y'>Yearly</MenuItem>
                    </Select>
                </FormControl>
                <br />
                {getFillSelect('backFill', "Back Fill")}
                {getFillSelect('middleFill', "Middle Fill")}
                {getFillSelect('futureFill', "Future Fill")}
                <br />
                <SelectDataType style={styles.name} label='Target Data Type' appContext={appContext} dataId={model.target.dataId}
                    onCreate={() => {
                        self.setState({
                            dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                dataType={{ dataId: '', name: '', description: '', category: 'symptom', tupleDescriptions: [] }}
                                onCancel={() => self.setState({ dialog: null })}
                                onDone={(newType) => {
                                    model.target.dataId = newType.dataId;
                                    self.setState({ dialog: null, model, modified: true });
                                }} />
                        });
                    }}
                    onChange={(dataType) => {
                        model.target.dataId = dataType ? dataType.dataId : '';
                        self.setState({ model, modified: true });
                    }} />
                <SelectTupleIndex style={styles.name} label='Target Tuple Index' appContext={appContext} dataId={model.target.dataId}
                    tupleIndex={model.target.tupleIndex}
                    onChange={(e) => {
                        model.target.tupleIndex = e;
                        self.setState({ model, modified: true });
                    }} />
                <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={model.target.average} onChange={(e, v) => {
                    model.target.average = v;
                    self.setState({ model, modified: true });
                }} />} label='Average Target' />

            </div>,
            <div key='training' style={styles.tabContent}>
                <FormControl style={styles.name}>
                    <InputLabel>Source Stage</InputLabel>
                    <Select value={model.training.sourceStage} onChange={(e) => {
                        model.training.sourceStage = e.target.value;
                        self.setState({ model, modified: true });
                    }}>
                        <MenuItem value='develop'>Develop</MenuItem>
                        <MenuItem value='test'>Test</MenuItem>
                        <MenuItem value='demo'>Demo</MenuItem>
                        <MenuItem value='production'>Production</MenuItem>
                        <MenuItem value='vcc'>VCC</MenuItem>
                    </Select>
                </FormControl>
                <SelectPlan appContext={appContext} style={styles.name} label='Source Plan' planId={model.training.sourcePlanId} enableNone={true} onChange={(plan) => {
                    model.training.sourcePlanId = plan ? plan.planId : ''; self.setState({model, modified: true}) }}/>
                <TextField style={styles.number} type='number' label="Max Patients" value={model.training.maxPatients}
                    onChange={(e) => { model.training.maxPatients = e.target.value; self.setState({ model, modified: true }); }} />
                <TextField style={styles.number} type='number' label="Days" value={model.training.days}
                    onChange={(e) => { model.training.days = e.target.value; self.setState({ model, modified: true }); }} />
                <br />
                <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={model.training.flagged} onChange={(e, v) => {
                    model.training.flagged = v;
                    self.setState({ model, modified: true });
                }} />} label='Flagged Patients Only' />
                <br />
                <DataFilters appContext={appContext} label='AND Filters' filters={model.training.andFilters} onChange={(filters) => {
                    model.training.andFilters = filters;
                    self.setState({ model, modified: true });
                }} />
                <br />
                <DataFilters appContext={appContext} label='OR Filters' filters={model.training.orFilters} onChange={(filters) => {
                    model.training.orFilters = filters;
                    self.setState({model, modified: true})
                }} />
            </div>,
            <EnhancedTable
                key='relatedData'
                style={styles.tabContent}
                orderBy='_index'
                columnData={columnData}
                data={model.related}
                rowsPerPage={5}
                onDataChanged={(values) => {
                    model.related = values;
                    self.setState({ model, modified: true });
                }}
                title='Related Data' />,
            <EnhancedTable
                key='metaData'
                style={styles.tabContent}
                orderBy='_index'
                columnData={columnData}
                data={model.meta}
                rowsPerPage={5}
                onDataChanged={(values) => {
                    model.meta = values;
                    self.setState({ model, modified: true });
                }}
                title='Meta Data' />,
            <EnhancedTable
                key='instances'
                disableMultiSelect={false}
                onActions={(table, numSelected, actions) => {
                    if (numSelected > 0 ) {
                        actions.unshift( <Tooltip key='import' title='Update Now'><IconButton onClick={self.forceDataImport.bind(self, table)}>
                            <ImportIcon /></IconButton></Tooltip>);
                        actions.unshift( <Tooltip key='reimport' title='Re-Import Forecast'><IconButton onClick={this.reimportForecast.bind(this,table)}>
                            <ExportIcon /></IconButton></Tooltip>)
                        actions.unshift( <Tooltip key='clear' title='Clear error or failure'><IconButton onClick={this.resetInstance.bind(this,table)}>
                            <ClearIcon /></IconButton></Tooltip>)
                        if ( numSelected === 1) {
                            const { selected, data } = table.state;
                            let instance = data[selected[0]];
                            actions.unshift( this.onInstanceDetails(instance) );
                            actions.unshift( this.onForecastsControl(instance) );
                        }
                    }
                }}
                onAdd={this.onAddInstance.bind(this)}
                onDelete={this.onDeleteInstance.bind(this)}
                style={styles.tabContent}
                orderBy='createdAt'
                order='desc'
                columnData={instanceColumns}
                data={instances}
                rowsPerPage={5}
                title='Instances' />
        ];

        let disableDelete = model.planId !== appContext.state.plan.planId;
        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Predictor Model</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField disabled={true} style={styles.name} label="Predictor ID" value={model.predictorId}
                                        onChange={(e) => { model.predictorId = e.target.value; self.setState({ model, modified: true }); }} />
                                </td>
                                <td valign="top" align="right">
                                    {error}
                                    <IconButton disabled={progress !== null} onClick={this.savePredictorModel.bind(this,null)}>{progress || <SaveIcon />}</IconButton>
                                    <IconButton onClick={this.showCode.bind(this)}><CodeIcon /></IconButton>
                                    <Tooltip title='Import from a condition model.'><IconButton onClick={this.onImportConditionModel.bind(this)}><CreateIcon /></IconButton></Tooltip>
                                    <IconButton disabled={disableDelete} onClick={this.deletePredictorModel.bind(this)}><DeleteIcon /></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <Tabs style={{ width: '100%', backgroundColor: '#96b7eb' }}
                                        value={tab} onChange={(e, newTab) => { self.setState({ tab: `${newTab}` }); }}>
                                        <Tab label='Options' value='0' />
                                        <Tab label='Training Options' value='1' />
                                        <Tab label='Related Data' value='2' />
                                        <Tab label='Meta Data' value='3' />
                                        <Tab label='Instances' value='4' />
                                    </Tabs>
                                    {tabs[tab]}
                                    <br />
                                    <ChangedByButton appContext={this.props.appContext} primaryKey={model.predictorId} collection='PredictorModel' />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        width: '100%'
    },
    tabContent: {
        width: 1300
    },
    button: {
        margin: 10
    },
    div: {
        margin: 0
    },
    name: {
        margin: 5,
        width: 300
    },
    desc: {
        margin: 5,
        width: 500
    },
    text: {
        margin: 5,
        width: 300
    },
    code: {
        margin: 5,
        width: 500
    },
    number: {
        margin: 5,
        width: 100
    },
    description: {
        margin: 5,
        width: 500
    },
    select: {
        margin: 5,
        width: 300
    },
    tags: {
        margin: 5
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default PredictorModelView;
