import React, { Component } from "react";
import {
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core/';
import {
    isArrayValid,
} from '../utils/Utils';

class RecommendationFlattenedView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            models: props.models
        };
    }

    static getDerivedStateFromProps(props) {
        return { models: props.models };
    }

    render() {
        let { models } = this.state;
        let report = this.getRecommendationModelReport(models);
        return (
            <div align="left" style={styles.div} >
                {models && <Typography variant="h6" color="inherit" >
                    There are {models.length} models.
                </Typography>}
                {report}
            </div>

        )
    }

    getRecommendationModelReport(model) {
        const { appContext: { stores: { DataTypesStore }} } = this.props;

        if (!model) return [];
        if (isArrayValid(model)) {
            let report = [];
            for (let j = 0; j < model.length; j++) {
                let nextModel = model[j];
                report.push(this.getRecommendationModelReport(nextModel));
            }
            return report;
        }

        let groups = model.groups;
        if (isArrayValid(groups)) {
            groups.sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                else if (a.name > b.name)
                    return 1;
                return 0;
            });
        }
        let rows = [];
        let modelName = model.name;
        console.log(`processing ${modelName} recommendation model `)
        let modelDescripton = model.description;
        if (isArrayValid(groups)) {
            for (let j = 0; j < groups.length; j++) {
                let group = groups[j];
                let groupName = group.name;

                let type = DataTypesStore.getRecommendGroupTypes().find((e) => e.typeId === group.type);        // why did this need a trim() before?
                let groupType = type ? type.name : "NA";

                rows.push(<tr><th colSpan='2' className='group_name'>{groupName} ({groupType})</th></tr>);

                let columns = [];

                if (isArrayValid(group.categories)) {
                    for (let k = 0; k < group.categories.length; k++) {
                        let category = group.categories[k];
                        let type = DataTypesStore.getRecommendCategories().find((e) => e.categoryId === category.name);
                        let catName = type ? type.name : "NA";
                        columns.push(<td className='category_name'>{catName}</td>);

                        if (isArrayValid(category.recommendations)) {
                            let listItems = [];
                            category.recommendations.forEach(recommendation => {
                                let name = recommendation.name;
                                let weight = recommendation.weight;
                                let protocol = DataTypesStore.getRecommendProtocols().find((e) => e.protocolId === recommendation.protocol );
                                let explanation = recommendation.explanation;
                                let fullName = name + (weight ? `; weight: ${weight}` : "");
                                let fontColor = protocol ? protocol.color : 'black';
                                listItems.push(<ListItem>
                                    <ListItemText style={{ color: fontColor }} primary={fullName} secondary={explanation} /></ListItem >);
                            });

                            let list = <List dense>{listItems}</List>
                            columns.push(<td className='recommendation_cell'><ul className='recommendation_list'>{list}</ul></td>);
                        } else {
                            columns.push(<td className='recommendation_cell'><ul className='recommendation_list'><li>none</li></ul></td>);
                        }

                        rows.push(<tr className='tr'>{columns}</tr>);
                        columns = [];
                    }

                } else {
                    columns.push(<td colSpan='2' className='recommendation_cell'><ul className='recommendation_list'><li>none</li></ul></td>);
                    rows.push(<tr className='tr'>{columns}</tr>);
                    columns = [];
                }


            }
        }
        return <table align="left" className='table' >
            <tbody>
                <tr className='tr'>
                    <th className='title' colSpan={groups.length + 1}>
                        {modelName} ({modelDescripton})
                     </th>
                </tr>
                {rows}
            </tbody>

        </table>

    }


}

const styles = {
    div: {
        margin: 10
    }
};


export default RecommendationFlattenedView;
