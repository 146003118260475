import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    CircularProgress,
    Typography,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core/';

import {
    loadConfigurations,
    saveConfigurations,
    deleteConfiguration
} from '@apricityhealth/web-common-lib/utils/Services';

import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import RefreshIcon from '@material-ui/icons/Refresh';
import ChangedByButton from '../components/ChangedByButton';

class ConfigurationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: { 
                config: '{}', 
                configId: this.props.configId ? this.props.configId : '', 
                ownerId: '' 
            },
            planId: this.props.appContext.state.plan.planId,
            dialog: null
        }
    }

    componentDidMount() {
        console.log(`config view componentWillMount`, this.props, this.state);
        this.loadConfig();
    }

    componentDidUpdate(oldProps) {
        const { config } = this.state;
        console.log(`config view componentDidUpdate`, this.props);
        if (this.props.configId !== oldProps.configId) {
            config.configId=this.props.configId
            this.setState({ config: config }, this.loadConfig.bind(this));
        }
    }
    loadConfig() {
        const self = this;
        const { appContext } = this.props;
        const { configId } = this.state.config;
        if (configId) {
            self.setState({ progress: <CircularProgress size={20}/> });
            loadConfigurations(appContext, { configId, dependencies: true } ).then((response) => {
                let config = response.length > 0 ? response[0] : { configId, config: '{}', ownerId: '' };

                if (config.config){
                    console.log("config:", config );
                    let strParsedJSON = JSON.parse(config.config)
                    config.config = JSON.stringify(strParsedJSON, undefined, 4);
                }

                self.setState({ config, progress: null });
            }).catch(function (error) {
                console.log("loadConfigs error:", error.response);
                self.setState({ progress: error.message });
            });

        } else {
            this.setState({
                config: {
                    configId: '',
                    config: '{}',
                    ownerId: ''
                }
            });
        }
    }

    closeConfig() {
        this.props.onClose();
    }


    saveConfig() {
        let self = this
        let { appContext } = this.props
        let { config } = this.state

        //cloning config so text doesn't bounce around in the text field during save 
        config = JSON.parse(JSON.stringify(config))
        
        self.setState({ progress: <CircularProgress size={20}/> })
        //new lines and whitespace removal
        if (config.config){
            config.config = config.config.replace(/(\r\n|\n|\r)/gm,"");
            config.config = config.config.replace(/\s+/g," ");
        }

        saveConfigurations(appContext, config).then((response) => {
            if (response.config){
                console.log("config:", config );
                let strParsedJSON = JSON.parse(response.config)
                response.config = JSON.stringify(strParsedJSON, undefined, 4);
            } 
            self.setState({ progress: null, config: response });
            
        }).catch((error) => {
            console.error("saveConfig error:", error);
            self.setState({ progress: error.message })
        })
    }

    deleteConfig() {
        let { configId } = this.state.config;
        this.setState({dialog: <Dialog open={true}>
            <DialogContent>{`Please confirm you would like to delete configuration ${configId}?`}</DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={this.closeDialog.bind(this)}>Cancel</Button>
                <Button variant='contained' onClick={this.deleteConfigConfirmed.bind(this)}>Yes</Button>
            </DialogActions>
            </Dialog>});
    }

    deleteConfigConfirmed() {
        let { config } = this.state;
        let { appContext } = this.props;

        let self = this
        self.setState({ progress: <CircularProgress size={20} /> })
        deleteConfiguration(appContext, config ).then((response) => {
            console.log("deleteConfig response:", response);
            self.setState({ progress: null }, this.props.onClose);
        }).catch(function (error) {
            console.log("deleteConfig  error:", error.response);
            self.setState({ progress: error.message });
        });
    }

    parseConfigJSON() {
        let { config } = this.state
        
        try {
            JSON.stringify( JSON.parse(config.config));
            this.saveConfig()
        }
        catch(e) {
            console.error("Caught exception:", e );
            alert('Invalid JSON');
        }
    }

    closeDialog() {
        this.setState({ dialog: null })
    }

    textUpdate(e){
        let { config } = this.state;
        config.config = e.currentTarget.value 
        this.setState({config})
    }

    render() {
        const self = this
        let { config, dialog, progress } = this.state;
        let { appContext } = this.props;

        let appBar = this.props.onClose ?
            <AppBar style={styles.appBar}  position="static">
                <Toolbar>
                    <IconButton onClick={this.closeConfig.bind(this)}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" color="inherit">Configuration</Typography>
                </Toolbar>
            </AppBar> : null;
        let editConfig =
            <div style={styles.main}>
                <div>
                    <div style={styles.configHeader}>
                        <TextField style={styles.configId} label="Config Id" value={config.configId}
                            onChange={(e) => { config.configId = e.target.value; self.setState({ config }); }} />
                        <div >
                            <IconButton disabled={progress !== null} onClick={this.loadConfig.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                            <IconButton onClick={(event) => { self.parseConfigJSON(); }}><SaveIcon /></IconButton>
                            <IconButton onClick={(event) => { self.deleteConfig(); }}><DeleteIcon /></IconButton>
                        </div>

                    </div>
                </div>
            </div>


        return (
            <div style={{ textAlign: 'left' }}>
                {appBar}
                {editConfig}
                {dialog}
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={20}
                    spellCheck="false"
                    onChange={this.textUpdate.bind(this)}
                    style={{width:'100%',padding:'30px'}}
                    value={config.config}
                    variant="outlined"
                />
                <div style={{ paddingLeft: 30 }}>
                    <ChangedByButton appContext={appContext} primaryKey={config.configId} collection='ConfigModel' />
                </div>
            </div>
        )
    }
}

const styles = {
    main: {
        margin: 10,
        textAlign: 'left'
    },
    appBar: {
        backgroundColor: "#FF9800",
        width: '60vw' 
    },
    configHeader: {
        marginLeft: 12,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    configId:{
        margin: 5,
        width:400
    },
}
export default ConfigurationView;