import React, { Component } from "react";
import PubSub from 'pubsub-js';

import {
    Drawer,
    CircularProgress,
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core/';

import LayoutIcon from '@material-ui/icons/OpenWith';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DrawIcon from '@material-ui/icons/ShowChart';
import RefreshIcon from '@material-ui/icons/Refresh';
import StopIcon from '@material-ui/icons/Stop';

import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import { DialogTester } from '../utils/DialogTester';

import JSONView from '../views/JSONView';
var gridGuide = require('cytoscape-grid-guide');
gridGuide(cytoscape);

const layoutType = {
    name: 'breadthfirst',
    directed: true,
    padding: 10,
    avoidOverlap: true,
    circle: false
};

let defaultNodes = [
    { "data": { id: 'start', shape: 'ellipse', color: { bg: 'blue', text: 'white' }, size: { width: 50, height: 40 } } },
    { "data": { id: 'end', shape: 'ellipse', color: { bg: 'blue', text: 'white' }, size: { width: 50, height: 40 } } },

];

class DialogReportView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nodes: defaultNodes,
            edges: [
            ],
            progress: null,
            progressMessage: null,
            error: null,
            drawAnswerPaths: false,
            drawIncompletePermurtation: false
        };
        this.dialogTester = new DialogTester(props.appContext);

    }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.generatePermuations();
            }
        });
        this.generatePermuations();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
        this.cancelGenerate();
    }

    generatePermuations() {
        const self = this;
        this.setState({ progress: <CircularProgress size={20} />, error: null, progressMessage: null });
        this.dialogTester.generatePermuations((progressMessage) => {
            self.setState({ progressMessage });
        }).then((result) => {
            console.log(`permutations `, result)
            self.setState({ permutations: result, progress: null }, self.drawPermutations.bind(self));

        }).catch((err) => {
            console.error("generatePermuations error:", err);
            self.setState({ progress: null, error: err.message });
        })
    }

    cancelGenerate() {
        this.setState({ progress: null, error: 'Cancelled...' });
        this.dialogTester.cancelGeneratePermuations();
    }

    layout() {
        if (this.cy) {
            let layout = this.cy.layout(layoutType);
            layout.run();
        }
    }

    addLink(edges, source, target) {
        let id = `${source}-${target}`
        if (edges.find((edge) => edge.data.id === id)) return;
        edges.push({ data: { id, source, target } });
    }

    addNode(nodes, id) {
        if (nodes.find((node) => node.data.id === id)) return;
        nodes.push({ data: { id } });
    }

    showPermutations() {
        let { permutations } = this.state;
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <JSONView data={permutations} onClose={this.closeDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    closeDialog() {
        this.setState({ dialog: null });
    }



    drawPermutations() {
        let self = this;
        let { permutations, drawIncompletePermurtation } = self.state;
        let nodes = JSON.parse(JSON.stringify(defaultNodes));
        if (drawIncompletePermurtation) {
            nodes.push({ "data": { id: 'dead', shape: 'diamond', color: { bg: 'red', text: 'white' }, size: { width: 50, height: 40 } } });
        }
        let edges = [];
        delete edges['link1'];
        if (Array.isArray(permutations))
            permutations.forEach(permutation => {
                //only draw permutations 
                if (permutation.complete || drawIncompletePermurtation) {
                    let journals = permutation.journals;
                    if (Array.isArray(journals)) {

                        let length = journals.length;
                        for (let index = 0; index < length; index++) {

                            let from = null;
                            let to = null;
                            //wire to the start
                            if (index === 0) {
                                from = 'start';
                                to = journals[index].questionId;

                                //wire the nodes
                                to = journals[index].questionId;
                                self.addNode(nodes, to);
                                self.addLink(edges, from, to);

                            } else if (index === length - 1) {//wire the end
                                //onlu add the end if complete 
                                //wire the nodes
                                from = journals[index - 1].questionId;
                                to = journals[index].questionId;
                                self.addNode(nodes, from);
                                self.addNode(nodes, to);
                                self.addLink(edges, from, to);

                                if (permutation.complete) {
                                    from = journals[index].questionId;
                                    to = 'end'
                                    self.addNode(nodes, from);
                                    self.addLink(edges, from, to);
                                } else if (drawIncompletePermurtation) {
                                    from = journals[index].questionId;
                                    to = 'dead'
                                    self.addNode(nodes, from);
                                    self.addLink(edges, from, to);
                                }
                            } else {

                                //wire the nodes
                                from = journals[index - 1].questionId;
                                to = journals[index].questionId;
                                self.addNode(nodes, from);
                                self.addNode(nodes, to);
                                self.addLink(edges, from, to);
                            }
                        }
                    }
                }

            });

        self.setState({ nodes, edges });
    }

    cancelDialog() {
        this.setState({ dialog: null });
    }

    onFilesError(error, file) {
        console.log("onFilesError:", error, file);
    }
    export() {
        if (this.cy) {
            let pngExport = this.cy.png({ output: "blob", full: true, bg: 'white' });
            const fileDownload = require('js-file-download');
            fileDownload(pngExport, `diagram.png`);
        }
    }



    render() {
        let self = this;
        let { error, progressMessage, progress, dialog, nodes, edges, drawIncompletePermurtation } = self.state;

        let drawIncompleteToggle = <FormControlLabel control={<Checkbox checked={drawIncompletePermurtation} onChange={(e) => {
            self.setState({ drawIncompletePermurtation: e.target.checked }, self.drawPermutations.bind(self));
        }} />} label="Draw Incomplete" />

        let layout = <IconButton onClick={() => self.layout()}><LayoutIcon /></IconButton>;
        let showPermutations = <IconButton onClick={this.showPermutations.bind(this)}><DrawIcon /></IconButton>;
        let exportPNG = <IconButton onClick={this.export.bind(this)}><DownloadIcon /></IconButton>;

        console.log(`Nodes `, nodes);
        console.log(`edges `, edges);


        let elements = CytoscapeComponent.normalizeElements({
            nodes,
            edges
        });


        if (self.cy) {
            self.cy.gridGuide({
                drawGrid: true,
                guidelinesStyle: {
                    strokeStyle: "black",
                    horizontalDistColor: "#ff0000",
                    verticalDistColor: "green",
                    initPosAlignmentColor: "#0000ff",
                }
            })

            let layout = self.cy.layout(layoutType);
            layout.run();
        }
        return (
            <div style={styles.div}>
                {error || progressMessage}
                {drawIncompleteToggle}
                {showPermutations}
                {layout}
                {exportPNG}
                <IconButton disabled={progress !== null} onClick={this.generatePermuations.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                <IconButton disabled={progress === null} onClick={this.cancelGenerate.bind(this)}><StopIcon /></IconButton>
                <div id="cy">
                    <CytoscapeComponent
                        cy={(cy) => { self.cy = cy }}
                        layout={layoutType}
                        elements={elements}
                        style={{ width: 1500, height: 1000 }}
                        stylesheet={[
                            {
                                "selector": "node",
                                "style": {
                                    "text-valign": "center",
                                    "text-halign": "center",
                                    "shape": "rectangle",
                                    'background-color': "white",
                                    'border-color': 'black',
                                    'border-width': 2,
                                    "label": "data(id)",
                                    "width": 130,
                                    "height": 70,
                                }
                            },
                            {
                                "selector": "node[color]",
                                "style": {
                                    "text-valign": "center",
                                    "text-halign": "center",
                                    'background-color': "data(color.bg)",
                                    'border-color': 'white',
                                    'border-width': 2,
                                    "color": "data(color.text)",
                                    "label": "data(id)",
                                }
                            },
                            {
                                "selector": "node[size]",
                                "style": {
                                    "text-valign": "center",
                                    "text-halign": "center",
                                    "width": 'data(size.width)',
                                    "height": 'data(size.height)'
                                }
                            },
                            {
                                "selector": "node[shape]",
                                "style": {
                                    "text-valign": "center",
                                    "text-halign": "center",
                                    "shape": "data(shape)",
                                    "label": "data(id)",
                                }
                            },
                            {
                                "selector": "edge",
                                "style": {
                                    "width": 1,
                                    "curve-style": "straight",
                                    "target-arrow-shape": "triangle"
                                }
                            }, {
                                "selector": "edge.hollow",
                                "style": {
                                    "target-arrow-fill": "hollow"
                                }
                            }
                        ]}
                    />
                </div>
                {dialog}

            </div>);
    }

}


const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 1500,
        height: 1000,
        borderRadius: 6
    }
};


export default DialogReportView;
