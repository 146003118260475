import {
    loadDataTypes,
    loadQuestions
} from '@apricityhealth/web-common-lib/utils/Services';

import { updateModelDataType, getModelsForDataIds } from './Models';
import { isArrayValid } from './Utils';

function isNull(value) {
    return value === '' || value === null || value === undefined;
}

class QuestionDataTypes {

    //utility to ensure questions/answers have working responses in their respective datatypes if a score tuple description exists in the dataType 
    //(no creating score datatypes if one doesn't exist)

    getPlanIds(plans, planId) {
        let planIds = [];
        let plan = plans.find((e) => e.planId === planId);
        if (plan) {
            planIds.push(plan.planId);
            if (Array.isArray(plan.dependencies)) {
                for (let i = 0; i < plan.dependencies.length; ++i) {
                    planIds.push(...this.getPlanIds(plans, plan.dependencies[i]));
                }
            }
        } else {
            console.error(`plan not found ${planId}`)
        }
        //console.debug(`planIds:`, planIds );
        return planIds;
    }

    getPrograms(plans) {
        return plans.filter((e) => e.program === true).map((e) => e.planId);
    }

    isDataTypeUsedByQuestion(plans, question, dataType) {
        const programs = this.getPrograms(plans);
        for (let i = 0; i < programs.length; ++i) {
            const programId = programs[i];
            const planIds = this.getPlanIds(plans, programId);
            if (!planIds.find((e) => e === question.planId)) continue;        // question is not under this given programId
            if (!planIds.find((e) => e === dataType.planId)) continue;        // data type is not under this given plan
            return true;
        }
        return false;
    }

    updateDataTypesForQuestion(appContext, question) {
        const { plans } = appContext.state;
        const store = appContext.stores.DataTypesStore;
        //get all possible dataTypes in questions and answers 
        //and then look for answer values to match datatypes 
        //with score tuple and make sure value exists if score exists

        //get all used datatypes and load them
        let dataIds = []
        if (question.dataId) {
            dataIds.push(question.dataId)
        }
        if (question.answers) {
            for (let i = 0; i < question.answers.length; i++) {
                let answer = question.answers[i]
                if (answer.dataId) {
                    if (dataIds.indexOf(answer.dataId) < 0) {
                        dataIds.push(answer.dataId)
                    }
                }
            }
        }

        // load the data types related to any dataId's affected by this question, we will then
        // need to pull any questions that use that same data type, no matter what plan they are on
        // and pull those answers into each data type respectively.
        console.debug("dataIds:", dataIds );
        let dataTypes = store.getDataTypes().filter((e) => dataIds.indexOf(e.dataId) >= 0);
        console.debug("dataTypes:", dataTypes ); 

        let promises = [];
        for (let i = 0; i < dataTypes.length; ++i) {
            let dataType = dataTypes[i];
            promises.push(new Promise((resolve, reject) => {
                console.debug("updating data type:", dataType );

                // grab all questions from all plans that are related to this dataId,
                // don't use the store since we may not have those questions loaded up because
                // of the selected plan.
                Promise.all([
                    loadQuestions(appContext, { planId: '*', dataId: dataType.dataId, resolveConflicts: false }),
                    loadDataTypes(appContext, { planId: '*', dataId: dataType.dataId, resolveConflicts: false })
                ]).then(([questions, dataTypes]) => {
                    // filter down to questions, that actually use this data type
                    questions = questions.filter((q) => this.isDataTypeUsedByQuestion(plans, q, dataType));
                    dataTypes = dataTypes.filter((t) => questions.filter((q) => this.isDataTypeUsedByQuestion(plans, q, t)).length > 0 );

                    console.debug(`Questions using data type:`, dataType, questions );
                    console.debug(`Updating Data types:`, dataTypes )

                    let tupleDesc = dataType.tupleDescriptions.find((e) => e.classType === 'Score');
                    if (tupleDesc) {
                        tupleDesc.valueDescriptions = [];
                        for (let j = 0; j < questions.length; ++j) {
                            const question = questions[j];
                            const answers = question.dataId === dataType.dataId ? question.answers : question.answers.filter((e) => e.dataId === dataType.dataId);
                            //console.debug("mappinig:", question.mapping );
                            if (isArrayValid(question.mapping)) {
                                for (let k = 0; k < question.mapping.length; ++k) {
                                    tupleDesc.valueDescriptions.push({
                                        value: String(question.mapping[k].v),
                                        description: question.mapping[k].description
                                    });
                                }
                            }
                            else if (isArrayValid(answers)) {
                                for (let k = 0; k < answers.length; ++k) {
                                    const answer = answers[k];
                                    if (isArrayValid(answer.mapping)) {
                                        for (let n = 0; n < answer.mapping.length; ++n) {
                                            tupleDesc.valueDescriptions.push({
                                                value: String(answer.mapping[n].v),
                                                description: answer.mapping[n].description
                                            });
                                        }
                                    } else {
                                        if (!isNull(answer.noAnswerScore)) {
                                            tupleDesc.valueDescriptions.push({
                                                value: String(answer.noAnswerScore),
                                                description: "[[noAnswer]]"
                                            });
                                        }
                                        if (!isNull(answer.score)) {
                                            tupleDesc.valueDescriptions.push({
                                                value: String(answer.score),
                                                description: answer.text[0]
                                            });
                                        }
                                    }
                                }
                            }
                        }

                        // sort and de-duplicate the valueDescriptions
                        console.debug("valueDescriptions:", tupleDesc.valueDescriptions);
                        tupleDesc.valueDescriptions = tupleDesc.valueDescriptions.sort((a, b) => { 
                            let diff = Number(a.value) - Number(b.value);
                            if ( diff !== 0 ) return diff;
                            return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
                        }).filter(
                            (v, i, a) => a.findIndex((k) => Number(k.value) === Number(v.value) && k.description === v.description) === i);

                        return Promise.all(dataTypes.map((t) => {
                            t.tupleDescriptions = dataType.tupleDescriptions;
                            return store.saveDataType(t, t.planId)
                        }));
                    }
                }).then((dataType) => {
                    resolve(dataType);
                }).catch((err) => {
                    console.error("updateDataTypesForQuestion error:", err);
                    reject(err);
                })
            }));
        }

        return Promise.all(promises).then((results) => {
            console.debug("All Question Data types updated:", results);
            // now that the data types are updated, update any related models
            return updateModelDataType(appContext, getModelsForDataIds(appContext, dataIds));
        }).then((models) => {
            console.debug("Updating question models:", models);
            return Promise.all(models.map((e) => store.saveModel(e, e.planId)));
        }).then((results) => {
            console.debug("All question models updated:", results);
        }).catch((err) => {
            console.error("updateDataTypesForQuestion error:", err);
        });
    }
}

export default new QuestionDataTypes();

