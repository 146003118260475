import React from 'react';
import Papa from 'papaparse';
import SW from 'stopword';

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {
    AppBar,
    IconButton,
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';

import {
    //eslint-disable-next-line
    MedicationType, isArrayValid, saveMedication
} from '@apricityhealth/web-common-lib/utils/Services';

import { SelectMedication } from '@apricityhealth/web-common-lib/components/SelectMedication';
import { SelectCodeSystem, TypeOntology } from '@apricityhealth/web-common-lib/components/TypeSearch';
import UploadCsv from '@apricityhealth/web-common-lib/components/UploadCsv';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

/**
 * @typedef {Object} MedicationFile
 * 
 * @property {Object} fileMeta
 * @property {String} fileMeta.name
 * @property {{system, url}} fileMeta.codeSystem
 * @property {String} fileMeta.parentId
 * @property {Array<MedicationType>} medications
 */
/**
 * Medication List Upload Utility. Indicate CodeSystem/parentId values for imported Medication(s)
 * @memberof module:Rosetta
 * @alias BulkUploadMedicationsView
 */
class BulkUploadMedicationsView extends React.Component {
//#region Component-Lifecycle Methods
    constructor (props) {
        super(props);
        this.state = {
            dialog: null,
            progress: null,
            medicationFiles: [],
            amountUploaded: 0,
            uploadedSize: 1,
            statusUpdate: ''
        };

        // if (typeof this.onSave !== 'function') throw new ReferenceError(
        //     'medication-save not found');
    }

    onCodeSystem(e, i = 0) {
        const { state: { medicationFiles } } = this,
            { system, url } = e;

        if (typeof system !== 'string' || typeof url !== 'string') throw new TypeError(
            'CodeSystemUpdateEvent<`e`> must contain a string-tuple');

        medicationFiles[i].fileMeta.codeSystem = { system, url };
        this.setState({ medicationFiles });
    }

    onParentId(e, i = 0) {
        const { state: { medicationFiles } } = this,
            { medicationId } = e;

        medicationFiles[i].fileMeta.parentId = medicationId;
        this.setState({ medicationFiles });
    }
//#endregion

    /**
     * Search the MedicationTreeTypes for specified value
     * 
     * @param {String} system Ontology-Terminology System
     * @param {String} code Assigned coding by `system`
     * @param {String} [description] Human-readable code
     * @returns {MedicationType|undefined} Medication, if found
     */
    find(system, code, description = '') {
        const { props: { appContext } } = this;
        const types = appContext.stores.DataTypesStore.getMedications();

        /* Validate inputs | Log errors */
        if (!isArrayValid(types)) return void console.error('no type data <Array>');
        if (typeof code !== 'string') return void console.error(
            'parameter `code` must be a string');
        if (typeof description !== 'string') return void console.error(
            'parameter `description` must be a string');

        switch (system)
        {
            case TypeOntology.Systems.ICD10.Url:
            case TypeOntology.Systems.LOINC.Url:
            case TypeOntology.Systems.NCBO.Url:
            case TypeOntology.Systems.NDC.Url:
            case TypeOntology.Systems.RxNORM.Url:
            case TypeOntology.Systems.SNOMED.Url:
                return types.find(
                    c => c.system.startsWith(system) && c.code === code);
            default:
                return void console.warn('ontology system unsupported');
        };
    }

    processFiles(files) {
        const {
            props: { appContext },
            state: { medicationFiles, parentId }
        } = this,
            { state: { plan } } = appContext;
        const promises = [];

        for (const file of /**@type {FileList}*/(files)) promises.push(file.text().then( text => {
            const csvTuples = Papa.parse(text, { header: true, skipEmptyLines: true });
            const medicationList = csvTuples.data.reduce((acc, m) => {
                if (!m || !m.name || !m.code) console.warn(
                    'processFiles: `medicationType` must contain both `.name` & `.code` fields');
                else {
                    m.parentId = parentId;
                    m.planId = plan.planId;
                    m.code = String(m.code).trim();
                    m.name = String(m.name).trim();
                    m.displayName = String(m.displayName).trim();
                    m.route = String(m.route).trim();
                    m.strength = String(m.strength).trim();
                    m.keywords = SW.removeStopwords(m.name.split(' '), SW.en)
                    // Remove [,|:|;|.|(|)] from filtered keyword(s)
                    .map(ct => ct.replace(/,?|:?|;?|\.?|\(?|\)?/g, ''));
                    acc.push(m);
                }
                return acc;
            }, []);
            const codeSystem = Object.seal({ system: '', url: '' }),
                fName = file.name.toLowerCase();

            if (fName.includes(TypeOntology.Systems.ICD10.Name)) {
                codeSystem.system = TypeOntology.Systems.ICD10.Name;
                codeSystem.url = TypeOntology.Systems.ICD10.Url;
            } else if (fName.includes(TypeOntology.Systems.LOINC.Name)) {
                codeSystem.system = TypeOntology.Systems.LOINC.Name;
                codeSystem.url = TypeOntology.Systems.LOINC.Url;
            } else if (fName.includes(TypeOntology.Systems.SNOMED.Name)) {
                codeSystem.system = TypeOntology.Systems.SNOMED.Name;
                codeSystem.url = TypeOntology.Systems.SNOMED.Url;
            } else if (fName.includes(TypeOntology.Systems.RxNORM.Name)) {
                codeSystem.system = TypeOntology.Systems.RxNORM.Name;
                codeSystem.url = TypeOntology.Systems.RxNORM.Url;
            }

            return Object.seal(
            { // Gather File metadata & contents
                fileMeta: Object.seal(
                {
                    name: file.name,
                    codeSystem,
                    parentId: ''
                }),
                medications: medicationList
            });
        }));


        Promise.all(promises).then(mFiles => {
            if (isArrayValid(medicationFiles)) for (const mFile of mFiles)
                if (medicationFiles.some( mf => (mf.fileMeta.name === mFile.fileMeta.name) &&
                    (mf.medications.length === mFile.medications.length) )) continue;
                else medicationFiles.push(mFile);
            else medicationFiles.push(...mFiles)
            this.setState({ medicationFiles })
        });
    }

    /**
     * Save Medications from imported list(s)
     * 
     * @param {Boolean} close Close this view after save?
     * @param {Boolean} ignore Ignore changes? `[no-save]`
     * 
     * @throws {ReferenceError} `props.onSave` handler not found
     */
    bulkSave({ close = false, ignore = false } = {}) {
        const {
            state: { medicationFiles }
        } = this;

        this.setState({ statusUpdate: 'Preparing meds for upload...' }, () => {
            setTimeout(() => {
                if (ignore) return void this.onSave([], true);
                else if (isArrayValid(medicationFiles))
                {
                    const mArr = [];
                    for (const mFile of medicationFiles) try
                        {
                            const {
                                medications,
                                fileMeta: { parentId, codeSystem: { url: system } }
                            } = mFile;
                            /* Check each MedicationList[File] for `required` fields */
                            // if (! parentId) throw new ReferenceError('no `parentId` selected');
                            if (!system) throw new ReferenceError('no `system` selected');
                            /* Update each MedicationList's medication(s) w/ `required` fields */
                            for (const m of medications) void (m.parentId = parentId, m.system = system, m);
                            mArr.push(...medications);
                        } catch (err)
                        {
                            console.error('BulkSave:', err);
                        }

                    console.log('BulkSave:', mArr.length);
                    return this.onSave(mArr, !close);
                    // }
                }
                else return void this.onSave([], true);
            }, 200);
        });
    }

    onSave(newMedications, close = true) {
        const {
            props: { appContext },
        } = this;

        const diff = [];
        for (const m of newMedications)
        {
            const found = this.find(m.system, m.code);
            if (found)
            {
                found.name = m.name;
                found.displayName = m.displayName;
                found.route = m.route;
                found.strength = m.strength;
                found.keywords = m.keywords;
                diff.push(found);
            } else
            {
                diff.push(m);
            }
        }

        this.setState({ uploadedSize: diff.length, statusUpdate: 'Uploading ' + diff.length + ' meds.' }, () => {

            const sequential = require('promise-sequential');
            let promises = [];

            const chunk = 1000;
            for (let i = 0; i < diff.length; i += chunk)
            {
                const slice = diff.slice(i, i + chunk);

                promises.push(() => {
                    return saveMedication(appContext, slice)
                    .then(() => {
                        this.setState({ amountUploaded: this.state.amountUploaded + slice.length }, () => {
                            // console.log(' PERCENTAGE ', this.state.amountUploaded)
                        })
                    })
                    .catch(err => {
                        console.error('MedicationTypes.BulkSave:', err);
                    });
                })
            }

            sequential(promises).then(() => {
                this.setState({ statusUpdate: "Done with errors.  Inspect Log" });
            });

            // this.setState({ types: [...types] });
        });
    }

    render() {
        const {
            props: { appContext, onClose },
            state: { medicationFiles, dialog, progress, amountUploaded, uploadedSize }
        } = this;

        return <div styles={styles.div}>
            <AppBar style={styles.appBar} position='static'><Toolbar>
                <IconButton onClick={onClose} >
                    <NavigationClose />
                </IconButton>
                <Typography variant='h6' color='inherit'>Upload Medication Types</Typography>
            </Toolbar></AppBar>
            {dialog}
            {progress}
            <table style={styles.table}><tbody>
                <tr>
                    <th>Select Medication File(s)</th>
                    <td colSpan={2}>Spreadsheet/CSV must contain the column header(s) 'name' & 'code'</td>
                    <td align='right'>
                        <UploadCsv tooltip='Import Medication List `[.csv|.tsv|xls]`'
                            onChange={this.processFiles.bind(this)} />
                        <Tooltip title='Save/Upload Medication List(s)'><IconButton
                            onClick={() => this.bulkSave()}><SaveIcon />
                        </IconButton></Tooltip>
                    </td>
                </tr>
                {medicationFiles.map((mFile, i) => <React.Fragment key={i}>
                    <tr>
                        <th align='left'>{mFile.fileMeta.name}</th>
                        <td>Found {mFile.medications.length} code(s)</td>
                        <td><SelectCodeSystem system={mFile.fileMeta.codeSystem.name || 'RxNORM'}
                            onChange={e => this.onCodeSystem.call(this, e, i)} /></td>
                        <td><SelectMedication label='Parent Medication' appContext={appContext}
                            enableNone onSelect={e => this.onParentId.call(this, e, i)} /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style={{ width: '100px' }}>
                            <CircularProgressbar
                                maxValue={uploadedSize}
                                value={amountUploaded}
                                text={`${Math.floor(amountUploaded * 100 / uploadedSize)}%`}
                            />
                        </td>
                        <td>
                            {amountUploaded === uploadedSize ? 'Done' : this.state.statusUpdate}
                        </td>
                    </tr>
                </React.Fragment>)}
            </tbody></table>
        </div>;
    }
};


const styles = {
    appBar: {
        backgroundColor: '#FF9800',
        width: '1000px'
    },
    button: { margin: 10 },
    buttonHeader: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    checkbox: { marginBottom: 16 },
    div: { margin: 10, padding: 10 },
    flex: { flex: 1 },
    menuButton: { marginLeft: -12, marginRight: 20 },
    menuExpansion: {
        background: '#ffffff',
        justifyContent: 'left',
        width: '800px'
    },
    menuExpansionDetails: {
        background: '#ffffff',
        padding: '0px',
        width: '800px'
    },
    menuExpansionSummary: { justifyContent: 'left' },
    menuList: {
        justifyContent: 'left',
        width: '500px'
    },
    openButton: { margin: 15 },
    placeholder: {
        fontSize: '0.75rem',
        lineHeight: 1,
        position: 'absolute',
        top: -22,
        transition: 'top 0.1s, font-size 0.1s'
    },
    question: { margin: 5, width: '80%' },
    sectionRow: {
        marginBottom: 15,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 15
    },
    select: { margin: 10, width: 200 },
    tags: { margin: 5 },
    text: { margin: 5 },
    tab: { backgroundColor: 'lightblue' },
    table: { width: '1000px' }
};


export default BulkUploadMedicationsView;