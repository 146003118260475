import React, { Component } from "react";
import Config from '@apricityhealth/web-common-lib/Config';
import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import {
    Tab, Tabs, IconButton, CircularProgress, MenuItem, Select,
    FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogContentText,
    TextField, DialogActions, Button
} from '@material-ui/core/';
import { v4 as uuidv4 } from 'uuid';
import RefreshIcon from '@material-ui/icons/Refresh';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import PubSub from 'pubsub-js'
import T from 'i18n-react';
import DetectModel from './DetectModel';
import { isArrayValid } from "../utils/Utils";
import '../styles/doc.css';

const Diff = require('diff');
const stringify = require('json-stable-stringify');

const ENVIRONMENTS = Config.environments;
class ContentReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            error: null,
            expanded: null,
            currentTab: 0,
            environment1: 'develop',
            environment2: 'test',
            alerts1: [],
            detectModels1: [],
            models1: [],
            alerts2: [],
            detectModels2: [],
            models2: [],
            username: null,
            password: null,
            mfaCode: null,
            device: null,
            group: props.group || 'experts',
        };

        //srtore the 'current' env as a token key for the drop down access
        Object.keys(ENVIRONMENTS).forEach(environment => {
            if (environment === Config.stage) {
                console.log(`storing  token`, environment)
                sessionStorage.setItem(`${environment}idToken`, props.appContext.state.idToken);
            }
        });

    }

    componentDidUpdate(oldProps) {
        let { appContext } = this.props;
        let currentPlanId = appContext.state.plan ? appContext.state.plan.planId : null;
        let oldPlanId = oldProps.appContext.state.plan ? oldProps.appContext.state.plan.planId : null;
        console.log(`this planId`, currentPlanId)
        console.log(`oldProps planId`, oldPlanId)
    }

    componentDidMount() {
        console.log(`ContentReview componentDidMount`)
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        self.loadContent();
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }


    loadContent() {
        const self = this;
        let { alerts1, detectModels1, alerts2, detectModels2, environment1, environment2 } = this.state;
        const { appContext } = this.props;


        let token1 = sessionStorage.getItem(`${environment1}idToken`);
        let token2 = sessionStorage.getItem(`${environment2}idToken`);

        if (environment1 !== Config.stage && !token1) {
            self.showLoginDialog(environment1);
        } else if (environment2 !== Config.stage && !token2) {
            self.showLoginDialog(environment2);
        } else {
            let promises = [];
            promises.push(loadModels(appContext, '*', environment1, token1, true));
            promises.push(loadModels(appContext, '*', environment2, token2, true));
            promises.push(loadDetectModels(appContext, '*', environment1, token1, true));
            promises.push(loadDetectModels(appContext, '*', environment2, token2, true));
            promises.push(getDetectAlerts(appContext, environment1, token1, true));
            promises.push(getDetectAlerts(appContext, environment2, token2, true));

            this.setState({ progress: <CircularProgress size={20} />, error: null })
            Promise.all(promises).then((response) => {
                let models1 = response[0];
                let models2 = response[1];
                detectModels1 = response[2];
                detectModels2 = response[3];
                alerts1 = response[4];
                alerts2 = response[5];
                self.setState({ progress: null, detectModels1, alerts1, models1, detectModels2, alerts2, models2 });
            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    cancel() {
        this.setState({ dialog: null });
    }

    showLoginDialog(environment) {
        let self = this;
        let { username, password, progress, error } = this.state;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    maxWidth={'md'}
                    width='800px'
                    onClose={() => self.cancel()}
                >
                    <DialogTitle>Login</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{error ? `There was an  ${error} error logging in.` : ""}</DialogContentText>
                        <br />
                        <DialogContentText>Login to the {environment} environment</DialogContentText>
                        <br />
                        <TextField autoComplete="email" style={styles.input} autoFocus={true} id="username" label={<T.span text="email" />} value={username}
                            onChange={(e) => { self.setState({ username: e.target.value }); }}
                        />
                        <br />
                        <TextField autoComplete="current-password" style={styles.input} id="password" type="password" label={<T.span text="password" />} value={password}
                            onChange={(e) => { self.setState({ password: e.target.value }); }}
                        />
                        <br />

                        <br />
                        {progress}
                    </DialogContent>
                    <DialogActions>
                        <Button id="loginSubmit" color="primary" variant="contained" style={styles.button} onClick={this.onLogin.bind(this, environment)}><T.span text="submit" /></Button>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.cancel() }} >Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }


    onLogin(environment) {
        const self = this;
        console.log(`login to `, environment)
        this.setState({ progress: <CircularProgress />, dialog: null, error: null, isLoading: true });
        let { username, password, group, mfaCode, Session } = this.state;
        let { device } = this.state;
        let idToken = sessionStorage.getItem(`${environment}idToken`);
        let userId = null;
        let refreshToken = null;
        if (!device) {
            device = localStorage.getItem('device')
            if (!device) {
                console.log(`device id not found `);
                device = uuidv4();
                localStorage.setItem('device', device);
            }
        }
        self.setState({ device });
        login(username, password, group, environment, mfaCode, Session, device).then((login) => {
            console.log("Login response:", environment, login);
            idToken = login.idToken;
            sessionStorage.setItem(`${environment}idToken`, idToken);
            userId = login.userId;
            refreshToken = login.refreshToken;
            return getAccount(idToken, userId, environment);
        }).then((account) => {
            self.props.appContext.setState({
                account,
                username,
                idToken,
                userId,
                refreshToken,
                device
            });
            self.loadContent();
        }).catch((err) => {
            console.error("onLogin error:", err);
            self.setState({ isLoading: false, error: getErrorMessage(err) + ` ${environment}` }, self.showLoginDialog.bind(self, environment))


        });
    }

    getDetectModelCompare() {
        let { detectModels1, alerts1, models1, detectModels2, alerts2, models2, environment1, environment2 } = this.state;

        let tables = [];

        if (isArrayValid(detectModels1)) {
            for (let index = 0; index < detectModels1.length; index++) {
                let detectModel1 = detectModels1[index];
                cleanRecord(detectModel1);
                let model1String = stringify(detectModel1, { space: 4 });
                detectModel1 = JSON.parse(model1String);
                let detectModel2 = detectModels2.find((e) => e.modelId === detectModel1.modelId);
                let rows = [];
                let hasDiff = false;
                let newModel = false;
                if (detectModel2) {
                    cleanRecord(detectModel2);
                    let model2String = stringify(detectModel2, { space: 4 });
                    detectModel2 = JSON.parse(model2String);
                    // let diffResults = Diff.createTwoFilesPatch('unknown', 'unknown', stringify(detectModel1, { space: 4 }), stringify(detectModel2, { space: 4 }));
                    let diffResults = Diff.diffJson(model1String, model2String);
                    // let diffResults = Diff.diffChars(detectModel1, detectModel2);
                    // console.log(`diffResults`, diffResults);
                    for (let index = 0; index < diffResults.length; index++) {
                        if (diffResults[index].added || diffResults[index].removed) {
                            hasDiff = true;
                            break;
                        }
                    }
                    rows.push(<tr paddingBottom="-10px" marginBottom="-10px"><td marginBottom="-10px">{environment2}:</td></tr>);
                    rows.push(<tr><td key={index} ><DetectModel appContext={this.props.appContext} readOnly={true} alerts={alerts2} detectModel={detectModel2} model={models2} key={index} />  </td></tr>);
                } else {
                    //new model
                    hasDiff = true;
                    newModel = true;
                }
                if (hasDiff) {

                    let content = <table style={{ width: '100%' }}><tbody>
                        {newModel ? <tr style={{ marginBottom: "-10px" }}><td style={{ marginBottom: "-10px" }}>New Model; </td><td marginBottom="-10px">{environment1}:</td></tr> : <tr><td marginBottom="-10px">{environment1}:</td> </tr>}
                        <tr>
                            <td colSpan='2' key={2 + index}><DetectModel appContext={this.props.appContext} readOnly={true} alerts={alerts1} detectModel={detectModel1} model={models1} key={2 + index} /> </td>
                        </tr>
                        {rows}
                    </tbody>
                    </table>
                    tables.push(content);
                    tables.push(<div className='break' />);
                }
            }
        }

        return isArrayValid(tables) ? tables : <div><br />No changes</div>;

    }



    render() {
        const self = this;
        const { error, progress, dialog, currentTab, environment1, environment2 } = self.state;

        let menuItems = [];
        Object.keys(ENVIRONMENTS).forEach(environment => {
            menuItems.push(<MenuItem key={environment} value={environment}>{ENVIRONMENTS[environment].name}</MenuItem>)
        });

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td>
                    <FormControl style={styles.select}>
                        <InputLabel>Environment 1</InputLabel>
                        <Select styles={{ margin: 0 }} value={environment1} onChange={(e) => { self.setState({ environment1: e.target.value }) }}>
                            {menuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={styles.select}>
                        <InputLabel>Environment 2</InputLabel>
                        <Select styles={{ margin: 0 }} value={environment2} onChange={(e) => { self.setState({ environment2: e.target.value }, self.loadContent.bind(self)) }}>
                            {menuItems}
                        </Select>
                    </FormControl>
                </td>
                <td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={() => self.loadContent()}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <Tabs value={currentTab} onChange={(e, t) => self.setState({ currentTab: t })}>
                        <Tab label="Detect" />
                        <Tab label="Condition" />
                    </Tabs>
                    {currentTab === 0 && self.getDetectModelCompare()}
                    {/* {currentTab === 1 && dataReport}  */}
                    {dialog}
                </td>
            </tr>
        </tbody>
        </table>;
    }


}



function loadModels(appContext, modelId, env, idToken, dependencies = false, category = '') {
    let pathPrefix = ENVIRONMENTS[env].pathPrefix
    return new Promise((resolve, reject) => {
        if (!appContext.state.plan) {
            return reject(new Error("No plan selected."));
        }
        let planId = appContext.state.plan.planId;
        if (!modelId)
            modelId = '*';
        const request = {
            url: Config.baseUrl + `${pathPrefix}types/${planId}/models/${modelId}?dependencies=${dependencies}`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        if (category)
            request.url += `&category=${category}`;

        console.log(`loadModels request`, request);
        Axios(request).then((response) => {
            console.log(`loadModels response`, response);
            resolve(response.data);
        }).catch((error) => {
            console.log(`loadModels error`, getErrorMessage(error) + ` ${env}`);
            //clear the token it may have expired
            console.log(`clearing  token for `, env)
            sessionStorage.removeItem(`${env}idToken`);
            reject(error);
        });
    });
}

function loadDetectModels(appContext, modelId, env, idToken, dependencies = false, category = '') {
    let pathPrefix = ENVIRONMENTS[env].pathPrefix
    return new Promise((resolve, reject) => {
        if (!appContext.state.plan) {
            return reject(new Error("No plan selected."));
        }
        let planId = appContext.state.plan.planId;
        if (!modelId)
            modelId = '*';
        const request = {
            url: Config.baseUrl + `${pathPrefix}types/${planId}/detect_models/${modelId}?dependencies=${dependencies}`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        if (category)
            request.url += `&category=${category}`;

        console.log(`loadDetectModels request`, request);
        Axios(request).then((response) => {
            console.log(`loadDetectModels response`, response);
            resolve(response.data);
        }).catch((error) => {
            console.error("loadDetectModels error", getErrorMessage(error) + ` ${env}`);
            //clear the token it may have expired
            console.log(`clearing  token for `, env)
            sessionStorage.removeItem(`${env}idToken`);
            reject(error);
        });
    });
}

function getDetectAlerts(appContext, env, idToken, dependencies = false) {
    let pathPrefix = ENVIRONMENTS[env].pathPrefix
    return new Promise((resolve, reject) => {
        let planId = appContext.state.plan ? appContext.state.plan.planId : null;
        const request = {
            url: Config.baseUrl + `${pathPrefix}types/${planId}/alert_levels/*?dependencies=${dependencies}`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        console.log(`getDetectAlerts request `, request);
        Axios(request).then((response) => {
            console.log(`getDetectAlerts response `, response);
            let alerts = {};
            for (let i = 0; i < response.data.length; ++i) {
                let level = response.data[i];
                alerts[level.alertLevelId] = level;
            }
            resolve(alerts);
        }).catch((error) => {
            console.error(`getDetectAlerts error `, getErrorMessage(error) + ` ${env}`);
            //clear the token it may have expired
            console.log(`clearing  token for `, env)
            sessionStorage.removeItem(`${env}idToken`);
            reject(error);
        });
    });
}


function login(username, password, group, env, mfaCode = null, Session = null, device = null) {
    let pathPrefix = ENVIRONMENTS[env].pathPrefix
    return new Promise((resolve, reject) => {

        const request = {
            url: Config.baseUrl + `${pathPrefix}authentication/login`,
            method: 'POST',
            data: {
                "Username": username.trim(),
                "Password": password.trim(),
                "Group": group
            }
        };
        if (mfaCode)
            request.data.mfaCode = mfaCode.trim();

        if (device)
            request.data.device = device.trim();

        console.log("login request", request);
        Axios(request).then((response) => {
            console.log("login response:", response);
            resolve(response.data)
        }).catch((error) => {
            console.log("login error:", getErrorMessage(error) + ` ${env}`);
            //clear the token it may have expired
            console.log(`clearing  token for `, env)
            sessionStorage.removeItem(`${env}idToken`);
            reject(error);
        });
    });
}

function getAccount(idToken, userId, env) {
    let pathPrefix = ENVIRONMENTS[env].pathPrefix;
    return new Promise((resolve, reject) => {
        const request = {
            url: Config.baseUrl + `${pathPrefix}authentication/users/${userId}`,
            method: 'GET',
            headers: { "Authorization": idToken }
        };
        console.log("getAccount request", request);
        Axios(request).then((response) => {
            console.log("getAccount response:", response);
            resolve(response.data.user)
        }).catch((error) => {
            console.log("getAccount error:", getErrorMessage(error) + ` ${env}`);
            //clear the token it may have expired
            console.log(`clearing  token for `, env)
            sessionStorage.removeItem(`${env}idToken`);
            resolve({})     // getAccount can fail for non-providers, so ignore any error.
        });
    });
}


export function cleanRecord(record, cleanNested = true) {
    if (record) {
        delete record._id;
        delete record.__v;
        delete record._version;
        delete record.createdAt;
        delete record.updatedAt;
        delete record.createDate;
        delete record.planId;
        delete record.userId;
        //delete record.ownerId;

        if (cleanNested) {
            for (let prop in record) {
                if (Array.isArray(record[prop])) {
                    let arr = record[prop];
                    for (let i = 0; i < arr.length; ++i) {
                        cleanRecord(arr[i]);
                    }
                }
                else if (typeof record[prop] === 'object') {
                    cleanRecord(record[prop]);
                }
            }
        }
    }
}



const styles = {
    button: {
        margin: 10
    },
    select: {
        margin: 5,
        width: 300
    },
    input: {
        margin: 5,
        width: 300
    },
};

export default ContentReview;
