import React, { Component } from "react";
import _ from 'lodash';
import {
    IconButton,
    Typography,
    Tooltip
} from '@material-ui/core/';
import { Redirect } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import BackIcon from '@material-ui/icons/ArrowBack';
import VisibilityOn from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import QuestionUtils from '../utils/QuestionUtils';
import DetectModel from '../reports/DetectModel';
import RecommendationModelReportView from '../reports/RecommendationModelReportView';
import { MuiAccordion, MuiAccordionSummary, MuiAccordionDetails } from '../views/SymptomModelView'
import {
    isArrayValid,
} from '../utils/Utils';
import PubSub from 'pubsub-js'
import { parseCode } from '@apricityhealth/web-common-lib/utils/ParseCode';
import Logo from './logo.svg';
import '../styles/tableedit.css';

function getQuestionText(question) {
    return question.question[0].text;
}

function getAnswerText(answer, addScore = false) {
    if (addScore === true)
        return answer.text[0] + ` (${answer.score})`;
    else
        return answer.text[0];
}

let NAMES = {
    'pro': "Condition Model",
    'lab': "Lab Model",
    "triage": "Triage Model",
    "survey": "Survey Model",
    "symptom": "Symptom Model"
}


export class PrintModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelId: this.props.location && this.props.location.state && this.props.location.state.modelId ? this.props.location.state.modelId : props.modelId,
            showTriggerSymptoms: this.props.location && this.props.location.state && _.isBoolean(this.props.location.state.showTriggerSymptoms) ? this.props.location.state.showTriggerSymptoms : true,
            showPrimarySymptoms: this.props.location && this.props.location.state && _.isBoolean(this.props.location.state.showPrimarySymptoms) ? this.props.location.state.showPrimarySymptoms : true,
            showAnswers: true,
            componentVisible: {
                "Condition Model": true,
                "Model": true,
                "Lab Model": true,
                "Survey Model": true,
                "Triage Model": true,
                "Symptom Model": true,
                "Detect Rules": true,
                "Triage Detect Rules": true,
                "Model Questions": true,
                "Survey Questions": true,
                "Triage Questions": true,
                "Recommendations": true,
                "Trigger Symptoms": true,
                "Primary Symptoms": true
            },
            dialog: null
        }

        console.log(`location state `, this.props.location)
    }

    componentDidMount() {
        this.loadContent();
        this.planToken = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                //go back to the main models page
                this.setState({ dialog: <Redirect to={{ pathname: "/" }} /> });
            }
        });

        this.dataToken = PubSub.subscribe('STORE_UPDATE', () => {
            this.loadContent();
        });
        let { appContext } = this.props;
        const { plan } = appContext.state;
        if (plan)
            this.questionUtils = new QuestionUtils(appContext);
    }

    loadContent() {
        let { appContext } = this.props;
        let { modelId } = this.state;
        const store = appContext.stores.DataTypesStore;
        let models = store.getModels();
        let model = models.find(model => model.modelId === modelId);

        this.setState({ model });

    }

    onDone() {
        if (typeof this.props.onDone === 'function') {
            this.props.onDone()
        }
    }
    getHeadingTable() {
        let { model } = this.state;
        return <table className="profile_table" style={{ width: "500px" }}>
            <tbody>
                <tr>
                    <th >Name</th>
                    <td>{model && model.name}</td>
                </tr>
                <tr>
                    <th>Description</th>
                    <td>{model && model.description}</td>
                </tr>
            </tbody>
        </table>;
    }

    getSymptoms(symptoms) {
        let rows = [];
        if (isArrayValid(symptoms)) {
            symptoms.forEach(symptom => {
                rows.push(<tr><td>{symptom.description ? symptom.description : symptom.dataId}</td></tr>)
            });
        }

        return <table className="profile_table" style={{ width: "500px" }}>
            <tbody>
                <tr>
                    <th >Symptom</th>
                </tr>
                {rows}
            </tbody>
        </table>;

    }

    getDetectTable() {
        let { appContext, showDetectModels } = this.props;
        if (!showDetectModels) return null;
        let { model } = this.state;
        const store = appContext.stores.DataTypesStore;
        let alertLevels = store.getAlertLevels();
        let detectModels = store.getDetectModels();
        let models = store.getModels();
        let detectModel = detectModels.find(nextModel => nextModel.modelDataId === model.dataId);
        return detectModel && <DetectModel appContext={appContext} readOnly={true} alerts={alertLevels} detectModel={detectModel} model={models} print key={1} />;
    }

    getTriageDetectTable() {
        let { appContext, showDetectModels } = this.props;
        if (!showDetectModels) return null;
        let { model } = this.state;
        const store = appContext.stores.DataTypesStore;
        let alertLevels = store.getAlertLevels();
        let detectModels = store.getDetectModels();
        let detectModel = detectModels.find(nextModel => nextModel.modelDataId === model.dataId);
        let priorityDataId = detectModel ? detectModel.priorityDataId : null;
        let models = store.getModels();
        let triageModel = models.find(model => { return isArrayValid(model.triggerSymptoms) ? model.triggerSymptoms[0].dataId === priorityDataId : null });
        if (triageModel) {
            let triageDetectModel = detectModels.find(nextModel => nextModel.modelDataId === triageModel.dataId);
            return <DetectModel appContext={appContext} readOnly={true} alerts={alertLevels} detectModel={triageDetectModel} model={models} print key={1} />;
        } else {
            return null;
        }
    }

    getRecommendationsTable() {
        let { appContext, showRecommendations } = this.props;
        if (!showRecommendations) return null;
        let { model } = this.state;
        const store = appContext.stores.DataTypesStore;
        let recommendModels = store.getRecommendModels();
        let detectModels = store.getDetectModels();
        let detectModel = detectModels.find(nextModel => nextModel.modelDataId === model.dataId);
        let priorityDataId = detectModel ? detectModel.priorityDataId : null;
        let recommendModel = recommendModels.find(nextModel => {
            if (!nextModel.groups) nextModel.groups = [];
            return nextModel.groups.find(group => {
                let funct = parseCode(group.dataConditions);
                if (funct && isArrayValid(funct.functions)) {
                    let isDataInRange = funct.functions[0];
                    if (isDataInRange && isArrayValid(isDataInRange.args))
                        return isDataInRange.args[1].string === priorityDataId;
                }
                return false;
            });

        });

        return recommendModel && <RecommendationModelReportView appContext={appContext} model={recommendModel} print />;
    }

    getModelTable() {
        let { model } = this.state;
        let tables = [];
        tables.push(this.getQuestionTable(this.gatherModelQuestions(model.primarySymptoms), "Primary Symptom Questions"));
        tables.push(this.getQuestionTable(this.gatherModelQuestions(model.associatedSymptoms), "Associated Symptom Questions"));
        tables.push(this.getQuestionTable(this.gatherModelQuestions(model.clinicalModifiers), "Clinical Modifier Questions"));
        tables.push(this.getQuestionTable(this.gatherModelQuestions(model.negativeSymptoms), "Pertinent Negative Questions"));
        tables.push(this.getQuestionTable(this.gatherModelQuestions(model.positiveSymptoms), "Pertinent Positive Questions"));
        return tables;

    }

    getTriageModelTable() {
        let { appContext } = this.props;
        let { model } = this.state;
        const store = appContext.stores.DataTypesStore;
        let detectModels = store.getDetectModels();
        let detectModel = detectModels.find(nextModel => nextModel.modelDataId === model.dataId);
        let priorityDataId = detectModel ? detectModel.priorityDataId : null;
        let models = store.getModels();
        let triageModel = models.find(model => { return isArrayValid(model.triggerSymptoms) ? model.triggerSymptoms[0].dataId === priorityDataId : null });
        if (triageModel) {
            let tables = [];
            tables.push(this.getQuestionTable(this.gatherModelQuestions(triageModel.primarySymptoms), "Primary Symptom Questions"));
            tables.push(this.getQuestionTable(this.gatherModelQuestions(triageModel.associatedSymptoms), "Associated Symptom Questions"));
            tables.push(this.getQuestionTable(this.gatherModelQuestions(triageModel.clinicalModifiers), "Clinical Modifier Questions"));
            tables.push(this.getQuestionTable(this.gatherModelQuestions(triageModel.negativeSymptoms), "Pertinent Negative Questions"));
            tables.push(this.getQuestionTable(this.gatherModelQuestions(triageModel.positiveSymptoms), "Pertinent Positive Questions"));
            return tables;
        } else {
            return null;
        }

    }

    gatherModelQuestions(symptoms) {
        let { appContext, showQuestions } = this.props;
        if (!showQuestions) return null;
        const store = appContext.stores.DataTypesStore;
        let modelQuestions = [];
        for (let index = 0; index < symptoms.length; index++) {
            const modelSymptom = symptoms[index];
            let links = store.getDataTypeLinks(modelSymptom.dataId).links;
            if (isArrayValid(links)) {
                links.forEach(link => {
                    if (link.question) {
                        this.questionUtils.unlocalizeQuestion(link.question); // very important that we convert each question to regular text
                        modelQuestions.push(link.question);
                    }
                });
            } else {
                console.log(`no links found for `, modelSymptom.dataId)
            }
        }
        return modelQuestions;
    }

    getQuestionTable(questions, title) {
        let { showQuestions } = this.props;
        if (!showQuestions) return null;
        let { showAnswers } = this.state;
        if (isArrayValid(questions)) {
            let rows = [];
            for (let index = 0; index < questions.length; index++) {
                let question = questions[index];
                let answers = question.answers;
                rows.push(<tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td>
                        {<span dangerouslySetInnerHTML={{ __html: getQuestionText(question) }} />}
                        {showAnswers && <ol>{isArrayValid(answers) ? answers.map(ans => <li key={ans.answerId}> <span dangerouslySetInnerHTML={{ __html: getAnswerText(ans, true) }} /></li>) : "No Answers"}</ol>}
                    </td>
                </tr>);;
            }

            return <table className="styled_table">
                <tbody>
                    <tr ><th className="question_section_heading" colSpan="2" >{title}</th></tr>
                    <tr >
                        <th style={{ width: "4%" }}>Order</th>
                        <th style={{ width: "100%" }}>Question</th>
                    </tr>
                    {rows}
                </tbody>
            </table>;
        } else {
            return null;
        }

    }

    getChunk(title, component) {
        let { componentVisible } = this.state;
        if (componentVisible[title]) {
            return component && <MuiAccordion square expanded={componentVisible[title]} onChange={() => { componentVisible[title] = !componentVisible[title]; this.setState({ componentVisible }) }}>
                <MuiAccordionSummary>
                    <Typography className="section_title">{title}</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                    <p style={{ "page-break-inside": "avoid", width: "100%" }}>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr ><td>{component}</td></tr>
                            </tbody>
                        </table>
                    </p>
                </MuiAccordionDetails>
            </MuiAccordion>
        } else {
            return null;
        }
    }

    render() {
        let { showQuestions, showRecommendations, showDetectModels, showPrimary, showTrigger, } = this.props;
        let { showAnswers, model, dialog, showTriggerSymptoms, showPrimarySymptoms } = this.state;

        let headingTable = model && this.getChunk("Model", this.getHeadingTable());
        let modelTables = null;
        let triageModelTable = null;
        let detectTable = null;
        let triageDetectTable = null;
        let triggerSymptomTable = showTriggerSymptoms && showTrigger && model && this.getChunk("Trigger Symptoms", this.getSymptoms(model.triggerSymptoms));
        let primarySymptomTable = showPrimarySymptoms && showPrimary && model && this.getChunk("Primary Symptoms", this.getSymptoms(model.primarySymptoms));

        if (model && model.category === 'triage') {
            //just the 2 
            modelTables = showQuestions && this.getChunk("Triage Questions", this.getModelTable());
            detectTable = showDetectModels && model && this.getChunk("Triage Detect Rules", this.getDetectTable());
        } else if (model && model.category === 'survey') {
            modelTables = showQuestions && this.getChunk("Survey Questions", this.getModelTable());
        } else if (model) {
            //follow the crumbs.. 
            modelTables = showQuestions && this.getChunk("Model Questions", this.getModelTable());
            detectTable = showDetectModels && model && this.getChunk("Detect Rules", this.getDetectTable());
            triageModelTable = this.getChunk("Triage Questions", this.getTriageModelTable());
            triageDetectTable = this.getChunk("Triage Detect Rules", this.getTriageDetectTable());
        }


        let recommendTable = showRecommendations && model && this.getChunk("Recommendations", this.getRecommendationsTable());
        return <StylesProvider>

            <table className="button_table">
                <tbody>
                    <tr>
                        <td>
                            <Tooltip title={"Back"}><IconButton onClick={() => this.props.history.goBack()}><BackIcon /></IconButton></Tooltip>
                        </td>
                        <td align='right'>
                            <Tooltip title={showAnswers ? 'Hide Answers' : "Show Answers"}><IconButton onClick={() => { this.setState({ showAnswers: !showAnswers }) }}>{showAnswers ? <VisibilityOff /> : <VisibilityOn />}</IconButton></Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="header">
                <tbody>
                    <tr>
                        <td>
                            <img className="logo" src={Logo} alt="logo" />
                            <div className="barTop" />
                            <div className="verticalBar" />
                            <div className="barBottom" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />

            <div className="page">
                {model && <div className="documentTitle">{NAMES[model.category]}</div>}
                {headingTable}
                {triggerSymptomTable}
                {primarySymptomTable}
                {modelTables}
                {detectTable}
                {triageModelTable}
                {triageDetectTable}
                {recommendTable}
                {dialog}
            </div>
            <div className="footer" />
        </StylesProvider>

    }
}
export default PrintModel;