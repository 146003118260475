import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles, Avatar } from '@material-ui/core/';
import {
    Chip,
    FormLabel,
    Tooltip,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core/';
import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';
import {
    arrayRemoveLocation,
    arrayInsertLocation,
    isArrayValid

} from '../utils/Utils';
import SelectModelType from './SelectModelType'

class EditModelTypeArrayView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            array: props.array,
            name: props.name,
            menu: null
        };
    }

    onAdd = index => e => {
        let { array } = this.state;
        //just add a default one
        arrayInsertLocation(array, 0, "");
        this.setState({ array });
    }

    onAddModelSelect(type, index) {
        let { array } = this.state;
        array[index] = type.modelId
        this.setState({ array, menu: null });
    }
    onChangeModel = index => e => {
        let { array } = this.state;
        const { appContext } = this.props;
        // if (e)
        //     preventClickthrough(e);

        var menu = <Menu open={true} onClose={this.closeMenu.bind(this)} anchorEl={e.target}>
            <MenuItem>
                <SelectModelType
                    appContext={appContext}
                    modelId={array[index]}
                    onSelect={(type) => this.onAddModelSelect(type, index)} />
            </MenuItem>

        </Menu>;

        this.setState({ menu });

    }

    closeMenu() {
        this.setState({ menu: null });
    }

    static getDerivedStateFromProps(props, state){
        const hasNewArray = JSON.stringify(state.array) !== JSON.stringify(props.array) 
        const hasNewName = state.name !== props.name 
        if (hasNewArray || hasNewName){
            const array = props.array ? props.array : [];
            const name = props.name;
            return { array:array, name:name };
        }else{
            return null
        }
    }

    onDelete = index => e => {
        let { array } = this.state;
        arrayRemoveLocation(array, index);
        this.setState({ array });
    }

    render() {
        let { array, menu } = this.state;
        let self = this;

        let dataChips = [];
        if (isArrayValid(array)) {
            for (let index = 0; index < array.length; index++) {
                let data = array[index];
                dataChips.push(
                    <Chip
                        key={index}
                        avatar={<Avatar><FaceIcon style={{ marginBottom: '0px' }} /></Avatar>}
                        label={data}
                        onClick={self.onChangeModel(index)}
                        onDelete={self.onDelete(index)}
                    />);

            }
        }
        let label = <FormLabel>{this.props.label || "Edit Models"}</FormLabel>;
        if (this.props.tip)
            label = <Tooltip title={this.props.tip}>{label}</Tooltip>;

        return (<div >
            <div style={{ marginTop: '5px' }} />
            {label}
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={self.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div >
        )
    }
}

const styles = {
    div: {
        margin: 10
    }
};

EditModelTypeArrayView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditModelTypeArrayView);
