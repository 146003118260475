import React, { Component } from "react";
import '../styles/doc.css';

import {
    IconButton, CircularProgress
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh'

import {
    loadDetectModels,
    loadModels,
    getAlertLevels
} from '@apricityhealth/web-common-lib/utils/Services';

import {
    isArrayValid
} from '../utils/Utils';

import DetectModel from './DetectModel';
import PubSub from 'pubsub-js'

class DetectModelRulesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            detectModels: props.detectModels,
            alerts: props.alerts,
            dialog: null,
            messages: props.appContext.state.messages
        };
        console.log(`DetectModelRulesView`, props.detectModels);
    }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.setState({ detectModels: self.props.detectModels, alerts: self.props.alerts }, this.loadContent.bind(this))
            }
        });
        this.loadContent();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    componentDidUpdate(oldProps) {
        if (this.props.detectModels !== oldProps.detectModels) {
            console.log("detectModel changed:", this.props.detectModels);
            this.setState({ detectModels: this.props.detectModels, alerts: this.props.alerts }, this.loadContent.bind(this))
        }
    }

    loadContent() {
        const self = this;
        let { alerts, detectModels } = this.state;
        const { appContext } = this.props;

        let promises = [];
        promises.push(loadModels(appContext, { dependencies: true }));
        promises.push(detectModels ? Promise.resolve(detectModels) : loadDetectModels(appContext, { dependencies: true} ));
        promises.push(alerts ? Promise.resolve(alerts) : getAlertLevels(appContext, { dependencies: true } ));

        this.setState({ progress: <CircularProgress size={20} />, error: null })
        Promise.all(promises).then((response) => {
            let models = response[0];
            detectModels = response[1];
            alerts = response[2];

            console.log("loadContent:", detectModels);
            self.setState({ progress: null, detectModels, alerts, models });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }


    getReport(detectModel, model) {
        let { appContext } = this.props;
        let rows = []
        let { alerts } = this.state;
        if (isArrayValid(detectModel)) {
            for (let j = 0; j < detectModel.length; j++) {
                let nextModel = detectModel[j];
                rows.push(<DetectModel appContext={appContext} readOnly={this.props.readOnly} alerts={alerts} detectModel={nextModel} model={model} key={j} onChange={(detectModel) => { this.props.onChange(detectModel) }} />);
            }
        } else {
            rows.push(<DetectModel appContext={appContext} readOnly={this.props.readOnly} alerts={alerts} detectModel={detectModel} model={model} />);
        }
        return rows;
    }



    render() {
        const { error, progress, dialog, detectModels, models } = this.state;
        let report = (isArrayValid(detectModels) && isArrayValid(models)) ? this.getReport(detectModels, models) : null;

        return <table style={{ width: '98%' }}><tbody>
            <tr>
                <td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this, detectModels)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td>
                    {report}
                    {dialog}
                </td>
            </tr>
        </tbody></table>
    }
}


export default DetectModelRulesView;
