import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    IconButton,
    Tooltip,
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

import ImportIcon from '@material-ui/icons/GetApp';
import CheckPatientsIcon from '@material-ui/icons/PlayArrow';

import Config from '@apricityhealth/web-common-lib/Config';
import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import TrialView from './TrialView';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

function testFilter(filter, trial) {
    if (trial) {
        filter = filter.toLowerCase();
        if (trial.name && trial.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (trial.description && trial.description.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (trial.trialId && trial.trialId.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}
class TrialsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            resetTrials: false,
            error: null,
            showExpired: false,
            showActive: false,
            country: '',
            status: ''
        }
    }

    componentWillMount() {
    }

    onLoadTrials({ offset, limit, order, orderBy }) {
        const { search, showExpired, showActive, country, status } = this.state;
        const args = [
            `offset=${offset}`,
            `limit=${limit}`,
            `order=${order}`,
            `orderBy=${orderBy}`,
            `showExpired=${showExpired}`,
        ]
        if (showActive) {
            args.push('active=true');
        }
        if (country) {
            args.push(`country=${encodeURIComponent(country)}`)
        }
        if (status) {
            args.push(`status=${encodeURIComponent(status)}`)
        }
        if (search) {
            args.push(`search=${encodeURIComponent(search)}`)
        }
        const getTrials = {
            url: Config.baseUrl + `${Config.pathPrefix}trials?${args.join('&')}`,
            method: 'GET',
            headers: { "Authorization": this.props.appContext.state.idToken }
        };

        console.log("getTrials:", getTrials);
        this.setState({ error: null });
        return Axios(getTrials).then((response) => {
            console.log("getTrials response:", response.data);
            return { data: response.data.trials, dataLength: response.data.totalTrials };
        }).catch((err) => {
            console.log("getTrials error:", err.response);
            this.setState({ error: getErrorMessage(err) })
        });
    }
    updateFilter() {
        let { trials, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < trials.length; ++i) {
                if (testFilter(filter, trials[i]))
                    filtered.push(trials[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }
    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._trials) {
            this._trials.setState({ selected: [] });
            this._trials.loadData();
        }
    }

    onCreateTrial() {
        const { appContext } = this.props;
        let trial = {
            startDate: Date(),
            endDate: null,
            name: '',
            description: ''
        }
        const dialog = <div className='drawer' ><Drawer variant="persistent" anchor="right" open={true} >
            <TrialView appContext={appContext} trial={trial} onClose={this.onCloseDialog.bind(this)} />
        </Drawer> </div>;
        this.setState({ progress: null, dialog });
    }

    onTrialSelected(s, t) {
        console.log(`trial selected `, s);
        if (s.length > 0) {
            const appContext = this.props.appContext;
            const trial = t.state.data[s[0]];
            console.log(`trial selected `, trial);
            const dialog = <div className='drawer' >
                <Drawer variant="persistent" anchor="right" open={true}>
                    <TrialView appContext={appContext} trial={trial} onClose={this.onCloseDialog.bind(this)} />
                </Drawer>
            </div>;
            this.setState({ progress: null, dialog });
        }
        else {
            // nothing selected..
            this.setState({ progress: null, dialog: null });
        }
    }

    onImportPatientTrials() {
        const { search, resetTrials } = this.state;

        this.setState({
            dialog: <Dialog open={true}>
                <DialogTitle>Confirm Import Patient Trials</DialogTitle>
                <DialogContent>Please confirm that you would like to import any new trials from the internet?<br />
                    <TextField label='Trial Search Terms' value={search} onChange={(e) => {
                        this.setState({ search: e.target.value }, this.onImportPatientTrials.bind(this))
                    }} style={{ width: '95%', margin: 5 }} /><br />
                    <i>(e.g. "Ovarian Cancer" OR "Prostate Cancer", "Cancer" AND "Lung")</i><br />
                    <FormControlLabel label='Reset All Trials' control={<Checkbox checked={resetTrials} onChange={(e) => {
                        this.setState({ resetTrials: e.target.checked }, this.onImportPatientTrials.bind(this))
                    }} />} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ dialog: null })}>Cancel</Button>
                    <Button onClick={() => {
                        const { appContext: { state: { idToken } } } = this.props;
                        const { search, resetTrials } = this.state;
                        const importPatientTrials = {
                            url: Config.baseUrl + `${Config.pathPrefix}trials/importPatientTrials?async=true&reset=${resetTrials}`,
                            method: 'GET',
                            headers: { "Authorization": idToken }
                        };
                        if (search) {
                            importPatientTrials.url += `&search=${encodeURIComponent(search)}`;
                        }

                        console.log("importPatientTrials request:", importPatientTrials);
                        this.setState({ dialog: null, error: null });
                        Axios(importPatientTrials).then((result) => {
                            console.log("importPatientTrials result:", result.data);
                        }).catch((err) => {
                            this.setState({ error: getErrorMessage(err) });
                        })
                    }}>Confirm</Button>
                </DialogActions>
            </Dialog>
        });
    }

    onCheckPatientsForTrials() {
        this.setState({
            dialog: <Dialog open={true}>
                <DialogTitle>Confirm Check Patients for Trials</DialogTitle>
                <DialogContent>Please confirm that you would like to check all patients to see if they qualify for a trial?</DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ dialog: null })}>Cancel</Button>
                    <Button onClick={() => {
                        const { appContext: { state: { idToken } } } = this.props;
                        const checkPatientsForTrial = {
                            url: Config.baseUrl + `${Config.pathPrefix}trials/checkPatientsForTrials?async=true`,
                            method: 'GET',
                            headers: { "Authorization": idToken }
                        };

                        console.log("checkPatientsForTrial request:", checkPatientsForTrial);
                        this.setState({ dialog: null, error: null });
                        Axios(checkPatientsForTrial).then((result) => {
                            console.log("checkPatientsForTrial result:", result.data);
                        }).catch((err) => {
                            this.setState({ error: getErrorMessage(err) });
                        })
                    }}>Confirm</Button>
                </DialogActions>
            </Dialog>
        });
    }

    render() {
        const { dialog, error, showExpired, showActive, country, status } = this.state;
        let { filter } = this.state;

        const columnData = [
            { id: 'nctId', label: 'NCT ID' },
            { id: 'name', label: 'Name' },
            { id: 'description', label: 'Description' },
            { id: 'status', label: 'Status' },
            { id: 'intervention', label: 'Intervention' },
            { id: 'endDate', editType: 'dateLabel', label: 'End Date' }
        ];
        return <table width='100%'>
            <tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                            onChange={(e) => { this.setState({ search: e.target.value }) }}
                            onKeyDown={(e) => { if (e.keyCode === 13) this._trials.loadData(); }}
                        />
                        <TextField style={{ margin: 5, width: 500 }} value={country} label="Country"
                            onChange={(e) => { this.setState({ country: e.target.value }) }}
                            onKeyDown={(e) => { if (e.keyCode === 13) this._trials.loadData(); }}
                        />
                        <TextField style={{ margin: 5, width: 500 }} value={status} label="Status"
                            onChange={(e) => { this.setState({ status: e.target.value }) }}
                            onKeyDown={(e) => { if (e.keyCode === 13) this._trials.loadData(); }}
                        />
                        <FormControlLabel label='Show Expired' control={<Checkbox checked={showExpired}
                            onClick={(e) => this.setState({ showExpired: e.target.checked }, () => this._trials.loadData())} />} />
                        <FormControlLabel label='Show Active' control={<Checkbox checked={showActive}
                            onClick={(e) => this.setState({ showActive: e.target.checked }, () => this._trials.loadData())} />} />
                    </td>
                    <td align='right'>
                        {dialog}
                        <span style={{ color: 'red' }}>{error}</span>
                        <Tooltip title='Import Patient Trials'><IconButton onClick={this.onImportPatientTrials.bind(this)}>
                            <ImportIcon /></IconButton></Tooltip>
                        <Tooltip title='Check Patients For Trials'><IconButton onClick={this.onCheckPatientsForTrials.bind(this)}>
                            <CheckPatientsIcon /></IconButton></Tooltip>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => this._trials = table}
                            onSelected={(s, t) => this.onTrialSelected(s, t)}
                            onAdd={() => this.onCreateTrial()}
                            orderBy='trialId'
                            columnData={columnData}
                            data={this.onLoadTrials.bind(this)}
                            title='Trials' />
                    </td>
                </tr>
            </tbody>
        </table>
    }
}

export default TrialsView;

