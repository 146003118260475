import { Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    IconButton, 
    TextField, 
    Tooltip, 
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem     
} from '@material-ui/core';
import React from 'react';
import GridLayout from 'react-grid-layout';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import 'react-grid-layout/css/styles.css';
import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex, { Tuple } from '@apricityhealth/web-common-lib/components/SelectTupleIndex';

const COMPARISON_MAP = {
    "gt": ">",
    "gte": ">=",
    "eq": "=",
    "lt": "<",
    "lte": "<="
};

class EditFilterDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: JSON.parse( JSON.stringify( props.filter ))
        }
    }

    render() {
        const self = this;
        const { filter } = this.state;

        return <Dialog open={true} PaperProps={{style: {overflowY: 'visible'}}}>
            <DialogTitle>Edit Filter</DialogTitle>
            <DialogContent style={{ overflowY: 'visible' }}>
                <SelectDataType appContext={this.props.appContext} dataId={filter.dataId} onChange={(type) => {
                    filter.dataId = type ? type.dataId : '';
                    self.setState({filter});
                }} />
                <br />
                <SelectTupleIndex appContext={this.props.appContext} dataId={filter.dataId} tupleIndex={filter.tupleIndex} onChange={(tupleIndex) => {
                    filter.tupleIndex = tupleIndex;
                    self.setState({filter});
                }} />
                <br />
                <FormControl style={{ width: 100, margin: 5 }}>
                    <InputLabel>Comparison</InputLabel>
                    <Select value={filter.comparison} onChange={(e) => {
                        filter.comparison = e.target.value;
                        self.setState({ filter });
                    }}>
                        <MenuItem value='gte'>{'>='}</MenuItem>
                        <MenuItem value='gt'>{'>'}</MenuItem>
                        <MenuItem value='eq'>{'='}</MenuItem>
                        <MenuItem value='lt'>{'<'}</MenuItem>
                        <MenuItem value='lte'>{'<='}</MenuItem>
                    </Select>
                </FormControl>
                <TextField style={{width: 100, margin: 5}} label='Value' value={filter.value} onChange={(e) => {
                    filter.value = e.target.value;
                    self.setState({ filter } );
                }} />
                <TextField style={{width: 100, margin: 5}} label='Day' type='number' value={filter.day} onChange={(e) => {
                    filter.day = Number(e.target.value);
                    self.setState({ filter } );
                }} />
                <TextField style={{width: 100, margin: 5}} label='Duration' type='number' value={filter.duration} onChange={(e) => {
                    filter.duration = Number(e.target.value);
                    self.setState({ filter } );
                }} />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={this.props.onCancel}>Cancel</Button>
                <Button variant='contained' onClick={this.props.onDelete}>Delete</Button>
                <Button variant='contained' onClick={() => self.props.onDone( filter )}>Done</Button>
            </DialogActions>
        </Dialog>;
    }
};

export default class DataFilters extends React.Component {
    constructor(props) {
        super(props);

        let filters = JSON.parse(JSON.stringify(props.filters || []));
        let maxDays = props.maxDays || 30;
        for(let i=0;i<filters.length;++i) {
            let lastDay = filters[i].day + filters[i].duration;
            if ( lastDay > maxDays ) maxDays = lastDay;
        }

        this.state = {
            maxDays,
            filters,
            label: props.label || 'Data Filter',
            dialog: null
        }
    }

    onEditFilter( filters, index ) {
        const self = this;
        console.log("onEditFilter:", filters, index );
        this.setState({dialog: <EditFilterDialog appContext={this.props.appContext} filter={filters[index]} 
            onCancel={() => self.setState({dialog: null})}
            onDelete={this.onDeleteFilter.bind(this, filters, index)}
            onDone={(filter) => { 
                filters[index] = filter;
                let maxDays = Math.max( filter.day + filter.duration, self.state.maxDays);
                // we need to set to null first, to force the grid to rebuild
                self.setState({maxDays, filters: [], dialog: null}, () => {
                    self.setState({filters});
                });
            }} />});
    }

    onDeleteFilter( filters, index ) {
        console.log("onDeleteFilter:", filters, index );
        filters.splice( index, 1 );
        this.setState({filters, dialog: null});
    }

    onDragEnd( args ) {
        console.log("onDragEnd:", args );
    }

    onAddFilter() {
        const { filters } = this.state;
        filters.push( { day: 0, duration: 7, dataId: '', tupleIndex: 0, comparison: 'gt', value: '0'});
        this.setState({filters});
        if (this.props.onChange) this.props.onChange( filters );
    }

    onLayoutChange(layout) {
        console.log("onLayoutChange:", layout );

        let { filters } = this.state;
        for(let i=0;i<layout.length;++i) {
            let filter = filters[layout[i].i];
            filter.day = layout[i].x;
            filter.duration = layout[i].w;
        }
        this.setState({filters});
        if (this.props.onChange) this.props.onChange( filters );
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { filters, dialog, maxDays } = this.state;

        let grid = [];
        for(let i=0;i<filters.length;++i) {
            let filter = filters[i];
            let label = filter.dataId ? <span>
                {filter.dataId}[<Tuple appContext={appContext} dataId={filter.dataId} tupleIndex={filter.tupleIndex} />]{COMPARISON_MAP[filter.comparison]} {filter.value}
                </span> : null;
            grid.push(<div key={i} data-grid={{x: filter.day, y: i, w: filter.duration, h: 1, minH: 1, maxH: 1 }} style={{whiteSpace: 'nowrap'}}>
                <div style={{ display: 'block', flexDirection: 'row', whiteSpace: 'nowrap', overflow: 'hidden', alignContent: 'center'}}>
                    <Tooltip title='Edit'><IconButton onClick={this.onEditFilter.bind(this, filters, i)}><EditIcon fontSize={'small'} /></IconButton></Tooltip>
                    {label}
                </div>
                <i style={{fontSize: 9}}>Day: {filter.day}, Days: {filter.duration}</i>
            </div>);
        }

        return <table style={{width: '100%'}}><tbody>
            <tr>
                <td>
                    <Typography>{this.state.label}</Typography>
                </td>
                <td align='right'>
                    <TextField label={'Max Days'} style={{margin: 5, width: 75}} type='number' value={maxDays} onChange={(e) => {
                        self.setState({maxDays: Number(e.target.value)})
                    }}/>
                    <Tooltip title='Add'><IconButton onClick={this.onAddFilter.bind(this)}><AddIcon /></IconButton></Tooltip>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <GridLayout className='layout' cols={maxDays} rowHeight={50} width={1200} compactType='' isBounded={true} onLayoutChange={this.onLayoutChange.bind(this)}>
                        {grid}
                    </GridLayout>
                    {dialog}
                </td>
            </tr>
        </tbody></table>;
    }
}