import React, { Component } from 'react';
import { 
    Drawer, 
    CircularProgress, 
    TextField, 
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip
 } from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import ValidIcon from '@material-ui/icons/CheckCircle';

import ModelTypeView from './ModelTypeView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';

import PubSub from 'pubsub-js'
import { loadModels } from '@apricityhealth/web-common-lib/utils/Services';
import EditableNote from '../components/EditableNote'
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';

function testFilter(filter, model) {
    if (model) {
        filter = filter.toLowerCase();
        if (model.name && model.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.description && model.description.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.modelId && model.modelId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.dataId && model.dataId.toLowerCase().indexOf(filter) >= 0)
            return true;
            
        function testSymptoms(symptoms, filter) {
            if ( Array.isArray(symptoms)) {
                for(let i=0;i<symptoms.length;++i) {
                    let symptom = symptoms[i];
                    if ( symptom.dataId && symptom.dataId.toLowerCase().indexOf(filter) >= 0)
                        return true;
                }
            }
            return false;
        }

        if ( testSymptoms( model.triggerSymptoms, filter))
            return true;
        if ( testSymptoms( model.primarySymptoms, filter))
            return true;
        if ( testSymptoms( model.associatedSymptoms, filter))
            return true;
        if ( testSymptoms( model.clinicalModifiers, filter))
            return true;
        if ( testSymptoms( model.laboratory, filter))
            return true;
        if ( testSymptoms( model.negativeSymptoms, filter))
            return true;
    }
    return false;
}

export class ModelTypesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            types: []
        }
    }

    componentDidMount() {
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }
    
    componentDidUpdate(oldProps) {
        if ( oldProps.category !== this.props.category) {
            this.loadContent();
        } else if ( this.props.appContext.state.plan ) {
            this.handleOpen();     
        }
    }
    
    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        let { appContext, category } = this.props;
        let { dependencies } = this.state;
        
        self.setState({ progress: <CircularProgress size={20} />, error: null });

        loadModels(appContext, { dependencies, category } ).then((types) => {
            self.setState( { types: types, progress: null }, self.onContentLoaded.bind(self));
        }).catch((err) => {
            console.log("loadModels error:", err);
            self.setState( { types: [], error: err.message, progress: null })
        });
    }

    onContentLoaded() {
        this.updateFilter();
        this.handleOpen();
    }

    handleOpen() {
        // automatically open the question by ID if provided..
        let parts = window.location.pathname.slice(1).split('/');
        if ( parts.length > 1) {
            window.history.replaceState( null, '',  window.location.href.substring( 0, window.location.href.lastIndexOf('/') ));
            this.onOpenModel( parts[1] );
        }
    }

    updateFilter() {
        let { types, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < types.length; ++i) {
                if (testFilter(filter, types[i]))
                    filtered.push(types[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    typeSelected(s, t) {
        console.log("typeSelected:", s, t);
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const type = self.state.filtered ? self.state.filtered[s[0]] : self.state.types[s[0]];
            self.onOpenModel(type.modelId)
        }
        else {
            // nothing selected..
            self.setState({ progress: null, dialog: null });
        }
    }

    onOpenModel( modelId ) {
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <ModelTypeView appContext={this.props.appContext} modelId={modelId} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    createType() {
        console.log("createType");
        const self = this;
        const { appContext, category } = this.props;
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <ModelTypeView appContext={appContext} modelId='' category={category} onClose={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }
    render() {
        const self = this;
        const { filtered, types, dialog, error, progress, dependencies } = this.state;
        const { category, appContext } = this.props;
        const errors = appContext.stores.DataTypesStore.getErrors(`${category}_models`).errors;
        let { filter } = this.state;

        const columnData = [
            { id: 'modelId', width: 25, label: 'Valid', formatValue: (v) => {
                return errors[v] ? <Tooltip key='error' title={errors[v].join(', ')}><WarningIcon style={{ fill: 'red'}} /></Tooltip> : <ValidIcon key='valid' style={{ fill: 'green'}} />;
            }},
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'modelId', numeric: false, disabledPadding: false, label: 'Model ID' },
            { id: 'order', numeric: true, disabledPadding: false, label: 'Order' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } },
            { id: 'inactive', label: 'Inactive', formatValue: (v) => toBoolean(v) ? "Yes" : "No" }
        ];

        return (
            <table width='100%'><tbody>
            <tr>
                <td>
                    <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                        onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                    <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                            self.setState({dependencies: v}, self.loadContent.bind(self));
                        }} />}label="Show Dependencies" />
                </td>
                <td align="right">
                        {error}
                        <EditableNote textId={`modelTypesTooling`} planId={"tooling"} />
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <EnhancedTable
                        title=''
                        disableMultiSelect={true}
                        onTable={table => self._types = table}
                        onSelected={(s, t) => self.typeSelected(s, t)}
                        onAdd={() => self.createType()}
                        orderBy='name'
                        columnData={columnData}
                        data={filtered ? filtered : types} />
                    {dialog}
                </td>
            </tr>
            </tbody></table> 
        );
    }
}

export default ModelTypesView;
