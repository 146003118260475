
import Config from '@apricityhealth/web-common-lib/Config';
import React from 'react';
import T from 'i18n-react';

import { Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'mobx-react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import PubSub from 'pubsub-js';

import { LoginView } from '@apricityhealth/web-common-lib/views/LoginView';
import Stores from './stores';

import ActionsView from './views/ActionsView';
import AlertLevelsView from './views/AlertLevelsView';
import AlertTypesView from './views/AlertTypesView';
import BroadcastGroupsView from './views/BroadcastGroupsView';
import ChangeReport from './reports/ChangeReport';
import ConditionTypesView from './views/ConditionTypesView/ConditionTypesView';
import ConfigurationsView from './views/ConfigurationsView';
import ContentView from './views/ContentView';
import ContentReview from './reports/ContentReview';
import DataModelsView from './views/DataModelsView';
import DataTypesView from './views/DataTypesView';
import DetectModelRulesView from './reports/DetectModelRulesView';
import DetectModelsView from './views/DetectModelsView';
import DialogReportView from './reports/DialogReportView';
import EducationView from './views/EducationView';
import FlagTypesView from './views/FlagTypesView';
import FollowupTypesView from './views/FollowupTypesView';
import GraphTypesView from './views/GraphTypesView';
import MedicationTypesView from './views/MedicationTypesView/MedicationTypesView';
import ModelTypesView from './views/ModelTypesView';
import ObservationTypesView from './views/ObservationTypesView/ObservationTypesView';
import PlansView from './views/PlansView';
import PredictorModelsView from './views/PredictorModelsView';
import ProcedureTypesView from './views/ProcedureTypesView';
import QuestionsReport from './reports/QuestionsReport';
import PlanHeirarchyReport from './reports/PlanHeirarchyReport';
import QuestionsView from './views/QuestionsView';
import RecommendationsReport from './reports/RecommendationsReport';
import RecommendationTypesView from './views/RecommendationTypesView';
import RecommendModelsView from './views/RecommendModelsView';
import TypesView from './views/TypesView';
import RulesView from './views/RulesView';
import TrialsView from './views/TrialsView';
import SymptomModelsView from './views/SymptomModelsView'
import SymptomModelView from './views/SymptomModelView';
import SymptomsReport from './reports/SymptomsReport';
import TextsView from './views/TextsView';
import ValidationReportView from './reports/ValidationReportView';
import ModelsReport from './reports/ModelsReport';

import PrintModel from './print/PrintModel'

import {
    Button, Box,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    MuiThemeProvider,
    TextField
} from '@material-ui/core/';

import { Logger } from '@apricityhealth/web-common-lib';

import AppHeader from './AppHeader';
import MenuView from './MenuView';
import SelectMode from './views/SelectMode';
import SelectTheme from '@apricityhealth/web-common-lib/components/SelectTheme';
import { SelectLanguage } from '@apricityhealth/web-common-lib/components/SelectLanguage';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/';
import { getTheme } from '@apricityhealth/web-common-lib/themes/manifest';

import './App.css';

const log = new Logger();
const clientVersion = require('../package.json').version;

log.update({ clientName: 'rosetta-web-client', clientVersion });

class SettingsDialog extends React.Component {
    constructor(props) {
        super(props);

        const appContext = props.appContext;
        const { updateInterval, mode, language, theme } = appContext.state;

        this.state = {
            updateInterval,
            mode,
            language,
            theme
        };
    }

    render() {
        const { state: { mode, theme, language, updateInterval } } = this;
        return <Dialog open model='false' onClose={() => this.props.appContext.setState({ dialog: null })}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <SelectMode appContext={this} mode={mode} onChange={nMode => {
                    this.setState({ mode: nMode }); localStorage.setItem('mode', nMode);
                }} />
                <br />
                <SelectTheme appContext={this} theme={theme} onChange={nTheme => {
                    this.setState({ theme: nTheme });
                }} />
                <br />
                <SelectLanguage appContext={this} language={language} onChange={nLanguage => {
                    this.setState({ language: nLanguage }); localStorage.setItem('language', nLanguage);
                }} />
                <br />
                <TextField style={{ margin: 5 }} label={'Update Interval'} value={updateInterval} type='numeric' onChange={(v) => {
                    this.setState({ updateInterval: v.target.value }); localStorage.setItem('updateInterval', v.target.value);
                }} />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' style={styles.button} onClick={this.onAppySettings.bind(this)}>Ok</Button>
            </DialogActions>
        </Dialog>;
    }

    onAppySettings() {
        const { appContext } = this.props;
        const { updateInterval, mode, theme, language } = this.state;
        localStorage.setItem("language", language);
        localStorage.setItem("updateInterval", updateInterval);
        localStorage.setItem("mode", mode);

        appContext.setState({ updateInterval, mode, theme, language, dialog: null });
        appContext.handleMenuClose();
        if (updateInterval > 0)
            appContext.stores.DataTypesStore.startUpdates(updateInterval);
        else
            appContext.stores.DataTypesStore.stopUpdates();
    }
}

/**
 * Medical Terminology-Ontology`¿TermOnTology?` Platform
 * @module Rosetta
 * @copyright Apricity Health Practice, LLC
 * 
 * @author Raphael Chancey <raphael.chancey@apricityhealth.com>
 * @author Richard Lyle <rlyle@apricityhealth.com>
 * @author Shawn Snyder <shawn.snyder@apricityhealth.com>
 * @author Zachary Ellis <zachary.ellis@apricityhealth.com>
 */


/**
 * Rosetta `main` Application
 * @alias App
 */
class App extends React.Component {
    //#region Component-Lifecycle Methods
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            mode: 'simple',
            auth: true,
            idToken: null,
            refreshToken: null,
            currentView: null,
            dialog: null,
            open: true,
            anchorEl: null,
            theme: 'light',
            warningOpen: false,
            warningShown: false,
            language: String(
                localStorage.getItem('language') ||
                (navigator.languages && navigator.languages[0]) ||
                navigator.language ||
                navigator.userLanguage
            ).toLowerCase()
        };
        this.stores = new Stores(this);

        /* Reset Store type_cache, then update Plan */
        PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected' && data.plan) {
                this.setState({ plan: data.plan }, () => this.stores.DataTypesStore.loadDataTypes());
            }
        });
    }


    /** @returns {Boolean} Is the current Plan protected (read-only)? */
    isProtected() {
        const { state: { plan } } = this;
        return plan && String(plan.protected) === 'true';
    }

    onSignIn() {
        let mode = localStorage.getItem('mode') || 'simple';
        let updateInterval = localStorage.getItem('updateInterval') || 30;

        this.setState({ warningOpen: this.isProtected(), mode, updateInterval });

        console.log("updateInterval:", updateInterval);
        if (updateInterval > 0) {
            this.stores.DataTypesStore.startUpdates(updateInterval);
        } else {
            this.stores.DataTypesStore.updateDataTypes();
        }
    }

    onSignOut() {
        log.debug('onSignOut()', this);

        this.handleMenuClose();
        this.setState(
            {
                dialog: <div><Dialog open model='false' onClose={() => this.cancelSignOut()}>
                    <DialogTitle>Sign Out</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to sign out?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' style={styles.button} onClick={
                            e => this.cancelSignOut()}>Cancel</Button>,
                        <Button variant='contained' style={styles.button} onClick={
                            e => this.confirmSignOut()}>Confirm</Button>
                    </DialogActions>
                </Dialog></div>
            });
    }

    cancelSignOut() {
        log.debug('cancelSignOut:', this);
        this.handleMenuClose();
        this.setState({ dialog: null });
        this.stores.DataTypesStore.stopUpdates();
    }

    confirmSignOut() {
        log.debug('confirmSignOut:', this);
        this.handleMenuClose();
        log.update({ userId: null, idToken: null });

        this.setState({ username: '', idToken: null, refreshToken: null, dialog: null, patientViews: [] });
        sessionStorage.removeItem('idToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('userId');
    }

    onSettings() {
        this.setState({ dialog: <SettingsDialog appContext={this} /> });
    }
    //#endregion


    /** Webpage Application Header focus toggle */
    onToggleLeftNav() {
        var newState = !this.state.open;
        this.setState({ open: newState });
    }

    /**
     * Focus app on given `event` target
     * @param {Event} event DOM|env `Event` w/ target|source Element `e.g. <FormData /> | Window`
     * @todo - move appHeader functions to appHeader
     */
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    /**
     * Profile Menu Drawer toggle
     * @param {Event} event DOM|env `Event`
     * @todo Handle event param
     */
    handleProfileMenuOpen = event => {
        this.setState({ accountMenuOpen: false });
    };

    closeDialog() {
        this.setState({ dialog: null });
    }

    handleWarningClose() {
        const { state: { warningOpen } } = this;
        this.setState({ warningOpen: !(warningOpen === true), warningShown: true });
        console.log(`warning close`);
    }


    render() {
        const {
            props: { classes },
            state: {
                anchorEl,
                auth, idToken,
                currentView,
                dialog, language,
                mode, open,
                progress
            }
        } = this;
        const theme = getTheme(this.state.theme);
        const isMenuOpen = Boolean(anchorEl);
        const stage = Config.stage !== 'production' ? Config.stage.toUpperCase() : '';

        T.setTexts(require(`./locale/${language}.json`));

        // display the login page if we don't have an idToken..
        if (!idToken) return <div className='App'>
            <MuiThemeProvider theme={theme}>
                <div>
                    <LoginView title={'Rosetta Login'}
                        stage={stage}
                        appContext={this} group={'experts'} // PoLP screen ?. group w/ less perms?
                        onDone={this.onSignIn.bind(this)} />
                </div>
            </MuiThemeProvider>
        </div>;
        else return <Provider {...this.stores}>
            <MuiThemeProvider theme={theme}>
                <MenuView
                    appContext={this}
                    mode={mode}
                    open={open}
                    theme={theme}
                    language={language}
                />

                <main className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                    <CssBaseline />
                    <Box display="block" displayPrint="none" m={1}>
                        <AppHeader
                            appContext={this}
                            open={open}
                            auth={auth}
                            mode={mode}
                            theme={theme}
                            isMenuOpen={isMenuOpen}
                            anchorEl={anchorEl}
                            username={this.state.username}
                            onSettings={this.onSettings.bind(this)}
                            onSignOut={this.onSignOut.bind(this)}
                            onToggle={this.onToggleLeftNav.bind(this)}
                            handleMenu={this.handleMenu.bind(this)}
                            handleMenuClose={this.handleMenuClose.bind(this)}
                        />
                    </Box>
                    {progress}

                    <Switch basename="/" >
                        <Route
                            path="/manage_content"
                            render={(props) => <ContentView {...props} appContext={this} theme={theme} />}
                        />
                        <Route
                            path="/questions"
                            render={(props) => <QuestionsView {...props} appContext={this} theme={theme} />}
                        />
                        <Route
                            path="/types"
                            render={(props) => <DataTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/symptom_models"
                            render={(props) => <ModelTypesView {...props} appContext={this} category='symptom' />}
                        />
                        <Route
                            path="/pro_models"
                            render={(props) => <ModelTypesView {...props} appContext={this} category='pro' />}
                        />
                        <Route
                            path="/survey_models"
                            render={(props) => <ModelTypesView {...props} appContext={this} category='survey' />}
                        />
                        <Route
                            path="/lab_models"
                            render={(props) => <ModelTypesView {...props} appContext={this} category='lab' />}
                        />
                        <Route
                            path="/triage_models"
                            render={(props) => <ModelTypesView {...props} appContext={this} category='triage' />}
                        />
                        <Route
                            path="/symptom_detect"
                            render={(props) => <DetectModelsView {...props} appContext={this} category='symptom' />}
                        />
                        <Route
                            path="/survey_detect"
                            render={(props) => <DetectModelsView {...props} appContext={this} category='survey' />}
                        />
                        <Route
                            path="/pro_detect"
                            render={(props) => <DetectModelsView {...props} appContext={this} category='pro' />}
                        />
                        <Route
                            path="/triage_detect"
                            render={(props) => <DetectModelsView {...props} appContext={this} category='triage' />}
                        />
                        <Route
                            path="/lab_detect"
                            render={(props) => <DetectModelsView {...props} appContext={this} category='lab' />}
                        />
                        <Route
                            path="/detect_models"
                            render={(props) => <DetectModelsView {...props} appContext={this} />}
                        />
                        <Route
                            exact
                            path="/symptom_models_unified"
                            render={(props) => <SymptomModelsView {...props} appContext={this} category='symptom' />}
                        />
                        <Route
                            exact
                            path="/symptom_models_unified/model"
                            render={(props) => <SymptomModelView {...props} appContext={this} />}
                        />
                        <Route
                            path="/data_models"
                            render={(props) => <DataModelsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/predictor_models"
                            render={(props) => <PredictorModelsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/recommend_models"
                            render={(props) => <RecommendModelsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/recommend_categories"
                            render={(props) => <TypesView {...props}
                                typePath='recommend_categories'
                                primaryKey='categoryId'
                                title='Recommendation Categories'
                                collection='RecommendCategoryModel'
                                orderBy={'name'}
                                appContext={this}
                                disableTypeView={true}
                                disableErrorColumn={true}
                                typeFields={[
                                    { id: 'categoryId', label: 'Category ID', listField: true, editField: true, editType: 'text', width: 300 },
                                    { id: 'name', label: 'Name', listField: true, editType: 'text', width: 500 },
                                    { id: 'section', label: 'Section', listField: true, editType: 'number', width: 150 }
                                ]}
                            />}
                        />
                        <Route
                            path="/recommend_group_types"
                            render={(props) => <TypesView {...props}
                                typePath='recommend_group_types'
                                primaryKey='typeId'
                                title='Recommendation Group Types'
                                collection='RecommendGroupTypeModel'
                                orderBy={'name'}
                                appContext={this}
                                disableTypeView={true}
                                disableErrorColumn={true}
                                typeFields={[
                                    { id: 'typeId', label: 'Type ID', listField: true, editType: 'text', width: 300 },
                                    { id: 'name', label: 'Name', listField: true, editType: 'text', width: 500 }
                                ]}
                            />}
                        />
                        <Route
                            path="/recommend_protocols"
                            render={(props) => <TypesView {...props}
                                typePath='recommend_protocols'
                                primaryKey='protocolId'
                                title='Recommendation Protocols'
                                collection='RecommendProtocolModel'
                                orderBy={'name'}
                                appContext={this}
                                disableTypeView={true}
                                disableErrorColumn={true}
                                typeFields={[
                                    { id: 'protocolId', label: 'Protocol ID', listField: true, editType: 'text', width: 300 },
                                    { id: 'name', label: 'Name', listField: true, editType: 'text', width: 500 },
                                    { id: 'color', label: 'Text Color', listField: true, editType: 'text' },
                                    { id: 'background', label: 'Background Color', listField: true, editType: 'text' },
                                ]}
                            />}
                        />
                        <Route
                            path="/recommendations"
                            render={(props) => <RecommendationTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/condition"
                            render={(props) => <ConditionTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/procedures"
                            render={(props) => <ProcedureTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/medications"
                            render={(props) => <MedicationTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/observations"
                            render={(props) => <ObservationTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/education"
                            render={(props) => <EducationView {...props} appContext={this} />}
                        />
                        <Route
                            path="/trials"
                            render={(props) => <TrialsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/broadcast_groups"
                            render={(props) => <BroadcastGroupsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/detect_rule_models"
                            render={(props) => <DetectModelRulesView {...props} appContext={this} readOnly />}
                        />
                        <Route
                            path="/validation_report"
                            render={(props) => <ValidationReportView {...props} appContext={this} />}
                        />
                        <Route
                            path="/recommendations_report"
                            render={(props) => <RecommendationsReport {...props} appContext={this} />}
                        />
                        <Route
                            path="/symptoms_report"
                            render={(props) => <SymptomsReport {...props} appContext={this} />}
                        />
                        <Route
                            path="/questions_report"
                            render={(props) => <QuestionsReport {...props} appContext={this} />}
                        />
                        <Route
                            path="/plan_heirarchy_report"
                            render={(props) => <PlanHeirarchyReport {...props} appContext={this} />}
                        />
                        <Route
                            path="/changes_report"
                            render={(props) => <ChangeReport {...props} appContext={this} />}
                        />
                        <Route
                            path="/models_report"
                            render={(props) => <ModelsReport {...props} appContext={this} />}
                        />
                        <Route
                            path="/plans"
                            render={(props) => <PlansView {...props} appContext={this} program={false} />}
                        />
                        <Route
                            path="/programs"
                            render={(props) => <PlansView {...props} appContext={this} program={true} />}
                        />
                        <Route
                            path="/texts"
                            render={(props) => <TextsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/actions"
                            render={(props) => <ActionsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/flags"
                            render={(props) => <FlagTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/graphs"
                            render={(props) => <GraphTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/rules"
                            render={(props) => <RulesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/followup"
                            render={(props) => <FollowupTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/alert_types"
                            render={(props) => <AlertTypesView {...props} appContext={this} />}
                        />
                        <Route
                            path="/alert_levels"
                            render={(props) => <AlertLevelsView {...props} appContext={this} triage={false} />}
                        />
                        <Route
                            path="/content_review"
                            render={(props) => <ContentReview {...props} appContext={this} />}
                        />
                        <Route
                            path="/triage_levels"
                            render={(props) => <AlertLevelsView {...props} appContext={this} triage />}
                        />
                        <Route
                            path="/dialog_report"
                            render={(props) => <DialogReportView {...props} appContext={this} />}
                        />
                        <Route
                            path="/configs"
                            render={(props) => <ConfigurationsView {...props} appContext={this} />}
                        />
                        <Route
                            path="/print/model"
                            render={(props) => <PrintModel {...props} appContext={this} />}
                        />

                        <Route exact path="/">
                            <Redirect to="/questions" />
                        </Route>
                    </Switch>

                    {currentView}
                    {dialog}
                </main>
            </MuiThemeProvider>
        </Provider>;
    }
};


const drawerWidth = 300;
const styles = theme => ({
    content: { marginLeft: 10 },
    contentShift: { marginLeft: drawerWidth },
    formControl: { minWidth: 280 },
    selectEmpty: { marginTop: 2 }
});

App.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles, { withTheme: true })(App);