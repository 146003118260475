import React, { Component } from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core/';

import {
    loadModels
} from '@apricityhealth/web-common-lib/utils/Services';

let types = {};

export class SelectModelType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null,
            modelId: props.modelId,
            select: ''
        }
    }
    componentDidMount() {
        resetTypesCache();
        this.updateTypes();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.modelId !== this.props.modelId) {
            this.setState({
                modelId: this.props.modelId,
                select: ''
            }, this.updateTypes.bind(this));
        }
    }

    handleChange(v) {
        console.log("SelectModelType.handleChange:", v);

        var type = null;
        for (let i = 0; i < types.response.length; ++i) {
            const t = types.response[i];
            if (t.modelId === v) {
                type = t;
                break;
            }
        }
        if (type !== null) {
            this.setState({ type, modelId: v }, this.updateTypes.bind(this));
        } else {
            this.setState({ type: null, modelId: '' }, this.updateTypes.bind(this));
        }
        if (this.props.onSelect)
            this.props.onSelect(type);
        if (this.props.onChange)
            this.props.onChange(type);
    }

    updateTypes() {
        const self = this;
        const { appContext, label, enableNone } = self.props;
        getTypes(appContext, function (err, types) {
            if (!err) {
                var { modelId } = self.state;
                if (!modelId)
                    modelId = '';
                var items = [];
                var type = null;
                if (enableNone)
                    items.push(<MenuItem key={'none'} value={''}>None</MenuItem>);
                for (let i = 0; i < types.length; ++i) {
                    const t = types[i];
                    items.push(<MenuItem key={i} value={t.modelId}>{t.modelId}</MenuItem>);

                    if (t.modelId === modelId) {
                        type = t;
                    }
                }
                var select = <FormControl style={{ margin: 5, width: 300 }}>
                    <InputLabel>{label ? label : 'Model'}</InputLabel>
                    <Select value={modelId} onChange={(e) => self.handleChange(e.target.value)}>
                        {items}
                    </Select>
                </FormControl>;
                self.setState({ select, type });

            }
        });
    }
    render() {
        if (this.state.select)
            return this.state.select;
        return null;
    }
}

export default SelectModelType;

export function resetTypesCache() {
    types = {};
}

export function getTypes(appContext, callback) {
    if (types.response) {
        callback(null, types.response);
    }
    else if (types.callbacks) {
        types.callbacks.push(callback);
    }
    else {
        types.callbacks = [callback];

        loadModels(appContext, { dependencies: true }).then((response) => {
            types.response = response;
            types.response.sort((a, b) => {
                if (a.modelId < b.modelId)
                    return -1;
                else if (a.modelId > b.modelId)
                    return 1;
                return 0;
            });

            for (var i = 0; i < types.callbacks.length; ++i)
                types.callbacks[i](null, types.response);
        }).catch((err) => {
            console.log("getTypes error:", err);
            for (var i = 0; i < types.callbacks.length; ++i)
                types.callbacks[i](err, null);
        });
    }
}

export function getDataType(appContext, modelId, callback) {
    getTypes(appContext, (err, types) => {
        if (err)
            return callback(null);

        for (let i = 0; i < types.length; ++i) {
            if (types[i].modelId === modelId) {
                //console.log("getDataType result:", list[i] );
                return callback(types[i]);
            }
        }
        return callback(null);
    });
}