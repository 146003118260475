import React from 'react';
import {
    Dialog,
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Tabs,
    Tab,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton
} from '@material-ui/core/';

import Moment from 'moment';

import { 
    getForecasts
} from '@apricityhealth/web-common-lib/utils/Services';

import { ResponsiveLine } from '@nivo/line';
import { Defs } from '@nivo/core';
import { area, curveMonotoneX } from 'd3-shape';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Patient from '@apricityhealth/web-common-lib/components/Patient';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import SelectPatientIcon from '@material-ui/icons/Person';

const FORECAST_BLOCK_SIZE = 1000;

//const ReactHighstock = require('react-highcharts/ReactHighstock');

export class ForecastDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            tab: '0',
            forecastType: 'p50',
            progress: null,
            error: null,
            patientId: '*',
            model: props.model,
            instance: props.instance,
            patientIds: [],
            data: [],
            now: Moment(),
            startTime: null,
            endTime: null,
            pastSeries: [],
            futureSeries: [],
            dialog: null,
            cancel: false,
            min: 0,
            max: 4,
            roundDataPoints: false,
            tabs: []
        };
    }

    componentDidMount() {
        this.onUpdateTabs();
        this.loadContent();
    }

    loadContent( offset = 0 ) {
        const self = this;
        const { appContext } = this.props;
        const { model, instance, patientId } = this.state;
        let { data, startTime, endTime, now, cancel, patientIds } = this.state;

        if ( offset === 0 ) {
            data = [];
            cancel = false;
            now = Moment();
            startTime = Moment(now).subtract(model.forecastLength,'days');
            endTime = Moment(now).add(model.forecastLength,'days');
    
            this.setState({progress: <CircularProgress size={20} />, data, error: null, now, startTime, endTime, cancel })
        }

        let opts = [
            `instanceId=${instance.instanceId}`,
            `planId=${instance.planId}`,
            `predictorId=${instance.predictorId}`,
            `startTime=${startTime.toISOString()}`,
            `endTime=${endTime.toISOString()}`,
            `offset=${offset}`,
            `limit=${FORECAST_BLOCK_SIZE}`
        ];

        return getForecasts(appContext, patientId, opts).then((forecasts) => {
            console.log(`Loaded ${forecasts.length} forecast records...`);
            data = data.concat(forecasts);

            if ( forecasts.length === FORECAST_BLOCK_SIZE && cancel === false ) {
                self.setState({ data, progress: <span>Loaded {data.length} records.. <CircularProgress size={20} /></span>});
                return self.loadContent( offset + FORECAST_BLOCK_SIZE );
            } else {
                if ( patientId === '*') {
                    patientIds = data.map((e) => e.patientId).filter((v, i, a) => a.indexOf(v) === i);

                }
                self.setState({ progress: null, data, patientIds}, self.updateSeries.bind(self));
                console.log(`loadContent done, loaded ${data.length} forecast records...`);
            }
        }).catch((err) => {
            console.error(err);
            self.setState({progress: null, error: getErrorMessage(err)})
        });
    }

    updateSeries() {
        const { data, now, forecastType, roundDataPoints, patientId } = this.state;

        let past = {};
        let future = {};
        let min = undefined;
        let max = undefined;
        for(let i=0;i<data.length;++i) {
            let record = data[i];
            let x = Moment(record.eventTime).valueOf();
            let series = x < now.valueOf() ? past : future;
            if ( series[patientId] === undefined ) {
                series[patientId] = {
                    id: patientId,
                    data: []
                }
            }
            const line = series[patientId];
            let y = record[forecastType];
            if ( roundDataPoints )
                y = Math.round( y );
            const { p10, p50, p90 } = record;
            min = min === undefined ? Math.min(y, p10, p50, p90) : Math.min(y, p10, p50, p90, min);
            max = max === undefined ? Math.max(y, p10, p50, p90) : Math.max(y, p10, p50, p90, max);
            let point = line.data.find((e) => e.x === x);
            if ( point ) {
                point.y = Math.max(point.y, y);
                point.p10 = Math.max(point.p10,p10);
                point.p50 = Math.max(point.p50,p50);
                point.p90 = Math.max(point.p90,p90);
            }
            else {
                line.data.push( { x, y, p10, p50, p90} );
            }
        }
        let pastSeries = [];
        for(let k in past) {
            pastSeries.push( past[k] );
        }
        let futureSeries = [];
        for(let k in future) {
            futureSeries.push( future[k] );
        }
        console.log(`min: ${min}, max: ${max}`);
        this.setState({pastSeries, futureSeries, min, max }, this.onUpdateTabs.bind(this) );
    }

    onCloseDialog() {
        this.setState({dialog: null});
    }

    onSelectPatient(patientId) {
        this.setState({patientId, dialog: null}, this.loadContent.bind(this));
    }

    onUpdateTabs() {
        const self = this;
        const { appContext } = this.props;
        const { data, model, pastSeries, futureSeries, min, max } = this.state;
        console.log("futureSeries:", futureSeries);
        console.log("pastSeries:", pastSeries);

        const columnData = [
            { id: 'eventTime', label: 'Event Time' },
            { id: 'patientId', label: 'Patient', formatValue: (e) => { return <Patient appContext={appContext} patientId={e} /> } },
            { id: 'dataId', label: 'Data Id' },
            { id: 'data', label: 'Data', numeric: true, formatValue: (e) => e[model.target.tupleIndex] },
            { id: 'p10', label: 'P10', numeric: true },
            { id: 'p50', label: 'P50', numeric: true },
            { id: 'p90', label: 'P90', numeric: true }
        ];

        const lineProps = {
            // width: 500,
            // height: 500,
            xScale: {
                type: 'linear',
                min: 'auto',
                max: 'auto',
                reverse: false
            },
            yScale: {
                type: 'linear',
                min,
                max,
                stacked: true,
                reverse: false
            },
            axisTop: null,
            axisBottom: {
                orient: 'bottom',
                format: (v) => {
                    return Moment(v).format("M/D");
                }
            },  
            indexBy: "id",
            pointSize: 10,
            enableGridX: false,
            curve: 'monotoneX',
            isInteractive: true,
            useMesh: true,
            tooltip: ({point}) => {
                let p = point.data;
                return `${Moment(p.x).format("M/D")}:\np10: ${p.p10}\np50: ${p.p50}\np90: ${p.p90}`;
            },  
            enableSlices: false,
            enableCrosshair: true,
            pointLabelYOffset: -12,
            enablePointLabel: false
        };
        const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
            //console.log("series:", series, ", xScale:", xScale, ", yScale:", yScale, ", innerHeight:", innerHeight);
            if ( series.length < 1) {
                return <React.Fragment />;
            }
            const areaGenerator = area()
                .x(d => xScale(d.data.x))
                .y0(d => yScale(d.data.p10))
                .y1(d => yScale(d.data.p90))
                .curve(curveMonotoneX);

            const paths = series.map((e) => {
                //console.log("e:", e );
                return <React.Fragment>
                    <Defs
                        defs={[
                            {
                                id: 'pattern',
                                type: 'patternLines',
                                background: 'transparent',
                                color: e.color,
                                lineWidth: 1,
                                spacing: 6,
                                rotation: -45,
                            },
                        ]}
                    />
                    <path
                        d={areaGenerator(e.data)}
                        fill="url(#pattern)"
                        fillOpacity={0.6}
                        stroke={e.color}
                        strokeWidth={2}
                    />
                </React.Fragment>;
            })
            return paths;
        };
        const lineLayers = [
            'grid',
            'markers',
            'axes',
            'areas',
            AreaLayer,
            'crosshair',
            'lines',
            'points',
            'slices',
            'mesh',
            'legends',
        ];                
        let tabs = [
            <div key='graphs' style={{display: 'flex', height: 500, width: 1000}}>
                <ResponsiveLine 
                    {...lineProps}
                    margin={{ top: 50, right: 15, bottom: 50, left: 50}}
                    data={pastSeries}
                />
                <ResponsiveLine 
                    {...lineProps}
                    margin={{ top: 50, right: 50, bottom: 50, left: 15}}
                    data={futureSeries} 
                    layers={lineLayers}
                    axisLeft={null}
                />
            </div>,
            <EnhancedTable key='data'
                onActions={(table,numSelected,actions) => {
                    if ( numSelected === 1 ) {
                        const { selected, data } = table.state;
                        let record = data[selected[0]];
                        actions.push(<Tooltip title='Select Patient'><IconButton onClick={() => {
                            self.setState({patientId: record.patientId}, self.loadContent.bind(self));
                        }}><SelectPatientIcon /></IconButton></Tooltip>);
                    }
                }}
                disableDelete={true} 
                disableAdd={true}               
                style={{width: 1190}}
                orderBy='eventTime'
                order='desc'
                columnData={columnData}
                data={data}
                rowsPerPage={10}
                title='Data' />
        ];

        this.setState({tabs});
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { tab, tabs, instance, patientId, patientIds, forecastType, roundDataPoints, dialog, error, progress } = this.state;

        return <Dialog open={true} maxWidth='lg' fullWidth={true}>
            <DialogTitle>Forecasts for {instance.instanceId}</DialogTitle>
            <DialogContent>
                <FormControl style={{margin: 5, width: 300}}>
                    <InputLabel>Select Patient</InputLabel>
                    <Select value={patientId} onChange={(e) => {
                        self.setState({patientId: e.target.value}, self.loadContent.bind(self))
                    }}>
                        <MenuItem value='*'>All</MenuItem>
                        {patientIds.map((e) => {
                            return <MenuItem value={e} key={e}><Patient appContext={appContext} patientId={e} /></MenuItem>;
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{ margin: 5, width: 150 }}>
                    <InputLabel>Forecast Type</InputLabel>
                    <Select value={forecastType} onChange={(e) => {
                        self.setState({forecastType: e.target.value}, self.updateSeries.bind(self));
                    }}>
                        <MenuItem value='p10'>p10</MenuItem>
                        <MenuItem value='p50'>p50</MenuItem>
                        <MenuItem value='p90'>p90</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel style={{margin: 5}} label='Round Data' control={<Checkbox checked={roundDataPoints} onChange={(e,v) => self.setState({roundDataPoints: v}, self.updateSeries.bind(self)) } />} />
                <br />
                <Tabs style={{width: 800}}
                    variant="scrollable"
                    value={tab} 
                    onChange={(e, newTab) => { self.setState({tab: `${newTab}`}); }}>
                    <Tab label='Forecast Graph' value='0' /> 
                    <Tab label='Forecast Data' value='1' /> 
                </Tabs>
                {tabs[tab]}
                {dialog}
            </DialogContent>
            <DialogActions>
                <span style={{color: 'red'}}>{error}</span>
                {progress}
                {progress !== null ? <Button onClick={() => self.setState({cancel: true})}>CANCEL</Button> : null}
                <Button onClick={this.props.onClose}>CLOSE</Button>
            </DialogActions>
        </Dialog>;
    };
};

export default ForecastDialog;
