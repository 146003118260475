import React, { Component } from "react";

import {
    CircularProgress,
    TextField,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Tabs,
    Tab,
    IconButton,
    Paper,
    DialogTitle,
    FormLabel
} from '@material-ui/core/';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import CreateIcon from '@material-ui/icons/Create';
import FlagIcon from '@material-ui/icons/Flag';
import TranslateIcon from '@material-ui/icons/Translate';

import SortableTree from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

import {
    isArrayValid
} from '../utils/Utils';

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType'
import SelectTupleIndex from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

import EditFlagArrayView from '../components/EditFlagArrayView'
import EditMappingArray from '../components/EditMappingArray';
import EditDataTypeArray from '../components/EditDataTypeArray';
import OverrideDialog from "./OverrideDialog";
import EditDataTypeDialog from './EditDataTypeDialog';
import EditAnswerDialog from './EditAnswerDialog';

import ChangedByButton from "../components/ChangedByButton";
import QuestionUtils from "../utils/QuestionUtils";
import AdHocTranslationDialog from '../dialogs/AdHocTranslationDialog';

class EditQuestionDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            texts: props.texts,
            questionId: props.questionId || '',
            question: null,
            originalQuestion: null,
            language: props.language,
            progress: null,
            updateDataTypeScores: true,
            cloneQuestion: false,
            tabIndex: '0',
            section: 'question',
            dialog: null,
            done: false
        };
    }

    componentDidMount() {
        this.loadQuestion();
    }

    loadQuestion() {
        const self = this;
        const { appContext, language } = this.props;
        const { questionId } = this.state;
        console.log("loadQuestion:", questionId);

        this.questionUtils = new QuestionUtils(appContext, language);
        let question = appContext.stores.DataTypesStore.getQuestions().find((e) => e.questionId === questionId);
        if (question) {
            question = self.finalizeQuestion(JSON.parse(JSON.stringify(question)));
            let originalQuestion = JSON.parse(JSON.stringify(question));
            self.setState({ question, originalQuestion }, self.initializeTreeData.bind(self));
        }
        else {
            let questionId = '';
            let question = {
                category: '',
                question: [{ text: `[[${questionId}]]`, condition: '' }],
                questionId,
                answers: [],
                andFlagConditions: [],
                orFlagConditions: [],
                addFlags: [],
                removeFlags: [],
                mapping: [],
                bounds: [],
                baseline: false,
                order: '0'          // TODO: need to provide a order for the question that is reasonable
            };
            this.finalizeQuestion(question);
            this.setState({ question, originalQuestion: null }, self.initializeTreeData.bind(self));
        }
    }

    finalizeQuestion(question) {
        this.questionUtils.unlocalizeQuestion(question);
        if (question.slopeDays === undefined) question.slopeDays = 14;
        if (question.relatedDataIds === undefined) question.relatedDataIds = [];
        if (!isArrayValid(question.addModels)) question.addModels = [];
        if (!isArrayValid(question.addRequiredData)) question.addRequiredData = [];
        if (!isArrayValid(question.addOptionalData)) question.addOptionalData = [];
        if (question.preConditions === undefined) question.preConditions = "";
        return question;
    }

    initializeTreeData() {
        let self = this;
        let { question, language } = this.state;

        let treeData = [];
        if (!language || language === 'en-US') {
            language = 'en-us';
        }
        if (!Array.isArray(question.answers)) {
            question.answers = [];
        }

        let answers = question.answers;
        for (let j = 0; j < answers.length; j++) {
            let answer = answers[j];
            answer.order = j;
            let text = answer.text[0];
            text = text.replace(/<\/?[^>]+(>|$)/g, "");     // strip out any HTML from the text
            if (answer.category)
                text = <span><b>{answer.category + ' / '}</b>{text}</span>;

            let subtitle = <span>
                TYPE: {answer.controlType}
                {answer.preConditions ? `, CONDITION` : null}
                {answer.detect ? ', DETECT' : null}
                {answer.bail ? ', BAIL' : null}
                {answer.default ? ', DEFAULT' : null}
                {answer.dataId ? `, DATA: ${answer.dataId}` : null}
                {answer.baselineId ? `, BASELINE: ${answer.baselineId}` : null}
                {isArrayValid(answer.addModels) ? `, addModels: ${answer.addModels.join(',')}` : null}
                {isArrayValid(answer.addRequiredData) ? `, addRequiredData: ${answer.addRequiredData.join(',')}` : null}
                {isArrayValid(answer.addOptionalData) ? `, addOptionalData: ${answer.addOptionalData.join(',')}` : null}
                {isArrayValid(answer.andFlagConditions) ? `, andFlags: ${answer.andFlagConditions.join(',')}` : null}
                {isArrayValid(answer.orFlagConditions) ? `, orFlags: ${answer.orFlagConditions.join(',')}` : null}
                {isArrayValid(answer.addFlags) ? `, addFlags: ${answer.addFlags.join(',')}` : null}
                {isArrayValid(answer.removeFlags) ? `, removeFlags: ${answer.removeFlags.join(',')}` : null}
                {isArrayValid(answer.bounds) ? ', Bounds' : null}
                {isArrayValid(answer.mapping) ? ', Mapping' : null}
            </span>;

            let child = {
                isAnswer: true,
                isQuestion: false,
                question,
                answer,
                text,
                subtitle
            };
            treeData.push(child);
        }
        console.log("initializeTreeData:", treeData);
        self.setState({ treeData });
    }

    onChangeTreeData(treeData) {
        console.log("onChangeTreeData:", treeData);

        const { question } = this.state;
        question.answers = [];
        for (let i = 0; i < treeData.length; ++i) {
            let answer = treeData[i].answer;
            answer.order = i;
            question.answers.push(answer);
        }

        this.setState({ question, treeData });
    }

    getNodeProps({ node, path, treeIndex }) {
        return {
            listIndex: 0,
            lowerSiblingCounts: [],
            style: { height: 50, width: 600, border: 0 },
            title: (
                <div style={{ fontSize: 16, width: 600, border: 0, overflow: 'hidden', fontWeight: 'normal' }}>
                    {node.text}
                </div>
            ),
            buttons: (this.getNodeButtons(node, path, treeIndex))
        };
    }

    onAddAnswer() {
        const self = this;
        const { appContext } = this.props;
        const { question, texts, language } = this.state;

        this.setState({
            dialog: <EditAnswerDialog
                question={question}
                texts={texts}
                language={language}
                appContext={appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(answer) => {
                    answer.order = question.answers.length;
                    question.answers.push(answer);
                    self.setState({ question, dialog: null }, self.initializeTreeData.bind(self));
                }} />
        });
    }

    onEditAnswer(answer) {
        let self = this;
        const { appContext } = this.props;
        const { question, texts, language } = this.state;

        self.setState({
            dialog: <EditAnswerDialog
                question={question}
                texts={texts}
                answer={answer}
                language={language}
                appContext={appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(answer) => {
                    question.answers[answer.order] = answer;
                    self.setState({ question, dialog: null }, self.initializeTreeData.bind(self));
                }} />
        });
    }

    onDeleteAnswer(question, answer) {
        console.log("onDeleteAnswer:", question, answer);
        question.answers.splice(answer.order, 1);
        this.setState({ question }, this.initializeTreeData.bind(this));
    }

    onTranslateNode(node) {
        const self = this;
        let { texts, language } = this.state;
        let dialog =
            <AdHocTranslationDialog
                node={node}
                texts={texts}
                language={language}
                appContext={self.props.appContext}
                onCancel={() => {
                    self.setState({ dialog: null })
                }}
                onDone={(text) => {
                    console.log("onTranslateNode:", text, node);
                    if (node.isQuestion) {
                        node.question.text[0].text = text[0].text;
                    } else if (node.isAnswer) {
                        node.answer.text[0] = text[0].text;
                    }
                    this.setState({ dialog: null }, this.initializeTreeData.bind(this));
                }}
            />
        this.setState({ dialog: dialog })
    }

    getNodeButtons(node, path, treeIndex) {
        const { language } = this.state;
        let self = this;
        let buttons = [];

        let score = node.answer.score;
        let scoreLabel = <FormLabel style={{
            fontSize: 10, display: 'inline-block', color: 'purple', width: 50,
            textAlign: 'right', marginRight: 30, border: 'none'
        }}>
            {score}
        </FormLabel>;
        buttons.push(scoreLabel);

        if (isArrayValid(node.answer.addFlags)) {
            buttons.push(<FlagIcon />);
        }
        if (language !== 'en-us') {
            buttons.push(<IconButton key='translate' onClick={this.onTranslateNode.bind(this, node)}><TranslateIcon /></IconButton>);
        }
        buttons.push(<IconButton key='edit' onClick={() => self.onEditAnswer(node.answer)}><CreateIcon /></IconButton>);
        buttons.push(<IconButton key='delete' onClick={() => { self.onDeleteAnswer(node.question, node.answer) }}><DeleteIcon /></IconButton>);

        return buttons;
    }


    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    saveQuestion( planId = null ) {
        const { question, cloneQuestion, originalQuestion, updateDataTypeScores } = this.state;
        return this.questionUtils.saveQuestion(question, originalQuestion, cloneQuestion, planId, updateDataTypeScores);
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone( planId = null ) {
        const { onDone, appContext } = this.props;
        const { done, question, originalQuestion, cloneQuestion } = this.state;

        if (!question.questionId) return

        if (!planId && question.planId && question.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(question.planId, this.onDone.bind(this));
        }

        if (done === false) {
            const self = this;
            this.setState({
                progress: <CircularProgress />,
                done: true
            }, () => {

                if (isArrayValid(question.mapping))
                    question.mapping.sort((a, b) => b.v - a.v);
                if (isArrayValid(question.bounds))
                    question.bounds.sort((a, b) => b.v - a.v)

                this.saveQuestion(planId).then(() => {
                    // if not cloning and if the ID was changed, delete the old text and question..
                    if (!cloneQuestion && originalQuestion 
                        && originalQuestion.questionId 
                        && originalQuestion.questionId !== '*' 
                        && originalQuestion.questionId !== question.questionId) {
                        return self.questionUtils.deleteQuestion(originalQuestion);
                    } else {
                        return Promise.resolve();
                    }
                }).then(() => {
                    self.setState({ progress: null });
                    if (onDone)
                        onDone(question);
                }).catch((error) => {
                    self.setState({ progress: error.message });
                    if (onDone)
                        onDone(question);
                });
            });
        }
    }

    onSelectDataType(type) {
        let { question } = this.state;
        question.dataId = type ? type.dataId : '';
        this.setState({ question });
    }

    onSelectBaselineType(type) {
        let { question } = this.state;
        question.baselineId = type ? type.dataId : '';
        this.setState({ question });
    }

    addQuestionText() {
        let { question } = this.state;
        question.question.push({
            text: ``,
            condition: ''
        });
        this.setState({ question });
    }

    deleteQuestionText(index) {
        let { tabIndex, question } = this.state;
        question.question.splice(index, 1);
        if (tabIndex > 0 && tabIndex >= question.question.length)
            tabIndex = `${question.question.length - 1}`;
        this.setState({ question, tabIndex });
    }

    displayPreview() {
        const self = this;
        let { tabIndex, question } = this.state;
        let text = question.question[tabIndex].text;

        this.setState({
            dialog: <Dialog fullWidth={true} maxWidth="md" modal="true" open={true}>
                <DialogTitle>Preview</DialogTitle>
                <DialogContent>
                    <div style={{ margin: 5 }}>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => {
                        self.setState({ dialog: null });
                    }}>OK</Button>
                </DialogActions>
            </Dialog>
        });
    }

    getEditQuestion() {
        let self = this;
        let { question, tabIndex } = this.state;
        let { appContext } = this.props

        if (!question) return null;
        if (!Array.isArray(question.question)) question.question = [];

        let textEdit = [];
        let tabs = [];
        for (let i = 0; i < question.question.length; ++i) {
            textEdit.push(<table key={i} style={{ width: '100%' }}><tbody>
                <tr>
                    <td   style={{ width: '100%' }}>
                        <TextField
                            style={{ width: '100%' }}
                            label="Question Text"
                            value={question.question[i].text}
                            multiline={true}
                            inputProps={{
                                style: {
                                    width: '100%',
                                    height: '10rem',
                                    padding: '4px',
                                },
                            }}
                            rows={3}
                            onChange={(e) => {
                                question.question[i].text = e.target.value;
                                self.setState({ question });
                            }}
                        />
                    </td>
                    <td align='right'>
                        <IconButton onClick={self.displayPreview.bind(self)}><InfoIcon /></IconButton>
                        <IconButton onClick={self.deleteQuestionText.bind(self, i)}><DeleteIcon /></IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <EditConditions
                            appContext={appContext}
                            conditions={question.question[i].condition}
                            onChange={(condition) => {
                                question.question[i].condition = condition;
                                self.setState({ question });
                            }}
                            name={'Variation condition'}
                        />
                    </td>
                </tr>
            </tbody></table>);
            tabs.push(<Tab key={i} label={`Variation #${i + 1}`} value={`${i}`} />);
        }

        return <table width="100%">
            <tbody>
                <tr>
                    <td colSpan={2}>
                        {this.getOptions()}
                    </td>
                </tr>
                <tr>
                    <td>
                        <Tabs style={{ width: 1000 }}
                            variant="scrollable"
                            scrollButtons="on"
                            value={tabIndex}
                            onChange={(e, newTab) => { self.setState({ tabIndex: `${newTab}` }); }}>
                            {tabs}
                        </Tabs>
                    </td>
                    <td align="right" valign="top">
                        <IconButton onClick={self.addQuestionText.bind(self)}><AddIcon /></IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Paper style={{ margin: 5 }}>
                            {textEdit[tabIndex]}
                        </Paper>
                    </td>
                </tr>
            </tbody>
        </table>;
    }

    getAnswers() {
        let self = this;
        let { question, treeData } = this.state;

        if (!question) return null;
        let sortableTree = treeData ?
            <div style={{ height: 580, width: '100%' }} valign='top'>
                <SortableTree
                    treeData={treeData}
                    maxDepth={1}
                    onChange={this.onChangeTreeData.bind(this)}
                    theme={FileExplorerTheme}
                    canNodeHaveChildren={() => false}
                    isVirtualized={true}
                    generateNodeProps={this.getNodeProps.bind(this)}
                />
            </div> : null;

        return <table width="100%">
            <tbody>
                <tr>
                    <td>
                        {`${question.answers.length} Answers:`}
                    </td>
                    <td align="right" valign="top">
                        <IconButton onClick={self.onAddAnswer.bind(self)}><AddIcon /></IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Paper style={{ margin: 5 }}>
                            {sortableTree}
                        </Paper>
                    </td>
                </tr>
            </tbody>
        </table>;
    }

    getOptions() {
        const self = this;
        const { question } = this.state;
        const { appContext } = this.props;
        if (!question) return null;

        return <Paper style={{ margin: 5, padding: 5 }}>
            <TextField
                style={styles.name}
                label="Question ID"
                value={question.questionId}
                onChange={(e) => {
                    question.questionId = e.target.value.replace(/\W/g, '');     // do not allow spaces or asterick *
                    self.setState({ question });
                }}
            />
            <TextField
                style={styles.number}
                type='number'
                label="Order"
                value={question.order}
                onChange={(e) => {
                    question.order = e.target.value;
                    self.setState({ question });
                }}
            />
            <TextField
                style={styles.name}
                label="Category"
                value={question.category}
                onChange={(e) => {
                    question.category = e.target.value;
                    self.setState({ question });
                }}
            />
            <br />
            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.baseline}
                    onChange={(e) => {
                        question.baseline = e.target.checked;
                        self.setState({ question });

                    }}
                />
                }
                label="Baseline" />
            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.required}
                    onChange={(e) => {
                        question.required = e.target.checked;
                        self.setState({ question });
                    }}
                />
                }
                label="Required" />

            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.eod}
                    onChange={(e) => {
                        question.eod = e.target.checked;
                        self.setState({ question });
                    }}
                />
                }
                label="EOD" />

            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.detect}
                    onChange={(e) => {
                        question.detect = e.target.checked;
                        self.setState({ question });
                    }}
                />
                }
                label="Detect" />

            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.bail}
                    onChange={(e) => {
                        question.bail = e.target.checked;
                        self.setState({ question });
                    }}
                />
                }
                label="Bail" />

            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.multiSelect}
                    onChange={(e) => {
                        question.multiSelect = e.target.checked;
                        self.setState({ question });
                    }}
                />
                }
                label="Multi Select" />
            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.embedded}
                    onChange={(e) => {
                        question.embedded = e.target.checked;
                        self.setState({ question });
                    }}
                />
                }
                label="Embedded" />
            <FormControlLabel style={{ marginLeft: '0px' }}
                control={<Checkbox
                    checked={question.noCulling}
                    onChange={(e) => {
                        question.noCulling = e.target.checked;
                        self.setState({ question });
                    }}
                />
                }
                label="No Culling" />
            <br />
            <TextField
                style={styles.question}
                label="Explanation Text"
                value={question.explanation}
                multiline={true}
                rows={2}
                onChange={(e) => {
                    question.explanation = e.target.value;
                    self.setState({ question });
                }}
            />
            <br />
            <SelectDataType
                style={styles.select}
                appContext={appContext}
                dataId={question.dataId}
                enableNone={true}
                onCreate={() => {
                    self.setState({
                        dialog: <EditDataTypeDialog appContext={this.props.appContext}
                            dataType={{ dataId: '', name: '', description: '', category: 'symptom', tupleDescriptions: [] }}
                            onCancel={() => self.setState({ dialog: null })}
                            onDone={(newType) => {
                                question.dataId = newType.dataId;
                                self.setState({ dialog: null, question });
                            }} />
                    });
                }}
                onSelect={(type) => this.onSelectDataType(type)} />
            <TextField type='number' label='Slope Days' style={styles.days} value={question.slopeDays} onChange={(e) => {
                question.slopeDays = e.target.value;
                self.setState({ question, modified: true });
            }} />
            <br />
            <EditConditions
                style={{ margin: 5 }}
                appContext={appContext}
                conditions={question.preConditions}
                onChange={(conditions) => {
                    question.preConditions = conditions;
                    self.setState({ question });
                }}
                name={'Question Condition'}
            />
        </Paper>;
    }

    getAdvancedOptions() {
        const self = this;
        const { question } = this.state;
        const { appContext } = this.props;
        if (!question) return null;

        return <Paper style={{ margin: 5, padding: 5 }}>
            <SelectDataType
                style={styles.select}
                appContext={appContext}
                label="Baseline Data Type"
                dataId={question.baselineId}
                enableNone={true}
                onCreate={() => {
                    self.setState({
                        dialog: <EditDataTypeDialog appContext={this.props.appContext}
                            dataType={{ dataId: '', name: '', description: '', category: 'symptom', tupleDescriptions: [] }}
                            onCancel={() => self.setState({ dialog: null })}
                            onDone={(newType) => {
                                question.baselineId = newType.dataId;
                                self.setState({ dialog: null, question });
                            }} />
                    });
                }}
                onSelect={this.onSelectBaselineType.bind(this)} />
            <SelectTupleIndex
                appContext={appContext}
                label="Baseline Tuple"
                dataId={question.baselineId}
                tupleIndex={question.baselineTuple}
                onChange={(tupleIndex) => {
                    question.baselineTuple = tupleIndex
                    self.setState({ question });
                }} />
            <br />
            <EditDataTypeArray appContext={appContext} array={question.relatedDataIds} label={'Related Data Types'}
                onChange={(dataIds) => {
                    question.relatedDataIds = dataIds
                    self.setState({ question });
                }}
            />
            <br />
            <EditFlagArrayView appContext={appContext} array={question.andFlagConditions} label={'And Flags'} />
            <br />
            <EditFlagArrayView appContext={appContext} array={question.orFlagConditions} label={'Or Flags'} />
            <br />
            <EditFlagArrayView appContext={appContext} array={question.addFlags} label={'Add Flags'} />
            <br />
            <EditFlagArrayView appContext={appContext} array={question.removeFlags} label={'Remove Flags'} />
            <br />
            <EditMappingArray appContext={appContext} array={question.bounds} label={'Bounds'} />
            <br />
            <EditMappingArray appContext={appContext} array={question.mapping} label={'Mapping'} />
        </Paper>;
    }

    render() {
        let self = this;
        let { dialog, question, progress, updateDataTypeScores, cloneQuestion } = this.state;
        let { appContext } = this.props

        let saveButtonDisabled = () => {
            if (question && question.questionId !== '') return false
            return true
        }

        const { section } = this.state;

        return (
            <Dialog fullWidth={true} maxWidth="lg" model="false" open={true}>
                <DialogTitle>Edit Question</DialogTitle>
                <DialogContent>
                    <Tabs style={{ width: '100%', backgroundColor: '#96b7eb' }} value={section} onChange={(e, t) => {
                        console.log("Select Tab:", e, t);
                        self.setState({ section: t })
                    }}>
                        <Tab label='Question' value='question' />
                        <Tab label="Answers" value='answers' />
                        <Tab label="Advanced" value="advanced" />
                    </Tabs>
                    {section === 'question' ? self.getEditQuestion() : section === 'answers' ? self.getAnswers() : self.getAdvancedOptions()}
                    <br />
                    {dialog}
                </DialogContent>
                <DialogActions>
                    {progress}
                    {question ? <ChangedByButton appContext={appContext} primaryKey={question.questionId} collection='QuestionModel' /> : null}
                    <FormControlLabel style={{ marginLeft: '0px' }}
                        control={<Checkbox
                            checked={cloneQuestion}
                            onChange={(e) => {
                                cloneQuestion = e.target.checked;
                                self.setState({ cloneQuestion });
                            }}
                        />
                        }
                        label="Clone Question" />
                    <FormControlLabel style={{ marginLeft: '0px' }}
                        control={<Checkbox
                            checked={updateDataTypeScores}
                            onChange={(e) => {
                                updateDataTypeScores = e.target.checked;
                                self.setState({ updateDataTypeScores });
                            }}
                        />
                        }
                        label="Update Data Types" />
                    <Button variant="contained" self={self} disabled={saveButtonDisabled()} style={styles.button} onClick={(e) => { self.onDone() }}>Save</Button>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    question: {
        margin: 5,
        width: 800
    },
    name: {
        margin: 5,
        width: 300
    },
    number: {
        margin: 5,
        width: 100
    },
    select: {
        margin: 5,
        width: 300
    },
    days: {
        margin: 5,
        width: 100
    },
    div: {
        margin: 10
    },
    button: {
        margin: 10
    },
};


export default EditQuestionDialog;
