import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Paper,
    IconButton,
} from '@material-ui/core/';
import NavigationClose from '@material-ui/icons/Close';
import ReactJson from 'react-json-view';

class JSONView extends Component {

    close() {
        this.props.onClose();
    }

    render() {
        const self = this;
        const { data } = self.props;

        let elms = [];

        if (data)
            elms.push(<Paper key="1" style={styles.jsonPaper}>
                <ReactJson src={data}  collapsed={1} name={"data"} collapseStringsAfterLength={64} displayDataTypes={false} />
            </Paper>);

        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={this.close.bind(this)}><NavigationClose /></IconButton>
                Details
            </Toolbar>
        </AppBar> : null;

        return (
            <div align="left">
                {appBar}
                {elms}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    jsonPaper: {
        display: 'inline-block',
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6,
        maxWidth: 700
    }
}

export default JSONView;
