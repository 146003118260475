import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { SelectTaskType } from '@apricityhealth/web-common-lib/components/SelectTaskType';
import { SelectTaskArgs } from '@apricityhealth/web-common-lib/components/SelectTaskArgs';

import React, { Component } from 'react';

export class TasksView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: props.tasks || []
        };
    }
    componentWillMount() {
        console.log("componentWillMount:", this);
        this.setState({ tasks: this.props.tasks || [] });
    }
    componentWillReceiveProps(props) {
        if (props.tasks !== this.props.tasks)
            this.setState({ tasks: props.tasks || [] });
    }

    onTasksChanged(data) {
        this.setState({ tasks: data });
        if (this.props.onTasksChanged)
            this.props.onTasksChanged(data);
    }

    onChangeType(table, index, id, event) {
        console.log("onChangeType:", this, table, index, id, event);
        table.setDataField(index, id, event.target.value);
    }

    onTaskTypeControl(table, value, row, index, id) {
        console.log("onTaskTypeControl:", value, index);
        return <SelectTaskType value={value}
            onChange={this.onChangeType.bind(this, table, row._index, id)}
            onClick={(e) => table.handleClickEdit(e)} />;
    }

    onChangeArgs(table, index, id, args) {
        console.log("onChangeArgs:", this, table, index, id, args);
        table.setDataField(index, id, args);
    }

    onTaskArgs(table, value, row, index, id) {
        const { appContext } = this.props;
        if (! Array.isArray(value) )
            value = [];
        return <SelectTaskArgs
            appContext={appContext}
            value={value}
            taskType={row.taskType}
            onChange={this.onChangeArgs.bind(this, table, row._index, id)}
            onClick={(e) => table.handleClickEdit(e)} />;
    }

    render() {
        const self = this;
        const { tasks } = self.state;

        const columnData = [
            { id: '_index', numeric: false, disabledPadding: false, label: 'Order' },
            { id: 'taskType', numeric: false, disabledPadding: false, label: 'Task Type', editControl: this.onTaskTypeControl.bind(this) },
            { id: 'args', numeric: false, disabledPadding: false, label: 'Arguments', editControl: this.onTaskArgs.bind(this) }
        ];

        return <EnhancedTable
            disableMultiSelect={false}
            onDataChanged={data => self.onTasksChanged(data)}
            onTable={table => self._table = table}
            orderBy='_index'
            columnData={columnData}
            data={tasks}
            rowsPerPage={5}
            title='Tasks' />;
    }
}


export default TasksView;
