import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles, Avatar } from '@material-ui/core/';
import {
    Chip,
    FormLabel,
    Button, 
    Tooltip,
    IconButton,
    Dialog,
    DialogContent, 
    DialogActions 
} from '@material-ui/core/';
import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';
import {
    arrayRemoveLocation,
    arrayInsertLocation,
    isArrayValid
} from '../utils/Utils';
import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType'

class EditDataTypeArray extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: JSON.parse( JSON.stringify( props.array || [] )),
            menu: null
        };
    }

    onAdd = index => e => {
        let { array } = this.state;
        //just add a default one
        arrayInsertLocation(array, 0, "");
        this.setState({ array });
    }

    onSelectDataType(type, index) {
        let { array } = this.state;
        let dataId = type ? type.dataId : '';

        if ( array[index] !== dataId ) {
            array[index] = type ? type.dataId : '';
            this.setState({ array , menu: null});
            if (this.props.onChange)
                this.props.onChange(array)
        }
    }

    onChangeData = index => e => {
        //console.log("onChangeData:", index, e );
        let { array } = this.state;
        const { appContext } = this.props;
        let menu =
                <Dialog fullWidth={true} maxWidth={'sm'} model="true" open={true}>
                    <DialogContent style={{minHeight:400}}>
                        <SelectDataType
                            appContext={appContext}
                            dataId={array[index]}
                            onSelect={(type) => this.onSelectDataType(type, index)} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.closeMenu.bind(this)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
        this.setState({ menu });
    }

    closeMenu() {
        this.setState({ menu: null });
    }


    componentDidUpdate(prevProps) {
        const hasNewArray = JSON.stringify(prevProps.array) !== JSON.stringify(this.props.array)
        const hasNewName = prevProps.label !== this.props.label
        if (hasNewArray || hasNewName){
            let array = this.props.array ? this.props.array : [];
            let label = this.props.label;
            this.setState({ array, label });
        }
    }


    onDelete = index => e => {
        let { array } = this.state;
        arrayRemoveLocation(array, index);
        this.setState({ array });
        if (this.props.onChange){
            this.props.onChange(array)
        }
    }

    render() {
        let self = this;
        let { array, menu } = this.state;

        let dataChips = [];
        if (isArrayValid(array)) {
            for (let index = 0; index < array.length; index++) {
                let data = array[index];
                dataChips.push(
                    <Chip
                        key={index}
                        avatar={<Avatar><FaceIcon /></Avatar>}
                        label={data}
                        onClick={self.onChangeData(index)}
                        onDelete={self.onDelete(index)}
                    />);

            }
        }

        let label = <FormLabel>{this.props.label || "Edit Data Types"}</FormLabel>;
        if (this.props.tip)
            label = <Tooltip title={this.props.tip}>{label}</Tooltip>;

        return (<div >
            <div style={{  marginTop: '5px' }} />
            {label}
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={self.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div>
        )
    }
}

const styles = {
    div: {
        margin: 10
    }
};

EditDataTypeArray.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditDataTypeArray);
