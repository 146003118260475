import React from "react";
import PubSub from 'pubsub-js'
import { Diff2Html } from "diff2html";

import {
    withStyles,
    Button,
    TextField,
    Tabs,
    Typography,
    DialogTitle,
    Dialog,
    DialogContentText,
    DialogContent,
    DialogActions,
    Tab,
    Checkbox,
    CircularProgress,
} from '@material-ui/core/';

import ApproveIcon from '@material-ui/icons/Done';
import RevertIcon from '@material-ui/icons/Undo';
import MergeIcon from '@material-ui/icons/CallMerge';

// Local
import { isAdminUser, isArrayValid } from '../utils/Utils';

import {
    mergePlan,
    addPlanActivity,
    sendSlack,
    savePlan
} from '@apricityhealth/web-common-lib/utils/Services';

import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import DiffRowExpansionBox from "../components/DiffRowExpansionBox";

// CSS
import "../styles/content.css";
import "diff2html/dist/diff2html.min.css";

const PLAN_TOPIC = 'PLAN_TOPIC';

class ContentChangesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            diffChecked: [],
            diffFileNames: [],
            currentTab: 0,
            tabs: [],
            tabContent: [],
            content: null,
            expanded: []
        }
    }

    componentDidMount() {
        this.initializeContent();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.mode !== this.props.mode) {
            this.setState({ diffChecked: [], diffFileNames: [] }, this.initializeContent.bind(this))
        }
    }

    approve(index) {
        let { diffChecked } = this.state;
        diffChecked[index] = !diffChecked[index];
        this.setState({ diffChecked });
    }

    approveAll(k, e, v) {
        let { diffChecked, content } = this.state;
        let views = content[k].views;
        for (let i = 0; i < views.length; ++i) {
            diffChecked[views[i].index] = v;
        }
        this.setState({ diffChecked, content });
    }

    resetContent() {
        this.setState({content: null})
    }

    initializeContent() {
        const { mode, parent: { state: { diffs, changeKeys, plan: { approvedDiffs } }}} = this.props;
        const { diffChecked } = this.state;
        const changes = mode === 'approved' ? approvedDiffs : diffs;
        if ( changes !== null && changeKeys !== null ) {
            let diffFileNames = [];
            if (isArrayValid(changes)) {
                let content = {};
                for (let index = 0; index < changes.length; index++) {
                    diffChecked.push(true);
                    const diffString = changes[index];

                    //filename
                    let filename = diffString.substring(
                        diffString.indexOf(":") + 1,
                        diffString.indexOf("=")
                    );

                    let fileName = '';
                    let fileNameStartIndex = diffString.indexOf("Index: ");
                    let fileNameEndIndex = diffString.indexOf("\n");
                    if (fileNameStartIndex >= 0 && fileNameEndIndex >= fileNameStartIndex + 7) {
                        fileName = diffString.slice(fileNameStartIndex + 7, fileNameEndIndex);
                    }
                    diffFileNames.push(fileName);

                    let contentCategory = diffString.slice(diffString.indexOf('/') + 1);
                    contentCategory = contentCategory.slice(0, contentCategory.indexOf('/'));

                    if (contentCategory === 'types') {
                        let typeContent = diffString.slice(diffString.indexOf('/') + 7);
                        contentCategory = typeContent.slice(0, typeContent.indexOf('/'));
                    }
                    if (!content[contentCategory]) {
                        content[contentCategory] = {};
                        content[contentCategory].views = [];
                        content[contentCategory].name = contentCategory;
                    }
                    let diffHtml = Diff2Html.getPrettyHtml(
                        diffString,
                        { inputFormat: 'diff', showFiles: false, matching: 'lines', renderNothingWhenEmpty: true, outputFormat: 'side-by-side' }
                    );

                    content[contentCategory].views.push({ index, changeKeys: changeKeys[fileName], diffHtml, filename });

                }
                console.log("initializeContent:", content );
                this.setState({ content, diffChecked, diffFileNames }, this.resetExpanded.bind(this));
            }
            else {
                console.log("iniitalizeContent: No content");
                this.setState({ content: {} });
            }
        }
    }


    handleExpand(key) {
        let { expanded } = this.state
        if (expanded.includes(key)) {
            expanded = expanded.filter(item => (item !== key))
        } else {
            expanded.push(key)
        }
        this.setState({ expanded: expanded })
    }

    resetExpanded() {
        const { content, currentTab } = this.state;
        const expanded = []

        let index = 0;
        for (let k in content) {
            if (index === currentTab) {
                let lengthAccumulator = 0
                let part = content[k];
                for (var i = 0; i <= part.views.length; i++) {
                    if (part.views[i] && part.views[i].diffHtml)
                        lengthAccumulator = lengthAccumulator + part.views[i].diffHtml.length
                    if (lengthAccumulator > 100000) {
                        break
                    } else {
                        expanded.push(String(i))
                    }
                }
            }
            index++;
        }
        this.setState({ expanded })
    }

    initializeTabs() {
        const { content, diffChecked, expanded } = this.state;
        const { appContext } = this.props;

        const tabs = [];
        const tabContent = [];
        let isExpanded = (key) => {
            if (expanded.includes(key)) {
                return true
            } else {
                return false
            }
        }

        for (let k in content) {
            let part = content[k];
            let views = part.views;

            let rows = [];
            let approved = 0;
            for (let k in views) {
                let view = views[k];
                if (diffChecked[view.index])
                    approved += 1;
                let expansionPanel = <DiffRowExpansionBox
                    key={k}
                    approve={this.approve.bind(this)}
                    allowMerge={this.props.allowMerge}
                    checked={diffChecked[view.index]}
                    appContext={appContext}
                    view={view}
                    onChange={(e) => { this.handleExpand(k) }}
                    id={view.index}
                    expanded={isExpanded(k)} />
                rows.push(
                    expansionPanel
                )
            }

            let checkbox = this.props.allowMerge !== true ? <Checkbox checked={approved > 0} onChange={this.approveAll.bind(this, k)} /> : null;
            let selected = this.props.allowMerge !== true ? `(${approved} Selected)` : '';
            let diffList =
                <div >
                    <div>
                        <div style={styles.diffRow}>
                            {checkbox}
                            <div>
                                <Typography margintop='20px' align='left' variant="h6" color="inherit" >
                                    {views.length} Changes {selected}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {rows}
                </div>;


            tabs.push(<Tab key={part.name} label={part.name} />);
            tabContent.push(diffList);
        }

        return { tabs, tabContent }
    }

    confirmApprove() {
        let { appContext } = this.props;
        let { diffChecked, diffFileNames } = this.state
        let plan = appContext.state.plan;

        let checkedFileNames = [];
        if (isArrayValid(diffFileNames)) {
            checkedFileNames = diffFileNames.filter((value, index) => diffChecked[index]);
        }
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Approve Changes to plan: {`${plan.title}`}</DialogTitle>
                    <DialogContent>
                    
                        <DialogContentText>note the <font style={styles.BoldRedText}> {checkedFileNames.length}</font> files changed below:</DialogContentText>
                        <div style={styles.ScrollableFileListDiv}>
                            <ul>
                                {checkedFileNames.map((value, index) => <li key={index}>{value}</li>)}
                            </ul>
                        </div>
                        <DialogContentText>Please add an approval comment</DialogContentText>
                        <TextField
                            style={{ width: '550px', height: '100px' }}
                            label="Comment"
                            multiline
                            rows={3}
                            variant="outlined"
                            onChange={(e) => { this.setState({ comment: e.target.value }) }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button}
                            onClick={() => {
                                this.setState({ dialog: null }, this.approvePlan.bind(this))
                            }}>Ok</Button>
                        <Button variant="contained" style={styles.button}
                            onClick={() => {
                                this.setState({ dialog: null })
                            }}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    approvePlan() {
        const { parent, appContext } = this.props;
        const { parent: { state: { diffs, targetPlanId }}} = this.props;
        const { comment, diffChecked } = this.state;
        const { plan, username, account } = appContext.state;

        if (!account || !account.username ) {
            console.error("approvePlan account missing data:", account );
            return this.setState({error: 'Your account information is incomplete, please log out and log back in.'});
        }

        plan.approvedBy = account.username;
        plan.approvedDiffs = [];
        plan.approvedPlanId = targetPlanId;
        for (let i = 0; i < diffs.length; ++i) {
            if (diffChecked[i] === true)
                plan.approvedDiffs.push(diffs[i]);
        }

        let promises = [
            sendSlack(appContext, "testing", `${username} approved ${plan.approvedDiffs.length} change(s) to ${plan.title} (${plan.protected ? 'protected' : 'editable'}).` + (comment ? `\nComment: ${comment}` : ``)),
            comment ? addPlanActivity(appContext, { activityType: 'approve', comment }) : Promise.resolve(),
            savePlan(appContext, plan )
        ];

        parent.setState({ progress: <CircularProgress size={20} />, error: null });
        Promise.all(promises).then(() => {
            parent.setState({ progress: null });
            parent.loadContent();
        }).catch((error) => {
            parent.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    clearApprovedDiffs() {
        const { parent, appContext } = this.props;
        const { plan, username } = appContext.state;
        const changes = plan.approvedDiffs.length;

        plan.approvedBy = null;
        plan.approvedDiffs = [];
        parent.setState({ progress: <CircularProgress size={20} />, error: null });

        savePlan(appContext, plan).then(() => {
            return sendSlack(appContext, "testing", `${username} unapproved ${changes} change(s) to ${plan.title} (${plan.protected ? 'protected' : 'editable'}).`);
        }).then(() => {
            parent.setState({ progress: null });
            parent.loadContent();
        }).catch((error) => {
            parent.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    confirmMerge() {
        const { plan, parent: { state: { plans }}} = this.props;
        console.log("plan:", plan );
        let mergeInto = plans.find((e) => e.planId === plan.approvedPlanId);
        if ( mergeInto ) {
            this.setState({
                dialog:
                    <Dialog
                        model="false"
                        open={true}>
                        <DialogTitle>Merge Plan {`${plan.title} (${plan.protected ? 'Protected' : 'Editable'})`} into {`${mergeInto.title} (${mergeInto.protected ? 'Protected' : 'Editable'})`}</DialogTitle>
                        <DialogContent>
                        {plan.approvedBy === null && <DialogContentText>Warning: Plan has no approvedBy. Please approve before merging</DialogContentText>}
                            <DialogContentText>Please add an merge comment</DialogContentText>
                            <TextField
                                style={{ width: '550px', height: '100px' }}
                                label="Comment"
                                multiline
                                rows={3}
                                variant="outlined"
                                onChange={(e) => { this.setState({ comment: e.target.value }) }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" style={styles.button}
                                onClick={() => {
                                    this.setState({ dialog: null }, this.mergePlan.bind(this))
                                }}>Ok</Button>
                            <Button variant="contained" style={styles.button}
                                onClick={() => {
                                    this.setState({ dialog: null })
                                }}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
            });
        }
    }

    mergePlan() {
        const { comment } = this.state;
        const { plan, parent, appContext, parent: { state: { plans }} } = this.props;
        const { username } = appContext.state;
        const targetPlan = plans.find((e) => e.planId === plan.approvedPlanId);

        parent.setState({ progress: <CircularProgress size={20} />, error: null });
        let activity = { activityType: 'Merge', comment };
        addPlanActivity(appContext, activity).then(() => {
            return sendSlack(appContext, "testing", `${username} is merging data into ${targetPlan.title} (${targetPlan.protected ? 'protected' : 'editable'}).`);
        }).then(() => {
            return mergePlan(appContext, comment, plan.approvedPlanId, true);
        }).then(({ asyncId }) => {
            let pending = false;
            let mergePlansTimer = setInterval( () => {
                if ( pending ) return;
                pending = true;
                mergePlan(appContext, comment, plan.approvedPlanId, false, asyncId ).then((result) => {
                    if ( result.status !== 'active' ) {
                        console.log("mergePlan result:", result );
                        clearInterval( mergePlansTimer );

                        if ( result.status === 'error' ) {
                            throw new Error((result.result && result.result.error) || "Error merging plan");
                        }
                        
                        sendSlack(appContext, "testing", `${username} is done merging data into ${targetPlan.title} (${targetPlan.protected ? 'protected' : 'editable'}).`).then(() => {
                            plan.approvedBy = null;
                            plan.approvedDiffs = [];
                            parent.setState({progress: null, error: null});
                            parent.loadContent();

                            PubSub.publish(PLAN_TOPIC, { action: "PlanMerged", plan });
                        }).catch((err) => {
                            parent.setState({progress: null, error: getErrorMessage(err) });
                        })
                    }
                    pending = false;
                }).catch((err) => {
                    console.error("mergePlan error:", err );
                    clearInterval( mergePlansTimer );

                    sendSlack(appContext, "testing", `Error occured merging data into ${targetPlan.title} by ${username}!`);
                    parent.setState({progress: null, error: getErrorMessage(err) });
                })
            }, 2500 );
        }).catch((error) => {
            console.log(getErrorMessage(error))
            parent.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    render() {
        const { dialog, currentTab, content } = this.state;
        const { appContext, mode } = this.props;

        if ( content === null ) {
            return <div align='center'><CircularProgress /></div>;
        }
        if ( Object.keys(content).length === 0) {
            return <div><h2>No changes.</h2></div>
        }

        const { tabs, tabContent } = this.initializeTabs();

        let actions = [];

        if (mode === 'approved') {
            if (isAdminUser(appContext)) {
                actions.push(<Button key='unapprove'
                    style={{ margin: 5, align: 'right' }}
                    onClick={this.clearApprovedDiffs.bind(this)}>Clear Approved<RevertIcon />
                </Button>);
                actions.push(<Button key='mergeButton'
                    style={{ margin: 5, align: 'right' }}
                    onClick={this.confirmMerge.bind(this)}>Merge<MergeIcon />
                </Button>);
            }
        }
        else {
            actions.push(<Button key='approveButton' onClick={this.confirmApprove.bind(this)}>Approve<ApproveIcon /></Button>);
        }

        return (
            <div>
                <table width="100%"><tbody>
                    <tr>
                        <td>
                            <Tabs value={currentTab} onChange={(e, t) => { ; this.setState({ currentTab: t }, this.resetExpanded.bind(this)) }}>
                                {tabs}
                            </Tabs>
                        </td>
                        <td align="right">
                            {actions}
                        </td>
                    </tr>
                </tbody></table>
                {tabContent[currentTab]}
                {dialog}
            </div>
        );
    }
};

const styles = {
    diffRow: {
        display: 'flex',
        alignItems: 'center',
        margin: 2
    },
    BoldRedText: {
        color: "red",
        fontWeight: 'bold"'
    },
    ScrollableFileListDiv: {
        height: '110px',
        'overflowX': 'hidden',
        width: '100%',
        marginBottom: '10px'
    },
    button: {
        margin: 5
    }
}

const materialStyles = () => ({
    rootExpand: {
        margin: '0 !important',
    }
});

export default withStyles(materialStyles)(ContentChangesView)

