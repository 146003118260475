import React, { Component } from "react";
import {
    Tooltip, Dialog, DialogTitle, DialogContent,
    TextField, FormControl, DialogActions,
    FormControlLabel, InputLabel,
    Checkbox, Input, MenuItem,
    Select, Button,
    FormHelperText,
    IconButton,
} from '@material-ui/core/';
import ReactSelect, { components } from "react-select";
import CloneIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import move from "../utils/ArrayMove";
import InfoIcon from '@material-ui/icons/Info';
import { Table } from 'antd';
import ReactDragListView from 'react-drag-listview';
import EditObjectArray from '../components/EditObjectArray';
import { createIdFromText, createTextFromId } from "../utils/CreateIdFromText";
import {
    isArrayValid, loadQuestions, loadText
} from '@apricityhealth/web-common-lib/utils/Services';

import QuestionUtils from '../utils/QuestionUtils';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import '../styles/tableedit.css';

const { Placeholder } = components;

function findNextAnswerId(answers) {
    let index = 0;
    let found = nextAns(answers, index += 1);
    while (found !== undefined) {
        found = nextAns(answers, index += 1);
    }
    return String(index);
}
var nextAns = function (answers, index) {
    return answers.find((ans) => String(ans.answerId) === String(index))
};

class QuestionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: JSON.parse(JSON.stringify(props.question)),
            originalQuestion: JSON.parse(JSON.stringify(props.question)),
            selectedQuestion: null,
            allowQuestionSelect: this.props.allowQuestionSelect || false,
            language: 'en-us', //fix to english for now
            progress: null,
            dialog: null,
            done: false,
            error: null,
            hasDuplicateName: false,
            allNames: {}
        };

        const self = this;

        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                let { question } = self.state;
                question.answers = move(question.answers, fromIndex, toIndex);
                self.setState({ question }, () => self.onChange());
            },
            handleSelector: "a",
        };
        this.loadContent();
    }
    componentDidUpdate() {
        if (JSON.stringify(this.state.question) !== JSON.stringify(this.props.question)) {
            this.setState({ question: JSON.parse(JSON.stringify(this.props.question)) });
        }
    }

    loadContent() {
        let { appContext } = this.props;
        let questionUtils = new QuestionUtils(appContext);
        let { question, selectedQuestion } = this.state;
        let promises = [];
        promises.push(loadText(appContext, { dependecies: true }));
        promises.push(loadQuestions(appContext, { dependecies: true }));

        Promise.all(promises).then((results) => {
            let allTexts = results[0];
            let questions = results[1];
            let texts = {};
            //this is to make the lookup faster.. 
            allTexts.forEach(entry => {
                if (!texts[entry.language]) texts[entry.language] = {};
                texts[entry.language][entry.textId] = entry;
            });
            let allQuestions = questions.map(question => questionUtils.unlocalizeQuestion(question));
            let items = [{ value: 'newQuestion', label: "<New Question>" }];

            allQuestions.forEach(quest => {
                let label = quest.name ? quest.name : quest.dataId ? quest.dataId : quest.questionId;
                let item = { value: quest.questionId, label };
                if (question) {
                    if (item.value === question.questionId)
                        selectedQuestion = item;
                }
                items.push(item);
            });
            if (!selectedQuestion) {
                selectedQuestion = items[0];
            }
            let allNames = {};
            questions.forEach(type => {
                if (!type.name) {
                    type.name = createTextFromId(type.questionId);
                }
                type.name = type.name.toLowerCase();
                if (!allNames[type.name]) allNames[type.name] = [];
                allNames[type.name].push(type.dataId);
            });


            this.setState({ selectedQuestion, allQuestions, allNames, items, texts });
        }).catch((error) => {
            this.setState({ error: getErrorMessage(error) });
        });
    }

    onQuestionSelected(e) {
        let { allQuestions } = this.state;

        if (e.value === 'newQuestion') {
            let { originalQuestion } = this.state;
            this.setState({ selectedQuestion: e, question: originalQuestion }, () => this.onChange());
        } else {
            let question = allQuestions.find(q => q.questionId === e.value);
            if (!question.name) question.name = createTextFromId(question.questionId);
            this.setState({ selectedQuestion: e, question }, () => this.onChange());
        }
    }

    getQuestionText() {
        let { question } = this.state;
        return question && isArrayValid(question.question) ? question.question[0].text : "";
    }

    getAnswerText(answer) {
        return answer && isArrayValid(answer.text) && answer.text[0];
    }

    addAnswer() {
        let { question } = this.state;
        let answers = question.answers;
        let answer = {
            answerId: findNextAnswerId(answers),
            controlType: "text",
            text: [""],
            score: 0
        };
        answers.push(answer)
        this.setState({ question }, () => this.onChange());
    }

    getScoreEdit(answer) {
        let items = [];
        items.push(<FormControl style={styles.scoreControl}>
            <InputLabel>Score</InputLabel>
            <Select
                style={styles.scoreSelect}
                value={answer.score}
                onChange={(e) => {
                    answer.score = e.target.value;
                    this.updateAnswer(answer);
                }}>
                <MenuItem value='0'>0</MenuItem>
                <MenuItem value='1'>1</MenuItem>
                <MenuItem value='2'>2</MenuItem>
                <MenuItem value='3'>3</MenuItem>
                <MenuItem value='4'>4</MenuItem>
                <MenuItem value='NaN'>NaN</MenuItem>
            </Select>
        </FormControl>);
        items.push(<TextField
            style={styles.scoreControl}
            label='Custom'
            value={answer.score}
            onChange={(e) => {
                answer.score = e.target.value;
                this.updateAnswer(answer);
            }} />)
        return items;
    }

    getControlTypeEdit(answer) {
        if (!answer.controlType)
            answer.controlType = 'text';
        let items = [];
        items.push(<FormControl key="controltype" style={styles.controlType} >
            <InputLabel>Control Type</InputLabel>
            <Select
                value={answer.controlType}
                onChange={(selection) => {
                    answer.controlType = selection.target.value;
                    if (answer.controlType === 'slider') {
                        if (!answer.controlOptions)
                            answer.controlOptions = { min: 1, max: 10, scale: 1 };
                        else
                            delete answer.controlOptions;
                    }
                    this.updateAnswer(answer)
                }}
                input={<Input name="controlType" id="controlType-helper" />}
            >
                <MenuItem key={'text'} value={"text"}>Text</MenuItem>
                <MenuItem key={'edit'} value={"edit"}>Editable Text</MenuItem>
                <MenuItem key={'slider'} value={'slider'}>Slider</MenuItem>
                <MenuItem key={'singleton'} value={'singleton'}>Singleton</MenuItem>
                <MenuItem key={'bodyMap'} value={'bodyMap'}>Body Map</MenuItem>
            </Select>
            {answer.controlType === 'slider' && <FormHelperText style={{ color: "blue" }}>{`[${answer?.controlOptions?.min}-${answer?.controlOptions?.max} | ${answer?.controlOptions?.scale}]`}</FormHelperText>}
        </FormControl>);

        if (answer.controlType === 'slider') {
            items.push(<Tooltip title={"Edit Slider"}><IconButton style={styles.operationButton} onClick={() => this.editControlOptions(answer)}><EditIcon /></IconButton></Tooltip>)
        }
        return items;
    }

    editControlOptions(answer) {
        if (!answer.controlOptions)
            answer.controlOptions = { min: 0, max: 10, scale: 1 };
        this.setState({
            dialog: <ConditionEdit
                controlOptions={answer.controlOptions}
                onDone={(controlOptions) => {
                    answer.controlOptions = controlOptions;
                    let question = this.getUpdatedQuestion(answer)
                    this.setState({ dialog: null, question }, () => this.onChange());
                }
                } />

        })
    }

    getAnswerEdits(answer) {
        return <TextField
            style={styles.answer}
            label="Answer"
            value={this.getAnswerText(answer)}
            onChange={(e) => {
                if (!answer.text) answer.text = [];
                answer.text[0] = e.target.value;
                this.updateAnswer(answer);
            }}
        />
    }

    getExplanationEdit(answer) {
        return <TextField
            style={styles.answer}
            label="Explanation"
            value={answer.explanation}
            onChange={(e) => {
                answer.explanation = e.target.value;
                this.updateAnswer(answer);
            }}
        />
    }

    onChange() {
        let { question } = this.state;
        // this.setState({ question });
        if (typeof this.props.onChange === 'function')
            this.props.onChange(question);
    }

    getUpdatedQuestion(answer) {
        let { question } = this.state;
        if (answer.controlType !== 'slider') {
            answer.controlOptions = null;
        }
        let index = question.answers.findIndex((ans) => ans.answerId === answer.answerId);
        if (index)
            question.answers[index] = answer;

        return question;
    }
    onChaneMultiSelect(isMulti) {
        let { question } = this.state;
        question.multiSelect = isMulti;
        let { answers, questionId } = question;
        answers.forEach(answer => {
            if (isMulti === true) {
                let answerText = this.getAnswerText(answer);
                let id = createIdFromText(answerText)
                answer.dataId = `${questionId}.${id}`
            } else {
                answer.dataId = null
            }
        });

        this.setState({ question }, () => this.onChange());


    }
    updateAnswer(answer) {
        let { question } = this.state;
        if (answer.controlType !== 'slider') {
            answer.controlOptions = null;
        }
        let index = question.answers.findIndex((ans) => ans.answerId === answer.answerId);
        if (index)
            question.answers[index] = answer;
        this.setState({ question }, () => this.onChange());
    }

    updateName(e) {

        let { question, allNames } = this.state;
        let name = e.target.value;
        question.name = name;
        question.description = e.target.value;

        let dups = allNames[name.toLowerCase()];
        let hasDuplicateName = isArrayValid(dups) && dups[0] !== question.questionId;

        let id = createIdFromText(name);
        question.dataId = id;
        question.questionId = id;
        this.setState({ question, hasDuplicateName }, () => this.onChange());
    }

    updateQuestionDescription(e) {
        let { question } = this.state;
        question.question[0].text = e.target.value;
        this.setState({ question }, () => this.onChange());
    }

    deleteAnswer(answer) {
        let { question } = this.state;
        let index = question.answers.findIndex((ans) => ans.answerId === answer.answerId);
        question.answers.splice(index, 1);
        this.setState({ question }, this.onChange());
    }

    cloneAnswer(answer) {
        let { question } = this.state;
        let newRecord = JSON.parse(JSON.stringify(answer));
        newRecord.answerId = findNextAnswerId(question.answers);
        question.answers.push(newRecord);
        this.setState({ question }, this.onChange());

    }

    displayPreview() {
        const self = this;
        this.setState({
            dialog: <Dialog fullWidth={true} maxWidth="sm" modal="true" open={true}>
                <DialogTitle>Preview</DialogTitle>
                <DialogContent>
                    <div style={{ margin: 5 }}>
                        <div dangerouslySetInnerHTML={{ __html: this.getQuestionText() }}></div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => {
                        self.setState({ dialog: null });
                    }}>OK</Button>
                </DialogActions>
            </Dialog>
        });
    }

    render() {
        let self = this;
        let { question, allQuestions, allowQuestionSelect, selectedQuestion, items, progress, dialog, error, hasDuplicateName } = this.state;
        let columns = [
            {
                title: "#",
                width: 40,
                render: (text, record, index) =>
                    <div >
                        <a className="drag-handle" href="#section" >{index + 1}</a>
                    </div>
            },
            {
                title: "ANSWER",
                ellipsis: true,
                width: '40%',
                render: (text, record, index) => {
                    return self.getAnswerEdits(record.answer);
                }
            },
            {
                title: "CONTROL TYPE",
                width: '22%',
                render: (text, record, index) => {
                    return self.getControlTypeEdit(record.answer)
                },
            },
            {
                title: "EXPLANATION",
                ellipsis: true,
                width: '30%',
                render: (text, record, index) => {
                    return self.getExplanationEdit(record.answer);
                }
            },
            {
                title: "SCORE",
                width: '10%',
                render: (text, record, index) => {
                    return self.getScoreEdit(record.answer)
                }
            },
            {
                title: "ACTIONS",
                width: '15%',
                render: (text, record, index) => {
                    return <div  >
                        <Tooltip title={"Clone Answer"}><IconButton style={styles.operationButton} onClick={() => this.cloneAnswer(record.answer)}><CloneIcon /></IconButton></Tooltip>
                        <Tooltip title={'Delete Answer'}><IconButton onClick={() => this.deleteAnswer(record.answer)}>{<DeleteIcon />}</IconButton></Tooltip>
                    </div>
                }
            }

        ];

        let contentList = [];
        if (question && Array.isArray(question.answers)) {
            for (let index = 0; index < question.answers.length; index++) {
                let answer = question.answers[index]
                contentList.push({ answer, order: index + 1, key: answer.answerId });
            };
        }

        let loading = true
        if (isArrayValid(allQuestions))
            loading = false

        return (
            <table width="100%" >
                <tbody>
                    <tr valign='top'>
                        {error}
                    </tr>
                    <tr valign='top'>
                        {allowQuestionSelect && <td style={{ width: 320 }}>
                            <FormControl style={{ margin: 5, width: 300 }}>
                                <InputLabel>Select question</InputLabel>
                                <ReactSelect
                                    isLoading={loading}
                                    isSearchable
                                    value={selectedQuestion}
                                    options={items}
                                    placeholder={"Select Question"}
                                    onChange={(selection) => this.onQuestionSelected(selection)}
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: 'visible'
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            marginTop: 20
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: -15,
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: '0.75rem',
                                            lineHeight: 1,
                                            marginLeft: -5
                                        })
                                    }}
                                />
                            </FormControl>
                        </td>}
                        <td style={{ width: 220 }}>
                            {question && <TextField
                                style={{ margin: 5, width: '200px' }}
                                label="Name:"
                                error={Boolean(hasDuplicateName)}
                                helperText={Boolean(hasDuplicateName) && "Duplicate exists"}
                                value={question.name}
                                onChange={(e) => {
                                    this.updateName(e);
                                }}
                            />}

                        </td>
                        <td style={{ width: '100%' }}>
                            {question &&
                                <TextField
                                    style={{ margin: 5, width: '100%' }}
                                    label="Question:"
                                    defaultValue={question.question[0].text}
                                    onChange={(e) => {
                                        this.updateQuestionDescription(e)
                                    }}
                                />}
                        </td>
                        <td style={{ width: 100 }}>
                            <IconButton onClick={() => self.displayPreview()}><InfoIcon /></IconButton>
                        </td>
                        <td align='right' style={{ width: 160 }}>
                            {question && <FormControlLabel style={{ marginLeft: '1px', width: 130 }}
                                control={<Checkbox
                                    checked={question.multiSelect}
                                    onChange={(e) => {
                                        this.onChaneMultiSelect(e.target.checked)
                                    }}
                                />
                                }
                                label="Multi Select" />}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={allowQuestionSelect ? 5 : 4} valign="top">
                            <ReactDragListView  {...this.dragProps}>
                                {<Table
                                    className="answer-table"
                                    pagination={{ pageSize: 20 }}
                                    title={() => <table valign='bottom' style={{ width: '100%', border: 'none' }}><tbody><tr><td valign='bottom'><i>The answers can be reorderd by dragging</i></td><td valign='bottom' align='right'>{question && <Tooltip title={'Add Answer'}><IconButton disabled={progress !== null} onClick={() => this.addAnswer()}><AddIcon /></IconButton></Tooltip>}</td></tr></tbody></table>}
                                    columns={columns}
                                    dataSource={contentList}
                                />}
                            </ReactDragListView>
                        </td>
                    </tr>
                    {dialog}
                </tbody>
            </table>
        )
    }
}



function ConditionEdit({ controlOptions, onDone }) {
    return (
        <Dialog fullWidth={true} maxWidth="sm" modal="true" open={true}>
            <DialogTitle>Edit slider</DialogTitle>
            <DialogContent>
                <EditObjectArray style={{ margin: 5 }} object={controlOptions} label={'Control Options'} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={() => {
                    onDone(controlOptions);
                }}>OK</Button>
            </DialogActions>
        </Dialog>
    )

}

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <components.ValueContainer {...props}>
            <Placeholder  {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child => {
                return child && child.type !== Placeholder ? child : null;
            })}
        </components.ValueContainer>
    );
};

const styles = {

    operationButton: {
        margin: 5,
        width: 40,
        height: 40
    },
    question: {
        margin: 5,
        width: 800
    },
    name: {
        margin: 5,
        width: 300
    },
    number: {
        margin: 5,
        width: 100
    },
    dataTypeSelect: {
        paddingLeft: 10,
        width: "300px",

    },
    select: {
        margin: 5,
        width: 100
    },
    answer: {
        margin: 5,
        width: '100%'
    },

    scoreControl: {
        margin: 5,
        width: '50%'
    },
    scoreSelect: {
        marginLeft: 0,
        marginTop: 16,
        width: '100%',
        align: "left"
    },
    controlType: {
        margin: 5,
        width: 130
    },
    slider: {
        margin: 5,
        width: 200
    },
    sliderMax: {
        margin: 5,
        width: 50
    },
    days: {
        margin: 5,
        width: 100
    },
    div: {
        margin: 10
    },
    button: {
        margin: 10
    },
    inputRoot: {
        fontSize: 30,
        color: "orange",
    },
    labelRoot: {
        fontSize: 30,
        color: "red",
        "&$labelFocused": {
            color: "purple"
        }
    },
};

export default QuestionComponent;

