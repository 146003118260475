import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    TextField,
    Divider
} from '@material-ui/core/';

class EditDetectRuleView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: props.alerts,
            rule: props.rule,
            background: '#ffffff',
            progress: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        let { background, alerts } = state;
        let rule = props.rule;
        console.log(`alerts`, alerts)
        if (alerts && alerts[rule.alert])
            background = alerts[rule.alert].color;
        return { rule, background };
    }

    onChange = type => e => {
        let { rule, background, alerts } = this.state;
        rule[type] = e.target.value;
        if (alerts && alerts[rule.alert])
            background = alerts[rule.alert].color;
        this.setState({ rule, background });
    }
    onChangeRule( key, type, e ) {
        let { rule } = this.state;
        console.log(`onChangeRule rule[${key}][${type}] = ${e.target.value}`);
        rule[key][type] = e.target.value;
        this.setState({ rule });
    }
    onChangePrimary = type => e => {
        let { rule } = this.state;
        rule.primarySymptoms[type] = e.target.value;
        this.setState({ rule });
    }
    onChangeAssociated = type => e => {
        let { rule } = this.state;
        rule.associatedSymptoms[type] = e.target.value;
        this.setState({ rule });
    }
    onChangeClinical = type => e => {
        let { rule } = this.state;
        rule.clinicalModifiers[type] = e.target.value;
        this.setState({ rule });
    }

    render() {
        const { detectModel } = this.props;
        let { rule, background, alerts } = this.state;

        let alertOptions = [];
        //console.log("alerts:", alerts );
        if (alerts) {
            let alertValues = Object.values(alerts)
            alertValues.sort((a, b) => {
                if (a.priority < b.priority)
                    return -1;
                else if (a.priority > b.priority)
                    return 1;
                return 0;
            });
            alertValues.forEach(alert => {
                alertOptions.push(<MenuItem key={alert.name} style={{ backgroundColor: alert.color }} 
                    value={alert.alertLevelId}>{alert.name}</MenuItem>)
            });
        }

        function getMinMaxControl(label, value, onChange) {
            return <FormControl style={{margin: 5, width: 75}}>
                <InputLabel>{label}</InputLabel>
                <Select value={value} onChange={onChange}>
                    <MenuItem value='0'>0</MenuItem>
                    <MenuItem value='1'>1</MenuItem>
                    <MenuItem value='2'>2</MenuItem>
                    <MenuItem value='3'>3</MenuItem>
                    <MenuItem value='4'>4</MenuItem>
                </Select>
            </FormControl>;
        }

        function getAlertControl(label, value, onChange) {
            return <FormControl style={{margin: 5, width: 150}}>
                <InputLabel>{label}</InputLabel>
                <Select style={{ backgroundColor: background }} value={value} onChange={onChange}>
                    {alertOptions}
                </Select>
            </FormControl>
        }

        function getConditionControl(value, onChange) {
            return <FormControl style={{margin: 5, width: 75}}>
                <InputLabel>Condition</InputLabel>
                <Select value={value} onChange={onChange}>
                    <MenuItem value='all'>all</MenuItem>
                    <MenuItem value='any'>any</MenuItem>
                </Select>
            </FormControl>
        }

        function getCountCountrol(value, onChange) {
            return <TextField type='number' label='Count' value={value} onChange={onChange} style={{margin: 5, width: 75}} />;
        }

        function getConditionSelect(value, onChange) {
            let options = [];
            options.push(<MenuItem key='none' value=''>None</MenuItem>);
            
            if ( Array.isArray(detectModel.conditions)) {
                for(let i=0;i<detectModel.conditions.length;++i) {
                    let condition = detectModel.conditions[i];
                    options.push(<MenuItem key={i} value={condition.conditionId}>{condition.description}</MenuItem>);
                }
            }

            return <FormControl style={{margin: 5, width: 200}}>
                <Select value={value} onChange={onChange}>
                    {options}
                </Select>
            </FormControl>
        }

        return (<div>
            {getMinMaxControl('Grade', rule.grade, this.onChange('grade'))}
            {getAlertControl('Alert', rule.alert, this.onChange("alert"))}
            {getMinMaxControl('Priority', rule.priority, this.onChange("priority"))}
            <br /><br />
            Primary Symptom
            <Divider />
            {getMinMaxControl('Min', rule.primarySymptoms.min, this.onChangeRule.bind(this,'primarySymptoms','min'))}
            {getMinMaxControl('Max', rule.primarySymptoms.max, this.onChangeRule.bind(this,'primarySymptoms','max'))}
            {getConditionControl(rule.primarySymptoms.cond, this.onChangeRule.bind(this, 'primarySymptoms', 'cond'))}
            {rule.primarySymptoms.cond === 'any' ? getCountCountrol(rule.primarySymptoms.count, this.onChangeRule.bind(this, 'primarySymptoms', 'count')) : null}
            <br /><br />
            Associated Symptom
            <Divider />
            {getMinMaxControl('Min', rule.associatedSymptoms.min, this.onChangeRule.bind(this, 'associatedSymptoms', "min"))}
            {getMinMaxControl('Max', rule.associatedSymptoms.max, this.onChangeRule.bind(this, 'associatedSymptoms', "max"))}
            {getConditionControl(rule.associatedSymptoms.cond, this.onChangeRule.bind(this, 'associatedSymptoms', "cond"))}
            {rule.associatedSymptoms.cond === 'any' ? getCountCountrol(rule.associatedSymptoms.count, this.onChangeRule.bind(this, 'associatedSymptoms', 'count')) : null}
            <br /><br />
            Pertinent Positives
            <Divider />
            {getMinMaxControl('Min', rule.positiveSymptoms.min, this.onChangeRule.bind(this, 'positiveSymptoms', "min"))}
            {getMinMaxControl('Max', rule.positiveSymptoms.max, this.onChangeRule.bind(this, 'positiveSymptoms', "max"))}
            {getConditionControl(rule.positiveSymptoms.cond, this.onChangeRule.bind(this, 'positiveSymptoms', "cond"))}
            {rule.positiveSymptoms.cond === 'any' ? getCountCountrol(rule.positiveSymptoms.count, this.onChangeRule.bind(this, 'positiveSymptoms', 'count')) : null}
            <br /><br />
            Pertinent Negatives
            <Divider />
            {getMinMaxControl('Min', rule.negativeSymptoms.min, this.onChangeRule.bind(this, 'negativeSymptoms', "min"))}
            {getMinMaxControl('Max', rule.negativeSymptoms.max, this.onChangeRule.bind(this, 'negativeSymptoms', "max"))}
            {getConditionControl(rule.negativeSymptoms.cond, this.onChangeRule.bind(this, 'negativeSymptoms', "cond"))}
            {rule.negativeSymptoms.cond === 'any' ? getCountCountrol(rule.negativeSymptoms.count, this.onChangeRule.bind(this, 'negativeSymptoms', 'count')) : null}
            <br /><br />
            Clinical Modifiers
            <Divider />
            {getMinMaxControl('Min', rule.clinicalModifiers.min, this.onChangeRule.bind(this, 'clinicalModifiers', "min"))}
            {getMinMaxControl('Max', rule.clinicalModifiers.max, this.onChangeRule.bind(this, 'clinicalModifiers', "max"))}
            {getConditionControl(rule.clinicalModifiers.cond, this.onChangeRule.bind(this, 'clinicalModifiers', "cond"))}
            {rule.clinicalModifiers.cond === 'any' ? getCountCountrol(rule.clinicalModifiers.count, this.onChangeRule.bind(this, 'clinicalModifiers', 'count')) : null}
            <br /><br />
            Condition
            <Divider />
            {getConditionSelect(rule.conditionId, this.onChange("conditionId"))}
        </div>
        )
    }
}

class EditDetectRulesDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: props.alerts,
            editType: props.editType,
            rule: JSON.parse( JSON.stringify(props.rule) )
        };
        console.log(`EditDetectRulesDialog`)
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        const { onDone } = this.props;
        const { rule } = this.state;
        if (onDone)
            onDone(rule);
    }

    onChange = type => e => {
        let { rule } = this.state;
        rule[type] = e.target.value;
        this.setState({ rule });
    }

    render() {
        let { rule, alerts, editType } = this.state;
        if(!rule)  {
            rule = {
                grade: 0,
                alert: 'green',
                priority: 0,
                primarySymptoms: { min: 0, max: 4, cond: 'all', count: 1 },
                associatedSymptoms: { min: 0, max: 4, cond: 'all', count: 1  },
                positiveSymptoms: { min: 0, max: 4, cond: 'all', count: 1 },
                negativeSymptoms: { min: 0, max: 4, cond: 'all', count: 1  },
                clinicalModifiers: { min: 0, max: 4, cond: 'all', count: 1 },
                conditionId: ''
            };
        }
        const { appContext } = this.props
        let self = this;
        let title = null;
        let content = null;

        if (editType === 'remove') {
            title = 'Detect Rule Remove';
            content = <div><span style={{ color: 'red', fontSize: '20px' }}>Warning:</span> Are you sure you want to remove this rule. This has potential to break the detection engine. Please be sure.</div>;
        } else if (editType === 'edit') {
            title = 'Edit Detect Rule';
            content = <EditDetectRuleView appContext={appContext} detectModel={this.props.detectModel} rule={rule} alerts={alerts} />
        } else if (editType === 'add') {
            title = 'New Detect Rule';
            content = <EditDetectRuleView appContext={appContext} detectModel={this.props.detectModel} rule={rule} alerts={alerts} />
        }

        return (
            <Dialog model="false" open={true} maxWidth='xs' fullWidth>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Ok</Button>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    }
};

EditDetectRulesDialog.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditDetectRulesDialog);
