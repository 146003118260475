import React, { Component } from 'react';
import JsonDialog from '../dialogs/JsonDialog';
import TranslationDialog from '../dialogs/TranslationDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import ChangedByButton from '../components/ChangedByButton';

import {
    AppBar,
    Toolbar,
    Typography,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    IconButton,
    TextField,
    FormLabel,
    Button,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import TranslateIcon from '@material-ui/icons/Translate';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

class TextView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
            language: props.language,
            progress: null,
            deleteProgress: null,
            modified: false,
            dialog: null,
            autoVersion: true
        };
    }

    componentDidUpdate(oldProps) {
        if (this.props.text !== oldProps.text || this.props.language !== oldProps.language) {
            this.setState({ text: this.props.text, language: this.props.language });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.text && props.text) {
            if (state.text.textId !== props.text.textId) {
                return { text: props.text, modified: false };
            } else {
                return null
            }
        } else {
            return null
        }
    }

    onChange = type => e => {
        let { text, modified } = this.state;
        text[type] = e.target.value;
        modified = true
        this.setState({ text, modified });
    }

    deleteText() {
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Text: {`${this.state.text.text}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { this.cancelDeleteText() }}>Cancel</Button>
                        <Button variant="contained" style={styles.button} onClick={() => { this.confirmDeleteText() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDeleteText() {
        this.setState({ dialog: null });
    }

    saveText(planId) {
        const { text, autoVersion, modified } = this.state;
        const { appContext } = this.props
        const store = appContext.stores.DataTypesStore;

        if (!planId && text.planId && text.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(text.planId, this.saveText.bind(this));
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null, modified: false, dialog: null });
        if (autoVersion && modified) {
            text.version += 1;
        }
        store.saveText(text, planId).then(() => {
            this.setState({ progress: null });
            if (this.props.onDone)
                this.props.onDone(text);
        }).catch((error) => {
            console.error("Error saving text  ", error);
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    confirmDeleteText() {
        const self = this
        const { text } = this.state;
        const { appContext } = self.props
        const store = appContext.stores.DataTypesStore;

        this.setState({
            dialog: null,
            error: null,
            deleteProgress: <CircularProgress size={20} />
        });
        store.deleteText(text.textId, text.language, text.planId).then(() => {
            this.setState({ deleteProgress: null });
            if (this.props.onDone) {
                this.props.onDone(null);
            }
        }).catch((error) => {
            console.error("Error deleted text  ", error);
            this.setState({ deleteProgress: null, error: getErrorMessage(error) });
        });
    }

    translateText() {
        const self = this;
        let { text } = this.state;
        self.setState({
            dialog:
                <TranslationDialog
                    content={text}
                    appContext={self.props.appContext}
                    onCancel={() => {
                        self.setState({ dialog: null })
                    }}
                    onDone={() => {
                        self.setState({ dialog: null })
                    }}
                />
        })
    }

    showNodeJSON() {
        let { appContext } = this.props;
        let { text } = this.state;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={text}
                onEditDone={(text) => {
                    this.setState({ text, dialog: null });
                }}
                onDone={() => {
                    this.setState({ dialog: null });
                }} />
        });
    }

    closeContent() {
        if (this.state.modified === true) {
            this.displayModifiedDialog();
        } else {
            this.props.onClose();
        }
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    displayModifiedDialog() {
        let dialog = <Dialog open={true}>
            <DialogTitle>Content Modified!</DialogTitle>
            <DialogContent>This content has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    this.saveText();
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    this.setState({ modified: false, dialog: null }, this.props.onClose());
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    render() {
        const { progress, deleteProgress, dialog, text, autoVersion, error } = this.state;
        if (text) {
            return (
                <div align="center">
                    <AppBar style={styles.appBar} position="static">
                        <Toolbar>
                            <IconButton onClick={() => this.closeContent()}>
                                <NavigationClose />
                            </IconButton>
                            <Typography variant="h6" color="inherit">Edit Content</Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={styles.mainBody} align="center" >
                        <div align="left" style={styles.div}>
                            <div style={styles.buttonHeader}>
                                <div>Text Id: {text.textId}</div>
                                <div style={styles.delete} valign="top">
                                    <span style={{ color: 'red' }}>{error}</span>
                                    <IconButton disabled={progress !== null} onClick={() => { this.saveText(); }}>{progress || <SaveIcon />}</IconButton>
                                    <IconButton onClick={() => { this.showNodeJSON(); }}><CodeIcon /></IconButton>
                                    <IconButton onClick={() => { this.translateText(); }}><TranslateIcon /></IconButton>
                                    <IconButton disabled={deleteProgress !== null} onClick={() => { this.deleteText(); }}>{deleteProgress || <DeleteIcon />}</IconButton>
                                </div>
                            </div>
                            <br />   <br />
                            <TextField
                                style={{ width: 400, margin: 5 }}
                                label="Text ID"
                                value={text.textId}
                                onChange={this.onChange("textId")}
                            />
                            <TextField
                                style={{ width: 100, margin: 5 }}
                                type='number'
                                label="Version"
                                disabled={autoVersion}
                                value={text.version}
                                onChange={(e) => {
                                    text.version = e.target.value;
                                    this.setState({ text, modified: true })
                                }} />
                            <FormControlLabel label='Auto Version' control={<Checkbox checked={autoVersion} onChange={(e, v) => this.setState({ autoVersion: v })} />} />
                            <TextField
                                style={{ width: 100, margin: 5 }}
                                type='number'
                                label="Order"
                                value={text.order}
                                onChange={(e) => {
                                    text.order = e.target.value;
                                    this.setState({ text, modified: true })
                                }} />
                            <br />   <br />
                            <TextField
                                fullWidth
                                style={{ margin: 5 }}
                                label="Category"
                                value={text.category}
                                onChange={this.onChange("category")}
                            />
                            <br />   <br />
                            <TextField
                                fullWidth
                                style={{ margin: 5 }}
                                label="Language"
                                value={text.language}
                                onChange={this.onChange("language")}
                            />
                            <br />   <br />
                            <TextField
                                fullWidth
                                style={{ margin: 5 }}
                                label="Content"
                                variant="outlined"
                                value={text.text}
                                multiline={true}
                                rows={10}
                                onChange={this.onChange("text")}
                            />
                            <div style={styles.renderedContent}>
                                <FormLabel>Rendered:</FormLabel>
                                <div dangerouslySetInnerHTML={{ __html: text.text }}>
                                </div>
                            </div>
                            <ChangedByButton appContext={this.props.appContext} primaryKey={text.textId} collection='TextModel' />
                        </div>
                    </div>
                    {dialog}
                </div>
            )
        } else {
            return null
        }
    }
}

const styles = {
    mainBody: {
        padding: 10
    },
    div: {
        margin: 10
    },
    buttonHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    renderedContent: {
        paddingTop: 10,
        maxWidth: 800
    },
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    }
}
export default TextView;