import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@material-ui/core/';

import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";
import EditAlertTypeArrayView from "../components/EditAlertTypeArrayView";
import EditActivityArrayView from "../components/EditActivityArrayView";
//import MessageArrayView from "../dialogs/MessageArrayView";

class EditConditionGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: props.group
        };
    }
    componentDidUpdate(oldProps) {
        if (oldProps.group !== this.props.group)
            this.setState({ group: oldProps.group });
    }

    onChange(e, type) {
        let { group } = this.state;
        group[type] = e.target.value;
        this.setState({ group });

        if (this.props.onChange)
            this.props.onChange(group);
    }
    render() {
        const { group } = this.state;
        const { appContext, model } = this.props;

        //console.log(`rendering condition group `, group);
        if (!group.dataConditions)
            group.dataConditions = "";

        const generateTypeItems = () => {
            const { appContext: { stores: { DataTypesStore }} } = this.props;
            return DataTypesStore.getRecommendGroupTypes().sort((a,b) => a.name.localeCompare(b.name)).map((e) => {
                return <MenuItem key={e.typeId} value={e.typeId}>{e.name}</MenuItem>;
            });
        } 

        return (
            <div>
                <TextField
                    label={'Name'}
                    style={styles.entry}
                    value={group.name}
                    onChange={(e) => { this.onChange(e, "name") }}
                />
                <br />
                <FormControl style={{margin: 5}}>
                    <InputLabel>Type</InputLabel>
                    <Select label='Type' value={group.type} onChange={(e) => {
                        this.onChange(e, "type");
                    }}>
                        {generateTypeItems()}
                    </Select>
                </FormControl>
                <br />
                <EditConditions
                    appContext={appContext}
                    conditions={group.dataConditions}
                    onChange={(conditions) => {
                        group.dataConditions = conditions;
                        this.setState({group}, () => {
                            if ( this.props.onChange )
                                this.props.onChange(group);
                        });
                    }}
                    model={model}
                    name={'Conditions'}
                />
                <EditAlertTypeArrayView appContext={appContext} array={group.addAlert} name={'Add Alerts'} /><br />
                <EditAlertTypeArrayView appContext={appContext} array={group.deleteAlert } name={'Delete Alerts'} /><br />
                <EditActivityArrayView appContext={appContext} array={group.addActivity} name={"Add Activities"} /><br />
                {/* <MessageArrayView key='patient' appContext={appContext} array={group.patientNotifications} name={"Patient Notifications"} /><br />
                <MessageArrayView key='provider' appContext={appContext} array={group.providerNotifications} name={"Provider Notifications"} /><br /> */}
            </div>
        )
    }
}

const styles = {
    div: {
        margin: 10
    },
    entry: {
        margin: 5,
        width: 300
    },
};

EditConditionGroup.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditConditionGroup);