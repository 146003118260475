import React from "react";
import {
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core/';

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

import EditDataTypeDialog from '../dialogs/EditDataTypeDialog';
import createIdFromText from "../utils/CreateIdFromText";
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../styles/workup.css';

export class EditModelDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            model: JSON.parse(JSON.stringify(props.model))     // make a copy 
        }
    }

    onDone() {
        this.props.onDone(this.state.model);
    }

    render() {
        const { appContext } = this.props;
        let { model, dialog } = this.state;

        return <Dialog open={true}>
            <DialogTitle>Edit Model</DialogTitle>
            <DialogContent>
                <TextField disabled={true} style={styles.name} label="Model ID" value={model.modelId} onChange={(e) => {
                    model.modelId = e.target.value;
                    this.setState({ model });
                }} />
                <br />
                <TextField style={styles.name} label="Name" value={model.name} onChange={(e) => {
                    model.name = e.target.value;
                    model.modelId = createIdFromText(model.name);
                    this.setState({ model });
                }} />
                <br />
                <TextField style={styles.description} label="Description" value={model.description} onChange={(e) => {
                    model.description = e.target.value;
                    this.setState({ model });
                }} />
                <br />
                <TextField style={styles.description} label="Instructions" value={model.instructions} onChange={(e) => {
                    model.instructions = e.target.value;
                    this.setState({ model });
                }} />
                <br />
                <FormControl style={styles.select}>
                    <InputLabel>Category</InputLabel>
                    <Select value={model.category} onChange={(e) => {
                        model.category = e.target.value;
                        this.setState({ model });
                    }}>
                        <MenuItem value='pro'>PRO</MenuItem>
                        <MenuItem value='lab'>Lab</MenuItem>
                        <MenuItem value='triage'>Triage</MenuItem>
                        <MenuItem value='survey'>Survey</MenuItem>
                        <MenuItem value='symptom'>Symptom</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <TextField style={styles.description} label="Chief Complaint" value={model.complaintText} onChange={(e) => {
                    model.complaintText = e.target.value;
                    this.setState({ model });
                }} />
                <TextField style={styles.description} label="Status" value={model.status} onChange={(e) => {
                    model.status = e.target.value;
                    this.setState({ model });
                }} />
                <br />
                <TextField style={styles.number} type='number' label="Order" value={model.order} onChange={(e) => {
                    model.order = e.target.value;
                    this.setState({ model });
                }} />
                <br />
                <EditConditions
                    style={{ width: '100%' }}
                    appContext={appContext}
                    conditions={model.condition}
                    onChange={(condition) => {
                        model.condition = condition;
                        this.setState({ model });
                    }}
                    name={<span style={{ fontSize: '14px' }}>Condition that triggers this model</span>}
                />
                <br />
                <SelectDataType label='Model Data Type' style={styles.select} appContext={appContext} category={'model'} dataId={model.dataId}
                    onSelect={(dataType) => {
                        model.dataId = dataType ? dataType.dataId : '';
                        this.setState({ model, modified: true });
                    }}
                    onCreate={() => {
                        this.setState({
                            dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                dataType={{ dataId: '', name: '', description: '', category: 'model', tupleDescriptions: [] }}
                                onCancel={() => this.setState({ dialog: null })}
                                onDone={(newType) => {
                                    model.dataId = newType.dataId;
                                    this.setState({ dialog: null, model, modified: true });
                                }} />
                        });
                    }}
                />
                <br />
                <FormControlLabel style={{ margin: 5 }} label="Default Model" control={<Checkbox checked={model.defaultModel} onChange={(e, v) => {
                    model.defaultModel = v;
                    this.setState({ model });
                }} />} />
                <FormControlLabel style={{ margin: 5 }} label="No Triggers Default" control={<Checkbox checked={model.defaultTrigger} onChange={(e, v) => {
                    model.defaultTrigger = v;
                    this.setState({ model });
                }} />} />
                <FormControlLabel style={{ margin: 5 }} label="Related Questions" control={<Checkbox checked={model.relatedQuestions} onChange={(e, v) => {
                    model.relatedQuestions = v;
                    this.setState({ model });
                }} />} />
                <FormControlLabel style={{ margin: 5 }} label="Disable Questions" control={<Checkbox checked={model.disableQuestions} onChange={(e, v) => {
                    model.disableQuestions = v;
                    this.setState({ model });
                }} />} />
                <FormControlLabel style={{ margin: 5 }} label="Hide During Triage" control={<Checkbox checked={model.hideDuringTriage} onChange={(e, v) => {
                    model.hideDuringTriage = v;
                    this.setState({ model });
                }} />} />
                <FormControlLabel style={{ margin: 5 }} label="Inactive" control={<Checkbox checked={model.inactive} onChange={(e, v) => {
                    model.inactive = v;
                    this.setState({ model });
                }} />} />
                {dialog}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>OK</Button>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 5
    },
    button: {
        margin: 5
    },
    number: {
        margin: 5,
        width: 200
    },
    name: {
        margin: 5,
        width: 300
    },
    select: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

export default EditModelDialog;
