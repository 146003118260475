import React, { Component } from 'react';
import JsonDialog from '../../dialogs/JsonDialog';
import {
    AppBar,
    Toolbar,
    Typography,
    CircularProgress,
    Dialog,
    IconButton,
    TextField,
    Button,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Drawer,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';

import CodeIcon from '@material-ui/icons/Code';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import SelectUnit from '@apricityhealth/web-common-lib/components/SelectUnit';
import TypeSearch from '@apricityhealth/web-common-lib/components/TypeSearch';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import OverrideDialog from "../../dialogs/OverrideDialog";
import EditDataTypeDialog from '../../dialogs/EditDataTypeDialog';
import { v4 as uuidv4 } from 'uuid';

import ChangedByButton from '../../components/ChangedByButton';

class ObservationTypeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modified: false,
            rule: props.rule
        }
        this._SelectDataTypeChild = React.createRef();
    }
    componentDidMount() {
        if (this.props.rule)
            this.loadRule(JSON.parse(JSON.stringify(this.props.rule)));
    }

    componentDidUpdate(oldProps) {
        if (this.props.rule !== oldProps.rule)
            this.loadRule(JSON.parse(JSON.stringify(this.props.rule)));
    }

    closeView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else
            this.props.onClose();
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Observation Rule Modified!</DialogTitle>
            <DialogContent>This observation rule has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveRule(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    showRuleJSON() {
        const self = this;
        let { appContext } = this.props;
        let { rule } = this.state;
        self.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={rule}
                onEditDone={(rule) => {
                    self.setState({ rule, dialog: null });
                }}
                onDone={() => {
                    self.setState({ dialog: null });
                }} />
        });
    }

    loadRule(rule) {
        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadRule.bind(this, rule));
        }
        if (!rule.name) rule.name = '';
        if (!rule.code) rule.code = '';
        if (!rule.system) rule.system = '';
        if (!rule.dataId) rule.dataId = '';
        if (!rule.normalizeMethod) rule.normalizeMethod = 'Linear';
        if (!rule.referenceRange) rule.referenceRange = [];
        if (rule.tupleIndex === undefined) rule.tupleIndex = '';
        if (rule.unitTupleIndex === undefined) rule.unitTupleIndex = '';
        if (rule.normalizedTupleIndex === undefined) rule.normalizedTupleIndex = '';

        this.setState({
            rule: rule,
            progress: null,
            dialog: null
        });
    }

    saveRule(callback, planId = null) {
        const self = this;
        const { rule } = self.state;
        let { appContext } = this.props;

        if (!planId && rule.planId && rule.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(rule.planId, this.saveRule.bind(this, callback));
        }

        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        this.setState({ progress: <CircularProgress /> });

        appContext.stores.DataTypesStore.saveObservationRule(rule, planId).then((response) => {
            self.setState({ modified: false, rule: response, progress: null }, callback);
            this.closeView();
        }).catch((error) => {
            self.setState({ progress: error.message });
        });
    }

    deleteRule() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Rule: {`${self.state.rule.ruleId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.closeDialog.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={this.confirmDelete.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    closeDialog() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        let { appContext } = this.props;
        let { rule } = this.state;

        this.setState({ dialog: null, modified: false, progress: <CircularProgress /> });
        appContext.stores.DataTypesStore.deleteObservationRule(rule.ruleId, rule.planId).then(() => {
            this.setState({ progress: null });
            this.closeView();
        }).catch((error) => {
            console.log("deleteRule error:", error.response);
            this.setState({ progress: error.message });
        });
    }

    onSelectDataType(dataType) {
        let { rule } = this.state;
        rule.dataId = dataType ? dataType.dataId : '';
        this.setState({ rule });
    }

    onSelectTupleIndex(e) {
        let { rule } = this.state;
        rule.tupleIndex = `${e}`;
        this.setState({ rule, modified: true });
    }

    onUpdateRule(item) {
        let { rule } = this.state;
        rule.name = item.name;
        rule.code = item.code;
        rule.system = item.system;
        this.setState({ dialog: null, rule, modified: true });
    }

    resetSelectDataTypeChild() {
        this._SelectDataTypeChild.current.updateTypes()
    }


    showCreateDataType() {
        let self = this
        let { appContext } = this.props
        let dataType = {
            name: "New Name",
            description: "New Description",
            category: "labTest",
            tupleDescriptions: [],
            kind: 'symptom',
            dataId: uuidv4()
        }

        self.setState({
            dialog: <EditDataTypeDialog
                appContext={appContext}
                dataType={dataType}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(dataType) => {
                    self.setState({ dialog: null }, self.resetSelectDataTypeChild.bind(self));
                }} />
        });
    }

    search(args) {
        let self = this;
        let { appContext } = this.props;
        let { code, system, search } = args;
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <TypeSearch
                appContext={appContext}
                searchType={'observations'}
                code={code}
                system={system}
                search={search}
                closeSearch={this.closeDialog.bind(this)}
                itemSelected={this.onUpdateRule.bind(this)} />
        </Drawer>;
        self.setState({ progress: null, dialog: dialog });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { rule, progress, dialog } = this.state;

        const columnData = [
            { id: 'low', numeric: true, editType: 'text', label: 'Low' },
            { id: 'high', numeric: true, editType: 'text', label: 'High' }
        ];

        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Observation</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    Rule ID: {rule.ruleId}

                                </td>
                                <td valign="top" align="right">
                                    <IconButton onClick={() => { self.showRuleJSON(); }}><CodeIcon /></IconButton>
                                    <IconButton onClick={this.saveRule.bind(this, null, null)}><SaveIcon /></IconButton>
                                    <IconButton disabled={!rule.ruleId} onClick={this.deleteRule.bind(this)}><DeleteIcon /></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Owner ID: {rule.ownerId}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField style={styles.name} label="Name" value={rule.name}
                                        onChange={(e) => { rule.name = e.target.value; self.setState({ rule, modified: true }); }} />
                                    <IconButton disabled={!rule.name} onClick={() => { self.search({ search: rule.name }); }} ><SearchIcon /></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField style={styles.system} label="System" value={rule.system}
                                        onChange={(e) => { rule.system = e.target.value; self.setState({ rule, modified: true }); }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField multiline={true} style={styles.code} label="Code" value={rule.code}
                                        onChange={(e) => { rule.code = e.target.value; self.setState({ rule, modified: true }); }} />
                                    <IconButton disabled={!rule.code} onClick={() => { self.search({ system: rule.system, code: rule.code }); }} ><SearchIcon /></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectDataType
                                        category={'labTest,vitals'}
                                        appContext={appContext} dataId={rule.dataId} enableNone={true}
                                        ref={this._SelectDataTypeChild}
                                        onSelect={this.onSelectDataType.bind(this)}
                                        onCreate={() => {
                                            self.setState({
                                                dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                                    dataType={{ dataId: '', name: '', description: '', category: 'labTest', tupleDescriptions: [] }}
                                                    onCancel={() => self.setState({ dialog: null })}
                                                    onDone={(newType) => {
                                                        rule.dataId = newType.dataId;
                                                        self.setState({ dialog: null, rule });
                                                    }} />
                                            });
                                        }}
                                    />
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={rule.isReviewed}
                                            onChange={(e) => {
                                                rule.isReviewed = e.target.checked
                                                self.setState({ rule, modified: true });
                                            }}
                                        />
                                        } label="Reviewed" /> 
                                    <FormControlLabel style={{ marginLeft: 10, marginTop: 20 }}
                                        control={<Checkbox
                                            checked={rule.isIgnored}
                                            onChange={(e) => {
                                                rule.isIgnored = e.target.checked
                                                self.setState({ rule, modified: true });
                                            }}
                                        />
                                        } label="Ignored" /> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField style={styles.category} label="Unit" value={rule.unit}
                                        onChange={(e) => { rule.unit = e.target.value; self.setState({ rule, modified: true }); }} />

                                    <SelectUnit style={styles.category} label="Target Unit" value={rule.targetUnit} enableNone={true}
                                        onChange={(v) => { rule.targetUnit = v; self.setState({ rule, modified: true }); }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <SelectTupleIndex appContext={appContext} dataId={rule.dataId}
                                        allowNone={true} tupleIndex={rule.tupleIndex} onChange={(e) => {
                                            rule.tupleIndex = `${e}`; self.setState({ rule, modified: true });
                                        }} />

                                    <SelectTupleIndex appContext={appContext} dataId={rule.dataId} label="Normalized Tuple Index"
                                        allowNone={true} tupleIndex={rule.normalizedTupleIndex} onChange={(e) => {
                                            rule.normalizedTupleIndex = `${e}`; self.setState({ rule, modified: true });
                                        }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <FormControl style={{ margin: 5, width: 300 }}>
                                        <InputLabel>Normalize Method</InputLabel>
                                        <Select value={rule.normalizeMethod} onChange={(e) => {
                                            rule.normalizeMethod = e.target.value; self.setState({ rule })
                                        }}>
                                            <MenuItem value='Linear'>Linear</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <SelectTupleIndex appContext={appContext} dataId={rule.dataId} label="Unit Tuple Index"
                                        allowNone={true} tupleIndex={rule.unitTupleIndex} onChange={(e) => {
                                            rule.unitTupleIndex = `${e}`; self.setState({ rule, modified: true });
                                        }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <EnhancedTable rowsPerPage={2}
                                        onDataChanged={(data) => {
                                            rule.referenceRange = data;
                                            self.setState({ rule, modified: true });
                                        }}
                                        orderBy='_index'
                                        columnData={columnData}
                                        data={rule.referenceRange}
                                        title='Reference Ranges' />
                                </td>
                            </tr>
                            <tr>
                                <td>

                                    <ChangedByButton appContext={this.props.appContext} primaryKey={rule.ruleId} collection='ObservationRuleModel' />
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
                {progress}
            </div >
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    name: {
        margin: 5,
        width: '90%'
    },
    category: {
        margin: 5,
        width: 300
    },
    code: {
        margin: 5,
        width: '90%'
    },
    system: {
        margin: 5,
        width: '90%'
    },
    question: {
        margin: 5,
        width: '80%'
    },
    select: {
        margin: 10,
        width: 100
    },
    tags: {
        margin: 5
    },
    text: {
        margin: 5
    },
    tab: {
        "backgroundColor": "lightblue"
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default ObservationTypeView;
