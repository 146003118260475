import React, { Component } from 'react';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import ConfigurationView from './ConfigurationView';
import Plan from '@apricityhealth/web-common-lib/components/Plan';

import {
    loadConfigurations
} from '@apricityhealth/web-common-lib/utils/Services';

import { 
    Drawer, 
    CircularProgress, 
    TextField,
    FormControlLabel,
    Checkbox,
    IconButton
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import PubSub from 'pubsub-js'

function testFilter(filter, config) {
    if (config) {
        filter = filter.toLowerCase();
        if (config.configId && config.configId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (config.config && config.config.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

class ConfigurationsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dependencies: true,
            filter: '',
            configs: [],
            progress: null
        }
    }
    
    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadConfigs();
            }
        });
        this.loadConfigs();
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadConfigs(){
        const self = this;
        const { dependencies } = this.state;

        self.setState({ progress: <CircularProgress size={20} />, error: null });
        loadConfigurations(this.props.appContext, { dependencies }).then((configs) => {
            self.setState({ configs, progress: null });
        }).catch(function (error) {
            console.log("loadConfigs error:", error.response);
            self.setState({ progress: null, error: error.message });
        });
    }

    updateFilter() {
        let { configs, filter } = this.state;
        if (filter) {
            let filtered = [];
            for (var i = 0; i < configs.length; ++i) {
                if (testFilter(filter, configs[i]))
                    filtered.push(configs[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    createConfig(){
        const { appContext } = this.props;
        let dialog = <div className='drawer' ><Drawer variant="persistent" anchor="right" open={true} >
            <ConfigurationView appContext={appContext} onClose={this.onCloseDialog.bind(this)} />
        </Drawer> </div>;
        this.setState({  dialog });
    }

    configSelected(s, t) {
        console.log(`config selected `,  s);
        if (s.length > 0) {
            const { configs, filtered } = this.state;
            const config = filtered ? filtered[s[0]] : configs[s[0]];

            console.log(`config selected `,  config);
            let dialog = <div className='drawer' >
                <Drawer variant="persistent" anchor="right" open={true}>
                    <ConfigurationView appContext={this.props.appContext} configId={config.configId} onClose={this.onCloseDialog.bind(this)} />
                </Drawer>
            </div>;
            this.setState({ dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }
    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadConfigs();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    render(){
        const self = this;
        let { configs, filtered, error, progress, dialog, filter, dependencies } = this.state;
        const columnData = [
            { id: 'configId', numeric: false, disabledPadding: false, label: 'configId' },
            { id: 'config', numeric: false, disabledPadding: false, label: 'Config'},
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width='100%'><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                            onChange={(e) => { filter = e.target.value; self.setState({ filter }, this.updateFilter.bind(this)); }} />
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadConfigs.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadConfigs.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable disableMultiSelect={true}
                            onTable={table => self._types = table}
                            onSelected={(s, t) => self.configSelected(s, t)}
                            onAdd={() => self.createConfig()}
                            orderBy='name'
                            columnData={columnData}
                            data={filtered ? filtered : configs}
                            title='Configurations' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}
export default ConfigurationsView ;
