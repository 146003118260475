import React from 'react';
import T from 'i18n-react';
import clsx from 'clsx';

import {
    AppBar,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    withStyles
} from '@material-ui/core/';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';

import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';

class AppHeader extends React.Component {
    render() {
        const {
            props: {
                anchorEl,
                appContext, 
                auth,
                classes,
                handleMenu, handleMenuClose, isMenuOpen,
                onSettings, onSignOut, onToggle,
                open,
                username
            }
        } = this;
        const { mode } = appContext.state;
        const pathname = window.location.pathname.slice(1).split('/')[0];


        return <div>
            <div className={classes.toolbar} />
            <AppBar position='fixed' className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
                <Toolbar disableGutters={! open}>
                    <IconButton aria-label='Menu' color='inherit' className={classes.menuButton}
                        onClick={onToggle}>
                    <MenuIcon /></IconButton>
                    <Typography variant='h6' color='inherit' className={classes.grow}>
                        {<T.span text={pathname} />}
                    </Typography>
                    {auth && <div>
                        <SelectPlan hideProtected={mode !== 'advanced'} appContext={appContext} style={
                        {
                            backgroundColor: 'white',
                            margin: '10px', padding: '5px 5px 5px 25px', borderRadius: '10px'
                        }} />
                        <span>{username}</span>
                        <IconButton aria-haspopup aria-owns={isMenuOpen ? 'menu-appbar' : undefined}
                            color='inherit' onClick={handleMenu}
                        ><AccountCircle /></IconButton>
                        <Menu id='menu-appbar' open={isMenuOpen} anchorEl={anchorEl} anchorOrigin={
                            { vertical: 'top', horizontal: 'right' }} transformOrigin={
                            { vertical: 'top', horizontal: 'right' }} onClose={handleMenuClose}
                        >
                            <MenuItem onClick={e => onSettings()}>Settings</MenuItem>
                            <MenuItem onClick={e => onSignOut()}>Logout</MenuItem>
                        </Menu>
                    </div>}
                </Toolbar>
            </AppBar>
        </div>;
    }
};


const drawerWidth = 300;
const styles = theme => ({
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: '350ms',
            duration: '350ms',
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: '350ms',
            duration: '350ms',
        }),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'flex-start',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 36,
    },
    grow: {
        flexGrow: 1,
    }
});


export default withStyles(styles, { withTheme: true })(AppHeader);