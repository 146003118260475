import React from "react";
import {
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip
} from '@material-ui/core/';

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex from "@apricityhealth/web-common-lib/components/SelectTupleIndex";
import SelectUnit from "@apricityhealth/web-common-lib/components/SelectUnit";
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

import 'react-perfect-scrollbar/dist/css/styles.css';
import '../styles/workup.css';

export class EditTriggerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trigger: JSON.parse(JSON.stringify(props.trigger))     // make a copy 
        }
    }

    onDone() {
        this.props.onDone(this.state.trigger);
    }

    onChange(field, e) {
        let { trigger } = this.state;
        if (trigger) {
            trigger[field] = e.target !== undefined ? e.target.value : e;
            this.setState({ trigger });
        }
    }

    render() {
        const self = this;
        let { trigger } = this.state;
        if (trigger.condition === undefined) trigger.condition = '';

        return <Dialog open={true}>
            <DialogTitle>Edit Trigger</DialogTitle>
            <DialogContent>
                <TextField label="Description" style={styles.description} value={trigger.description}
                    onChange={this.onChange.bind(this, 'description')} />
                <br />
                <SelectDataType appContext={this.props.appContext} dataId={trigger.dataId} onSelect={(dataType) => {
                    trigger.dataId = dataType ? dataType.dataId : '';
                    self.setState({ trigger });
                }} />
                <br />
                <SelectTupleIndex appContext={this.props.appContext} dataId={trigger.dataId} tupleIndex={trigger.tupleIndex}
                    onChange={(tupleIndex, dataType) => {
                        trigger.tupleIndex = tupleIndex;
                        trigger.description = dataType ? dataType.tupleDescriptions[tupleIndex].description : '';
                        self.setState({ trigger });
                    }} />
                <br />
                <TextField label="Time Window (Hours)" style={styles.number} value={trigger.timeWindow} type='number'
                    onChange={this.onChange.bind(this, 'timeWindow')} />
                <br />
                <TextField label="Min" style={styles.number} value={trigger.min} type='number'
                    onChange={this.onChange.bind(this, 'min')} />
                <br />
                <TextField label="Max" style={styles.number} value={trigger.max} type='number'
                    onChange={this.onChange.bind(this, 'max')} />
                <br />
                <SelectUnit style={styles.number} value={trigger.unit} onChange={this.onChange.bind(this,'unit')} enableNone={true} />
                {trigger.unit ? <SelectTupleIndex appContext={this.props.appContext} dataId={trigger.dataId} label={'Unit Tuple Index'} tupleIndex={trigger.unitTupleIndex}
                    onChange={(tupleIndex) => {
                        trigger.unitTupleIndex = tupleIndex;
                        self.setState({trigger})
                    }} /> : null}
                <br />
                <EditConditions
                    appContext={this.props.appContext}
                    conditions={trigger.condition}
                    onChange={(condition) => {
                        trigger.condition = condition;
                        self.setState({ trigger });
                    }}
                    name={'Conditions'}
                />
                <br />
                <Tooltip title="If true, then we will test against the latest value found in the time window.">
                    <FormControlLabel control={<Checkbox checked={trigger.useLatest} onChange={(e) => {
                        trigger.useLatest = e.target.checked;
                        if ( trigger.useLatest && trigger.useLowest) trigger.useLowest = false;
                        self.setState({ trigger });
                    }} />} label="Use Latest" />
                </Tooltip>
                <Tooltip title="If true, then we will test against the lowest value found in the time window.">
                    <FormControlLabel control={<Checkbox checked={trigger.useLowest} disabled={trigger.useLatest} onChange={(e) => {
                        trigger.useLowest = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Use Lowest" />
                </Tooltip>
                <Tooltip title="If true, then value can be equal to the min or max.">
                    <FormControlLabel control={<Checkbox checked={trigger.inclusive} onChange={(e) => {
                        trigger.inclusive = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Inclusive" />
                </Tooltip>
                <Tooltip title="This forces requires the symptom to be gather, even if we already have collected the data within the time window.">
                    <FormControlLabel control={<Checkbox checked={trigger.required} onChange={(e) => {
                        trigger.required = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Required" />
                </Tooltip>
                <Tooltip title="Set to require data to be from the same data group.">
                    <FormControlLabel control={<Checkbox checked={trigger.inGroup} onChange={(e) => {
                        trigger.inGroup = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Same Day" />
                </Tooltip>
                <Tooltip title="Set to require data to be from the same session.">
                    <FormControlLabel control={<Checkbox checked={trigger.inSession} onChange={(e) => {
                        trigger.inSession = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Same Session" />
                </Tooltip>
                <Tooltip title="Set to add the model to trigger dialog questions.">
                    <FormControlLabel control={<Checkbox checked={trigger.addModel} onChange={(e) => {
                        trigger.addModel = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Add Model" />
                </Tooltip>
                <Tooltip title="If true, then this will be includes in the symptom list in the complaint.">
                    <FormControlLabel control={<Checkbox checked={trigger.symptom} onChange={(e) => {
                        trigger.symptom = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Symptom" />
                </Tooltip>
                <Tooltip title="If true, then no questions will be triggered by this symptom.">
                    <FormControlLabel control={<Checkbox checked={trigger.disableQuestions} onChange={(e) => {
                        trigger.disableQuestions = e.target.checked;
                        self.setState({ trigger });
                    }} />} label="Disable Questions" />
                </Tooltip>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>OK</Button>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
};

const styles = {
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 5
    },
    button: {
        margin: 5
    },
    number: {
        margin: 5,
        width: 200
    },
    name: {
        margin: 5,
        width: 300
    },
    select: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

export default EditTriggerDialog;