import React, { Component } from "react";
import {
    FormControl, Tooltip, IconButton, FormControlLabel, Checkbox,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core/';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactSelect, { components } from "react-select";

import {
    loadDetectModels,
    loadDataTypes,
    loadPlans,
    getAlertLevels,
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';

import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import '../styles/tableedit.css';

const TYPE_TEMPLATES = require('../data/TypeTemplates.json');
const { Placeholder } = components;

class SymptomDetectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowModelSelect: this.props.allowModelSelect || false,
            detectModel: null,
            allDataTypes: [],
            allDetectModels: [],
            allAlerts: [],
            selectedModel: null,
            error: null,
            cloneDetect: false,
            preserveDetect: true
        };
    }

    componentDidMount() {
        this.loadContent();
    }
    // componentDidUpdate(oldProps) {
    //     if (this.props.modelDataId !== oldProps.modelDataId) {
    //         console.log(`Model changed `, this.props.modelDataId);
    //         // this.onModelChanged();
    //     }
    // }

    loadContent() {
        const self = this;
        const { appContext, modelDataId } = this.props;
        let { selectedModel } = this.state;
        const { plan } = appContext.state;
        if (plan) {

            let promises = [];
            //load all the models anyway so we can cross check for duplicate modelId
            promises.push(loadDetectModels(appContext, { dependencies: true, category: "pro,triage,symptom,survey", planId: '*', resolveConflicts: false } ));
            promises.push(loadDataTypes(appContext, { planId: "*", dependencies: false, resolveConflicts: false} ));
            promises.push(getAlertLevels(appContext, { planId: "*", dependencies: true} ));
            promises.push(loadPlans(appContext));
            Promise.all(promises).then(([allDetectModels, allDataTypes, allAlerts, plans]) => {
                let items = [];
                allDetectModels.forEach(detectModel => {
                    let plan = plans.find((plan) => plan.planId === detectModel.planId);
                    let planName = plan ? plan.title : "";
                    let state = plan.protected ? "prod" : "edit";
                    let label = detectModel.name ? `${detectModel.name} (${detectModel.category}) (${planName} - ${state})` : `${detectModel.modelId} (${detectModel.category}) (${planName} - ${state})`;
                    let value = detectModel.modelDataId +"-"+ detectModel.category +"-"+ planName +"-"+ state;
                    let item = { value , modelDataId: detectModel.modelDataId, label };
                    if (modelDataId) {
                        if (item.modelDataId === modelDataId)
                            selectedModel = item;
                    }
                    items.push(item);
                });

                items.sort((a, b) => {
                    if (a.label < b.label)
                        return -1;
                    else if (a.label > b.label)
                        return 1;
                    return 0;
                });
                items.unshift({ value: 'none', modelDataId: "none", label: "<None>" }, { value: 'newDetectModel', modelDataId: 'newDetectModel', label: "<New Detect Model>" });
                if (!selectedModel) {
                    selectedModel = items[0];
                }

                console.log(`Items `, items);
                self.setState({ allDetectModels, allDataTypes, allAlerts, selectedModel, items }, () => this.onModelChanged());
            }).catch((err) => {
                self.setState({ error: getErrorMessage(err) });
            });
        }
    }


    onModelChanged() {
        let { planId, modelDataId } = this.props;
        let { items, selectedModel, allDetectModels } = this.state;
        let detectModel = null;
        let originalDetectModel = null;
        if (modelDataId === 'none') {
            detectModel = null;
            selectedModel = items[0];
        } else if (modelDataId === 'newDetectModel') {
            detectModel = this.newDetectModel("New Detect Model", 'newDetectModel', planId);
            selectedModel = items[1];
        } else {
            detectModel = allDetectModels.find(m => m.modelDataId === modelDataId);
            if (detectModel) {
                selectedModel = items.find(item => item.modelDataId === modelDataId);
                detectModel = JSON.parse(JSON.stringify(detectModel));
                originalDetectModel = JSON.parse(JSON.stringify(detectModel));
            }
        }
        this.updateTypes(detectModel, originalDetectModel, selectedModel);
    }

    updateTypes(detectModel, originalDetectModel, selectedModel) {
        let { planId, modelDataId } = this.props;
        let { allDataTypes, name, description } = this.state;
        let originalGradeDataType = null;
        let gradeDataType = null;
        let priorityDataType = null;
        let originalPriorityDataType = null;
        let alertDataType = null;
        let originalAlertDataType = null;
        let scoreDataType = null;
        let originalScoreDataType = null;

        if (detectModel && detectModel.modelId) {
            let modelId = detectModel.modelId;

            gradeDataType = allDataTypes.find((type) => type.dataId === detectModel.gradeDataId);
            if (gradeDataType && gradeDataType.dataId) {
                originalGradeDataType = JSON.parse(JSON.stringify(gradeDataType));
                gradeDataType = JSON.parse(JSON.stringify(gradeDataType));
            } else {
                gradeDataType = {
                    dataId: modelId + "Grade",
                    name,
                    planId: planId,
                    description,
                    category: 'grade',
                    parentId: modelDataId,
                    tupleDescriptions: [],
                    valueDescriptions: []
                }
            }

            priorityDataType = allDataTypes.find((type) => type.dataId === detectModel.priorityDataId);
            if (priorityDataType && priorityDataType.dataId) {
                originalPriorityDataType = JSON.parse(JSON.stringify(priorityDataType));
                priorityDataType = JSON.parse(JSON.stringify(priorityDataType));
            } else {
                priorityDataType = {
                    dataId: modelId + "Priority",
                    name,
                    planId: planId,
                    description,
                    category: 'status',
                    parentId: modelDataId,
                    tupleDescriptions: [],
                    valueDescriptions: []
                }
            }

            alertDataType = allDataTypes.find((type) => type.dataId === detectModel.alertDataId);

            if (alertDataType && alertDataType.dataId) {
                originalAlertDataType = JSON.parse(JSON.stringify(alertDataType));
                alertDataType = JSON.parse(JSON.stringify(alertDataType));
            } else {
                alertDataType = {
                    dataId: modelId + "Alert",
                    name,
                    planId: planId,
                    description,
                    category: 'alert',
                    parentId: modelDataId,
                    tupleDescriptions: [],
                    valueDescriptions: []
                }
            }

            scoreDataType = allDataTypes.find((type) => type.dataId === detectModel.scoreDataId);

            if (scoreDataType && scoreDataType.dataId) {
                originalScoreDataType = JSON.parse(JSON.stringify(scoreDataType));
                scoreDataType = JSON.parse(JSON.stringify(scoreDataType));
            } else {
                scoreDataType = {
                    dataId: modelId + "Score",
                    name,
                    planId: planId,
                    description,
                    category: 'score',
                    parentId: modelDataId,
                    tupleDescriptions: [],
                    valueDescriptions: []
                }
            }
        }

        this.setState({
            detectModel,
            originalDetectModel,
            scoreDataType,
            originalScoreDataType,
            alertDataType,
            originalAlertDataType,
            gradeDataType,
            originalGradeDataType,
            priorityDataType,
            originalPriorityDataType,
            selectedModel
        }, () => this.onChange());
    }


    onModelTemplateSelected(selectedModel) {
        let { planId } = this.props;
        let { originalDetectModel, allDetectModels } = this.state;
        let detectModel = null;
        if (selectedModel.modelDataId === "none") {
            detectModel = null;
        } else if (selectedModel.modelDataId === "newDetectModel") {
            detectModel = this.newDetectModel("New Detect Model", 'newDetectModel', planId);
            detectModel = JSON.parse(JSON.stringify(detectModel));
        } else {
            detectModel = allDetectModels.find(nextModel => nextModel.modelDataId === selectedModel.modelDataId);
            detectModel = JSON.parse(JSON.stringify(detectModel));
            // originalDetectModel = JSON.parse(JSON.stringify(detectModel));
        }
        this.updateTypes(detectModel, originalDetectModel, selectedModel);

    }


    newDetectModel(name, modelId, planId) {
        let model = TYPE_TEMPLATES.detectModels["symptomDetectModel"];
        model.name = name;
        model.modelId = modelId;
        model.planId = planId;
        model.gradeDataId = modelId + "Grade";
        model.priorityDataId = modelId + "Priority";
        model.alertDataId = modelId + "Alert";
        model.scoreDataId = modelId + "Score";
        model.modelDataId = modelId;
        model.description = `Detect model for ${name}`;
        return model;
    }


    onChangeRule(index, key, e, type) {
        let { detectModel } = this.state;
        detectModel.rules[index][key] = e.target.value;
        this.setState({ detectModel }, () => this.onChange());
    }

    onChangeSymptoms(index, key, type, e) {
        let { detectModel } = this.state;
        detectModel.rules[index][key][type] = e.target.value;
        this.setState({ detectModel }, () => this.onChange());
    }

    deleteRule(index) {
        let { detectModel } = this.state;
        detectModel.rules.splice(index, 1);
        this.setState({ detectModel }, () => this.onChange());
    }

    onChange() {
        let { detectModel,
            originalDetectModel,
            scoreDataType,
            originalScoreDataType,
            alertDataType,
            originalAlertDataType,
            gradeDataType,
            originalGradeDataType,
            priorityDataType,
            originalPriorityDataType, cloneDetect, preserveDetect } = this.state;
        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                detectModel,
                originalDetectModel,
                scoreDataType,
                originalScoreDataType,
                alertDataType,
                originalAlertDataType,
                gradeDataType,
                originalGradeDataType,
                priorityDataType,
                originalPriorityDataType,
                cloneDetect, preserveDetect
            });
    }

    addRule() {
        let { detectModel } = this.state;
        detectModel.rules.unshift({
            "alert": "red",
            "grade": 4,
            "priority": 4,
            "primarySymptoms": {
                "min": 4,
                "max": 4,
                "cond": "any",
                "count": 1
            }
        });
        this.setState({ detectModel }, () => this.onChange());
    }


    render() {
        let { error, detectModel, allAlerts, items, selectedModel, allowModelSelect, cloneDetect, preserveDetect } = this.state;
        let loading = true
        if (isArrayValid(items))
            loading = false
        let alertOptions = [];
        if (allAlerts) {
            let alertValues = Object.values(allAlerts)
            alertValues.sort((a, b) => {
                if (a.priority < b.priority)
                    return -1;
                else if (a.priority > b.priority)
                    return 1;
                return 0;
            });
            alertValues.forEach(alert => {
                alertOptions.push(<MenuItem key={alert.name} style={{ backgroundColor: alert.color }}
                    value={alert.alertLevelId}>{alert.name}</MenuItem>)
            });
        }

        function getMinMaxControl(label, value, onChange) {
            return <FormControl style={{ margin: 5 }}>
                <InputLabel>{label}</InputLabel>
                <Select value={value} onChange={onChange}>
                    <MenuItem value='0'>0</MenuItem>
                    <MenuItem value='1'>1</MenuItem>
                    <MenuItem value='2'>2</MenuItem>
                    <MenuItem value='3'>3</MenuItem>
                    <MenuItem value='4'>4</MenuItem>
                </Select>
            </FormControl>;
        }


        function getConditionControl(value, onChange) {
            return <FormControl style={{ margin: 5 }}>
                <InputLabel>Condition</InputLabel>
                <Select value={value} onChange={onChange}>
                    <MenuItem value='all'>all</MenuItem>
                    <MenuItem value='any'>any</MenuItem>
                </Select>
            </FormControl>
        }

        function getAlertControl(label, value, onChange) {
            return <FormControl style={{ margin: 5 }}>
                <InputLabel>{label}</InputLabel>
                <Select style={{ backgroundColor: allAlerts[value] ? allAlerts[value].color : null }} value={value} onChange={onChange}>
                    {alertOptions}
                </Select>
            </FormControl>
        }

        let gradeCols = [];
        let alertCols = [];
        let priorityCols = [];
        let controlCols = [];

        let rules = detectModel ? detectModel.rules : [];
        for (let index = 0; index < rules.length; index++) {
            const rule = detectModel.rules[index];
            gradeCols.push(<td key={"g" + index}><table className="empty_table" width="100%"><tbody><tr><td>{getMinMaxControl('Grade', rule.grade, this.onChangeRule.bind(this, index, 'grade'))}</td><td align="right" valign="top">{<Tooltip title={'Remove rule'}><IconButton onClick={() => this.deleteRule(index)}><DeleteIcon /></IconButton></Tooltip>}</td></tr></tbody></table></td>);
            alertCols.push(<td key={"a" + index}>{getAlertControl('Alert', rule.alert, this.onChangeRule.bind(this, index, "alert"))}</td>);
            priorityCols.push(<td key={"p" + index}>{getMinMaxControl('Priority', rule.priority, this.onChangeRule.bind(this, index, "priority"))}</td>);
            controlCols.push(<td key={"c" + index}> <span style={{ fontSize: '12px', marginLeft: '5px', color: "grey" }}>Primary Symptom</span><br />{getMinMaxControl('Min', rule.primarySymptoms.min, this.onChangeSymptoms.bind(this, index, 'primarySymptoms', 'min'))}
                {getMinMaxControl('Max', rule.primarySymptoms.max, this.onChangeSymptoms.bind(this, index, 'primarySymptoms', 'max'))}
                {getConditionControl(rule.primarySymptoms.cond, this.onChangeSymptoms.bind(this, index, 'primarySymptoms', 'cond'))}</td>);
        }
        let showOptions = selectedModel && selectedModel.modelDataId !== 'none' && selectedModel.modelDataId !== 'newDetectModel';

        return (
            <div>
                {error}
                <table width="100%" >
                    <tbody>
                        {allowModelSelect && <tr>
                            <td style={{ width: "500px" }}>
                                <FormControl style={{ marginBottom: 5, marginTop: 5, marginRight: 5, width: "500px" }}>
                                    <InputLabel>Select Model</InputLabel>
                                    <ReactSelect
                                        isLoading={loading}
                                        isSearchable
                                        value={selectedModel}
                                        options={items}
                                        placeholder={"Select Model Template (... all plans)"}
                                        onChange={(e) => this.onModelTemplateSelected(e)}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        styles={{
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                overflow: 'visible'
                                            }),
                                            container: (provided, state) => ({
                                                ...provided,
                                                marginTop: 20
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: -15,
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: '0.75rem',
                                                lineHeight: 1,
                                                marginLeft: -5
                                            })
                                        }}
                                    />
                                </FormControl>
                            </td>

                            {showOptions && <td align="right" width="200px">
                                <Tooltip title={'Preserve the Detect model and data types.'}>
                                    <FormControlLabel style={{ marginLeft: '0px', marginTop: '20px' }}
                                        control={<Checkbox
                                            checked={preserveDetect}
                                            onChange={(e) => {
                                                this.setState({ preserveDetect: e.target.checked }, () => this.onChange());
                                            }}
                                        />
                                        }
                                        label="Preserve" />
                                </Tooltip>
                                <Tooltip title={'Clone the Detect model and data types and rename. This will also create them in the current plan.'}>
                                    <FormControlLabel style={{ marginLeft: '0px', marginTop: '20px' }}
                                        control={<Checkbox
                                            checked={cloneDetect}
                                            onChange={(e) => {
                                                this.setState({ cloneDetect: e.target.checked }, () => this.onChange());
                                            }}
                                        />
                                        }
                                        label="Clone" />
                                </Tooltip>
                            </td>}
                        </tr>}
                        <tr>
                            <td width="100%" colSpan='2'>
                                <table width="100%" >
                                    <tbody>
                                        <tr>
                                            <td width="95%">
                                                <table className="styled_table" >
                                                    <tbody>
                                                        <tr>{gradeCols}</tr>
                                                        <tr>{alertCols}</tr>
                                                        <tr>{priorityCols}</tr>
                                                        <tr>{controlCols}</tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td align="right" valign="top" >
                                                <Tooltip title={'Add rule'}><IconButton onClick={() => this.addRule()}><AddIcon /></IconButton></Tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div >
        )
    }
}
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <components.ValueContainer {...props}>
            <Placeholder  {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child => {
                return child && child.type !== Placeholder ? child : null;
            })}
        </components.ValueContainer>
    );
};
export default SymptomDetectComponent;
