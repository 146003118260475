import React, { Component } from "react";
import '../styles/doc.css';

import {
    IconButton,
    Tooltip,
} from '@material-ui/core/';

import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import {
    arrayRemoveLocation,
    isArrayValid
} from '../utils/Utils';

import EditDetectRulesDialog from '../dialogs/EditDetectRulesDialog';
import JsonDialog from '../dialogs/JsonDialog';
class DetectModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alerts: props.alerts,
            detectModel: props.detectModel,
            model: props.model,
            key: props.key ? props.key : 'report',
            dialog: null
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.detectModel !== this.props.detectModel) {
            this.setState({ detectModel: this.props.detectModel });
        }
    }

    getAlertCol(alert, span, key) {
        let { alerts } = this.state;
        let color = alerts && alerts[alert] ? alerts[alert].color : '#808080';
        let name = alerts && alerts[alert] ? alerts[alert].name : '';
        return <td key={key} colSpan={span} style={{ background: color }}><span style={{ color: 'white' }}>{name.toUpperCase()}</span></td>;
    }

    getModelLinks(models, modelDataId) {
        let links = [];
        if (!modelDataId) return links;
        if (isArrayValid(models)) {
            for (let index = 0; index < models.length; index++) {
                let detectModel = models[index].dataId;
                if (detectModel && detectModel === modelDataId)
                    links.push(models[index]);
            }
        }
        return links;

    }

    getGradeHelper(detectModel) {
        let rules = []
        if (isArrayValid(detectModel.rules)) {
            for (let index = 0; index < detectModel.rules.length; index++) {
                let rule = detectModel.rules[index];
                let content = { rule, location: index, primary: 'NA', associated: 'NA', positive: 'NA', negative: 'NA', clinical: 'NA' };

                function setContent(key, symptom) {
                    if (symptom) {
                        const { cond, min, max, count } = symptom;

                        if (min === max) {
                            if (cond === 'all')
                                content[key] = `${min} for ${cond}`;
                            else if ( cond === 'any') {
                                if ( count > 1) {
                                    content[key] = `${min} on ${cond} >= ${count}`;
                                } else {
                                    content[key] = `${min} on ${cond}`;
                                }
                            }
                        }
                        else {
                            if (cond === 'all')
                                content[key] = `${min}-${max} for ${cond}`;
                            else if ( cond === 'any') {
                                if ( count > 1 ) {
                                    content[key] = `${min}-${max} on ${cond} >= ${count}`;
                                } else {
                                    content[key] = `${min}-${max} on ${cond}`;
                                }
                            }
                        }
                    }
                }

                setContent('primary', rule.primarySymptoms);

                setContent('associated', rule.associatedSymptoms);
                setContent('positive', rule.positiveSymptoms);
                setContent('negative', rule.negativeSymptoms);
                setContent('clinical', rule.clinicalModifiers);

                rules.push(content);
            }
        }
        return rules;
    }

    getDetectRuleReport() {
        let { detectModel, model, key } = this.state;
        let { appContext } = this.props;

        const self = this;
        const { alerts } = this.state;
        let readOnly = this.props.readOnly || false;
        if (!detectModel) return [];
        if (!Array.isArray(detectModel.rules))
            detectModel.rules = [];

        // validate the rules
        for (let i = 0; i < detectModel.rules.length; ++i) {
            let rule = detectModel.rules[i];
            if (rule.conditionId === undefined)
                rule.conditionId = '';
            if (rule.primarySymptoms === undefined)
                rule.primarySymptoms = { min: 0, max: 4, cond: 'all', count: 1 };
            if (rule.associatedSymptoms === undefined)
                rule.associatedSymptoms = { min: 0, max: 4, cond: 'all', count: 1 };
            if (rule.positiveSymptoms === undefined)
                rule.positiveSymptoms = { min: 0, max: 4, cond: 'all', count: 1 };
            if (rule.negativeSymptoms === undefined)
                rule.negativeSymptoms = { min: 0, max: 4, cond: 'all', count: 1 };
            if (rule.clinicalModifiers === undefined)
                rule.clinicalModifiers = { min: 0, max: 4, cond: 'all', count: 1 };
        }

        // detectModel.rules= arraySort(detectModel.rules, ["grade","priority", "!alert"]);
        //detectModel.rules = multisort(detectModel.rules, ["grade", "priority", "alert"]);

        let gradeList = this.getGradeHelper(detectModel);
        let gradeColumns = [];
        let priorGrade = null;
        let gradeColSpan = 1;

        let alertColumns = [];
        let priorAlert = null;
        let alertColSpan = 1;

        let priorityColumns = [];
        let priorPriority = null;
        let priorityColSpan = 1;

        let primaryColumns = [];
        let associatedColumns = [];
        let clinicalColumns = [];
        let positiveColumns = [];
        let negativeColumns = [];

        let conditionColumns = [];
        let priorCondition = null;
        let conditionColSpan = 1;

        let editColumns = [];

        function getConditionColumn(index, colSpan, conditionId) {
            let condition = detectModel.conditions.find((e) => e.conditionId === conditionId);
            if (!condition)
                condition = { description: "NA" };
            return <td key={index} colSpan={colSpan} className='detect_rule_model_associated'>{condition.description}</td>;
        }

        for (let index = 0; index < gradeList.length; index++) {
            let element = gradeList[index];
            let grade = element.rule.grade;
            let conditionId = element.rule.conditionId;

            if (priorCondition === conditionId) {
                conditionColSpan += 1;
                conditionColumns[conditionColumns.length - 1] = getConditionColumn(index, conditionColSpan, conditionId);
            } else {
                priorCondition = conditionId;
                conditionColSpan = 1;
                conditionColumns.push(getConditionColumn(index, conditionColSpan, conditionId));
            }

            if (priorGrade === grade) {
                gradeColSpan += 1;
                gradeColumns[gradeColumns.length - 1] = <td key={index} colSpan={gradeColSpan} className='detect_rule_model_grade'> Grade {grade}</td>;
            } else {
                priorGrade = grade;
                gradeColSpan = 1;
                gradeColumns.push(<td key={index} colSpan={gradeColSpan} className='detect_rule_model_grade'> Grade {grade}</td>);
            }

            let alert = element.rule.alert;
            if (priorAlert === alert) {
                alertColSpan += 1;
                alertColumns[alertColumns.length - 1] = this.getAlertCol(alert, alertColSpan, index);
            } else {
                priorAlert = alert;
                alertColSpan = 1;
                alertColumns.push(this.getAlertCol(alert, alertColSpan, index));
            }


            let priority = element.rule.priority;
            if (priorPriority === priority) {
                priorityColSpan += 1;
                priorityColumns[priorityColumns.length - 1] = <td key={index} colSpan={priorityColSpan} className='detect_rule_model_priority'>  {priority}</td>;
            } else {
                priorPriority = priority;
                priorityColSpan = 1;
                priorityColumns.push(<td key={index} colSpan={priorityColSpan} className='detect_rule_model_priority'> {priority}</td>);
            }

            if (element.primary) {
                primaryColumns.push(<td key={index} className='detect_rule_model_primary'>{element.primary}</td>);
            }

            if (element.associated) {
                associatedColumns.push(<td key={index} className='detect_rule_model_associated'>{element.associated}</td>);
            }
            if (element.positive) {
                positiveColumns.push(<td key={index} className='detect_rule_model_associated'>{element.positive}</td>);
            }
            if (element.negative) {
                negativeColumns.push(<td key={index} className='detect_rule_model_associated'>{element.negative}</td>);
            }
            if (element.clinical) {
                clinicalColumns.push(<td key={index} className='detect_rule_model_clinical'> {element.clinical}</td>);
            }

            let editButton = <CreateIcon style={styles.icon}
                onClick={(e) => {
                    self.setState({
                        dialog:
                            <EditDetectRulesDialog
                                alerts={alerts}
                                detectModel={detectModel}
                                editType={'edit'}
                                rule={element.rule}
                                appContext={self.props.appContext}
                                onCancel={(rule) => {
                                    self.setState({ dialog: null })
                                }}
                                onDone={(rule) => {
                                    console.log("onDone:", rule);
                                    for (let k in rule) {
                                        element.rule[k] = rule[k];
                                    }
                                    if (self.props.onChange)
                                        self.props.onChange(detectModel);
                                    self.setState({ dialog: null })
                                }}

                            />
                    });
                }} />;

            let deleteButton = <DeleteIcon style={styles.icon}
                onClick={(e) => {
                    self.setState({
                        dialog:
                            <EditDetectRulesDialog
                                alerts={alerts}
                                detectModel={detectModel}
                                editType={'remove'}
                                rule={element.rule}
                                appContext={self.props.appContext}
                                onCancel={(rule) => {
                                    self.setState({ dialog: null })
                                }}
                                onDone={(rule) => {
                                    arrayRemoveLocation(detectModel.rules, element.location);
                                    if (self.props.onChange)
                                        self.props.onChange(detectModel);
                                    self.setState({ dialog: null })
                                }}

                            />
                    });
                }} />;

            editColumns.push(<td key={index}>{editButton} {deleteButton}</td>);
        }

        let links = this.getModelLinks(model, detectModel.modelDataId);


        let linkedNames = [];
        if (isArrayValid(links)) {
            for (let index = 0; index < links.length; index++) {
                linkedNames.push(links[index].name);
            }
        }
        const store = appContext.stores.DataTypesStore;
        let dataTypes = store.getDataTypes();
        let primarySymptoms = [];
        if (isArrayValid(links)) {
            for (let index = 0; index < links.length; index++) {
                let model = links[index];
                if (isArrayValid(model.primarySymptoms)) {
                    for (let index = 0; index < model.primarySymptoms.length; index++) {
                        let dataId = model.primarySymptoms[index].dataId;
                        let type = dataTypes.find((type) => type.dataId === dataId);
                        primarySymptoms.push(type ? type.name : dataId);
                    }
                }
            }
        }

        let editRow = null;
        if (!readOnly) {
            function onAddRule() {
                detectModel.rules.push({
                    grade: 0, alert: 'green', priority: 0,
                    primarySymptoms: { min: 0, max: 4, cond: 'all', count: 1 },
                    associatedSymptoms: { min: 0, max: 4, cond: 'all', count: 1 },
                    positiveSymptoms: { min: 0, max: 4, cond: 'all', count: 1 },
                    negativeSymptoms: { min: 0, max: 4, cond: 'all', count: 1 },
                    clinicalModifiers: { min: 0, max: 4, cond: 'all', count: 1 }
                });
                self.setState({ modified: true });
            }

            editColumns.push(<td key={editColumns.length}><IconButton onClick={onAddRule.bind()}><AddIcon /></IconButton></td>);
            editRow = <tr><td></td>{editColumns}</tr>;
        }

        let numRules = detectModel.rules.length;
        let linkedNamesText = isArrayValid(linkedNames) ? "Models: " + linkedNames.join(', ') : "Models: ";
        let primarySymptomsText = isArrayValid(primarySymptoms) ? " Primary Symptoms: " + primarySymptoms.join(', ') : ' Primary Symptoms:';

        let conditionModel = model.find((condition) => condition.dataId === detectModel.modelDataId);

        return <table key={key} align="left" className='detect_rule_model_table'>
            <tbody>

                {/* <tr><th className='detect_rule_model_seperator' colSpan={numRules + 1} ></th></tr> */}
                {/* {readOnly && <tr style={{ marginBottom: '1px' }}><td >{detectModel._id}</td></tr>} */}
                <tr>
                    <th className='detect_rule_model_header' colSpan={numRules + 1}>
                        {linkedNamesText}; {primarySymptomsText}
                        {!this.props.print && <Tooltip title="Show JSON"><IconButton onClick={this.onShowCode.bind(this, detectModel)}><CodeIcon /></IconButton></Tooltip>}
                    </th>
                </tr>
                <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_grade' >CTCAE Grade</td>
                    {gradeColumns}
                </tr>
                <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_alert' >Alert Color</td>
                    {alertColumns}
                </tr>
                <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_priority' >Evaluation  Priority</td>
                    {priorityColumns}
                </tr>
                {(!conditionModel || isArrayValid(conditionModel.primarySymptoms)) && <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_primary' >Primary Symptoms</td>
                    {primaryColumns}
                </tr>}
              <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_associated' >Associated Symptoms</td>
                    {associatedColumns}
                </tr>
              <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_associated' >Pertinent Positives</td>
                    {positiveColumns}
                </tr>
              <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_associated' >Pertinent Negatives</td>
                    {negativeColumns}
                </tr>
              <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_clinical' >Clincal Modifiers</td>
                    {clinicalColumns}
                </tr>
                {(!readOnly || isArrayValid(detectModel.conditions)) && <tr className='detect_rule_model_row'>
                    <td className='detect_rule_model_associated' >Condition</td>
                    {conditionColumns}
                </tr>}
                {editRow}
            </tbody>
        </table>;
    }

    onShowCode(model) {
        if (model) {
            this.setState({
                dialog: <JsonDialog
                    appContext={this.props.appContext}
                    dataType={model}
                    onDone={() => {
                        this.setState({dialog: null})
                    }}
                    onEditDone={(detectModel) => {
                        this.setState({detectModel, dialog: null});
                    }}
                />
            });
        }
    }


    onCloseDialog() {
        this.setState({ dialog: null });
    }
    render() {
        let { dialog } = this.state;

        let report = this.getDetectRuleReport();
        return (
            <div align="left" style={{ width: "100%" }} >
                {report}
                {dialog}
            </div>
        )
    };



}

const styles = {
    icon: {
        height: 20,
        width: 20
    }
};

export default DetectModel;