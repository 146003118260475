import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Dialog,
    IconButton,
    TextField,
    Button,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    CircularProgress,
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import SaveIcon from '@material-ui/icons/Save';

import NavigationClose from '@material-ui/icons/Close';
import EditModelTypeArrayView from '../components/EditModelTypeArrayView'
import EditDataTypeArray from '../components/EditDataTypeArray'
import EditFlagArrayView from '../components/EditFlagArrayView'
import EditConditionArray from "../components/EditConditionArray";
import AddDataArrayView from '../components/AddDataArrayView';

import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";
import { deleteRule, saveRule } from '@apricityhealth/web-common-lib/utils/Services';

import OverrideDialog from "../dialogs/OverrideDialog";
import JsonDialog from '../dialogs/JsonDialog';
import createIdFromText from '../utils/CreateIdFromText';
import ChangedByButton from '../components/ChangedByButton';

class RuleView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rule: null
        };
    }
    componentDidMount() {
        if (this.props.rule)
            this.loadRule(this.props.rule);
    }
    componentDidUpdate(oldProps) {
        if (this.props.rule !== oldProps.rule)
            this.loadRule(this.props.rule);
    }

    closeView() {
        console.log("closeView()", this);
        this.props.onClose();
    }

    loadRule(rule) {
        console.log("loadRule:", rule);
        this.setState({
            rule,
            progress: null,
            dialog: null
        });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    saveRule(planId = null) {
        const self = this;
        const { rule } = self.state;
        let { appContext } = this.props;

        if (!planId && rule.planId && rule.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(rule.planId, this.saveRule.bind(this));
        }
        this.setState({ progress: <CircularProgress /> });

        saveRule(appContext, rule, planId).then((response) => {
            console.log("saveRule response:", response);
            self.setState({ override: false, rule: response, progress: null });
        }).catch((error) => {
            self.setState({ progress: error.message });
        });
    }

    deleteRule() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete rule: {`${self.state.rule.ruleId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.cancelDelete.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={this.confirmDelete.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    showCode() {
        let { appContext } = this.props;
        let { rule } = this.state;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={rule}
                onEditDone={(rule) => {
                    this.setState({ rule, dialog: null });
                }}
                onDone={() => {
                    this.setState({ dialog: null });
                }}
            />
        });
    }

    cancelDelete() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const self = this;
        const rule = this.state.rule;
        let { appContext } = this.props;

        this.setState({ dialog: null, progress: <CircularProgress /> });
        deleteRule(appContext, rule).then(() => {
            self.setState({ progress: null });
            self.closeView();
        }).catch((error) => {
            console.log("deleteRule error:", error.response);
            self.setState({ progress: error.message });
        });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        let { rule, progress, dialog } = this.state;

        if (!rule) {
            return null;
        }

        let editRule = [];
        editRule.push(<AddDataArrayView key={editRule.length} appContext={appContext} array={rule.addData} name={'Add Data'} />);
        if (rule.ruleType === 'transform') {
            editRule.push(<EditModelTypeArrayView key={editRule.length} appContext={appContext} array={rule.addModels} name={'Add Models'} />);
            editRule.push(<EditDataTypeArray
                key={editRule.length}
                appContext={appContext}
                array={rule.addRequiredData}
                name={'Add Required Data'}
                onChange={(addRequiredData) => {
                    rule.addRequiredData = addRequiredData
                    self.setState({ rule });
                }}
            />);
            editRule.push(<EditDataTypeArray
                key={editRule.length}
                appContext={appContext}
                array={rule.addOptionalData}
                name={'Add Optional Data'}
                onChange={(addOptionalData) => {
                    rule.addOptionalData = addOptionalData
                    self.setState({ rule });
                }}
            />);
            editRule.push(<EditConditionArray key={editRule.length} appContext={appContext} array={rule.addConditions} label={'Add Conditions'} />);
            editRule.push(<EditConditionArray key={editRule.length} appContext={appContext} array={rule.removeConditions} label={'Remove Conditions'} />);
        }
        editRule.push(<EditFlagArrayView key={editRule.length} appContext={appContext} array={rule.addFlags} disableNot={true} label={'Add Flags'} />);
        editRule.push(<EditFlagArrayView key={editRule.length} appContext={appContext} array={rule.removeFlags} disableNot={true} label={'Remove Flags'} />);

        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Rule</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField disabled={true} style={styles.name} label="Rule ID" value={rule.ruleId}
                                        onChange={(e) => { rule.ruleId = e.target.value; self.setState({ rule }); }} />
                                    <br />
                                    <TextField style={styles.name} label="Name" value={rule.name}
                                        onChange={(e) => {
                                            rule.name = e.target.value;
                                            rule.ruleId = createIdFromText(rule.name);
                                            self.setState({ rule });
                                        }} />
                                    <br />
                                    <TextField type='number' style={styles.name} label="Priority" value={rule.priority}
                                        onChange={(e) => { rule.priority = e.target.value; self.setState({ rule }); }} />
                                    <br />
                                    <EditConditions
                                        appContext={appContext}
                                        conditions={rule.condition}
                                        onChange={(condition) => {
                                            rule.condition = condition;
                                            self.setState({ rule });
                                        }}
                                        name={'Condition'}
                                    />
                                    <br />
                                    <FormControl style={styles.select} >
                                        <InputLabel>Rule Type</InputLabel>
                                        <Select value={rule.ruleType} onChange={(e) => { rule.ruleType = e.target.value; self.setState({ rule }) }}>
                                            <MenuItem value="transform">Transform</MenuItem>
                                            <MenuItem value="detect">Detect</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <div style={{ margin: 5 }}>
                                        {editRule}
                                    </div>
                                    <br />
                                    <ChangedByButton appContext={this.props.appContext} primaryKey={rule.ruleId} collection='RuleModel' />
                                </td>
                                <td valign="top" align="right">
                                    {progress}
                                    <IconButton onClick={this.saveRule.bind(this, null)}><SaveIcon /></IconButton>
                                    <IconButton onClick={this.showCode.bind(this)}><CodeIcon /></IconButton>
                                    <IconButton disabled={!rule.ruleId} onClick={this.deleteRule.bind(this)}><DeleteIcon /></IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        width: 800
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10,
        width: 780
    },
    name: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    },
    select: {
        margin: 5,
        width: 300
    },
    tags: {
        margin: 5
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default RuleView;
