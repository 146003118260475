import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles, Avatar } from '@material-ui/core/';
import {
    Chip,
    FormLabel,
    Tooltip,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core/';
import FlagIcon from '@material-ui/icons/Flag';
import AddIcon from '@material-ui/icons/Add';

import {
    arrayRemoveLocation,
    arrayInsertLocation,
    isArrayValid,
} from '../utils/Utils';

import SelectFlagType from '@apricityhealth/web-common-lib/components/SelectFlagType';

class EditFlagArrayView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            array: props.array,
            menu: null
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.array) !== JSON.stringify(this.props.array)){
            let array = this.props.array ? this.props.array : [];
            this.setState({ array });
        }
    }

    onChange() {
        if ( this.props.onChange ) {
            this.props.onChange(this.state.array);
        }
    }

    onAdd = index => e => {
        let { array } = this.state;
        //just add a default one
        arrayInsertLocation(array, 0, "");
        this.setState({ array }, this.onChange.bind(this) );
    }

    onUpdateFlag(index, type, isNot) {
        let { array } = this.state;
        if ( type )
            array[index] = isNot ? "!" + type.flagId : type.flagId;
        else
            array[index] = isNot ? "!" : "";
        this.setState({ array, menu: null }, this.onChange.bind(this));
    }

    onChangeData = index => e => {
        let { array } = this.state;
        const { appContext } = this.props;
        let flagId = array[index];
        if (! flagId )
            flagId = '';
        let isNot = flagId[0] === '!';
        if ( isNot )
            flagId = flagId.slice(1);
        let menu = <Menu open={true} onClose={this.closeMenu.bind(this)} anchorEl={e.target}>
            <MenuItem style={{height: 50}}>
                <SelectFlagType
                    appContext={appContext}
                    flagId={flagId}
                    isNot={isNot}
                    disableNot={this.props.disableNot}
                    onSelect={this.onUpdateFlag.bind(this, index)} />
            </MenuItem>

        </Menu>;

        this.setState({ menu });
    }

    closeMenu() {
        this.setState({ menu: null });
    }

    
    onDelete = index => e => {
        let { array } = this.state;
        arrayRemoveLocation(array, index);
        this.setState({ array }, this.onChange.bind(this) );
    }

    render() {
        let { array, menu } = this.state;
        let self = this;

        let dataChips = [];
        if (isArrayValid(array)) {
            for (let index = 0; index < array.length; index++) {
                let data = array[index];
                dataChips.push(
                    <Chip
                        key={index}
                        avatar={<Avatar><FlagIcon /></Avatar>}
                        label={data}
                        onClick={self.onChangeData(index)}
                        onDelete={self.onDelete(index)}
                    />);

            }
        }

        let label = <FormLabel>{this.props.label || "Edit Flags"}</FormLabel>;
        if ( this.props.tip ) 
            label = <Tooltip title={this.props.tip}>{label}</Tooltip>;

        return (<div >
            <div style={{ marginTop: '5px' }} />
            {label}
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={self.onAdd()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div >
        )
    }
}

const styles = {
    div: {
        margin: 10
    }
};

EditFlagArrayView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(EditFlagArrayView);
