
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import QuestionsIcon from '@material-ui/icons/Chat';
import ModelsIcon from '@material-ui/icons/DeviceHub';
import DetectIcon from '@material-ui/icons/QueryBuilder';
import RecommendIcon from '@material-ui/icons/ThumbUp';
import MedicationsIcon from '@material-ui/icons/LocalPharmacy';
import ConditionsIcon from '@material-ui/icons/Healing';
import ReportIcon from '@material-ui/icons/BarChart';
import ContentIcon from '@material-ui/icons/FileCopy';
import SymptomsIcon from '@material-ui/icons/DataUsage';
import RecommendModelsIcon from '@material-ui/icons/DeviceHub';
import EducationIcon from '@material-ui/icons/RateReview';
import ObservationsIcon from '@material-ui/icons/RemoveRedEye';
import Hospital from '@material-ui/icons/LocalHospital';

import ApricityLogo from './apricityTextLogo.png';

import {
    withStyles,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Drawer,
    List,
    MuiThemeProvider,
    ListSubheader,
    Badge
} from '@material-ui/core/';

import PubSub from 'pubsub-js'

import T from 'i18n-react';
import './App.css';

class MenuView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: null,
            models: [],
            plan: null,
            errors: {}
        }

    }

    componentDidMount() {
        PubSub.subscribe('ERRORS_TOPIC', (errors) => {
            this.setState({ errors });
        })
    }
    componentDidUpdate(prevProps) {
        let self = this;
        let { plan } = this.state;
        let appContext = this.props.appContext;
        if (appContext && appContext.state && appContext.state.plan) {
            let newPlanId = appContext.state.plan ? appContext.state.plan.planId : null;
            if (!plan || plan.planId !== newPlanId) {
                self.setState({ plan: appContext.state.plan });
            }
        }
    }


    isSelected(path) {
        let pathname = window.location.pathname.slice(1).split('/');
        pathname = pathname[0]
        return (path === pathname)
    }

    createGeneralMenus() {
        let self = this
        const { open } = this.props;
        let menuItems = [];
        if (open) {
            let subList = <ListSubheader key={'generalcontent'} disableSticky>General Content</ListSubheader>
            menuItems.push(subList);

            let contentMenu =
                <MenuItem key={'cm'} component={Link} to="/manage_content" selected={self.isSelected('manage_content')}>
                    <ListItemIcon ><ContentIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="manage_content" />} />
                </MenuItem>
            menuItems.push(contentMenu);

        }

        return menuItems;
    }


    createAdvancedTypesMenus() {
        let self = this
        let menuItems = [];

        let actionsMenu =
            <MenuItem key={'actions'} component={Link} to="/actions" selected={self.isSelected('actions')}>
                <ListItemText primary={<T.span text="actions" />} />
            </MenuItem>
        menuItems.push(actionsMenu);

        let alertLevelsMenu =
            <MenuItem key={'alert_levels'} component={Link} to="/alert_levels" selected={self.isSelected('alert_levels')}>
                <ListItemText primary={<T.span text="alert_levels" />} />
            </MenuItem>
        menuItems.push(alertLevelsMenu);

        let alertTypesMenu =
            <MenuItem key={'alert_types'} component={Link} to="/alert_types" selected={self.isSelected('alert_types')}>
                <ListItemText primary={<T.span text="alert_types" />} />
            </MenuItem>
        menuItems.push(alertTypesMenu);

        let broadcastGroupsMenu =
            <MenuItem key={'broadcast_groups'} component={Link} to="/broadcast_groups" selected={self.isSelected('broadcast_groups')}>
                <ListItemText primary={<T.span text="broadcast_groups" />} />
            </MenuItem>
        menuItems.push(broadcastGroupsMenu);

        let configsMenu =
            <MenuItem key={'configs'} component={Link} to="/configs" selected={self.isSelected('configs')}>
                <ListItemText primary={<T.span text="configs" />} />
            </MenuItem>
        menuItems.push(configsMenu);

        let flagTypesMenu =
            <MenuItem key={'flags'} component={Link} to="/flags" selected={self.isSelected('flags')}>
                <ListItemText primary={<T.span text="flags" />} />
            </MenuItem>
        menuItems.push(flagTypesMenu);

        let graphTypesMenu =
            <MenuItem key={'gc'} component={Link} to="/graphs" selected={self.isSelected('graphs')}>
                <ListItemText primary={<T.span text="graphs" />} />
            </MenuItem>
        menuItems.push(graphTypesMenu);

        menuItems.push(<MenuItem key={'plans'} component={Link} to="/plans" selected={self.isSelected('plans')}>
            <ListItemText primary={<T.span text="plans" />} />
        </MenuItem >);

        menuItems.push(<MenuItem key={'programs'} component={Link} to="/programs" selected={self.isSelected('programs')}>
            <ListItemText primary={<T.span text="programs" />} />
        </MenuItem >);


        let rulesMenu =
            <MenuItem key={'rules'} component={Link} to="/rules" selected={self.isSelected('rules')}>
                <ListItemText primary={<T.span text="rules" />} />
            </MenuItem >
        menuItems.push(rulesMenu);

        let triageLevelsMenu =
            <MenuItem key={'triage_levels'} component={Link} to="/triage_levels" selected={self.isSelected('triage_levels')}>
                <ListItemText primary={<T.span text="triage_levels" />} />
            </MenuItem>
        menuItems.push(triageLevelsMenu);

        menuItems.push(<MenuItem key={'trials'} component={Link} to="/trials" selected={self.isSelected('trials')}>
            <ListItemText primary={<T.span text="trials" />} />
        </MenuItem>)

        let textContentMenu =
            <MenuItem key={'tc'} component={Link} to="/texts" selected={self.isSelected('="/')}>
                <ListItemText primary={<T.span text="texts" />} />
            </MenuItem>
        menuItems.push(textContentMenu)
        let followupTypesMenu =
            <MenuItem key={'fp'} component={Link} to="/followup" selected={self.isSelected('followup')}>
                <ListItemText primary={<T.span text="followup" />} />
            </MenuItem>
        menuItems.push(followupTypesMenu);
        menuItems.sort((a, b) => (a.key > b.key) ? 1 : -1)

        menuItems.push(<Divider key={menuItems.length} />);
        menuItems.push(<ListSubheader key='recommendations'>Recommendation</ListSubheader>)
        menuItems.push(<MenuItem key={'recommend_categories'} component={Link} to="/recommend_categories" selected={self.isSelected('recommend_categories')}>
            <ListItemText primary={<T.span text="recommend_categories" />} />
        </MenuItem>)

        menuItems.push(<MenuItem key={'recommend_group_types'} component={Link} to="/recommend_group_types" selected={self.isSelected('recommend_group_types')}>
            <ListItemText primary={<T.span text="recommend_group_types" />} />
        </MenuItem>)

        menuItems.push(<MenuItem key={'recommend_protocols'} component={Link} to="/recommend_protocols" selected={self.isSelected('recommend_protocols')}>
            <ListItemText primary={<T.span text="recommend_protocols" />} />
        </MenuItem>)


        let subList = <ListSubheader key='ListSubHeader' disableSticky>Types</ListSubheader>
        menuItems.unshift(subList)

        return menuItems;
    }

    createReportMenus() {
        let self = this
        const { open } = this.props;
        let menuItems = [];

        if (open) {
            let reportsSubList = <ListSubheader key={'reports'} disableSticky>Reports</ListSubheader>
            menuItems.push(reportsSubList);

            menuItems.push(<MenuItem key={'mr'} component={Link} to="/models_report" selected={self.isSelected('models_report')}>
                <ListItemIcon ><ReportIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="models_report" />} />
            </MenuItem>);

            let detectRulesMenu =
                <MenuItem key={'drm'} component={Link} to="/detect_rule_models" selected={self.isSelected('detect_rule_models')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="detect_rule_models" />} />
                </MenuItem>
            menuItems.push(detectRulesMenu);


            let validationReportMenu =
                <MenuItem key={'vr'} component={Link} to="/validation_report" selected={self.isSelected('validation_report')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="validation_report" />} />
                </MenuItem>
            menuItems.push(validationReportMenu);

            let recommendationReportMenu =
                <MenuItem key={'rr'} component={Link} to="/recommendations_report" selected={self.isSelected('recommendations_report')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="recommendations_report" />} />
                </MenuItem>
            menuItems.push(recommendationReportMenu);


            let symptomsReportMenu =
                <MenuItem key={'sr'} component={Link} to="/symptoms_report" selected={self.isSelected('symptoms_report')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="symptoms_report" />} />
                </MenuItem>
            menuItems.push(symptomsReportMenu);


            let questionsReportMenu =
                <MenuItem key={'qr'} component={Link} to="/questions_report" selected={self.isSelected('questions_report')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="questions_report" />} />
                </MenuItem>
            menuItems.push(questionsReportMenu);

            let plansReportMenu =
                <MenuItem key={'planH'} component={Link} to="/plan_heirarchy_report" selected={self.isSelected('plan_heirarchy_report')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="plan_heirarchy_report" />} />
                </MenuItem>
            menuItems.push(plansReportMenu);


            let changesReportMenu =
                <MenuItem key={'cr'} component={Link} to="/changes_report" selected={self.isSelected('changes_report')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="changes_report" />} />
                </MenuItem>
            menuItems.push(changesReportMenu);
            //dialog report

            let dialogReportMenu =
                <MenuItem key={'dr'} component={Link} to="/dialog_report" selected={self.isSelected('dialog_report')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="dialog_report" />} />
                </MenuItem>

            menuItems.push(dialogReportMenu);

            let contentReviewMenu =
                <MenuItem key={'contentreview'} component={Link} to="/content_review" selected={self.isSelected('content_review')}>
                    <ListItemIcon ><ReportIcon /></ListItemIcon>
                    <ListItemText primary={<T.span text="content_review" />} />
                </MenuItem>
            menuItems.push(contentReviewMenu);
        }
        return menuItems;
    }

    createProgramMenus() {
        let self = this;
        let { plan } = this.state;
        let { open } = this.props;

        let store = this.props.appContext.stores.DataTypesStore;

        let menuItems = [];
        if (open && plan) {
            let isProtected = plan.protected ? '(protected)' : '';
            let name = `Program: ${plan.title} ${isProtected}`;
            menuItems.push(<ListSubheader key={'iraecontent'} disableSticky>{name}</ListSubheader>);

            let question_errs = store.getErrors('questions');
            menuItems.push(<MenuItem key={'questions'} component={Link} to="/questions" selected={self.isSelected('questions')}>
                <ListItemIcon >
                    <Badge badgeContent={question_errs.count} invisible={question_errs.count === 0} color='error'>
                        <QuestionsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="questions" />} />
            </MenuItem>)

            menuItems.push(<MenuItem key={'types'} component={Link} to="/types" selected={self.isSelected('types')}>
                <ListItemIcon ><SymptomsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="types" />} />
            </MenuItem>)

            let data_model_errs = store.getErrors('data_models');
            menuItems.push(<MenuItem key={'data_models'} component={Link} to="/data_models" selected={self.isSelected('data_models')}>
                <ListItemIcon >
                    <Badge badgeContent={data_model_errs.count} invisible={data_model_errs.count === 0} color='error'>
                        <ModelsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="data_models" />} />
            </MenuItem>)

            // let predictor_model_errs = store.getErrors('predictor_models');
            // menuItems.push(<MenuItem key={'predictor_models'} component={Link} to="/predictor_models" selected={self.isSelected('predictor_models')}>
            //     <ListItemIcon >
            //         <Badge badgeContent={predictor_model_errs.count} invisible={predictor_model_errs.count === 0} color='error'>
            //             <ModelsIcon />
            //         </Badge>
            //     </ListItemIcon>
            //     <ListItemText primary={<T.span text="predictor_models" />} />
            // </MenuItem>)

            let symptom_models_errs = store.getErrors('symptom_models');
            menuItems.push(<MenuItem key={'symptom_models'} component={Link} to="/symptom_models" selected={self.isSelected('symptom_models')}>
                <ListItemIcon >
                    <Badge badgeContent={symptom_models_errs.count} invisible={symptom_models_errs.count === 0} color='error'>
                        <ModelsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="symptom_models" />} />
            </MenuItem>)

            let symptom_detect_errs = store.getErrors('symptom_detect');
            menuItems.push(<MenuItem key={'symptom_detect'} component={Link} to="/symptom_detect" selected={self.isSelected('symptom_detect')} style={{ marginLeft: 20 }}>
                <ListItemIcon >
                    <Badge badgeContent={symptom_detect_errs.count} invisible={symptom_detect_errs.count === 0} color='error'>
                        <DetectIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="symptom_detect" />} />
            </MenuItem>)

            let pro_models_errs = store.getErrors('pro_models');
            menuItems.push(<MenuItem key={'pro_models'} component={Link} to="/pro_models" selected={self.isSelected('pro_models')}>
                <ListItemIcon >
                    <Badge badgeContent={pro_models_errs.count} invisible={pro_models_errs.count === 0} color='error'>
                        <ModelsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="pro_models" />} />
            </MenuItem>)

            let pro_detect_errs = store.getErrors('pro_detect');
            menuItems.push(<MenuItem key={'pro_detect'} component={Link} to="/pro_detect" selected={self.isSelected('pro_detect')} style={{ marginLeft: 20 }}>
                <ListItemIcon >
                    <Badge badgeContent={pro_detect_errs.count} invisible={pro_detect_errs.count === 0} color='error'>
                        <DetectIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="pro_detect" />} />
            </MenuItem>)

            let lab_models_errs = store.getErrors('lab_models');
            menuItems.push(<MenuItem key={'lab_models'} component={Link} to="/lab_models" selected={self.isSelected('lab_models')}>
                <ListItemIcon>
                    <Badge badgeContent={lab_models_errs.count} invisible={lab_models_errs.count === 0} color='error'>
                        <ModelsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="lab_models" />} />
            </MenuItem>)

            let lab_detect_errs = store.getErrors('lab_detect');
            menuItems.push(<MenuItem key={'lab_detect'} component={Link} to="/lab_detect" selected={self.isSelected('lab_detect')} style={{ marginLeft: 20 }}>
                <ListItemIcon >
                    <Badge badgeContent={lab_detect_errs.count} invisible={lab_detect_errs.count === 0} color='error'>
                        <DetectIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="lab_detect" />} />
            </MenuItem>)

            let triage_models_errs = store.getErrors('triage_models');
            menuItems.push(<MenuItem key={'triage_models'} component={Link} to="/triage_models" selected={self.isSelected('triage_models')}>
                <ListItemIcon>
                    <Badge badgeContent={triage_models_errs.count} invisible={triage_models_errs.count === 0} color='error'>
                        <ModelsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="triage_models" />} />
            </MenuItem>)

            let triage_detect_errs = store.getErrors('triage_detect');
            menuItems.push(<MenuItem key={'triage_detect'} component={Link} to="/triage_detect" selected={self.isSelected('triage_detect')} style={{ marginLeft: 20 }}>
                <ListItemIcon >
                    <Badge badgeContent={triage_detect_errs.count} invisible={triage_detect_errs.count === 0} color='error'>
                        <DetectIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="triage_detect" />} />
            </MenuItem>)

            let survey_models_errs = store.getErrors('survey_models');
            menuItems.push(<MenuItem key={'survey_models'} component={Link} to="/survey_models" selected={self.isSelected('survey_models')}>
                <ListItemIcon >
                    <Badge badgeContent={survey_models_errs.count} invisible={survey_models_errs.count === 0} color='error'>
                        <ModelsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="survey_models" />} />
            </MenuItem>)

            // let survey_detect_errs = store.getErrors('survey_detect');
            // menuItems.push(<MenuItem key={'survey_detect'} component={Link} to="/survey_detect" selected={self.isSelected('survey_detect')} style={{ marginLeft: 20 }}>
            //     <ListItemIcon >
            //         <Badge badgeContent={survey_detect_errs.count} invisible={survey_detect_errs.count === 0} color='error'>
            //             <DetectIcon />
            //         </Badge>
            //     </ListItemIcon>
            //     <ListItemText primary={<T.span text="survey_detect" />} />
            // </MenuItem>)

            menuItems.push(<MenuItem key={'symptom_models_unified'} component={Link} to="/symptom_models_unified" selected={self.isSelected('symptom_models_unified')}>
                <ListItemIcon>
                    <Badge badgeContent={symptom_models_errs.count} invisible={symptom_models_errs.count === 0} color='error'>
                        <Hospital />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="symptom_models_unified" />} />
            </MenuItem>)

            let recommend_model_errs = store.getErrors('recommend_models');
            menuItems.push(<MenuItem key={'recommend_models'} component={Link} to="/recommend_models" selected={self.isSelected('recommend_models')}>
                <ListItemIcon >
                    <Badge badgeContent={recommend_model_errs.count} invisible={recommend_model_errs.count === 0} color='error'>
                        <RecommendModelsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="recommend_models" />} />
            </MenuItem>)

            menuItems.push(<MenuItem key={'reccomendations'} component={Link} to="/recommendations" selected={self.isSelected('recommendations')}>
                <ListItemIcon ><RecommendIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="recommendations" />} />
            </MenuItem>)

            menuItems.push(<MenuItem key={'condition'} component={Link} to="/condition" selected={self.isSelected('condition')}>
                <ListItemIcon ><ConditionsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="condition" />} />
            </MenuItem>)

            menuItems.push(<MenuItem key={'procedure'} component={Link} to="/procedures" selected={self.isSelected('procedures')}>
                <ListItemIcon ><ConditionsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="procedures" />} />
            </MenuItem>)

            menuItems.push(<MenuItem key={'medications'} component={Link} to="/medications" selected={self.isSelected('medications')}>
                <ListItemIcon ><MedicationsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="medications" />} />
            </MenuItem>)

            let obErrors = store.getErrors('observation');
            menuItems.push(
                <MenuItem key={'observations'} component={Link} to="/observations" selected={self.isSelected('observations')}>
                    <ListItemIcon >
                        <Badge badgeContent={obErrors.count} invisible={obErrors.count === 0} color='error'>
                            <ObservationsIcon />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={<T.span text="observations" />} />
                </MenuItem>)

            menuItems.push(<MenuItem key={'education'} component={Link} to="/education" selected={self.isSelected('education')}>
                <ListItemIcon ><EducationIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="education" />} />
            </MenuItem>)
        }

        return menuItems;
    }

    render() {
        const self = this;
        const { open, classes, language, theme } = this.props;

        let programMenuItems = self.createProgramMenus();
        let reportMenuItems = self.createReportMenus();
        let generalMenuItems = self.createGeneralMenus()
        let advancedTypesMenus = self.createAdvancedTypesMenus();

        T.setTexts(require(`./locale/${language}.json`));
        return (
            <MuiThemeProvider theme={theme}>
                <div className={classes.root}>

                    <Drawer
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                        variant="persistent"
                        open={open}

                    >
                        <List component="nav" className={classes.menuList}>
                            <div className={classes.sidebarHeader}>
                                <img className={classes.logo} src={ApricityLogo} alt="apricity logo" />
                            </div>
                            <Divider />
                            {generalMenuItems}
                            <Divider />
                            {programMenuItems}
                            <Divider />
                            {reportMenuItems}
                            <Divider />
                            {advancedTypesMenus}
                        </List>
                    </Drawer>
                    {self.state.dialog}

                </div>
            </MuiThemeProvider>
        );
    }
}

const drawerWidth = 300;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    logo: {
        height: '90px',
        marginBottom: '10px',
    },
    sidebarHeader: {
        display: 'flex',
        padding: '10px 10px 0 10px',
        justifyContent: 'center'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: '150ms',
            duration: '150ms',
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: '150ms',
            duration: '150ms',
        }),
        overflowX: 'hidden',
        width: theme.spacing(1) * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(1) * 9 + 1,
        },
    },
});

MenuView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuView);

