import React, { Component } from "react";

import {
    CircularProgress,
    TextField,
    IconButton,
    Drawer,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import {
    loadActions
} from '@apricityhealth/web-common-lib/utils/Services';
import {
    isArrayValid
} from '../utils/Utils';

import PubSub from 'pubsub-js'
import RefreshIcon from '@material-ui/icons/Refresh';
import ActionView from './ActionView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';

function filterAction(filter, action) {
    if (action.actionId && action.actionId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (action.conditions && action.conditions.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (isArrayValid(action.tags)) {
        for (var i = 0; i < action.tags.length; ++i) {
            if (action.tags[i].toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                return true;
        }
    }

    return false;
}

class ActionsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: "",
            actions: [],
            filtered: null,
            dialog: null,
            plan: null
        };

    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('PLAN_TOPIC', this.loadContent.bind(this));
    }
    
    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        this.setState({progress: <CircularProgress size={20} />, error: null});
        loadActions(this.props.appContext, { dependencies: this.state.dependencies} ).then((actions) => {
            self.setState({ actions, progress: null }, self.updateFilter.bind(self) );
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    updateFilter() {
        let { filter, actions } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < actions.length; ++i) {
                if (filterAction(filter, actions[i]))
                    filtered.push(actions[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    selectAction(s, t) {
        console.log("selectAction:", s, t);
        if (s.length > 0) {
            const action = this.state.filtered ? this.state.filtered[s[0]] : this.state.actions[s[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <ActionView appContext={this.props.appContext} actionId={action.actionId} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ dialog: dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    onCloseDialog() {
        this.setState({dialog: null});
        if (this._types)
            this._types.setState({ selected: [] }, this.loadContent.bind(this));
    }

    render() {
        const self = this;
        let { error, progress, dialog, actions, filtered, filter, dependencies } = self.state;

        const columnData = [
            { id: 'actionId', numeric: false, disabledPadding: false, label: 'Action ID', formatValue: (v) => v.substring(0,50) },
            { id: 'conditions', numeric: false, disabledPadding: false, label: 'Conditions', formatValue: (v) => v.substring(0,50) },
            { id: 'tasks', numeric: false, disabledPadding: false, label: 'Tasks', formatValue: (v) => { return v.length; } },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={styles.text} value={filter} label="Filter"
                            onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            disableActions={true}
                            onTable={table => self._types = table}
                            onSelected={this.selectAction.bind(this)}
                            orderBy='actionId'
                            columnData={columnData}
                            data={filtered ? filtered : actions}
                            title='Actions' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}



const styles = {
    div: {
        margin: 10
    },
    text: {
        margin: 5,
        width: 300
    },
    progress: {
        color: 'red'
    }
};

export default ActionsView;
