import React, { Component } from "react";
import PropTypes from 'prop-types';

import {
    withStyles,
    FormControl,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    InputLabel,
    Select,
    MenuItem,
    Checkbox
} from '@material-ui/core/';

import {
    isArrayValid,
} from '../utils/Utils';

const { Parser } = require('json2csv');
const FileDownload = require('js-file-download');

const AWS_LANG_CODES = {
    'Arabic': 'ar',
    'Chinese (Simplified)': 'zh',
    'Chinese (Traditional)': 'zh-tw',
    'Czech ': 'cs',
    'Danish ': 'da',
    'Dutch ': 'nl',
    'Finnish ': 'fi',
    'French ': 'fr',
    'German ': 'de',
    'Hebrew ': 'he',
    'Indonesian ': 'id',
    'Italian': 'it',
    'Japanese ': 'ja',
    'Korean ': 'ko',
    'Polish': 'pl',
    'Portuguese ': 'pt',
    'Russian ': 'ru',
    'Spanish ': 'es',
    'Swedish': 'sv',
    'Turkish ': 'tr'
}

class ContentExportDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            store: props.appContext.stores.DataTypesStore,
            content: props.content || [],
            dialog: null,
            categories: [],
            targetLangauges: [],
            versionChanged: true
        };
    }

    onExport() {
        const { store, content, targetLangauges, categories, versionChanged } = this.state;

        console.log(`exporting`, targetLangauges, categories);

        let report = [];
        let filtered = content.filter((e) => {
            if ( e.language !== 'en-us' ) {
                return false;
            }
            if ( categories.length > 0 && !categories.includes(e.category)) {
                return false;
            }
            return true;
        }).sort((a, b) => {
            if (a.textId < b.textId)
                return -1;
            else if (a.textId > b.textId)
                return 1;
            return 0;
        });

        //add the conent to the export .. include english with the language.
        for (let i = 0; i < filtered.length; i++) {
            const text = filtered[i];
            const { textId } = text;

            //now loop through the languages
            for (let j = 0; j < targetLangauges.length; j++) {
                const targetLanguage = targetLangauges[j];
                const targetText = (store.getTexts().find((e) => e.textId === textId && e.language === targetLanguage) || { text: '', version: 1 });

                if ( targetText.version === undefined || targetText.version === null ) {
                    targetText.version = 1;
                }
                if ( text.version === undefined || text.version === null ) {
                    text.version = targetText.version;
                }
                if (versionChanged && text.version === targetText.version) {
                    continue;
                }
                report.push({ 
                    item: report.length, 
                    textId, 
                    category: text.category, 
                    en_version: text.version, 
                    english: text.text, 
                    language: targetLanguage, 
                    lang_version: targetText.version, 
                    text: targetText.text }
                );
            }
        }

        if (isArrayValid(report)) {
            const parser = new Parser({ withBOM: true });
            const csv = parser.parse(report);
            FileDownload(csv, 'ApricityContent.csv');
            this.setState({ error: null });
        } else {
            this.setState({ error: 'No content found to export!' });
        }
    }

    cancel() {
        this.setState({ dialog: null });

    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        const { onDone } = this.props;
        const { content } = this.state;
        if (onDone)
            onDone(content);
    }

    render() {
        const { dialog, content, targetLangauges, categories, error, versionChanged } = this.state;
        const disableTranslate = targetLangauges.length < 1 && isArrayValid(content);

        return (
            <Dialog
                maxWidth={'sm'}
                fullWidth
                open={true}
            >
                <DialogTitle>Translation Export Dialog</DialogTitle>
                <DialogContent>
                    <FormControl style={{margin: 5, width: 500}}>
                        <InputLabel>Select Categories</InputLabel>
                        <Select multiple={true} value={categories} onChange={(e) => {
                            this.setState({categories: e.target.value})
                        }}>
                            {content.reduce((p,c) => {
                                if ( p.indexOf(c.category) < 0 ) {
                                    p.push(c.category);
                                }
                                return p;
                            }, []).sort().map((category) => <MenuItem key={category} value={category}>{category}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl style={{margin: 5, width: 500}}>
                        <InputLabel>Select Languages</InputLabel>
                        <Select multiple={true} value={targetLangauges} onChange={(e) => {
                            this.setState({targetLangauges: e.target.value})
                        }}>
                            {Object.keys(AWS_LANG_CODES).sort().map((v) => <MenuItem key={AWS_LANG_CODES[v]} value={AWS_LANG_CODES[v]}>{v}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControlLabel label='Version Changed' 
                        control={<Checkbox checked={versionChanged} onChange={(e) => this.setState({versionChanged: e.target.checked})} />} />
                    {dialog}
                </DialogContent>
                <DialogActions>
                    <span style={{color: 'red'}}>{error}</span>
                    <Button variant="contained" self={this} disabled={disableTranslate} style={styles.button} onClick={(e) => { this.onExport() }}>Export</Button>
                    <Button variant="contained" self={this} style={styles.button} onClick={(e) => { this.onCancel() }}>Cancel</Button>
                </DialogActions>

            </Dialog>
        )
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    },
    table: {
        valign: 'top',
    },
    label: {
        margin: 10,
        marginLeft: 25
    },
    linearColorPrimary: {
        backgroundColor: '#b2dfdb',
    },
    linearBarColorPrimary: {
        backgroundColor: '#00695c',
    },
};

ContentExportDialog.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(ContentExportDialog);
