

import React, { useState, useEffect } from 'react';

import {
    withStyles,
    IconButton,
    CircularProgress,
} from '@material-ui/core/';

import Cytoscape from 'cytoscape';
import COSEBilkent from 'cytoscape-cose-bilkent';
import CytoscapeComponent from 'react-cytoscapejs';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
    isArrayValid,
    loadPlans
} from '@apricityhealth/web-common-lib/utils/Services'
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import styles from './styles'

var gridGuide = require('cytoscape-grid-guide');
gridGuide(Cytoscape);
let dagre = require('cytoscape-dagre');
Cytoscape.use(COSEBilkent);
Cytoscape.use(dagre);


const layoutType = {
    name: 'breadthfirst',
    directed: true,
    padding: 10,
    avoidOverlap: true,
    circle: false
};

function PlanHeirarchyReport({ classes, appContext }) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [planTypes, setPlanTypes] = useState([]);
    const [cyto, setCyto] = useState(null);

    async function fetchPlans() {
        setLoading(true)
        setCyto(null);
        try {
            let planTypes = await loadPlans(appContext, "*");
            setPlanTypes([...planTypes])
        } catch (error) {
            setError(getErrorMessage(error));
            console.error(`Error fetching type data`, getErrorMessage(error))
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchPlans();
    }, [])


    useEffect(() => {
        if (isArrayValid(planTypes)) {

            let filteredPlanTypes = planTypes.filter(p => Boolean(p.protected));
            let nodes = [];
            let edges = []
            filteredPlanTypes.forEach(el => {
                nodes.push({
                    data: {
                        id: el.planId,
                        label: el.title,
                        width: 144,
                        height: 100,
                    }
                })
                if (isArrayValid(el.dependencies)) {
                    el.dependencies.forEach(element => {
                        let depPlan = planTypes.find(p => p.planId === element);
                        let label = `${depPlan.title} -> ${el.title}`;
                        edges.push({
                            data: { source: element, target: el.planId, id: label, label }
                        })
                    });
                }
            })

            setCyto(<CytoscapeComponent
                stylesheet={[
                    {
                        selector: 'node',
                        style: {
                            'content': 'data(label)',
                            'text-opacity': 0.5,
                            'text-valign': 'top',
                            'text-halign': 'left',
                            'font-size': "20px",
                            'font-weight': "bold",
                            'background-color': '#11479e',
                            'shape': "round-rectangle",
                            "width": "50px"
                        }
                    },

                    {
                        selector: 'edge',
                        style: {
                            'curve-style': 'bezier',
                            'width': 4,
                            'target-arrow-shape': 'triangle',
                            'line-color': '#9dbaea',
                            'target-arrow-color': '#9dbaea'
                        }
                    }
                ]}
                style={{ width: 1500, height: 1000 }}
                elements={[...nodes, ...edges]}
                layout={layoutType}
            />);
        }
    }, [planTypes])

    return (
        <div className={classes.root}>
            <IconButton disabled={loading} onClick={() => fetchPlans()}>{loading ? <CircularProgress size={20} /> : <RefreshIcon />}</IconButton>
            {error}
            {cyto}
        </div>
    )

}

export default withStyles(styles)(PlanHeirarchyReport)