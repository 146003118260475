import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/';

import {
    isArrayValid,
    findNodeByName,
} from '../utils/Utils';

class RecommendationsReportView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recommendations: props.recommendations,
            models: props.models
        };
    }

    static getDerivedStateFromProps(props) {
        return { recommendations: props.recommendations, models: props.models };
    }

    render() {
        let { models, recommendations } = this.state;
        let groups = models.reduce((acc, model) => {
            if (isArrayValid(model.groups)) {
                model.groups.forEach(group => {
                    group.modelId = model.modelId
                });
                acc = acc.concat(model.groups);
            }
            return acc;
        }, []);

        let categories = groups.reduce((acc, group) => {
            if (isArrayValid(group.categories)) {
                group.categories.forEach(category => {
                    category.modelId = group.modelId;
                });
                acc = acc.concat(group.categories);
            }
            return acc;
        }, []);

        let flattened = {};
        categories.forEach(category => {
            let name = category.name;
            if (!flattened[name]) {
                flattened[name] = [];
            }
            category.recommendations = category.recommendations.map(recommendation => {
                let type = recommendations.find(recommend => recommend.recommendId === recommendation.recommendId);
                return { ...type, ...recommendation };

            });
            category.recommendations.forEach(recommendation => {
                recommendation.modelId = category.modelId;
            });

            flattened[name] = flattened[name].concat(category.recommendations);
            flattened[name] = flattened[name].sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                else if (a.name > b.name)
                    return 1;
                return 0;
            });
        });


        let report = this.getReport(flattened);
        // let report = this.getRecommendationsReport(recommendations);
        return (
            <div align="left" style={styles.div} >
                {report}
            </div>
        )
    }



    getReport(categories) {

        let tables = [];
        Object.keys(categories).forEach(category => {

            let optionsList = {};

            let recommendations = categories[category];
            recommendations.forEach(recommendation => {
                let option = recommendation.option;
                if (!optionsList[option]) optionsList[option] = [];
                let name = `${recommendation.name} (weight:${recommendation.weight}, model:${recommendation.modelId} )`;
                optionsList[option].push(<li><span dangerouslySetInnerHTML={{ __html: name }} /></li>);
            });
            let optionCols = [];
            let dataCols = []
            Object.keys(optionsList).forEach(option => {
                optionCols.push(<th className='recommend_options_heading'> Option {option}  </th>);
                dataCols.push(<td className='group_recommendation_cell'><ul className='group_recommendation_list'>{optionsList[option]}</ul></td>);
            });
            let categoryHeading = <tr className='tr'>
                <th className='title' colSpan={Object.keys(optionsList).length}>
                    {category}
                </th>
            </tr>

            tables.push(<table align="left" className='recommendation_groups_table' >
                <tbody>
                    {categoryHeading}
                    <tr className='tr'>{optionCols}</tr>
                    <tr className='tr'>{dataCols}</tr>
                </tbody>
            </table>);
        });
        return tables;
    }

    getRecommendationsReport(recommendations) {
        const { appContext: { stores: { DataTypesStore }} } = this.props;

        if (!isArrayValid(recommendations)) return [];
        //need to find the higest option number used.
        let numOptions = 0;
        for (let j = 0; j < recommendations.length; j++) {
            let recommendation = recommendations[j];
            if (recommendation.option > numOptions)
                numOptions = recommendation.option;
        }

        let groups = this.getRecommendationsByGroup(recommendations)
        console.log(`groups `, groups);

        let rows = [];
        let columns = []

        for (let j = 0; j < groups.length; j++) {
            let group = groups[j];
            let rowSpan = 0;
            if (group.groups)
                rowSpan = group.groups.length;

            let optionRows = [];
            for (let index = 0; index < numOptions; index++) {
                optionRows.push(<th className='recommend_options_heading'> Option {index + 1}  </th>);
            }
            let prettyName = group.name;
            let recIndex = DataTypesStore.getRecommendCategories().findIndex((rec) => rec.categoryId === group.name);
            if (recIndex >= 0)
                prettyName = DataTypesStore.getRecommendCategories()[recIndex].name;

            rows.push(
                <tr >
                    <td rowSpan={rowSpan * 2 + 2} className='group_category_name'>{prettyName}</td>
                    <th className='recommend_options_heading'><span> Default<br />(Option 0 - always shown)</span></th>
                    {optionRows}
                </tr>
            );
            if (isArrayValid(group.options)) {

                group.options.sort((a, b) => {
                    if (a.name < b.name)
                        return -1;
                    else if (a.name > b.name)
                        return 1;
                    return 0;
                });
                for (let k = 0; k <= numOptions; k++) {
                    let option = findNodeByName(group.options, k);
                    if (option && isArrayValid(option.recommendations)) {
                        let recommendations = option.recommendations;
                        recommendations.sort((a, b) => {
                            if (a.name < b.name)
                                return -1;
                            else if (a.name > b.name)
                                return 1;
                            return 0;
                        });
                        let listItems = [];
                        let found = [];
                        for (let m = 0; m < recommendations.length; m++) {
                            let recommendation = recommendations[m];

                            let recIndex = found.findIndex((rec) => rec.name === recommendation.name);
                            if (recIndex >= 0)
                                listItems.push(<li style={{ color: 'red' }}><span dangerouslySetInnerHTML={{ __html: recommendation.name + " {duplicate}" }} /></li>);
                            else {
                                found.push(recommendation);
                                listItems.push(<li><span dangerouslySetInnerHTML={{ __html: recommendation.name }} /></li>);
                            }
                        }
                        columns.push(<td className='group_recommendation_cell'><ul className='group_recommendation_list'>{listItems}</ul></td>);
                    } else {
                        columns.push(<td className='group_recommendation_cell'><ul className='group_recommendation_list'><li>none </li></ul></td>);
                    }

                }
                rows.push(<tr className='tr'>{columns}</tr>);
                columns = [];
            }
            if (isArrayValid(group.groups)) {

                for (let m = 0; m < group.groups.length; m++) {

                    let groupOption = group.groups[m]


                    columns.push(<th className='recommend_groups_heading' colSpan={numOptions + 1}>{groupOption.name}</th>)
                    rows.push(<tr className='tr'>{columns}</tr>);
                    columns = [];

                    for (let k = 0; k <= numOptions; k++) {
                        let option = findNodeByName(groupOption.options, k);
                        if (option && isArrayValid(option.recommendations)) {
                            let recommendations = option.recommendations;
                            recommendations.sort((a, b) => {
                                if (a.name < b.name)
                                    return -1;
                                else if (a.name > b.name)
                                    return 1;
                                return 0;
                            });

                            let listItems = [];
                            let found = []
                            for (let m = 0; m < recommendations.length; m++) {
                                let recommendation = recommendations[m];
                                let recIndex = found.findIndex((rec) => rec.name === recommendation.name);
                                if (recIndex >= 0)
                                    listItems.push(<li style={{ color: 'red' }}><span dangerouslySetInnerHTML={{ __html: recommendation.name + " {duplicate}" }} /></li>);
                                else {
                                    found.push(recommendation);
                                    listItems.push(<li><span dangerouslySetInnerHTML={{ __html: recommendation.name }} /></li>);
                                }

                                // listItems.push(<li ><span dangerouslySetInnerHTML={{ __html: recommendation.name}} /></li>);
                            }
                            columns.push(<td className='group_recommendation_cell'><ul className='group_recommendation_list'>{listItems}</ul></td>);
                        } else {
                            columns.push(<td className='group_recommendation_cell'><ul className='group_recommendation_list'><li>none </li></ul></td>);
                        }

                    }
                    rows.push(<tr className='tr'>{columns}</tr>);
                    columns = [];
                }
            }
            rows.push(<tr><th className='recommend_group_seperator' colSpan={numOptions + 2} >  </th></tr>);

            columns = [];


        }

        return <table align="left" className='recommendation_groups_table' >
            <tbody>
                <tr className='tr'>
                    <th className='title' colSpan={numOptions + 2}>
                        Management Options with Rank Order
                    </th>
                </tr>
                {rows}
            </tbody>
        </table>
    }

    getRecommendationsByGroup(recommendations) {
        let groups = [];

        if (isArrayValid(recommendations)) {
            for (let j = 0; j < recommendations.length; j++) {
                let recommendation = recommendations[j];
                let category = recommendation.category;
                let group = recommendation.group;

                let groupCategory = findNodeByName(groups, category);
                if (!groupCategory) {
                    groupCategory = { name: category, options: [], groups: [] };
                    groups.push(groupCategory);
                }

                //add to the option list on category
                let option = recommendation.option;

                if (group === category) {

                    let groupOption = findNodeByName(groupCategory.options, option);
                    if (!groupOption) {
                        groupOption = { name: option, recommendations: [] }
                        groupCategory.options.push(groupOption);
                    }
                    groupOption.recommendations.push(recommendation);
                } else {
                    //add to the group  under  the category
                    let subGroupCategory = findNodeByName(groupCategory.groups, group);
                    if (!subGroupCategory) {
                        subGroupCategory = { name: group, options: [] };
                        groupCategory.groups.push(subGroupCategory);
                    }

                    let groupOption = findNodeByName(subGroupCategory.options, option);
                    if (!groupOption) {
                        groupOption = { name: option, recommendations: [] }
                        subGroupCategory.options.push(groupOption);
                    }
                    groupOption.recommendations.push(recommendation);
                }
            }
        }

        return groups;

    }
}

const styles = {
    div: {
        margin: 10
    }
};

RecommendationsReportView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(RecommendationsReportView);
