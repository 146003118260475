import React, { Component } from "react";
import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Drawer,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    CircularProgress,
    TextField,
    Tooltip,
    Tabs,
    Tab,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SaveAsIcon from '@material-ui/icons/SaveAlt';
import CodeIcon from '@material-ui/icons/Code';
import RefreshIcon from '@material-ui/icons/Refresh';
import CreateIcon from '@material-ui/icons/Create';
import ModelsIcon from '@material-ui/icons/DeviceHub';
import DetectErrorsIcon from '@material-ui/icons/Warning';

import ModelTypeView from './ModelTypeView';
import DetectModelRulesView from "../reports/DetectModelRulesView";

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

import OverrideDialog from "../dialogs/OverrideDialog";
import EditDataTypeDialog from "../dialogs/EditDataTypeDialog";
import GenerateDetectDataTypes from '../dialogs/GenerateDetectDataTypes';
import SaveToPlanDialog from "../dialogs/SaveToPlanDialog";

import EditableNote from '../components/EditableNote'

import {
    isArrayValid,
} from '../utils/Utils';

import {
    loadDetectModels,
    saveDetectModels,
    deleteDetectModels,
    getAlertLevels,
    loadModels
} from "@apricityhealth/web-common-lib/utils/Services";

import JsonDialog from '../dialogs/JsonDialog';
import { v4 as uuidv4 } from 'uuid';
import createIdFromText from "../utils/CreateIdFromText";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import ChangedByButton from '../components/ChangedByButton';

const MAX_PERMUTATIONS = 100000000;

function numberOfPermutations(model) {
    function calculatePermuations(symptoms, permuations) {
        if (Array.isArray(symptoms)) {
            for (let i = 0; i < symptoms.length; ++i) {
                let symptom = symptoms[i];
                let count = symptom.values.length;
                if (permuations === 0)
                    permuations = count;
                else if (count > 0)
                    permuations *= count;
            }
        }
        return permuations;
    }

    let permuations = calculatePermuations(model.primarySymptoms, 0);
    permuations = calculatePermuations(model.associatedSymptoms, permuations);
    permuations = calculatePermuations(model.positiveSymptoms, permuations);
    permuations = calculatePermuations(model.negativeSymptoms, permuations);
    permuations = calculatePermuations(model.clinicalModifiers, permuations);
    return permuations;
}

function getPermuation(model, permuation) {
    let data = { primary: [], assoc: [], positive: [], negative: [], clinical: [], labs: [] };
    function pushData(data, symptoms, permuation) {
        if (Array.isArray(symptoms)) {
            for (let i = 0; i < symptoms.length; ++i) {
                let symptom = symptoms[i];
                let values = symptom.values;
                if (values.length > 0) {
                    let pick = permuation > 0 ? permuation % values.length : 0;
                    let value = values[pick].value;
                    permuation = Math.floor(permuation / values.length);
                    data.push(value);
                }
            }
        }
        return permuation;
    }

    permuation = pushData(data.primary, model.primarySymptoms, permuation);
    permuation = pushData(data.assoc, model.associatedSymptoms, permuation);
    permuation = pushData(data.positive, model.positiveSymptoms, permuation);
    permuation = pushData(data.negative, model.negativeSymptoms, permuation);
    permuation = pushData(data.clinical, model.clinicalModifiers, permuation);
    return data;
}

export class EditDetectModelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            model: JSON.parse(JSON.stringify(props.model))     // make a copy 
        }
    }

    onDone() {
        this.props.onDone(this.state.model);
    }

    render() {
        const { appContext } = this.props;
        let { model, dialog } = this.state;
        if (model.modelDataId === undefined) model.modelDataId = '';
        if (model.alertDataId === undefined) model.alertDataId = '';
        if (model.gradeDataId === undefined) model.gradeDataId = '';
        if (model.priorityDataId === undefined) model.priorityDataId = '';
        if (model.scoreDataId === undefined) model.scoreDataId = '';
        if (model.order === undefined) model.order = 0;
        if (model.inactive === undefined) model.inactive = false;

        let generateDisabled = model.modelDataId !== "" || model.alertDataId !== "" || model.gradeDataId !== "" || model.priorityDataId !== "" || model.gradeDataId !== "" || model.scoreDataId !== "";

        return <Dialog open={true}>
            <DialogTitle>Edit Model</DialogTitle>
            <DialogContent>
                <TextField disabled={true} style={styles.name} label="Model ID" value={model.modelId} onChange={(e) => {
                    model.modelId = e.target.value;
                    this.setState({ model });
                }} />
                <br />
                <TextField style={styles.name} label="Name" value={model.name} onChange={(e) => {
                    model.name = e.target.value;
                    model.modelId = createIdFromText(model.name);
                    this.setState({ model, modified: true });
                }} />
                <br />
                <TextField style={styles.description} label="Description" value={model.description} onChange={(e) => {
                    model.description = e.target.value;
                    this.setState({ model, modified: true });
                }} />
                <br />
                <FormControl style={styles.select}>
                    <InputLabel>Category</InputLabel>
                    <Select value={model.category} onChange={(e) => {
                        model.category = e.target.value;
                        this.setState({ model });
                    }}>
                        <MenuItem value='pro'>PRO</MenuItem>
                        <MenuItem value='lab'>Lab</MenuItem>
                        <MenuItem value='triage'>Triage</MenuItem>
                        <MenuItem value='symptom'>Symptom</MenuItem>
                        <MenuItem value='survey'>Survey</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <TextField style={styles.name} type='number' label="Order" value={model.order} onChange={(e) => {
                    model.order = e.target.value;
                    this.setState({ model, modified: true });
                }} />
                <br />
                <SelectDataType label='Model Data Type' style={styles.select} appContext={appContext} category={'model'} dataId={model.modelDataId}
                    onCreate={() => {
                        this.setState({
                            dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                dataType={{ dataId: '', name: '', description: '', category: 'model', tupleDescriptions: [] }}
                                onCancel={() => this.setState({ dialog: null })}
                                onDone={(newType) => {
                                    model.modelDataId = newType.dataId;
                                    this.setState({ dialog: null, model, modified: true });
                                }} />
                        });
                    }}
                    onSelect={(dataType) => {
                        model.modelDataId = dataType ? dataType.dataId : '';
                        this.setState({ model, modified: true });
                    }} />
                {!generateDisabled && <GenerateDetectDataTypes style={styles.generate} model={model} appContext={appContext} onDone={({ model }) => this.setState({ model, modified: true })} />}
                <br />
                <SelectDataType label='Alert Data Type' style={styles.select} appContext={appContext} category={'alert'} dataId={model.alertDataId} enableNone={true}
                    onCreate={() => {
                        this.setState({
                            dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                dataType={{ dataId: '', name: '', description: '', category: 'alert', tupleDescriptions: [] }}
                                onCancel={() => this.setState({ dialog: null })}
                                onDone={(newType) => {
                                    model.alertDataId = newType.dataId;
                                    this.setState({ dialog: null, model, modified: true });
                                }} />
                        });
                    }}
                    onSelect={(dataType) => {
                        model.alertDataId = dataType ? dataType.dataId : '';
                        this.setState({ model, modified: true });
                    }} />
                <br />
                <SelectDataType label='Grade Data Type' style={styles.select} appContext={appContext} category={'grade'} dataId={model.gradeDataId} enableNone={true}
                    onCreate={() => {
                        this.setState({
                            dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                dataType={{ dataId: '', name: '', description: '', category: 'grade', tupleDescriptions: [] }}
                                onCancel={() => this.setState({ dialog: null })}
                                onDone={(newType) => {
                                    model.gradeDataId = newType.dataId;
                                    this.setState({ dialog: null, model, modified: true });
                                }} />
                        });
                    }}
                    onSelect={(dataType) => {
                        model.gradeDataId = dataType ? dataType.dataId : '';
                        this.setState({ model, modified: true });
                    }} />
                <TextField type='number' label='Slope Days' style={styles.days} value={model.gradeSlopeDays} onChange={(e) => {
                    model.gradeSlopeDays = e.target.value;
                    this.setState({ model, modified: true });
                }} />
                <br />
                <SelectDataType label='Priority Data Type' style={styles.select} appContext={appContext} category={'status'} dataId={model.priorityDataId} enableNone={true}
                    onCreate={() => {
                        this.setState({
                            dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                dataType={{ dataId: '', name: '', description: '', category: 'status', tupleDescriptions: [] }}
                                onCancel={() => this.setState({ dialog: null })}
                                onDone={(newType) => {
                                    model.priorityDataId = newType.dataId;
                                    this.setState({ dialog: null, model, modified: true });
                                }} />
                        });
                    }}
                    onSelect={(dataType) => {
                        model.priorityDataId = dataType ? dataType.dataId : '';
                        this.setState({ model, modified: true });
                    }} />
                <TextField type='number' label='Slope Days' style={styles.days} value={model.prioritySlopeDays} onChange={(e) => {
                    model.prioritySlopeDays = e.target.value;
                    this.setState({ model, modified: true });
                }} />
                <br />
                <SelectDataType label='Score Data Type' style={styles.select} appContext={appContext} category={'score'} dataId={model.scoreDataId} enableNone={true}
                    onCreate={() => {
                        this.setState({
                            dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                dataType={{ dataId: '', name: '', description: '', category: 'score', tupleDescriptions: [] }}
                                onCancel={() => this.setState({ dialog: null })}
                                onDone={(newType) => {
                                    model.scoreDataId = newType.dataId;
                                    this.setState({ dialog: null, model, modified: true });
                                }} />
                        });
                    }}
                    onSelect={(dataType) => {
                        model.scoreDataId = dataType ? dataType.dataId : '';
                        this.setState({ model, modified: true });
                    }} />
                <TextField type='number' label='Slope Days' style={styles.days} value={model.scoreSlopeDays} onChange={(e) => {
                    model.scoreSlopeDays = e.target.value;
                    this.setState({ model, modified: true });
                }} />
                <br />
                <FormControlLabel style={{margin: 5}} control={<Checkbox checked={model.inactive} onChange={(e) => {
                    model.inactive = e.target.checked;
                    this.setState({ model, modified: true })
                }} />} label='Inactive' />
                {dialog}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>OK</Button>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

class DetectModelView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelId: '',
            detectModel: null,
            alerts: null,
            modified: false,
            tabIndex: '0',
            error: null,
            errors: [],
            typesToDelete: []
        };
    }

    componentDidMount() {
        this.loadDetectModel(this.props.modelId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.modelId !== prevProps.modelId) {
            this.loadDetectModel(this.props.modelId);
        }
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Detect Model Modified!</DialogTitle>
            <DialogContent>This detect model has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveDetectModel(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    closeType() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else {
            this.props.onClose();
        }
    }

    loadDetectModel(modelId) {
        const self = this;
        const { appContext, category } = this.props;
        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadDetectModel.bind(this, modelId));
        }

        console.log("loadType:", modelId);
        if (modelId) {
            this.setState({ progress: <CircularProgress /> });

            Promise.all([loadDetectModels(appContext, { modelId, dependencies: true }), getAlertLevels(appContext, { dependencies: true })]).then((results) => {
                let detectModels = results[0];
                let alerts = results[1];
                let detectModel = isArrayValid(detectModels) ? detectModels[0] :
                    { name: 'New Detect Model', modelId: '', description: '', rules: [], conditions: [] };
                self.setState({ progress: null, alerts, detectModel, modelId });
            }).catch((err) => {
                self.setState({ progress: null, error: getErrorMessage(err) });
            })
        }
        else {
            let modelId = 'new_model';
            let detectModel = {
                name: 'New Detect Model',
                modelId,
                description: 'New detect model description',
                category,
                order: 0,
                rules: [],
                conditions: []
            };
            this.setState({ detectModel, modelId })
        }
    }

    detectErrors() {
        const self = this;
        const { appContext } = this.props;
        const { detectModel } = this.state;

        loadModels(appContext, { dependencies: true }).then((models) => {
            let conditionModel = null;
            for (let i = 0; i < models.length; ++i) {
                let model = models[i];
                if (model.dataId === detectModel.modelDataId) {
                    conditionModel = model;
                    break;
                }
            }

            if (conditionModel) {
                let errors = [];
                let count = numberOfPermutations(conditionModel);
                let testCount = Math.min(count, MAX_PERMUTATIONS);

                let dialog = <Dialog open={true} maxWidth="sm" fullWidth={true}><DialogContent>
                    <div align='center'>
                        {`Testing ${testCount} of ${count} permutations...`}
                        <br />
                        <br />
                        <CircularProgress />
                    </div>
                </DialogContent></Dialog>;

                self.setState({ dialog });
                setTimeout(() => {
                    console.log(`Testing ${testCount} of ${count} permuations...`);
                    for (let k = 0; k < testCount; ++k) {
                        let picked = testCount < count ? Math.floor(Math.random() * count) : k;
                        let permuation = getPermuation(conditionModel, picked);
                        //console.log(`Testing permuation ${picked}`, permuation );
                        let ruleFound = null;
                        for (let i = 0; i < detectModel.rules.length && !ruleFound; ++i) {
                            let rule = detectModel.rules[i];

                            function testValues(condition, values) {
                                if (condition === undefined || !condition.cond)
                                    return true;

                                if (values.length === 0)
                                    values.push(0);

                                if (condition.cond.toLowerCase() === 'any') {
                                    // if any one value is inside our range, then this condition is true
                                    let count = 0;
                                    for (let i = 0; i < values.length; ++i) {
                                        if (values[i] >= condition.min && values[i] <= condition.max)
                                            count += 1;
                                    }
                                    return count >= (condition.count || 1);
                                }
                                else if (condition.cond.toLowerCase() === 'all') {
                                    // all values must be inside our range..
                                    for (let i = 0; i < values.length; ++i) {
                                        if (values[i] < condition.min || values[i] > condition.max)
                                            return false;
                                    }
                                    return true;
                                }
                            }

                            let primaryTest = testValues(rule.primarySymptoms, permuation.primary);
                            let assocTest = testValues(rule.associatedSymptoms, permuation.assoc);
                            let posTest = testValues(rule.positiveSymptoms, permuation.positive);
                            let negTest = testValues(rule.negativeSymptoms, permuation.negative);
                            let clinicalTest = testValues(rule.clinicalModifiers, permuation.clinical);

                            //console.log(`primaryTest: ${primaryTest}, assocTest: ${assocTest}, posTest: ${postTest}, negTest: ${negTest}, clinicalTest: ${clinicalTest}`);
                            if (primaryTest && assocTest && posTest && negTest && clinicalTest) {
                                ruleFound = rule;
                                break;
                            }
                        }

                        //console.log(`Testing - primary: ${permuation.primary.join(',')}, assoc: ${permuation.assoc.join(',')}, clinical: ${permuation.clinical.join(',')}, ruleFound:`, ruleFound );
                        if (!ruleFound) {
                            errors.push([permuation.primary, permuation.assoc, permuation.positive, permuation.negative, permuation.clinical]);
                        }
                    }

                    console.log(`Tested ${testCount} of ${count} permuations...`);

                    let detectErrors = [];
                    if (errors.length > 0) {
                        detectErrors.push(<div key='result' align='left' style={{ margin: 15 }}><h4 style={{ color: 'red' }}>DETECT RULE GAPS:</h4>
                            <table style={{ width: '60%' }}><tbody>
                                <tr>
                                    <td><b>PRIMARY</b></td>
                                    <td><b>ASSOCIATED</b></td>
                                    <td><b>POSITIVE</b></td>
                                    <td><b>NEGATIVE</b></td>
                                    <td><b>CLINICAL</b></td>
                                </tr>
                                {errors.map((e, i) => {
                                    return <tr key={i}>
                                        <td>{e[0].join(',')}</td>
                                        <td>{e[1].join(',')}</td>
                                        <td>{e[2].join(',')}</td>
                                        <td>{e[3].join(',')}</td>
                                        <td>{e[4].join(',')}</td>
                                    </tr>
                                })}
                            </tbody></table></div>);
                    }
                    else {
                        detectErrors.push(<div key='result'>{"NO ERRORS FOUND, GOOD JOB!"}</div>);
                    }

                    let dialog = <Dialog open={true} maxWidth="sm" fullWidth={true}>
                        <DialogTitle>{`Tested ${testCount} of ${count} permuations for ${conditionModel.name}`}</DialogTitle>
                        <DialogContent>
                            {detectErrors}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => self.setState({ dialog: null })}>Done</Button>
                        </DialogActions>
                    </Dialog>;

                    self.setState({ dialog });
                }, 1000);
            }
            else {
                console.log(`No condition model found for ${detectModel.modelDataId}`);
            }
        }).catch((err) => {
            console.error("detectErrors error:", err);
            self.setState({ error: getErrorMessage(err) });
        })
    }

    saveDetectModel(callback, planId = null) {
        const self = this;
        let { appContext } = this.props;
        let { detectModel } = this.state;

        if (!planId && detectModel.planId && detectModel.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(detectModel.planId, this.saveDetectModel.bind(this, callback));
        }

        // validate the conditions in the rules..
        for (let i = 0; i < detectModel.rules.length; ++i) {
            let rule = detectModel.rules[i];
            if (rule.conditionId && !detectModel.conditions.find((e) => e.conditionId === rule.conditionId)) {
                console.error(`Removing invalid condition from rule:`, rule);
                rule.conditionId = '';
            }
        }

        this.setState({ progress: <CircularProgress />, error: null, dialog: null });
        this.sortRules( detectModel );

        saveDetectModels(appContext, detectModel, planId).then((detectModel) => {
            let modelId = detectModel.modelId;
            self.setState({ modified: false, detectModel, modelId, progress: null }, callback);
        }).catch((err) => {
            self.setState({ progress: null, error: getErrorMessage(err) }, () => callback(err) );
        })
    }

    saveToPlan(callback) {
        let dialog = <SaveToPlanDialog appContext={this.props.appContext} planId={this.state.model.planId} onCancel={this.onCloseDialog.bind(this)}
            onDone={({ plan, move }) => {
                console.log("plan:", plan, ", move: ", move);
                const detectModel = { ...this.state.detectModel };       // make a copy of the old detect model before we save to the new plan
                this.saveDetectModel((err) => {
                    // restore the detect model to the original one now
                    this.setState({detectModel}, () => {
                        if ( err ) {
                            console.error("saveDetectModel err:", err );
                        } else if ( move ) {
                            // if we are moving, then delete our model now
                            this.deleteDetectModelConfirmed();
                        }
                    })                    
                }, plan.planId );
            }} />;
        this.setState({ dialog });
    }

    showConditionModel() {
        let { detectModel } = this.state;
        const { conditionTypes, appContext } = this.props
        let conditionId = ''
        if (conditionTypes && Array.isArray(conditionTypes)) {
            let condition = conditionTypes.find((condition) => condition.dataId === detectModel.modelDataId);
            if (condition) conditionId = condition.modelId
        }
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <ModelTypeView appContext={appContext} modelId={conditionId} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    deleteDetectModel() {
        this.setState({ dialog: <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Please confirm you want to delete this detect model?</DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                <Button onClick={this.deleteDetectModelConfirmed.bind(this)}>Confirm</Button>
            </DialogActions>
        </Dialog>});
    }

    deleteDetectModelConfirmed() {
        const { detectModel } = this.state;
        const { appContext } = this.props;

        console.log("deleteDetectModel:", detectModel);
        this.setState({ progress: < CircularProgress />, error: null, dialog: null });
        deleteDetectModels(appContext, detectModel).then(() => {
            this.setState({ modified: false, progress: null, dialog: null }, this.closeType.bind(this));
        }).catch((err) => {
            console.log(getErrorMessage(err))
            this.setState({ progress: null, error: getErrorMessage(err), dialog: null });
        })
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onShowCode() {
        let { detectModel } = this.state;
        if (detectModel) {
            this.setState({
                dialog: <JsonDialog
                    appContext={this.props.appContext}
                    dataType={detectModel}
                    onEditDone={(detectModel) => {
                        this.setState({detectModel: this.sortRules(detectModel), dialog: null, modified: true})
                    }}
                    onDone={this.onCloseDialog.bind(this)} />
            });
        }
    }

    sortRules(detectModel) {
        let { alerts } = this.state;
        detectModel.rules.sort((a, b) => {
            let result = 0;
            if (a.priority !== b.priority)
                result = a.priority > b.priority ? -1 : 1;
            else if (a.grade !== b.grade)
                result = a.grade > b.grade ? -1 : 1;
            else if (a.alert !== b.alert) {
                let aPriority = alerts[a.alert] ? alerts[a.alert].priority : 0;
                let bPriority = alerts[b.alert] ? alerts[b.alert].priority : 0;
                result = aPriority > bPriority ? -1 : 1;
            }
            return result;
        });
        return detectModel;
    }

    onChange() {
        this.setState({ detectModel: this.sortRules(this.state.detectModel), modified: true });
    }

    onEditType() {
        const self = this;
        let dialog = <EditDetectModelDialog appContext={this.props.appContext} model={this.state.detectModel} onDone={(detectModel) => {
            console.log("onEditModel done:", detectModel);
            self.setState({ dialog: null, detectModel, modified: true });
        }} onCancel={() => {
            console.log("onEditModel cancel");
            self.setState({ dialog: null });
        }} />;

        this.setState({ dialog });
    }

    onUpdateConditions(conditions) {
        let { detectModel } = this.state;
        detectModel.conditions = conditions;
        this.setState({ detectModel });
    }

    onConditionControl(table, value, row, index, id) {
        const { appContext } = this.props;
        //const { detectModel } = this.state;
        return <EditConditions
            appContext={appContext}
            conditions={value}
            onClick={(e) => table.handleClickEdit(e)}
            onChange={(conditions) => {
                table.setDataField(index, id, conditions);
            }}
            name={'Conditions'}
        />;
    }

    render() {
        const self = this;
        let { tabIndex, alerts, detectModel, modelId, dialog, error, progress, errorProgress } = self.state;

        const columnData = [
            //{ id: 'conditionId', width: 50, editType: 'text', numeric: false, disabledPadding: false, label: 'conditionId', onAdd: () => { return uuidv4() } },
            { id: 'description', width: 200, editType: 'text', disabledPadding: false, label: 'Description' },
            { id: 'condition', numeric: false, disabledPadding: false, label: 'Condition', editControl: this.onConditionControl.bind(this) }
        ];

        let title = '';
        let tabs = [];
        let disableDelete = true;
        if (detectModel) {
            title = `${detectModel.name} - ${detectModel.description}`;
            tabs.push(<DetectModelRulesView key='rules' appContext={this.props.appContext} alerts={alerts} detectModels={[detectModel]} onChange={this.onChange.bind(this)} />);
            tabs.push(<EnhancedTable key='conditions' columnData={columnData} onPreAdd={(add) => { add.conditionId = uuidv4(); }}
                data={detectModel.conditions} title='Conditions' onDataChanged={this.onUpdateConditions.bind(this)} />);
            disableDelete = detectModel.planId !== this.props.appContext.state.plan.planId;
        }

        return (
            <div align="center" style={{ width: 1200 }}>
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={this.closeType.bind(this)}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Detect Model</Typography>
                    </Toolbar>
                </AppBar>
                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    <Tooltip title='Edit Model'><IconButton onClick={this.onEditType.bind(this)}><CreateIcon /></IconButton></Tooltip>
                                    {title}
                                </td>
                                <td valign="top" align="right">
                                    <Tooltip title="Save"><span><IconButton onClick={this.saveDetectModel.bind(this, null, null)}><SaveIcon /></IconButton></span></Tooltip>
                                    <Tooltip title='Save To Plan'><IconButton disabled={progress !== null} onClick={this.saveToPlan.bind(this, null)}><SaveAsIcon /></IconButton></Tooltip>
                                    {detectModel && detectModel.planId ? <EditableNote textId={`detectModel.${detectModel.modelId}`} planId={detectModel.planId} /> : null}
                                    <Tooltip title="Detect Gap Errors"><span><IconButton onClick={this.detectErrors.bind(this)}><DetectErrorsIcon /></IconButton></span></Tooltip>
                                    <Tooltip title="Show Condition Model"><span><IconButton onClick={this.showConditionModel.bind(this)}><ModelsIcon /></IconButton></span></Tooltip>
                                    <Tooltip title="Refresh"><span><IconButton onClick={this.loadDetectModel.bind(this, modelId)}><RefreshIcon /></IconButton></span></Tooltip>
                                    <Tooltip title="Show JSON"><span><IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton></span></Tooltip>
                                    <Tooltip title="Delete"><span><IconButton disabled={disableDelete}
                                        onClick={this.deleteDetectModel.bind(this)}><DeleteIcon /></IconButton></span></Tooltip>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                            <tr><td colSpan="2" style={styles.appBar}>
                                <Tabs value={tabIndex} onChange={(e, newValue) => {
                                    self.setState({ tabIndex: `${newValue}` });
                                }}>
                                    <Tab label="Rules" value='0' />
                                    <Tab label="Conditions" value='1' />
                                </Tabs>
                            </td></tr>
                            <tr><td colSpan="2">
                                {tabs[tabIndex]}
                                <br />
                                {detectModel ? <ChangedByButton appContext={this.props.appContext} primaryKey={detectModel.modelId} collection='DetectModel' /> : null}
                            </td></tr>
                        </tbody>
                    </table>
                </div>
                <span style={{ color: 'red' }}>{error}</span>
                {errorProgress}
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 5
    },
    button: {
        margin: 5
    },
    number: {
        margin: 5,
        width: 200
    },
    name: {
        margin: 5,
        width: 300
    },
    select: {
        margin: 5,
        width: 300
    },
    generate: {
        marginLeft: 20,
        marginTop: 20,
        valign: 'bottom',
    },
    days: {
        margin: 5,
        width: 100
    },
    description: {
        margin: 5,
        width: 500
    },
    tabs: {
        backgroundColor: 'green'
    },
    table: {
        width: '100%'
    },
    grid: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    grid1: {
        flexGrow: 1
    },
    grid2: {
        minWidth: '50%',
        color: 'gray'
    }
};

export default withStyles(styles)(DetectModelView);
