import React, { Component } from "react";
import preventClickthrough from 'react-prevent-clickthrough';
import { v4 as uuidv4 } from 'uuid';

import {
    CircularProgress,
    DialogTitle,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    Drawer,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    TextField,
    Tooltip,
    Switch,
    FormControl,
    InputLabel,
    FormControlLabel,
    Select,
    MenuItem,
    Checkbox
} from '@material-ui/core/';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import SaveAsIcon from '@material-ui/icons/SaveAlt';
import CreateIcon from '@material-ui/icons/Create';
import CodeIcon from '@material-ui/icons/Code';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandIcon from '@material-ui/icons/OpenWith';
import LayersClear from '@material-ui/icons/LayersClear';

import SortableTree, { walk, getNodeAtPath } from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

import {
    isArrayValid
} from '../utils/Utils';

import {
    validateRecommendModel,
} from '../utils/RecommendModelValidator'

import {
    loadRecommendationModels,
    saveRecommendtionModel,
    deleteRecommendationModel,
    loadRecommendations,
    saveRecommendation,
} from '@apricityhealth/web-common-lib/utils/Services';

import RecommendationTypesView from './RecommendationTypesView';
import EditConditionGroupDialog from '../dialogs/EditConditionGroupDialog';
import EditRecommendationType from '../dialogs/EditRecommendationDialog';
import EditCategoryDialog from '../dialogs/EditCategoryDialog';
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import SaveToPlanDialog from "../dialogs/SaveToPlanDialog";

import SelectRecommendation, { resetRecommendationsCache } from "@apricityhealth/web-common-lib/components/SelectRecommendation";
import createIdFromText from "../utils/CreateIdFromText";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

import '../styles/workup.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ChangedByButton from "../components/ChangedByButton";

class EditRecommendationModelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: JSON.parse(JSON.stringify(props.model)),     // make a copy 
            recommendationTypes: [],
        }
    }
    onDone() {
        let { model } = this.state;
        if (this.props.onDone)
            this.props.onDone(model);
    }
    onCancel() {
        if (this.props.onCancel)
            this.props.onCancel();
    }

    render() {
        const self = this;
        let { model } = this.state;

        return <Dialog open={true}>
            <DialogTitle>Edit Recommendation Model</DialogTitle>
            <DialogContent>
                <TextField disabled={true} style={styles.name} label="Model ID" value={model.modelId} onChange={(e) => {
                    model.modelId = e.target.value;
                    self.setState({ model });
                }} />
                <br />
                <TextField style={styles.name} label="Name" value={model.name} onChange={(e) => {
                    model.name = e.target.value;
                    model.modelId = createIdFromText(model.name);
                    self.setState({ model });
                }} />
                <br />
                <TextField style={styles.description} label="Description" value={model.description} onChange={(e) => {
                    model.description = e.target.value;
                    self.setState({ model });
                }} />
                <br />
                <FormControlLabel
                    style={{ margin: 5 }}
                    control={<Checkbox
                        checked={model.guideline}
                        name='dependencies'
                        onChange={(e, v) => {
                            model.guideline = v;
                            self.setState({ model });
                        }}
                    />}
                    label="Show in guidelines?"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>Save</Button>
                <Button variant="contained" style={styles.button} onClick={this.onCancel.bind(this)}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

class EditRecommendationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            recommendation: JSON.parse(JSON.stringify(props.recommendation))     // make a copy 
        }
    }

    onDone() {
        let { recommendation } = this.state;
        if (this.props.onDone)
            this.props.onDone(recommendation);
    }
    onCancel() {
        if (this.props.onCancel)
            this.props.onCancel();
    }

    onEditRecommendationType(recommendation) {
        const self = this;
        if (!recommendation) {
            recommendation = {
                name: "",
                description: "",
                category: "",
                option: '0',
                type: "",
                recommendId: uuidv4()
            }
        }
        this.setState({
            dialog: <EditRecommendationType
                recommendation={recommendation}
                appContext={this.props.appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(type) => {

                    saveRecommendation(self.props.appContext, type).then(() => {
                        const { recommendation } = self.state;
                        recommendation.recommendId = type ? type.recommendId : '';
                        recommendation.name = type ? type.name : '';
                        resetRecommendationsCache();
                        self.setState({ dialog: null, recommendation });
                    }).catch((err) => {
                        console.error("saveRecommendation error:", err);
                    })
                }} />
        });
    }

    render() {
        const self = this;
        const { appContext: { stores: { DataTypesStore }} } = this.props;
        let { recommendation, dialog } = this.state;
        if (!recommendation.protocol) recommendation.protocol = ["none"];
        if (!Array.isArray(recommendation.protocol)) {
            let protocol = recommendation.protocol;
            recommendation.protocol = [protocol];
        }

        return <Dialog open={true} fullWidth={true} maxWidth={'md'} PaperProps={{ style: { overflowY: 'visible' } }}>
            <DialogTitle>Edit Recommendation</DialogTitle>
            <DialogContent style={{ overflowY: 'visible' }}>
                <SelectRecommendation appContext={this.props.appContext} recommendId={recommendation.recommendId}
                    onChange={(recommend) => {
                        recommendation.recommendId = recommend ? recommend.recommendId : '';
                        recommendation.name = recommend ? recommend.name : '';
                        self.setState({ recommendation });
                    }}
                    onEdit={this.onEditRecommendationType.bind(this)}
                    onCreate={this.onEditRecommendationType.bind(this)}
                />

                <TextField style={styles.description} label="Explanation" value={recommendation.explanation} onChange={(e) => {
                    recommendation.explanation = e.target.value;
                    self.setState({ recommendation });
                }} />
                <br />
                <TextField style={styles.weight} type="number" label="Weight" value={recommendation.weight} onChange={(e) => {
                    recommendation.weight = e.target.value;
                    self.setState({ recommendation });
                }} />
                <FormControl style={this.props.style || { margin: 5, width: 550 }}>
                    <InputLabel>Protocol/Highlight Recommendation</InputLabel>
                    <Select
                        value={recommendation.protocol}
                        multiple
                        onChange={(e) => {
                            console.debug(`recommend protocol value `, e.target.value)
                            recommendation.protocol = e.target.value;
                            self.setState({ recommendation });
                        }
                        }>
                        {DataTypesStore.getRecommendProtocols().map((item) => (
                            <MenuItem key={item.protocolId} value={item.protocolId}>
                                {item.name}
                            </MenuItem>
                        ))}

                    </Select>

                </FormControl>
                <br />
                {dialog}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>Save</Button>
                <Button variant="contained" style={styles.button} onClick={this.onCancel.bind(this)}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

class RecommendModelView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelId: props.modelId || '',
            name: props.name || '',
            dialog: null,
            drawerOpen: false,
            modified: false,
            copyEnabled: false,
            wideDrawer: false,
            badRecommendations: [],
            badCategories: [],
            model: null,
            recommendations: null
        };

        this.expanded = {};
    }

    componentDidMount() {
        this.loadModel(this.props.modelId);
    }

    componentWillUnmount() {
    }

    componentDidUpdate(oldProps) {
        if (oldProps.modelId !== this.props.modelId) {
            this.loadModel(this.props.modelId);
        }
    }

    onCloseView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else
            this.props.onClose();
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Recommend Model Modified!</DialogTitle>
            <DialogContent>This recommend model has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveModel(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    onToggleDrawer() {
        var newState = !this.state.drawerOpen;
        this.setState({ drawerOpen: newState });
    }

    loadModel(modelId) {
        const self = this;
        const { appContext } = this.props;

        console.log("LoadModel:", modelId);
        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadModel.bind(this, modelId));
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null });

        let loadModels = modelId ? loadRecommendationModels(appContext, { modelId, dependencies: true }) : Promise.resolve();
        loadModels.then((recommendModels) => {
            let model = null;
            if (!isArrayValid(recommendModels)) {
                model = self.createNewModel();
            } else {
                model = recommendModels[0]
            }
            self.setState({
                modelId,
                model,
                dialog: null,
                modified: false,
                progress: null
            }, self.loadRecommendations.bind(self));
        }).catch((err) => {
            self.setState({ dialog: null, progress: null, error: getErrorMessage(err) });
        });
    }

    loadRecommendations() {
        const self = this;
        const { appContext } = this.props;

        loadRecommendations(appContext, { dependencies: true }).then((recommendations) => {
            self.setState({ recommendations }, self.validateModel.bind(self));
        }).catch((err) => {
            self.setState({ dialog: null, progress: null, error: getErrorMessage(err) });
        });
    }

    validateModel() {
        const { model, recommendations } = this.state;
        let badItems = validateRecommendModel( this.props.appContext, model, recommendations)
        this.setState({
            badRecommendations: badItems.badModels,
            badCategories: badItems.badCategories,
        }, this.initalizeTreeData.bind(this));
    }

    createNewModel() {
        let self = this;
        let recommendModel = {
            modelId: '',
            name: ``,
            description: ``,
            groups: [self.createNewGroup()]
        };
        return recommendModel;
    }

    createNewGroup(type) {
        let recommendGroup = {
            type: type || 'Triage Advisory',
            name: "A New Group",
            description: "A New Group",
            addAlert: [],
            deleteAlert: [],
            categories: [],
            treeId: uuidv4()
        };
        return recommendGroup
    }

    saveModel(callback, planId = null) {
        const self = this;
        const { appContext } = this.props;
        const { model, recommendations } = this.state;

        if (!planId && model.planId && model.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(model.planId, this.saveModel.bind(this, callback));
        }

        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        saveRecommendtionModel(appContext, model, planId).then((response) => {
            console.log("saveModel response:", response);

            let badItems = validateRecommendModel(appContext, response, recommendations)
            self.setState({
                badRecommendations: badItems.badModels,
                badCategories: badItems.badCategories,
                model: response,
                modified: false,
                progress: null
            }, callback);
        }).catch(function (error) {
            console.error("save model error:", error);
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    saveToPlan(callback) {
        const { model } = this.state;
        let dialog = <SaveToPlanDialog appContext={this.props.appContext} planId={model.planId} onCancel={this.onCloseDialog.bind(this)}
            onDone={({plan, move}) => {
                console.log("plan:", plan, ", move: ", move );
                let originalModel = { ...model };
                this.saveModel(() => {
                    if ( move ) {
                        deleteRecommendationModel(this.props.appContext, originalModel).then(() => {
                            this.onCloseView();
                        }).catch((err) => {
                            this.setState({ error: getErrorMessage(err)}, callback );                            
                        });
                    } else {
                        callback();
                    }
                }, plan.planId );
            }} />;
        this.setState({dialog});
    }

    onCloseDialog() {
        this.setState({dialog: null});
    }

    deleteModel() {
        console.log("deleteModel:", this);
        const { model } = this.state;
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Delete this recommendation model, are you sure?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: <CircularProgress size={20} />, error: null });
                    deleteRecommendationModel(this.props.appContext, model).then(() => {
                        self.setState({ progress: null, dialog: null }, self.onCloseView.bind(self));
                    }).catch((err) => {
                        console.error("deleteRecommendationModel failed:", err);
                        self.setState({ progress: null, dialog: null, error: getErrorMessage(err) });
                    })
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: null, dialog: null });
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    onAddGroup() {
        let { model } = this.state;
        let newGroup = this.createNewGroup();
        model.groups.splice(0, 0, newGroup);
        this.setState({ model, modified: true }, this.validateModel.bind(this));
    }

    onDeleteGroup(group) {
        const self = this;
        let { model } = this.state;
        let groupIndex = model.groups.indexOf(group);
        if (groupIndex >= 0) {
            model.groups.splice(groupIndex, 1);
            self.setState({ model, dialog: null, modified: true }, self.validateModel.bind(self))
        }
    }

    onEditGroup(model, group, e) {
        preventClickthrough(e);

        let { appContext } = this.props;

        const self = this;
        this.setState({
            dialog: <EditConditionGroupDialog
                model={model}
                group={group}
                appContext={appContext}
                onCancel={() => {
                    this.setState({ dialog: null });
                }}
                onDone={(update) => {
                    for (let k in update) {
                        group[k] = update[k];
                    }
                    self.setState({ modified: true, dialog: null }, self.validateModel.bind(self));
                }} />
        });
    }

    onAddCategory(group) {
        group.categories.splice(0, 0, {
            name: "New Category",
            recommendations: [],
            treeId: uuidv4(),
        });
        this.validateModel();
    }

    onAddRecommentation(category) {
        category.recommendations.splice(0, 0, {
            name: '',
            recommendId: '',
            explanation: '',
            weight: 1,
            treeId: uuidv4()
        });
        this.validateModel();
    }

    onEditCategory(category) {
        let self = this;
        this.setState({
            dialog: <EditCategoryDialog
                style={{ width: '100%' }}
                category={category}
                appContext={this.props.appContext}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(update) => {
                    for (let k in update)
                        category[k] = update[k];
                    self.setState({ dialog: null }, self.validateModel.bind(self));
                }} />
        });
    }

    onShowCode() {
        let { model } = this.state;
        let { appContext } = this.props;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={model}
                onEditDone={(model) => {
                    this.setState({model, dialog: null});
                }}
                onDone={() => { 
                    this.setState({ dialog: null }); 
                }} />
        });
    }

    onEditRecommendationModel() {
        let { model } = this.state;
        const self = this;
        let dialog = <EditRecommendationModelDialog model={model} onDone={(model) => {
            console.log("onEditRecommendationModel done:", model);
            self.setState({ dialog: null, model, modified: true });
        }} onCancel={() => {
            self.setState({ dialog: null });
        }} />;
        this.setState({ dialog });
    }

    getCategoryChildren(group, category) {
        const { recommendations } = this.state;

        let nodes = [];
        if (isArrayValid(category.recommendations)) {
            let recs = category.recommendations;
            for (let i = 0; i < recs.length; ++i) {
                let recommendation = recs[i];
                if (!recommendation.treeId)
                    recommendation.treeId = recommendation._id || uuidv4();

                let type = recommendations.find((e) => e.recommendId === recommendation.recommendId);
                // update the data in the model with the latest name from the type
                if (type) recommendation.name = type.name;

                let node = {
                    nodeType: 'recommendation',
                    recommendation,
                    category,
                    group,
                    text: type && type.name ? type.name.substring(0, 80) : recommendation.name,
                    subtitle: type ? undefined : 'Recommendation type not found!',
                    error: type && type.name ? false : true,
                    children: []
                };
                nodes.push(node)
            }
        }

        return nodes;
    }

    getGroupChildren(group) {
        const { appContext: { stores: { DataTypesStore }} } = this.props;

        let nodes = [];
        if (isArrayValid(group.categories)) {
            for (let i = 0; i < group.categories.length; ++i) {
                let category = group.categories[i];
                if (!category.treeId)
                    category.treeId = category._id || uuidv4();

                let cat = DataTypesStore.getRecommendCategories().find((e) => e.categoryId === category.name);
                let node = {
                    nodeType: 'category',
                    category,
                    group,
                    text: cat ? cat.name : category.name,
                    error: cat ? false : true,
                    treeId: category.treeId,
                    children: this.getCategoryChildren(group, category),
                    expanded: this.expanded[category.treeId]
                }

                nodes.push(node);
            }
        }
        return nodes;
    }

    onExpandNodes() {
        let expanded = false;
        this.onChangeTree(this.state.treeData);     // capture the current expanded state
        for (let k in this.expanded) expanded |= this.expanded[k];
        for (let k in this.expanded) this.expanded[k] = !expanded;
        console.log(`onExpandNodes, new state: ${expanded}:`, this.expanded);
        this.initalizeTreeData();                   // apply the new expanded state
    }

    initalizeTreeData() {
        const self = this;
        const { appContext: { stores: { DataTypesStore }} } = this.props;
        const { model } = this.state;

        let treeData = [];
        if (model && isArrayValid(model.groups)) {
            for (let i = 0; i < model.groups.length; i++) {
                let group = model.groups[i];
                if (!group.treeId)
                    group.treeId = group._id || uuidv4();

                let type = DataTypesStore.getRecommendGroupTypes().find((e) => e.typeId === group.type);
                let node = {
                    nodeType: 'group',
                    group,
                    error: type ? false : true,
                    text: `${group.name} (${type ? type.name : group.type})`,
                    treeId: group.treeId,
                    expanded: this.expanded[group.treeId]
                };

                node.children = this.getGroupChildren(group);
                treeData.push(node);
            }
        }
        self.setState({ treeData });
    }

    canDrop(args) {
        const { node, nextParent } = args;
        //console.log("canDrop:", node, nextParent);
        if (node.nodeType === 'group') {
            // groups can only be on the root
            return !nextParent;
        }
        else if (node.nodeType === 'category') {
            // categories can only be attached to a group
            return nextParent && nextParent.nodeType === 'group';
        }
        else if (node.nodeType === 'recommendation') {
            // recommendations can only be attached to a category
            return nextParent && nextParent.nodeType === 'category';
        }
        return false;
    }

    findNode(treeData, treeId) {
        let node = null;
        walk({
            treeData,
            callback: (rowInfo) => {
                //console.log("rowInfo:", rowInfo );
                if (rowInfo.node.treeId === treeId) {
                    node = rowInfo.node;
                }
            },
            getNodeKey: ({ treeIndex }) => treeIndex,
            ignoreCollapsed: true,
        });
        console.log(`findNode, treeId: ${treeId}:`, node, treeData);
        return node;
    }

    onMoveNode(args) {
        console.log("onMoveNode:", args);
        let { node, prevPath, treeData } = args;
        if (this.state.copyEnabled) {
            let { prevTreeData } = this.state;

            // copy is enabled, so when a node is moved, make a copy of the moved node, and put
            // a copy back in it's original location. 

            let copy = null;
            if (node.nodeType === 'category') {
                copy = JSON.parse(JSON.stringify(node));
                copy.treeId = copy.category.treeId = uuidv4();
                delete copy.category._id;
            } else if (node.nodeType === 'recommendation') {
                copy = JSON.parse(JSON.stringify(node));
                copy.treeId = copy.recommendation.treeId = uuidv4();
                delete copy.recommendation._id;
            } else if (node.nodeType === 'group') {
                copy = JSON.parse(JSON.stringify(node));
                copy.treeId = copy.group.treeId = uuidv4();
                delete copy.group._id;
            }

            if (copy) {
                prevPath.pop();
                let prevParent = getNodeAtPath({
                    treeData: prevTreeData,
                    path: prevPath,
                    getNodeKey: ({ treeIndex }) => treeIndex,
                    ignoreCollapsed: true
                });
                //console.log("prevParent:", prevParent );
                let nodeIndex = prevParent ? prevParent.node.children.findIndex((e) => e.treeId === node.treeId) :
                    prevTreeData.findIndex((e) => e.treeId === node.treeId);
                if (nodeIndex >= 0) {
                    let newParent = this.findNode(treeData, prevParent.node.treeId);
                    //console.log(`Copy node, nodeIndex: ${nodeIndex}, newParent:`, newParent, "prevParent:", prevParent.node, "copy:", copy );
                    if (newParent)
                        newParent.children.splice(nodeIndex, 0, copy);
                    else
                        treeData.splice(nodeIndex, 0, copy);
                    this.onChangeTree(treeData);
                }
            }
        }
    }

    onChangeCategoryNode(node) {
        let category = node.category;
        category.recommendations = [];
        for (let i = 0; i < node.children.length; ++i) {
            let child = node.children[i];
            if (child.nodeType !== 'recommendation') continue;
            child.category = category;
            this.expanded[child.treeId] = child.expanded;
            category.recommendations.push(child.recommendation);
        }
    }

    onChangeGroupNode(node) {
        let group = node.group;
        group.categories = [];
        for (let i = 0; i < node.children.length; ++i) {
            let child = node.children[i];
            if (child.nodeType !== 'category') continue;
            child.group = group;
            this.expanded[child.treeId] = child.expanded;
            this.onChangeCategoryNode(child);
            group.categories.push(child.category);
        }
    }

    onChangeTree(treeData) {
        console.log("onChangeTree:", treeData);
        let { model } = this.state;
        model.groups = [];

        // save a copy of the previous tree data, so if we need to copy a node we can find the 
        // right location to insert
        let prevTreeData = this.state.treeData.slice();

        this.expanded = {};     // reset our expanded state
        for (let i = 0; i < treeData.length; ++i) {
            let node = treeData[i];
            if (node.nodeType !== 'group') continue;
            this.expanded[node.treeId] = node.expanded;
            this.onChangeGroupNode(node);
            model.groups.push(node.group);
        }

        this.setState({ treeData, prevTreeData });
    }

    onDeleteCategory(group, category) {
        console.log("onDeleteCategory:", group, category);
        let index = group.categories.indexOf(category);
        if (index >= 0) {
            group.categories.splice(index, 1);
            this.setState({ modified: true }, this.validateModel.bind(this));
        }
    }

    onDeleteRecommendation(category, recommendation) {
        console.log("onDeleteRecommendation:", category, recommendation);
        let index = category.recommendations.indexOf(recommendation);
        if (index >= 0) {
            category.recommendations.splice(index, 1);
            this.setState({ modified: true }, this.validateModel.bind(this));
        }
    }

    onEditRecommendation(category, recommendation) {
        console.log("onEditRecommendation:", category, recommendation);
        const self = this;
        let dialog = <EditRecommendationDialog appContext={this.props.appContext} recommendation={recommendation} onDone={(result) => {
            console.log("EditRecommendationDialog done:", result);
            for (let k in result) {
                recommendation[k] = result[k];
            }
            self.setState({ dialog: null, modified: true }, self.loadRecommendations.bind(self));
        }} onCancel={() => {
            self.setState({ dialog: null });
        }} />;

        this.setState({ dialog });
    }

    getNodeProps(args) {
        // console.log("getNodeProps:", args );
        let { node } = args;

        let title = node.text;
        let subtitle = null;
        let buttons = [];

        if (node.nodeType === 'group') {
            buttons.push(<IconButton onClick={this.onEditGroup.bind(this, this.state.model, node.group)}><CreateIcon /></IconButton>);
            buttons.push(<IconButton onClick={this.onAddCategory.bind(this, node.group)}><AddIcon /></IconButton>);
            buttons.push(<IconButton key='delete' onClick={this.onDeleteGroup.bind(this, node.group)}><DeleteIcon /></IconButton>);
        }
        else if (node.nodeType === 'category') {
            buttons.push(<IconButton key='edit' onClick={this.onEditCategory.bind(this, node.category)}><EditIcon /></IconButton>);
            buttons.push(<IconButton onClick={this.onAddRecommentation.bind(this, node.category)}><AddIcon /></IconButton>);
            buttons.push(<IconButton key='delete' onClick={this.onDeleteCategory.bind(this, node.group, node.category)}><DeleteIcon /></IconButton>);
        }
        else if (node.nodeType === 'recommendation') {
            buttons.push(<div key='edit' style={{ fontSize: '12px' }}>{node.recommendation.weight}<IconButton onClick={this.onEditRecommendation.bind(this, node.category, node.recommendation)}><CreateIcon /></IconButton></div>);
            buttons.push(<IconButton key='delete' onClick={this.onDeleteRecommendation.bind(this, node.category, node.recommendation)}><DeleteIcon /></IconButton>);
            if (node.recommendation && node.recommendation.explanation)
                subtitle = node.recommendation.explanation
        }

        let style = node.error ? { height: 40, width: 500, maxWidth: 500, border: 0, boxShadow: `0 0 0 2px tomato`, cursor: 'pointer' }
            : { height: 40, width: 500, maxWidth: 500, border: 0, cursor: 'pointer' };

        return {
            listIndex: 0,
            lowerSiblingCounts: [],
            style,
            title: (<div style={{ fontSize: 16, maxWidth: "500px", border: 0, overflow: 'hidden', fontWeight: 'normal' }}
            >{title} </div>),
            subtitle,
            buttons
        };
    }

    showBadCategories() {
        const self = this
        const { badCategories } = this.state

        let badCatItems = []
        badCategories.forEach((item) => {
            const badItem = <TableRow key={badCatItems.length}>
                <TableCell>
                    {item.group}
                </TableCell>
                <TableCell>
                    {item.type}
                </TableCell>
                <TableCell>
                    {item.category}
                </TableCell>
            </TableRow>
            badCatItems.push(badItem)
        })
        var dialog =
            <Dialog
                maxWidth='lg'
                model="false"
                open={true}>
                <DialogTitle>Missing Categories</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Group</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Category</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {badCatItems}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={(e) => {
                        self.setState({ dialog: null })
                    }}>Okay</Button>
                </DialogActions>
            </Dialog>
        this.setState({ dialog: dialog });
    }

    showBadRecommendations() {
        const self = this
        const { badRecommendations } = this.state
        let badRecItems = []
        badRecommendations.forEach((item) => {
            const badItem =
                <TableRow>
                    <TableCell>
                        {item.name}
                    </TableCell>
                    <TableCell>
                        {item.group}
                    </TableCell>
                    <TableCell>
                        {item.type}
                    </TableCell>
                    <TableCell>
                        {item.category}
                    </TableCell>
                    <TableCell>
                        {item.recommendationId}
                    </TableCell>
                </TableRow >
            badRecItems.push(badItem)
        })
        let dialog = <Dialog
            maxWidth='lg'
            model="false"
            open={true}>
            <DialogTitle>Missing Recommendations</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Group</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>recommendationId</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {badRecItems}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ dialog: null })
                }}>Okay</Button>
            </DialogActions>
        </Dialog>
        this.setState({ dialog: dialog });
    }

    clearRecentlyChanged() {
        let { model } = this.state;
        let groups = model.groups || [];
        groups.forEach(group => {
            let categories = group.categories || [];
            categories.forEach(category => {
                let recommendations = category.recommendations || [];
                recommendations.forEach(recommendation => {
                    recommendation.protocol = 'none';
                });
            });
        });
        this.setState({ model });
    }

    render() {
        const self = this;
        const { treeData, dialog, model, drawerOpen, progress, error, copyEnabled, wideDrawer, badRecommendations, badCategories } = this.state;
        const { appContext } = this.props;

        let drawer = null;
        drawer = <Drawer variant="permanent" anchor="right" open={drawerOpen} onClose={() => { self.setState({ drawerOpen: !drawerOpen }) }}>
            <div style={{ display: drawerOpen ? 'block' : 'none' }} align="left"><IconButton onClick={() => { self.setState({ drawerOpen: !drawerOpen }) }}><ChevronRightIcon /></IconButton></div>
            <div style={{ display: drawerOpen ? 'none' : 'block' }} align="left"><IconButton onClick={() => { self.setState({ drawerOpen: !drawerOpen }) }}><ChevronLeftIcon /></IconButton></div>
            <div style={{ width: wideDrawer ? '60vw' : '40vw', display: drawerOpen ? 'block' : 'none' }}>
                <RecommendationTypesView appContext={appContext} editMode={true} externalNodeType={'recommendations'} onChange={self.loadRecommendations.bind(self)} />
            </div>
        </Drawer>;

        let title = model ? model.name : "";
        let sortableTree = treeData ?
            <div style={styles.treeWrap} valign='top'>
                <SortableTree
                    treeData={treeData}
                    isVirtualized={true}
                    maxDepth={3}
                    theme={FileExplorerTheme}
                    dndType={'recommendation'}
                    canDrop={this.canDrop.bind(this)}
                    onChange={this.onChangeTree.bind(this)}
                    onMoveNode={this.onMoveNode.bind(this)}
                    generateNodeProps={this.getNodeProps.bind(this)} />
            </div> : null;

        let showRecWarning = () => {
            if (badRecommendations.length > 0)
                return (
                    <Button
                        style={styles.recWarning}
                        variant="contained"
                        color="secondary"
                        onClick={this.showBadRecommendations.bind(this)}
                    ><WarningIcon style={styles.warningIcon} />Missing Recommendations</Button>
                )
        }
        let showCatWarning = () => {
            if (badCategories.length > 0)
                return (
                    <Button
                        style={styles.recWarning}
                        variant="contained"
                        color="secondary"
                        onClick={this.showBadCategories.bind(this)}
                    ><WarningIcon style={styles.warningIcon} />Missing Categories</Button>
                )

        }
        let disableDelete = model ? model.planId !== appContext.state.plan.planId : true;
        let deleteModel = this.props.onClose ? <Tooltip title='Delete model'><span><IconButton disabled={disableDelete} onClick={this.deleteModel.bind(this)}><DeleteIcon /></IconButton></span></Tooltip> : null;
        let editModel = <table width="100%"><tbody>
            <tr >
                <td>
                    <IconButton onClick={this.onEditRecommendationModel.bind(this)}><EditIcon /></IconButton>{title}
                </td>
                <td>
                </td>
                <td align="right">
                    {showRecWarning()}
                    {showCatWarning()}
                    {error}
                    <Tooltip title='Save model'><span><IconButton disabled={progress !== null} onClick={this.saveModel.bind(this, this.validateModel.bind(this), null)}><SaveIcon /></IconButton></span></Tooltip>
                    <Tooltip title='Save To Plan'><IconButton disabled={progress !== null} onClick={this.saveToPlan.bind(this, this.validateModel.bind(this))}><SaveAsIcon /></IconButton></Tooltip>
                    <Tooltip title='Reload model'><span><IconButton disabled={progress !== null} onClick={this.loadModel.bind(this, this.state.modelId)}>{progress || <RefreshIcon />}</IconButton></span></Tooltip>
                    <Tooltip title='Show model'><span><IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton></span></Tooltip>
                    {deleteModel}
                </td>
                <td width="50"></td>
            </tr>
            <tr >
                <td>
                    <Tooltip title='Add new group'><span><IconButton onClick={this.onAddGroup.bind(this)}><AddIcon /></IconButton></span></Tooltip>
                    <Tooltip title='Expand/Collapse'><span><IconButton onClick={this.onExpandNodes.bind(this)}><ExpandIcon /></IconButton></span></Tooltip>
                </td>
                <td>
                </td>
                <td align="right">
                    <Tooltip title='Moving nodes will copy instead of move'>
                        <FormControlLabel control={<Switch checked={copyEnabled} onChange={(e) => self.setState({ copyEnabled: e.target.checked })} />}
                            label={'Copy Mode'} />
                    </Tooltip>
                    <Tooltip title='Clear Protocol'><span><IconButton onClick={() => this.clearRecentlyChanged()}>< LayersClear /></IconButton></span></Tooltip>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    {sortableTree}
                    <div style={styles.footer}>
                        {model ? <ChangedByButton appContext={appContext} primaryKey={model.modelId} collection='RecommendModelModel' /> : null}
                    </div>
                    {drawer}
                    {dialog}
                </td>
            </tr>
        </tbody></table>;

        if (this.props.onClose) {
            return (
                <div align="center" style={{ width: 1200 }} >
                    <AppBar style={styles.appBar} position="static">
                        <Toolbar>
                            <IconButton onClick={this.onCloseView.bind(this)}>
                                <NavigationClose />
                            </IconButton>
                            <Typography variant="h6" color="inherit">Recommendation Model</Typography>
                        </Toolbar>
                    </AppBar>
                    <div align="left" style={styles.div}>
                        {editModel}
                    </div>
                </div>
            );
        }
        else {
            return editModel;
        }
    }
}

const styles = {
    warningIcon: {
        marginRight: 10
    },
    recWarning: {
        marginLeft: 15,
        marginRight: 5,
        marginTop: 13
    },
    copy: {
        color: 'white',
        backgroundColor: 'tomato',
        marginLeft: 15,
        marginRight: 5,
        marginTop: 13
    },
    treeWrap: {
        height: 'calc(100vh - 265px)',
        width: '100%',
        position: 'absolute'
    },
    div: {
        margin: 10
    },
    description: {
        margin: 5,
        width: 500
    },
    footer: {
        bottom: 0,
        position: 'absolute',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    name: {
        margin: 5,
        width: 300
    },
    weight: {
        margin: 5,
        width: 50
    },
    dialogItem: {
        width: '20%',
        display: 'inline-block',
        textAlign: 'left'
    },
};

export default RecommendModelView;
