import React, { Component } from "react";

import NavigationClose from "@material-ui/icons/Close";
import PlayIcon from "@material-ui/icons/Visibility";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import CodeIcon from '@material-ui/icons/Code';

import {
    AppBar,
    IconButton,
    Typography,
    TextField,
    Toolbar,
    FormControl,
    Select,
    MenuItem,
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    Dialog,
    InputLabel,
    CircularProgress,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core/";

import {
    deleteEducation,
    saveEducation,
    getReport,
    getS3Url,
    saveToS3,
} from "@apricityhealth/web-common-lib/utils/Services";

import { v4 as uuidv4 } from 'uuid';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import SelectLanguage from "@apricityhealth/web-common-lib/components/SelectLanguage";
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";
import AddCircle from "@material-ui/icons/AddCircle";

const createEducationItem = () => {
    return {
        planId: "",
        educationId: uuidv4(),
        type: "s3Video",
        title: "",
        thumbnail: "",
        content: "",
        description: "",
        order: 0,
        condition: "",
        language: 'en-us',
        educationView: false,
        tutorial: false
    };
};

class ReportContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            result: "",
        };
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (JSON.stringify(oldProps.report) !== JSON.stringify(this.props.report))
            this.loadContent();
    }

    loadContent() {
        try {
            const { reportName, recipe, args } = this.state.report;
            if (!reportName) throw new Error("reportName not found in content!");
            if (!recipe) throw new Error("recipe not found in content!");
            if (!args) throw new Error("args not found in content!");
            getReport(this.props.appContext, reportName, recipe, args)
                .then((result) => {
                    this.setState({ result });
                })
                .catch((err) => {
                    this.setState({ result: getErrorMessage(err) });
                });
        } catch (err) {
            this.setState({ result: getErrorMessage(err) });
        }
    }

    render() {
        return <div dangerouslySetInnerHTML={{ __html: this.state.result }} />;
    }
}

class EducationItemView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            education: JSON.parse(JSON.stringify(this.props.education || createEducationItem())),
            progress: null,
            modified: false,
            dialog: null,
            s3Content: []
        };
        console.log("EducationItemView:", this.state, this.props);
    }

    componentDidUpdate(oldProps) {
        console.log("componentDidUpdate:", oldProps, this.props);
        if (JSON.stringify(oldProps.education) !== JSON.stringify(this.props.education)) {
            if (this.state.modified === true) {
                this.onDisplayModifiedDialog(
                    this.componentDidUpdate.bind(this, oldProps)
                );
            } else {
                this.setState({
                    education: JSON.parse(JSON.stringify(this.props.education || createEducationItem())),
                });
            }
        }
    }

    onDone() {
        if (this.state.modified === true) {
            this.onDisplayModifiedDialog(this.onDone.bind(this));
        } else if (this.props.onDone) {
            this.props.onDone();
        }
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    onSave(callback, planId) {
        const { appContext } = this.props;
        const { education, s3Content } = this.state;

        console.log("onSave:", planId, education, appContext.state.plan);
        if (!planId && education.planId && education.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(education.planId, this.onSave.bind(this, callback));
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        const promises = [];

        if (s3Content.length > 0) {
            for (let i = 0; i < s3Content.length; ++i) {
                const content = s3Content[i];
                promises.push(saveToS3(content.url, content.file));
            }
        }

        return Promise.all(promises)
            .then((uploads) => {
                console.log("uploads done", uploads);
                this.setState({s3Content: []});
                return saveEducation(this.props.appContext, education, planId);
            })
            .then((result) => {
                this.setState({ modified: false, progress: null, education: result }, callback);
            })
            .catch((err) => {
                this.setState({ progress: null, error: getErrorMessage(err) });
            });
    }

    onConfirmDelete() {
        this.setState({
            dialog: (
                <Dialog open={true}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        Please confirm you want to delete this education item?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onDelete.bind(this)}>Confirm</Button>
                        <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            ),
        });
    }

    onDelete() {
        const { education } = this.state;
        if (education.educationId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            deleteEducation(this.props.appContext, education)
                .then(() => {
                    this.props.onDone();
                })
                .catch((err) => {
                    this.setState({ progress: null, error: getErrorMessage(err) });
                });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onPreview() {
        const self = this;
        const { education } = self.state;

        function getPreview() {
            if (education.type === "youTube") {
                return (
                    <span>
                        <div style={styles.videoPreview}>
                            <iframe
                                title="Embed Preview"
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                                width="320"
                                height="180"
                                type="text/html"
                                src={`https://www.youtube.com/embed/${education.content}?autoplay=1`}
                            ></iframe>
                        </div>
                    </span>
                );
            }
            if (education.type === "s3Video") {
                return (
                    <span>
                        <div style={styles.videoPreview}>
                            <video width="320" height="180" controls>
                                <source
                                    src={`https://s3.amazonaws.com/${education.content}`}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                    </span>
                );
            } else if (education.type === "html") {
                return (
                    <span>
                        <div dangerouslySetInnerHTML={{ __html: education.content }} />
                    </span>
                );
            } else if (education.type === "report") {
                try {
                    return (
                        <span>
                            <ReportContent
                                appContext={self.props.appContext}
                                report={JSON.parse(education.content)}
                            />
                        </span>
                    );
                } catch (err) {
                    return (
                        <span style={{ color: "red" }}>ERROR: {getErrorMessage(err)}</span>
                    );
                }
            } else {
                return <span>{`Preview not supported: ${education.type}`}</span>;
            }
        }

        const dialog = (
            <Dialog maxWidth={"md"} open={true}>
                <DialogTitle>Preview</DialogTitle>
                <DialogContent style={{ width: "850px" }}>
                    <div
                        style={{
                            backgroundColor: "#29a744",
                            color: "white",
                            fontSize: 15,
                            margin: 5,
                            padding: 10,
                            textAlign: "center",
                        }}
                    >
                        {education.title}
                    </div>
                    <div style={{ padding: 20 }}>
                        {getPreview()}
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {education.description}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCloseDialog.bind(this)}>Ok</Button>
                </DialogActions>
            </Dialog>
        );
        this.setState({ dialog });
    }

    onPreviewImage(url) {
        const dialog = (
            <Dialog maxWidth={"md"} open={true}>
                <DialogTitle>Image Preview</DialogTitle>
                <DialogContent style={{ width: "850px" }}>
                    <div align='center'>
                        <img alt={url}
                            width="800"
                            src={`https://s3.amazonaws.com/${url}`}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCloseDialog.bind(this)}>Ok</Button>
                </DialogActions>
            </Dialog>
        );
        this.setState({ dialog });
    }

    onDisplayModifiedDialog(callback) {
        const dialog = (
            <Dialog open={true}>
                <DialogTitle>Education Modified</DialogTitle>
                <DialogContent>
                    This content has been modified, would you like to save any changes?
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        style={styles.button}
                        onClick={(e) => {
                            this.onSave(callback);
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="contained"
                        style={styles.button}
                        onClick={(e) => {
                            this.setState({ modified: false, dialog: null }, callback);
                        }}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );

        this.setState({ dialog });
    }

    onChange(prop) {
        return (e) => {
            const { education } = this.state;
            education[prop] = e.target.value;
            this.setState({ education, modified: true });
        };
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { progress, education, dialog, error, s3Content } = this.state;

        return (
            <div align="center">
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={this.onDone.bind(this)}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">
                            Edit Education
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={styles.mainBody} align="center">
                    <div align="left" style={styles.div}>
                        <div style={styles.buttonHeader}>
                            <div>ID: {education.educationId}</div>
                            <div valign="top">
                                <span style={{ color: 'red' }}>{error}</span>
                                <IconButton
                                    disabled={progress !== null}
                                    onClick={() => this.onSave()}
                                >
                                    {progress || <SaveIcon />}
                                </IconButton>
                                <IconButton onClick={() => {
                                    this.setState({
                                        dialog: <JsonDialog
                                            appContext={this.props.appContext}
                                            dataType={education}
                                            onEditDone={(education) => {
                                                this.setState({education, dialog: null});
                                            }}
                                            onDone={() => {
                                                this.setState({ dialog: null });
                                            }} />
                                    });
                                }}><CodeIcon /></IconButton>
                                <IconButton onClick={this.onConfirmDelete.bind(this)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                        <FormControlLabel style={{ margin: 5 }} label='Education View'
                            control={<Checkbox checked={education.educationView} onChange={(e, v) => {
                                education.educationView = v;
                                this.setState({ education, modified: true });
                            }} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Tutorial'
                            control={<Checkbox checked={education.tutorial} onChange={(e, v) => {
                                education.tutorial = v;
                                this.setState({ education, modified: true });
                            }} />} />
                        <br />
                        <SelectLanguage value={education.language} onChange={(language) => {
                            education.language = language;
                            this.setState({ education, modified: true });
                        }} />
                        <FormControl style={{ margin: 5, width: 150 }}>
                            <InputLabel>Education Type</InputLabel>
                            <Select
                                placeholder=""
                                value={education.type}
                                onChange={this.onChange("type")}
                            >
                                <MenuItem value={"s3Video"}>S3 Video</MenuItem>
                                <MenuItem value={"youTube"}>YouTube</MenuItem>
                                <MenuItem value={"html"}>HTML</MenuItem>
                                <MenuItem value={"report"}>Report</MenuItem>
                                <MenuItem value={"slides"}>Slide Show</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            style={{ margin: 5, width: 100 }}
                            type="number"
                            label="Order"
                            value={education.order}
                            onChange={this.onChange("order")}
                        />
                        <br />
                        <TextField
                            style={{ margin: 5, width: 300 }}
                            label="Title"
                            value={education.title}
                            onChange={this.onChange("title")}
                        />
                        <br />
                        <TextField
                            style={{ margin: 5, width: '95%' }}
                            label="Description"
                            multiline
                            rows={2}
                            value={education.description}
                            onChange={this.onChange("description")}
                        />
                        <br />
                        <div>{this.renderContentField()}</div>
                        <div>
                            <FileUpload
                                accept=".png,.jpg,.jpeg"
                                value={education.thumbnail}
                                pending={s3Content.reduce((p,c) => {
                                    if (c.id === education.thumbnail) {
                                        p = `Upload pending for ${c.file.name}`;
                                    }
                                    return p;
                                }, undefined)}
                                label={"Thumbnail Image"}
                                onChange={(e) => {
                                    if (e.target.files.length) {
                                        const [file] = e.target.files;
                                        this.getEducationS3Url(
                                            education.educationId,
                                            education.language,
                                            "thumbnail",
                                            file
                                        ).then((url) => {
                                            education.thumbnail = url.split("?")[0].replace(
                                                "https://s3.amazonaws.com/",
                                                ""
                                            );
                                            let existingUpload = s3Content.find((e) => e.id === education.thumbnail);
                                            if (existingUpload >= 0) {
                                                s3Content[existingUpload] = { url, file, id: education.thumbnail };
                                            } else {
                                                s3Content.push({ url, file, id: education.thumbnail });
                                            }
                                            this.setState({ s3Content, education });
                                        }).catch((error) => {
                                            console.error(error)
                                        });
                                    }
                                }}
                                onPreview={this.onPreviewImage.bind(this, education.thumbnail)}
                            />
                        </div>
                        <EditConditions
                            appContext={appContext}
                            conditions={education.condition}
                            onChange={(condition) => {
                                education.condition = condition;
                                self.setState({ education, modified: true });
                            }}
                            name={"Conditions"}
                        />
                        <br />
                        {!education.condition ? <span style={{ color: 'red' }}>A condition is required to display education content.</span> : null}
                    </div>
                    {dialog}
                </div>
            </div>
        );
    }

    /**
     *
     * @param {uuid} educationId from the db
     * @param {string} type  either 'thumbnail' or 'content'
     * @param {*} file our file to upload
     */
    getEducationS3Url(educationId, language, type, file) {
        const parts = file.name.split(".");
        const extension = parts[parts.length - 1];

        let key = `${educationId}-${type}`;
        if (language !== 'en-us')
            key += `-${language}`

        return getS3Url(this.props.appContext, {
            key,
            contentType: file.type,
            extension,
        }).then(({ url }) => (url))
    }

    renderContentField() {
        const { s3Content, education } = this.state;
        // only handling s3Video for now, think about de-coupling
        if (education.type === "s3Video") {
            return (
                <FileUpload
                    accept=".mp4,.webm"
                    value={education.content}
                    pending={s3Content.reduce((p,c) => {
                        if (c.id === education.content) {
                            p = `Upload pending for ${c.file.name}`;
                        }
                        return p;
                    }, undefined)}                    
                    label={"Video"}
                    onChange={(e) => {
                        if (e.target.files.length) {
                            const [file] = e.target.files;
                            this.getEducationS3Url(
                                education.educationId,
                                education.language,
                                "content",
                                file
                            ).then((url) => {
                                education.content = url.split("?")[0].replace(
                                    "https://s3.amazonaws.com/",
                                    ""
                                );
                                let existingUpload = s3Content.find((e) => e.id === education.content);
                                if (existingUpload >= 0) {
                                    s3Content[existingUpload] = { url, file, id: education.content };
                                } else {
                                    s3Content.push({ url, file, id: education.content });
                                }
                                this.setState({ s3Content, education });
                            }).catch((error) => {
                                console.error(error)
                            });
                        }
                    }}
                    onPreview={this.onPreview.bind(this)}
                />
            );
        } else if (education.type === 'slides') {
            let images = education.content.split(',');

            return <div>
                {images.map((e, i) => {
                    return <span style={{ display: 'flex', flexDirection: 'row' }}>
                        <FileUpload
                            accept=".png,.jpg,.jpeg"
                            value={e}
                            pending={s3Content.reduce((p,c) => {
                                if (c.id === e) {
                                    p = `Upload pending for ${c.file.name}`;
                                }
                                return p;
                            }, undefined)}                    
                            label={"Image"}
                            onChange={(e) => {
                                if (e.target.files.length) {
                                    const [file] = e.target.files;
                                    this.getEducationS3Url(
                                        education.educationId,
                                        education.language,
                                        `slide-${i}`,
                                        file
                                    ).then((url) => {
                                        const { s3Content } = this.state;
                                        let imageUrl = url.split("?")[0].replace(
                                            "https://s3.amazonaws.com/",
                                            ""
                                        );
                                        images[i] = imageUrl;

                                        education.content = images.join(',');
                                        let existingUpload = s3Content.findIndex((e) => e.id === imageUrl);
                                        if (existingUpload >= 0) {
                                            s3Content[existingUpload] = { url, file, id: imageUrl }
                                        } else {
                                            s3Content.push({ url, file, id: imageUrl });
                                        }
                                        this.setState({ s3Content, education });
                                    }).catch((error) => {
                                        console.error(error)
                                    });
                                }
                            }}
                            onPreview={this.onPreviewImage.bind(this, e)}
                        />
                        <IconButton onClick={() => {
                            let deleted = images.splice(i, 1);
                            let deleteUpload = s3Content.findIndex((e) => e.id === deleted[0]);
                            if (deleteUpload >= 0) {
                                s3Content.splice(deleteUpload, 1);
                            }
                            education.content = images.join(',');
                            this.setState({ s3Content, education });
                        }}><DeleteIcon /></IconButton>

                    </span>
                })}
                <IconButton onClick={() => {
                    images.push("");
                    education.content = images.join(',');
                    this.setState({ education });
                }}><AddCircle /></IconButton>
            </div>;
        } else if (education.type === 'youTube') {
            return (<React.Fragment>
                <TextField
                    style={{ margin: 5, width: '90%' }}
                    label="You Tube URL"
                    value={education.content}
                    onChange={this.onChange("content")}
                />
                <Tooltip title="Preview Content">
                    <IconButton onClick={this.onPreview.bind(this)}>
                        <PlayIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>);
        } else {
            return (<React.Fragment>
                <TextField
                    style={{ margin: 5, width: '90%' }}
                    label="Content"
                    multiline
                    rows={15}
                    value={education.content}
                    onChange={this.onChange("content")}
                />
                <Tooltip title="Preview Content">
                    <IconButton onClick={this.onPreview.bind(this)}>
                        <PlayIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>);
        }
    }
}
const styles = {
    mainBody: {
        padding: 10,
    },
    div: {
        margin: 10,
    },
    buttonHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    appBar: {
        width: "1000px",
        backgroundColor: "#FF9800",
    },
    videoPreview: {
        display: "flex",
        justifyContent: "space-around",
    },
};

const FileUpload = ({ onPreview, onChange, accept, label, value, pending }) => {
    const fileInput = React.useRef(null);
    return (
        <div style={{ position: "relative", padding: "16px 0px", width: 800 }}>
            <TextField
                fullWidth
                label={label}
                variant="outlined"
                value={value}
                style={{ marginRight: 92 }}
            />
            {pending && <span style={{color: 'red'}}>{pending}</span>}
            <div style={{ position: "absolute", right: 10, top: 25 }}>
                {onPreview && value && (
                    <Tooltip title="Preview Thumbnail">
                        <IconButton onClick={onPreview}>
                            <PlayIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Button
                    color="primary"
                    onClick={(e) => {
                        fileInput.current.click();
                    }}
                >
                    Upload
                </Button>
            </div>
            <input
                ref={fileInput}
                type="file"
                accept={accept}
                onChange={onChange}
                style={{ display: "none" }}
            />
        </div>
    );
};

export default EducationItemView;
