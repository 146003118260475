import React, { Component } from 'react';
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';

import {
    saveFollowup,
    deleteFollowup
} from '@apricityhealth/web-common-lib/utils/Services';

import {
    AppBar,
    Toolbar,
    Typography,
    LinearProgress,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    TextField,
    IconButton,
    Button,
} from '@material-ui/core/';
import ChangedByButton from '../components/ChangedByButton';

class FollowupTypeView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            progress: null,
            modified: false,
            followUp: JSON.parse(JSON.stringify(props.followUp)),
            dialog: null
        };
    }

    componentDidUpdate(oldProps) {
        if (oldProps.followUp) {
            if (oldProps.followUp._id !== this.props.followUp._id) {
                this.setState({
                    followUp: JSON.parse(JSON.stringify(this.props.followUp)),
                    modified: false
                });
            }
        }
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    displayModifiedDialog() {
        const self = this; let dialog = <Dialog open={true}>
            <DialogTitle>Follow up Modified!</DialogTitle>
            <DialogContent>This content has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveFollowUp();
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, this.props.onClose());
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    closeContent() {
        if (this.state.modified === true) {
            this.displayModifiedDialog();
        } else {
            this.props.onClose();
        }
    }

    onChange = type => e => {
        let { followUp, modified } = this.state;
        followUp[type] = e.target.value;
        modified = true
        this.setState({ followUp, modified });
    }

    showFollowUpJSON() {
        let { appContext } = this.props;
        let { followUp } = this.state;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={followUp}
                onEditDone={(followUp) => {
                    this.setState({ followUp, dialog: null });
                }}
                onDone={() => {
                    this.setState({ dialog: null });
                }} />
        });
    }

    saveFollowUp(planId) {
        const self = this;
        const { appContext } = this.props;
        const { followUp } = this.state;

        if (!planId && followUp.planId && followUp.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(followUp.planId, this.saveFollowUp.bind(this));
        }

        this.setState({
            progress: <LinearProgress />
        });

        saveFollowup(appContext, followUp, planId).then(function () {
            self.setState({ progress: null });
            if (self.props.onDone)
                self.props.onDone();
        }).catch(function (error) {
            console.error("Error saving Follow up  ", error);
            self.setState({ progress: error.message });
        });
    }

    deleteFollowUp() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Follow up: {`${self.state.followUp.name}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDeleteFollowUp() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDeleteFollowUp() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDeleteFollowUp() {
        this.setState({ dialog: null });
    }

    confirmDeleteFollowUp() {
        const { followUp } = this.state;
        const { appContext } = this.props
        this.setState({
            progress: <LinearProgress />
        });
        deleteFollowup(appContext, followUp).then(() => {
            this.setState({ progress: null });
            if (this.props.onDone) {
                this.props.onDone();
            }
        }).catch((error) => {
            console.error("Error deleting follow up", error);
            this.setState({ progress: error.message, dialog: null });
        });
    }

    render() {
        const self = this;
        const { progress, dialog, followUp } = this.state;
        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeContent()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Edit Follow up</Typography>
                    </Toolbar>
                </AppBar>
                {progress}
                {dialog}
                <div style={styles.mainBody} align="center" >
                    <div align="left" style={styles.div}>
                        <div style={styles.buttonHeader}>
                            <div>Followup : { }</div>
                            <div valign="top">
                                <IconButton onClick={() => { self.saveFollowUp(); }}><SaveIcon /></IconButton>
                                <IconButton onClick={() => { self.showFollowUpJSON(); }}><CodeIcon /></IconButton>
                                <IconButton onClick={() => { self.deleteFollowUp(); }}><DeleteIcon /></IconButton>
                            </div>
                        </div>
                        <br />   <br />
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            value={followUp.name}
                            onChange={self.onChange("name")}
                        />
                        <br />   <br />
                        <TextField
                            fullWidth
                            label="Description"
                            variant="outlined"
                            value={followUp.description}
                            onChange={self.onChange("description")}
                        />
                        <br />   <br />
                        <TextField
                            fullWidth
                            label="Days"
                            variant="outlined"
                            value={followUp.days}
                            onChange={self.onChange("days")}
                        />
                        <br />   <br />
                        <TextField
                            fullWidth
                            label="Category"
                            variant="outlined"
                            value={followUp.category}
                            onChange={self.onChange("category")}
                        />
                        <br />   <br />
                        <ChangedByButton appContext={this.props.appContext} primaryKey={followUp.typeId} collection='FollowUpTypeModel' />
                    </div>


                </div>
            </div>
        )
    }
}

const styles = {
    mainBody: {
        padding: 10
    },
    div: {
        margin: 10
    },
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    buttonHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

export default FollowupTypeView;