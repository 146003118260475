import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Tab, Tabs, withStyles } from '@material-ui/core/';
import {
    TextField,
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Tooltip,
    Button,
    FormLabel,
    InputLabel,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import CommitIcon from '@material-ui/icons/LibraryAdd';
import ResetIcon from '@material-ui/icons/FlipToBack';
import CodeIcon from '@material-ui/icons/Code';
import FlattenIcon from '@material-ui/icons/FlipToFront';

import CommitDialog from '../dialogs/CommitDialog';
import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import SelectMedication from '@apricityhealth/web-common-lib/components/SelectMedication';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import JsonDialog from '../dialogs/JsonDialog';

import {
    addPlan,
    savePlan,
    deletePlan,
    resetAll,
    flattenPlan
} from '@apricityhealth/web-common-lib/utils/Services'

import PubSub from 'pubsub-js'
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

class ConfirmDeleteDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            planToDelete: ''
        }
    }

    render() {
        return <Dialog
            model="false"
            open={true}>
            <DialogTitle>Delete Plan: {this.props.plan.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>Enter the plan title to delete</DialogContentText>
                <TextField
                    fullWidth
                    label="Plan"
                    variant="outlined"
                    width='30' height='50'
                    value={this.state.planToDelete}
                    onChange={(e) => { this.setState({ planToDelete: e.target.value }) }}
                />

            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
                <Button disabled={this.state.planToDelete !== this.props.plan.title} variant="contained" style={styles.button} onClick={this.props.onConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    }
}

class PlanView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            create: props.create,
            clonePlanBool: false,
            clonePlanId: '',
            plan: null,
            dependencies: [],
            medicationIds: [],
            progress: null,
            dialog: null,
            currentTab: 0,
            modified: false
        };
    }

    componentDidMount() {
        console.log("componentDidMount:", this.props.plan );
        this.setState({ plan: JSON.parse(JSON.stringify(this.props.plan)) }, this.updatePlanArrays.bind(this));
    }

    componentDidUpdate(oldProps) {
        const self = this;
        if (JSON.stringify(oldProps.plan) !== JSON.stringify(this.props.plan)) {
            console.log("componentDidUpdate:", oldProps.plan, this.props.plan);
            if (this.state.modified === true) {
                this.displayModifiedDialog(() => {
                    self.setState({ plan: JSON.parse(JSON.stringify(self.props.plan)) }, this.updatePlanArrays.bind(this))
                });
            } else {
                this.setState({ plan: JSON.parse(JSON.stringify(this.props.plan)) }, this.updatePlanArrays.bind(this))
            }
        }
    }

    updatePlanArrays() {
        const { plan } = this.state;
        if (!Array.isArray(plan.dependencies)) plan.dependencies = [];
        if (!Array.isArray(plan.medicationIds)) plan.medicationIds = [];

        let dependencies = [];
        for (let i = 0; i < plan.dependencies.length; ++i) {
            dependencies.push({ planId: plan.dependencies[i] });
        }
        let medicationIds = [];
        for (let i = 0; i < plan.medicationIds.length; ++i) {
            medicationIds.push({ medicationId: plan.medicationIds[i] });
        }

        console.log("updatePlanArrays:", dependencies, medicationIds);
        this.setState({dependencies, medicationIds})
    }

    changeClonePlanBool(e) {
        let { clonePlanBool } = this.state
        this.setState({ clonePlanBool: !clonePlanBool })
    }

    onChange = type => e => {
        let { plan } = this.state;
        plan[type] = e.target.value;
        this.setState({ plan, modified: true });
    }

    onCloseView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else {
            this.props.onClose();
        }
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Plan Modified!</DialogTitle>
            <DialogContent>This plan has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ dialog: null });
                    self.savePlan(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onCommit() {
        let { plan } = this.state;
        const self = this;
        self.setState({
            dialog: <CommitDialog
                contentId={plan.contentId}
                planId={plan.planId}
                appContext={this.props.appContext}
                onDone={() => {
                    self.onCloseDialog();
                }} />
        });
    }

    onReset() {
        const self = this;
        self.setState({
            dialog: <Dialog model="false" open={true}>
                <DialogTitle ><FormLabel style={{ color: 'red', fontSize: '30px' }}> Reset</FormLabel></DialogTitle>
                <DialogContent>
                    <DialogContentText><span style={{ color: 'red', fontSize: '20px' }}>Warning:</span>
                        This will reset all changes back to the last known state from development. All pending changes will be overwritten. <p />Are you sure?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { this.resetCommit() }}>Yes</Button>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { this.setState({ dialog: null, anchorEl: null }) }}>No</Button>
                </DialogActions>
            </Dialog>
        });
    }

    resetCommit() {
        const self = this;
        const { plan } = this.state;

        this.setState({
            progress: <CircularProgress size={20} />, error: null
        });
        resetAll(this.props.appContext, plan.contentId, plan.planId).then((response) => {
            self.setState({ progress: null, dialog: null });
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    deletePlan() {
        const { plan } = this.state;
        const { appContext } = this.props;

        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        deletePlan(appContext, plan.planId).then(() => {
            PubSub.publish('PLAN_TOPIC', { action: 'PlanUpdated', plan });
            this.setState({ progress: null }, this.onCloseView.bind(this));
        }).catch((error) => {
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    confirmDeletePlan() {
        this.setState({
            dialog: <ConfirmDeleteDialog plan={this.state.plan} onCancel={this.onCloseDialog.bind(this)} onConfirm={this.deletePlan.bind(this)} />
        });
    }

    flattenPlan() {
        const self = this;
        const { plan } = this.state;
        const { appContext } = this.props;

        this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null });
        flattenPlan(appContext, `Flattend plan ${plan.title}.`, plan.planId).then(({asyncId}) => {
            let pending = false;
            let flattenPlanTimer = setInterval(() => {
                if ( pending ) return;
                pending = true;
                flattenPlan( appContext, null, plan.planId, false, asyncId).then((job) => {
                    if ( job.status !== 'active') {
                        clearInterval(flattenPlanTimer);

                        const jobResult = JSON.parse(job.result);
                        if ( job.status === 'error' ) {
                            self.setState({progress: null, error: getErrorMessage(jobResult)});
                        } else {
                            self.setState({ plan: jobResult, progress: null });
                            PubSub.publish('PLAN_TOPIC', { action: 'PlanUpdated', plan });
                        }
                    }
                    pending = false;
                }).catch((err) => {
                    clearInterval( flattenPlanTimer );
                    this.setState({progress: null, error: getErrorMessage(err)})
                })
            }, 1000 );
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    confirmFlattenPlan() {
        const self = this;
        let { plan } = this.state;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Flatten Plan: {plan.title}</DialogTitle>
                    <DialogContent>
                        Please confirm you want to flatten this plan, all dependencies will be merged into this plan and cannot be undone?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.onCloseDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.flattenPlan() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    savePlan(callback) {
        const self = this;
        const { plan, clonePlanBool, clonePlanId, create } = self.state;
        let { appContext } = this.props;

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        // note, setting the answers state here since we don't want a new answer to disappear while we are saving
        if (create) {
            if (clonePlanBool && clonePlanId) {
                addPlan(appContext, plan, clonePlanId).then(() => {
                    PubSub.publish('PLAN_TOPIC', { action: 'PlanUpdated', plan });
                    self.setState({ progress: null, modified: false }, callback);
                }).catch((error) => {
                    self.setState({ progress: null, error: getErrorMessage(error) });
                });
            } else {
                addPlan(appContext, plan).then(() => {
                    PubSub.publish('PLAN_TOPIC', { action: 'PlanUpdated', plan });
                    self.setState({ progress: null, modified: false }, callback);
                }).catch((error) => {
                    self.setState({ progress: null, error: getErrorMessage(error) });
                });
            }
        } else {
            savePlan(appContext, plan).then(() => {
                PubSub.publish('PLAN_TOPIC', { action: 'PlanUpdated', plan });
                self.setState({ progress: null, modified: false }, callback);
            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    render() {
        let { plan, dependencies, medicationIds, create, clonePlanBool, clonePlanId, error, progress, dialog, currentTab } = this.state;
        if (! plan ) return null;
        
        let selectPlanToClone = () => {
            if (clonePlanBool) {
                return <SelectPlan appContext={this.props.appContext} planId={clonePlanId} onChange={(plan) => {
                    this.setState({ clonePlanId: plan ? plan.planId : '' })
                }} />
            } else {
                return null;
            }
        }
        let showClone = () => {
            if (create) {
                return (
                    <FormControlLabel style={{ marginLeft: 0 }}
                        control={<Checkbox
                            checked={clonePlanBool}
                            onChange={(e) => this.changeClonePlanBool(e)}
                            value={`${clonePlanBool}`} />
                        }
                        label="Clone Plan" />
                )
            }
        }

        const columnData = [
            {
                id: 'planId', label: 'Plan', editControl: (table, value, row, index, id) => {
                    return <SelectPlan style={{ margin: 5, width: 300 }} appContext={this.props.appContext} planId={value} onChange={(plan) => {
                        if (plan && plan.planId !== value) {
                            table.setDataField(index, id, plan.planId);
                        }
                    }} />
                }
            }
        ];
        const medicationColumns = [
            {
                id: 'medicationId', label: 'Medication', editControl: (table, value, row, index, id) => {
                    return <SelectMedication style={{ margin: 5, width: 300 }} appContext={this.props.appContext} medicationId={value} onSelect={(medication) => {
                        if (medication && medication.medicationId !== value) {
                            table.setDataField(index, id, medication.medicationId);
                        }
                    }} />
                }
            }
        ];

        console.log("dependencies:", dependencies);
        let tabs = [
            <EnhancedTable key={0}
                rowsPerPage={5}
                orderBy='planId'
                columnData={columnData}
                data={dependencies}
                title='Dependencies'
                onDataChanged={(data, table) => {
                    plan.dependencies = [];
                    for (let i = 0; i < data.length; ++i) {
                        plan.dependencies.push(data[i].planId);
                    }
                    this.setState({ plan, modified: true });
                }}
            />,
            <EnhancedTable key={1}
                rowsPerPage={5}
                orderBy='medicationId'
                columnData={medicationColumns}
                data={medicationIds}
                title='Medications'
                onDataChanged={(data, table) => {
                    plan.medicationIds = [];
                    for (let i = 0; i < data.length; ++i) {
                        plan.medicationIds.push(data[i].medicationId);
                    }
                    this.setState({ plan, modified: true });
                }}
            />
        ];

        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => this.onCloseView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Edit Plan</Typography>
                    </Toolbar>
                </AppBar>
                <table style={{ width: '100%', margin: 5 }}>
                    <tbody>
                        <tr>
                            <td>
                                Plan Id: {plan.planId}<br />
                                Current Version: {plan.version}
                            </td>
                            <td align='right' valign='top'>
                                {progress}
                                <Tooltip title="Save Plan"><IconButton disabled={progress !== null} onClick={() => { this.savePlan(); }}><SaveIcon /></IconButton></Tooltip>
                                <Tooltip title='Show Code'><IconButton onClick={() => {
                                    this.setState({
                                        dialog: <JsonDialog
                                            dataType={plan}
                                            onEditDone={(plan) => {
                                                this.setState({plan, dialog: null})
                                            }}
                                            onDone={() => { 
                                                this.setState({ dialog: null }); 
                                            }} />
                                    });
                                }}><CodeIcon /></IconButton></Tooltip>
                                <Tooltip title="Commit All Changes"><IconButton disabled={progress !== null} onClick={this.onCommit.bind(this)}><CommitIcon /></IconButton></Tooltip>
                                <Tooltip title="Reset All Changes"><IconButton disabled={progress !== null} onClick={this.onReset.bind(this)}><ResetIcon /></IconButton></Tooltip>
                                <Tooltip title="Flatten Plan"><IconButton disabled={progress !== null} onClick={this.confirmFlattenPlan.bind(this)}><FlattenIcon /></IconButton></Tooltip>
                                <Tooltip title="Delete Plan"><IconButton disabled={progress !== null} onClick={this.confirmDeletePlan.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                                <br />
                                <span style={{color: 'red', maxWidth: 300}}>{error}</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <TextField style={styles.text}
                                    label="Title"
                                    value={plan.title}
                                    onChange={this.onChange("title")}
                                />
                                  <TextField style={styles.text}
                                    label="Short Title"
                                    value={plan.shortTitle}
                                    onChange={this.onChange("shortTitle")}
                                />
                                <TextField style={styles.text}
                                    label="Content Id:"
                                    value={plan.contentId}
                                    onChange={(e) => {
                                        plan.contentId = e.target.value.replace(/([^a-z0-9_-]+)/gi, '');      // strip illegial charfacters for filenames, allow _ and -
                                        this.setState({plan, modified: true });
                                    }}
                                />
                                <br />
                                <TextField style={styles.description}
                                    label="Description:"
                                    value={plan.description}
                                    multiline={true}
                                    rows={2}
                                    onChange={this.onChange("description")}
                                />
                                <br />
                                <InputLabel style={{ margin: 5 }}>Source Plan<br />
                                    <SelectPlan style={{ margin: 5, width: 300 }} enableNone={true} appContext={this.props.appContext} planId={plan.sourcePlanId} onChange={(selected) => {
                                        if (!selected) {
                                            plan.sourcePlanId = '';
                                            this.setState({ plan, modified: true });
                                        }
                                        else if (selected.planId !== plan.sourcePlanId) {
                                            plan.sourcePlanId = selected.planId;
                                            this.setState({ plan, modified: true });
                                        }
                                    }} />
                                </InputLabel>
                                <FormControlLabel style={{ margin: 5 }}
                                    control={<Checkbox
                                        checked={plan.protected}
                                        onChange={(e) => {
                                            plan.protected = e.target.checked;
                                            this.setState({ plan, modified: true });
                                        }}
                                    />
                                    }
                                    label="Protected" />
                                <FormControlLabel style={{ margin: 5 }}
                                    control={<Checkbox
                                        checked={plan.program}
                                        onChange={(e) => {
                                            plan.program = e.target.checked;
                                            this.setState({ plan, modified: true });
                                        }}
                                    />
                                    }
                                    label="Program" />
                                <FormControlLabel style={{ margin: 5 }}
                                    control={<Checkbox
                                        checked={plan.inactive}
                                        onChange={(e) => {
                                            plan.inactive = e.target.checked;
                                            this.setState({ plan, modified: true });
                                        }}
                                    />
                                    }
                                    label="Inactive" />
                                {showClone()}
                                {selectPlanToClone()}
                                <br />
                                <Tabs value={currentTab} onChange={(e, newTab) => {
                                    this.setState({ currentTab: newTab })
                                }}>
                                    <Tab value={0} label='Dependencies' />
                                    <Tab value={1} label='Medications' />
                                </Tabs>
                                {tabs[currentTab]}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {dialog}
            </div>
        )
    }
}

const styles = {
    text: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 600
    },
    mainBody: {
        padding: 10
    },
    planHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    checkboxRow: {
        display: 'flex',
        alignItems: 'center',
        margin: 5
    },
    planToCloneSelect: {
        bottom: 10,
        width: 200,
    },
    div: {
        margin: 10
    },
    appBar: {
        width: '100%',
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    }
};

PlanView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(PlanView);
