import React, { Component } from 'react';
import { 
    Drawer, 
    CircularProgress, 
    TextField, 
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import ValidIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';

import PubSub from 'pubsub-js';

import TypeView from './TypeView';
import JsonDialog from '../dialogs/JsonDialog';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import { loadTypes, saveType, deleteType } from '@apricityhealth/web-common-lib/utils/Services';

export class TypesView extends Component {
    constructor(props) {
        super(props);
        
        if (! props.typePath ) throw new Error("typePath property missing!");
        if (! props.primaryKey ) throw new Error("primaryKey property missing!");
        if (! props.typeFields || !Array.isArray(props.typeFields) ) console.warn("typeFields property is missing!");        // should be there, but not an error if they don't have it
        if (! props.title ) console.warn("title property is missing!");
        
        this.state = {
            filter: '',
            types: [],
            filtered: null,
            progress: null,
            dependencies: true
        }
   }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        })
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.typePath !== this.props.typePath) {
            this.setState({types: [], filtered: null}, this.loadContent.bind(this))
        }
    }
    
    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext, typePath } = this.props;
        const { dependencies } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null});
        loadTypes(appContext, { dependencies, typePath }).then((types)=>{
            this.setState({progress: null, types }, this.updateFilter.bind(this) );
        }).catch((err) => {
            console.error("loadContent error:", err);
            this.setState({progress: null, error: getErrorMessage(err) });
        });
    }

    testFilter(filters, type) {
        if (type) {
            const { typeFields, primaryKey } = this.props;
            const fields = typeFields || [ { id: primaryKey } ];

            for(let k=0;k<filters.length;++k) {
                const filter = filters[k];
                let found = false;
                for(let i=0;i<fields.length;++i) {
                    const field = fields[i];
                    if (! field.id ) continue;
                    if ( typeof type[field.id] !== 'string' ) continue;
                    const value = type[field.id].toLowerCase();
                    if ( value.indexOf(filter) >= 0 ) {
                        found = true;
                        break;
                    }
                }

                if (!found ) return false;      // filter not found in this type, this is a false
            }
            // all filter terms were found in this type
            return true;
        }
        return false;
    }
    
    updateFilter() {
        const { types, filter } = this.state;
        if ( filter ) {
            const normalizedFilter = filter.toLowerCase().split(' ');

            let filtered = [];
            for(let i=0;i<types.length;++i) {
                if ( this.testFilter(normalizedFilter, types[i]))
                    filtered.push( types[i] );
            }

            this.setState({filtered}, this.handleOpen.bind(this) );
        }
        else {
            this.setState({filtered: null}, this.handleOpen.bind(this));
        }
    }

    handleOpen() {
        // automatically open the question by ID if provided..
        let parts = window.location.pathname.slice(1).split('/');
        if ( parts.length > 1) {
            window.history.replaceState( null, '',  window.location.href.substring( 0, window.location.href.lastIndexOf('/') ));
            this.onOpenType( parts[1] );
        }
    }

    onCloseDialog(reload = false) {
        this.setState({ dialog: null });
        if ( reload )
            this.loadContent();
        if (this._table)
            this._table.setState({ selected: [] });        // unselect the question
    }

    typeSelected(s, t) {
        console.log("typeSelected:", s, t);
        const self = this;
        const { filtered, types } = this.state;

        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const type = filtered ? filtered[s[0]] : types[s[0]];
            this.onOpenType( type[this.props.primaryKey] );
        }
        else {
            // nothing selected..
            self.setState({ dialog: null });
        }
    }

    onOpenType(typeId) {
        const { appContext, typePath, typeFields, primaryKey, collection, onTypeView } = this.props;
        this.setState({
            dialog: <Drawer variant="persistent" anchor="right" open={true}>
                {onTypeView ? onTypeView(this, typeId) : <TypeView appContext={appContext} typePath={typePath} typeFields={typeFields} primaryKey={primaryKey} typeId={typeId} collection={collection} onClose={this.onCloseDialog.bind(this, true)} />}
            </Drawer>
        });
    }

    onCreateType() {
        const { appContext, typePath, typeFields, primaryKey, collection, onTypeView } = this.props;
        this.setState({
            dialog: <Drawer variant="persistent" anchor="right" open={true} >
                {onTypeView ? onTypeView(this) : <TypeView appContext={appContext} typePath={typePath} typeFields={typeFields} primaryKey={primaryKey} collection={collection} onClose={this.onCloseDialog.bind(this, true)} />}
            </Drawer>
        });
    }

    onPreAdd(row) {
        const { typeFields, primaryKey } = this.props;
        if (! row[primaryKey] ) row[primaryKey] = '';
        if ( Array.isArray(typeFields)) {
            for(let i=0;i<typeFields.length;++i) {
                const field = typeFields[i];
                if (! row[field.id] ) row[field.id] = field.defaultValue || '';
            }
        }
    }

    onDelete(row, index) {
        const { appContext, typePath, primaryKey } = this.props;
        if ( this.deleteQueue === undefined ) this.deleteQueue = [];
        this.deleteQueue.push(row);

        console.log("onDelete:", row, this );
        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Please confirm you want to delete {this.deleteQueue.length} rows? 
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                <Button onClick={() => {
                    this.onCloseDialog();
                    this.setState({error: null});

                    let promises = [];
                    for(let i=0;i<this.deleteQueue.length;++i) {
                        let row = this.deleteQueue[i];
                        promises.push( deleteType( appContext, { ...row, typeId: row[primaryKey], typePath } ) );
                    }
                    this.deleteQueue = [];

                    Promise.all(promises).then(() => {
                        this.loadContent();
                    }).catch((err) => {
                        this.setState( { error: getErrorMessage(err) });
                    })
                }}>Delete</Button>
            </DialogActions>
        </Dialog>;

        this.setState({dialog});
    }

    onDataChanged(data, table) {
        this.setState({types: data, modified: true}, this.updateFilter.bind(this));
    }

    onShowCode() {
        if (this._table) {
            const { selected, data } = this._table.state;
            const types = [];
            for(let i=0;i<selected.length;++i)
                types.push( data[selected[i]] );

            const { appContext } = this.props;
            this.setState({
                dialog: <JsonDialog
                    appContext={appContext}
                    dataType={types}
                    onDone={this.onCloseDialog.bind(this, false)} />
            });
        }
    }

    render() {
        const { appContext, typePath, typeFields, orderBy, title, primaryKey, disableErrorColumn, disableTypeView } = this.props;
        const { types, filter, filtered, dependencies, modified } = this.state;
        const { dialog, error, progress } = this.state;
        const errors = this.props.appContext.stores.DataTypesStore.getErrors(typePath).errors;
        const columnData = (typeFields && Array.isArray(typeFields)) ? typeFields.filter((e) => e.listField).map((e) => {
            const k = { ...e };
            if (! disableTypeView  && k.editType ) delete k.editType;
            return k; 
        }) : [{
            id: primaryKey, label: 'ID'
        }];

        if ( disableErrorColumn !== true ) {
            columnData.unshift({ id: primaryKey, width: 25, label: 'Valid', formatValue: (v) => {
                return errors[v] ? <Tooltip key='error' title={errors[v].join(', ')}><WarningIcon style={{ fill: 'red'}} /></Tooltip> : <ValidIcon key='valid' style={{ fill: 'green'}} />;
            }})
        }

        return (
            <table width='100%'>
            <tbody>
            <tr>
                <td>
                    <TextField 
                        label="Filter"
                        style={{ margin: 5, width: 500 }} 
                        value={filter} 
                        name='filter'
                        onChange={(e) => {
                            this.setState({filter: e.target.value}, this.updateFilter.bind(this) );
                        }}
                    />   
                    <FormControlLabel
                        style={{margin: 5}}
                        control={<Checkbox
                            checked={dependencies} 
                            name='dependencies' 
                            onChange={(e,v) => {
                                this.setState({dependencies: v}, this.loadContent.bind(this) );
                            }}
                        />}
                        label="Show Dependencies" 
                    />
                </td>
                <td align="right">
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <EnhancedTable
                        disableMultiSelect={disableTypeView ? false : true}
                        onTable={table => this._table = table}
                        onActions={(table, numSelected, actions) => {
                            if ( disableTypeView ) {
                                actions.unshift(<Tooltip title='Code'><IconButton disabled={numSelected <= 0} onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton></Tooltip>);
                                actions.unshift(<Tooltip key={'save'} title='Save'><IconButton disabled={!modified || progress !== null} onClick={() => {
                                    this.setState({progress: <CircularProgress size={20} />, error: null});
                                    saveType(appContext, types, { typePath }).then((result) => {
                                        this.setState({progress: null, types: result, modified: false}, this.updateFilter.bind(this));
                                    }).catch((err) => {
                                        this.setState({progress: null, error: getErrorMessage(err)});
                                    })
                                }}>{progress !== null ? progress : <SaveIcon />}</IconButton></Tooltip>)
                            }
                        }}
                        onSelected={disableTypeView ? undefined : this.typeSelected.bind(this)}
                        onAdd={disableTypeView ? undefined : this.onCreateType.bind(this)}
                        onPreAdd={disableTypeView ? this.onPreAdd.bind(this) : undefined}
                        onDelete={disableTypeView ? this.onDelete.bind(this) : undefined}
                        onDataChanged={disableTypeView ? this.onDataChanged.bind(this) : undefined}
                        orderBy={orderBy || primaryKey}
                        columnData={columnData}
                        data={filtered ? filtered : types}
                        title={title} />
                    {dialog}
                </td>
            </tr>
            </tbody>
            </table> 
        );
    }
}

export default TypesView;