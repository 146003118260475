import React from "react";
import sequential from 'promise-sequential';
import PubSub from 'pubsub-js'

import {
    Button,
    List,
    Dialog,
    DialogContent,
    DialogActions,
    LinearProgress,
    DialogTitle,
    CircularProgress
} from '@material-ui/core/';

// Local
import { isArrayValid } from '../utils/Utils';


import Plan from '@apricityhealth/web-common-lib/components/Plan';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import { deleteType } from './ContentConflictsView';

class ContentOverridesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null
        };
    }

    componentDidMount() {
        this.abort = false;
    }

    componentWillUnmount() {
        this.abort = true;
    }

    onRemoveOverrides( planId, groups, removeCount ) {
        this.setState({dialog: <Dialog open={true}>
            <DialogTitle>Confirm Remove</DialogTitle>
            <DialogContent>
                Please confirm you want to remove <b>{removeCount}</b> overrides from <b><Plan appContext={this.props.appContext} planId={planId} /></b>?<br /><br />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => this.setState({dialog: null})}>Cancel</Button>
                <Button variant="contained" onClick={this.removeOverrides.bind(this, planId, groups)}>Confirm</Button>
            </DialogActions>
            </Dialog>});
    }

    removeOverrides( planId, groups ) {
        console.log("removeOverrides:", planId, groups);

        const { appContext } = this.props;

        let promises = [];
        let promisesDone = 0;

        const updateProgressDialog = () => {
            let progress = Math.floor((promisesDone / promises.length) * 100);
            this.setState({
                dialog:
                    <Dialog open={true} maxWidth='sm' fullWidth={true}>
                        <DialogContent>
                            <LinearProgress style={{ width: '100%' }} variant='determinate' value={progress} />
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button variant="contained" onClick={() => this.abort = true}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
            });

        }
        Object.keys(groups).forEach((group) => {
            Object.keys(groups[group]).forEach((file) => {
                const keys = groups[group][file];
                keys.forEach((objectId) => {
                    promises.push(() => {
                        if ( this.abort ) throw new Error("Remove overrides aborted...");
                        promisesDone += 1;
                        updateProgressDialog();
                        return deleteType(appContext, planId, group, objectId);
                    });
                })
            })
        });

        this.abort = false;
        sequential(promises).then(() => {
            console.log("removeOverrides done.");
            this.setState({dialog: null});
            PubSub.publish('PLAN_TOPIC', { action: "RefreshPlan" });
        }).catch((err) => {
            console.error("removeOverrides error:", err );
            this.abort = true;
            this.setState({dialog: null});
            this.props.parent.setState({ error: getErrorMessage(err) })
        })
    }

    generateOverridesView( plan, overrides ) {
        console.log("overrides:", overrides );
        if (isArrayValid(overrides)) {
            let content = [];
            for(let i=0;i<overrides.length;++i) {   
                for(let j=0;j<overrides[i].length;++j) {
                    let override = overrides[i][j];
                    console.log("override:", override );

                    let removeCount = 0;
                    let groups = {};
                    override.same.forEach((file) => {
                        const changeKey = override.contentSame[file];
                        for(let k in changeKey) {
                            if (! groups[k] ) groups[k] = {};
                            if (! groups[k][file] ) groups[k][file] = [];

                            for(let i=0;i<changeKey[k].length;++i) {
                                groups[k][file].push( changeKey[k][i] );
                                removeCount += 1;
                            }
                        }
                    })

                    let rows = [];
                    for(let k in groups) {
                        rows.push(<div key={k}><h3>{k}</h3>
                            <ul>
                                {Object.keys(groups[k]).map((file,i) => {
                                    let keys = groups[k][file].join(',');
                                    if (keys.length > 128) {
                                        keys = keys.slice(0,128) + "..."
                                    }
                                    return <li key={i}>{file} {`(${keys})`}</li>
                                })}
                            </ul>
                            <br />
                        </div>);
                    }

                    if ( removeCount > 0 ) {
                        content.push(<div key={content.length}>
                            <Button style={{ margin: 5}} onClick={this.onRemoveOverrides.bind(this, plan.planId, groups, removeCount)} variant='contained'>Remove {removeCount} overrides</Button>
                            <Button style={{ margin: 5}} onClick={this.onRemoveOverrides.bind(this, override.dependencyPlanId, groups, removeCount)} variant='contained'>Keep {removeCount} overrides</Button><br /><br />
                            <h4>The following data is identical in <b><Plan appContext={this.props.appContext} planId={plan.planId} /></b> when compared to <b><Plan appContext={this.props.appContext} planId={override.dependencyPlanId} /></b>, so the override is not needed.</h4>
                            <List
                                style={{ marginTop: '10px' }}
                                height={1000}
                                width={'90%'}
                            >
                                {rows}
                            </List>
                        </div>)
                    } else {
                        content.push(<div key={content.length}>
                            <h4><b><Plan appContext={this.props.appContext} planId={plan.planId} /></b> has no overrides to remove for <b><Plan appContext={this.props.appContext} planId={override.dependencyPlanId} /></b>.</h4>
                        </div>)
                    }
                }
            }

            return <div style={{marginTop: 20}}>
                <h2>{plan.title} has {plan.dependencies.length} dependencies.</h2>
                {content}
            </div>;
        } else {
            return <div align='center'>
                <CircularProgress />
            </div>
        } 
    }

    render() {
        const { dialog } = this.state;
        const { parent: { state: { plan, overrides }}} = this.props;

        return <div>
            {this.generateOverridesView( plan, overrides)}
            {dialog}
        </div>;
    }
}

export default ContentOverridesView;

