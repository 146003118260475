import React from "react";
import {
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from '@material-ui/core/';

import SelectPlan from "@apricityhealth/web-common-lib/components/SelectPlan";

export class SaveToPlanDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planId: props.planId || props.model.planId,
            plan: props.appContext.state.plan,
            move: false
        };
    }

    render() {
        const { plan, move, planId } = this.state;
        
        let saveDisabled = plan.planId === planId;
        return <Dialog open={true}>
            <DialogTitle>Save To Plan</DialogTitle>
            <DialogContent>
                <SelectPlan appContext={this.props.appContext} planId={plan.planId} onChange={(plan) => {
                    this.setState({plan});
                }} />
                <br />
                <Tooltip title="If checked, model will be movied to the new plan instead of copied.">
                    <FormControlLabel control={<Checkbox checked={move} onChange={(e) => {
                        this.setState({move: e.target.checked});
                    }} />} label="Move" />
                </Tooltip>

            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onCancel}>Cancel</Button>
                <Button disabled={saveDisabled} onClick={() => this.props.onDone({plan, move})}>Save</Button>
            </DialogActions>
        </Dialog>;
    }
};
 
export default SaveToPlanDialog;
