import React, { Component } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Box,
    Typography
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import sequential from 'promise-sequential';
import QuestionUtils from "../utils/QuestionUtils";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

class SaveAllQuestionsDialog extends Component {
    constructor(props) {
        super(props);
        if (!props.appContext) throw new Error("appContext property missing.");
        if (!props.onDone) throw new Error("onDone property missing");
        if (!props.onCancel) throw new Error("onCancel property missing");
        this.state = {
            error: null,
            progress: null,
            cancelled: false,
            label: ""
        }
        this.questionUtils = new QuestionUtils(props.appContext, 'en-us');

    }

    onCancel() {
        this.setState({ cancelled: true }, () => this.props.onCancel());
       
    }
    progress(label, progress) {
        console.debug(`progress `, label, progress);
        this.setState({
            label: <Typography color="primary">Processing question {label}</Typography>,
            progress: <CircularProgressWithLabel value={progress} />
        });
    }

    updateDataModels(question, percentComplete) {
        const { cancelled } = this.state;
        if (cancelled)
            return Promise.reject(new Error("Update cancelled."));

        console.log(`processing question `, question.questionId)
        this.progress(question.questionId, percentComplete);
        return new Promise((resolve, reject) => {
            this.questionUtils.unlocalizeQuestion(question);
            this.questionUtils.saveQuestion(question, null, null, question.planId, true).then(() => {
                return resolve();
            }).catch((err) => {
                console.log(`error `, getErrorMessage(err))
                this.setState({ progress: null, error: getErrorMessage(err) });
                return reject(err);
            });
        })

    }

    onDone() {
        const questions = this.props.questions;
        let promises = [];
        for (let index = 0; index < questions.length; index++) {
            const question = questions[index];
            promises.push(() => this.updateDataModels(question, (index / questions.length) * 100))
        }

        sequential(promises).then(() => {
            this.setState({
                label: ``,
                progress: null
            });
            this.props.onDone();
        }).catch((err) => {
            console.log(`error `, getErrorMessage(err))
            this.setState({ cancel: false, progress: null, error: getErrorMessage(err) });
        })
      
    }

    render() {
        let { questions } = this.props;
        let { error, progress, label } = this.state;
        return (
            <Dialog open={true} maxWidth='md' fullWidth={true} onClose={() => this.onCancel()}>
                <DialogTitle>Save All Questions</DialogTitle>
                <DialogContent>
                    {error}{progress}{label}<br />
                    This will save <strong>{questions.length}</strong> questions for the slected plan. <br />
                    This will <strong>not</strong> save questions or types to the dependent plans.<br />
                    <br />
                    The following activities will occur when you press save:<br />
                    <br />
                    <ul>
                        <li>Save <strong>{questions.length}</strong> questions and answers on the respective plan (will <strong>not</strong> save to dependent plan)</li>
                        <li>Updates the data types used by the question or any answers:
                            <ul>
                                <li>For each data type, finds the score tuple and then...</li>
                                <li>Adds a tuple value decription for each mapping on the question (if there is a mapping).</li>
                                <li>Adds a 'no answer' tuple value description if there is a noAnswer option</li>
                                <li>Adds a tuple value description for each answer, incuding the answer text and score value</li>
                            </ul>
                        </li>
                        <li>Updates models that use the data types for any of the symptoms (trigger, primary, etc):
                            <ul>
                                <li>Each symptom's values array will be set to the newly updated value descriptions array from the data type. (this is a 1-1 so the values array should be the same as the value descriptions array)</li>
                            </ul>
                        </li>
                        <li>Updates the model data type:</li>
                        <ul>
                            <li>The tuple descriptions on the 'model' data type will reflect each symptom used in the model.</li>
                        </ul>
                        <li>Updates any model tuple indexes that were changed as a result of the above.</li>
                    </ul>
                    This will take time to process <strong>{questions.length}</strong> questions. This dialog will remain open. Press <strong><i>Cancel</i></strong> to cancel at any time.<br />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={progress !== null} style={styles.button} onClick={() => this.onDone()}>Save</Button>
                    <Button variant="contained" style={styles.button} onClick={() => this.onCancel()}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const styles = {
    div: {
        margin: 10
    },
    button: {
        margin: 10
    }
};

export default SaveAllQuestionsDialog;
