import React from 'react';
import { Drawer,
    CircularProgress, 
    TextField, 
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import RefreshIcon from '@material-ui/icons/Refresh';
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import RuleView from './RuleView';

import {
    loadRules
} from '@apricityhealth/web-common-lib/utils/Services';

import PubSub from 'pubsub-js'

function filterRule(filter, rule) {
    if (rule.ruleId && rule.ruleId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (rule.name && rule.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;

    return false;
}

export class RulesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            rules: []
        }
    }
    
    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.loadContent();
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        let { appContext } = this.props;
        let { dependencies } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadRules(appContext, { dependencies } ).then((rules) => {
            self.setState({ rules, progress: null }, self.updateFilter.bind(self));
        }).catch((err) => {
            self.setState({ progress: null, error: err.message });
        });
    }
    updateFilter() {
        let { filter, rules } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < rules.length; ++i) {
                if (filterRule(filter, rules[i]))
                    filtered.push(rules[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });
    }

    selectRule(s, t) {
        console.log("selectRule:", s, t);
        if (s.length > 0) {
            const rule = this.state.filtered ? this.state.filtered[s[0]] : this.state.rules[s[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <RuleView appContext={this.props.appContext} rule={rule} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    createRule() {
        console.log("createRule");
        let newRule = { name: '', ruleId: '', ruleType: '', priority: 0, condition: '', addData: [], addModels: [], addRequiredData: [], 
            addOptionalData: [], addConditions: [], removeConditions: [], addFlags: [], removeFlags: [], ownerId: '' };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <RuleView appContext={this.props.appContext} rule={newRule} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    render() {
        const self = this;
        let { filter, filtered, rules, dialog, error, progress, dependencies } = this.state;

        const columnData = [
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'priority', numeric: false, disabledPadding: false, label: 'Priority' },
            { id: 'ruleType', numeric: false, disabledPadding: false, label: 'Type' },
            { id: 'ruleId', numeric: false, disabledPadding: false, label: 'Rule ID' },
            { id: 'condition', numeric: false, disabledPadding: false, label: 'Condition' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={styles.text} value={filter} label="Filter"
                            onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._types = table}
                            onSelected={this.selectRule.bind(this)}
                            onAdd={this.createRule.bind(this)}
                            orderBy='name'
                            columnData={columnData}
                            data={filtered ? filtered : rules}
                            title='Rules' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 500
    },
}

export default RulesView;
