import React, { Component } from "react";
import { withStyles, CircularProgress } from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';
import { v4 as uuidv4 } from 'uuid';
import PubSub from 'pubsub-js'

import {
  Drawer,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@material-ui/core/';

import {
  loadFollowups
} from '@apricityhealth/web-common-lib/utils/Services';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import FollowupTypeView from './FollowupTypeView'

class FollowupTypesView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dependencies: true,
            filter: "",
            followups: [],
            dialog: null,
            messages: props.appContext.state.messages,
            expanded: null,
            plan:null
        };


    }

    componentDidMount(){
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }
    
    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }
      
    loadContent() {
        const self = this;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadFollowups(this.props.appContext, { dependencies: this.state.dependencies} ).then((followups) => {
            self.setState({ followups, filtered: null, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    onEditFollowup(index, table) {
        let self = this;
        if (index.length && table.props && table.props.data){
            let followUp = table.props.data[index]
            const { appContext } = this.props;
            let dialog = <Drawer variant="persistent" anchor="right" open={true} >
                <FollowupTypeView appContext={appContext} followUp={followUp} onClose={() => self.cancel()} onDone={() => self.onCloseDialog()} />
            </Drawer>;
            this.setState({ dialog });
        }else{
          self.setState({ dialog: null });
        }
    }

    onCloseDialog(){
        this.setState({ dialog: null }, this.loadContent.bind(this))
        if (this._plans)
            this._plans.setState({ selected: [] });        // unselect the question
    }

    cancel() {
        this.setState({ dialog: null });
        if (this._plans)
            this._plans.setState({ selected: [] });        // unselect the question
    }

    createNewFollowUp() {
        let self = this;
        const { appContext } = this.props;
        let newFollowUp = {
            name: "New Followup",
            description: "description",
            category: "Category",
            typeId: uuidv4()
        }
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <FollowupTypeView appContext={appContext} followUp={newFollowUp} onClose={() => self.cancel()} onDone={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ dialog });
    }

    render(){
        const self = this;
        let { error, progress, followups, dialog, dependencies } = self.state;

        const columnData = [
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width='100%'><tbody>
                <tr>
                    <td>
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._plans = table}
                            orderBy='name'
                            columnData={columnData}
                            onSelected={(s, t) => {self.onEditFollowup(s, t)}}
                            data={followups}
                            title='FollowUps'
                            onAdd={()=> this.createNewFollowUp()}
                        />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );

        // return(
        //     <div>
        //         {progress}
        //         <div align='left' style={styles.buttonHeader} >
        //             <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
        //                     self.setState({dependencies: v}, self.loadContent.bind(self));
        //                 }} />}label="Show Dependencies" />
        //             <div style={styles.spacer}></div> 
        //             <IconButton onClick={this.loadContent.bind(this)}><RefreshIcon /></IconButton>
        //         </div>
        //             {dialog}
        //         <EnhancedTable
        //             disableMultiSelect={true}
        //             onTable={table => self._plans = table}
        //             orderBy='name'
        //             columnData={columnData}
        //             onSelected={(s, t) => {self.onEditFollowup(s, t)}}
        //             data={followups}
        //             title='FollowUps'
        //             onAdd={()=> this.createNewFollowUp()}
        //         />
        //     </div> 
        // )
    }
}

const styles = {
    div: {
      margin: 10
    },
    buttonHeader:{
      display:'flex',
      justifyContent:'flex-start',
      align:'center',
      flexWrap:'wrap',
      marginLeft:20,
      marginTop:10,
    },
    spacer: {
        flexGrow: 1
    }
  }

export default withStyles(styles)(FollowupTypesView);