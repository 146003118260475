import React, { Component } from 'react';
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@material-ui/core/';

const ACTIVITY_TYPES = require("../data/ActivityTypes.json");

export class SelectActivityType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activityType: props.activityType || '',
            items: []
        }
    }
    componentDidMount() {
        this.updateTypes();
    }

    handleChange(v) {
        this.setState({activityType: v}, () => {
            if (this.props.onChange)
                this.props.onChange(v);
        })
    }

    updateTypes() {
        const { enableNone } = this.props;
        let items = [];
        if (enableNone)
            items.push(<MenuItem key={'none'} value={''}>None</MenuItem>);
        for (let i = 0; i < ACTIVITY_TYPES.length; ++i) {
            const t = ACTIVITY_TYPES[i];
            items.push(<MenuItem key={i} value={t}>{t}</MenuItem>);
        }
        this.setState({ items });
    }

    render() {
        const { label } = this.props;
        const { items, activityType } = this.state;

        return <div>
            <FormControl style={{ margin: 5, width: 300 }}>
                <InputLabel>{label ? label : 'Activity Type'}</InputLabel>
                <Select value={activityType} onChange={(e) => this.handleChange(e.target.value)}>
                    {items}
                </Select>
            </FormControl>
        </div>;
    }
}

export default SelectActivityType;
