import React from "react";
import {
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
} from '@material-ui/core/';

import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex from "@apricityhealth/web-common-lib/components/SelectTupleIndex";
import SelectUnit from "@apricityhealth/web-common-lib/components/SelectUnit";
import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";

import 'react-perfect-scrollbar/dist/css/styles.css';
import '../styles/workup.css';

export class EditSymptomDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            symptom: JSON.parse(JSON.stringify(props.symptom))     // make a copy 
        }
    }

    onDone() {
        this.props.onDone(this.state.symptom);
    }

    onChange(field, e) {
        let { symptom } = this.state;
        if (symptom) {
            symptom[field] = e.target ? e.target.value : e;
            this.setState({ symptom });
        }
    }

    render() {
        const self = this;
        let { symptom } = this.state;
        return <Dialog open={true}>
            <DialogTitle>Edit Symptom</DialogTitle>
            <DialogContent>
                <TextField label="Description" style={styles.description} value={symptom.description}
                    onChange={this.onChange.bind(this, 'description')} />
                <br />
                <SelectDataType appContext={this.props.appContext} dataId={symptom.dataId} onSelect={(dataType) => {
                    symptom.dataId = dataType ? dataType.dataId : '';
                    self.setState({ symptom });
                }} />
                <br />
                <SelectTupleIndex appContext={this.props.appContext} dataId={symptom.dataId} tupleIndex={symptom.tupleIndex}
                    onChange={(tupleIndex, dataType) => {
                        symptom.tupleIndex = tupleIndex;
                        symptom.description = dataType ? dataType.tupleDescriptions[tupleIndex].description : '';
                        self.setState({ symptom });
                    }} />
                <br />
                <TextField label="Weight" style={styles.number} value={symptom.weight} type='number'
                    onChange={this.onChange.bind(this, 'weight')} />
                <br />
                <TextField label="Time Window (Hours)" style={styles.number} value={symptom.timeWindow} type='number'
                    onChange={this.onChange.bind(this, 'timeWindow')} />
                <br />
                <SelectUnit style={styles.number} value={symptom.unit} onChange={this.onChange.bind(this,'unit')} enableNone={true} />
                {symptom.unit ? <SelectTupleIndex appContext={this.props.appContext} dataId={symptom.dataId} label={'Unit Tuple Index'} tupleIndex={symptom.unitTupleIndex}
                    onChange={(tupleIndex) => {
                        symptom.unitTupleIndex = tupleIndex;
                        self.setState({symptom})
                    }} /> : null}
                <br />
                <EditConditions
                    appContext={this.props.appContext}
                    conditions={symptom.condition}
                    onChange={(condition) => {
                        symptom.condition = condition;
                        self.setState({ symptom });
                    }}
                    name={'Conditions'}
                />
                <br />
                <Tooltip title="If true, then we will use the latest value found in the time window.">
                    <FormControlLabel control={<Checkbox checked={symptom.useLatest} onChange={(e) => {
                        symptom.useLatest = e.target.checked;
                        if ( symptom.useLatest && symptom.useLowest) symptom.useLowest = false;
                        self.setState({ symptom });
                    }} />} label="Use Latest" />
                </Tooltip>
                <Tooltip title="If true, then we will use the lowest value found in the time window.">
                    <FormControlLabel control={<Checkbox checked={symptom.useLowest} disabled={symptom.useLatest} onChange={(e) => {
                        symptom.useLowest = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Use Lowest" />
                </Tooltip>
                <Tooltip title="This forces requires the symptom to be gather, even if we already have collected the data within the time window.">
                    <FormControlLabel control={<Checkbox checked={symptom.required} onChange={(e) => {
                        symptom.required = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Required" />
                </Tooltip>
                <Tooltip title="Set to require data to be from the same data group.">
                    <FormControlLabel control={<Checkbox checked={symptom.inGroup} onChange={(e) => {
                        symptom.inGroup = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Same Day" />
                </Tooltip>
                <Tooltip title="Set to require data to be from the same session.">
                    <FormControlLabel control={<Checkbox checked={symptom.inSession} onChange={(e) => {
                        symptom.inSession = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Same Session" />
                </Tooltip>
                <Tooltip title="Store NaN in the model when data cannot be found.">
                    <FormControlLabel control={<Checkbox checked={symptom.useNAN} onChange={(e) => {
                        symptom.useNAN = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Use NaN" />
                </Tooltip>
                <Tooltip title="If true, then this will be includes in the symptom list in the complaint.">
                    <FormControlLabel control={<Checkbox checked={symptom.symptom} onChange={(e) => {
                        symptom.symptom = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Symptom" />
                </Tooltip>
                <Tooltip title="If true, then this is only added during triage of the patient.">
                    <FormControlLabel control={<Checkbox checked={symptom.triage} onChange={(e) => {
                        symptom.triage = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Triage" />
                </Tooltip>
                <Tooltip title="If true, then no questions will be triggered by this symptom.">
                    <FormControlLabel control={<Checkbox checked={symptom.disableQuestions} onChange={(e) => {
                        symptom.disableQuestions = e.target.checked;
                        self.setState({ symptom });
                    }} />} label="Disable Questions" />
                </Tooltip>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>OK</Button>
                <Button variant="contained" style={styles.button} onClick={this.props.onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
};

const styles = {
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    div: {
        margin: 5
    },
    button: {
        margin: 5
    },
    number: {
        margin: 5,
        width: 200
    },
    name: {
        margin: 5,
        width: 300
    },
    select: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

export default EditSymptomDialog;
