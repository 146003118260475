import {
    findNodeByRecommendId
} from './Utils';

function validateRecommendModel(appContext, recModel, recommendations){
    const { stores: { DataTypesStore } } = appContext;

    let badModels = []
    let badCategories = []

    //each group
    for (let i = 0; i < recModel.groups.length; ++i) {
        //todo - look for unresolved group names
        for (let j = 0; j < recModel.groups[i].categories.length; ++j) {

            //look for unresolved category names
            let categoryModel = recModel.groups[i].categories[j] 
            var recIndex = DataTypesStore.getRecommendCategories().findIndex((rec) => rec.categoryId === categoryModel.name);
            if (recIndex === -1){
                let badCategory = {}
                badCategory.group = recModel.groups[i].name
                badCategory.reason = 'missing category'
                badCategory.type = recModel.groups[i].type
                badCategory.category = recModel.groups[i].categories[j].name
                badCategories.push(badCategory) 
            }

            for (let k = 0; k < recModel.groups[i].categories[j].recommendations.length; ++k) {
                //look for unresolved recommendations
                let recommendation = recModel.groups[i].categories[j].recommendations[k] 
                let sourceRecommendation = findNodeByRecommendId(recommendations, recommendation.recommendId);
                if (sourceRecommendation === null){
                    let badModel = {} 
                    badModel.group = recModel.groups[i].name
                    badModel.reason = 'missing recommendation'
                    badModel.type = recModel.groups[i].type
                    badModel.category = recModel.groups[i].categories[j].name
                    badModel.name =  recommendation.name
                    badModel.recommendationId = recommendation.recommendId
                    badModels.push(badModel)
                }
            }
        }
    }
    let badItems = {}
    badItems.badCategories = badCategories 
    badItems.badModels = badModels 
    return (badItems)
}


function validateRecommendModels(appContext, recModels, recommendations){
    for (let i = 0; i < recModels.length; ++i) {
        let badItems = validateRecommendModel(appContext, recModels[i], recommendations)
        if (badItems.badModels.length > 0 || badItems.badCategories.length > 0){
            recModels[i].valid = false
        }else{
            recModels[i].valid = true 
        }
    }
    return recModels
}

export {validateRecommendModel, validateRecommendModels }