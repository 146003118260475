import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core/';


const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class SelectCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category
    }
  }

  static getDerivedStateFromProps(props, state){
    if (state.category !== props.category){
      return {category: props.category};
    }
  }

  handleChange(category) {
    const { onChange } = this.props;
    if (onChange)
      onChange(category);
    this.setState({ category });
  }

  render() {
    const self = this;
    const { classes, categories } = this.props;
    let { category } = this.state;

    var items = [];
    for (var i = 0; i < categories.length; ++i) {
      const cat = categories[i];
      items.push(<MenuItem key={i} value={cat}>{cat}</MenuItem>)
    }

    return (
      <FormControl  style={{margin: 5}} className={classes.formControl}>
        <InputLabel htmlFor="categories-simple">Category</InputLabel>
        <Select
          value={category}
          onChange={(e) => self.handleChange(e.target.value)}
          input={<Input name="categories" id="categories-simple" />}
        >
          {items}
        </Select>
      </FormControl>
    );
  }
}

SelectCategory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectCategory);
