import React, { Component } from 'react';
import JsonDialog from '../dialogs/JsonDialog';
import OverrideDialog from "../dialogs/OverrideDialog";

import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';

import {
    AppBar, 
    Toolbar, 
    Typography, 
    LinearProgress, 
    DialogActions, 
    DialogTitle, 
    DialogContentText, 
    DialogContent, 
    Dialog,
    TextField, 
    IconButton, 
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Button, 
} from '@material-ui/core/';

import {
    saveFlags,
    deleteFlag
  } from '@apricityhealth/web-common-lib/utils/Services';

import ChangedByButton from '../components/ChangedByButton';

class FlagTypeView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            progress: null,
            modified:false,
            flag:JSON.parse( JSON.stringify( props.flag )),
            links:JSON.parse( JSON.stringify( props.links )),
            dialog:null
        };
    }    

    componentDidUpdate(oldProps){
        if (oldProps.flag){
            if (oldProps.flag.description !== this.props.flag.description || oldProps.flag.flagId !== this.props.flag.flagId){
                this.setState({
                    links: JSON.parse( JSON.stringify( this.props.links )), 
                    flag:  JSON.parse( JSON.stringify(this.props.flag)) ,
                    modified:false
                });
            }
        }
    }

    onChange = type => e => {
        let { flag, modified } = this.state;
        flag[type] = e.target.value;
        modified = true
        this.setState({ flag, modified });
    }

    saveFlag(planId) {
        this.setState({ dialog: null });
        const self = this;
        const { appContext } = this.props;
        const { flag } = this.state;

        if ( !planId && flag.planId && flag.planId !== appContext.state.plan.planId ) {
            return this.displayOverrideDialog( flag.planId, this.saveFlag.bind(this) );
        }

        this.setState({
          progress: <LinearProgress />
        });
        
        saveFlags(appContext, flag, planId).then(() => {
          self.setState({ progress: null });
          if (self.props.onDone)
            self.props.onDone()
        }).catch((error) => {
          console.error("Error saving flags:", error);
          self.setState({ progress: error.message });
        });
    }

    deleteFlagPrompt(){
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Flag: {`${self.state.flag.flagId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.cancelDeleteFlag() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.confirmDeleteFlag() }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    cancelDeleteFlag() {
        this.setState({ dialog: null });
    }
    
    confirmDeleteFlag() {
        const self = this
        const { flag } = this.state;
        const {appContext} = self.props
        this.setState({
            progress: <LinearProgress />
        });
        deleteFlag(appContext, flag).then(function (response) {
            self.setState({ progress: null });
            if (self.props.onDone)
                self.props.onDone();
          }).catch(function (error) {
            console.error("Error deleting flags:", error);
            self.setState({ progress: error.message });
          });
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    displayModifiedDialog() {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Flag Modified!</DialogTitle>
            <DialogContent>This content has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveFlag();
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, this.props.onClose());
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    closeContent() {
        if (this.state.modified === true) {
            this.displayModifiedDialog();
        }else{
            this.props.onClose();
        }
    }
    
    render(){
        const self = this;
        const { progress, dialog, flag, links } = this.state;

        let getLinkItems = (links) => {
            let linkItems = []
            if (links && links.links){
                links.links.forEach( (link, index)=>{
                    linkItems.push(
                        <TableRow key={index}>
                            <TableCell>{link.type}</TableCell>
                            <TableCell>{link.name.text}</TableCell>
                            <TableCell>{link.location}</TableCell>
                        </TableRow>
                    )
                })
            }
            return linkItems
        }
        let linksSection = () => {
            let linkItem = []
            linkItem.push(
                <Table style={styles.linksTable}>
                <TableHead>
                <TableRow>
                    <TableCell >Type</TableCell>
                    <TableCell >Name</TableCell>
                    <TableCell >Location</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                   {getLinkItems(links)}
                </TableBody>
                </Table>
            )
            return linkItem
        }
        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeContent()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Edit Flag</Typography>
                    </Toolbar>
                </AppBar>
                {progress}
                {dialog}
                <div style={styles.mainBody} align="center" >
                    <div align="left" style={styles.div}>
                        <div style={styles.buttonHeader}>
                            <div>Flag Id: {flag.flagId}</div>
                            <div valign="top">
                                <IconButton onClick={() => {self.saveFlag();}}><SaveIcon /></IconButton>
                                <IconButton onClick={() => {
                                    this.setState({
                                        dialog: <JsonDialog
                                        appContext={this.props.appContext}
                                        dataType={flag}
                                        onEditDOne={(flag) => {
                                            this.setState({flag, dialog: null});
                                        }}
                                        onDone={() => {
                                            this.setState({ dialog: null });
                                        }} />
                                    });
                                }}><CodeIcon /></IconButton>
                                <IconButton onClick={() => {self.deleteFlagPrompt();}}><DeleteIcon /></IconButton>
                            </div>
                        </div> 
                        <br />   <br />
                        <TextField
                            fullWidth={true}
                            label="Flag Id:"
                            value={flag.flagId}
                            onChange={self.onChange("flagId")}
                        />
                        <br />   <br />
                        <TextField
                            fullWidth={true}
                            label="Description:"
                            value={flag.description}
                            onChange={self.onChange("description")}
                        />
                        <br />   <br />
                        <h5>{links.flagId}</h5>
                        <p>Flag is linked from the following:</p>
                        {linksSection()}
                        <br/>
                        <ChangedByButton appContext={this.props.appContext} primaryKey={flag.flagId} collection='FlagTypeModel' />
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    mainBody:{
        padding:10
    },
    div: {
        margin: 10
    },
    buttonHeader:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    appBar: {
        width: '1000px',
        backgroundColor: "#FF9800"
    },
    linksTable:{
        maxWidth: '900px'
    }
}

export default FlagTypeView;