import React, { Component } from "react";

import {
    loadQuestions,
    loadFlags
} from '@apricityhealth/web-common-lib/utils/Services';

import {
    TextField,
    Drawer,
    IconButton,
    FormControlLabel,
    Checkbox,
    CircularProgress
} from '@material-ui/core/';

import {
    getQuestionsLinkedByFlag,
    findNodeByFlagId,
    isArrayValid,
} from '../utils/Utils';

import PubSub from 'pubsub-js'
import RefreshIcon from '@material-ui/icons/Refresh';
import FlagTypeView from './FlagTypeView';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';

function filterFlagTypes(filter, type) {
    if (type.flagId && type.flagId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    if (type.description && type.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    return false;
}

class FlagTypesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dependencies: true,
            workingFlags: [],
            flagLinks: [],
            progress: null,
            flagData: [],
            filter: "",
            dialog: null,
        }


    }

    componentDidMount() {
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }


    loadContent() {
        const self = this;
        const { dependencies } = this.state;
        const { appContext } = this.props;

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        let promises = [];
        promises.push(loadQuestions(appContext, { dependencies: true } ));
        promises.push(loadFlags(appContext, { dependencies } ));
        Promise.all(promises).then((response) => {
            let questions = response[0];
            let allFlags = response[1];
            let flagLinks = [];
            for (let index = 0; index < allFlags.length; index++) {
                let flag = allFlags[index];
                let flagId = flag.flagId;
                let links = getQuestionsLinkedByFlag(questions, flag.flagId);
                flagLinks.push({ flagId, links });
            }
            self.setState({ workingFlags: allFlags, flagLinks, progress: null }, self.createFlagData.bind(self));
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });
    }

    createFlagData() {
        let self = this;
        let { workingFlags, filter } = this.state;
        let flagData = []
        for (let i = 0; i < workingFlags.length; i++) {
            let dataType = workingFlags[i];
            if (!filterFlagTypes(filter, dataType)) {
                continue;
            }
            let text = dataType.flagId;
            let description = dataType.description;
            let isUsed = self.isFlagUsed(dataType);
            let flag = {
                text,
                isUsed,
                description,
                dataType,
                planId: dataType.planId,
                isFlag: true
            }
            flagData.push(flag);
        }
        self.setState({ flagData });
    }

    createNewFlag() {
        let self = this;
        const { appContext } = this.props;
        let newFlag = {
            flagId: "flagId",
            description: "",
            features: []
        }
        let links = []
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <FlagTypeView appContext={appContext} links={links} flag={newFlag} onClose={() => self.cancel()} onDone={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ dialog });
    }

    isFlagUsed(flag) {
        let { flagLinks } = this.state;
        let links = findNodeByFlagId(flagLinks, flag.flagId);
        if (links && links.links)
            return isArrayValid(links.links);
        else
            return false;
    }

    onEditFlag(index, table) {
        let self = this;
        if (index.length && table.props && table.props.data) {
            let flag = table.props.data[index]
            let links = this.getAllReferenceLinks(flag.dataType)
            flag = flag.dataType
            const { appContext } = this.props;
            let dialog = <Drawer variant="persistent" anchor="right" open={true} >
                <FlagTypeView appContext={appContext} links={links} flag={flag} onClose={() => self.cancel()} onDone={() => self.onCloseDialog()} />
            </Drawer>;
            this.setState({ dialog });
        } else {
            self.setState({ dialog: null });
        }
    }

    cancel() {
        this.setState({ dialog: null });
        if (this._plans)
            this._plans.setState({ selected: [] });        // unselect the question
    }

    onCloseDialog() {
        this.setState({ dialog: null }, this.loadContent.bind(this))
        if (this._plans)
            this._plans.setState({ selected: [] });        // unselect the question
    }

    getAllReferenceLinks(flag) {
        let { flagLinks } = this.state;
        if (flagLinks && flag && flag.flagId) {
            let links = findNodeByFlagId(flagLinks, flag.flagId);
            return links
        } else {
            return []
        }
    }
    render() {
        const self = this;
        let { error, progress, flagData, filter, dialog, dependencies } = this.state;

        const columnData = [
            { id: 'text', numeric: false, disabledPadding: false, label: 'Text' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'isUsed', numeric: false, disabledPadding: false, label: 'Is Used', formatValue: (v) => toBoolean(v) ? "Yes" : "No" },
            {
                id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                    return <Plan appContext={this.props.appContext} planId={v} />
                }
            }
        ];

        return (
            <table width='100%'><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                            onChange={(e) => { self.setState({ filter: e.target.value }, self.createFlagData.bind(self)); }} />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={dependencies} onChange={(e, v) => {
                            self.setState({ dependencies: v }, self.loadContent.bind(self));
                        }} />} label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._plans = table}
                            orderBy='text'
                            onSelected={(s, t) => { self.onEditFlag(s, t) }}
                            columnData={columnData}
                            data={flagData}
                            title='Flags'
                            onAdd={() => this.createNewFlag()}
                        />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

export default FlagTypesView;