import React, { Component } from 'react';
import { Drawer, 
    CircularProgress, 
    TextField, 
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import PubSub from 'pubsub-js'

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import AlertTypeView from './AlertTypeView'

import {
    loadAlertTypes,
} from '@apricityhealth/web-common-lib/utils/Services'

function testFilter(filter, model) {
    if (model) {
        filter = filter.toLowerCase();
        if (model.alertTypeId && model.alertTypeId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.description && model.description.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class AlertTypesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            types: []
        }

    }

    componentDidMount() {
        this.loadContent();
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }
    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        let { appContext } = this.props;
        let { dependencies } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadAlertTypes(appContext, { dependencies } ).then((response) => {
            self.setState({ types: response, progress: null }, self.updateFilter.bind(self));
        }).catch((err) => {
            self.setState({ types: [], progress: null, error: err.message });
        });
    }

    updateFilter() {
        let { types, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < types.length; ++i) {
                if (testFilter(filter, types[i]))
                    filtered.push(types[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    typeSelected(s, t) {
        console.log("typeSelected:", s, t);
        const self = this;
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = self.props.appContext;
            const type = self.state.filtered ? self.state.filtered[s[0]] : self.state.types[s[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <AlertTypeView appContext={appContext} alertTypeId={type.alertTypeId} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            self.setState({ dialog });
        }
        else {
            // nothing selected..
            self.setState({ dialog: null });
        }
    }
    createType() {
        console.log("createType");
        const { appContext } = this.props;
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <AlertTypeView appContext={appContext} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }
    render() {
        const self = this;
        const { filtered, types, dialog, error, progress, dependencies } = this.state;
        let { filter } = this.state;

        const columnData = [
            { id: 'alertTypeId', numeric: false, disabledPadding: false, label: 'Alert Type Id' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width='100%'><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                            onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._types = table}
                            onSelected={(s, t) => self.typeSelected(s, t)}
                            onAdd={() => self.createType()}
                            orderBy='name'
                            columnData={columnData}
                            data={filtered ? filtered : types}
                            title='Alert Types' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

export default AlertTypesView;