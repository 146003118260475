import React from 'react';
import PubSub from 'pubsub-js';

import {
    Checkbox,
    CircularProgress,
    Drawer,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles';

import CreateIcon from '@material-ui/icons/Create';
import PublishIcon from '@material-ui/icons/Publish';
import RefreshIcon from '@material-ui/icons/Refresh';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import MedicationTypeView from './MedicationTypeView';
import BulkUploadMedicationsView from './BulkUploadMedicationsView';

const Promise = require('bluebird');

/**
 * Medication Terminology Portal `e.g. Clinical Ontology [Tree|Web|List] Viewer`
 * @memberof module:Rosetta
 * @alias MedicationTypesView
 */
export class MedicationTypesView extends React.Component {
    //#region Component-Lifecycle Methods
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            dependencies: true,
            invalids: false,
            drawerOpen: false,
            filter: '',
            filtered: [],
            types: [],
            typeHash: {},
            deleteTypes: []
        };
        this.config = {
            id: 'medicationId',
            parentId: 'parentId'
        };
        this.localExpanded = [];
    }


    componentDidMount() {
        this.token = PubSub.subscribe('STORE_UPDATE', this.loadContent.bind(this));
        this.loadContent();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext } = this.props;
        const { plan } = appContext.state;
        if (plan) {
            const { planId } = plan;
            const { dependencies, invalids } = this.state;

            const store = appContext.stores.DataTypesStore;
            const types = store.getMedications().filter((e) => (dependencies === true || e.planId === planId)
                && (invalids === false || !e.name));
            const typeHash = store.getMedicationsHash();

            this.setState({ types, typeHash }, this.updateFilter.bind(this));
        }
    }
    //#endregion


    //#region Component-Data Methods
    importBulkMedications() {
        const {
            props: { appContext },
            state: { drawerOpen, types }
        } = this;

        if (drawerOpen) throw new EvalError('`MedicationTypes.Drawer` is open, csvImport aborted');

        this.setState(
            { // Pass the selected Files & Save event-listener [this.bound] to BulkMedicationsView slide
                dialog: <Drawer open variant='persistent' anchor='right'
                    onClose={this.onCloseDialog.bind(this)}
                >
                    <BulkUploadMedicationsView appContext={appContext}
                        types={types}
                        onClose={() => this.onCloseDialog()}
                    />
                </Drawer>
            });
    }
    //#endregion


    updateFilter() {
        function testFilter(filter, medication) {
            if (medication) {
                filter = filter.toLowerCase();
                if (medication.name && medication.name.toLowerCase().indexOf(filter) >= 0)
                    return true;
                if (medication.description && medication.description.toLowerCase().indexOf(filter) >= 0)
                    return true;
                if (medication.system && medication.system.toLowerCase().indexOf(filter) >= 0)
                    return true;
                if (medication.code && medication.code.toLowerCase().indexOf(filter) >= 0)
                    return true;
                if (medication.medicationId && medication.medicationId.toLowerCase().indexOf(filter) >= 0)
                    return true;
                if (medication.parentId && medication.parentId.toLowerCase().indexOf(filter) >= 0)
                    return true;
                if (Array.isArray(medication.tags)) {
                    for (var i = 0; i < medication.tags.length; ++i) {
                        if (medication.tags[i].toLowerCase().indexOf(filter) >= 0)
                            return true;
                    }
                }
            }
            return false;
        };

        const { filter, types, typeHash } = this.state;
        if (filter) {
            let filtered = [];
            for (let i = 0; i < types.length; ++i) {
                const type = types[i];
                if (testFilter(filter, type)) {
                    filtered.push(type);
                }
                else if (type.parentId && typeHash[type.parentId]) {
                    if (testFilter(filter, typeHash[type.parentId])) {
                        filtered.push(type);
                    }
                }
            }

            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog(reload = true) {
        this.setState({ dialog: null }, reload ? this.loadContent.bind(this) : undefined);
    }

    onEditType(table) {
        const { selected, data } = table.state;
        const { appContext } = this.props;
        const { types } = this.state;

        if (selected.length > 0) {
            let type = data[selected[0]];
            if (type) {
                this.setState(
                    {
                        dialog: <Drawer open variant='persistent' anchor='right'>
                            <MedicationTypeView appContext={appContext} types={types} type={type}
                                onClose={() => this.onCloseDialog()} />
                        </Drawer>
                    });
                table.setState({ selected: [] });
            }
        }
    }

    onCreateType() {
        const { appContext } = this.props;
        const { types } = this.state;
        const newType = { medicationId: '', description: '', tags: [] };

        this.setState(
            {
                dialog: <Drawer open variant='persistent' anchor='right'>
                    <MedicationTypeView appContext={appContext} type={newType} types={types}
                        onClose={this.onCloseDialog.bind(this)} />
                </Drawer>
            });
    }

    onDeleteType(type) {
        console.log("onDeleteType:", type);
        const { deleteTypes } = this.state;
        deleteTypes.push(type);

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Please confirm the deletion of {deleteTypes.length} medication types?</DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    this.setState({ dialog: null, deleteTypes: [] });
                }}>Cancel</Button>
                <Button onClick={() => {
                    const { appContext } = this.props;

                    this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null, deleteTypes: [] });
                    Promise.map(deleteTypes, (type) => {
                        return appContext.stores.DataTypesStore.deleteMedication(type.medicationId, type.planId);
                    }, { concurrency: 20 }).then(() => {
                        this.loadContent();
                    }).catch((err) => {
                        this.setState({ progress: null, error: getErrorMessage(err) });
                    })
                }} >Confirm</Button>
            </DialogActions>
        </Dialog>;
        this.setState({ dialog });
    }

    render() {
        const {
            props: {
                appContext: {
                    stores: {
                        DataTypesStore: store
                    }
                }
            },
            state: {
                filter,
                dependencies,
                invalids,
                dialog,
                error,
                types,
                filtered,
                typeHash
            }
        } = this;

        const typesColumns = [
            { id: 'medicationId', label: 'Medication ID' },
            { id: 'parentId', label: 'Parent', formatValue: (i) => (typeHash[i] || { name: '' }).name },
            { id: 'name', label: 'Name', formatValue: (v) => v.substring(0, 32) },
            { id: 'description', label: 'Description', formatValue: (v) => v.substring(0, 64) },
            { id: 'system', label: 'System', formatValue: (v) => v.substring(0, 32) },
            { id: 'code', label: 'Code', formatValue: (v) => v.substring(0, 16) },
            {
                id: 'planId', label: 'Plan', formatValue: (i) => {
                    return <Plan appContext={this.props.appContext} planId={i} />
                }
            }
        ];

        return <table width='100%'><tbody>
            <tr>
                <td>
                    <TextField label='Filter' style={divStyles.filterField} value={filter}
                        onChange={e => { this.setState({ filter: e.target.value }, this.updateFilter.bind(this)); }} />
                    <FormControlLabel label='Show Dependencies' style={{ margin: 5 }}
                        control={<Checkbox checked={dependencies} onChange={(e, v) => {
                            this.setState({ dependencies: v }, this.loadContent.bind(this));
                        }} />} />
                    <FormControlLabel label='Show Invalids' style={{ margin: 5 }}
                        control={<Checkbox checked={invalids} onChange={(e, v) => {
                            this.setState({ invalids: v }, this.loadContent.bind(this));
                        }} />} />
                </td>
                <td align='right'>
                    {error}
                    <IconButton disabled={store.progress !== null}
                        onClick={this.importBulkMedications.bind(this)}><PublishIcon />
                    </IconButton>
                    <IconButton disabled={store.progress !== null} onClick={store.loadDataTypes.bind(store)}>
                        {store.progress || <RefreshIcon />}
                    </IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan='2'>
                    <EnhancedTable
                        onTable={(table) => this._table = table}
                        disableMultiSelect={false}
                        onActions={(table, numSelected, actions) => {
                            if (numSelected === 1) {
                                actions.unshift(<Tooltip title='Edit' key='edit'><IconButton onClick={this.onEditType.bind(this, table)}><CreateIcon /></IconButton></Tooltip>);
                            }
                        }}
                        onAdd={this.onCreateType.bind(this)}
                        onDelete={this.onDeleteType.bind(this)}
                        orderBy='medicationId'
                        columnData={typesColumns}
                        data={filtered || types}
                        title='Medication Types' />
                    {dialog}
                </td>
            </tr>
        </tbody></table>;
    }
};


const styles = theme => ({ drawerPaper: { marginTop: '65px' } });

const divStyles = {
    filterField: {
        marginLeft: '20px',
        marginTop: '10px',
        width: '500px'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'no-wrap'
    },
    push: { flexGrow: 2 }
};


export default withStyles(styles, { withTheme: true })(MedicationTypesView);