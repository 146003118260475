import React from 'react';
import PubSub from 'pubsub-js';

import { 
    Drawer, 
    TextField, 
    IconButton, 
    FormControlLabel,
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip
} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';

import ProcedureTypeView from './ProcedureTypeView';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

const Promise = require('bluebird');

function testFilter(filter, procedure) {
    if (procedure) {
        filter = filter.toLowerCase();
        if (procedure.name && procedure.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (procedure.description && procedure.description.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (procedure.system && procedure.system.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (procedure.code && procedure.code.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (procedure.procedureId && procedure.procedureId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (Array.isArray(procedure.tags)) {
            for (var i = 0; i < procedure.tags.length; ++i) {
                if (procedure.tags[i].toLowerCase().indexOf(filter) >= 0)
                    return true;
            }
        }
    }
    return false;
}
export class ProcedureTypesView extends React.Component {
    constructor(props) {
        super(props);
        this.localExpanded = []
        this.config = {
            id: 'procedureId',
            parentId: 'parentId'
        };
        this.state = {
            dialog: null,
            dependencies: true,
            invalids: false,
            filter: '',
            filtered: null,
            types: [],
            typeHash: {},
            deleteTypes: []
        }
    }

    componentDidMount() {
        this.token = PubSub.subscribe('STORE_UPDATE', this.loadContent.bind(this));
        this.loadContent();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext } = this.props;
        const { plan } = appContext.state;        
        if ( plan ) {
            const { planId } = plan;
            const { dependencies, invalids } = this.state;

            const store = appContext.stores.DataTypesStore;
            const types = store.getProcedures().filter((e) => (dependencies === true || e.planId === planId) 
                && (invalids === false || !e.name));
            const typeHash = store.getProceduresHash();

            this.setState({types, typeHash }, this.updateFilter.bind(this));
        }
    }

    updateFilter() {
        const { filter, types, typeHash } = this.state;

        if (filter) {
            let filtered = [];
            for (let i = 0; i < types.length; ++i) {
                const type = types[i];
                if (testFilter(filter, type)) {
                    filtered.push(type);
                }
                else if (type.parentId && typeHash[type.parentId]) {
                    if ( testFilter(filter, typeHash[type.parentId])) {
                        filtered.push(type);
                    }
                }
            }

            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog( reload = true ) {
        this.setState({ dialog: null }, reload ? this.loadContent.bind(this) : undefined );
    }

    onEditType(table) {
        const { selected, data } = table.state;
        const { appContext } = this.props;

        if ( selected.length > 0) {
            let type = data[selected[0]];
            if ( type ) {
                this.setState(
                {
                    dialog: <Drawer open variant='persistent' anchor='right'>
                        <ProcedureTypeView appContext={appContext} type={type}
                            onClose={this.onCloseDialog.bind(this)} />
                    </Drawer>
                });
                table.setState( { selected: [] });
            }
        }

    }

    onCreateType() {
        const { appContext } = this.props;
        const newType = { procedureId: '', description: '', tags: [] };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <ProcedureTypeView appContext={appContext} type={newType} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    onDeleteType(type) {
        console.log("onDeleteType:", type );
        const { deleteTypes } = this.state;
        deleteTypes.push( type );

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Please confirm the deletion of {deleteTypes.length} procedure types?</DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    this.setState({dialog: null, deleteTypes: [] });
                }}>Cancel</Button>
                <Button onClick={() => {
                    const { appContext } = this.props;

                    this.setState({dialog: null, error: null, deleteTypes: [] });
                    Promise.map( deleteTypes, (type) => {
                        return appContext.stores.DataTypesStore.deleteProcedure(type.procedureId, type.planId);
                    }, { concurrency: 20 }).then(() => {
                        this.loadContent();
                    }).catch((err) => {
                        this.setState({error: getErrorMessage(err)});
                    })
                }} >Confirm</Button>
            </DialogActions>
        </Dialog>;
        this.setState({dialog});
    }

    render() {
        const {
            props: {
                appContext: {
                    stores: {
                        DataTypesStore: store
                    }
                }
            },
            state: {
                filter,
                dependencies,
                invalids,
                dialog, 
                error, 
                types,
                filtered,
                typeHash
            }
        } = this;

        const typesColumns = [
            { id: 'procedureId', label: 'Procedure ID' },
            { id: 'parentId', label: 'Parent', formatValue: (i) => (typeHash[i] || { name: i}).name },
            { id: 'name', label: 'Name', formatValue: (v) => v.substring(0,32) },
            { id: 'description', label: 'Description', formatValue: (v) => v.substring(0,64) },
            { id: 'system', label: 'System', formatValue: (v) => v.substring(0,32) },
            { id: 'code', label: 'Code', formatValue: (v) => v.substring(0,16) },
            { id: 'planId', label: 'Plan', formatValue: (i) => {
                return <Plan appContext={this.props.appContext} planId={i} />
            }}
        ];

        return <table width='100%'><tbody>
            <tr>
                <td>
                    <TextField label='Filter'
                        value={filter}
                        style={divStyles.filterField}
                        onChange={ e => {
                            this.setState({ filter: e.target.value }, this.updateFilter.bind(this));
                        }}
                    />
                    <FormControlLabel label='Show Dependencies'
                        style={{ margin: 5 }}
                        control={
                            <Checkbox checked={dependencies} onChange={(e, v) => {
                                this.setState({ dependencies: v }, this.loadContent.bind(this));
                            }} />
                        }
                    />
                    <FormControlLabel label='Show Invalids'
                        style={{ margin: 5 }}
                        control={
                            <Checkbox checked={invalids} onChange={(e, v) => {
                                this.setState({ invalids: v }, this.loadContent.bind(this));
                            }} />
                        }
                    />
                </td>
                <td align='right'>
                    <span style={{color: 'red'}}>{error}</span>
                    <IconButton disabled={store.progress !== null} onClick={store.loadDataTypes.bind(store)}>
                        {store.progress || <RefreshIcon />}
                    </IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan='2'>
                    <EnhancedTable
                        onTable={(table) => this._table = table}
                        disableMultiSelect={false}
                        onActions={(table, numSelected, actions) => {
                            if (numSelected === 1) {
                                actions.unshift(<Tooltip title='Edit' key='edit'><IconButton onClick={this.onEditType.bind(this, table)}><CreateIcon /></IconButton></Tooltip>);
                            }
                        }}
                        onAdd={this.onCreateType.bind(this)}
                        onDelete={this.onDeleteType.bind(this)}
                        orderBy='procedureId'
                        columnData={typesColumns}
                        data={filtered || types}
                        title='Procedure Types' />
                    {dialog}
                </td>
            </tr>
        </tbody></table>;
    }
}

const styles = theme => ({
  drawerPaper: {
    marginTop: '65px'
  }
});

const divStyles = {
    filterField: {
        marginLeft: '20px',
        width: '500px',
        marginTop: '10px'
    },
    header:{
        display:'flex',
        flexDirection: 'row',
        flexWrap:'no-wrap',
        alignItems:'center'
    }
}

export default withStyles(styles, { withTheme: true })(ProcedureTypesView);
