import React from "react";
import PubSub from 'pubsub-js'

import {
    Tabs,
    Tab,
    Checkbox,
    IconButton,
    FormControlLabel
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

// Local
import { isArrayValid } from '../utils/Utils';
import { getPlanDiffsAsync, getPlanConflictsAsync } from "../utils/Utils";
import {
    loadPlanActivity
} from '@apricityhealth/web-common-lib/utils/Services';

import { AxiosRequest } from "@apricityhealth/web-common-lib/utils/Axios";
import Config from '@apricityhealth/web-common-lib/Config';

import SelectUser from '@apricityhealth/web-common-lib/components/SelectUser';
import SelectPlan, { getPlans } from '@apricityhealth/web-common-lib/components/SelectPlan';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import ContentChangesView from './ContentChangesView';
import ContentActivityView from "./ContentActivityView";
import ContentSnapshotsView from "./ContentSnapshotsView";
import ContentOverridesView from './ContentOverridesView';
import ContentConflictsView from './ContentConflictsView';

// CSS
import "../styles/content.css";
import "diff2html/dist/diff2html.min.css";

const PLAN_TOPIC = 'PLAN_TOPIC';

export function loadSnapshots(appContext, snapshotId = '*', opts = {} ) {
    const { state: { idToken, plan } } = appContext;
    if ( !opts.planId && plan ) {
        opts.planId = plan.planId;
    }
    const args = [];
    for(let k in opts) {
        args.push(`${k}=${encodeURIComponent(opts[k])}`)
    }
    const loadSnapshots = {
        url: Config.baseUrl + `${Config.pathPrefix}rosetta/snapshots/${snapshotId}?${args.join('&')}`,
        method: 'GET',
        headers: { "Authorization": idToken }

    }
    console.log("loadSnapshots request:", loadSnapshots);
    return AxiosRequest(loadSnapshots).then((result) => {
        console.log("loadSnapshots result:", result.data );
        return result.data;
    }).catch((err) => {
        console.error("loadSnapshots error:", err );
        throw err;
    })
}

export function saveSnapshot(appContext, snapshot, opts = {}) {
    const { state: { idToken, plan } } = appContext;
    if ( !snapshot.planId && plan ) {
        snapshot.planId = plan.planId;
    }
    const args = [];
    for(let k in opts) {
        args.push(`${k}=${encodeURIComponent(opts[k])}`)
    }
    const saveSnapshot = {
        url: Config.baseUrl + `${Config.pathPrefix}rosetta/snapshots?${args.join('&')}`,
        method: 'POST',
        headers: { "Authorization": idToken },
        data: snapshot
    }
    console.log("saveSnapshot request:", saveSnapshot);
    return AxiosRequest(saveSnapshot).then((result) => {
        console.log("saveSnapshot result:", result.data );
        return result.data;
    }).catch((err) => {
        console.error("saveSnapshot error:", err );
        throw err;
    })
}

export function deleteSnapshot(appContext, snapshotId, opts = {}) {
    const { state: { idToken } } = appContext;
    const args = [];
    for(let k in opts) {
        args.push(`${k}=${encodeURIComponent(opts[k])}`)
    }
    const deleteSnapshot = {
        url: Config.baseUrl + `${Config.pathPrefix}rosetta/snapshots/${snapshotId}?${args.join('&')}`,
        method: 'DELETE',
        headers: { "Authorization": idToken }
    }
    console.log("deletSnapshot request:", deleteSnapshot);
    return AxiosRequest(deleteSnapshot).then((result) => {
        console.log("deleteSnapshot result:", result.data);
        return result.data;
    }).catch((err) => {
        console.error("deleteSnapshot error:", err );
        throw err;
    })
}

export function restoreSnapshot(appContext, snapshotId, opts = {}) {
    const { state: { idToken } } = appContext;
    const args = [];
    for(let k in opts) {
        args.push(`${k}=${encodeURIComponent(opts[k])}`)
    }
    const restoreSnapshot = {
        url: Config.baseUrl + `${Config.pathPrefix}rosetta/restoreSnapshot/${snapshotId}?${args.join('&')}`,
        method: 'GET',
        headers: { "Authorization": idToken }
    }
    console.log("restoreSnapshot request:", restoreSnapshot);
    return AxiosRequest(restoreSnapshot).then((result) => {
        console.log("restoreSnapshot result:", result.data);
        return result.data;
    }).catch((err) => {
        console.error("restoreSnapshot error:", err );
        throw err;
    })
}

class ContentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            currentTab: 0,
            tabs: [],
            tabContent: [],
            comment: "",
            plan: null,
            enableUserFilter: false,
            filterByUserId: props.appContext.state.userId,
            enablePlanTarget: false,
            targetPlanId: '',
            activity: null,
            snapshots: null,
            conflicts: null,
            overrides: null,
            plans: [],
            truncated: false,
            diffs: null,
            changeKeys: null,
            dialog: null,
            revert: false
        };
    }

    componentDidMount() {
        console.log("componentDidMount()")
        this.token = PubSub.subscribe(PLAN_TOPIC, (msg,data) => {
            this.loadContent()
        });
        PubSub.publish(PLAN_TOPIC, { action: "RefreshPlan" });
    }
    componentWillUnmount() {
        console.log("componentWillUnmount()")
        PubSub.unsubscribe(this.token);
        if ( this.getDiffTimer) {
            clearInterval(this.getDiffTimer);
            this.getDiffTimer = null;
        }
    }

    onPlanChanged() {
        console.log("onPlanChanged()")
        this.loadContent();
    }

    loadContent() {
        const { appContext } = this.props;
        const plan = appContext.state.plan;
        if (plan) {
            console.log("loadContent:", plan );
            let { targetPlanId, filterByUserId, enableUserFilter } = this.state;
            if (!targetPlanId || plan.planId !== this.state.plan.planId) {
                targetPlanId = plan.sourcePlanId;
            }
            if (!enableUserFilter) {
                filterByUserId = undefined;
            }
            console.log(`loadContent for plan: ${plan.planId}, targetPlanId: ${targetPlanId}`);
            let tabs = [
                <Tab key='activity' label='Activities' />,
                <Tab key='snapshots' label='Snapshots' />,
                <Tab key='overrides' label='Overrides' />,
                <Tab key='conflicts' label='Conflicts' />,
                <Tab key='same' label='Same' />,
                <Tab key='pending' label='Pending Changes' />,
            ];
            let tabContent = [
                <ContentActivityView key='activity' appContext={appContext} parent={this} plan={plan} ref={(r) => this._activityView = r } />,
                <ContentSnapshotsView key='snapshots' appContext={appContext} parent={this} plan={plan} ref={(r) => this._snapshotsView = r } />,
                <ContentOverridesView key='overrides' appContext={appContext} parent={this} plan={plan} ref={(r) => this._overridesView = r} />,
                <ContentConflictsView key='conflicts' appContext={appContext} parent={this} plan={plan} mode='conflict' ref={(r) => this._conflictsView = r } />,
                <ContentConflictsView key='conflicts' appContext={appContext} parent={this} plan={plan} mode='same' ref={(r) => this._sameView = r } />,
                <ContentChangesView key='pending' appContext={appContext} parent={this} plan={plan} mode='pending' ref={(r) => this._pendingView = r } />,
            ];

            if (isArrayValid(plan.approvedDiffs)) {
                tabs.push( <Tab key={'approved'} label={'Approved Changes'} /> );
                tabContent.push( <ContentChangesView key='pending' appContext={appContext} parent={this} plan={plan} mode='approved' ref={(r) => this._approvedView = r } /> );
            }

            this.setState({ plan, targetPlanId, tabs, tabContent, error: null, activity: null, 
                overrides: null, conflicts: null, diffs: null, changeKeys: null });

            loadPlanActivity(appContext, plan.planId).then((activity) => {
                if (this.state.plan === plan && this.state.targetPlanId === targetPlanId) {
                    console.log("activity:", activity );
                    this.setState({ activity }, () => {
                        if (this._activityView)
                            this._activityView.forceUpdate() 
                    });
                }
            }).catch((err) => {
                console.error("loadPLanActivity error:", err );
                this.setState({ error: getErrorMessage(err) });
            })

            loadSnapshots(appContext).then((snapshots) => {
                if (this.state.plan === plan) {
                    console.log("snapshots:", snapshots);
                    this.setState({ snapshots}, () => {
                        if ( this._snapshotsView) {
                            this._snapshotsView.forceUpdate();
                        }
                    })
                }
            }).catch((err) => {
                console.error("loadSnapshots error:", err );
                this.setState({error: getErrorMessage(err) });
            })

            getPlans(appContext).then((plans) => {
                if (this.state.plan === plan && this.state.targetPlanId === targetPlanId) {
                    console.log("plans:", plans );
                    this.setState({ plans }, () => {
                        if ( this._conflictsView ) {
                            this._conflictsView.forceUpdate();
                        }
                        if ( this._sameView ) {
                            this._sameView.forceUpdate();
                        }
                    })
                }
            }).catch((err) => {
                console.error("getPlans error:", err );
                this.setState({ error: getErrorMessage(err) })
            })

            if (Array.isArray(plan.dependencies)) {
                let promises = [];
                promises.push(new Promise((resolve, reject) => {
                    let promises = [];
                    for(let i=0;i<plan.dependencies.length;++i) {
                        const dependencyPlanId = plan.dependencies[i];
                        promises.push( getPlanDiffsAsync( this.props.appContext, 
                            { planId: plan.planId, contentId: plan.contentId, targetPlanId: dependencyPlanId, same: true }).then((result) => {
                            return { ...result, dependencyPlanId };
                        }))
                    }
    
                    Promise.all(promises).then(resolve).catch((err) => {
                        console.error("Error checking dependency diffs:", err );
                        reject(err);
                    });
                }))
    
                Promise.all(promises).then((overrides) => {
                    if ( this.state.plan === plan && this.state.targetPlanId === targetPlanId) {
                        console.log("overrides:", overrides );
                        this.setState({ overrides }, () => {
                            if ( this._overridesView ) {
                                this._overridesView.forceUpdate();
                            }
                        })
                    }
                }).catch((err) => {
                    console.error("loadOverrides error:", err );
                    this.setState({error: getErrorMessage(err) })
                })
            }
    
            if (this._pendingView) {
                this._pendingView.resetContent();
            }
            if (this._approvedView) {
                this._approvedView.resetContent();
            }

            getPlanDiffsAsync(appContext, { planId: plan.planId, contentId: plan.contentId, targetPlanId, filterByUserId }).then(({ diffs, changeKeys, truncated }) => {
                if (this.state.plan === plan && this.state.targetPlanId === targetPlanId ) {
                    this.setState({ diffs, changeKeys, truncated}, () => {
                        console.log("diffs:", diffs, changeKeys, truncated );
                        if (this._pendingView) {
                            this._pendingView.initializeContent();
                        }
                        if (this._approvedView) {
                            this._approvedView.initializeContent();
                        }

                        if (truncated) {
                            this.setState({error: "NOTE: Content changes were truncated due to their size."});
                        }
                    } )
                }
            }).catch((err) => {
                console.error("getPlanDiffsAsync error:", err );
                this.setState({ error: getErrorMessage(err )})
            })

            getPlanConflictsAsync(appContext, { planId: plan.planId, async: true }).then((conflicts) => {
                if ( this.state.plan === plan && this.state.targetPlanId === targetPlanId ) {
                    this.setState({ conflicts }, () => {
                        if (this._conflictsView)
                            this._conflictsView.forceUpdate();
                        if (this._sameView)
                            this._sameView.forceUpdate();
                    })
                }
            }).catch((err) => {
                console.error("getPlanConflictsAsync error:", err );
                this.setState({ error: getErrorMessage(err) })
            })
        } // if (plan)
    }

    render() {
        const { appContext } = this.props;
        const { tabs, tabContent, error, progress, dialog, targetPlanId, enablePlanTarget, enableUserFilter, filterByUserId } = this.state;
        let { currentTab } = this.state;

        if (currentTab >= tabs.length)
            currentTab = tabs.length - 1;

        return (
            <div style={{margin: 10}}>
                <div style={{ marginTop: '20px' }} />
                <table width="100%"><tbody><tr>
                    <td>
                        <Tabs
                            value={currentTab}
                            onChange={(e, t) => { this.setState({ currentTab: t }) }}
                        >
                            {tabs}
                        </Tabs>
                    </td><td align="right">
                        <span style={{ color: 'red' }}>{error}</span>
                        <IconButton disabled={progress !== null} onClick={() => {
                            this.setState({ diffContent: null, currentTab: 0 }, this.loadContent.bind(this))
                        }}>{progress || <RefreshIcon />}</IconButton>
                        <FormControlLabel control={<Checkbox checked={enablePlanTarget} onChange={(e, v) => { 
                            this.setState({ enablePlanTarget: v, targetPlanId: '' }, this.loadContent.bind(this) ) 
                        }} />}
                            label="Target Plan" />
                        {enablePlanTarget ? <SelectPlan appContext={appContext} planId={targetPlanId} onChange={(plan) => {
                            if (plan && plan.planId !== this.state.targetPlanId) {
                                console.log("Changing targetPlanId:", plan.planId);
                                this.setState({ targetPlanId: plan.planId }, this.loadContent.bind(this));
                            }
                        }} /> : null}
                        <FormControlLabel control={<Checkbox checked={enableUserFilter} 
                            onChange={(e, v) => { this.setState({ enableUserFilter: v }, this.loadContent.bind(this)) }} />}
                            label="Filter By User" />
                        {enableUserFilter ? <div><SelectUser appContext={appContext} userId={filterByUserId}
                            onChange={(userId) => this.setState({ filterByUserId: userId }, this.loadContent.bind(this))} /></div> : null}
                    </td></tr></tbody></table>
                {tabContent[currentTab]}
                {dialog}
            </div>
        );

    }
}

export default ContentView;

