import {
    getPlanDiffs, getPlanConflicts
} from '@apricityhealth/web-common-lib/utils/Services';

export function getPlanConflictsAsync( appContext, opts ) {
    return getPlanConflicts(appContext, { ...opts, async: true }).then(({asyncId}) => {
        return new Promise((resolve, reject) => {
            let timer = setInterval(() => {
                getPlanConflicts(appContext, { ...opts, asyncId, async: false } ).then((result) => {
                    if ( result.status === 'error' ) {
                        clearInterval(timer);
                        reject(new Error(result.error)); //getErrorMessage(result)))
                    }
                    else if ( result.status !== 'active') {
                        clearInterval(timer);
                        resolve(JSON.parse(result.result))
                    }
                }).catch((err) => {
                    clearInterval(timer);
                    reject(err);
                })
            }, 2500);
        })
    })    
}

export function getPlanDiffsAsync( appContext, opts ) {
    return getPlanDiffs(appContext, { ...opts, async: true }).then(({asyncId}) => {
        return new Promise((resolve, reject) => {
            let timer = setInterval(() => {
                getPlanDiffs(appContext, { ...opts, asyncId, async: false } ).then((result) => {
                    if ( result.status === 'error' ) {
                        clearInterval(timer);
                        reject(new Error(result.error || result.result)); //getErrorMessage(result)))
                    }
                    else if ( result.status !== 'active') {
                        clearInterval(timer);
                        resolve(JSON.parse(result.result))
                    }
                }).catch((err) => {
                    clearInterval(timer);
                    reject(err);
                })
            }, 2500);
        })
    })    
}

export function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
}

export function hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
}

export function getByUserName(array, userId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].username === userId) return array[index];
        }
    return null;
}

export function isAdminUser(appContext) {
    let account = appContext.state.account;
    if (account && isArrayValid(account.groups)) {
        return account.groups.includes('administrators');
    }
    return false;
}

export function isExpertUser(appContext) {
    let account = appContext.state.account;
    if (account && isArrayValid(account.groups)) {
        return account.groups.includes('experts');
    }
    return false;
}
export function getLocationById(array, id) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].id === id) {
                return index;
            }
        }
    return -1;
}

export function getLocationByRecommendId(array, recommendId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].recommendId === recommendId) {
                return index;
            }
        }
    return -1;
}
export function getLocationByDataId(array, dataId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].dataId === dataId) {
                return index;
            }
        }
    return -1;
}
export function getLocationByText(array, text) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].text === text) {
                return index;
            }
        }
    return -1;
}
export function getLocationByName(array, name) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].name === name) {
                return index;
            }
        }
    return -1;
}
export function getLocationByQuestionId(array, questionId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].questionId === questionId) {
                return index;
            }
        }
    return -1;
}


export function getLocationByFlagId(array, flagId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].flagId === flagId) return index;

        }
    return -1;
}

export function removeAnswer(question, answer) {
    let answers = question.answers;
    if (answer && isArrayValid(answers)) {
        let loc = getAnswerLocationById(answers, answer.answerId);
        if (loc >= 0) {
            arrayRemoveLocation(answers, loc);
        }
    }
}


export function getAnswerLocationById(array, answerId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].answerId === answerId) {
                return index;
            }
        }
    return -1;
}

export function arrayInsertLocation(array, index, element) {
    array.splice(index, 0, element);
}

export function addUniqueValue(array, value) {
    if (!Array.isArray(array))
        throw new Error("addUniqueValue needs an array!");

    var found = false;
    for (var i = 0; i < array.length && !found; ++i) {
        if (array[i] === value)
            found = true;
    }

    if (!found)
        array.push(value);
    return array;
}

export function removeValue(array, value) {
    if (!Array.isArray(array))
        throw new Error("removeValue needs an array!");
    for (let i = 0; i < array.length; ++i) {
        if (array[i] === value)
            array.splice(i--, 1);
    }
}

export function arrayRemoveLocation(array, index) {
    if (isArrayValid(array))
        array.splice(index, 1);
}


export function arrayMove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
}


export function findNodeByType(array, type) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].type === type) return array[index];

        }
    return null;
}


export function findNodeByFlagId(array, flagId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].flagId === flagId) return array[index];

        }
    return null;
}

export function findNodeByDataId(array, dataId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].dataId === dataId) return array[index];

        }
    return null;
}
export function findNodeByModelId(array, modelId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].modelId === modelId) return array[index];

        }
    return null;
}
export function findNodeByCategory(array, category) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].category === category) return array[index];

        }
    return null;
}


export function findNodeByName(array, name) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].name === name) return array[index];

        }
    return null;
}
export function findNodeById(array, id) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].id === id) return array[index];

        }
    return null;
}


export function findNodeByRecommendId(array, recommendId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].recommendId === recommendId) return array[index];

        }
    return null;
}

export function findLocationByQuestionId(array, questionId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].questionId === questionId) return index;

        }
    return -1;
}


export function findLocationByRecommendId(array, recommendId) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].recommendId === recommendId) return index;

        }
    return -1;
}
export function findNodeByText(array, text) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].text === text) return array[index];

        }
    return null;
}

export function getByTextId(array, textId) {
    let texts = [];
    if (isArrayValid(array)) {
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.textId === textId)
                texts.push(element);

        }
    }
    return texts;
}


export function findByPlanId(array, planId) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            if (array[i].planId === planId) return array[i];

        }
    return null;
}
export function findLocationByModelId(array, modelId) {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            if (array[i].modelId === modelId) return i;

        }
    return -1;
}

export function findLocationByTextIdByLanguage(array, textId, language = 'en-us') {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.textId === textId && element.language === language)
                return i;

        }
    return -1;
}

export function getByTextIdByLanguage(array, textId, language = 'en-us') {
    if (isArrayValid(array))
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.textId === textId && element.language === language)
                return element;

        }
    return null;
}

export function findByTextId(array, textId) {

    if (isArrayValid(array))

        for (let index = 0; index < array.length; index++) {
            if (array[index].textId === textId) return array[index];

        }
    return null;
}

export function findQuestionByTextId(array, textId) {

    if (isArrayValid(array))

        for (let index = 0; index < array.length; index++) {
            if (textId.indexOf(array[index].questionId) >= 0) return array[index];

        }
    return null;
}


export function findByQuestionId(array, questionId) {

    if (isArrayValid(array))

        for (let index = 0; index < array.length; index++) {
            if (array[index].questionId === questionId) return array[index];

        }
    return null;
}
export function findNodeByValue(array, value) {
    if (isArrayValid(array))

        for (let index = 0; index < array.length; index++) {
            if (array[index].value === value) return array[index];

        }
    return null;
}


export function getDataTypeQuestionLinks(symptom, questions, texts) {
    let links = [];
    if (isArrayValid(questions)) {
        for (let i = 0; i < questions.length; i++) {
            let question = questions[i];
            let questionName = getByTextIdByLanguage(texts, question.questionId)

            if (questionName && questionName.text)
                questionName = questionName.text;
            addQuestionLinkedData(question, "Question ", questionName, symptom.dataId, links);
            if (isArrayValid(question.answers)) {
                for (let j = 0; j < question.answers.length; j++) {
                    let answer = question.answers[j];
                    let answerName = getByTextIdByLanguage(texts, question.questionId + '.' + answer.answerId);
                    if (answerName && answerName.text)
                        answerName = answerName.text;
                    addAnswerLinkedData(question, answer, `Question: ${questionName}:  Answer `, answerName, symptom.dataId, links);
                }
            }
        }
    }
    return links;
}

export function getDataTypeModelLinks(symptom, models) {
    let links = [];
    if (isArrayValid(models)) {
        for (let i = 0; i < models.length; i++) {
            let model = models[i];
            let name = model.name;
            addModelLinkedData(model, name, symptom.dataId, links);
        }
    }
    return links;
}



export function addQuestionLinkedData(question, type, name, dataId, links) {
    if (question.dataId === dataId)
        links.push({ question, type, name, location: 'dataId' });

    if (isArrayValid(question.orFlagConditions))
        for (let j = 0; j < question.orFlagConditions.length; j++)
            if (question.orFlagConditions[j] === dataId)
                links.push({ question, type, name, location: 'orFlagConditions' });

    if (isArrayValid(question.andFlagConditions))
        for (let j = 0; j < question.andFlagConditions.length; j++)
            if (question.andFlagConditions[j] === dataId)
                links.push({ question, type, name, location: 'andFlagConditions' });

    if (isArrayValid(question.removeFlags))
        for (let j = 0; j < question.removeFlags.length; j++)
            if (question.removeFlags[j] === dataId)
                links.push({ question, type, name, location: 'removeFlags' });

    if (isArrayValid(question.addFlags))
        for (let j = 0; j < question.addFlags.length; j++)
            if (question.addFlags[j] === dataId)
                links.push({ question, type, name, location: 'addFlags' });

    if (isArrayValid(question.addModels))
        for (let j = 0; j < question.addModels.length; j++)
            if (question.addModels[j] === dataId)
                links.push({ question, type, name, location: 'addModels' });

    if (isArrayValid(question.addOptionalData))
        for (let j = 0; j < question.addOptionalData.length; j++)
            if (question.addOptionalData[j] === dataId)
                links.push({ question, type, name, location: 'addOptionalData' });

    if (isArrayValid(question.addRequiredData))
        for (let j = 0; j < question.addRequiredData.length; j++)
            if (question.addRequiredData[j] === dataId)
                links.push({ question, type, name, location: 'addRequiredData' });


    return links;
}

export function addAnswerLinkedData(question, answer, type, name, dataId, links) {
    if (answer.dataId === dataId)
        links.push({ question, type, name, location: 'dataId' });

    if (isArrayValid(answer.orFlagConditions))
        for (let j = 0; j < answer.orFlagConditions.length; j++)
            if (answer.orFlagConditions[j] === dataId)
                links.push({ question, type, name, location: 'orFlagConditions' });

    if (isArrayValid(answer.andFlagConditions))
        for (let j = 0; j < answer.andFlagConditions.length; j++)
            if (answer.andFlagConditions[j] === dataId)
                links.push({ question, type, name, location: 'andFlagConditions' });

    if (isArrayValid(answer.removeFlags))
        for (let j = 0; j < answer.removeFlags.length; j++)
            if (answer.removeFlags[j] === dataId)
                links.push({ question, type, name, location: 'removeFlags' });

    if (isArrayValid(answer.addFlags))
        for (let j = 0; j < answer.addFlags.length; j++)
            if (answer.addFlags[j] === dataId)
                links.push({ question, type, name, location: 'addFlags' });

    if (isArrayValid(answer.addModels))
        for (let j = 0; j < answer.addModels.length; j++)
            if (answer.addModels[j] === dataId)
                links.push({ question, type, name, location: 'addModels' });

    if (isArrayValid(answer.addOptionalData))
        for (let j = 0; j < answer.addOptionalData.length; j++)
            if (answer.addOptionalData[j] === dataId)
                links.push({ question, type, name, location: 'addOptionalData' });

    if (isArrayValid(answer.addRequiredData))
        for (let j = 0; j < answer.addRequiredData.length; j++)
            if (answer.addRequiredData[j] === dataId)
                links.push({ question, type, name, location: 'addRequiredData' });


    return links;
}

export function getQuestionsLinkedByFlag(allQuestions, flagId) {

    let links = [];

    if (isArrayValid(allQuestions)) {
        for (let i = 0; i < allQuestions.length; i++) {
            let question = allQuestions[i];
            let name = question.question[0];
            if (isArrayValid(question.orFlagConditions))
                for (let j = 0; j < question.orFlagConditions.length; j++)
                    if (question.orFlagConditions[j] === flagId)
                        links.push({ type: "Question", name, location: 'orFlagConditions' });

            if (isArrayValid(question.andFlagConditions))
                for (let j = 0; j < question.andFlagConditions.length; j++)
                    if (question.andFlagConditions[j] === flagId)
                        links.push({ type: "Question", name, location: 'andFlagConditions' });

            if (isArrayValid(question.removeFlags))
                for (let j = 0; j < question.removeFlags.length; j++)
                    if (question.removeFlags[j] === flagId)
                        links.push({ type: "Question", name, location: 'removeFlags' });

            if (isArrayValid(question.addFlags))
                for (let j = 0; j < question.addFlags.length; j++)
                    if (question.addFlags[j] === flagId)
                        links.push({ type: "Question", name, location: 'addFlags' });


            if (isArrayValid(question.answers)) {
                let questionName = name;
                for (let j = 0; j < question.answers.length; j++) {
                    let answer = question.answers[j];
                    let name = answer.text[0]
                    let type = `Question: ${questionName}:  Answer `

                    if (isArrayValid(answer.orFlagConditions))
                        for (let j = 0; j < answer.orFlagConditions.length; j++)
                            if (answer.orFlagConditions[j] === flagId)
                                links.push({ type, name, location: "orFlagConditions" });
                    if (isArrayValid(answer.andFlagConditions))
                        for (let j = 0; j < answer.andFlagConditions.length; j++)
                            if (answer.andFlagConditions[j] === flagId)
                                links.push({ type, name, location: "andFlagConditions" });
                    if (isArrayValid(answer.removeFlags))
                        for (let j = 0; j < answer.removeFlags.length; j++)
                            if (answer.removeFlags[j] === flagId)
                                links.push({ type, name, location: "removeFlags" });
                    if (isArrayValid(answer.addFlags))
                        for (let j = 0; j < answer.addFlags.length; j++)
                            if (answer.addFlags[j] === flagId)
                                links.push({ type, name, location: "addFlags" });
                }
            }

        }
    }

    return links;
}


export function getDialogTextIds(questions) {
    let texts = [];
    if (isArrayValid(questions)) {
        for (let i = 0; i < questions.length; i++) {
            let question = questions[i];
            let questionId = question.questionId;
            texts.push(questionId)
            texts.push(questionId + ".explanation");
            if (isArrayValid(question.answers)) {
                for (let j = 0; j < question.answers.length; j++) {
                    let answerId = question.answers[j].answerId;
                    texts.push(questionId + '.' + answerId);
                    texts.push(questionId + '.' + answerId + ".explanation");
                }
            }
        }
    }
    return texts;
}

export function containsId(array, id) {
    if (isArrayValid(array)) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].toLowerCase().indexOf(id.toLowerCase()) >= 0) {
                return true;
            }
        }
    }
    return false;
}

export function getAnyQuestionOrAnswerByText(questions, text) {
    if (isArrayValid(questions)) {
        for (let i = 0; i < questions.length; i++) {
            let question = questions[i];
            if (isArrayValid(question.question)) {
                for (let j = 0; j < question.question.length; j++)
                    if (question.question[j] === text)
                        return question;
            }


            if (isArrayValid(question.answers)) {
                for (let j = 0; j < question.answers.length; j++) {
                    let answer = question.answers[j];
                    if (isArrayValid(answer.text)) {
                        for (let j = 0; j < answer.text.length; j++) {
                            if (answer.text[j] === text)
                                return answer;
                        }
                    }
                }
            }

        }
    }

    return null;
}

export function addModelLinkedData(data, name, dataId, links) {
    if (isArrayValid(data.triggerSymptoms))
        for (let j = 0; j < data.triggerSymptoms.length; j++)
            if (data.triggerSymptoms[j].dataId === dataId)
                links.push({ type: 'Model', name, location: 'triggerSymptoms' });


    if (isArrayValid(data.primarySymptoms))
        for (let j = 0; j < data.primarySymptoms.length; j++)
            if (data.primarySymptoms[j].dataId === dataId)
                links.push({ type: 'Model', name, location: 'primarySymptoms' });

    if (isArrayValid(data.associatedSymptoms))
        for (let j = 0; j < data.associatedSymptoms.length; j++)
            if (data.associatedSymptoms[j].dataId === dataId)
                links.push({ type: 'Model', name, location: 'associatedSymptoms' });

    if (isArrayValid(data.clinicalModifiers))
        for (let j = 0; j < data.clinicalModifiers.length; j++)
            if (data.clinicalModifiers[j].dataId === dataId)
                links.push({ type: 'Model', name, location: 'clinicalModifiers' });


    return links;
}

export function getText(texts, textId, language = 'en-us') {
    if ( texts[language] ) {
        if ( texts[language][textId] ) {
            return texts[language][textId]
        }
    }

    return {
        textId,
        language,
        text: '',
        version: 0,
        category: 'dialog'
    };
}
