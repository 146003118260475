import React from 'react';

import {
    AppBar,
    Button, Checkbox,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Drawer,
    FormControl, FormControlLabel,
    IconButton, InputLabel,
    CircularProgress,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core/';

import Select from 'react-select';

import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import TypeSearch from '@apricityhealth/web-common-lib/components/TypeSearch';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import JsonDialog from '../../dialogs/JsonDialog';
import ChangedByButton from '../../components/ChangedByButton';
import OverrideDialog from '../../dialogs/OverrideDialog';

class MedicationTypeView extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            progress: null,
            modified: false,
            type: { hidden: false }
        };
    }

    componentDidMount() {
        if (this.props.type) {
            this.loadType(this.props.type);
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.type !== oldProps.type) {
            this.loadType(this.props.type);
        }
    }

    closeType() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else
            this.props.onClose();
    }

    displayModifiedDialog(done) {
        this.setState(
        {
            dialog: <Dialog open>
                <DialogTitle>Medication Type Modified!</DialogTitle>
                <DialogContent>This medication type has been modified, would you like to save any changes?</DialogContent>
                <DialogActions>
                    <Button variant='contained' style={styles.button} onClick={e => {
                        this.setState({ modified: false, dialog: null });
                        this.saveType(done);
                    }}>Yes</Button>
                    <Button variant='contained' style={styles.button} onClick={e => {
                        this.setState({ modified: false, dialog: null }, done);
                    }}>No</Button>
                </DialogActions>
            </Dialog>
        });
    }

    closeSearch() {
        this.setState({ dialog: null });
    }

    loadType(type) {
        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadType.bind(this, type));
        }

        if (! type.options ) type.options = {};

        let options = [];
        for(let k in type.options) {
            options.push({key: k, value: type.options[k] });
        }
        this.setState({
            type: JSON.parse(JSON.stringify(type)),
            options,
            progress: null,
            dialog: null
        });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState(
        {
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    saveType(callback, planId = null) {
        const { appContext } = this.props;
        const { type, options } = this.state;

        if (!planId && type.planId && type.planId !== appContext.state.plan.planId) {
            console.log(`${type.planId} !== ${planId}`, appContext );
            return this.displayOverrideDialog(type.planId, this.saveType.bind(this, callback));
        }

        type.options = {};
        for(let i=0;i<options.length;++i)
            type.options[options[i].key] = options[i].value;
        type.keywords = type.keywords.filter((e) => e);     // remove any empty strings

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        appContext.stores.DataTypesStore.saveMedication(type, planId).then( type => {
            if (! type.options ) type.options = {};
            let options = [];
            for(let k in type.options) {
                options.push({key: k, value: type.options[k] });
            }
    
            this.setState({ modified: false, type, options, progress: null }, callback);
        }).catch( error => {
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    deleteType() {
        this.setState(
        {
            dialog: <div><Dialog open model='false'>
                <DialogTitle>Delete Medication: {`${this.state.type.name}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' style={styles.button}
                        onClick={this.cancelDeleteType.bind(this)}>Cancel</Button>
                    <Button variant='contained' style={styles.button}
                        onClick={this.confirmDeleteType.bind(this)}>Confirm</Button>
                </DialogActions>
            </Dialog></div>
        });
    }

    cancelDeleteType() {
        this.setState({ dialog: null });
    }

    confirmDeleteType() {
        const {
            props: { appContext },
            state: { type }
        } = this;

        this.setState({ dialog: null });
        if (type.medicationId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            appContext.stores.DataTypesStore.deleteMedication(type.medicationId, type.planId).then( () => {
                this.setState({ progress: null });
                this.closeType();
            }).catch( error => {
                this.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    updateType(item) {
        const { state: { type } } = this;

        type.name = item.name;
        type.description = item.description;
        type.code = item.code;
        type.system = item.system;

        this.setState({ dialog: null, type, modified: true });
    }

    handleCheckBoxChange = (e) => {
        const { state: { type } } = this,
            { target: { name } } = e;

        type[name] = ! type[name];
        this.setState({ dialog: null, type, modified: true });
    };

    showJSON() {
        const {
            props: { appContext },
            state: { type }
        } = this;

        this.setState(
        {
            dialog: <JsonDialog appContext={appContext} dataType={type}
                onEditDone={(type) => this.setState({ type, dialog: null })}
                onDone={() => this.setState({ dialog: null })}
            />
        });
    }

    search(args) {
        const { props: { appContext } } = this,
            { code, system, search } = args;

        this.setState(
        {
            progress: null,
            dialog: <Drawer open variant='persistent' anchor='right'>
                <TypeSearch
                    appContext={appContext}
                    searchType='medications'
                    code={code}
                    system={system}
                    search={search}
                    closeSearch={this.closeSearch.bind(this)}
                    itemSelected={this.updateType.bind(this)}
                />
            </Drawer>
        });
    }


    render() {
        const { appContext, types } = this.props;
        const { type, options, progress, dialog, error } = this.state;

        if (type.name === undefined) type.name = '';
        if (type.description === undefined) type.description = '';
        if (type.code === undefined) type.code = '';
        if (type.system === undefined) type.system = '';
        if (type.tags === undefined) type.tags = [];
        if (type.keywords === undefined) type.keywords = [];

        const selectParent = () => {
            const items = [
                { label: 'None', value: '' },
                ...types.map( m => ({ label: m.name, value: m.medicationId }) )
            ],
                selected = items.find( e => e.value === (type.parentId || '') );

            return <FormControl style={{ marginTop: 30, width: '500px' }}>
                <Select isSearchable options={items} value={selected}
                    onChange={select => {
                        type.parentId = select.value;
                        this.setState({ type });
                    }}
                />
                <InputLabel style={{ top: -40, position: 'absolute', width: 300 }
                    }>{'Parent Medication'}</InputLabel>
            </FormControl>;
        };
        const selectStatus = () => {
            const items = [
                { label: 'Active', value: 'active' },
                { label: 'Deprecated', value: 'deprecated' }
            ],
                selected = items.find(e => e.value === type.status);

            return <FormControl style={{ marginTop: 30, width: '500px' }}>
                <Select options={items} value={selected}
                    onChange={select => {
                        type.status = select.value;
                        this.setState({ type });
                    }}
                />
                <InputLabel style={{ top: -40, position: 'absolute', width: 300 }
                    }>{'Status'}</InputLabel>
            </FormControl>;
        }


        return <div align='center'>
            <AppBar position='static' style={styles.appBar}><Toolbar>
                <IconButton onClick={this.closeType.bind(this)}>
                    <NavigationClose />
                </IconButton>
                <Typography variant='h6' color='inherit'>Medication Type</Typography>
            </Toolbar></AppBar>
            <div align='left' style={styles.div}><table style={styles.table}><tbody>
                <tr>
                    <td><p>Medication ID: {type.medicationId}</p></td>
                    <td valign='top' align='right'>
                        <span style={{color: 'red'}}>{error}</span>
                        <IconButton disabled={progress !== null} onClick={this.saveType.bind(this, null, null)}>{progress || <SaveIcon />}</IconButton>
                        <IconButton onClick={this.showJSON.bind(this)}><CodeIcon /></IconButton>
                        <IconButton onClick={this.deleteType.bind(this)}><DeleteIcon />
                        </IconButton>
                    </td>
                </tr>
                <tr><td colSpan='2'>
                    <TextField label='Common Name' value={type.name} style={styles.question}
                        onChange={e => {
                            type.name = e.target.value;
                            this.setState({ type, modified: true });
                    }} />
                    <IconButton disabled={!type.name}
                        onClick={this.search.bind(this, { search: type.name })}><SearchIcon />
                    </IconButton>
                </td></tr>
                <tr><td colSpan='2'>
                    <TextField label='Description' value={type.description} style={styles.question}
                        onChange={e => {
                            type.description = e.target.value;
                            this.setState({ type, modified: true });
                        }} />
                    <IconButton disabled={!type.description}
                        onClick={this.search.bind(this, { search: type.description })}>
                            <SearchIcon />
                    </IconButton>
                </td></tr>
                <tr><td colSpan='2'>
                    <TextField label='Code ID' value={type.code} style={styles.question}
                        onChange={e => {
                            type.code = e.target.value; this.setState({ type, modified: true });
                        }} />
                    <IconButton disabled={!type.code}
                        onClick={this.search.bind(this, { system: type.system, code: type.code })}>
                            <SearchIcon />
                    </IconButton>
                </td></tr>
                <tr><td colSpan='2'>
                    <TextField label='System' value={type.system} style={styles.question}
                        onChange={e => {
                            type.system = e.target.value; this.setState({ type, modified: true });
                        }}
                    />
                </td></tr>
                <tr><td colSpan='2'>
                    <TextField label='Keywords' value={type.keywords.join(' ')} style={styles.question}
                        onChange={e => {
                            type.keywords = e.target.value.toLowerCase().split(' '); this.setState({ type, modified: true });
                        }}
                    />
                </td></tr>
                <tr><td colSpan='2'>{selectStatus()}</td></tr>
                <tr><td colSpan='2'>{selectParent()}</td></tr>
                <tr><td colSpan='2'>
                    <FormControl style={styles.status}>
                    <FormControlLabel label='Hidden' key='hidden' control={
                        <Checkbox name='hidden' checked={type.hidden}
                            onChange={this.handleCheckBoxChange.bind(this)} />
                    } />
                    </FormControl>
                </td></tr>
                <tr>
                    <td colSpan='2'>
                        <EnhancedTable
                            title='Options'
                            rowsPerPage={5}
                            orderBy='key'
                            columnData={[
                                { id: 'key', editType: 'text', label: 'Key' },
                                { id: 'value', editType: 'text', label: 'Value' }
                            ]}
                            data={options}
                            onDataChanged={(data, table) => {
                                this.setState({ options: data, modified: true });
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan='2'>
                        <ChangedByButton collection='MedicationTypeModel' appContext={appContext}
                            primaryKey={type.medicationId} />
                    </td>
                </tr>
            </tbody></table></div>
            {dialog}
        </div>;
    }
};


const styles = {
    appBar: { backgroundColor: '#FF9800', width: '1000px' },
    button: { margin: 10 },
    checkbox: { marginBottom: 16 },
    div: { margin: 10 },
    flex: { flex: 1 },
    openButton: { margin: 15 },
    question: { margin: 5, width: '80%' },
    select: { margin: 10, width: 200 },
    status: { margin: 5, width: '50%' },
    tab: { backgroundColor: 'lightblue' },
    tags: { margin: 5 },
    table: { width: '100%' },
    td: { textAlign: 'right' },
    text: { margin: 5 }
};


export default MedicationTypeView;