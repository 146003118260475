import React from "react";

import {
    Checkbox
} from '@material-ui/core/';

// CSS
import "../styles/content.css";
import "diff2html/dist/diff2html.min.css";

export class DiffRowCheckbox extends React.Component {
    static defaultProps = {
        checked: false
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps.checked !== this.props.checked) {
            return true
        } else {
            return false
        }
    }
    checkboxClicked(e) {
        const { onChange, id } = this.props
        e.preventDefault();
        e.stopPropagation();
        onChange(id)
    }
    render() {
        const { checked, id } = this.props
        return (
            <Checkbox
                key={id}
                checked={checked}
                onClick={this.checkboxClicked.bind(this)}
            />
        )
    }
}

export default DiffRowCheckbox;

