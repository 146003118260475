import React from "react";

import {
    CircularProgress,
    IconButton,
    Drawer,
    AppBar,
    Toolbar,
    Typography,
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';

import {
    loadQuestion,
    deleteQuestionErrors
} from '@apricityhealth/web-common-lib/utils/Services';

import JsonDialog from '../dialogs/JsonDialog';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

export default class QuestionErrorsView extends React.Component {
    constructor(props) {
        super(props);
        this.localExpanded = []
        this.state = {
            progress: null,
            dialog: null,
            errors: []
        }
    }

    componentDidMount() {
        this.loadQuestion( this.props.questionId );
    }
    componentDidUpdate(prevProps){
        if ( prevProps.questionId !== this.props.questionId ) {
            this.loadQuestion( this.props.questionId );
        }
    }

    loadQuestion( questionId ) {
        const self = this;
        this.setState({ progress: <CircularProgress size={20} />});
        loadQuestion( this.props.appContext, questionId, true ).then((result) => {
            console.log("loadQuestion result:", result );

            const question_errs = this.props.appContext.stores.DataTypesStore.getErrors('questions');
            const more_errors = question_errs.errors[questionId];
            if ( more_errors ) {
                for(let i=0;i<more_errors.length;++i) {
                    result.errors.push( { createDate: new Date().toISOString(), message: more_errors[i] } );
                }
            }

            self.setState({ questionId, progress: null, errors: result.errors });
        }).catch((err) => {
            console.error(err);
            self.setState({ progress: null, error: err.message} );
        });
    }

    clearErrors() {
        const self = this;
        this.setState({ progress: <CircularProgress size={20} />});
        deleteQuestionErrors( this.props.appContext, this.state.questionId ).then(() => {
            this.setState({ progress: null, errors: [] });
        }).catch((err) => {
            console.error(err);
            self.setState({ progress: null, error: err.message} );
        })
    }

    render() {
        const columnData = [
            { id: 'createDate', numeric: false, disabledPadding: false, label: 'Date' },
            { id: 'message', numeric: false, disabledPadding: false, label: 'Error' },
        ];

        const self = this;
        return <Drawer variant="persistent" anchor="right" open={true} >
            <div align="center" height="100%">
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={this.props.onClose}><NavigationClose /></IconButton>
                        <Typography variant="h6" color="inherit">Question Errors</Typography>
                    </Toolbar>
                </AppBar>
                <EnhancedTable
                    onSelected={(selected, table) => {
                        if ( selected.length > 0 ) {
                            let error = table.state.data[selected[0]];
                            console.log("onSelected:", error );
                            self.setState({
                                dialog: <JsonDialog
                                    appContext={self.props.appContext}
                                    dataType={error}
                                    onDone={() => {
                                        table.setState({selected: []});
                                        self.setState({ dialog: null });
                                    }} />
                            });
                        }
                    }}
                    disableActions={true}
                    disableMultiSelect={true}
                    rowsPerPage={10}
                    orderBy='createDate'
                    order="desc"
                    columnData={columnData}
                    data={this.state.errors}
                    title='Errors' />
            </div>
            <div align="right">
                <IconButton onClick={this.clearErrors.bind(this)}><DeleteIcon /></IconButton>
            </div>
            {this.state.error}
            {this.state.progress}
            {this.state.dialog}
        </Drawer>;
    }
};

