import React, { Component } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Dialog,
    IconButton,
    TextField,
    Button,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    CircularProgress,
    Select,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputLabel,
    MenuItem
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import NavigationClose from '@material-ui/icons/Close';
import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import { deleteGraph, saveGraph } from '@apricityhealth/web-common-lib/utils/Services';

import OverrideDialog from "../dialogs/OverrideDialog";
import ChangedByButton from '../components/ChangedByButton';

class GraphTypeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            graph: props.graph
        };
    }
    componentDidMount() {
        if (this.props.graph)
            this.loadGraph(this.props.graph);
    }
    componentDidUpdate(oldProps) {
        if (this.props.graph !== oldProps.graph)
            this.loadGraph(this.props.graph);
    }

    closeView() {
        console.log("closeView()", this);
        this.props.onClose();
    }

    loadGraph(graph) {
        console.log("loadGraph:", graph);

        if (! graph.tags) graph.tags = [];
        if (! graph.category ) graph.category = '';
        if (! graph.name ) graph.name = '';
        if (! graph.description ) graph.description = '';
        if ( graph.order === undefined ) graph.order = 0;
        if (! graph.graphType ) graph.graphType = 'line';
        if (! graph.dataId ) graph.dataId = '';
        
        this.setState({
            graph,
            progress: null,
            dialog: null
        });
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    saveGraph(planId = null) {
        const self = this;
        const { graph } = self.state;
        let { appContext } = this.props;

        if ( !planId && graph.planId && graph.planId !== appContext.state.plan.planId ) {
            return this.displayOverrideDialog( graph.planId, this.saveGraph.bind(this));
        }
        this.setState({ progress: <CircularProgress /> });

        saveGraph(appContext, graph, planId).then((response) => {
            console.log("saveGraph response:", response );
            self.setState({ override:false, graph: response, progress: null });
        }).catch((error) => {
            self.setState({ progress: error.message });
        });
    }

    deleteGraph() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete Graph: {`${self.state.graph.graphId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.cancelDelete.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={this.confirmDelete.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }
    cancelDelete() {
        this.setState({ dialog: null });
    }

    confirmDelete() {
        const self = this;
        const graph = this.state.graph;
        let { appContext } = this.props;
        self.setState({ dialog: null });
        if (graph.graphId) {

            this.setState({ progress: <CircularProgress /> });
            deleteGraph(appContext, graph).then(() => {
                self.setState({ progress: null });
                self.closeView();
            }).catch((error) => {
                console.log("deleteGraph error:", error.response);
                self.setState({ progress: error.message });
            });
        }
    }

    onSelectDataType(dataType) {
        let { graph } = this.state;
        graph.dataId = dataType ? dataType.dataId : '';
        this.setState({ graph });
    }
    onSelectTupleIndex(e) {
        let { graph } = this.state;
        graph.tupleIndex = `${e}`;
        this.setState({ graph });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        let { graph, progress, dialog } = this.state;

        return (
            <div align="center" >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Graph Type</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    <p>Graph ID: {graph.graphId}</p>
                                    <TextField style={styles.name} label="Name" value={graph.name}
                                        onChange={(e) => { graph.name = e.target.value; self.setState({ graph }); }} />
                                    <br />
                                    <TextField style={styles.description} label="Description" value={graph.description}
                                        onChange={(e) => { graph.description = e.target.value; self.setState({ graph }); }} />
                                    <br />
                                    <TextField style={styles.name} label="Category" value={graph.category}
                                        onChange={(e) => { graph.category = e.target.value; self.setState({ graph }); }} />
                                    <br />
                                    <TextField style={styles.name} label="Order" value={graph.order} type={'number'}
                                        onChange={(e) => { graph.order = e.target.value; self.setState({ graph }); }} />
                                    <br />
                                    <FormControl style={styles.select} >
                                        <InputLabel>Graph Type</InputLabel>
                                        <Select value={graph.graphType} onChange={(e) => { graph.graphType = e.target.value; self.setState({graph})}}>
                                            <MenuItem value="line">Line</MenuItem>
                                            <MenuItem value="scatter">Scatter</MenuItem>
                                            <MenuItem value="aeHM">Adverse Event Heat Map</MenuItem>
                                            <MenuItem value="steroidHM">Steroid Heat Map</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />

                                    <SelectDataType appContext={appContext} dataId={graph.dataId}
                                        onSelect={this.onSelectDataType.bind(this)} />
                                    <br />
                                    <SelectTupleIndex appContext={appContext} dataId={graph.dataId}
                                        tupleIndex={graph.tupleIndex} onChange={this.onSelectTupleIndex.bind(this)} />
                                    <br />
                                    <FormControlLabel control={<Checkbox checked={graph.disabled} onChange={(e,v) => {
                                        graph.disabled = v; self.setState({graph}) }} />} label="Disabled" />
                                    <br/>
                                    <ChangedByButton appContext={this.props.appContext} primaryKey={graph.graphId} collection='GraphModel' />
                                </td>
                                <td valign="top" align="right">
                                    <IconButton onClick={this.saveGraph.bind(this, null)}><SaveIcon /></IconButton>
                                    <IconButton onClick={this.deleteGraph.bind(this)}><DeleteIcon /></IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
                {progress}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    },
    button: {
        margin: 10
    },
    div: {
        margin: 10
    },
    name: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    },
    select: {
        margin: 5,
        width: 300
    },
    tags: {
        margin: 5
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default GraphTypeView;
