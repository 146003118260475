import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
    withStyles,
    Tab,
    Tabs,
    CircularProgress,
    IconButton
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';

import PubSub from 'pubsub-js'

import {
    loadRecommendationModels,
    loadRecommendations,
} from '@apricityhealth/web-common-lib/utils/Services';

import RecommendationModelReportView from './RecommendationModelReportView';
import RecommendationsReportView from './RecommendationsReportView'
import RecommendationFlattenedView from './RecommendationFlattenedView'

class RecommendationsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendModels: [],
            recommendations: [],
            currentTab: 0,
            progress: null
        };

    }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
        this.loadContent();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { appContext } = this.props;

        let promises = [];
        promises.push(loadRecommendations(appContext, { dependencies: true} ));
        promises.push(loadRecommendationModels(appContext, { dependencies: true} ));

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Promise.all(promises).then((response) => {
            let recommendations = response[0];
            let recommendModels = response[1];
            self.setState({ recommendations, recommendModels, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: error.message });
        });

    }

    render() {
        let { recommendations, recommendModels, currentTab, progress, error } = this.state;
        let self = this;

        return <table style={{ width: '100%' }}><tbody>
            <tr>
                <td></td>
                <td align='right'>
                    {error}
                    <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <Tabs value={currentTab} onChange={(e, t) => self.setState({ currentTab: t })}>
                        <Tab label="Recommendations" />
                        <Tab label="Recommendations Flattened" />
                        <Tab label="Recommendation Groups" />
                    </Tabs>
                    {currentTab === 0 && <RecommendationModelReportView appContext={this.props.appContext} model={recommendModels} />}
                    {currentTab === 1 && <RecommendationFlattenedView appContext={this.props.appContext} models={recommendModels} />}
                    {currentTab === 2 && <RecommendationsReportView appContext={this.props.appContext} recommendations={recommendations} models={recommendModels} />}
                </td>
            </tr>
        </tbody>
        </table>;
    }
}




const styles = {
    div: {
        margin: 10
    },
    progress: {
        color: 'red'
    }
}

RecommendationsReport.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(RecommendationsReport);
