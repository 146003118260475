import {
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';
import { reject } from 'lodash';

import languages from '../data/Languages.json';
import QuestionsDataTypes from './QuestionDataTypes'

export default class QuestionUtils {
    constructor(appContext, language = null, texts = null) {
        if (!appContext || !appContext.state) throw new Error("QuestionUtils needs a valid appContext with state.");
        if (!language) language = appContext.state.language || 'en-us';
        if (!texts) texts = appContext.stores.DataTypesStore.getTextHash();

        this.state = {
            appContext,
            language,
            texts
        }
    }

    getText(textId, language = null) {
        //console.log(`getText ${textId}, language: ${language}:`, this );
        const { texts } = this.state;
        if (!language) language = this.state.language || 'en-us';
        if (texts[language]) {
            if (texts[language][textId]) {
                return texts[language][textId]
            }
        }
        return {
            textId,
            language,
            text: ``,
            version: 0,
            category: 'dialog'
        };
    }

    unlocalizeQuestion(question) {
        try {
            //this will convert all the textId references and make them actual text for the purpose of editing.
            for (let i = 0; i < question.question.length; ++i) {
                question.question[i].text = this.getText(i > 0 ? question.questionId + `.${i}` : question.questionId).text;
            }
            question.explanation = this.getText(question.questionId + '.explanation').text;
            for (let i = 0; i < question.answers.length; ++i) {
                let answer = question.answers[i];
                let textId = question.questionId + '.' + answer.answerId;

                answer.explanation = this.getText(textId + '.explanation').text;
                answer.category = this.getText(textId + ".category").text;
                for (let j = 0; j < answer.text.length; ++j) {
                    answer.text[j] = this.getText(j > 0 ? textId + `.${j}` : textId).text;
                }
            }
        }
        catch (err) {
            console.error("unlocalizeQuestion error:", err);
        }
        return question;
    }


    deleteQuestion(question, cleanText = true) {
        const { appContext } = this.state;
        const store = appContext.stores.DataTypesStore;
        let promises = [
            store.deleteQuestion(question.questionId)
        ];
        const { planId } = question;

        if (cleanText) {
            //delete all text content. This will delete all languages as well.
            for (let i = 0; i < question.question.length; ++i) {
                if (question.question[i]) {
                    let textId = i > 0 ? `${question.questionId}.${i}` : `${question.questionId}`;
                    promises.push(store.deleteText(textId, null, planId));
                }
            }

            //delete all text explanation content
            if (question.explaination)
                promises.push(store.deleteText(question.questionId + ".explanation", null, planId));

            let answers = question.answers;
            if (isArrayValid(answers)) {
                answers.forEach(answer => {
                    let answerId = answer.answerId;
                    for (let i = 0; i < answer.text.length; ++i) {
                        if (answer.text[i]) {
                            let textId = i > 0 ? `${question.questionId}.${answerId}.${i}` : `${question.questionId}.${answerId}`;
                            promises.push(store.deleteText(textId, null, planId));
                        }
                    }
                    //delete all explanation text content
                    if (answer.explanation)
                        promises.push(store.deleteText(question.questionId + '.' + answerId + ".explanation", null, planId));
                    if (answer.category)
                        promises.push(store.deleteText(question.questionId + '.' + answerId + ".category", null, planId));
                });
            }
        }

        return Promise.all(promises);
    }

    saveQuestion = async (question, originalQuestion = null, cloneQuestion = false, planId = null, updateDataTypes = true) => {
        const self = this;
        const { appContext, language } = this.state;
        const store = appContext.stores.DataTypesStore;
        if (!planId) {
            if (!appContext.state.plan) return reject(new Error("No valid plan selected!"));
            planId = appContext.state.plan.planId;
        }
        if (!originalQuestion) originalQuestion = JSON.parse(JSON.stringify(question));
        const originalQuestionId = originalQuestion.questionId;

        let promises = [];

        // If clone and id matches original, make new ID
        if (cloneQuestion && originalQuestionId === question.questionId) {
            return Promise.reject(new Error("questionId must be changed when trying to clone a question."))
        }

        function localizeText(localize, oldId, newId) {
            if (localize) {
                let text = self.getText(oldId);
                //if id has changed or text has changed
                if (localize !== text.text || oldId !== newId || text.planId !== planId) {
                    //update version if text has changed - new ids do not bump versions
                    if (localize !== text.text) {
                        text.version = text.version + 1;
                    }
                    text.text = localize;
                    text.textId = newId
                    promises.push(store.saveText(text, planId));
                }
                //create other language files if cloning or ID has changed
                if (cloneQuestion || originalQuestionId !== question.questionId) {
                    for (let lang in languages) {
                        if (lang !== language) {
                            let text = self.getText(oldId, lang);
                            if (text.text !== '') {
                                text.textId = newId;
                                promises.push(store.saveText(text, planId));
                            }
                        }
                    }
                    if (!cloneQuestion && oldId)   // if we are not cloning, then delete the old text entries
                        promises.push(store.deleteText(oldId, null, planId));
                }
                return `[[${newId}]]`;
            } else {
                if (!cloneQuestion && oldId) {
                    // only delete language specific strings in this case, since we may be editing a question with no language translation are we don't want it to delete the en-us
                    if (language !== 'en-us')
                        promises.push(store.deleteText(oldId, language, planId));
                    else
                        promises.push(store.deleteText(oldId, null, planId));
                }
                return '';
            }
        }

        for (let i = 0; i < question.question.length; ++i) {
            let oldId = i > 0 ? originalQuestionId + `.${i}` : originalQuestionId;
            let newId = i > 0 ? question.questionId + `.${i}` : question.questionId;
            question.question[i].text = localizeText(question.question[i].text, oldId, newId);
        }

        if (originalQuestion.question.length > question.question.length) {
            for (let i = question.question.length; i < originalQuestion.question.length; ++i) {
                let oldId = i > 0 ? originalQuestionId + `.${i}` : originalQuestionId;
                promises.push(store.deleteText(oldId, null, originalQuestion.planId));
            }
        }

        question.explanation = localizeText(question.explanation, originalQuestionId + '.explanation', question.questionId + '.explanation');

        for (let i = 0; i < question.answers.length; ++i) {
            let answer = question.answers[i];
            let originalAnswer = originalQuestion.answers.length > i ? originalQuestion.answers[i] : null;

            let oldId = originalAnswer ? originalQuestionId + '.' + originalAnswer.answerId : originalQuestionId + '.' + answer.answerId;
            let newId = question.questionId + '.' + answer.answerId;

            for (let j = 0; j < answer.text.length; ++j) {
                let oldAnswerTextId = j > 0 ? oldId + `.${j}` : oldId;
                let newAnswerTextId = j > 0 ? newId + `.${j}` : newId;
                answer.text[j] = localizeText(answer.text[j], oldAnswerTextId, newAnswerTextId);
            }

            answer.explanation = localizeText(answer.explanation, oldId + ".explanation", newId + ".explanation");
            answer.category = localizeText(answer.category, oldId + ".category", newId + ".category");

            if (originalAnswer !== null && originalAnswer.text.length > answer.text.length) {
                for (let j = answer.text.length; j < originalAnswer.text.length; ++j) {
                    let oldAnswerTextId = j > 0 ? oldId + `.${j}` : oldId;
                    promises.push(store.deleteText(oldAnswerTextId, null, planId));
                }
            }
        }

        //if answers were deleted, find what text to delete
        if (originalQuestion.answers.length > question.answers.length) {
            for (let i = question.answers.length; i < originalQuestion.answers.length; ++i) {
                let answer = originalQuestion.answers[i];
                let oldId = originalQuestionId + '.' + answer.answerId;
                for (let j = 0; j < answer.text.length; ++j) {
                    let oldAnswerTextId = j > 0 ? oldId + `.${j}` : oldId;
                    promises.push(store.deleteText(oldAnswerTextId, null, planId));
                }
            }
        }

        promises.push(store.saveQuestions(question, planId));
        console.debug("Question Util: Saving question");
        let results = await Promise.all(promises);
        console.debug(`Question util:  question saved`)
        if (updateDataTypes === true) {
            console.debug(`Updating data types for question`)
            results = await QuestionsDataTypes.updateDataTypesForQuestion(this.state.appContext, question);
        }
        console.debug(`Question utils done saving`)
        return results;
    }
}
