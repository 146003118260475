import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
} from '@material-ui/core/';

import { Diff2Html } from "diff2html";
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import "diff2html/dist/diff2html.min.css";

const Diff = require('diff');
const Stringify = require('json-stable-stringify');

class DiffDialog extends React.Component {
    constructor(props) {
        super(props);

        if (! props.appContext ) throw new Error("appContext property missing.");
        if (! props.type ) throw new Error("type property missing.");
        if (! props.onLoadType ) throw new Error("onLoadType property missing");
        if (! props.onClose ) throw new Error("onClose property missing");

        this.state = {
            types: [],
            diffs: []
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    cleanRecord( record ) {
        if ( record ) {
            delete record._id;
            delete record.__v;
            delete record._version;
            delete record.createdAt;
            delete record.updatedAt;
            delete record.createDate;
            delete record.planId;
            delete record.userId;
            delete record.override;
            delete record.ownerId;
    
            for(let prop in record ) {
                if ( Array.isArray(record[prop])) {
                    let arr = record[prop];
                    for(let i=0;i<arr.length;++i) {
                        this.cleanRecord( arr[i] );
                    }
                }
                else if ( typeof record[prop] === 'object' ) {
                    this.cleanRecord( record[prop] );
                }
            }
        }
        return record;
    }

    diffTypes( src, target ) {
        console.log("diffTypes:", src, target );
        let { plans } = this.props.appContext.state;

        function getPlanName( plans, planId ) {
            let plan = plans.find((e) => e.planId === planId );
            if ( plan ) 
                return plan.title;
            return '?';
        }

        let srcFile = getPlanName(plans, src.planId) + '/Source.json';
        let srcContent = Stringify( this.cleanRecord( { ...src } ), {space: 4});
        let targetFile = getPlanName(plans, target.planId) + '/Target.json';
        let targetContent = Stringify( this.cleanRecord( { ...target }), {space: 4} );
        
        let diff = Diff.createTwoFilesPatch( targetFile, srcFile, targetContent, srcContent );
        //console.log("createTwoFilesPatch result:", diff, targetContent, srcContent );
        return diff;
    }
    
    
    loadContent() {
        let { plans } = this.props.appContext.state;
        let { type } = this.props;

        function enumPlanIds( planId, plans, planIds ) {
            if ( planIds.indexOf(planId) < 0 )
                planIds.push( planId );

            let plan = plans.find((e) => e.planId === planId);
            if ( plan ) {
                for(let i=0;i<plan.dependencies.length;++i) {
                    enumPlanIds( plan.dependencies[i], plans, planIds );
                }
            }
        }

        let planIds = [];
        enumPlanIds( type.planId, plans, planIds );

        let promises = [];
        for(let i=0;i<planIds.length;++i) {
            if ( planIds[i] === type.planId ) continue;
            promises.push( this.props.onLoadType( planIds[i], type) );
        }

        this.setState({progress: <CircularProgress />, error: null })
        Promise.all(promises).then((results) => {
            let diffs = [];
            let types = [];
            for(let i=0;i<results.length;++i) {
                if (! results[i] ) continue;
                types.push( results[i] );
                diffs.push( this.diffTypes( type, results[i] ) );
            }        
            
            this.setState({ progress: null, types, diffs});
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err) });
        });
    }

    render() {
        const self = this;
        let { plans } = this.props.appContext.state;
        let { types, diffs, progress, error } = this.state;

        let diffDivs = [];
        if ( diffs ) {
            for(let i=0;i<diffs.length;++i) {
                if (! diffs[i] ) continue;
                let html = Diff2Html.getPrettyHtml( diffs[i], { inputFormat: 'diff', showFiles: false, matching: 'lines', 
                    renderNothingWhenEmpty: true, outputFormat: 'side-by-side' } );
                diffDivs.push(<div key={i} dangerouslySetInnerHTML={{ __html: html }} />)
            }
        }

        let mergeButtons = [];
        if ( types ) {
            for(let i=0;i<types.length;++i) {
                let type = types[i];
                let plan = plans.find((e) => e.planId === type.planId );
                if (! plan ) continue;

                mergeButtons.push(<Button key={i} variant="contained" style={styles.button} onClick={() => {
                    self.props.onDelete(type.planId);
                    }}>Delete from {plan.title}</Button>)
                mergeButtons.push(<Button key={i} variant="contained" style={styles.button} onClick={() => {
                    self.props.onMerge(type.planId);
                    }}>Merge into {plan.title}</Button>)
            }
        }

        return (
            <Dialog open={true} maxWidth='lg' fullWidth={true}>
                <DialogTitle>Override Differences</DialogTitle>
                <DialogContent>
                    {progress}
                    {error}
                    {diffDivs}
                </DialogContent>
                <DialogActions>
                    {mergeButtons}
                    <Button variant="contained" style={styles.button} onClick={() => this.props.onDelete() }>Delete Override</Button>
                    <Button variant="contained" style={styles.button} onClick={() => this.props.onClose() }>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = {
    div: {
        margin: 10
    },
    button: {
        margin: 10
    }
};

export default DiffDialog;
