import React, { Component } from 'react';
import {
    Drawer,
    CircularProgress,
    TextField,
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import ValidIcon from '@material-ui/icons/CheckCircle';
import PubSub from 'pubsub-js';

import RecommendModelView from './RecommendModelView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import { loadRecommendationModels, loadRecommendations } from '@apricityhealth/web-common-lib/utils/Services';
import { validateRecommendModels } from '../utils/RecommendModelValidator';
import EditableNote from '../components/EditableNote'

export class RecommendModelsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            models: [],
            filtered: null,
            progress: null,
            dependencies: true
        }
    }

    componentDidMount() {
        const self = this;
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        })
        this.loadContent();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        const { dependencies } = this.state;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        Promise.all([
            loadRecommendationModels(this.props.appContext, { dependencies }),
            loadRecommendations(this.props.appContext, { dependencies: true })
        ]).then(([models, recommendations]) => {
            validateRecommendModels(this.props.appContext, models, recommendations);
            self.setState({ progress: null, models, recommendations }, self.updateFilter.bind(self));
        }).catch((err) => {
            console.error("loadRecommendModels error:", err);
            self.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    testFilter(filter, model) {
        if (model) {
            filter = filter.toLowerCase();
            if (model.modelId && model.modelId.toLowerCase().indexOf(filter) >= 0)
                return true;
            if (model.name && model.name.toLowerCase().indexOf(filter) >= 0)
                return true;
            if (model.description && model.description.toLowerCase().indexOf(filter) >= 0)
                return true;
        }
        return false;
    }

    updateFilter() {
        const { models, filter } = this.state;
        if (filter) {
            let filtered = [];
            for (let i = 0; i < models.length; ++i) {
                if (this.testFilter(filter, models[i]))
                    filtered.push(models[i]);
            }

            this.setState({ filtered }, this.handleOpen.bind(this));
        }
        else {
            this.setState({ filtered: null }, this.handleOpen.bind(this));
        }
    }

    handleOpen() {
        // automatically open the question by ID if provided..
        let parts = window.location.pathname.slice(1).split('/');
        if (parts.length > 1) {
            window.history.replaceState(null, '', window.location.href.substring(0, window.location.href.lastIndexOf('/')));
            this.openModel(parts[1]);
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    typeSelected(s, t) {
        console.log("typeSelected:", s, t);
        const self = this;
        const { filtered, models } = this.state;

        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const model = filtered ? filtered[s[0]] : models[s[0]];
            this.openModel(model.modelId);
        }
        else {
            // nothing selected..
            self.setState({ dialog: null });
        }
    }

    openModel(modelId) {
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <RecommendModelView appContext={this.props.appContext} modelId={modelId} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    createType() {
        console.log("createType");
        const { appContext } = this.props;
        var dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <RecommendModelView appContext={appContext} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    render() {
        const self = this;
        const { models, filter, filtered, dependencies } = this.state;
        const { dialog, error, progress } = this.state;
        const errors = this.props.appContext.stores.DataTypesStore.getErrors(`recommend_models`).errors;

        const columnData = [
            {
                id: 'modelId', width: 25, label: 'Valid', formatValue: (v) => {
                    return errors[v] ? <Tooltip key='error' title={errors[v].join(', ')}><WarningIcon style={{ fill: 'red' }} /></Tooltip> : <ValidIcon key='valid' style={{ fill: 'green' }} />;
                }
            },
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'modelId', numeric: false, disabledPadding: false, label: 'Model ID' },
            {
                id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                    return <Plan appContext={this.props.appContext} planId={v} />
                }
            }
        ];

        return (
            <table width='100%'>
                <tbody>
                    <tr>
                        <td>
                            <TextField
                                label="Filter"
                                style={{ margin: 5, width: 500 }}
                                value={filter}
                                name='filter'
                                onChange={(e) => {
                                    self.setState({ filter: e.target.value }, self.updateFilter.bind(self));
                                }}
                            />
                            <FormControlLabel
                                style={{ margin: 5 }}
                                control={<Checkbox
                                    checked={dependencies}
                                    name='dependencies'
                                    onChange={(e, v) => {
                                        self.setState({ dependencies: v }, self.loadContent.bind(self));
                                    }}
                                />}
                                label="Show Dependencies"
                            />
                        </td>
                        <td align="right">
                            {error}
                            <EditableNote textId={`recommendModelsTooling`} planId={"tooling"} />
                            <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <EnhancedTable
                                disableMultiSelect={true}
                                onTable={table => self._types = table}
                                onSelected={(s, t) => self.typeSelected(s, t)}
                                onAdd={() => self.createType()}
                                orderBy='name'
                                columnData={columnData}
                                data={filtered ? filtered : models}
                                title='Recommendation Models' />
                            {dialog}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default RecommendModelsView;