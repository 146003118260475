import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import {
    Drawer,
    TextField,
    CircularProgress,
    IconButton
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';

import {
    toBoolean
} from '@apricityhealth/web-common-lib/utils/Utils';

import {
    loadPlans
} from '@apricityhealth/web-common-lib/utils/Services'

import { v4 as uuidv4 } from 'uuid';

import PlanView from './PlanView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";

function testFilter(filter, plan) {
    if (plan) {
        filter = filter.toLowerCase();
        if (plan.name && plan.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (plan.description && plan.description.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (plan.planId && plan.planId.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class PlansView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            plans: [],
            program: props.program || false
        }

    }

    componentDidMount() {
        this.loadContent();
        this.subscription = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanUpdated' && data.plan ) {
                const { plans } = this.state;
                const update = plans.findIndex((e) => e.planId === data.plan.planId);
                if ( update >= 0 ) {
                    plans[update] = data.plan;
                    this.setState({plans});
                }
            }
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscription);
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.program !== this.props.program ) {
            this.setState({plans: [], program: this.props.program}, this.loadContent.bind(this));
        }
    }

    loadContent() {
        const self = this;
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        loadPlans(this.props.appContext, null, [ `program=${this.state.program}` ]).then((response) => {
            self.setState({ plans: response, progress: null }, self.updateFilter.bind(self));
        }).catch(function (error) {
            self.setState({ progress: null, error: error.message });
        });
    }

    updateFilter() {
        let { plans, filter } = this.state;
        if (filter) {
            let filtered = [];
            for (let i = 0; i < plans.length; ++i) {
                if (testFilter(filter, plans[i]))
                    filtered.push(plans[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null }, this.loadContent.bind(this));
        if (this._plans) {
            this._plans.setState({ selected: [] });        // unselect the plan
        }
    }

    typeSelected(s, t) {
        console.log("typeSelected:", s, t);
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const appContext = this.props.appContext;
            const plan = this.state.filtered ? this.state.filtered[s[0]] : this.state.plans[s[0]];
            let dialog =
                <Drawer variant="persistent" anchor="right" open={true} >
                    <PlanView appContext={appContext} create={false} plan={plan}
                        onSave={(plan) => {
                            const { plans } = this.state;
                            const update = plans.findIndex((e) => e.planId === plan.planId);
                            if (update >= 0) {
                                plans[update] = plan;
                                this.setState({ plans })
                            }
                        }}
                        onClose={this.onCloseDialog.bind(this)}
                    />
                </Drawer>;
            this.setState({ dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }


    createType() {
        const self = this;
        const { appContext } = this.props;
        const { plans, program } = this.state;
        let planId = uuidv4();
        const newPlan = { planId, title: "", contentId:"", description: '', protected: false, program, dependencies: [], siblings: [] };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <PlanView appContext={appContext} create={true} plans={plans}  plan={newPlan} onClose={() => self.onCloseDialog()} />
        </Drawer>;
        this.setState({ dialog });
    }


    render() {
        const self = this;
        const { filtered, plans, dialog, error, progress, program } = this.state;
        let { filter } = this.state;
        const columnData = [
            { id: 'title', numeric: false, disabledPadding: false, label: 'Title' },
            { id: 'shortTitle', numeric: false, disabledPadding: false, label: 'Short Title' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID' },
            { id: 'contentId', numeric: false, disabledPadding: false, label: 'Content ID' },
            { id: 'protected', numeric: false, disabledPadding: false, label: 'Is Protected', formatValue: (v) => toBoolean(v) ? 'Yes' : 'No' },
            { id: 'inactive', numeric: false, disabledPadding: false, label: 'Inactive', formatValue: (v) => toBoolean(v) ? 'Yes' : 'No' },
        ];

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                            onChange={(e) => { self.setState({ filter: e.target.value }, self.updateFilter.bind(self)); }} />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._plans = table}
                            onSelected={(s, t) => self.typeSelected(s, t)}
                            onAdd={() => self.createType()}
                            orderBy='title'
                            columnData={columnData}
                            data={filtered ? filtered : plans}
                            title={program ? 'Programs' : 'Plans' } />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

export default PlansView;
