import React, { Component } from 'react';
import { 
    Drawer, 
    CircularProgress, 
    TextField,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import Config from '@apricityhealth/web-common-lib/Config';
import Axios from 'axios';
import AlertLevelView from './AlertLevelView'
import PubSub from 'pubsub-js'

function testFilter(filter, model) {
    if (model) {
        filter = filter.toLowerCase();
        if (model.alertLevelId && model.alertLevelId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.name && model.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (model.description && model.description.toLowerCase().indexOf(filter) >= 0)
            return true;
    }
    return false;
}

export class AlertLevelsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            types: []
        }
    }

    componentDidMount() {
        const self = this;
        this.loadContent();
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                self.loadContent();
            }
        });
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.triage !== this.props.triage) {
            this.loadContent();
        }
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const self = this;
        let { appContext, triage } = this.props;
        let { dependencies } = this.state;
        let { plan, idToken } = appContext.state;

        if (plan) {
            self.setState({ plan, progress: <CircularProgress size={20} />, error: null });

            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}types/${plan.planId}/alert_levels/*?dependencies=${dependencies}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };

            console.log("loadContent request", request);
            Axios(request).then((response) => {
                console.log("loadContent response:", response);
                let types = response.data.filter((e) => e.triage === triage );
                self.setState({ types, progress: null }, self.updateFilter.bind(self));
            }).catch((error) => {
                console.log("loadContent error:", error.response);
                self.setState({ types: [], progress: null, error: error.message })
            });
        }
    }

    updateFilter() {
        let { types, filter } = this.state;
        if (filter) {
            var filtered = [];
            for (var i = 0; i < types.length; ++i) {
                if (testFilter(filter, types[i]))
                    filtered.push(types[i]);
            }
            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });        // unselect the question
    }

    typeSelected(s, t) {
        console.log("typeSelected:", s, t);
        if (!t)
            throw new Error("t is null");
        if (s.length > 0) {
            const { appContext, triage } = this.props;
            const type = this.state.filtered ? this.state.filtered[s[0]] : this.state.types[s[0]];
            let dialog = <Drawer variant="persistent" anchor="right" open={true}>
                <AlertLevelView appContext={appContext} alertLevelId={type.alertLevelId} triage={triage} onClose={this.onCloseDialog.bind(this)} />
            </Drawer>;
            this.setState({ dialog });
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }
    createType() {
        console.log("createType");
        const { appContext, triage } = this.props;
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <AlertLevelView appContext={appContext} triage={triage} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }
    render() {
        const self = this;
        const { filtered, types, dialog, error, progress } = this.state;
        let { filter, dependencies } = this.state;

        const columnData = [
            { id: 'alertLevelId', numeric: false, disabledPadding: false, label: 'Alert Type Id' },
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'priority', numeric: true, disabledPadding: false, label: 'Priority' },
            { id: 'order', numeric: true, disabledPadding: false, label: 'Order' },
            { id: 'color', numeric: false, disabledPadding: false, label: 'Color' },
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width='100%'><tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 500 }} value={filter} label="Filter"
                            onChange={(e) => { filter = e.target.value; self.setState({ filter }, self.updateFilter.bind(self)); }} />
                        <FormControlLabel style={{margin: 5}} control={<Checkbox checked={dependencies} onChange={(e,v) => {
                                self.setState({dependencies: v}, self.loadContent.bind(self));
                            }} />}label="Show Dependencies" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={true}
                            onTable={table => self._types = table}
                            onSelected={(s, t) => self.typeSelected(s, t)}
                            onAdd={() => self.createType()}
                            orderBy='name'
                            columnData={columnData}
                            data={filtered ? filtered : types}
                            title='Levels' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

export default AlertLevelsView;