import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Dialog,
    IconButton,
    TextField,
    Button,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    CircularProgress,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Switch,
    Input
} from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';

import NavigationClose from '@material-ui/icons/Close';

import EditConditions from "@apricityhealth/web-common-lib/components/EditConditions";
import { deleteDataModel, saveDataModel } from '@apricityhealth/web-common-lib/utils/Services';

import OverrideDialog from "../dialogs/OverrideDialog";
import JsonDialog from '../dialogs/JsonDialog';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import EditDataTypeDialog from '../dialogs/EditDataTypeDialog';

import { parseCode } from '@apricityhealth/web-common-lib/utils/ParseCode';
import { buildCode } from '@apricityhealth/web-common-lib/utils/BuildCode';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import createIdFromText from '../utils/CreateIdFromText';
import ChangedByButton from '../components/ChangedByButton';
import SelectMedication from '@apricityhealth/web-common-lib/components/SelectMedication';

class EditValueDialog extends React.Component {
    constructor(props) {
        super(props);

        let value = props.value;
        let code = { functions: [], groups: [] };
        try {
            code = parseCode(value);
        } catch(e) {}

        this.state = { 
            value,
            code
        }
    }

    componentDidUpdate(oldProps) {
        if ( this.props.value !== oldProps.value) {
            let value = this.props.value;
            let code = { functions: [], groups: [] };
            try {
                code = parseCode(value);
            } catch(e) {}
                
            this.setState({ value, code });
        }
    }

    getFunctions(model, value) {
        function validateArg(func, i) {
            while (func.args.length <= i) {
                func.args.push({ string: '', type: "'" });
            }
        }

        return {
            "getValue": {
                label: 'Get Data Value',
                template: `getValue(context,'${model.dataId}',0)`,
                isFunction: ( value, code) => {
                    return code && code.functions.length === 1 && code.functions[0].functionName === 'getValue';
                },
                editControls: ( appContext, model, value, code, onChange) => {
                    let func = code.functions[0];
                    validateArg( func, 4);
                    
                    let controls = [];
                    let dataId = func.args[1].string || '';
                    let tupleIndex = func.args[2].name || func.args[2].string || 0;
                    controls.push( <SelectDataType key={'dataType'} label='Select Data Type' style={styles.select} appContext={appContext} dataId={dataId}
                        onSelect={(dataType) => {
                            func.args[1] = { string: dataType ? dataType.dataId : '', type: "'" };
                            onChange( buildCode(code) );
                        }} /> );
                    controls.push( <SelectTupleIndex key='tupleIndex' style={styles.select} appContext={appContext} dataId={dataId}
                        tupleIndex={tupleIndex}
                        onChange={(tupleIndex) => {
                            func.args[2] = { name: tupleIndex };
                            onChange( buildCode(code) );
                        }} /> );
            
                    controls.push( <br key='br1' /> );
                    controls.push( <FormControlLabel
                        style={{margin: 5}}
                        key={'inGroup'}
                        value="top"
                        control={
                            <Switch
                                color="primary"
                                checked={func.args[3] !== undefined ? String(func.args[3].string || func.args[3].name) === 'true' : false}
                                input={<Input name="inGroup" />}
                                onChange={(e) => {
                                    func.args[3] = { string: `${e.target.checked}`, type: "'" };
                                    onChange( buildCode(code) );
                                }}
                            />}
                        label="Same Day"
                        labelPlacement="top"
                    /> );

                    controls.push( <FormControlLabel
                        style={{margin: 5}}
                        key={'inSession'}
                        value="top"
                        control={
                            <Switch
                                color="primary"
                                checked={func.args[4] !== undefined ? String(func.args[4].string || func.args[4].name) === 'true' : false}
                                input={<Input name="inSession" />}
                                onChange={(e) => {
                                    func.args[4] = { string: `${e.target.checked}`, type: "'" };
                                    onChange( buildCode(code) );
                                }}
                            />}
                        label="Same Session"
                        labelPlacement="top"
                    /> );

                    return controls;
                }
            },
            "calculateVaccinationStatus": {
                label: 'Calculate Vaccination Status',
                template: `calculateVaccinationStatus(context,'','', 0)`,
                isFunction: ( value, code) => {
                    return code && code.functions.length === 1 && code.functions[0].functionName === 'calculateVaccinationStatus';
                },
                editControls: ( appContext, model, value, code, onChange) => {
                    let func = code.functions[0];
                    validateArg( func, 4);
                    
                    let controls = [];
                    let medicationId = func.args[1].string || '';
                    let doseDataId = func.args[2].string || '';
                    let doseTupleIndex = func.args[3].name || func.args[2].string || 0;
                    controls.push( <SelectMedication key='medication' label='Select Medication' style={styles.select} appContext={appContext} medicationId={medicationId} 
                        onSelect={(med) => {
                            func.args[1] = { string: med ? med.medicationId : '', type: "'" };
                            onChange( buildCode(code) );
                        }} /> );
                    controls.push( <SelectDataType key={'dataType'} label='Select Data Type' style={styles.select} appContext={appContext} dataId={doseDataId}
                        onSelect={(dataType) => {
                            func.args[2] = { string: dataType ? dataType.dataId : '', type: "'" };
                            onChange( buildCode(code) );
                        }} /> );
                    controls.push( <SelectTupleIndex key='tupleIndex' style={styles.select} appContext={appContext} dataId={doseDataId}
                        tupleIndex={doseTupleIndex}
                        onChange={(tupleIndex) => {
                            func.args[3] = { name: tupleIndex };
                            onChange( buildCode(code) );
                        }} /> );
            
                    return controls;
                }
            },
            "calculateConsecutiveDays": {
                label: 'Duration',
                template: `calculateConsecutiveDays( context, '${model.dataId}', 0, 1, 4)`,
                isFunction: ( value, code) => {
                    return code && code.functions.length === 1 && code.functions[0].functionName === 'calculateConsecutiveDays';
                },
                editControls: ( appContext, model, value, code, onChange) => {
                    let controls = [];
                    let func = code.functions[0];
                    let dataId = func.args[1].string || '';
                    let tupleIndex = func.args[2].name || func.args[2].string || 0;
                    let min = func.args[3].name || func.args[3].string || 1;
                    let max = func.args[4].name || func.args[4].string || 4;
                    controls.push( <SelectDataType key={1} label='Select Data Type' style={styles.select} appContext={appContext} dataId={dataId}
                        onSelect={(dataType) => {
                            func.args[1] = { string: dataType ? dataType.dataId : '', type: "'" };
                            onChange( buildCode(code) );
                        }} /> );
                    controls.push( <SelectTupleIndex key='tupleIndex' style={styles.select} appContext={appContext} dataId={dataId}
                        tupleIndex={tupleIndex}
                        onChange={(tupleIndex) => {
                            func.args[2] = { name: tupleIndex };
                            onChange( buildCode(code) );
                        }} /> );
                    controls.push(<TextField key={'min'} type='number' style={styles.number} value={min} label='Min'
                        onChange={(e) => {
                            func.args[3] = { name: e.target.value };
                            onChange( buildCode(code) );
                        }} /> );
                    controls.push(<TextField key={'max'} type='number' style={styles.number} value={max} label='Max'
                        onChange={(e) => {
                            func.args[4] = { name: e.target.value };
                            onChange( buildCode(code) );
                        }} /> );
        
                    return controls;
                }
            },
            "calculateConsecutiveDaysSame": {
                label: 'Duration Same',
                template: `calculateConsecutiveDaysSame( context, '${model.dataId}', 0, data[0] )`,
                isFunction: ( value, code) => {
                    return code && code.functions.length === 1 && code.functions[0].functionName === 'calculateConsecutiveDaysSame';
                },
                editControls: ( appContext, model, value, code, onChange) => {
                    let controls = [];
                    let func = code.functions[0];
                    let dataId = func.args[1].string || '';
                    let tupleIndex = func.args[2].name || func.args[2].string || 0;
                    controls.push( <SelectDataType key={1} label='Select Data Type' style={styles.select} appContext={appContext} dataId={dataId}
                        onSelect={(dataType) => {
                            func.args[1] = { string: dataType ? dataType.dataId : '', type: "'" };
                            onChange( buildCode(code) );
                        }} /> );
                    controls.push( <SelectTupleIndex key='tupleIndex' style={styles.select} appContext={appContext} dataId={dataId}
                        tupleIndex={tupleIndex}
                        onChange={(tupleIndex) => {
                            func.args[2] = { name: tupleIndex };
                            onChange( buildCode(code) );
                        }} /> );
        
                    return controls;
                }
            },
            "code": {
                label: 'Code',
                template: value.replace(/^'|'$/g, ""),
                isFunction: (value, code) => {
                    return code.functions.length > 0 || code.groups.length > 0;
                },
                editControls: ( appContext, model, value, code, onChange) => {
                    let controls = [];
                    controls.push(<TextField key={'value'} style={styles.code} value={value} label='Code'
                        onChange={(e) => {
                            onChange(e.target.value, true);
                        }} /> );
                    return controls;
                }
            },
            "simpleValue": {
                default: true,
                label: 'Value',
                template: isNaN(value) ? `'${value}'` : value,
                isFunction: (value, code) => {
                    return true;
                },
                editControls: ( appContext, model, value, code, onChange) => {
                    let controls = [];
                    value = value.replace(/^'|'$/g, "");
                    controls.push(<TextField key={'value'} style={styles.code} value={value} label='Value'
                        onChange={(e) => {
                            let value = isNaN(e.target.value) ? `'${e.target.value}'` : e.target.value;
                            onChange(value);
                        }} /> );
                    return controls;
                }
            },
        };
        
    }

    render() {
        const self = this;
        const { appContext, model } = this.props;
        let { value, code } = this.state;

        let functionItems = [];
        let selectedFunction = null;
        let defaultFunction = null;
        let functions = this.getFunctions(model,value);
        for(let k in functions) {
            functionItems.push(<MenuItem key={functionItems.length} value={k}>{functions[k].label}</MenuItem>);
            if ( !selectedFunction && functions[k].isFunction(value, code))
                selectedFunction = k;
            if ( functions[k].default) defaultFunction = k;
        }
        if (! selectedFunction) {
            selectedFunction = defaultFunction;
            code = buildCode(value);
        }

        let selectFunctionType = <Select 
            style={styles.select} 
            value={selectedFunction}  
            onChange={(e) => {
                let value = functions[e.target.value].template;
                let code = null;
                try {
                    code = parseCode(value);
                } catch (e) {
                    code = { functions: [], groups: [] };
                }
                self.setState({value, code});
            }}>
            {functionItems}
        </Select>;
        let functionControls = functions[selectedFunction].editControls( appContext, model, value, code, (value,noParse) => {
            if ( !noParse ) {
                let code = null;
                try {
                    code = parseCode(value);
                } catch (e) {
                    code = { functions: [], groups: [] };
                }
                self.setState({value, code});
            } else {
                self.setState({value});
            }
        });

        return <Dialog open={true} onClose={this.props.onClose} maxWidth='sm' fullWidth={true}>
            <DialogTitle>Edit Value</DialogTitle>
            <DialogContent>
                {selectFunctionType}
                {functionControls}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => {
                    self.props.onChange(self.state.value);
                    self.props.onClose();
                }}>OK</Button>
                <Button variant='contained' onClick={this.props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

class EditValue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            dialog: null
        }
    }

    componentDidUpdate(oldProps) {
        if ( this.props.value !== oldProps.value) {
            this.setState({value: this.props.value});
        }
    }

    editValue() {
        const self = this;
        this.setState({dialog: <EditValueDialog appContext={this.props.appContext} value={this.state.value} model={this.props.model}
            onClose={() => {
                self.setState({dialog: null});
            }}
            onChange={(value) => {
                self.setState({value}, () => {
                    self.props.onChange(value);
                })
            }} />});
    }

    render() {
        return <div>
            <IconButton onClick={this.editValue.bind(this)}><EditIcon /></IconButton>
            {this.state.value}
            {this.state.dialog}
        </div>;
    }
}

export class DataModelView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            model: null
        };
    }
    componentDidMount() {
        if (this.props.model)
            this.loadModel(this.props.model);
    }
    componentDidUpdate(oldProps) {
        if (this.props.model !== oldProps.model)
            this.loadModel(this.props.model);
    }

    closeView() {
        this.props.onClose();
    }

    loadModel(model) {
        this.setState({
            model,
            progress: null,
            dialog: null
        });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    saveDataModel(planId = null) {
        const self = this;
        const { model } = self.state;
        let { appContext } = this.props;

        if (!planId && model.planId && model.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(model.planId, this.saveDataModel.bind(this));
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        saveDataModel(appContext, model, planId).then((response) => {
            self.setState({ override: false, model: response, progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    deleteDataModel() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete model: {`${self.state.model.modelId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={this.cancelDelete.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={this.confirmDelete.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    showCode() {
        let { appContext } = this.props;
        let { model } = this.state;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={model}
                onEditDone={(model) => {
                    this.setState({ dialog: null, model });
                }}
                onDone={() => {
                    this.setState({ dialog: null });
                }}
            />
        });
    }

    cancelDelete() {
        this.setState({ dialog: null });
    }
    
    confirmDelete() {
        const self = this;
        const model = this.state.model;
        let { appContext } = this.props;
        self.setState({ dialog: null });
        if (model.modelId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            deleteDataModel(appContext, model).then(() => {
                self.setState({ progress: null });
                self.closeView();
            }).catch((error) => {
                console.log("deleteDataModel error:", error.response);
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    onTupleControl(table, value, row, index, id) {
        const { appContext } = this.props;
        return <SelectTupleIndex
            style={{width: 200, margin: 5}}
            appContext={appContext}
            dataId={this.state.model.dataId}
            tupleIndex={value}
            onClick={(e) => table.handleClickEdit(e)}
            onChange={(typleIndex) => {
                table.setDataField(index, id, typleIndex);
            }}
            name={'Conditions'}
        />;
    }

    onConditionControl(table, value, row, index, id) {
        const { appContext } = this.props;
        return <EditConditions
            appContext={appContext}
            conditions={value}
            onClick={(e) => table.handleClickEdit(e)}
            onChange={(conditions) => {
                table.setDataField(index, id, conditions);
            }}
            name={'Conditions'}
        />;
    }

    onValueControl(table, value, row, index, id) {
        const { appContext } = this.props;
        const { model } = this.state;
        return <EditValue
            appContext={appContext}
            model={model}
            value={value}
            onClick={(e) => table.handleClickEdit(e)}
            onChange={(value) => {
                table.setDataField(index, id, value);
            }}
        />;
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { model, progress, error, dialog } = this.state;

        if (!model) {
            return null;
        }

        const columnData = [
            { id: 'tupleIndex', numeric: false, disabledPadding: false, label: 'Tuple Index', editControl: this.onTupleControl.bind(this) },
            { id: 'condition', numeric: false, disabledPadding: false, label: 'Condition', editControl: this.onConditionControl.bind(this) },
            { id: 'value', numeric: false, disabledPadding: false, label: 'Value', width: 300, editControl: this.onValueControl.bind(this) }
        ];

        let disableDelete = model ? model.planId !== appContext.state.plan.planId : true;
        return (
            <div align="center" style={{width: 1220, margin: 0}} >
                <AppBar style={styles.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => self.closeView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">Data Model</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={styles.div}>
                    <table style={styles.table}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField disabled={true} style={styles.name} label="Model ID" value={model.modelId}
                                        onChange={(e) => { model.modelId = e.target.value; self.setState({ model }); }} />
                                </td>
                                <td valign="top" align="right" style={{width: 500}}>
                                    {error}
                                    <IconButton disabled={progress !== null} onClick={this.saveDataModel.bind(this, null)}>{progress || <SaveIcon />}</IconButton>
                                    <IconButton onClick={this.showCode.bind(this)}><CodeIcon /></IconButton>
                                    <IconButton disabled={disableDelete} onClick={this.deleteDataModel.bind(this)}><DeleteIcon /></IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <TextField style={styles.name} label="Name" value={model.name}
                                        onChange={(e) => { 
                                            model.name = e.target.value; 
                                            model.modelId = createIdFromText( model.name );
                                            self.setState({ model }); 
                                        }} />
                                    <br />
                                    <TextField style={styles.name} label="Status" value={model.status}
                                        onChange={(e) => { model.status = e.target.value; self.setState({ model }); }} />
                                    <br />
                                    <FormControlLabel label="Post Detect" control={<Checkbox checked={model.postDetect} onChange={(e,v) => {
                                        model.postDetect = v;
                                        self.setState({ model, modified: true });
                                    }} /> } />
                                    <br />
                                    <TextField style={styles.number} type='number' label="Order" value={model.order}
                                        onChange={(e) => { 
                                            model.order = e.target.value; 
                                            self.setState({ model, modified: true }); 
                                        }} />
                                    <br />
                                    <SelectDataType label='Data Type' appContext={appContext} dataId={model.dataId} 
                                        onCreate={() => {
                                            self.setState({ dialog: <EditDataTypeDialog appContext={this.props.appContext}
                                                dataType={{ dataId: '', name: '', description: '', category: 'symptom', tupleDescriptions: [] }}
                                                onCancel={() => self.setState({dialog: null})} 
                                                onDone={(newType) => {
                                                    model.dataId = newType.dataId;
                                                    self.setState({ dialog: null, model, modified: true} );
                                                }} /> });
                                        }}
                                        onSelect={(dataType) => {
                                            model.dataId = dataType ? dataType.dataId : '';
                                            self.setState({ model, modified: true });
                                        }} />
                                    <SelectDataType label="Date Data Id" appContext={this.props.appContext} dataId={model.dateDataId} enableNone={true}
                                        onChange={(select) => {
                                            model.dateDataId = select ? select.dataId : '';
                                            self.setState({ model, modified: true });
                                        }} />
                                    {model.dateDataId ? <SelectTupleIndex label='Select Date Tuple' appContext={this.props.appContext} dataId={model.dateDataId} tupleIndex={model.dateTupleIndex}
                                        onChange={(tupleIndex) => {
                                            model.dateTupleIndex = tupleIndex;
                                            self.setState({ model, modified: true });
                                        }} /> : null}
                                    <br />
                
                                    <br />
                                    <EditConditions appContext={appContext}
                                        conditions={model.condition}
                                        onChange={(conditions) => {
                                            model.condition = conditions;
                                            self.setState({model, modified: true});
                                        }}
                                        name={'Conditions'}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <EnhancedTable
                                        style={{width: 1190}}
                                        orderBy='tupleIndex'
                                        columnData={columnData}
                                        data={model.values}
                                        rowsPerPage={5}
                                        onDataChanged={(values) => {
                                            model.values = values;
                                            self.setState({ model, modified: true });
                                        }}
                                        title='Values' />
                                    <br />
                                    <ChangedByButton appContext={this.props.appContext} primaryKey={model.modelId} collection='DataModelModel' />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
            </div>
        );
    }
}

const styles = {
    appBar: {
        backgroundColor: "#FF9800",
        width: 1220
    },
    button: {
        margin: 10
    },
    div: {
        margin: 0
    },
    name: {
        margin: 5,
        width: 300
    },
    text: {
        margin: 5,
        width: 300
    },
    code: {
        margin: 5,
        width: 500
    },
    number: {
        margin: 5,
        width: 100
    },
    description: {
        margin: 5,
        width: 500
    },
    select: {
        margin: 5,
        width: 300
    },
    tags: {
        margin: 5
    },
    table: {
        "width": "100%"
    },
    td: {
        "textAlign": "right"
    },
    checkbox: {
        marginBottom: 16
    },
    flex: {
        flex: 1,
    },
    openButton: {
        margin: 15,
    },
}

export default DataModelView;
