import React, { Component } from "react";
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/';

import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PubSub from 'pubsub-js'
import ReactDOMServer from "react-dom/server";

import { parseCode } from '@apricityhealth/web-common-lib/utils/ParseCode';



import './report.css'

const fileDownload = require('js-file-download');

// TODO: This should all be removed, arrays have functions built in to do this..
function findNodeByType(array, type) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].type === type) return array[index];

        }
    return null;
}

function findNodeByName(array, name) {
    if (isArrayValid(array))
        for (let index = 0; index < array.length; index++) {
            if (array[index].name === name) return array[index];

        }
    return null;
}

function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
};

export function getDownloadReport(appContext, model, showAlerts, showExplanation, showPatientSummary) {

    let report = getRecommendationModelReport(appContext, model, showAlerts, showExplanation, showPatientSummary);
    const staticMArkup = ReactDOMServer.renderToString(report);
    let html = `
            <html lang='en'>
                <head>
                    <title>Recommendation Models Report</title>
                    <meta content="text/html; charset=utf-8" http-equiv="Content-Type">
                  <style>
                  @import url("https://fonts.googleapis.com/css?family=Roboto:300,400");
                  .recommend_table {
                    border-collapse: collapse;
                    width: 100%;
                    margin-top: 20px;
                    border: 0.5px solid #0d6baf;
                    font-size: 12px;
                    font-weight: 500 !important;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    hyphens: auto;
                  }
                  .recommend_table tr th {
                    border: 0.5px solid #4dabec;
                  }
                  .recommend_table tr td {
                    border: 0.5px solid #4dabec;
                  }
                  
                  .type_name {
                    background-color: #ffffff;
                    color: #000000;
                    padding: 10px;
                    text-align: center;
                  }
                  
                  .group_category_name {
                    width: 10%;
                    background-color: #ff7e00;
                    text-align: center;
                    border: 1px solid #4dabec;
                    border-collapse: collapse;
                  }
                  
                  .group_recommendation_cell {
                    background-color: #fafdfd;
                    vertical-align: top;
                  }
                  .group_recommendation_list {
                    align-content: top;
                    margin-left: 0px;
                    padding-left: 20px;
                    background-color: #fafdfd;
                    text-align: left;
                    vertical-align: top;
                    justify-content: top;
                    min-width: 200px;
                  }
                  
                  .recommend_options_heading {
                    width: 20%;
                    text-align: center;
                    background-color: #ffc17a;
                    padding: 10px;
                    border: 1px solid #4dabec;
                  }
                  
                  .recommend_groups_heading {
                    width: 20%;
                    text-align: center;
                    background-color: #0d6baf;
                    padding: 10px;
                  }
                  
                  .group_name {
                    background-color: #0d6baf;
                    color: #fafafa;
                    width: 130px;
                    min-width: 110px;
                    max-width: 200px;
                    padding: 10px;
                    text-align: center;
                  }
                  
                  .category_name {
                    width: 11%;
                    min-width: 110px;
                    background-color: #c0e8ff;
                    text-align: center;
                  }
                  
                  .recommendation_cell {
                    background-color: #fafdfd;
                    vertical-align: top;
                  }
                  
                  .recommend_group_seperator {
                    background-color: #ffffff;
                    width: 100%;
                    text-align: bottom;
                    font-size: 24px;
                    padding-bottom: 5px;
                    padding-top: 50px;
                  }
                  .recommendation_list {
                    align-content: top;
                    margin-left: 0px;
                    padding-left: 20px;
                    padding-top: 5px;
                    background-color: #fafdfd;
                    text-align: left;
                    vertical-align: top;
                    justify-content: top;
                    font-weight: 500 !important;
                  }
                  </style>
                </head>
                <body>
                <div>Note: Yellow background highlight are recommendations that have been recently updated per guidelines</div>
                    ${staticMArkup}
                </body>
        </html>
    `
    return html;
}

export function getRecommendationModelReport(appContext, model, showAlerts, showExplanation, showPatientSummary) {
    const { stores: { DataTypesStore } } = appContext;

    if (isArrayValid(model)) {
        let report = [];
        for (let j = 0; j < model.length; j++) {
            let nextModel = model[j];
            let modelReport = getRecommendationModelReport(appContext, nextModel, showAlerts, showExplanation, showPatientSummary);
            report.push(modelReport);
        }
        return report;
    }
    const alertTypes = DataTypesStore.getAlertTypes();
    const types = DataTypesStore.getDataTypes();

    let groups = model.groups;
    let htmlGroups = [];
    if (isArrayValid(groups)) {
        for (let j = 0; j < groups.length; j++) {
            let group = groups[j];

            if (!group.type) group.type = ''
            let type = group.type.trim();


            let typeGroup = findNodeByType(htmlGroups, type);
            if (!typeGroup) {
                typeGroup = { type, groups: [], categories: [], addAlerts: [], deleteAlerts: [] };
                htmlGroups.push(typeGroup);
            }

            let name = generateGroupNameFromCondition(group, types, model);
            let conditionGroup = findNodeByName(typeGroup.groups, name);
            if (!conditionGroup) {
                conditionGroup = { name, conditions: group.dataConditions };
                typeGroup.groups.push(conditionGroup);
            }

            if (isArrayValid(group.categories)) {
                for (let k = 0; k < group.categories.length; k++) {
                    let category = group.categories[k];
                    let catName = category.name.trim()

                    let recIndex = DataTypesStore.getRecommendCategories().findIndex((rec) => rec.categoryId === catName);
                    if (recIndex >= 0)
                        catName = DataTypesStore.getRecommendCategories()[recIndex].name;

                    let categoryType = findNodeByType(typeGroup.categories, catName);
                    if (!categoryType) {
                        categoryType = { type: catName, recommendationGroups: [] };
                        typeGroup.categories.push(categoryType);
                    }

                    let recommendationGroup = findNodeByName(categoryType.recommendationGroups, name);
                    if (!recommendationGroup) {
                        recommendationGroup = { name, recommendations: [] };
                        categoryType.recommendationGroups.push(recommendationGroup);
                    }
                    if (isArrayValid(category.recommendations)) {
                        for (let index = 0; index < category.recommendations.length; index++) {
                            recommendationGroup.recommendations.push(category.recommendations[index]);
                        }
                    }
                }
            }
            if (isArrayValid(group.addAlert)) {

                group.addAlert.forEach(alert => {
                    let alertTypeId = alert.alertTypeId;
                    let alertType = alertTypes.find(alert => alert.alertTypeId === alertTypeId);
                    typeGroup.addAlerts.push(alertType ? alertType : { description: alertTypeId })
                });

            }
            if (isArrayValid(group.deleteAlert)) {

                group.deleteAlert.forEach(alert => {
                    let alertTypeId = alert.alertTypeId;
                    let alertType = alertTypes.find(alert => alert.alertTypeId === alertTypeId);
                    typeGroup.deleteAlerts.push(alertType ? alertType : { description: alertTypeId })
                });

            }
        }
    }

    let tables = [];
    if (isArrayValid(htmlGroups)) {
        for (let j = 0; j < htmlGroups.length; j++) {
            let htmlGroup = htmlGroups[j];
            let categories = htmlGroup.categories;
            if (categories.length === 0) {
                if (!showAlerts) continue;
            }

            let groups = htmlGroup.groups;
            let rows = [];
            if (isArrayValid(groups)) {
                let columns = [];
                let typeName = DataTypesStore.getRecommendGroupTypes().find(item => item.typeId === htmlGroup.type);
                rows.push(<tr><th colSpan={groups.length + 1} className='type_name'>{model.description} {typeName ? typeName.name : htmlGroup.type}</th></tr>);
                columns.push(<td className='type_name'>Category</td>);
                for (let k = 0; k < groups.length; k++) {
                    let conditionGroup = groups[k];

                    if (!conditionGroup.conditions) {
                        columns.push(<td style={{ backgroundColor: 'tomato' }} className='group_name'>{conditionGroup.name} MISSING CONDITION</td>);
                    } else {
                        columns.push(<td className='group_name'>{conditionGroup.name}</td>);
                    }
                }
                rows.push(<tr >{columns}</tr>);

            }

            if (isArrayValid(categories)) {
                for (let l = 0; l < categories.length; l++) {
                    let columns = [];
                    let category = categories[l];
                    columns.push(<td className='category_name'>{category.type}</td>);

                    for (let k = 0; k < groups.length; k++) {
                        let name = groups[k].name;
                        let recommendationGroup = findNodeByName(category.recommendationGroups, name.trim())
                        if (recommendationGroup && isArrayValid(recommendationGroup.recommendations)) {
                            let listItems = [];
                            let found = [];
                            for (let m = 0; m < recommendationGroup.recommendations.length; m++) {
                                let recommendation = recommendationGroup.recommendations[m];
                                let explanation = "";
                                if (recommendation.explanation)
                                    explanation = '<i>' + String(recommendation.explanation) + '</i>';

                                let color = 'black';
                                let backgroundColor = 'white'
                                if (Array.isArray(recommendation.protocol) && recommendation.protocol.includes("recentlyUpdated")) {
                                    let protocol = DataTypesStore.getRecommendProtocols().find(i => i.protocolId === "recentlyUpdated");
                                    if (protocol) {
                                        color = protocol.color;
                                        backgroundColor = protocol.background;
                                    }
                                }
                                if (Array.isArray(recommendation.protocol) && recommendation.protocol.includes("required")) {
                                    let protocol = DataTypesStore.getRecommendProtocols().find(i => i.protocolId === "required");
                                    if (protocol) {
                                        color = protocol.color;
                                        backgroundColor = protocol.background;
                                    }
                                }
                                if (Array.isArray(recommendation.protocol) && recommendation.protocol.includes("recommended")) {
                                    let protocol = DataTypesStore.getRecommendProtocols().find(i => i.protocolId === "recommended");
                                    if (protocol) {
                                        color = protocol.color;
                                        backgroundColor = protocol.background;
                                    }
                                }
                                if (showPatientSummary) {
                                    if (Array.isArray(recommendation.protocol) && recommendation.protocol.includes("patientSummary")) {
                                        color = 'black';
                                        backgroundColor = '#ffc37e';
                                    }
                                }
                                let recIndex = found.findIndex((rec) => rec.name === recommendation.name);
                                if (recIndex >= 0)
                                    listItems.push(<li ><span style={{ backgroundColor, color: 'red' }} dangerouslySetInnerHTML={{ __html: `<b>${recommendation.name}</b> (${recommendation.weight}) ${showExplanation ? (explanation) : ""}  {duplicate}` }} /></li>);
                                else {
                                    found.push(recommendation);
                                    listItems.push(<li ><span style={{ backgroundColor, color }} dangerouslySetInnerHTML={{ __html: `<b>${recommendation.name}</b>  (${recommendation.weight}) ${showExplanation ? (explanation) : ""}` }} /></li>);
                                }



                            }
                            columns.push(<td className='recommendation_cell'><ul className='recommendation_list'>{listItems}</ul></td>);
                        }
                        else {
                            columns.push(<td className='recommendation_cell'><ul className='recommendation_list'><li>none</li></ul></td>);
                        }

                    }

                    rows.push(<tr >{columns}</tr>);
                    columns = [];
                }
            }

            if (isArrayValid(htmlGroup.addAlerts)) {
                let columns = [];
                let listItems = [];
                htmlGroup.addAlerts.forEach(alert => {
                    listItems.push(<li key={alert.description}><span dangerouslySetInnerHTML={{ __html: alert.description }} /></li>);
                });
                columns.push(<td className='category_name'>Alerts added</td>);
                columns.push(<td className='recommendation_cell'><ul className='recommendation_list'>{listItems}</ul></td>);
                rows.push(<tr >{columns}</tr>);
            }

            if (isArrayValid(htmlGroup.deleteAlerts)) {
                let columns = [];
                let listItems = [];
                htmlGroup.deleteAlerts.forEach(alert => {
                    listItems.push(<li key={alert.description}><span dangerouslySetInnerHTML={{ __html: alert.description }} /></li>);
                });
                columns.push(<td className='category_name'>Alerts removed</td>);
                columns.push(<td className='recommendation_cell'><ul className='recommendation_list'>{listItems}</ul></td>);
                rows.push(<tr >{columns}</tr>);
            }

            tables.push(
                <table className='recommend_table' >
                    <tbody>
                        {rows}
                    </tbody>

                </table>
            );
        }
    }
    return tables;
}

function generateGroupNameFromCondition(group, types, model) {

    let name = [];
    let funct = parseCode(group.dataConditions);
    if (funct && isArrayValid(funct.functions)) {
        funct.functions.forEach(funt => {
            if (funt.functionName === "isDataInRange") {
                if (isArrayValid(funt.args)) {
                    let dataId = funt.args[1].string;
                    let type = types.find(type => type.dataId === dataId);

                    let typeName = type ? type.category : "";
                    typeName = 'Grade'; //this is fixed to grade so the report reflects the guide
                    let min = funt.args[3].string;
                    let max = funt.args[4].string;
                    if (min === max)
                        name.push(`${typeName} ${min}`);
                    else
                        name.push(`${typeName} ${min}-${max}`);
                }

            } else if (funt.functionName === "isDataValue") {
                if (isArrayValid(funt.args)) {
                    let dataId = funt.args[1].string;
                    let type = types.find(type => type.dataId === dataId);
                    let typeName = type ? type.category : "";
                    typeName = 'Grade';//this is fixed to grade so the report reflects the guide
                    let min = funt.args[3].string;
                    name.push(`${typeName} ${min}`);
                }

            }
            else {
                name.push(`Name generation for ${funt.functionName} not yet supported`)
            }
        });
    }
    return name.join(", ")
}

class RecommendationModelReportView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: [],
            showExplanation: true,
            showPatientSummary: false,
            showAlerts: false,
            dialog: null,
            report: null
        };

    }

    componentDidMount() {
        this.errorToken = PubSub.subscribe('ERRORS_TOPIC', (errors) => {
            this.setState({ errors });
        })
        this.onChange();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.model.length > 0 && prevProps.model.length === 0)
            this.onChange();
        else if (this.props.model !== prevProps.model) {
            this.onChange();
        }
    }

    onChange() {
        let { showAlerts, showExplanation, showPatientSummary } = this.state;
        let { appContext, model } = this.props;
        let report = getRecommendationModelReport(appContext, model, showAlerts, showExplanation, showPatientSummary);
        this.setState({ report })
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.errorToken);
    }

    static getDerivedStateFromProps(props) {
        return { model: props.model };
    }

    print(report) {
        this.setState({
            dialog: <Dialog fullScreen={true} modal="true" open={true}>
                <DialogContent>
                    {report}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => {
                        this.setState({ dialog: null });
                    }}>OK</Button>
                </DialogActions>
            </Dialog>
        });
    }

    download() {
        let { showAlerts, showExplanation, showPatientSummary } = this.state;
        let { appContext, model } = this.props;
        let report = getDownloadReport(appContext, model, showAlerts, showExplanation, showPatientSummary);
        fileDownload(report, `RecommendationModels.html`);
    }

    render() {
        let { report, dialog, showAlerts, showExplanation, showPatientSummary } = this.state;
        return (

            <div align="left" style={{ width: "100%" }} >
                {!this.props.print && report && <IconButton disabled={report.length < 1} onClick={() => this.print(report)}><PrintIcon /></IconButton>}
                {report && <IconButton disabled={report.length < 1} onClick={() => this.download(report)}><DownloadIcon /></IconButton>}

                <FormControlLabel
                    control={<Checkbox
                        checked={showAlerts}
                        onChange={(e) => {
                            showAlerts = e.target.checked;
                            this.setState({ showAlerts }, () => this.onChange());

                        }}
                    />
                    }
                    label="Show Alerts" />
                <FormControlLabel
                    control={<Checkbox
                        checked={showExplanation}
                        onChange={(e) => {
                            showExplanation = e.target.checked;
                            this.setState({ showExplanation }, () => this.onChange());

                        }}
                    />
                    }
                    label="Show Explanation" />
                <FormControlLabel
                    control={<Checkbox
                        checked={showPatientSummary}
                        onChange={(e) => {
                            showPatientSummary = e.target.checked;
                            this.setState({ showPatientSummary }, () => this.onChange());

                        }}
                    />
                    }
                    label="Show Patient Summary" />
                <div>Note: Yellow background highlight are recommendations that have been recently updated per guidelines</div>
                {report}
                {dialog}
            </div>

        )
    }
}

const styles = {
    div: {
        margin: 10
    }
};

RecommendationModelReportView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(RecommendationModelReportView);
