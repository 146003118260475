import React, { Component } from 'react';
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@material-ui/core/';

import {
    loadAlertTypes
} from '@apricityhealth/web-common-lib/utils/Services';

export class SelectAlertType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertTypeId: props.alertTypeId,
            type: null,
            types: []
        }
    }
    componentDidMount() {
        this.updateTypes();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.alertTypeId !== this.state.alertTypeId) {
            this.setState({
                alertTypeId: nextProps.alertTypeId,
            }, this.updateTypes.bind(this));
        }
    };

    handleChange(v) {
        let type = this.state.types.find((e) => e.alertTypeId === v);
        if (type !== null) {
            this.setState({ type, alertTypeId: v }, this.updateTypes.bind(this));
        } else {
            this.setState({ type: null, alertTypeId: '' }, this.updateTypes.bind(this));
        }
        if (this.props.onSelect)
            this.props.onSelect(type);
    }

    updateTypes() {
        const self = this;
        const { appContext, enableNone } = self.props;
        loadAlertTypes(appContext, { dependencies: true }).then( (types) => {
            let { alertTypeId } = self.state;

            let items = [];
            let type = null;
            if (enableNone)
                items.push(<MenuItem key={'none'} value={''}>None</MenuItem>);
            for (let i = 0; i < types.length; ++i) {
                const t = types[i];
                items.push(<MenuItem key={i} value={t.alertTypeId}>{t.alertTypeId}</MenuItem>);

                if (t.alertTypeId === alertTypeId) {
                    type = t;
                }
            }
            self.setState({ types, items, alertTypeId, type });
        }).catch((err) => {
            console.error("updateTypes error:", err );
        });
    }

    render() {
        const self = this;
        const { label } = this.props;
        const { items, alertTypeId } = this.state;

        return <div>
            <FormControl style={{ margin: 5, width: 300 }}>
                <InputLabel>{label ? label : 'Alert Type'}</InputLabel>
                <Select value={alertTypeId} onChange={(e) => self.handleChange(e.target.value)}>
                    {items}
                </Select>
            </FormControl>
        </div>;
    }
}

export default SelectAlertType;
