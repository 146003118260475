import React, { Component } from "react";
import { Avatar } from '@material-ui/core/';
import {
    Chip,
    FormLabel,
    Tooltip,
    IconButton,
    Button,
    Dialog,
    DialogContent, 
    DialogActions 
} from '@material-ui/core/';
import FlagIcon from '@material-ui/icons/Flag';
import AddIcon from '@material-ui/icons/Add';

import {
    isArrayValid,
} from '../utils/Utils';

import { MedicationName, SelectMedication } from '@apricityhealth/web-common-lib/components/SelectMedication';

class EditMedicationArray extends Component {
    constructor(props) {
        super(props);

        this.state = {
            array: props.array || [],
            menu: null
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.array) !== JSON.stringify(this.props.array)) {
            let array = this.props.array ? this.props.array : [];
            this.setState({ array });
        }
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state.array);
        }
    }

    onAdd() {
        let { array } = this.state;
        array.splice(0, 0, "");
        this.setState({ array }, this.onChange.bind(this));
    }

    onUpdateMedication(index, type) {
        let { array } = this.state;
        let medicationId = type ? type.medicationId : '';
        if ( medicationId !== array[index] ) {
            array[index] = type ? type.medicationId : '';
            this.setState({ array, menu: null }, this.onChange.bind(this));
        }
    }

    onChangeData(index, e) {
        let { array } = this.state;
        const { appContext } = this.props;
        let medicationId = array[index];
        let menu = 
            <Dialog fullWidth={true} maxWidth={'sm'} model="true" open={true}>
                <DialogContent style={{minHeight:400}}>
                    <SelectMedication
                        appContext={appContext}
                        medicationId={medicationId}
                        onSelect={this.onUpdateMedication.bind(this, index)} />
                </DialogContent>
                    <DialogActions>
                        <Button variant="contained"  onClick={this.closeMenu.bind(this)}>Cancel</Button>
                    </DialogActions>
            </Dialog>
        this.setState({ menu });
    }

    closeMenu() {
        this.setState({ menu: null });
    }

    onDelete(index) {
        let { array } = this.state;
        array.splice(index, 1);
        this.setState({ array }, this.onChange.bind(this));
    }

    render() {
        let { array, menu } = this.state;

        let dataChips = [];
        if (isArrayValid(array)) {
            for (let index = 0; index < array.length; index++) {
                let data = array[index];
                dataChips.push(
                    <Chip
                        key={index}
                        avatar={<Avatar><FlagIcon /></Avatar>}
                        label={<MedicationName appContext={this.props.appContext} medicationId={data} />}
                        onClick={this.onChangeData.bind(this, index)}
                        onDelete={this.onDelete.bind(this, index)}
                    />);

            }
        }

        let label = <FormLabel>{this.props.label || "Edit Medications"}</FormLabel>;
        if ( this.props.tip ) 
            label = <Tooltip title={this.props.tip}>{label}</Tooltip>;

        return (<div >
            <div style={{ marginTop: '5px' }} />
            {label}
            <div style={{ marginTop: '5px' }} />
            <Tooltip title="Add" style={{ marginRight: '10px' }}>
                <IconButton aria-label="Add" onClick={this.onAdd.bind(this)}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {dataChips}
            {menu}
        </div >
        );
    }
}

export default EditMedicationArray;
