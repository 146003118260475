import React, { Component } from "react";

import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    IconButton,
    Select,
    MenuItem
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import InfoIcon from '@material-ui/icons/Info';
import ChangedByButton from "../components/ChangedByButton";


class EditRecommendationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendation: JSON.parse( JSON.stringify(props.recommendation))
        };
    }

    componentDidUpdate(oldProps){
        if (oldProps && oldProps.reccomendation){
            if (oldProps.reccomendation !== this.props.reccomendation){
                this.setState({ recommendation: JSON.parse( JSON.stringify( this.props.recommendation )) });
            }
        }
    }
    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        const { onDone } = this.props;
        const { recommendation } = this.state;
        if (onDone)
            onDone(recommendation);
    }

    onChange = type => e => {
        let { recommendation } = this.state;
        recommendation[type] = e.target.value;
        this.setState({ recommendation });
    }

    displayPreview() {
        const self = this;
        let {  recommendation } = this.state;
    

        this.setState({dialog: <Dialog fullWidth={true} maxWidth="sm" modal="true" open={true}>
            <DialogTitle>Preview</DialogTitle>
            <DialogContent>
                <div style={{ margin: 5 }}>
                    <div dangerouslySetInnerHTML={{ __html: recommendation.name }}></div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={() => { 
                    self.setState({dialog: null}); }}>OK</Button>
           </DialogActions>
        </Dialog>});
    }

    render() {
        let { recommendation, dialog } = this.state;
        let self = this;
        const columnData = [
            { id: 'name', numeric: false, disabledPadding: false, editType: 'text', label: 'Name' },
            { id: 'confidence', numeric: true, disabledPadding: false, editType: 'text', label: 'Confidence' }
        ];


        return (
            <Dialog
                maxWidth={'md'}
                fullWidth={true}
                model="false"
                open={true}
            >
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Name"
                        value={recommendation.name}
                        multiline={false}
                        onChange={this.onChange("name")}
                    />
                       <IconButton onClick={self.displayPreview.bind(self)}><InfoIcon /></IconButton>
                    <br />
                    <TextField
                        fullWidth
                        label="Description"
                        width='800'
                        value={recommendation.description}
                        multiline={true}
                        rows={2}
                        onChange={this.onChange("description")}
                    />
                    <br />
                    <TextField
                        style={{ margin: 5, width: 300 }}
                        label="Group"
                        value={recommendation.group}
                        onChange={this.onChange("group")}
                    />
                      <TextField
                        style={{ margin: 5, width: 300 }}
                        label="Sub Category"
                        value={recommendation.subCategory}
                        onChange={this.onChange("subCategory")}
                    />
                    <TextField
                        type='number'
                        label="Group Rank"
                        style={{ margin: 5, width: 100 }}
                        value={recommendation.option}
                        onChange={this.onChange("option")}
                    />
                    <br/>
                    <Select
                        style={{ margin: 15, width: 200 }}
                        label="System"
                        value={recommendation.system || ''}
                        onChange={this.onChange("system")}
                    >
                        <MenuItem value={'http://loinc.org'}>http://loinc.org</MenuItem>
                    </Select>
                    <TextField
                        label="Code"
                        style={{ margin: 5, width: 150 }}
                        value={recommendation.code}
                        onChange={this.onChange("code")}
                    />
                    <br/>
                    <EnhancedTable
                        disableMultiSelect={true}
                        rowsPerPage={5}
                        orderBy='name'
                        columnData={columnData}
                        data={recommendation.confidenceLevels}
                        title='Confidence Levels' 
                        onDataChanged={(data,table) => {
                            console.log("onDataChanged:", data );
                            recommendation.confidenceLevels = data;
                            self.setState({recommendation});
                        }}
                        />
                    <ChangedByButton appContext={this.props.appContext} primaryKey={recommendation.recommendId} collection='RecommendTypeModel' />
                    {dialog}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Ok</Button>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    },
};

export default EditRecommendationDialog;
