import React, { Component } from "react";

import {
    Select,
    MenuItem,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core/';

class EditCategoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: JSON.parse(JSON.stringify(props.category))
        };
    }


    componentDidUpdate(oldProps) {
        const oldData = JSON.stringify(oldProps.category)
        const currentData = JSON.stringify(this.props.category)
        if (oldData !== currentData){
            this.setState({ category: JSON.parse( JSON.stringify( this.props.category ))});
        }
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone() {
        const { onDone } = this.props;
        const { category } = this.state;
        if (onDone)
            onDone(category);
    }

    onChange(e, type) {
        let { category } = this.state;
        category[type] = e.target.value;
        this.setState({ category });

        if (this.props.onChange)
            this.props.onChange(category);
    }

  
    render() {
        let { category } = this.state;
       
        const generateRecommendationItems = () => {
            const { appContext: { stores: { DataTypesStore }} } = this.props;
            return DataTypesStore.getRecommendCategories().sort((a,b) => a.name.localeCompare(b.name)).map((e) => {
                return <MenuItem key={e.categoryId} value={e.categoryId}>{e.name}</MenuItem>;
            })
        } 
        let self = this;
        return <Dialog model="false" open={true}>
            <DialogContent>
                <Select style={styles.select} label='Category' value={category.name} onChange={(e) => { self.onChange(e, "name"); }}>
                    {generateRecommendationItems()}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" self={self} style={styles.button} onClick={this.onDone.bind(this)}>Ok</Button>
                <Button variant="contained" self={self} style={styles.button} onClick={this.onCancel.bind(this)}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    div: {
        margin: 10
    },
    select: {
        margin: 5,
        width: 400
    },
    button: {
        margin: 5
    }
};


export default EditCategoryDialog;
