import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Tooltip, Badge, FormControlLabel, Switch
} from "@material-ui/core";

import LinkIcon from '@material-ui/icons/Link';
import { Link, Redirect } from 'react-router-dom';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';

import React from "react";

export class DataLinksDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            textId: props.textId,
            dataId: props.dataId,
            links: [],
            progress: null,
            redirect: null,
            openNewWindow: toBoolean(localStorage.getItem("DataLinksDIalog.openNewWindow"))
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.dataId !== this.props.dataId || oldProps.textId !== this.props.textId) {
            this.setState({ textId: this.props.textId, dataId: this.props.dataId, links: [] }, this.loadContent.bind(this));
        }
    }

    loadContent() {
        let store = this.props.appContext.stores.DataTypesStore;
        let result = this.state.dataId ? store.getDataTypeLinks(this.state.dataId) : store.getTextLinks(this.state.textId);
        if (result)
            this.setState({ links: result.links });
    }

    onRedirect(url) {
        this.setState({ redirect: <Redirect to={url} /> });
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { language } = appContext.state;
        const { links, progress, dataId, textId, redirect, openNewWindow } = this.state;

        if (redirect) {
            return redirect;
        }

        function getLinkButton(to) {
            if ( openNewWindow ) {
                return <IconButton onClick={() => window.open(to, "_blank")}><LinkIcon /></IconButton>
            } else {
                return <IconButton component={Link} to={to}><LinkIcon /></IconButton>
            }
        }
        const store = appContext.stores.DataTypesStore;

        let linkItems = [];
        for (let i = 0; i < links.length; ++i) {
            let link = links[i];
            let primary = ``;
            let location = '';
            let button = null;
            if (link.answer) {
                button = getLinkButton(`/questions/${link.question.questionId}`);
                location = `Question ID: ${link.question.questionId}, Answer ID: ${link.answer.answerId} [${link.location}]`;
                primary = "Answer: " + store.getText(`${link.question.questionId}.${link.answer.answerId}`, language);
                primary += ", Question: " + store.getText(`${link.question.questionId}`, language);
            }
            else if (link.question) {
                button = getLinkButton(`/questions/${link.question.questionId}`);
                location = `Question ID: ${link.question.questionId} [${link.location}]`;
                primary = "Question: " + store.getText(`${link.question.questionId}`, language);
            } else if (link.model) {
                button = getLinkButton(`/${link.model.category}_models/${link.model.modelId}`);
                location = `Model ID: ${link.model.modelId} [${link.location}]`;
            } else if (link.detect) {
                button = getLinkButton(`/${link.detect.category}_detect/${link.detect.modelId}`);
                location = `Detect ID: ${link.detect.modelId} [${link.location}]`;
            } else if (link.data) {
                button = getLinkButton(`/data_models/${link.data.modelId}`);
                location = `Data ID: ${link.data.modelId} [${link.location}] `;
            } else if (link.recommend) {
                button = getLinkButton(`/recommend_models/${link.recommend.modelId}`);
                location = `Recommend ID: ${link.recommend.modelId} [${link.location}]`;
            } else if (link.observation) {
                button = getLinkButton(`/observations/${link.observation.ruleId}`);
                location = `Observation ID: ${link.observation.ruleId} [${link.location}] `;
            } else if (link.predictor) {
                button = getLinkButton(`/predictor_models/${link.predictor.predictorId}`);
                location = `Predictor ID: ${link.predictor.predictorId} [${link.location}] `;
            } else if (link.dataType) {
                button = getLinkButton(`/types/${link.dataType.dataId}`);
                location = `Data Type ID: ${link.dataType.dataId} [${link.location}] `;
            } else if (link.education) {
                button = getLinkButton(`/education/${link.education.educationId}`);
                location = `Education: ${link.education.title} [${link.location}] `;
            }

            linkItems.push(<div key={linkItems.length}>
                <div>{button} <b>{location}</b></div>
                {primary ? <div dangerouslySetInnerHTML={{ __html: primary }} /> : null}
                <br />
            </div>);
        }

        if (progress === null && linkItems.length === 0) {
            linkItems.push(<span style={{ color: 'red' }} key={0}>NO LINKS FOUND!</span>)
        }

        return <Dialog maxWidth={'md'} fullWidth={true} open={true} onClose={this.props.onClose}>
            <DialogTitle >Links to {dataId || textId}</DialogTitle>
            <DialogContent>
                {linkItems}
                <br />
                <FormControlLabel label='Open links in new window' control={<Switch checked={openNewWindow} onChange={(e) => {
                    self.setState({ openNewWindow: e.target.checked });
                    localStorage.setItem("DataLinksDIalog.openNewWindow", e.target.checked);
                }} />} />
                {progress}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={this.props.onClose}>DONE</Button>
            </DialogActions>
        </Dialog>;
    }
};

export class DataLinkIcon extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            textId: props.textId,
            dataId: props.dataId,
            dialog: null,
            icon: <LinkIcon />,
            linkCount: -1
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.dataId !== this.props.dataId || oldProps.textId !== this.props.textId) {
            this.setState({ textId: this.props.textId, dataId: this.props.dataId }, this.loadContent.bind(this));
        }
    }

    loadContent() {
        const store = this.props.appContext.stores.DataTypesStore;
        let data = this.state.dataId ? store.getDataTypeLinks(this.state.dataId) : store.getTextLinks(this.state.textId);
        if (data !== null) {
            let icon = data.links.length > 0 ? <Badge color={'primary'} badgeContent={`${data.links.length}`}><LinkIcon /></Badge> : <LinkIcon />;
            this.setState({ icon, linkCount: data.links.length });
            if (this.props.onLinks)
                this.props.onLinks(data.links, this);
        }
        else {
            this.setState({ icon: <LinkIcon />, linkCount: -1 });
        }
    }

    onDisplayReferences() {
        this.setState({
            dialog: <DataLinksDialog appContext={this.props.appContext} dataId={this.state.dataId} textId={this.state.textId}
                onClose={this.onCloseDialog.bind(this)} />
        });
    }

    onCloseDialog() {
        console.log("DataTypeLinkIcon.onCloseDialog");
        this.setState({ dialog: null });
    }

    render() {
        const { dialog, icon } = this.state;
        return <span><Tooltip title='Show References'>
            <IconButton onClick={this.onDisplayReferences.bind(this)} style={{padding: 0}}>
                {icon}
            </IconButton>
        </Tooltip>{dialog}</span>;
    }
};

export default DataLinksDialog;
