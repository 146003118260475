import React, { Component } from "react";
import PropTypes from 'prop-types';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {
    withStyles,
    CircularProgress,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Drawer,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CodeIcon from '@material-ui/icons/Code';

import OverrideDialog from "../dialogs/OverrideDialog";
import TextView from './TextView';
import ChangedByButton from '../components/ChangedByButton';
import SelectText from "@apricityhealth/web-common-lib/components/SelectText";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";

import {
    loadAlertTypes,
    saveAlertTypes,
    deleteAlertType,
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';

import JsonDialog from '../dialogs/JsonDialog';

//import '../styles/workup.css';

class AlertTypeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertTypeId: props.alertTypeId || '',
            name: props.alertTypeId || '',
            alertType: null,
            dialog: null,
            model: null,
            drawerOpen: false,
            modified: false
        };
    }

    componentDidMount() {
        this.loadAlertType(this.props.alertTypeId);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.alertTypeId !== this.props.alertTypeId)
            this.setState({ alertTypeId: this.props.alertTypeId }, this.loadAlertType.bind(this, this.props.alertTypeId));
    }


    onCloseView() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else {
            this.props.onClose();
        }
    }

    displayModifiedDialog(done) {
        const self = this;
        let dialog = <Dialog open={true}>
            <DialogTitle>Alert Type Modified!</DialogTitle>
            <DialogContent>This alert type has been modified, would you like to save any changes?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null });
                    self.saveAlertType(done);
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ modified: false, dialog: null }, done);
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    loadAlertType(alertTypeId) {
        const self = this;
        const { appContext } = this.props;

        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadAlertType.bind(this, alertTypeId));
        }
        if ( alertTypeId ) {
            this.setState({ progress: <CircularProgress />, error: null });
            loadAlertTypes(appContext, { alertTypeId, dependencies: true} ).then((types) => {
                let alertType = null;
                if (isArrayValid(types))
                    alertType = types[0];
                else
                    alertType = this.createNewAlertType();

                self.setState({ progress: null, modified: false, alertType });
            }).catch((error) => {
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
        else {
            this.setState({alertType: this.createNewAlertType(), modified: false });
        }
    }

    createNewAlertType() {
        let newAlertType = {
            alertTypeId: "",
            messageId: "",
            subjectMessageId: "",
            notificationType: "patientProviderAlert",
            description: "",
            contactId: "",
            contactMethod: "",
            messageLevel: 1,
            maxFollowUpAttempts: 3,
            intervalInMinutes: 1440,
            logActivity: true,
            allowOvernightCall: false
        };

        return newAlertType;
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    saveAlertType(callback, planId = null) {
        const self = this;
        const { appContext } = this.props;
        const { alertType } = this.state;

        if ( !planId && alertType.planId && alertType.planId !== appContext.state.plan.planId ) {
            return this.displayOverrideDialog( alertType.planId, this.saveAlertType.bind(this, callback));
        }
        this.setState({ progress: <CircularProgress />, error: null });
        saveAlertTypes(appContext, alertType, planId).then((response) => {
            self.setState({ alertType: response, override:false, progress: null, modified: false }, callback );
        }).catch((err) => {
            console.error("save model error:", err);
            self.setState({ progress: null, error: getErrorMessage(err) });
        });
    }

    deleteAlertType() {
        const self = this;
        const { alertType } = this.state;
        let { appContext } = this.props;
        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Delete this Alert Type, are you sure?</DialogContent>
            <DialogActions>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: <CircularProgress />, error: null });
                    deleteAlertType(appContext, alertType).then(() => {
                        self.setState({ progress: null, dialog: null }, self.onCloseView.bind(self));
                    }).catch((err) => {
                        console.error("deleteAlertType failed:", err);
                        self.setState({ progress: null, dialog: null, error: getErrorMessage(err) });
                    })
                }}>Yes</Button>
                <Button variant="contained" style={styles.button} onClick={(e) => {
                    self.setState({ progress: null, dialog: null });
                }}>No</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    onShowCode() {
        let { alertType } = this.state;
        let { appContext } = this.props;
        this.setState({
            dialog: <JsonDialog
                appContext={appContext}
                dataType={alertType}
                onEditDone={(alertType) => {
                    this.setState({ alertType, dialog: null});
                }}
                onDone={() => { 
                    this.setState({ dialog: null }); 
                }} />
        });
    }

    onCloseDialog() {
        this.setState({dialog: null});
    }

    onCreateText(property) {
        const self = this;
        const newText = {
            textId: '',
            version: 0,
            category: "notifications",
            text: "",
            language: 'en-us'
        };
        this.setState({dialog: <Drawer variant="persistent" anchor="right" open={true} >
            <TextView appContext={this.props.appContext} text={newText} onClose={this.onCloseDialog.bind(this)} onDone={(text) => {
                const { alertType } = self.state;
                console.log("onCreateText:", property, text );
                alertType[property] = text ? text.textId : '';
                self.setState({alertType, dialog: null});
            }} />
        </Drawer> })
    }

    render() {
        const self = this;
        const { appContext } = this.props;
        const { progress, dialog, alertType } = this.state;
        if (! alertType ) return null;

        if ( alertType.messageId === undefined ) alertType.messageId = '';
        if ( alertType.logActivity === undefined ) alertType.logActivity = true;
        if ( alertType.allowOvernightCall === undefined ) alertType.allowOvernightCall = false;
        if ( alertType.contactId === undefined ) alertType.contactId = '';
        
        let title = alertType ? alertType.alertTypeId : "";
        let deleteAlertTypeIcon = this.props.onClose ? <IconButton onClick={this.deleteAlertType.bind(this)}><DeleteIcon /></IconButton> : null;

        let editAlertTypeMenu = <table width="100%">
            <tbody>
                <tr>
                    <td>
                        {title}
                    </td>
                    <td align="right">
                        <IconButton onClick={this.loadAlertType.bind(this, this.state.alertTypeId)}><RefreshIcon /></IconButton>
                        <IconButton onClick={this.saveAlertType.bind(this, null, null)}><SaveIcon /></IconButton>
                        <IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton>
                        {deleteAlertTypeIcon}
                    </td>
                    <td width="50"></td>
                </tr>
                <tr>
                    <td colSpan="3">
                        <TextField style={styles.name} label="Alert Type ID" value={alertType.alertTypeId} onChange={(e) => {
                            alertType.alertTypeId = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />
                        <br />
                        <TextField style={styles.name} label="From Address" value={alertType.from} onChange={(e) => {
                            alertType.from = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />
                        <br />
                        <SelectText style={styles.name} appContext={appContext} label="Subject Text" category="notifications" enableNone={true} 
                            textId={alertType.subjectMessageId} 
                            onCreate={this.onCreateText.bind(this,'subjectMessageId')}
                            onChange={(text) => {
                                alertType.subjectMessageId = text ? text.textId : '';
                                self.setState({ alertType, modified: true });
                            }} 
                        />
                        <br />
                        <SelectText style={styles.name} appContext={appContext} label="Message Text" category="notifications" enableNone={true} 
                            textId={alertType.messageId} 
                            onCreate={this.onCreateText.bind(this,'messageId')}
                            onChange={(text) => {
                                alertType.messageId = text ? text.textId : '';
                                self.setState({ alertType, modified: true });
                            }} 
                        />
                        <br />
                        <TextField style={styles.name} label="Report ID" value={alertType.reportName} onChange={(e) => {
                            alertType.reportName = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />
                        <br />
                        <FormControl style={styles.name}>
                            <InputLabel htmlFor="contact">Notification Type</InputLabel>
                            <Select label='Contact Method'
                                value={alertType.notificationType}
                                onChange={(e) => {
                                    alertType.notificationType = e.target.value; self.setState({ alertType, modified: true });
                                }}>
                                <MenuItem value='providerAlert'>providerAlert</MenuItem>
                                <MenuItem value='patientAlert'>patientAlert</MenuItem>
                                <MenuItem value='patientProviderAlert'>patientProviderAlert</MenuItem>
                                <MenuItem value='patientPrimaryProviderAlert'>patientPrimaryProviderAlert</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <TextField style={styles.description} label="Description" value={alertType.description} onChange={(e) => {
                            alertType.description = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />

                        <br />
                        <TextField style={styles.name} type="number" label="Message Level" value={alertType.messageLevel} onChange={(e) => {
                            alertType.messageLevel = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />

                        <br />
                        <TextField style={styles.name} type="number" label="Interval (Minutes)" value={alertType.intervalInMinutes} onChange={(e) => {
                            alertType.intervalInMinutes = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />

                        <br />
                        <TextField style={styles.name} type="number" label="Max Follow-Up Attempts" value={alertType.maxFollowUpAttempts} onChange={(e) => {
                            alertType.maxFollowUpAttempts = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />

                        <br />
                        <TextField style={styles.name} label="Alertnate Contact ID" value={alertType.contactId} onChange={(e) => {
                            alertType.contactId = e.target.value;
                            self.setState({ alertType, modified: true });
                        }} />
                        <br />
                        <FormControl style={styles.name}>
                            <InputLabel htmlFor="contact">Contact Method</InputLabel>
                            <Select label='Contact Method'
                                value={alertType.contactMethod}
                                onChange={(e) => {
                                    alertType.contactMethod = e.target.value; self.setState({ alertType, modified: true });
                                }}>
                                <MenuItem value='email'>Email</MenuItem>
                                <MenuItem value='phone'>Phone</MenuItem>
                                <MenuItem value='userPreference'>User Preference</MenuItem>
                            </Select>
                        </FormControl>

                        <br />
                        <FormControlLabel style={styles.name} control={<Checkbox checked={alertType.logActivity} onChange={(e,v) => {
                            alertType.logActivity = v;
                            this.setState({ alertType, modified: true });
                        }} />}label="Log Activity" />
                        <br />
                        <FormControlLabel style={styles.name} control={<Checkbox checked={alertType.allowOvernightCall} onChange={(e,v) => {
                            alertType.allowOvernightCall = v;
                            this.setState({ alertType, modified: true });
                        }} />}label="Allow Overnight Calls" />
                        <br />
                        <ChangedByButton appContext={appContext} primaryKey={alertType.alertTypeId} collection='AlertTypesModel' />
                    </td>

                </tr>
                <tr>
                    <td colSpan="2" valign="top">
                        {dialog}
                        {progress}
                    </td>
                </tr>
            </tbody>
        </table>;

        let appBar = this.props.onClose ? <AppBar style={styles.appBar} position="static">
            <Toolbar>
                <IconButton onClick={this.onCloseView.bind(this)}>
                    <NavigationClose />
                </IconButton>
                <Typography variant="h6" color="inherit">Alert Type</Typography>
            </Toolbar>
        </AppBar> : null;

        return (
            <div align="center" >
                {appBar}
                <div align="left" style={styles.div}>
                    {editAlertTypeMenu}
                </div>
            </div>
        );
    }
}

const styles = {
    root: {
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 60
    },
    table: {
        width: '70%',
        height: 800
    },
    content: {
        transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
        marginRight: 60,
        paddingBottom: 20
    },
    div: {
        margin: 10
    }
    ,
    progress: {
        color: 'red'
    },
    drawer: {
        overflow: 'hidden',
        width: 50,
        marginTop: '70px',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        overflow: 'hidden',
        width: 600,
        resize: 'vertical',
        marginTop: '70px',
    },
    drawerClose: {
        marginTop: '70px',
        overflowX: 'hidden',
        resize: 'vertical',
        width: 50
    },
    menuExpansion: {
        width: '1200px',
        justifyContent: 'left'
    },
    menuExpansionDetails: {
        width: '1200px',
        padding: '0px'
    },
    name: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    }
};

AlertTypeView.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(AlertTypeView);